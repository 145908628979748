/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getAssortimentRapportJour, getDetailsMagasins } from '../../../services/Rapports/RapportsApi'
import ModalImagePointageDebut from '../SuiviPointage/ModalImagePointageDebut'
import TableauGratuites from './TableauGratuites'
import TablePartLineaire from './Tables/TablePartLineaire'
import TablePromotions from './Tables/TablePromotions'
import TableRayons from './Tables/TableRayons'
import TableRelevePrix from './Tables/TableRelevePrix'
import TableRuptures from './Tables/TableRuptures'
import { useTranslation } from 'react-i18next';
import ModalRapportJournalier from './ModalRapportJournalier'
import ContactParHeure from './ContactParHeure'

export default function DetailsMagasins() {
    //------ History -----------------
    const history = useHistory()
    //------ PARAMS ------------------
    const { pdv_code, date, user_code } = useParams('')
    // -------- LISTE ------------------    
    const [info, setinfo] = useState({})
    const [infoPl, setinfoPl] = useState({})
    const [ruptures, setruptures] = useState({})
    const [listeRuptures, setlisteRuptures] = useState([])
    const [listePL, setlistePL] = useState([])
    const [listePromotions, setlistePromotions] = useState([])
    const [listeRelevePrix, setlisteRelevePrix] = useState([])
    const [listeRayons, setlisteRayons] = useState([])
    const [rupturedisponibilite, setrupturedisponibilite] = useState([])
    //Assortiments
    const [sum_ass_pl, setsum_ass_pl] = useState("") // somme assortiment Part Linéaire
    const [summ_ass_promotions, setsumm_ass_promotions] = useState("") // somme assortiment Promotions
    const [sum_ass_rupture, setsum_ass_rupture] = useState("") // somme assortiment Part Linéaire

    const [localisation, setlocalisation] = useState([])
    const [listeImages, setlisteImages] = useState([])
    const [totalImage, settotalImage] = useState(0)
    const [ok1, setok1] = useState(false)
    const [data, setdata] = useState({
        total_contact: 0,
        contact_sans_achat: 0,
        contact_avec_achat: 0
    })
    const user = JSON.parse(localStorage.getItem('user'));

    const [listeProduits, setlisteProduits] = useState([])
    //------ LOADER ---------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getDetailsMagasins(user_code, date, pdv_code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    var liste = []
                    var listeContacts = result.data.contactsMagasins
                    result?.data?.listeVentesContacts.map((items) => {
                        var found = listeContacts?.filter((el) => el.produit_code === items.produit_code)
                        if (found.length === 0) {
                            var obj = {
                                nompdv: items.nompdv,
                                pdv_code: items.pdv_code,
                                produit: items.produits,
                                produit_code: items.produit_code,
                                stockfin: 0,
                                stokdebut: 0,
                                user_code: items.user_code,
                                variation: 0,
                                vendu: items.quantite
                            }
                            liste.push(obj)
                        }
                    })
                    settotalImage(result?.data?.totalImage?.total_images)
                    setlisteImages(result?.data?.listeImages)
                    let combinedArray1 = listeContacts.concat(liste)
                    setlisteProduits(combinedArray1)
                    setinfo(result?.data?.magasins)
                    var param = result?.data?.magasins
                    getAssortimentRapportJour(param?.categoriepdv_code, param?.enseigne_code, date)
                        .then((result) => {
                            if (result?.data?.success === 1) {
                                setsum_ass_pl(result?.data?.listepl?.sum_pl)
                                setsum_ass_rupture(result?.data?.assRuputre?.sum_rupture)
                                setsumm_ass_promotions(result?.data?.assPromotions?.sum_promotions)
                            }
                        }).catch((err) => {
                            console.log(err)
                        });
                    setinfoPl(result?.data?.infoPl)
                    setruptures(result?.data?.detailsRuptures)
                    setlisteRuptures(result?.data?.listeruptures)
                    setlistePL(result?.data?.listepl)
                    setlistePromotions(result?.data?.listepromotions)
                    setlisteRelevePrix(result?.data?.listereleveprix)
                    setrupturedisponibilite(result?.data?.rupturedisponibilite)
                    setlisteRayons(result?.data?.listerayons)
                    setlocalisation(result?.data?.localisation)
                    setdata({
                        total_contact: result?.data?.total_contact.total_contact,
                        contact_sans_achat: result?.data?.contact_sans_achat?.contacts_sans_achat,
                        contact_avec_achat: result?.data?.contact_avec_achat.contact_avec_achat
                    })
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [])

    return (

        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')}   </a></li>
                    <li className="breadcrumb-item " aria-current="page"> {t('rapports.RapportJournalier')} </li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.detailMagasins')} : {pdv_code} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <button className="btn bg-primary " onClick={() => history.goBack()}>
                            <i className="fe fe-arrow-left" />
                            {t('rapports.retour')}
                        </button>
                    </div>
                </div>
            </div>
            {loading ? <SqaureLoader /> : (
                <>
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title text-center mt-3' >
                                <h4 >{user?.result?.type === "promotrice" ? t('promotrice') : t('dashboard.Merchandiseurs') + " : "}   <span className='text-azure'> {info?.nom} </span>  -  {t('dashboard.Magasin') + " : "} <span className='text-azure'> {info?.nompdv} </span>  <button className='btn btn-azure text-white fs-10' data-toggle="modal" data-target="#exampleModal2">{totalImage} {t('contacts.images')} </button> </h4>
                                <hr className='hr1' />
                                <h6 className='text-muted' > {t('dashboard.Date') + " : "} <span className='text-azure'> {date} </span> - {t('contacts.debutVisite')}  : <span className='text-azure' data-toggle="modal" data-target="#exampleModal" onClick={() => setok1(true)}> {info?.heure_debut} </span>  - {t('contacts.finVisite')}  : <span className='text-azure'> {info?.heure_fin} </span>  - {t('contacts.tempdevisite')} : <span className='text-azure'> {info?.difference} </span> </h6>

                            </div>
                        </div>
                    </div>
                    {user?.result?.type !== "promotrice" ? null : 
                    <>
                     <TableauGratuites data={data} listeProduits={listeProduits} t={t} user={user} />
                    <ContactParHeure t={t} code={user_code} today={date} pdv_code={pdv_code} />
                    </>
                    }
                    <div className="row">
                        <div className='col-xl-6 col-lg-6 col-sm-12 col-12'>
                            <TableRuptures sum_ass_rupture={sum_ass_rupture} ruptures={ruptures} info={info} listeRuptures={listeRuptures} rupturedisponibilite={rupturedisponibilite} t={t} />
                        </div>
                        <div className='col-xl-6 col-lg-6 col-sm-12 col-12'>
                            <TablePartLineaire sum_ass_pl={sum_ass_pl} listePL={listePL} infoPl={infoPl} t={t} />
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
                            <TablePromotions summ_ass_promotions={summ_ass_promotions} listePromotions={listePromotions} t={t} />
                        </div>
                        {/*** LOCALISATION  */}
                        {/*  <div className='col-xl-4 col-lg-4 col-sm-12 col-12'>
                            <VisiteMagasins localisation={localisation} />
                        </div> */}
                    </div>
                    <div className="row">
                        <div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
                            <TableRelevePrix listeRelevePrix={listeRelevePrix} t={t} />
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
                            <TableRayons listeRayons={listeRayons} t={t} />
                        </div>
                    </div>
                </>
            )}
            <ModalRapportJournalier listeImages={listeImages} t={t} />
            {ok1 ? <ModalImagePointageDebut date={date} pdv_code={pdv_code} user_code={user_code} setok1={setok1} t={t} />
                : null}
        </div>
    )
}
