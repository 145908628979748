import React, { useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import ModalListeRuptures from './ModalListeRuptures';

export default function HistoriqueMensuel({ pdv_code, year, loading, t, nbrvisite, nbrvisite2, nbrRuptureMois, nbrRuptureMois2, partLineaire, partLineaire2, liste, assortiments, assortiments2 }) {
    //------------- ETAT ---------
    const [ok1, setok1] = useState(false)

    const [month, setmonth] = useState("")
    const [annee, setannee] = useState("")

    // FIND VISITE OF CURRENT YEAR
    const findOnVisite = (mois) => {
        const liste = nbrvisite?.find((el) => el?.mois === mois)
        return liste?.visite
    }
    const findOnVisitePrev = (mois) => {
        const liste = nbrvisite2?.find((el) => el?.mois === mois)
        return liste?.visite
    }
    // FIND Rupture OF CURRENT YEAR
    const findRupture = (mois) => {
        const liste = nbrRuptureMois?.find((el) => el?.mois === mois)
        return liste?.rupture
    }
    const findRupturePrev = (mois) => {
        const liste = nbrRuptureMois2?.find((el) => el?.mois === mois)
        return liste?.rupture
    }
    // FIND PART LINEAIRE 
    const findPartLineaire = (mois) => {
        const liste = partLineaire?.find((el) => el?.mois === mois)
        return liste?.pl
    }
    const findPartLineairePrev = (mois) => {
        const liste = partLineaire2?.find((el) => el?.mois === mois)
        return liste?.pl
    }
    // FIND ASSORTIMENTS 
    const findassortiments = (mois) => {
        const liste = assortiments?.find((el) => el.debut === mois || el.fin === mois)
        return liste?.sum_ass
    }
    const findassortimentsPrev = (mois) => {
        const liste = assortiments2?.find((el) => el.debut === mois || el.fin === mois)
        return liste?.sum_ass
    }
    const tauxRupture = (mois) => {

        const x = findRupture(mois)
        var y = findassortiments(mois)
        if (y === undefined) {
            y = 1
        }
        if (x !== undefined && y !== undefined && y !== 0) {
           
            return parseFloat((x / y)*100).toFixed(2) >= 100 ? 100 : parseFloat(x / y).toFixed(2)
        } else return 0
    }
    const tauxRupturePrev = (mois) => {

        const x = findRupturePrev(mois)
        var y = findassortimentsPrev(mois)
        if (y === undefined) {
            y = 1
        }
        if (x !== undefined && y !== undefined && y !== 0) {
            return parseFloat(x / y).toFixed(2) >= 100 ? 100 : parseFloat(x / y).toFixed(2)
        } else return 0
    }
    return (
        <div className="panel-group" id="accordion2" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
                <div className="panel-heading " role="tab" id="headingTwo">
                    <h4 className="panel-title text-primary">
                        <a role="button" data-toggle="collapse" data-parent="#accordion2" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                            <i className="icon icon-folder-alt mr-2" /> {t('historiqueMagasin.detailmagasinmensuel')}
                            <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                        </a>
                    </h4>
                </div>
                <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body">

                        <div className="row">
                            <div className='col-12'>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="btn btn-success text-center mb-2 float-right"
                                    table="table-to-xls2"
                                    filename="RuptureMerbiss"
                                    sheet="tablexls"
                                    buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                                />
                            </div>

                            <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '300px' }}>
                                <table id="table-to-xls2" className="table  table-bordered ">
                                    <thead className="text-center bg-azure-lighter" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >
                                        <tr>
                                            <th style={{ fontSize: "11px" }}></th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t('janvier')}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("fevrier")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("Mars")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("avril")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("mai")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("juin")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("juillet")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("aout")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("Septembre")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("Octobre")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("Novembre")}</th>
                                            <th className="wd-15p fs-10" colSpan={2}>{t("decembre")}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                                            <th className="wd-15p fs-10" >{year}</th>
                                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>


                                        </tr>
                                    </thead>
                                    {loading ? <SqaureLoader /> : (
                                        <tbody className='text-center'>
                                            <tr className='fs-10'>
                                                <td className="text-center"> {t('historiqueMagasin.nombreVisite')} </td>
                                                {liste?.map((items) => (
                                                    <>
                                                        <td>{findOnVisite(items) !== undefined ? <b className="text-primary">{findOnVisite(items)}</b> : 0}</td>
                                                        <td>{findOnVisitePrev(items) !== undefined ? <b className="text-primary">{findOnVisitePrev(items)}</b> : 0}</td>
                                                    </>
                                                ))}
                                            </tr>
                                            <tr className='fs-10'>
                                                <td className="text-center"> {t('historiqueMagasin.nombrerupture')} </td>
                                                {liste?.map((items) => (
                                                    <>
                                                        <td  data-toggle="modal" data-target="#exampleModal3" style={{ cursor: 'pointer' }} onClick={()=>{setmonth(items);setok1(true); setannee(year)}}>{findRupture(items) !== undefined ? <span className="text-primary">{findRupture(items)}</span> : "-"}</td>
                                                        <td  data-toggle="modal" data-target="#exampleModal3" style={{ cursor: 'pointer' }} onClick={()=>{setmonth(items) ;setok1(true);setannee(parseInt(year) - 1)}} >{findRupturePrev(items) !== undefined ? <span className="text-primary">{findRupturePrev(items)}</span> : "-"}</td>
                                                    </>
                                                ))}

                                            </tr>
                                            <tr className='fs-10'>
                                                <td className="text-center"> {t('Magasins.Assortiments')}  </td>
                                                {liste?.map((items) => (
                                                    <>
                                                        <td>{findassortiments(items) !== undefined ? <span className="text-primary">{findassortiments(items)}</span> : '-'}</td>
                                                        <td>{findassortimentsPrev(items) !== undefined ? <span className="text-primary">{findassortimentsPrev(items)}</span> : '-'}</td>
                                                    </>
                                                ))}
                                            </tr>
                                            <tr className='fs-10'>
                                                <td className="text-center"> {t('historiqueMagasin.tauxrupture')} </td>
                                                {liste?.map((items) => (
                                                    <>
                                                        <td>{tauxRupture(items) !== 0 && tauxRupture(items) !== undefined ? <span className="text-primary">{tauxRupture(items)} %</span> : "NS"}</td>
                                                        <td>{tauxRupturePrev(items) !== 0 && tauxRupturePrev(items) !== undefined ? <span className="text-primary">{tauxRupturePrev(items)} %</span> : "NS"}</td>
                                                    </>
                                                ))}
                                            </tr>
                                            <tr className='fs-10'>
                                                <td className="text-center">  {t('dashboard.pl')} </td>
                                                {liste?.map((items) => (
                                                    <>
                                                        <td>{findPartLineaire(items) !== undefined ? <span className="text-primary">{findPartLineaire(items)}</span> : "-"}</td>
                                                        <td>{findPartLineairePrev(items) !== undefined ? <span className="text-primary">{findPartLineairePrev(items)}</span> : "-"}</td>
                                                    </>
                                                ))}
                                            </tr>
                                        </tbody>
                                    )}

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {ok1 ? < ModalListeRuptures t={t} month={month} annee={annee} pdv_code={pdv_code} setok1={setok1}  /> : null}
        </div>
    )
}
