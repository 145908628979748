/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getSelectRegions, getSelectTypePdv } from '../../../services/Magasins/PdvApi'
import { addTournee, getListeRoutings, getListeTourneeRegion, getListeTourneeRoute } from '../../../services/Routings/RoutingsApi'
import { getListZones } from '../../../services/Zoning/ZoningApi'
import MapRoutes from '../Route/MapRoutes'

export default function CreationTournee() {

    // Paraméteres 
    const { route, region } = useParams('')
    //--------- HISTORY ---------------
    const history = useHistory();
    // CONDITION BOUTON ROUTE/REGION
    const [ok, setok] = useState(false)
    //---------- CODE FILTRE -----------
    const [codeJour, setcodeJour] = useState("-1")
    const [codeRoute, setcodeRoute] = useState("-1")
    const [codeRegion, setcodeRegion] = useState("-1")
    const [codeZone, setcodeZone] = useState("-1")
    const [codeType, setcodeType] = useState("-1")
    const [dateDebut, setdateDebut] = useState("")
    const [dateFin, setdateFin] = useState("")
    //---------- FILTRE -----------
    const [listeRoute, setlisteRoute] = useState([])
    const [listeRegion, setlisteRegion] = useState([])
    const [listeZone, setlisteZone] = useState([])
    const [localisation, setlocalisation] = useState([])
    const [type, settype] = useState([])
    //------- Liste Magasin ------------------
    const [liste, setliste] = useState([])
    const [listeTournee, setlisteTournee] = useState([])
    const [saveListe, setsaveListe] = useState([])
    const [frequence, setFrequence] = useState("")
    //Loader 
    const [loading, setloading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(10);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = liste
    //Choisir 
    const [show, setShow] = useState(false)
    const [index, setindex] = useState("")
    //const [codeSave, setcodeSave] = useState("")
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")

    useEffect(() => {
        //Get Liste Routes 
        getListeRoutings()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteRoute(result?.data?.getListeRoutes)
                }

            }).catch((err) => {
                console.log(err)
            });
        getListZones()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteZone(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectTypePdv()
            .then((result) => {
                if (result?.data?.success === 1) {
                    settype(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });

        getSelectRegions()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteRegion(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)

            }).finally(() => {
                setloading(false)
            })
        if (codeRegion !== "") {
            getListeTourneeRegion(codeRegion)
                .then((result) => {
                    traitement(result)
                    setlocalisation(result?.data?.localisation)
                }).catch((err) => {
                    console.log(err)
                });
        }
        if (codeRoute !== "") {
            getListeTourneeRoute(codeRoute)
                .then((result) => {
                    traitement(result)
                    setlocalisation(result?.data?.localisation)
                }).catch((err) => {
                    console.log(err)
                });
        }

    }, [])

    const filterDataByZone = (e) => {
        if (e.target.value !== "-1") {
            setcodeZone(e.target.value)
            const pdvs = liste?.filter((element) => element.zone_code === e.target.value)
            setliste(pdvs)
        }
        else {
            setliste(saveListe)
        }
    }
    const filterDataType = (e) => {
        if (e.target.value !== "-1") {
            setcodeType(e.target.value)
            const pdvs = liste?.filter((element) => element.typepdv_code === e.target.value)
            setliste(pdvs)
        }
        else {
            setliste(saveListe)
        }
    }
    const filterDataRoute = (e) => {
        if (e.target.value !== "-1") {
            setcodeRoute(e.target.value)
            const pdvs = liste?.filter((element) => element.routing_code === e.target.value)
            setliste(pdvs)
        }
        else {
            setliste(saveListe)
        }
    }
    const filterDataRegion = (e) => {
        if (e.target.value !== "-1") {
            setcodeRegion(e.target.value)
            const pdvs = liste?.filter((element) => element.region_code === e.target.value)
            setliste(pdvs)
        }
        else {
            setliste(saveListe)
        }
    }
    const traitement = (result) => {
        const test = result?.data?.data?.map((items) => {
            return Object.assign({}, {
                code: items.code,
                nompdv: items.nompdv,
                user_code: items.user_code,
                zone_code: items.zone_code,
                typepdv_code: items.typepdv_code,
                categoriepdv_code: items.categoriepdv_code,
                region_code: items.region_code,
                routing_code: items.routing_code,
                adresse: items.adresse,
                codetype: items.codetype,
                type: items.type,
                routes: items.routes,
                zone: items.zone,
                region: items.region,
                coderegion: items.coderegion,
                categorie: items.categorie,
                choisir: 0,
                frequence: "",
                dates: "",
                rang: 1
            })
        })
        setliste(test)
        setsaveListe(test)
    }
    const updateListePdv = (e, type) => {

        if (type === "route") {
            history.push({
                pathname: "/routings/routings&route=" + e.target.value + "&region=" + codeRegion
            })

            setcodeRoute(e.target.value)
            getListeTourneeRoute(e.target.value)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        traitement(result)
                        setlocalisation(result?.data?.localisation)
                    }
                }).catch((err) => {
                    console.log(err)
                });
            // get liste of pdvs by route code 
        } else if (type === "region") {
            history.push({
                pathname: "/routings/routings&route=" + codeRoute + "&region=" + e.target.value
            })
            setcodeRegion(e.target.value)
            //GET LISTE OF PDVS BY REGIONS CODE
            getListeTourneeRegion(e.target.value)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        traitement(result)
                        setlocalisation(result?.data?.localisation)
                        setlocalisation(result?.data?.localisation)
                    }
                }).catch((err) => {
                    console.log(err)
                });

        }
    }

    const handleChange = (e, index) => {
        const clonedData = [...liste]
        clonedData[index][e.target.name] = e.target.checked
        setindex(index)
        setliste(clonedData)
    }
    const handleChangeValue = (e, index) => {
        const clonedData = [...liste]
        clonedData[index][e.target.name] = e.target.value
        setliste(clonedData)
    }

    const saveTournee = () => {
   

            if (codeJour === "-1") {
                toast(t('messages.choixcode'), { type: "warning", position: 'top-right' })
            } else if (dateDebut === "" || dateFin === "") {
                toast(t('messages.messagedate'), { type: "warning", position: 'top-right' })
            } else if (dateDebut > dateFin) {
                toast(t('messages.dateinf'), { type: "warning", position: 'top-right' })
            }
            else {
                const terminal = liste?.filter((element) => element.choisir === true)
                if (terminal?.length === 0) {
                    toast(t('messages.choisirpdv'), { type: "warning", position: 'top-right' })
                } else {
                    var code_generated = codeRoute+"-"+parseInt(Date.now() * Math.random())
                    const ajouter_tournee = terminal?.map((items) => {
                        return Object.assign({}, {
                            code: code_generated,
                            routing_code: items.routing_code,
                            user_code: items.user_code,
                            pdv_code: items.code,
                            actif: "1",
                            date_debut: dateDebut,
                            date_fin: dateFin,
                            code_jour: codeJour,
                            frequence: frequence !== "" ? frequence : items.frequence,
                            dates: items.dates,
                            rang: items.rang
                        })
                    })
                    addTournee(ajouter_tournee)
                        .then((result) => {
                            if (result === 500) {
                                toast(t('messages.erreurajout'), { type: "error", position: 'top-right' })
                            } else if (result?.data?.success === 1) {
                                toast(t('messages.ajoutsuccee'), { type: "success", position: "top-right" })
                            } else {
                                toast(t('messages.verifierdonner'), { type: "warning", position: "top-right" })
                            }
                        }).catch((err) => {
                            console.log(err)
                        });

                }
            
        }


    }

    return (
        <div>
            {/** ---------------------- ENTETE CREATION TOURNEE ------------------------------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Routings.Tournee')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">   {t('sidebar.creationTournee')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href={'/routings/tournee&datedebut=' + date1 + '&datefin=' + date2} className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-directions mr-1" />
                            {t('Routings.Tournee')}
                        </a>
                        <a href="/routings/TourneeZone" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-event mr-1" />
                            {t('Routings.creationAgenda')}                        </a>
                        <a href="/routings/routes" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-map mr-1" />
                            {t('Routings.Route')}
                        </a>
                        <a href="/routings/tournee" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                         <i className="icon icon-map mr-1" />
                         {t('Tournee agenda par zone')}
                     </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="row">
                <div className="col-lg-9 col-xl-9 col-md-12 col-sm-12" >
                    {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
                    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                        <div className="panel panel-default active">
                            <div className="panel-heading " role="tab" id="headingOne">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <i className="icon icon-grid text-primary mr-2" />  {t('sidebar.creationTournee')}
                                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    <div className="row">

                                        <div className="col-12">
                                            <div className="form-group text-center">
                                                <button className={ok ? "btn br-tl-md-0 br-bl-md-0 btn-outline-primary fs-12 " : "btn br-tl-md-0 br-bl-md-0 bg-purple fs-12 "} onClick={() => setok(!ok)}> <i className="icon icon-directions mr-1" />{t('Routings.Route')}</button>
                                                <button className={ok ? "btn br-tl-md-0 br-bl-md-0 bg-purple fs-12  " : "btn br-tl-md-0 br-bl-md-0 btn-outline-primary fs-12 "} onClick={() => setok(!ok)}> <i className="icon icon-location-pin mr-1" /> {t('Routings.Region')}</button>
                                            </div>
                                        </div>

                                    </div>
                                    {loading ? <SqaureLoader /> : (
                                        <>
                                            <div className="form row no-gutters">
                                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-3 mb-0">
                                                    {ok ? <select value={codeRegion} onChange={(e) => updateListePdv(e, 'region')} className="form-control custom-select fs-12 br-md-0">
                                                        <option value={"-1"}  >{t('Routings.Region')}</option>
                                                        {listeRegion?.map((items, keys) => (
                                                            <option value={items.value} key={keys}> {items.name} </option>
                                                        ))}
                                                    </select> :
                                                        <select value={codeRoute} onChange={(e) => updateListePdv(e, 'route')} className="form-control custom-select fs-12  br-md-0">
                                                            <option value={"-1"}  >{t('Routings.Route')}</option>
                                                            {listeRoute?.map((items, keys) => (
                                                                <option value={items.code} key={keys}> {items.code} </option>
                                                            ))}
                                                        </select>}
                                                </div>
                                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-3 mb-0">
                                                    <select value={codeZone} onChange={(e) => filterDataByZone(e)} className="form-control custom-select fs-12 br-md-0">
                                                        <option value={"-1"}  >{t('Routings.Zone')}</option>
                                                        {listeZone?.map((items, keys) => (
                                                            <option value={items.code} key={keys}> {items.code} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-3 mb-0">
                                                    <select value={codeType} onChange={(e) => filterDataType(e)} className="form-control custom-select fs-12 br-md-0">
                                                        <option value={"-1"}  >{t('Routings.type')}</option>
                                                        {type?.map((items, keys) => (
                                                            <option value={items.value} key={keys}> {items.name} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-3 mb-0">
                                                    {ok ? <select value={codeRoute} onChange={(e) => filterDataRoute(e)} className="form-control custom-select fs-12  br-md-0">
                                                        <option value={"-1"}  >{t('Routings.Route')}</option>
                                                        {listeRoute?.map((items, keys) => (
                                                            <option value={items.code} key={keys}> {items.code} </option>
                                                        ))}
                                                    </select> :
                                                        <select value={codeRegion} onChange={(e) => filterDataRegion(e)} className="form-control custom-select fs-12  br-md-0">
                                                            <option value={"-1"}  >{t('Routings.Region')}</option>
                                                            {listeRegion?.map((items, keys) => (
                                                                <option value={items.value} key={keys}> {items.name} </option>
                                                            ))}
                                                        </select>}
                                                </div>
                                            </div>
                                            <h6 className=" mt-2 text-muted" style={{ fontSize: "12px" }}>  <span className="text-info"> <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information') + " : "}  </span> {t('Routings.messageCreationT')}</h6>

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** -----------------  BODY OF PAGE ------------------------- */}
                    <div className="card">
                        <div className="card-body">
                            {liste?.length === 0 ?
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                        <div className="alert bg-purple mb-5 py-4" role="alert">
                                            <div className="d-flex">
                                                <i className="icon icon-info mr-3 fs-30"></i>
                                                <div>
                                                    <h4 className="alert-heading">{t('Routings.AucunMagasin')} </h4>
                                                    <p> {t('Routings.selectionnerTournee')} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : (
                                    <>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                <span className='float-left'><i className="icon icon-home mr-1" /> {t('Routings.nombretotal')} : {liste?.length}  </span>

                                                <button className=" btn btn-azure fs-12 float-right " onClick={() => setShow(!show)}> <i className="icon  icon-plus" /> {t('Produits.Ajouter')} </button>
                                            </div>
                                        </div>

                                        <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '600px' }}>
                                            <table className="table table-striped  ">
                                                <thead className="text-center bg-azure-lighter" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >
                                                    <tr className="text-center">
                                                        <th className="wd-15p fs-10" >{t('rapports.code')}</th>
                                                        <th className="wd-15p fs-10" >{t('rapports.MAGASIN')}</th>
                                                        <th className="wd-15p fs-10" >{t('Routings.adresse')}</th>
                                                        <th className="wd-15p fs-10" >{t('Routings.Region')}</th>
                                                        <th className="wd-15p fs-10" >{t('Routings.Zone')}</th>
                                                        <th className=" fs-10" >{t('rapportrupture.categorie')}</th>
                                                        <th className="wd-15p fs-10" >{t('Routings.choisir')}</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentListe?.map((items, keys) => (
                                                        <>
                                                            <tr key={keys} className="text-center">
                                                                <td className="fs-10"> <a className="text-primary" href={"/pdv/historique&code=" + items.code}> {items.code}  </a> </td>
                                                                <td className="fs-10"> {items.nompdv} </td>
                                                                <td className="fs-10"> {items.adresse === null ? <span className="text-warning" > {t('Routings.aucune')} </span> : items.adresse} </td>
                                                                <td className="fs-10">  {items.region} </td>
                                                                <td className="fs-10">  {items.zone} </td>
                                                                <td className="fs-10">  {items.categorie} </td>
                                                                <td className="fs-10">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="choisir"
                                                                        value={items.choisir}
                                                                        onChange={(e) => handleChange(e, keys)}
                                                                        className="fcustom-control-input fs-10"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            {items.choisir === true ? (
                                                                <tr>
                                                                    <td colSpan={3}>
                                                                        <label className='form-label text-center fs-10'>{t('Routings.frequence')}</label>
                                                                        <select value={items.frequence} onChange={(e) => handleChangeValue(e, keys)} name="frequence" className="form-control custom-select fs-10 br-md-0">
                                                                            <option value={""}  >{t('Routings.frequence')}</option>
                                                                            <option value='semaine'>{t('Routings.semaine')}</option>
                                                                            <option value='semainepaire'>{t('Routings.semainepaire')}</option>
                                                                            <option value='mois'>{t('Routings.mois')}</option>
                                                                            <option value='1er_semaine'>{t('Routings.1erSemaine')}</option>
                                                                            <option value='2eme_semaine'>{t('Routings.2emeSemaine')}</option>
                                                                            <option value='3eme_semaine'>{t('Routings.3emeSemaine')}</option>
                                                                            <option value='4eme_semaine'>{t('Routings.4emeSemaine')}</option>
                                                                            <option value='4eme_semaine'>{t('Routings.journalier')}</option>
                                                                            <option value='Par_dates'>{t('Routings.pardates')}</option>
                                                                        </select>
                                                                    </td>
                                                                    <td colSpan={3}>
                                                                        <label className='form-label text-center fs-10'>{t('dashboard.Date')}</label>
                                                                        <input
                                                                            type="date"
                                                                            name="dates"
                                                                            value={items.dates}
                                                                            onChange={(e) => handleChangeValue(e, keys)}
                                                                            className="form-control fs-10 fc-datepicker"
                                                                        />
                                                                    </td>
                                                                    <td colSpan={3}>
                                                                        <label className='form-label text-center fs-10'>{t('Routings.rang')}</label>
                                                                        <input
                                                                            type='number'
                                                                            name="rang"
                                                                            min={1}
                                                                            value={items.rang}
                                                                            onChange={(e) => handleChangeValue(e, keys)}
                                                                            className="form-control fs-10 fc-datepicker"
                                                                        />
                                                                    </td>
                                                                </tr>

                                                            ) : null}

                                                        </>

                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>

                                    </>
                                )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-xl-3 col-md-12 col-sm-12">
                    {show ? (
                        <div className="card mt-2">
                            <div className="card-header">
                                <div className="card-title mt-2 text-center fs-12" >
                                    {t('Routings.ajouterTournee')}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-row">
                                        <div className="form-group col-md-12 mb-0">
                                            <div className="form-group">
                                                <label className='fs-10'>{t('Routings.CODEJOUR')} </label>
                                                <div className="form-group  mb-0">
                                                    <select value={codeJour} onChange={(e) => setcodeJour(e.target.value)} className="form-control fs-10  custom-select br-md-0">
                                                        <option value={"-1"} > {t('dashboard.jours')}  </option>
                                                        <option value={"Lundi"}> {t('dashboard.lundi')}</option>
                                                        <option value={"Mardi"}>{t('dashboard.Mardi')}</option>
                                                        <option value={"Mercredi"}>{t('dashboard.Mercredi')}</option>
                                                        <option value={"Jeudi"}>{t('dashboard.Jeudi')}</option>
                                                        <option value={"Vendredi"}>{t('dashboard.Vendredi')}</option>
                                                        <option value={"Samedi"}>{t('dashboard.Samedi')}</option>
                                                        <option value={"Dimanche"}>{t('dashboard.Dimanche')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-xl-12 col-lg-12 col-md-12 mb-0">
                                            <div className="form-group">
                                                <label className='fs-10'>{t('Routings.frequence')}</label>
                                                <select value={frequence} onChange={(e) => setFrequence(e.target.value)} name="frequence" className="form-control custom-select fs-10 br-md-0">
                                                    <option value={""}  >{t('Routings.frequence')}</option>
                                                    <option value='semaine'>{t('Routings.semaine')}</option>
                                                    <option value='semainepaire'>{t('Routings.semainepaire')}</option>
                                                    <option value='mois'>{t('Routings.mois')}</option>
                                                    <option value='1er_semaine'>{t('Routings.1erSemaine')}</option>
                                                    <option value='2eme_semaine'>{t('Routings.2emeSemaine')}</option>
                                                    <option value='3eme_semaine'>{t('Routings.3emeSemaine')}</option>
                                                    <option value='4eme_semaine'>{t('Routings.4emeSemaine')}</option>
                                                    <option value='4eme_semaine'>{t('Routings.journalier')}</option>
                                                    <option value='Par_dates'>{t('Routings.pardates')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group col-xl-6 col-lg-6 col-md-12 mb-0">
                                            <div className="form-group">
                                                <label className='fs-10'>{t('Routings.datedebut')}</label>
                                                <input type="date" value={dateDebut} onChange={(e) => setdateDebut(e.target.value)} className="form-control fs-10 text-primary" />
                                            </div>
                                        </div>
                                        <div className="form-group col-xl-6 col-lg-6 col-md-12 mb-0">
                                            <div className="form-group">
                                                <label className='fs-10'>{t('Routings.datefin')}</label>
                                                <input type="date" value={dateFin} onChange={(e) => setdateFin(e.target.value)} className="form-control fs-10 text-primary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-center ">
                                <button className="btn btn-primary fs-12 " onClick={() => saveTournee()}> <i className="ti ti-save mr-1" /> {t('Magasins.Enregistrer')} </button>
                            </div>
                        </div>
                    ) : null}
                    <MapRoutes listemagasins={localisation} loading={loading} t={t} />
                </div>
            </div>
        </div>
    )
}
