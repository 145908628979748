/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { getByCodeGammes, getListeGammes } from '../../../services/Produits/configurationProduits/ConfigurationAPI'
import { SearchItems } from '../../../utils/SearchUtils'
import AddModalGamme from './AddModalGamme'
import EditModalGammes from './EditModalGammes'
import ModalExportGammes from './ModalExportGammes'
import ModalImportExcel from './ModalImportExcel'

export default function ListeGamme({t}) {
    const [listeGammes, setlisteGammes] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    //Loader 
    const [Loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(30);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeGammes?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)
    //Edit Items 
    const [show, setShow] = useState(true)
    const [showEdit, setShowEdit] = useState(false)
    const [index, setIndex] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    //MODAL  IMPROT EXCEL
    const [ok, setOk] = useState(false)

    useEffect(() => {
        setLoading(true)
        getListeGammes()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteGammes(result?.data?.data)
                    setSaveList(result?.data.data)
                }
            }).catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    //ONChange 
    useEffect(() => {
        if (show === false ||showEdit === true) {
            setLoading(true)
            getListeGammes()
                .then((result) => {
                    if (result?.data?.data !== null) {
                        setlisteGammes(result?.data?.data)
                        setSaveList(result?.data.data)
                    }
                }).catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [show, showEdit])
    /**
      * Change gammes 
      */
    const changeDetailsGammes = (code) => {
        setLoading(true)
        getByCodeGammes(code)
            .then((result) => {
                setObject(result.data.data[0])

            }).catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }


    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeGammes, search)
            setSearchlist(newList)
        } else {
            setlisteGammes(saveList)
        }
    }


    return (
        <div>

            <div className="row">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title mt-3 float-left"> <i className="icon icon-list mr-1 text-azure" />  {t('Produits.ListeGammes')} </div>
                        <button className="btn btn-azure float-right fs-12" data-toggle="modal" data-target="#exampleModal3"> <i className="icon icon-plus mr-1" /> {t('Produits.Ajouter')}</button>
                        <button className='btn btn-green float-right mr-1 fs-12'  data-toggle="modal" data-target="#exampleModalimport" ><i className="fa fa-file-excel-o mr-1" /> {t('Produits.Importer')} </button>
                        <button className='btn btn-secondary float-right mr-1 fs-12' data-toggle="modal" data-target="#exportGamme" ><i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')} </button>

                    </div>
                    <ToastContainer />
                    <div className="card-body">
                    <div className="search1 search-elements-sm mb-4 ">
                                <div className="form row no-gutters">
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                    <span className="input-group-append">
                                        <input
                                            type="text"
                                            ref={inputEl}
                                            value={search}
                                            onChange={() => getSearchTerm()}
                                            className="form-control br-tr-md-0 br-br-md-0 text-azure fs-12"
                                            id="text1"
                                            placeholder={t("Produits.Chercherunegamme")}
                                        />
                                        <i className="text-azure ti-search ml-2 mt-4" />
                                    </span>
                                        </div>

                                  
                                </div>
                            </div>
                        {Loading ? (
                            <SqaureLoader />
                        ) : (
                            <>
                            <div className="table-responsive product-datatable" style={{ overflow: "scroll", height: "400px" }}>
                                <table
                                    id="example"
                                    className="table table-striped table-bordered "
                                >
                                    <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1 ,backgroundColor:"white"}}>
                                        <tr>
                                        <th className="w-15p fs-12">{t('rapports.code')}</th>
                                                <th className="w-15p fs-12">{t('Produits.Libelle')}</th>
                                            <th className="wd-15p fs-12" >{t('Produits.Couleur')}</th>
                                            <th className="w-15p fs-12">{t('Produits.Actif')}</th>

                                            <th className="w-15p fs-12">{t('rapports.ACTION')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {currentListe?.map((items, keys) => (
                                            <tr  className={
                                                keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                            }>
                                                <td>{items.code} </td>
                                                <td>{items.libelle} </td>
                                                <td className="text-center">
                                                    <div className=" mb-0" style={{ textAlign: "center", display: "inline-flex" }}>
                                                        <div className="w-6 h-4  text-center " style={{ background: items.color, alignContent: "center" }} />                                                            <div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{items.actif} </td>
                                                <td style={{ cursor: "pointer" }}>  <a data-toggle="modal" data-target="#exampleModal" onClick={() => {
                                                    changeDetailsGammes(items.code); setCode(items.code); setIndex(keys); setShowEdit(true)
                                                }}> <i className="icon icon-note text-azure" /></a> </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                              
                            </div>
                               <div className="float-right mt-3">
                               <PaginationTable
                                   postsPerPage={stocksPerPage}
                                   totalPosts={search.length <1 ? listeGammes?.length : searchList?.length}
                                   currentPage={currentPage}
                                   setCurrentPage={setCurrentPage}
                               />
                           </div>
                            </>
                            )}
                    </div>
                </div>
            </div>


            {showEdit ? <EditModalGammes setShowEdit={setShowEdit} setShow={setShow} keys={index}object={object} t={t} /> : null}

            <AddModalGamme setShow={setShow} t={t}  />
            <ModalImportExcel setOk={setOk} t={t}  />
            <ModalExportGammes listeGammes={listeGammes} t={t} />

        </div>
    )
}
