import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import { getEvaluationResults } from '../../services/Evaluation/EvaluationAPI'

export default function ModalEvaluationQuestions({ t, month, year, setok ,user_code}) {
 
    //----------------- LISTE ---------------------
    const [listeQuestions, setlisteQuestions] = useState([])
    const [loading, setloading] = useState(true)
    
    useEffect(() => {
        setloading(true)
        //Call WebService with liste of promotions
        getEvaluationResults(user_code, month, year)
          .then((result) => {
            if (result?.data?.success === 1) {
                setlisteQuestions(result?.data?.data)
            }
          }).catch((err) => {
            console.log(err)
          }).finally(() => {
            setloading(false)
          })
      }, [month,year, user_code])

    return (
    <div className="modal fade" id="getEvals" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="getEvals" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" style={{ width: "max-content" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="getEvals"> <i className='fe fe-tag text-azure mr-1' /> {t("Notes d'évaluation")}   </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setok(false);setlisteQuestions([])}}> 
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {loading ? <SqaureLoader /> : (
              <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '500px' }}>
                <table className="table  table-bordered ">
                  <thead className="text-center  bg-purple fs-10" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                  <td className='fs-10'>#</td>
                    <td className="wd-15p fs-10">{t('Questions')}</td>
                    <td className="wd-15p fs-10">{t('Note (/5)')}</td>
                  </thead>
                  <tbody>
                    {listeQuestions?.map((items, keys) => (
                      <tr key={keys} className="text-center fs-10">
                        <td className='fs-10'>{keys+1}</td>
                        <td>{items.question}</td>
                        <td>{items.note}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  )
}
