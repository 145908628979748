import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { updateCadeauxByCode } from '../../../services/Cadeaux/CadeauxApi';

export default function ModalUpdateCadeaux({ id, index2,object ,setShowEdit,t ,listeMerch }) {
    
    const [ok, setok] = useState(false)
    const [codeUser, setcodeUser] = useState("")
    const [code, setcode] = useState("")
    const [libelle, setlibelle] = useState("")
    const [a_la_prochaine, seta_la_prochaine] = useState("")
    const [qte, setqte] = useState("")
    const [ordre, setordre] = useState(0)
    const [date_debut, setdate_debut] = useState("")
    const [date_fin, setdate_fin] = useState("")
    const [avant_pause, setavant_pause] = useState("")
    const [apres_pause, setapres_pause] = useState("")
    //-------- Loading -----------------------
   const [loading, setloading] = useState(true)

    //add  cade aux 
    const [image, setImage] = useState({ preview: "", raw: "" });
   useEffect(()=>{
        try{
            setloading(true)
            setcodeUser(object?.user_code)
            setordre(object?.ordre)
            setcode(object?.code)
            setlibelle(object?.libelle)
            setdate_debut(moment(object?.date_debut).format("YYYY-MM-DD"))
            setdate_fin(moment(object?.date_fin).format("YYYY-MM-DD"))
            seta_la_prochaine(object?.a_la_prochaine)
            setapres_pause(object?.apres_pause)
            setavant_pause(object?.avant_pause)
            setqte(object?.qte)
            console.log(object);
            setImage({preview: object?.image})
            if(object?.type === "aleatoire"){
                setok(true)
            }else {
                setok(false)
            }
        }catch(error){
            console.log(error)
        }finally{
            setloading(false)

        }
       
   },[object])

   const saveData = () => {
    if(ordre==="" || libelle ==="" || date_debut==="" || date_fin===""){
        toast('Veuillez bien saisir les champs du formulaire ',{type:"warning",position:"top-right"})
    }else {
        const data = {
            code : code ,
            ordre : ordre,
            libelle : libelle,
            date_debut:date_debut,
            date_fin :date_fin,
            a_la_prochaine:a_la_prochaine,
            apres_pause : apres_pause,
            avant_pause: avant_pause,
            user_code  :codeUser,
            qte : qte,
            type : ok === true ? "aleatoire" : "programme"

        }
        if (image.raw !== "") {
            Object.assign(data, {
                image: image.raw,
            })
        }
        updateCadeauxByCode(id,data).then((result) => {
            if(result?.data?.success===1){
                toast("Cadeau modifié avec succée ", {type:"success",position:"top-right"})
            }
        }).catch((err) => {
            console.log(err)
        });
    }
   }

    //UPLOAD IMAGE WITH PREVIEW image Commerce
    const selectFile_image = (event) => {
        if (event.target.files.length) {
            setImage({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0]
            })
            console.log({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0]
            });
        }
    }
  return (
    <div className="modal fade" id="updateGIFT" tabIndex={-1} role="dialog" aria-hidden="true" key={index2} >
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title text-azure" id="updateGIFT"> <i className='icon icon-present mr-2' />Modification Cadeaux </h5>
                <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" /> </span>
            </div>
            <div className="modal-body">
                {loading ? <SqaureLoader /> : (
                    <form>
                           <div className='row'>
                                <div className="col-12">
                                <div className="form-group text-center">
                                        <button className={ok ? "btn br-tl-md-0 br-bl-md-0 btn-outline-primary fs-12 " : "btn br-tl-md-0 br-bl-md-0 bg-purple fs-12 "} onClick={() => setok(!ok)}> <i className="icon icon-trophy mr-1" /> {t('Produits.Programme')} </button>
                                        <button className={ok ? "btn br-tl-md-0 br-bl-md-0 bg-purple fs-12  " : "btn br-tl-md-0 br-bl-md-0 btn-outline-primary fs-12 "} onClick={() => setok(!ok)}> <i className="icon icon-event mr-1" />{t('Produits.Aleatoire')} </button>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className="col-4">
                                    <label>{t('Produits.Ordre')} </label>
                                    <input type="number" value={ordre} onChange={(e) => setordre(e.target.value)} placeholder='ordre ... ' className='form-control' />
                                </div>
                                <div className="col-4">
                                    <label>{t('Produits.Libelle')} </label>
                                    <input type="text" placeholder={t('Produits.Libelle')} value={libelle} onChange={(e) => setlibelle(e.target.value)} className='form-control' />
                                </div>
                                <div className="col-4">
                                            <label>{t('Produits.Quantite')} </label>
                                            <input type="number" placeholder={t('Produits.Quantite')} value={qte} onChange={(e) => setqte(e.target.value)} className='form-control' />
                                        </div>
                                {ok ? null : (
                                    <>
                                        <div className="col-6">
                                            <label>{t('Produits.Quantite')} </label>
                                            <input type="number" placeholder={t('Produits.Quantite')} value={qte} onChange={(e) => setqte(e.target.value)} className='form-control' />
                                        </div>
                                        <div className="col-6">
                                            <label>{t('Produits.Alaprochaine')} </label>
                                            <input type="number" placeholder={t('Produits.Alaprochaine')} value={a_la_prochaine} onChange={(e) => seta_la_prochaine(e.target.value)} className='form-control' />
                                        </div>
                                        <div className="col-6">
                                            <label>{t('Produits.AvantPause')} </label>
                                            <input type="number" placeholder={t('Produits.AvantPause')}  value={avant_pause} onChange={(e) => setavant_pause(e.target.value)} className='form-control' />
                                        </div>
                                        <div className="col-6">
                                            <label>{t('Produits.ApresPause')} </label>
                                            <input type="number" placeholder={t('Produits.ApresPause')} value={apres_pause} onChange={(e) => setapres_pause(e.target.value)} className='form-control' />
                                        </div>
                                    </>
                                )}

                                <div className="col-6">
                                    <label>{t('Routings.datedebut')}</label>
                                    <input type="date" value={date_debut} onChange={(e) => setdate_debut(e.target.value)} className="form-control fs-10 text-primary" />
                                </div>
                                <div className="col-6">
                                    <label>{t('Produits.DATEFIN')}</label>
                                    <input type="date" value={date_fin} onChange={(e) => setdate_fin(e.target.value)} className="form-control fs-10 text-primary" />
                                </div>
                                <div className='col-12'>
                                    <label className="form-control-label">{t('dashboard.Merchandiseurs')} : <span className="text-danger"> * </span></label>
                                    <select name="country" id="select-countries2" value={codeUser} onChange={(e) => setcodeUser(e.target.value)} className="form-control  text-primary  custom-select br-md-0">
                                        <option value={""} selected> {t('dashboard.Merchandiseurs')} ... </option>
                                        {listeMerch?.map((items, keys) => (
                                            <option value={items.value} key={keys}>{items.name} </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <label className="form-control-label">{t('Produits.imageproduit')}</label>

                                    <input type="file" name='image' onChange={(e) => selectFile_image(e)}  className="dropify" data-height={180} />
                                    {image?.preview !== "" ? <img src={image.preview} alt="image_produit" height={400} />: ""}
                                </div>
                            </div>


                    </form>
                )}

            </div>
            <div className="modal-footer ">
                <button type="button" className="btn btn-azure fs-12 " onClick={() => { saveData(); setShowEdit(true) }} > <i className='icon icon-cloud-download mr-1' />Enregister </button>
                <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" > <i className="ti-close mr-1" />Fermer</button>

            </div>
        </div>
    </div>
</div>
  )
}
