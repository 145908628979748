import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getListeRegions, getListeSecteurs, postObjectZones } from '../../../services/Zoning/ZoningApi'
import { myRnId } from '../../../utils/DateUtils'

export default function ModalAddZone({ setShow, t }) {

    //------ Formulaire -----------
    const [code, setCode] = useState("")
    const [libelle, setlibelle] = useState("")
    const [codeSecteur, setCodeSecteur] = useState("")
    const [codeRegion, setcodeRegion] = useState("")
    // ------ Liste des secteurs ------------
    const [listeSecteurs, setListeSecteurs] = useState([])
    const [listeRegions, setListeRegions] = useState([])

    useEffect(() => {
        /**
         * Get liste Secteurs
         */
        getListeSecteurs()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListeSecteurs(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            })
        /**
         * Get Liste Regions
         */
        getListeRegions()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListeRegions(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            })
    }, [])

    const traitement = (data) => {
        postObjectZones(data)
            .then((result) => {
                if (result.data.success === 0) {
                    toast(t('Zoning.Erreur'), {
                        type: "error",
                        position: toast.POSITION.TOP_RIGHT,
                    })
                } else if (result.data.success === 1) {
                    toast('Zoning.message', {
                        type: "success",
                        position: toast.POSITION.TOP_RIGHT,
                    })
                }
            }).catch((err) => {
                console.log(err)
            });
    }
    
    const saveData = () => {
        if (code === "" && libelle !== "") {
            var generateCode = libelle.slice(0, 3)
            setCode(generateCode + "-" + myRnId())
            const data = {
                code: generateCode + "-" + myRnId(),
                libelle: libelle
            }
            if (codeSecteur !== "") {
                Object.assign(data, {
                    secteur_code: codeSecteur
                })
            }
            if (codeRegion !== "") {
                Object.assign(data, {
                    region: codeRegion
                })
            }
            traitement(data)
        } else if (code !== "" && libelle !== "") {
            const data = {
                code: code,
                libelle: libelle
            }
            if (codeSecteur !== "") {
                Object.assign(data, {
                    secteur_code: codeSecteur
                })
            }
            if (codeRegion !== "") {
                Object.assign(data, {
                    region: codeRegion
                })
            }
            traitement(data)
        }
    }
    return (
        <div className="modal fade" id="exampleModal9" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-azure" id="example-Modal3"> <i className='icon icon-map mr-1' /> {t('Zoning.ajouterZone')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} /> </span>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className='row'>
                                <div className=" form-group col-6">
                                    <label className="form-control-label">{t('rapports.code')} <span className="text-danger">*</span></label>
                                    <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="form-control-label">{t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                    <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="mr-2 mt-1 mb-sm-1"> {t('dashboard.region')} <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codeRegion}
                                    onChange={(e) => {
                                        setcodeRegion(e.target.value)
                                    }}>
                                    <option value={0}> choisir </option>
                                    {listeRegions?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="mr-2 mt-1 mb-sm-1"> {t('Zoning.Secteur')} <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codeSecteur}
                                    onChange={(e) => {
                                        setCodeSecteur(e.target.value)
                                    }}>
                                    <option value={0}> choisir </option>
                                    {listeSecteurs?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer ">
                        <button type="button" className="btn btn-azure fs-12 " onClick={() => saveData()} > <i className='icon icon-cloud-download mr-1' />{t('rapports.enregistrer')} </button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" onClick={() => setShow(false)}> <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
