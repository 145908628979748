
import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Add Cadeaux
 * @param {*} data 
 * @returns 
 */
export async function addCadeaux(data) {
  const formData = new FormData();
  formData.append("image", data.image);
  formData.append("code", data.code);
  formData.append("libelle", data.libelle);
  formData.append("type", data.type);
  formData.append("a_la_prochaine", data.a_la_prochaine);
  formData.append("qte", data.qte);
  formData.append("date_debut", data.date_debut);
  formData.append("date_fin", data.date_fin);
  formData.append("user_code", data.user_code);
  formData.append("valeur", data.valeur);
  formData.append("avant_pause", data.avant_pause);
  formData.append("apres_pause", data.apres_pause);


  try {
    const response = await axios.post(url + "cadeaux/", formData, { headers: authHeader() }, {
      IsEssential: true
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getGroupedData() {
  try {
    const response = await axios.get(url + "cadeaux/grouped", { headers: authHeader() }, {
      IsEssential: true
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getListe() {
  try {
    const response = await axios.get(url + "cadeaux/", { headers: authHeader() }, {
      IsEssential: true
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * GET RESULTS ROULETTE
 * @returns 
 */
export async function getListeRoulette() {
  try {
    const response = await axios.get(url + "cadeaux/results", { headers: authHeader() }, {
      IsEssential: true
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * GET Cadeaux by CODE
 * @param {*} code 
 * @returns 
 */
export async function getCadeauxByCode(code) {
  try {
    const response = await axios.get(url + "cadeaux/code=" + code, { headers: authHeader() }, {
      IsEssential: true
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add 
 * @param {*} file 
 * @returns 
 */
export async function addCadeauxCSV(file) {
  try {
    let formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(url + "cadeaux/add/csv", formData, { headers: authHeader() }, {
      IsEssential: true
    });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return undefined
    }
    else if (error?.response?.status === 422) {
      return 422
    }
  }
}
/**
 * UPDATE CADEAUX BY Code
 * @param {*} code 
 * @returns 
 */

export async function updateCadeauxByCode(code, data) {
  const formData = new FormData();
  if (data.image !== undefined) {
    formData.append("image", data.image);
  }
  formData.append("code", code);
  formData.append("libelle", data.libelle);
  formData.append("type", data.type);
  formData.append("a_la_prochaine", data.a_la_prochaine);
  formData.append("qte", data.qte);
  formData.append("date_debut", data.date_debut);
  formData.append("date_fin", data.date_fin);
  formData.append("user_code", data.user_code);
  formData.append("valeur", data.valeur);
  formData.append("avant_pause", data.avant_pause);
  formData.append("apres_pause", data.apres_pause);

  try {
    const response = await axios.patch(url + "cadeaux/" + code, formData, { headers: authHeader() }, {
      IsEssential: true
    });
    return response;
  } catch (error) {
    if (error.response === undefined) {
      return undefined
    }
    else if (error?.response?.status === 422) {
      return 422
    }
  }
}
/**
 * Delete Casdeaux
 * @param {*} code 
 * @returns 
 */
export async function deleteCadeauxByCode(code) {
  try {
    const response = await axios.delete(url + "cadeaux/" + code, { headers: authHeader() }, {
      IsEssential: true
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}