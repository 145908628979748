import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SqaureLoader from '../../components/Loader/SqaureLoader';
import { partLineaireA } from '../../services/PartLineaire/partLineaireAPI';
import PLEnseigne from './PLEnseigne';
import PLGamme from './PLGamme';
import PLMagasin from './PLMagasin';
import PLmerch from './PLmerch';

export default function PartLineaireAnnuel() {
       //------ History -----------------
       const history = useHistory()
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //Loader 
    const [Loading, setLoading] = useState(true)
    const { year } = useParams('')
    const [dateYear, setdateYear] = useState(year);
    //Enseigne Precedent
    const [allEnseignes, setallEnseignes] = useState([])
    const [listeEnseignes, setlisteEnseignes] = useState([])
    const [listeEnseignesPres, setlisteEnseignesPres] = useState([])
    //Gamme Precedent
    const [allGamme, setallGamme] = useState([])
    const [listeGamme, setlisteGamme] = useState([])
    const [listeGammePresc, setlisteGammePresc] = useState([])
    //Magasins Precedent
    const [allMagasins, setallMagasins] = useState([])
    const [listeMagasins, setlisteMagasins] = useState([])
    const [listeMagasinsPresc, setlisteMagasinsPresc] = useState([])
    //Merch Precedent
    const [allMerch, setallMerch] = useState([])
    const [listeMerch, setlisteMerch] = useState([])
    const [listeMerchPresc, setlisteMerchPresc] = useState([])

    useEffect(()=>{
        partLineaireA(parseInt(dateYear)-1)
        .then((result) => {
            if(result?.data?.success === 1){
                const liste = groupBy(result.data.plEnseigne, "mois")
                setlisteEnseignesPres(liste)
                const liste1 = groupBy(result?.data?.plGamme,"mois")
                setlisteGammePresc(liste1)
                const liste2 = groupBy(result?.data?.plMagasin,"mois")
                setlisteMagasinsPresc(liste2)
                const liste3 = groupBy(result?.data?.plMerch,"mois")
                setlisteMerchPresc(liste3)
            }
        }).catch((err) => {
            console.log(err)
        })

        partLineaireA(dateYear).then((result) => {
            if(result?.data?.success === 1){
                const ensg = groupBy(result?.data.plEnseigne, "nom_enseigne")
                const x = Object.keys(ensg)
                const liste = groupBy(result.data.plEnseigne, "mois")
                setlisteEnseignes(liste)
                setallEnseignes(x)
                const gamme = groupBy(result?.data?.plGamme ,"gamme")
                const y = Object.keys(gamme)
                const liste1 = groupBy(result?.data?.plGamme,"mois")
                setallGamme(y)
                setlisteGamme(liste1)
                const magasin = groupBy(result?.data?.plMagasin ,"nompdv")
                const z = Object.keys(magasin)
                const liste2 = groupBy(result?.data?.plMagasin,"mois")
                setallMagasins(z)
                setlisteMagasins(liste2)
                const merch = groupBy(result?.data?.plMerch ,"nom_user")
                const a = Object.keys(merch)
                const liste3 = groupBy(result?.data?.plMerch,"mois")
                setallMerch(a)
                setlisteMerch(liste3)
            }
        }).catch((err) => {
           console.log(err) 
        }).finally(()=>{
            setLoading(false)
        })
    },[dateYear])

    const onChangeYear = (e) => {
        setLoading(true)
        setdateYear(e.target.value)
        partLineaireA(parseInt(e.target.value)-1)
        .then((result) => {
            if(result?.data?.success === 1){
                const liste = groupBy(result.data.plEnseigne, "mois")
                setlisteEnseignesPres(liste)
                const liste1 = groupBy(result?.data?.plGamme,"mois")
                setlisteGammePresc(liste1)
                const liste2 = groupBy(result?.data?.plMagasin,"mois")
                setlisteMagasinsPresc(liste2)
                const liste3 = groupBy(result?.data?.plMerch,"mois")
                setlisteMerchPresc(liste3)
            }
        }).catch((err) => {
            console.log(err)
        })

        partLineaireA(e.target.value).then((result) => {
            if(result?.data?.success === 1){
                const ensg = groupBy(result?.data.plEnseigne, "nom_enseigne")
                const x = Object.keys(ensg)
                const liste = groupBy(result.data.plEnseigne, "mois")
                setlisteEnseignes(liste)
                setallEnseignes(x)
                const gamme = groupBy(result?.data?.plGamme ,"gamme")
                const y = Object.keys(gamme)
                const liste1 = groupBy(result?.data?.plGamme,"mois")
                setallGamme(y)
                setlisteGamme(liste1)
                const magasin = groupBy(result?.data?.plMagasin ,"nompdv")
                const z = Object.keys(magasin)
                const liste2 = groupBy(result?.data?.plMagasin,"mois")
                setallMagasins(z)
                setlisteMagasins(liste2)
                const merch = groupBy(result?.data?.plMerch ,"nom_user")
                const a = Object.keys(merch)
                const liste3 = groupBy(result?.data?.plMerch,"mois")
                setallMerch(a)
                setlisteMerch(liste3)
            }
        }).catch((err) => {
           console.log(err) 
        });
        history.push({
            pathname :'/partlineaireAnnuel/'+e.target.value
        })
        setLoading(false)
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.pl')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> Part linéaire annuelle</li>
                </ol>
            </div>
            <div className='col-12'>
                <div className='row mb-3'>
                    <div className='col-4' />
                    <div className='col-4'>
                        <select name="annee" className="form-control select2 custom-select text-center" value={year} onChange={(e) => onChangeYear(e)}>
                            <option value={""}>{t('conventions.annee')}</option>
                            <option value={2022}>2022</option>
                            <option value={2021}>2021</option>
                            <option value={2020}>2020</option>
                            <option value={2019}>2019</option>
                            <option value={2018}>2018</option>
                        </select>
                    </div>
                    <div className='col-4' />

                </div>
                {Loading? <SqaureLoader />  : 
                <>
                   <div className='row'>
                    {/**
                 * PART LIN2AIRE PAR ENSEINGE 
                 */}
                    <PLEnseigne  t={t} dateYear={dateYear} allEnseignes={allEnseignes} listeEnseignes={listeEnseignes} listeEnseignesPres={listeEnseignesPres}/>
                </div>
                <div className='row'>
                    {/**
                 * PART LINEAIRE par  mAGASIN
                */}
                    <PLMagasin t={t} allMagasins={allMagasins} dateYear={dateYear} listeMagasins={listeMagasins} listeMagasinsPresc={listeMagasinsPresc}/>

                </div>
                <div className='row'>
                    {/**
                 * PART LINEAIRE PAR GAMME 
                 */}
                    <PLGamme t={t} allGamme={allGamme} dateYear={dateYear} listeGamme={listeGamme} listeGammePresc={listeGammePresc} />
                </div>
                <div className='row'>
                    {/**
                * PART LINEAIRE par MERCHANDISEUR
                */}
                    <PLmerch t={t} allMerch={allMerch} dateYear={dateYear} listeMerch={listeMerch} listeMerchPresc={listeMerchPresc} />

                </div>
                </>
                }
             
            </div>






        </div>
    )
}
