/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { getSelectProduits } from '../../../services/Produits/ProduitsAPI'
import { addExcelStockDepot, addStockDepot, getListeStockDepot, getStockDepot, updateStockDepot } from '../../../services/stock/stockAPI'
import { SearchItems } from '../../../utils/SearchUtils'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import SelectSearch, { fuzzySearch } from 'react-select-search'
import exemplaire from './Files/stock_depot_exemplaire.xlsx'
import AlerteStockDepot from './AlerteStockDepot'
import { useTranslation } from 'react-i18next'

export default function ListeStockDepot() {

    //---------- Reference ----------- 
    const inputEl = useRef("")
    const produits = useRef();
    //----------- LISTE -------------------
    const [listeStock, setlisteStock] = useState([])
    const [saveList, setSaveList] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [listeProduits, setListeProduits] = useState([])
    //------ Formulaire -----------
    const [codeproduit, setcodeproduit] = useState(0)
    const [code, setcode] = useState("")
    const [index, setIndex] = useState("")
    const [quantite, setQuantite] = useState(0)
    //---------SEARCH ------------------------------
    const [search, setSearch] = useState("")
    //-------- FILES ------------------------
    const [filename, setFileName] = useState("Choisir un fichier ")
    const [file, setfile] = useState("");
    const [ok, setOk] = useState(false)

    //----------- LOADER ----------------
    const [loading, setloading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(20);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeStock.slice(indexOfFirstPost, indexOfLastPost) : searchList.slice(indexOfFirstPost, indexOfLastPost)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {

        getSelectProduits().then((result) => {
            if (result?.data?.success === 1) {
                setListeProduits(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        /**
         * GET LISTE STOCK DEPOT
         */
        getListeStockDepot()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteStock(result?.data?.listeStock)
                    setSaveList(result?.data?.listeStock)
                }
                setloading(false)
            }).catch((err) => {
                console.log(err)
            });
    }, [currentPage])

    /**
     * UPLOAD FUNCTION : IMPORT FILE
     */
    const uploadFile = () => {
        addExcelStockDepot(file)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast("Données ajoutées avec succée ! ", { type: "success", position: 'top-right' })
                    getListeStockDepot()
                        .then((result) => {
                            if (result?.data?.success === 1) {
                                setlisteStock(result?.data?.listeStock)
                                setSaveList(result?.data?.listeStock)
                            }
                            setloading(false)
                        }).catch((err) => {
                            console.log(err)
                        });
                } else {
                    toast("Probléme d'ajout ,Veuillez vérifier vos données ! ", { type: "error", position: 'top-right' })
                }
            }).catch((err) => {
                console.log(err)
            });
    }


    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (inputEl.current.value !== "") {
            const newList = SearchItems(listeStock, inputEl.current.value)
            setSearchlist(newList)
        } else {
            setListeProduits(saveList)
        }
    }

    // Handle Change Famille
    const handleProduits = (...args) => {
        setcodeproduit(args[1].value)
    };
    //UPLOAD IMAGE WITH PREVIEW image Commerce
    const selectFile = (event) => {
        setFileName(event.target.files[0].name)
        if (event.target.files.length) {
            setfile(event.target.files[0])
        }
    }
    const save = () => {

        if (quantite !== "" && codeproduit !== 0) {
            const data = {
                quantite: quantite,
                produit_code: codeproduit,
            }
            getStockDepot(data?.produit_code).then((result) => {
                if(result?.data?.success===1){
                    updateStockDepot(data?.produit_code,{quantite: quantite})
                    .then((result) => {
                        if(result?.data?.success===1){
                            toast("Le stock depôt est mis à jour ",{type:"success",position:"top-right"})
                    }else {
                        toast("Erreur de chargement ",{type:"error",position:"top-right"})
                    }
                    }).catch((err) => {
                        console.log(err)
                    });
                }else if(result===422){
                    addStockDepot(data)
                .then((result) => {
                    if (result?.data?.success === 0) {
                        toast("Duplication information  ", { type: 'warning', position: 'top-right' })
                    } else if (result?.data.success === 1) {
                        toast(" Ajouté avec succées ", { type: 'success', position: 'top-right' })
                        getListeStockDepot()
                            .then((result) => {
                                if (result?.data?.success === 1) {
                                    setlisteStock(result?.data?.listeStock)
                                    setSaveList(result?.data?.listeStock)
                                }
                                setloading(false)
                            }).catch((err) => {
                                console.log(err)
                            });
                    }
                }).catch((err) => {
                    console.log(err)
                })
                }
            }).catch((err) => {
                console.log(err);
            });
      
        }
    }
    return (
        <div>
            {/** ----------------------------- Header Page Stock --------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('rapports.STOCK')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('stockdepot.stockdepot')}  </li>
                </ol>
            </div>
            <ToastContainer />
            {/** -----------------------------  SEARCH --------------------- */}
            <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                        <div className="panel panel-default active">
                            <div className="panel-heading " role="tab" id="headingOne">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <i className="icon icon-grid text-primary mr-2" /> {t('stockdepot.listestockdepot')}
                                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    <div className="form row no-gutters">
                                        <div className="col-5"></div>
                                        <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-4 mb-0">
                                            <input type="text" ref={inputEl} value={search}
                                                onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0"
                                                placeholder="Rechercher dans la liste ... " />
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-1 mb-0">
                                            <button
                                                className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16" >
                                                <i className="fe fe-search" />
                                            </button>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-2 mb-0">
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="br-tl-md-0 br-bl-md-0 btn btn-block btn-green fs-16"
                                                table="table-pdv"
                                                filename="liste_stockDepot"
                                                sheet="tablexls"
                                                buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            {/** Tableau Stock */}
                            <div className="table-responsive product-datatable">
                                <table
                                    id="table-pdv"
                                    className="table table-striped table-bordered "
                                >
                                    <thead className="text-center">
                                        <tr>
                                            <td colSpan={4} className="bg-purple text-white fs-12"> {t('stockdepot.totalstockdepot')}: {search.length < 1 ? listeStock.length : searchList.length} </td>

                                        </tr>
                                        <tr>
                                            <th className="w-15p  fs-12">{t('Produits.CODEPRODUIT')}</th>
                                            <th className="wd-15p fs-12">{t('rapportrupture.produit')}</th>
                                            <th className="wd-15p fs-12">{t('rapports.QUANTITE')}</th>
                                            <th className="wd-15p fs-12">{t('rapports.ACTION')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {currentListe?.map((items, keys) => (
                                            <tr key={keys} className="fs-12">
                                                <td >{items.produit_code} </td>
                                                <td >{items.produit} </td>
                                                <td >{items.quantite} </td>
                                                <td style={{ cursor: "pointer" }}>
                                                    <i className="  ti-trash text-danger" data-toggle="modal" data-target="#modal-notification" onClick={() => { setcode(items.produit_code); setIndex(keys) }} />
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="float-right">
                                    <PaginationTable
                                        postsPerPage={stocksPerPage}
                                        totalPosts={listeStock.length}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    {/** Import or ADD produits to  Stock */}
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-3 float-left"> {t('stockdepot.ajouterstockdepot')} </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label className="form-control-label"> {t('Produits.Quantite')} <span className="text-danger">*</span></label>
                                <input type="number" value={quantite} onChange={(e) => setQuantite(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">Produits  <span className="text-danger">*</span></label>
                                <SelectSearch
                                    options={listeProduits}
                                    search
                                    ref={produits}
                                    onChange={handleProduits}
                                    filterOptions={fuzzySearch}
                                    emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                    placeholder={t('Produits.choisirProduit')}
                                />
                            </div>
                            <hr />
                            <form>
                                <div className="form-group">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" onChange={(e) => selectFile(e)} name="file" />
                                        <label className="custom-file-label"> ... {filename} </label>
                                        <p className=" text-azure " > ... {filename} </p>

                                    </div>

                                </div>
                                <div className="form-group">
                                    <a className="text-primary" href={exemplaire} download> <u>{t('Produits.telechargerModele')} </u></a>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-primary col-6 " onClick={() => save()}><i className="ti-save mr-2" />  {t('Produits.Ajouter')} </button>
                            <button className="btn btn-success col-6 " onClick={() => uploadFile()}><i className="fa fa-file-excel-o mr-2" />   {t('Produits.Importer')}  </button>

                        </div>
                    </div>

                </div>
                {/*                 <ModalEditStock code={code} index={index} setOk={setOk} listeproduits={listeProduits} t={t}/>
 */}
            </div>/
            <AlerteStockDepot code={code} index={index} setOk={setOk} t={t} />
        </div>
    )
}
