/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { getlisteEnseigne } from '../../../services/Magasins/PdvApi'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useTranslation } from 'react-i18next'
import { SearchItems } from '../../../utils/SearchUtils'

export default function DetailsPdv() {
    const inputEl = useRef("")
    //--------- CODE -----------
    const { code } = useParams('')
    //--------- LISTE ET SEARCHLISTE --------
    const [liste, setliste] = useState([])
    const [saveList, setSaveList] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [search, setSearch] = useState([])

    //-------- LOADER ------------
    const [loading, setloading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(50);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? liste : searchList
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (inputEl.current.value !== "") {
            const newList = SearchItems(saveList, inputEl.current.value)
            setSearchlist(newList)
        } else {
            setliste(saveList)
        }
    }
    useEffect(() => {
        getlisteEnseigne(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.data)
                    setSaveList(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [])

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> Magasins </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> Détails Magasins </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Parametragemagasins')}
                        </a>
                        <a href="/pdv/listePdv" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-plus mr-1" />
                            {t('sidebar.listeMagasins')}
                        </a>
                        <a href="/pdv/ajouterPDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-docs mr-1" />
                            {t('sidebar.convention')}
                        </a>
                    </div>
                </div>
            </div>
            {loading ? <SqaureLoader /> : (
                <div className="card">

                    <div className="card-header">
                        <div className="card-title mt-3 float-left">{t('sidebar.listeMagasins')} </div>
                        <div className='float-right '>
                            <div className="search1 search-elements-sm  ">
                                <div className="form row no-gutters">
                                    <div className="form-group  mb-0">
                                        <input type="text" ref={inputEl} value={search}
                                            onChange={() => getSearchTerm()} className="form-control  text-primary fs-10" id="text1" placeholder={t('Magasins.chercherunmagasins')} />
                                    </div>
                                    <div className="form-group mt-3 ">
                                        <i className='ti-search text-azure ml-2  mr-2 ' />
                                    </div>
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-azure btn-pill fs-12 mt-0 "
                                        table="table-to-xls"
                                        filename="detailsMagasins"
                                        sheet="tablexls"
                                        buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card-body">

                        <div className="row">
                            <div className="table-responsive product-datatable" style={{ overflow: 'auto', height: '600px' }}>
                                <table className="table table-bordered " id="table-to-xls">
                                    <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                        <tr>
                                            <th className="w-15p fs-12" >{t('dashboard.Enseigne')}</th>
                                            <th className="wd-15p fs-12" >{t('rapports.code')}</th>
                                            <th className="wd-15p fs-12" >{t('dashboard.Magasin')}</th>
                                            <th className="wd-15p fs-12" >{t('Magasins.Assortiments')}</th>
                                            <th className="wd-15p fs-12" >{t('dashboard.region')}</th>
                                            <th className="wd-15p fs-12" >{t('rapportrupture.merch')}</th>
                                            <th className="wd-15p fs-12" >{t('rapportrupture.categorie')}</th>
                                        </tr>
                                    </thead>
                                    {loading ? <SqaureLoader /> : (
                                        <tbody>
                                            {currentListe?.map((items, keys) => (
                                                <tr key={keys} className="text-center">
                                                    <td className='fs-12'>
                                                        <i className='fa fa-stop mr-2' style={{ color: items.color !== null ? items.color : "#ff6600" }} />
                                                        <b>{items.nomenseigne}</b> </td>
                                                    <td className='fs-12'>
                                                        <a href={"/pdv/historique&code=" + items.code}><u className="text-primary">{items.code} </u></a>
                                                    </td>
                                                    <td className='fs-12'><a href={"/pdv/modifierPDV&code=" + items.code}> <u className="text-success fs-12"> {items.nompdv} </u> </a></td>
                                                    <td className='fs-12'> {items.sum_ass} </td>
                                                    <td className='fs-12'>{items.regions}</td>
                                                    <td className='fs-12'>{items.nom !== null ? items.nom : <h6 className="text-danger fs-12"> - </h6>}</td>
                                                    <td className='fs-12'>{items.categoriepdv}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    )}

                                </table>
                                {/*   <div className="float-right">
                                    <PaginationTable
                                        postsPerPage={stocksPerPage}
                                        totalPosts={liste.length}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
