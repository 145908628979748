import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { getSelectCategorie } from '../../services/Magasins/PdvApi';
import { getReminderByCode, updateMessages } from '../../services/Messages/MessagesAPI';
import { getSelctEnseignes } from '../../services/Produits/ProduitsAPI';
import { getListeUsers } from '../../services/Users/UsersApi';

export default function EditNote() {
    //URL PARAMS
    const { code } = useParams("")
    //PARAMETRAGE DATES
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")
    //-------- FORMULAIRE D AUTHENTIFICATION------------
    const { register, handleSubmit, reset, formState } = useForm();
    const { errors } = formState;
    //---------------- DETAILS FORMS -------------------
    const [codeUser, setcodeUser] = useState("")
    const [emetteur, setemetteur] = useState("")
    const [codeCategorie, setcodeCategorie] = useState("")
    const [listeUser, setlisteUser] = useState([])
    const [listeCategorie, setlisteCategorie] = useState([])
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [listeEnseignes, setlisteEnseignes] = useState([])
    // user state for form
    const [notes, setnotes] = useState({ objet: "", message: "", date_debut: "", date_fin: "", enseigne_code: "", categorie_code: "", recepteur: "" });
    //------------ REFERENCES --------------------------
    const searchInput = useRef();
    //----------Loading -----------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");



    useEffect(() => {

        setloading(true)
        /**
         * LISTE DES UTILISATEURS FORMAT (value , name )
         */
        getReminderByCode(code).then((result) => {
            if (result?.data?.success === 1) {
                var data = result?.data?.data[0]
                setemetteur(data?.nom_emetteur)
                setcodeUser(data?.recepteur)
                setcodeCategorie(data?.categorie_code)
                setcodeEnseigne(data?.enseigne_code)
                setTimeout(() => setnotes({ objet: data?.objet, message: data?.message, date_debut: moment(data?.date_debut).format("YYYY-MM-DD"), date_fin: moment(data?.date_fin).format("YYYY-MM-DD"), enseigne_code: data?.enseigne_code, categorie_code: data?.categorie_code, recepteur: data?.recepteur }), 1000);
            }


        }).catch((err) => {
            console.log(err)
        });

        getListeUsers().then((result) => {
            if (result?.data?.success === 1) {
                var liste = result?.data?.data
                liste.push({
                    value: "all",
                    name: "Tout les utilisateurs"
                })
                setlisteUser(liste)
            }
        }).catch((err) => {
            console.log(err)
        })
        /**
         * LISTE DES CATEGORIES FORMAT (value , name )
         */
        getSelectCategorie().then((result) => {
            if (result?.data?.success === 1) {
                setlisteCategorie(result?.data?.data)

            }
        }).catch((err) => {
            console.log(err)
        });
        /**
         * LISTE DES ENSEIGNES 
         */
        getSelctEnseignes().then((result) => {
            if (result?.data?.success === 1) {
                setlisteEnseignes(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {

            setloading(false)
        })

    }, [])

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(notes);
    }, [notes]);


    /**
     * SELECT SEARCH CHANGE ELEMENTS 
     */
    const handleChangeUser = (...args) => {
        setcodeUser(args[1].value)
        setnotes({recepteur :args[1].value})
    };
    const handleChangeCategorie = (...args) => {
        setcodeCategorie(args[1].value)
        setnotes({categorie_code:args[1].value})

    };
    const handleChangeEnseigne = (...args) => {
        setcodeEnseigne(args[1].value)
        setnotes({enseigne_code:args[1].value})
    };
    const saveData = (data) => {
        //console.log(data);
        updateMessages(code,data)
        .then((result) => {
            if(result?.data?.success === 1){
                toast(t('messageErreur.modifsuccee'), { type: "success", position: "top-right" })
            } else {
                toast(t('messageErreur.erreurdemodification'), { type: "error", position: "top-right" })
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item mt-2"><i className="icon icon-equalizer text-azure mr-1" /> {t('Ajouter un message')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href={"/messages/datedebut=" + date1 + "&datefin=" + date2} className='btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12 text-white'><i className="ti-list" /> Liste des messages</a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-2">{t('Ajouter un message')}</div>
                        </div>
                        <div className="card-body">
                            {loading ? <Loader /> : (
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <form className="form-horizontal" onSubmit={handleSubmit(saveData)}>
                                            <div className="form-group row">
                                                <label className="col-md-3 col-form-label">{t('rapports.code')}</label>
                                                <div className="col-md-9">
                                                    <input type="text"

                                                        className="form-control" value={code} disabled />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 col-form-label" htmlFor="example-email">{t('Catalogues.Objet')}</label>
                                                <div className="col-md-9">
                                                    <input type="text"
                                                        {...register('objet')} className="form-control" placeholder="Objet....." required />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 col-form-label">{t('Message')}</label>
                                                <div className="col-md-9">
                                                    <textarea type="text"
                                                        placeholder="messages .. "
                                                        className="form-control"  {...register('message')} required />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 col-form-label">{t('Magasins.Datedebut')}</label>
                                                <div className="col-md-9">
                                                    <input type="date" className="form-control" {...register('date_debut')} required />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 col-form-label">{t('Magasins.Datefin')}</label>
                                                <div className="col-md-9">
                                                    <input type="date" className="form-control"  {...register('date_fin')} required />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 col-form-label">{t('Recepteur')}</label>
                                                <div className="col-md-9">

                                                    <SelectSearch
                                                        options={listeUser}
                                                        search
                                                        value={codeUser}
                                                        ref={searchInput}
                                                        onChange={handleChangeUser}
                                                        filterOptions={fuzzySearch}
                                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                                        placeholder={t("Choisir un utilisateur")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 col-form-label">{t("Emetteur")}</label>
                                                <div className="col-md-9">
                                                    <input type="text" className="form-control" value={emetteur} disabled  />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 col-form-label">{t("rapportrupture.categorie")}</label>
                                                <div className="col-md-9">
                                                    <SelectSearch
                                                        options={listeCategorie}
                                                        search
                                                        value={codeCategorie}
                                                        ref={searchInput}
                                                        onChange={handleChangeCategorie}
                                                        filterOptions={fuzzySearch}
                                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                                        placeholder={t("Choisir une catégorie")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-3 col-form-label">{t("Produits.Enseigne")}</label>
                                                <div className="col-md-9">
                                                    <SelectSearch
                                                        options={listeEnseignes}
                                                        search
                                                        value={codeEnseigne}
                                                        ref={searchInput}
                                                        onChange={handleChangeEnseigne}
                                                        filterOptions={fuzzySearch}
                                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                                        placeholder={t("Promotions.ChoisirEnseigne")}
                                                    />
                                                </div>
                                            </div>
                                            <div className='card-footer'>
                                                <button className="btn btn-azure text-white mr-2 float-right" type='submit'><i className="ti-save mr-1" /> {t("rapports.enregistrer")}</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
