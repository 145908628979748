import React from 'react'
import { toast } from 'react-toastify';
import { editActifTournee } from '../../../services/Routings/RoutingsApi';

export default function EditDetailsTournee({ code, codepdv, setok, quota, setquota ,actif, setactif ,t , rang ,setrang}) {


    const editTournee = (code, codepdv) => {
        const data ={
            actif : actif,
            rang : rang
        }
        if(quota !== null ||quota !==""){
            Object.assign(data,{quota:quota})
        }
        editActifTournee(code, codepdv,data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('Routings.modifieSuccess'), { type: "success", position: "top-right" })
                } else {
                    toast(t('Routings.erreur'), { type: "error", position: "top-right" })
                }
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <div className="modal fade" id="modal" tabIndex={-1} role="dialog" aria-labelledby="modal" aria-hidden="true" >
            <div className="modal-dialog modal-danger" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal">{t('Routings.editer')+" : "+code}  </h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setok(true)}>
                            <span aria-hidden="true">x</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="py-3 text-center">
                        <h6 className="heading mt-4">Rang</h6>
                        <input type="number" className="form-control col-12  br-md-0" value={rang} onChange={(e)=>setrang(e.target.value)} />

                            <h6 className="heading mt-4">Quota</h6>
                            <input type="number" max={4} min={0} className="form-control col-12  br-md-0" value={quota} onChange={(e)=>setquota(e.target.value)} />
                            <h6 className="heading mt-4">{t('Routings.merchactif')}</h6>
                            <select value={actif} onChange={(e) => setactif(e.target.value)} className="form-control  custom-select col-12 br-md-0">
                                <option value={"1"}>{t('rapports.oui')}</option>
                                <option value={"0"}>{t('rapports.non')}</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => { editTournee(code, codepdv) }}> <i className="icon icon-check mr-1" /> {t('Routings.confirmer')}</button>
                        <button type="button" className="btn btn-secondary text-white ml-auto" data-dismiss="modal" onClick={() => setok(true)}><i className="icon icon-close mr-1" /> {t('Routings.annueler')} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
