import React from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import SqaureLoader from '../../components/Loader/SqaureLoader';

export default function ModalExporterProduits({ listeProduits, t, loading }) {
    return (
        <div className="modal fade" id="exportProduits" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-azure fs-12" id="ListePDV">{t('Produits.Listeproduits')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" /> </span>

                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="table-responsive  product-datatable" style={{ overflow: 'auto', height: '500px' }}>
                                <table className="table  table-bordered " id="liste_produits">
                                    <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >
                                        <tr>
                                            <th className="w-15p fs-10">{t('rapports.code')}</th>
                                            <th className="wd-15p fs-10">{t('Produits.CODEABARRE')}</th>
                                            <th className="wd-15p fs-10">{t('Produits.Produits')}</th>
                                            <th className="wd-15p fs-10">{t('rapports.prix')}</th>
                                            <th className="wd-15p fs-10">{t('Produits.SOCIETE')}</th>
                                            <th className="wd-15p fs-10">{t('codemarque')}</th>
                                            <th className="wd-15p fs-10">{t('Produits.MARQUE')}</th>
                                            <th className="wd-15p fs-10">{t('codefamille')}</th>
                                            <th className="wd-15p fs-10">{t('Produits.FAMILLE')}</th>
                                            <th className="wd-15p fs-10">{t('codeSFamille')}</th>
                                            <th className="wd-15p fs-10">{t('Produits.SousFamille')}</th>
                                            <th className="wd-15p fs-10">{t('codeGamme')}</th>
                                            <th className="wd-15p fs-10">{t('Produits.Gamme')}</th>
                                            <th className="wd-15p fs-10">{t('Produits.CodeMETI')}</th>
                                            <th className="fs-10" >{t('Produits.Ordre')}</th>
                                            <th className="fs-10" >{t('Produits.Colisage')}</th>
                                            <th className="fs-10" >{t('Produits.description')}</th>
                                            <th className="fs-10" >{t('Produits.Actif')}</th>

                                        </tr>
                                    </thead>
                                    {loading ? <SqaureLoader /> : (
                                        <tbody>
                                            {listeProduits?.map((items, keys) => (
                                                <tr key={keys} className="text-center pb-0 pt-0">
                                                    <td className='fs-10 '>  <a className='text-primary' href={"/produits/modifierProduits&id=" + items.code}>{items.code}  </a></td>
                                                    <td className='fs-10'>{items.code_a_barre} </td>
                                                    <td className='fs-10'>{items.libelle} </td>
                                                    <td className='fs-10'>{parseFloat(items.prix).toFixed(3)} </td>
                                                    <td className='fs-10'>{items.societe !== null ? items.societe : "Aucune"} </td>
                                                    <td className='fs-10'>{items.marque_code} </td>
                                                    <td className='fs-10'>{items.marques} </td>
                                                    <td className='fs-10'>{items.famille_code} </td>
                                                    <td className='fs-10'>{items.familles} </td>
                                                    <td className='fs-10'>{items.sousfamille_code} </td>
                                                    <td className='fs-10'>{items.sousfamilles} </td>
                                                    <td className='fs-10'>{items.gamme_code} </td>
                                                    <td className='fs-10'>{items.gammes} </td>
                                                    <td className='fs-10'>{items.code_meti === null ? <span className='text-danger'> - </span> : items.code_meti} </td>
                                                    <td className='fs-10'>{items.ordre} </td>
                                                    <td className='fs-10'>{items.colisage} </td>
                                                    <td className='fs-10'>{items.description !== null ? items.description : "Aucune"} </td>
                                                    <td className='fs-10'>{items.actif} </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    )}

                                </table>

                            </div>
                        </div>
                    </div>

                    <div className="modal-footer ">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-azure fs-10"
                            table="liste_produits"
                            filename="liste_produits"
                            sheet="tablexls"
                            buttonText={<span className=" text-center"> <i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')}</span>}
                        />
                        <button type="button" className="btn btn-secondary fs-10" data-dismiss="modal" > <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
