/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { getMagasinsRoutings, updateRoutingMerch } from '../../../services/Routings/RoutingsApi'
import { getMerch } from '../../../services/Users/UsersApi'
import { SearchItems } from '../../../utils/SearchUtils'
import MapRoutes from './MapRoutes'

export default function DetailsRoutes() {
    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
    //Paramétres 
    const { code } = useParams('')
    //---------- LISTE Routes -------------
    //USE REF
    const inputEl = useRef("")
    const [listeMagasins, setlisteMagasins] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    const [listeMerch, setlisteMerch] = useState([])
    const [codeMerch, setcodeMerch] = useState("")
    const [index, setindex] = useState("")
    //Search 
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(10);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeMagasins?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)
    //Editer Route 
    const [ok, setOk] = useState(false)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var today = moment(date).format("YYYY-MM-DD")
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")

    useEffect(() => {
        getMagasinsRoutings(code)
            .then((result) => {
                setlisteMagasins(result?.data?.data)
            }).catch((err) => {
                console.log(err)
            })
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteMerch(result?.data?.data)
                }
            }).catch((err) => {

            });
        setSaveList(listeMagasins)
        setLoading(false)
    }, [])

    const onChangeRouteByMerch = () => {
        //Change route for
        if (codeMerch !== "") {
            const data = {
                user_code: codeMerch,
                routing_code: code
            }
            updateRoutingMerch(data)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        toast(t('messageErreur.modifsuccee'), { type: 'success', position: 'top-right' })
                        setOk(false)
                    } else {
                        toast(t('messageErreur.erreurdemodification'), { type: "error", position: 'top-right' })
                    }
                }).catch((err) => {

                });
        }
    }

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeMagasins, search)
            setSearchlist(newList)
        } else {
            setlisteMagasins(saveList)
        }
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {('rapports.routes')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Routings.detailsRoutes')}: {code} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href={'/routings/tournee&datedebut='+date1+'&datefin='+date2} className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="fe fe-download-cloud " />
                            {t('Routings.Tournee')}
                        </a>
                        {details?.role_code !== "chef" ?
                            <>
                                <a href="/routings/routings&route=-1&region=-1" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                                    <i className=" icon icon-plus mr-1" />
                                    {t('Routings.CreationTournee')}
                                </a>
                                <a href="/routings/TourneeZone" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                                    <i className="icon icon-event mr-1" />
                                    {t('Routings.creationAgenda')}
                                </a>
                            </>
                            : <a href="/routings/validationTournee" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                                <i className=" icon icon-plus mr-1" />
                                {t('rapports.validationTournee')}
                            </a>}
                        <a href="/routings/routes" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="fe fe-download-cloud  mr-1" />
                            {t('Routings.Route')}
                        </a>
                    </div>
                </div>
            </div>
            {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" />{t('Routings.listemagasinsroutes')} : {code}
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">

                            <div className="search1 search-elements-sm mb-4 ">
                                <div className="form row no-gutters">
                                    <div className="col-6"></div>
                                    <div className="form-group  col-xl-5 col-lg-5 col-md-5 mb-0">
                                        <input type="text" ref={inputEl} value={search}
                                            onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary" id="text1" placeholder="Rechercher un magasin" />
                                    </div>

                                    <div className="col-xl-1 col-lg-1 col-md-1 mb-0">
                                        <a href="#" className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"><i className="icon icon-magnifier" /></a>
                                    </div>
                                </div>
                            </div>
                            <h6 className=" mt-2 text-muted" style={{ fontSize: "12px" }}>  <span className="text-info"> <i className="icon icon-exclamation mr-1" /> Information : </span> Pour modifier la route du magasin , il suffit de cliquer sur l'icone <i className="icon icon-note text-warning" /> </h6>
                        </div>
                    </div>
                </div>
            </div>

            {listeMagasins?.length === 0 ? (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                        <div className="alert bg-purple mb-5 py-4" role="alert">
                            <div className="d-flex">
                                <i className="icon icon-info mr-3 fs-30"></i>
                                <div>
                                    <h4 className="alert-heading"> {t('Routings.AucunMagasin')} </h4>
                                    <p>{t('Routings.aucunemagasinroutes')} : {code} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-md-12 col-xl-7 col-lg-7 col-sm-12 col-12">
                        <div className="card">
                            <div className="table-responsive product-datatable">
                                <table id="example" className="table table-striped table-bordered " >
                                    <thead className="text-center">
                                        <tr className="bg-purple">
                                            <td colSpan="4"> {t('Magasins.Totalmagasins')} {listeMagasins?.length}</td>

                                        </tr>
                                        <tr>
                                            <th className="w-15p">{t('rapports.code')}</th>
                                            <th className="wd-15p">{t('dashboard.Magasin')}</th>
                                            <th className="wd-15p">{t('map')}</th>
                                            <th className="wd-15p">{t('changerMerch')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentListe?.map((items, keys) =>
                                        (<tr key={keys} className='text-center'>
                                            <td className="w-15p">{items.code}</td>
                                            <td className="wd-15p">{items.nompdv}</td>
                                            <td className="wd-15p">
                                                <i className="ion-location  fa-2x" style={{ color: items.color !== null ? items.color : "#ff6600" }} />
                                            </td>
                                            <td className="wd-15p text-center">
                                                {ok && index === keys ? (
                                                    <div className="form row no-gutters text-center">
                                                        <div className="form-group text-center">
                                                            <select className="form-control text-center" value={codeMerch} onChange={(e) => setcodeMerch(e.target.value)}>
                                                                <option value={1} selected>{t('dashboard.Magasin')}</option>
                                                                {listeMerch?.map((items, keys) => (
                                                                    <option key={keys} value={items.value}>{items.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <button className='btn btn-primary' onClick={() => onChangeRouteByMerch()} > <i className="ti-save" /> </button>
                                                        </div>
                                                    </div>) : (<i className="icon icon-note text-warning" onClick={() => { setOk(true); setindex(keys) }} />)}
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                                <div className="card-footer">
                                    <div className="float-center">
                                        <PaginationTable
                                            postsPerPage={stocksPerPage}
                                            totalPosts={search.length < 1 ? listeMagasins?.length : searchList?.length}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-xl-5 col-lg-5 col-sm-12 col-12">
                        <MapRoutes listemagasins={listeMagasins} loading={loading} t={t} />
                    </div>
                </div>
            )}
        </div>


    )
}
