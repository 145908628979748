/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../../components/Loader/SqaureLoader'
import PaginationTable from '../../../../components/Pagination/PaginationTable'
import { getListeRoles, getRolebyCode } from '../../../../services/Users/UsersApi'
import { SearchItems } from '../../../../utils/SearchUtils'
import ModalAddRoles from './ModalAddRoles'
import ModalEdIitRole from './ModalEdIitRole'

export default function ListeRoles({t}) {

    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    //------- FORMULAIRE ---------
    const [listeRoles, setlisteRoles] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //Loader 
    const [Loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(5);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeRoles?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)

    //Edit Items 
    const [show, setShow] = useState(true)
    const [showEdit, setShowEdit] = useState(false)
    const [index, setIndex] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState("")
    //MODAL  IMPROT EXCEL
    const [ok, setOk] = useState(false)


    useEffect(() => {
        setLoading(true)
        getListeRoles()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteRoles(result?.data?.data)
                    setSaveList(result?.data.data)

                }
            }).catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    //ONChange 
    useEffect(() => {
        if (show === false) {
            getListeRoles()
            .then((result) => {
                    if (result?.data?.data !== null) {
                        setLoading(true)

                        setlisteRoles(result?.data?.data)
                        setSaveList(result?.data?.data)
                    }
            }).catch((err) => {
                console.log(err)
             })
            .finally(() => {
                setLoading(false)
            })
        }
    }, [show])
    const changedetaiils =  (code) =>{
        getRolebyCode(code)
        .then((result) => {
            if(result?.data?.success===1) {
                setObject(result?.data?.data[0])
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeRoles, search)
            setSearchlist(newList)
        } else {
            setlisteRoles(saveList)
        }
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-12 col-lg-12">

                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-3 float-left">{t('users.listeroles')}</div>
                            <button className="btn btn-azure float-right" data-toggle="modal" data-target="#addRoles"> 
                            <i className="icon icon-plus" />
                            </button>

                        </div>
                        <div className="card-body">
                            <div className="search1 search-elements-sm mb-4 ">
                                <div className="form row no-gutters">
                                    <div className="col-4"></div>
                                    <div className="form-group  col-xl-6 col-lg-6 col-md-6 mb-0">
                                        <input type="text" ref={inputEl} value={search}
                                            onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary" id="text1" placeholder="Chercher un rôle" />
                                    </div>

                                    <div className="col-xl-2 col-lg-2 col-md-2 mb-0">
                                        <a href="#" className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"><i className="icon icon-magnifier" /></a>
                                    </div>
                                </div>
                            </div>
                            {Loading ? (
                                <SqaureLoader />
                            ) : (
                                <div className="table-responsive product-datatable">
                                    <table
                                        className="table table-striped table-bordered "
                                    >
                                        <thead className="text-center">
                                            <tr>
                                                <th style={{ fontSize: "12px" }} className="w-15p">{t('rapports.code')}</th>
                                                <th style={{ fontSize: "12px" }} className="wd-15p">{t('dashboard.Libelle')}</th>
                                                <th style={{ fontSize: "12px" }} className="wd-15p">{t('rapports.ACTION')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {currentListe?.map((items, keys) => (
                                                <tr key={keys}>
                                                    <td style={{ fontSize: "12px" }}>{items.code} </td>
                                                    <td style={{ fontSize: "12px" }}>{items.libelle} </td>
                                                    <td style={{ cursor: "pointer" }}>
                                                        <a data-toggle="modal" data-target="#modal-editer" onClick={() => {
                                                        changedetaiils(items.code); setCode(items.code); setIndex(keys); setShowEdit(true)
                                                        }}> <i className="icon icon-note text-warning" /></a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <ToastContainer />
                                    <div className="float-right">
                                        <PaginationTable
                                            postsPerPage={stocksPerPage}
                                            totalPosts={search.length < 1 ? listeRoles?.length : searchList?.length}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>
                    <ModalAddRoles setShow={setShow} t={t} />
                    <ModalEdIitRole  code ={code} t={t} setShow={setShow} keys={index} setOk={setOk} object={object} /> 

                </div>

            </div>
        </div>
    )
}
