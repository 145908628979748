import React, { useState } from 'react'
import { toast } from 'react-toastify'
// ------- WEB SERVICE ---------
import {postObjectSecteurs} from "../../../services/Zoning/ZoningApi"
import { myRnId } from '../../../utils/DateUtils'

export default function ModalAddSecteurs({ setShow ,t }) {

    //------ Formulaire -----------
    const [code, setCode] = useState("")
    const [libelle, setlibelle] = useState("")
    
    const traitement = (data)=>{
        postObjectSecteurs(data)
        .then((result) => {
            console.log(result.data.success)
            if (result.data.success === 0 ){
                toast(t('Zoning.Erreur'), {
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT,
                  })
            }else if (result.data.success === 1){
                toast(t('Zoning.messageSecteur'), {
                    type: "success",
                    position: toast.POSITION.TOP_RIGHT,
                  })
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const saveData = () => {
        if (code === "" && libelle !== "") {
            var generateCode = libelle.slice(0, 3)
            setCode(generateCode + "-" + myRnId())
            const data = {
                code: generateCode + "-" + myRnId(),
                libelle: libelle
            }
            traitement(data)
        }  
         else if(code !== "" && libelle !== ""){
            const data= {
                code : code,
                libelle : libelle
            }
            traitement(data)
        }
    }
    return (
        <div className="modal fade" id="exampleModal8" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-azure" id="example-Modal3"> <i className='icon icon-map text-azure mr-2' /> {t('Zoning.ajoutersecteur')} </h5>
                    <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} /> </span>

                </div>
                <div className="modal-body">
                    <form>
                        <div className="form-group">
                            <label className="form-control-label">{t('rapports.code')}  <span className="text-danger">*</span></label>
                            <input type="text" value={code} onChange={(e)=>setCode(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label className="form-control-label">{t('Produits.Libelle')} <span className="text-danger">*</span></label>
                            <input type="text" value={libelle} onChange={(e)=>setlibelle(e.target.value)} className="form-control" />
                        </div>
                       
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button"  className="btn btn-azure fs-12 " onClick={()=>saveData()} >  <i className='icon icon-cloud-download mr-1' /> Enregister </button>
                    <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" onClick={()=>setShow(false)}> <i className="ti-close mr-1" />Fermer</button>

                </div>
            </div>
        </div>
    </div>
    )
}
