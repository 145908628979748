/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { getConventions, getSelectCategorie, searchConvention, typeConvention } from '../../../services/Magasins/PdvApi'
import { getSelctEnseignes } from '../../../services/Produits/ProduitsAPI'
//import ModalDeleteConvention from './ModalDeleteConvention'

export default function ListeConventions() {

    //Search 
    const [codeCategorie, setcodeCategorie] = useState("")
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [type, setType] = useState("")
    const [onsearch, setOnSearch] = useState(false)
    //Liste
    const [total, setTotal] = useState(0)
    const [totalSearch, settotalSearch] = useState(0)
    const [listeConventions, setlisteConventions] = useState([])
    const [saveConvention, setsaveConvention] = useState([])
    const [searchList, setsearchList] = useState([])
    //------- FILTRE -----------------
    const [listeType, setlisteType] = useState([])
    const [enseigne, setenseigne] = useState([])
    const [categorie, setcategorie] = useState([])
    //Loading 
    const [loading, setloading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [stocksPerPage, setStockPerPage] = useState(50);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;

    const currentListe = onsearch ? searchList?.slice(indexOfFirstPost, indexOfLastPost) : listeConventions?.slice(indexOfFirstPost, indexOfLastPost)

    //Modal items
    const [show, setShow] = useState(true)
    const [index, setIndex] = useState(-1)
    const data = {}
    //--------------------SETTINGS ----------------------
    const [settings, setsettings] = useState(false)
    const [show1, setshow1] = useState(true)
    const [show2, setshow2] = useState(true)
    const [show3, setshow3] = useState(true)
    const [show4, setshow4] = useState(true)
    const [show5, setshow5] = useState(true)
    const [show6, setshow6] = useState(true)
    const [show7, setshow7] = useState(true)
    const [show8, setshow8] = useState(true)
    const [show9, setshow9] = useState(true)
    const [show10, setshow10] = useState(true)

    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    // 
    useEffect(() => {

        typeConvention()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteType(result?.data?.type)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectCategorie()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setcategorie(result?.data?.data)
                    console.log(result?.data?.data);
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setenseigne(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)

            });

        getConventions()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteConventions(result?.data?.convention)
                    setsaveConvention(result?.data?.convention)
                    setTotal(result?.data?.totalConventions?.total)

                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })



    }, [show])



    const onBackToFirstPage = () => {
        setlisteConventions(saveConvention)
        setCurrentPage(1)
        setOnSearch(false)
    }

    const searchFunction = () => {

        setloading(true)
        if (codeEnseigne !== "") {
            Object.assign(data, {
                enseigne_code: codeEnseigne
            })
        }
        if (codeCategorie !== "") {
            Object.assign(data, {
                categorie_code: codeCategorie
            })
        }
        if (type !== "") {
            Object.assign(data, {
                type: type
            })
        }
        searchConvention(data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setOnSearch(true)
                    setloading(true)
                    setsearchList(result?.data?.searchConvention)
                    settotalSearch(result?.data?.totalConventions?.total)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })

    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.Magasin')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('conventions.convention')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Parametragemagasins')}
                        </a>
                        <a href="/pdv/ajouterPDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-plus mr-1" />
                            {t('Magasins.AjouterPDV')}
                        </a>


                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="card">
                <div className="card-header">
                    <div className="card-title mt-2">
                        <div className="float-left">
                            <h5 className="fs-12 mt-2"  > <i className="icon icon-docs  text-azure mr-2" /> {t('Magasins.Listedesconventions')} </h5>
                        </div>

                        <div className="float-right">
                            <a href='/conventions/ajouterconvention' className="btn btn-azure text-white mr-1"  > <i className="icon icon-plus text-white" />  </a>
                            {/*                             <a href='/conventions/ajouterconventions' className="btn btn-azure text-white"  > <i className="ti-settings text-white" /> </a>
 */}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="input-group mb-2">
                        <select name="country" id="select-countries1" value={type} onChange={(e) => setType(e.target.value)} className="form-control custom-select fs-12 br-md-0">
                            <option>---- {t('dashboard.type')} -----</option>
                            <option value={"rupture"}>Rupture</option>
                            <option value={"pl"}>Part Linéaire</option>
                            <option value={"promotion"}>Promotion</option>
                            <option value={"gratuite"}>Gratuité</option>
                            <option value={"dn"}>DN</option>
                            {/* 
                                  {/*   {listeType?.map((items, keys) => (
                                        <option value={items.type}> {items.type}</option>
                                    ))} */}
                        </select>
                        <select name="country" id="select-countries2" value={codeEnseigne} onChange={(e) => setcodeEnseigne(e.target.value)} className="form-control  fs-12 custom-select br-md-0">
                            <option value={""} >{t('rapports.Enseignes')}</option>
                            {enseigne?.map((items, keys) => (
                                <option value={items.value}> {items.name} </option>
                            ))}
                        </select>
                        <select name="country" id="select-countries2" value={codeCategorie} onChange={(e) => setcodeCategorie(e.target.value)} className="form-control fs-12  custom-select br-md-0">
                            <option value={""} >Cateogrie</option>
                            {categorie?.map((items, keys) => (
                                <option value={items.value}> {items.name} </option>
                            ))}
                        </select>
                        <span className="input-group-append">
                            <button className="btn btn-primary" type="button" onClick={() => searchFunction()}><i className='ti-search' /></button>
                            <button className="btn btn-yellow" type="button" onClick={() => onBackToFirstPage()}><i className='ti-close' /></button>

                        </span>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <button className="btn btn-azure  mb-3 btn-pill fs-12 mt-0  float-right  " onClick={() => setsettings(!settings)}><i className='ti-filter'/> </button>

                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-azure btn-pill fs-12 mt-0 float-right "
                                table="table-to-xls"
                                filename="Rapport_Suivi_Trournee"
                                sheet="tablexls"
                                buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                            />
                        </div>
                    </div>
                    {settings ?
                        <div className='row'>
                            <div className="selectgroup selectgroup-pills">
                                <label className="selectgroup-item">
                                    <input type="checkbox" value={show1}
                                checked={show1}
                                onChange={(e) => setshow1(e.target.checked)} className="selectgroup-input"  />
                                    <span className="selectgroup-button">{t('conventions.convention')}</span>
                                </label>
                                <label className="selectgroup-item">
                                    <input type="checkbox"  value={show2}  checked={show2}
                                onChange={(e) => setshow2(e.target.checked)} className="selectgroup-input" />
                                    <span className="selectgroup-button">{t('rapports.Enseignes')}</span>
                                </label>
                                <label className="selectgroup-item">
                                    <input type="checkbox"  value={show3}
                                checked={show3}
                                onChange={(e) => setshow3(e.target.checked)}  className="selectgroup-input" />
                                    <span className="selectgroup-button">{t('rapportrupture.produit')}</span>
                                </label>
                                <label className="selectgroup-item">
                                    <input type="checkbox" value={show4}
                                checked={show4}
                                onChange={(e) => setshow4(e.target.checked)}className="selectgroup-input" />
                                    <span className="selectgroup-button">{t('rapportrupture.categorie')}</span>
                                </label>
                                <label className="selectgroup-item">
                                    <input type="checkbox" value={show5}
                                checked={show5}
                                onChange={(e) => setshow5(e.target.checked)} className="selectgroup-input" />
                                    <span className="selectgroup-button">{t('Magasins.Type')}</span>
                                </label>
                                <label className="selectgroup-item">
                                    <input type="checkbox"value={show6}
                                checked={show6}
                                onChange={(e) => setshow6(e.target.checked)}className="selectgroup-input" />
                                    <span className="selectgroup-button">{t('Produits.Marque')}</span>
                                </label>
                                <label className="selectgroup-item">
                                    <input type="checkbox"value={show7}
                                checked={show7}
                                onChange={(e) => setshow7(e.target.checked)} className="selectgroup-input" />
                                    <span className="selectgroup-button">{t('dashboard.Gamme')}</span>
                                </label>
                                <label className="selectgroup-item">
                                    <input type="checkbox" value={show8}
                                checked={show8}
                                onChange={(e) => setshow8(e.target.checked)} className="selectgroup-input" />
                                    <span className="selectgroup-button">{t('Produits.FAMILLE')}</span>
                                </label>
                                <label className="selectgroup-item">
                                    <input type="checkbox" value={show9}
                                checked={show9}
                                onChange={(e) => setshow9(e.target.checked)} className="selectgroup-input" />
                                    <span className="selectgroup-button">{t('Produits.SousFamille')}</span>
                                </label>
                                <label className="selectgroup-item">
                                    <input type="checkbox" value={show10}
                                checked={show10}
                                onChange={(e) => setshow10(e.target.checked)} className="selectgroup-input" />
                                    <span className="selectgroup-button">{t('rapports.ACTION')}</span>
                                </label>
                            </div>

                         


                        </div>
                        : null}
                    <div className="row">
                        <table id="table-to-xls" className="table  table-bordered " >
                            <thead className="text-center">
                                <tr>
                                    <td colSpan="11" className=" text-azure fs-12" > Total Conventions : {onsearch ? totalSearch : total} .  Dans la  page : {currentPage} , {t('conventions.convention')} Par page : {stocksPerPage} </td>
                                </tr>
                                <tr className="bg-azure-lighter ">
                                    <th className='fs-10'>#</th>
                                    {show1 ? <th className='fs-10'>{t('conventions.convention')}</th> : null}
                                    {show2 ? <th className='fs-10'>{t('rapports.Enseignes')}</th> : null}
                                    {show3 ? <th className='fs-10'>{t('rapportrupture.produit')}</th> : null}
                                    {show4 ? <th className='fs-10'>{t('rapportrupture.categorie')}</th> : null}
                                    {show5 ? <th className='fs-10'>{t('Magasins.Type')}</th> : null}
                                    {show6 ? <th className='fs-10'>{t('Produits.Marque')}</th> : null}
                                    {show7 ?<th className='fs-10'>{t('dashboard.Gamme')}</th>: null}
                                    {show8 ?<th className='fs-10'>{t('Produits.FAMILLE')}</th>: null}
                                    {show9 ?<th className='fs-10'>{t('Produits.SousFamille')}</th>: null}
                                    {show10 ? <th className='fs-10'>{t('rapports.ACTION')}</th>: null}

                                </tr>
                            </thead>
                            {loading ? <SqaureLoader /> : (
                                <tbody>
                                    {currentListe?.map((items, keys) => (
                                        <tr className="text-center" key={keys}>
                                            <td className='fs-10'> {currentPage === 1 ? keys + 1 : keys + ((stocksPerPage + 1) * (currentPage - 1))}</td>
                                            {show1 ? <td className='fs-10'> {items.convention}</td> : null}
                                            {show2 ? <td className='fs-10'> {items.enseigne}</td> : null}
                                            {show3 ?<td className='fs-10'> {items.produits}</td>: null}
                                            {show4 ? <td className='fs-10'> {items.categorie}</td>: null}
                                            {show5 ? <td className='fs-10'> {items.type}</td>: null}
                                            {show6 ? <td className='fs-10'> {items.marque_code !== null ? items.marque_code : <span > - </span>}</td>: null}
                                            {show7 ? <td className='fs-10'> {items.gamme_code !== null ? items.gamme_code : <span > - </span>}</td>: null}
                                            {show8 ? <td className='fs-10'> {items.famille_code !== null ? items.famille_code : <span > - </span>}</td>: null}
                                            {show9 ? <td className='fs-10'> {items.sousfamille_code !== null ? items.sousfamille_code : <span > - </span>}</td>: null}
                                            {show10 ? <td className="text-center fs-10">
                                                <a href={"/conventions/code=" + items.code}><i className="icon icon-list text-info mr-2" /></a>
                                                <a href={"/editConvention/code=" + items.code} ><i className="icon icon-note text-yellow mr-2" /></a>
                                                {/* <i className="icon icon-trash text-danger"  data-toggle="modal" data-target="#smallModal" style={{cursor:"pointer"}} onClick={()=>{setShow(true);setIndex(items.code)}}/> */}
                                            </td>: null}
                                        </tr>
                                    ))}

                                </tbody>

                            )}
                        </table>
                        <div className="float-right">
                            <PaginationTable
                                postsPerPage={stocksPerPage}
                                totalPosts={onsearch ? totalSearch : total}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/*    {show?             
            <ModalDeleteConvention code={index} setShow={setShow} t={t}/>:null} */}
        </div>
    )
}
