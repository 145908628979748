/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getPL } from '../../../services/Rapports/RapportsApi'
import _ from 'lodash'
import { getMerch } from '../../../services/Users/UsersApi'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { getSelctEnseignes } from '../../../services/Produits/ProduitsAPI'
import { getSelectMagasins } from '../../../services/Magasins/PdvApi'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { getSelectZone } from '../../../services/Zoning/ZoningApi'
import { dayDifferenceBetweenDates } from "../../../utils/DateUtils"
import { useTranslation } from 'react-i18next';

export default function RapportTableauPL() {
    const { datedebut, datefin } = useParams('')
    //------ History -----------------
    const history = useHistory()
    // Formulaire
    const [today, settoday] = useState(moment(datedebut).format('YYYY-MM-DD'))
    const [fin, setfin] = useState(moment(datefin).format('YYYY-MM-DD'))
    //--------- LISTE USERS --------------------
    const [listeUsers, setlisteUsers] = useState([])
    const [codeUser, setcodeUser] = useState("")
    const [calculDays, setcalculDays] = useState(0);
    const [listeHeaders, setlisteHeaders] = useState([])
    const [liste, setliste] = useState([])
    const [saveListe, setsaveListe] = useState([])
    const [totalResults, settotalResults] = useState([])
    const [savetotalR, setsavetotalR] = useState([])
    const [listeEnseignes, setlisteEnseignes] = useState([])
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [listePdv, setlistePdv] = useState([])
    const [codePDV, setcodePDV] = useState("")
    const [listeZones, setlisteZones] = useState([])
    const [codeZone, setcodeZone] = useState("")
    const searchInput = useRef();
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    const handleChangeEnseigne = (...args) => {
        setcodeEnseigne(args[1].value)
        const l1 = liste?.filter((element) => element.enseigne_code === args[1].value)
        const l2 = totalResults?.filter((element) => element.enseigne_code === args[1].value)
        setliste(l1)
        settotalResults(l2)
    };
    const handleChangePdv = (...args) => {
        setcodePDV(args[1].value)
        const l1 = liste?.filter((element) => element.pdv_code === args[1].value)
        const l2 = totalResults?.filter((element) => element.pdv_code === args[1].value)
        setliste(l1)
        settotalResults(l2)
    };
    const handleChangeZone = (...args) => {
        setcodeZone(args[1].value)
        const l1 = liste?.filter((element) => element.zone_code === args[1].value)
        const l2 = totalResults?.filter((element) => element.zone_code === args[1].value)
        setliste(l1)
        settotalResults(l2)
    };

    const traitement = () => {
        getPL(today, fin)
            .then((result) => {
                if (result?.data?.success === 1) {
                    const l = _.groupBy(result?.data?.totalResult, 'gamme_code')
                    setliste(result?.data?.listePL)
                    setsaveListe(result?.data?.listePL)
                    settotalResults(result?.data?.totalResult)
                    setsavetotalR(result?.data?.totalResult)
                    const data = []
                    result?.data?.listeGammes?.map((items) => {
                        data.push(
                            {
                                code: items.code,
                                gamme: items.gamme,
                                marque1: result?.data?.headerTable.marque1,
                                marque2: result?.data?.headerTable.marque2,
                                marque3: result?.data?.headerTable.marque3,
                                societe: result?.data?.headerTable.societe,
                                pltotal: l[items.code] !== undefined ? l[items.code][0]?.total_pltotal : 0
                            }
                        )
                    })
                    setlisteHeaders(data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }
    useEffect(() => {
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnseignes(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectMagasins()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlistePdv(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectZone().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getMerch().then((result) => {
            if (result?.data?.success === 1) {
                setlisteUsers(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });

        traitement()

    }, [])

    const findFromListeElement = (codegamme, total) => {
        const l = liste?.filter((element) => element.gamme_code === codegamme)
        return l.length !== 0 ? l.map((items, keys) => (
            <tr key={keys} className='text-center'>
                <td>{items.familles} </td>
                <td>{items.plconstate !== 0 ? <span className='text-azure'><b>{calculDays !== 0 ? parseFloat(items.plconstate / calculDays).toFixed(2) : parseFloat(items.plconstate).toFixed(2)}</b></span> : 0} </td>
                <td> {items.plconstate !== 0 ? <span className='text-azure'><b>{calculDays !== 0 ? parseFloat(items.plmarque1 / calculDays).toFixed(2) : parseFloat(items.plmarque1).toFixed(2)}</b></span> : 0} </td>
                <td>{items.plconstate !== 0 ? <span className='text-azure'><b>{calculDays !== 0 ? parseFloat(items.plmarque2 / calculDays).toFixed(2) : parseFloat(items.plmarque2).toFixed(2)}</b></span> : 0}  </td>
                <td>{items.plmarque3 !== 0 ? <span className='text-azure'><b>{calculDays !== 0 ? parseFloat(items.plmarque3 / calculDays).toFixed(2) : parseFloat(items.plmarque3).toFixed(2)}</b></span> : 0}  </td>
                <td>{items.pltotal !== 0 ? <span className='text-azure'><b>{calculDays !== 0 ? parseFloat(items.pltotal / calculDays).toFixed(2) : parseFloat(items.pltotal).toFixed(2)}</b></span> : 0} </td>
                <td><span className='text-azure'> <b> {calculDays !== 0 ? parseFloat(((items.pltotal / calculDays) / total) * 100).toFixed(2) : parseFloat(((items.pltotal) / total) * 100).toFixed(2)} % </b></span> </td>

            </tr>
        )) : <tr>
            <td colSpan={7} className='text-center'> <span className='text-danger'> {t('Zoning.Aucunresultat')}</span> </td>
        </tr>
    }
    const totalPl = (codegamme) => {
        const l = totalResults?.filter((element) => element.gamme_code === codegamme)
        return l.length !== 0 ? l.map((items, keys) => (
            <>
                <tr className='text-center' key={keys}>
                    <td colSpan={1} className='bg-green-lightest' > Total </td>
                    <td colSpan={1} className='bg-green-lightest' > {items.totalSte !== 0 ? <span className='text-success' > <b>{calculDays !== 0 ? parseFloat(items.totalSte / calculDays).toFixed(2) : parseFloat(items.totalSte).toFixed(2)} </b></span> : 0}</td>
                    <td colSpan={1} className='bg-green-lightest' > {items.totalmarque1 !== 0 ? <span className='text-green' > <b>{calculDays !== 0 ? parseFloat(items.totalmarque1 / calculDays).toFixed(2) : parseFloat(items.totalmarque1).toFixed(2)} </b></span> : 0} </td>
                    <td colSpan={1} className='bg-green-lightest' > {items.totalmarque2 !== 0 ? <span className='text-green' > <b>{calculDays !== 0 ? parseFloat(items.totalmarque2 / calculDays).toFixed(2) : parseFloat(items.totalmarque2).toFixed(2)} </b></span> : 0}  </td>
                    <td colSpan={1} className='bg-green-lightest' > {items.totalmarque3 !== 0 ? <span className='text-green' > <b>{calculDays !== 0 ? parseFloat(items.totalmarque3 / calculDays).toFixed(2) : parseFloat(items.totalmarque3).toFixed(2)} </b></span> : 0}  </td>
                    <td colSpan={1} className='bg-green-lightest' > {items.total_pltotal !== 0 ? <span className='text-green' > <b>{calculDays !== 0 ? parseFloat(items.total_pltotal / calculDays).toFixed(2) : parseFloat(items.total_pltotal).toFixed(2)} </b></span> : 0} </td>
                    <td colSpan={1} className='bg-green-lightest' > <b className='text-green'> 100% </b> </td>
                </tr>
                <tr className='text-center' key={keys}>
                    <td colSpan={1} className='bg-yellow-lighter' > PDL </td>
                    <td colSpan={1} className='bg-yellow-lighter' >{parseFloat((items.totalSte / items.total_pltotal) * 100).toFixed(2) !== 0 ? <span className='text-yellow' > <b>{calculDays !== 0 ? parseFloat(((items.totalSte / items.total_pltotal) / calculDays) * 100).toFixed(2) : parseFloat((items.totalSte / items.total_pltotal) * 100).toFixed(2)} % </b>  </span> : ' 0 %'} </td>
                    <td colSpan={1} className='bg-yellow-lighter' > {parseFloat((items.totalmarque1 / items.total_pltotal) * 100).toFixed(2) !== 0 ? <span className='text-yellow' > <b>{calculDays !== 0 ? parseFloat(((items.totalmarque1 / items.total_pltotal) / calculDays) * 100).toFixed(2) : parseFloat((items.totalmarque1 / items.total_pltotal) * 100).toFixed(2)} % </b>  </span> : ' 0 %'} </td>
                    <td colSpan={1} className='bg-yellow-lighter' > {parseFloat((items.totalmarque2 / items.total_pltotal) * 100).toFixed(2) !== 0 ? <span className='text-yellow' > <b>{calculDays !== 0 ? parseFloat(((items.totalmarque2 / items.total_pltotal) / calculDays) * 100).toFixed(2) : parseFloat((items.totalmarque2 / items.total_pltotal) * 100).toFixed(2)} % </b>  </span> : ' 0 %'} </td>
                    <td colSpan={1} className='bg-yellow-lighter' > {parseFloat((items.totalmarque3 / items.total_pltotal) * 100).toFixed(2) !== 0 ? <span className='text-yellow' > <b>{calculDays !== 0 ? parseFloat(((items.totalmarque3 / items.total_pltotal) / calculDays) * 100).toFixed(2) : parseFloat((items.totalmarque3 / items.total_pltotal) * 100).toFixed(2)} % </b>  </span> : ' 0 %'}</td>
                    <td colSpan={1} className='bg-yellow-lighter' > {parseFloat((items.total_pltotal / items.total_pltotal) * 100).toFixed(2) !== 0 ? <span className='text-yellow' > <b>{calculDays !== 0 ? parseFloat(((items.total_pltotal / items.total_pltotal) / calculDays) * 100).toFixed(2) : parseFloat((items.total_pltotal / items.total_pltotal) * 100).toFixed(2)} % </b>  </span> : ' 0 %'} </td>
                    <td colSpan={1} className='bg-yellow-lighter' > <b className='text-yellow'> 100% </b> </td>
                </tr>
            </>
        )) : null
    }

    const findElementSearch = () => {
        const cal = dayDifferenceBetweenDates(today, fin)
        setcalculDays(cal)
        console.log("Nombre de jours " + cal)
        if (today > fin) {
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
        } else {
            setloading(true)
            setcodeUser("")
            setcodeZone("")
            setcodePDV("")
            setcodeEnseigne("")

            history.push({
                pathname:
                    "/rapports/rapportTableauPL&datedebut=" + today + "&datefin=" + fin,
            })
            traitement()
        }
    }
    const onClickUser = (e) => {
        if (e === "all") {
            setliste(saveListe)
            settotalResults(totalResults)
        } else {
            setcodeUser(e)
            setloading(true)
            const l1 = saveListe?.filter((element) => element.user_code === e)
            const l2 = totalResults?.filter((element) => element.user_code === e)
            setliste(l1)
            settotalResults(l2)
            setloading(false)
        }
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('rapports.RapportPartLineaire')}</li>
                </ol>
            </div>
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" /> {t('rapports.FiltrePartLineaire')}

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-3'></div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary" />
                                        <input type="date" value={fin} onChange={(e) => setfin(e.target.value)} className="form-control text-primary" />
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => findElementSearch()} ><i className='ti-search' /></button>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-3'></div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-3' />
                                <div className='col-2'>
                                    <SelectSearch
                                        options={listeEnseignes}
                                        search
                                        value={codeEnseigne}
                                        ref={searchInput}
                                        onChange={handleChangeEnseigne}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Magasins.Choisirenseigne')}
                                    />
                                </div>
                                <div className='col-2'>
                                    <SelectSearch
                                        options={listePdv}
                                        search
                                        value={codePDV}
                                        ref={searchInput}
                                        onChange={handleChangePdv}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Magasins.chercherunmagasins')}
                                    />
                                </div>
                                <div className='col-2'>
                                    <SelectSearch
                                        options={listeZones}
                                        search
                                        value={codeZone}
                                        ref={searchInput}
                                        onChange={handleChangeZone}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Magasins.Choisirzone')}
                                    />
                                </div>
                                <div className='col-3' />
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-3'>
                                    <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                        <button className="btn tag bg-azure text-white" onClick={() => onClickUser("all")}> {t('rapports.ALL')} </button>
                                        {listeUsers?.map((items, keys) => (
                                            <button className="btn tag bg-purple" key={keys} onClick={() => onClickUser(items.value)}> {items.name} </button>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {loading ? <SqaureLoader /> : (
                <>
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title mt-3'>
                                <span><i className='icon icon-folder-alt mr-2 text-primary' /> {t('rapports.RapportPartLineaire')} </span>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="br-tl-md-0 br-bl-md-0 btn btn-block btn-green fs-12 col-2 float-right"
                                    table="table-pdv"
                                    filename="liste_partLineaires"
                                    sheet="tablexls"
                                    buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                                />
                            </div>
                        </div>
                        <div className='card-body'>
                            {listeHeaders?.length === 0 ? (
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                        <div className="alert bg-purple mb-5 py-4" role="alert">
                                            <div className="d-flex">
                                                <i className="icon icon-info mr-3 fs-30"></i>
                                                <div>
                                                    <h4 className="alert-heading"> {t('Zoning.Aucunresultat')} ! </h4>
                                                    <p>  {t('rapportpl.aucunarticle')} {moment(today).format("DD/MM/YYYY") + " - " + moment(fin).format("DD/MM/YYYY")} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {loading ? <SqaureLoader /> : (
                                        <div className="table-responsive product-datatable">
                                            <table id="table-pdv" className="table table-striped table-bordered ">
                                                {listeHeaders?.map((items, keys) => (
                                                    <>
                                                        <thead className="text-center bg-azure-lighter ">
                                                            <tr className='text-center' key={keys}>
                                                                <td > {items.gamme} </td>
                                                                <td >{items.societe}</td>
                                                                <td >{items.marque1}</td>
                                                                <td >{items.marque2}</td>
                                                                <td >{items.marque3}</td>
                                                                <td >{t('rapports.TOTAL')}</td>
                                                                <td >{t('rapports.PDL')}</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {findFromListeElement(items.code, items.pltotal)}
                                                            {totalPl(items.code)}
                                                        </tbody>
                                                    </>
                                                ))}
                                            </table>
                                        </div>
                                    )}

                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
