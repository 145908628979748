/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { addCadeaux, getCadeauxByCode, getGroupedData, getListe, getListeRoulette } from '../../../services/Cadeaux/CadeauxApi'
import { getMerch } from '../../../services/Users/UsersApi'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import ModalUpdateCadeaux from './ModalUpdateCadeaux'
import ModalDeleteCadeaux from './ModalDeleteCadeaux'
import ModalImportCadeaux from './ModalImportCadeaux'
import ResultatRoulette from './ResultatRoulette'
import ListeCdeaux from './ListeCdeaux'
import _ from 'lodash'

export default function ListeCadeaux() {
    // CONDITION BOUTON ROUTE/REGION
    const [ok, setok] = useState(false)
    const [listeMerch, setlisteMerch] = useState([])
    const [codeUser, setcodeUser] = useState("")
    const [libelle, setlibelle] = useState("")
    const [a_la_prochaine, seta_la_prochaine] = useState("")
    const [qte, setqte] = useState("")
    const [ordre, setordre] = useState(0)
    const [date_debut, setdate_debut] = useState("")
    const [date_fin, setdate_fin] = useState("")
    const [avant_pause, setavant_pause] = useState("")
    const [apres_pause, setapres_pause] = useState("")
    /**
     * LISTE ROULETTE
     */
    const [Roulette, setRoulette] = useState([])
    /**
     * Loader 
     */
    const [loader, setloader] = useState(true)
    const [loader2, setloader2] = useState(false)
    //For détails Rows
    const [index, setindex] = useState(-1)
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [liste, setliste] = useState([])
    const [liste2, setliste2] = useState([])
    var [user, setuser] = useState("")
    var [deb, setdeb] = useState("")
    var [fin, setfin] = useState("")
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    //Modal items
    const [index2, setindex2] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    const [showEdit, setShowEdit] = useState(false)
    //DATE TODAY
    var date = new Date();
    var year = date.getFullYear()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var today = moment(date).format("YYYY-MM-DD")
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")
    
    // FILTRE ROULETTE
// Date debut /fin 
const [dateDebut, setdateDebut] = useState(date1)
const [dateFin, setdateFin] = useState(date2)
const [Commercial_Code, setCommercial_Code] = useState("")
const [ListeCommercial, setListeCommercial] = useState([])
const [saveRoulette, setsaveRoulette] = useState([])
    //add  cade aux 
    const [image, setImage] = useState({ preview: "", raw: "" });

    useEffect(() => {
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteMerch(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getGroupedData().then((result) => {
            if (result?.data?.success === 1) {
                setliste(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloader(false)
        })
        getListe()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste2(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            })
            getListeRoulette().then((result) => {
                if(result?.data?.success===1){
                    console.log(result?.data?.data);
                    var L = result?.data?.data
                    var results_roulette = L?.filter((el)=> moment(el.created_at).format("YYYY-MM-DD") >= date1 &&  moment(el.created_at).format("YYYY-MM-DD") <= date2  )
                    setRoulette(results_roulette)
                    setsaveRoulette(results_roulette)
                    setListeCommercial(_.uniqBy(results_roulette,"commercial_code"))
                }
            }).catch((err) => {
               console.log(err); 
            });
    }, [])

    //UPLOAD IMAGE WITH PREVIEW image Commerce
    const selectFile_image = (event) => {
        if (event.target.files.length) {
            setImage({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0]
            })
        }
    }
       //ONChange Pays
       useEffect(() => {
        if (show2===false || showEdit === true) {
            getGroupedData()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setShow(true);            
            })
              
        }
    }, [showEdit,show2]);

    const changedetails = (code) => {
        getCadeauxByCode(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    console.log(result?.data?.data[0]);
                    setObject(result?.data?.data[0])

                }
            }).catch((err) => {
                console.log(err)
            })
    }

    const saveData = () => {
        if(ordre==="" || libelle ==="" || date_debut==="" || date_fin===""){
            toast('Veuillez bien saisir les champs du formulaire ',{type:"warning",position:"top-right"})
        }else if(codeUser ==="all"){
            var array_gifts = []
            listeMerch?.map((items)=>{
                  addCadeaux({
                    code : ordre ,
                    ordre : ordre,
                    libelle : libelle,
                    date_debut:date_debut,
                    date_fin :date_fin,
                    a_la_prochaine:a_la_prochaine,
                    apres_pause : apres_pause,
                    avant_pause: avant_pause,
                    qte : qte,
                    image : image.raw,
                    type : ok === true ? "aleatoire" : "programme",
                    user_code:items.value              
                  }).then((result) => {
                    if(result?.data?.success===1){
                        toast("Cadeau ajouté avec succée ", {type:"success",position:"top-right"})
                    }
                }).catch((err) => {
                    console.log(err)
                });
        })}
        else
        {
            const data = {
                code : ordre ,
                ordre : ordre,
                libelle : libelle,
                date_debut:date_debut,
                date_fin :date_fin,
                a_la_prochaine:a_la_prochaine,
                apres_pause : apres_pause,
                avant_pause: avant_pause,
                user_code  :codeUser,
                qte : qte,
                image : image.raw,
                type : ok === true ? "aleatoire" : "programme"

            }
            addCadeaux(data).then((result) => {
                if(result?.data?.success===1){
                    toast("Cadeau ajouté avec succée ", {type:"success",position:"top-right"})
                }
            }).catch((err) => {
                console.log(err)
            });
        }
    }
  
    const listeOfData = () => {
        const list_u = liste2?.filter((element)=>element.user_code=== user && element.date_debut=== deb && element.date_fin===fin)
        return  <>
      
        {list_u?.map((items, k) => (
            <tr key={k} className="fs-12">
                <td>{items.libelle}</td>
                <td>{items.code}</td>
                <td>{items.a_la_prochaine}</td>
                <td>{items.qte}</td>
                <td>{items.avant_pause}</td>
                <td>{items.apres_pause}</td>
                <td>{items.type ==="programme" ? t('Produits.Programme') : t('Produits.Aleatoire')}</td>
                <td> <a data-toggle="modal" data-target="#updateGIFT" onClick={() => {changedetails(items.id); setCode(items.id); setindex2(k); setShowEdit(true)}}><b> <i className="icon icon-note text-azure" /></b> </a> </td>
                <td><a  data-toggle="modal" data-target="#modal-notification" onClick={() => {  setCode(items.id);setindex2(k); setShow2(true)}} ><i className='ti-trash text-danger'/> </a></td>

            </tr>))}
        
        </>
    }
    const onChangeDateFormat = () => {
        if(Commercial_Code !== ""){
            var results_roulette = saveRoulette?.filter((el)=> moment(el.created_at).format("YYYY-MM-DD") >= dateDebut &&  moment(el.created_at).format("YYYY-MM-DD") <= dateFin && el.commercial_code === Commercial_Code )
            setRoulette(results_roulette)
        }else{
            var results_roulette_2 = saveRoulette?.filter((el)=> moment(el.created_at).format("YYYY-MM-DD") >= dateDebut &&  moment(el.created_at).format("YYYY-MM-DD") <= dateFin )
            setRoulette(results_roulette_2)
        }
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/produits/liste"><i className="fe fe-life-buoy mr-1" /> {t('Produits.Produits')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Produits.Cadeaux')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                    <a href="/produits/validationGratuite" className="btn btn-green btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="ion-speakerphone mr-1" />
                            {t('sidebar.validationGratuite')}
                        </a>
                        <a href="/produits/liste" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="fe fe-download" />
                            {t('Produits.Listeproduits')}
                        </a>
                        
                        <a href="/produits/Configurations" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block  fs-12">
                            <i className="fe fe-download" />
                        {t('Produits.ConfiguraionProduits')}
                        </a>
                        <a href="/produits/codeMETI" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block  fs-12">
                            <i className="icon icon-wrench mr-1" />
                           {t('Produits.ConfigurationCodeMETI')}
                        </a>

                    </div>
                </div>

            </div>
            <ToastContainer />
            <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                    <ListeCdeaux t={t} loader={loader} liste={liste} index={index} show={show} setindex={setindex} setShow={setShow} setuser={setuser} setdeb={setdeb} setfin={setfin} loader2={loader2} listeOfData={listeOfData} />
                    {/** Tableaux CODE METI  */}
                    <ResultatRoulette  saveRoulette={saveRoulette} Commercial_Code={Commercial_Code} setCommercial_Code={setCommercial_Code} dateDebut={dateDebut} setdateDebut={setdateDebut} dateFin={dateFin} setdateFin={setdateFin} onChangeDateFormat={onChangeDateFormat} loader={loader} Roulette={Roulette} t={t} /> 
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                    {/** Tableaux CODE METI  */}
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-3 text-azure float-left"><i className="icon icon-plus mr-1" /> {t('Produits.AjouterCadeaux')} </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className="col-12">
                                    <div className="form-group text-center">
                                        <button className={ok ? "btn br-tl-md-0 br-bl-md-0 btn-outline-primary fs-12 " : "btn br-tl-md-0 br-bl-md-0 bg-purple fs-12 "} onClick={() => setok(!ok)}> <i className="icon icon-trophy mr-1" /> {t('Produits.Programme')} </button>
                                        <button className={ok ? "btn br-tl-md-0 br-bl-md-0 bg-purple fs-12  " : "btn br-tl-md-0 br-bl-md-0 btn-outline-primary fs-12 "} onClick={() => setok(!ok)}> <i className="icon icon-event mr-1" />{t('Produits.Aleatoire')} </button>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className="col-6">
                                    <label>{t('Produits.Ordre')} </label>
                                    <input type="number" value={ordre} onChange={(e) => setordre(e.target.value)} placeholder='ordre ... ' className='form-control' />
                                </div>
                                <div className="col-6">
                                    <label>{t('Produits.Libelle')} </label>
                                    <input type="text" placeholder={t('Produits.Libelle')} value={libelle} onChange={(e) => setlibelle(e.target.value)} className='form-control' />
                                </div>

                                {ok ? null : (
                                    <>
                                        <div className="col-6">
                                            <label>{t('Produits.Quantite')} </label>
                                            <input type="number" placeholder={t('Produits.Quantite')} value={qte} onChange={(e) => setqte(e.target.value)} className='form-control' />
                                        </div>
                                        <div className="col-6">
                                            <label>{t('Produits.Alaprochaine')} </label>
                                            <input type="number" placeholder={t('Produits.Alaprochaine')} value={a_la_prochaine} onChange={(e) => seta_la_prochaine(e.target.value)} className='form-control' />
                                        </div>
                                        <div className="col-6">
                                            <label>{t('Produits.AvantPause')} </label>
                                            <input type="number" placeholder={t('Produits.AvantPause')}  value={avant_pause} onChange={(e) => setavant_pause(e.target.value)} className='form-control' />
                                        </div>
                                        <div className="col-6">
                                            <label>{t('Produits.ApresPause')} </label>
                                            <input type="number" placeholder={t('Produits.ApresPause')} value={apres_pause} onChange={(e) => setapres_pause(e.target.value)} className='form-control' />
                                        </div>
                                    </>
                                )}

                                <div className="col-6">
                                    <label>{t('Routings.datedebut')}</label>
                                    <input type="date" value={date_debut} onChange={(e) => setdate_debut(e.target.value)} className="form-control fs-10 text-primary" />
                                </div>
                                <div className="col-6">
                                    <label>{t('Produits.DATEFIN')}</label>
                                    <input type="date" value={date_fin} onChange={(e) => setdate_fin(e.target.value)} className="form-control fs-10 text-primary" />
                                </div>
                                <div className='col-12'>
                                    <label className="form-control-label">{t('dashboard.Merchandiseurs')} : <span className="text-danger"> * </span></label>
                                    <select name="country" id="select-countries2" value={codeUser} onChange={(e) => setcodeUser(e.target.value)} className="form-control  text-primary  custom-select br-md-0">
                                        <option value={""} selected> {t('dashboard.Merchandiseurs')} ... </option>
                                        <option value={"all"} > Tout les merchandiseurs </option>
                                        {listeMerch?.map((items, keys) => (
                                            <option value={items.value} key={keys}>{items.name} </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <label className="form-control-label">{t('Produits.imageproduit')}</label>

                                    <input type="file" name='image' onChange={(e) => selectFile_image(e)} className="dropify" data-height={180} />
                                    {image?.preview !== "" ? <img src={image.preview} alt="image_produit" height={400} />: ""}
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <button className='btn btn-primary float-right' onClick={() => saveData()}> <i className='ti-save mr-1' /> {t('Magasins.Enregistrer')} </button>
                        </div>
                    </div>
                </div>
            </div>
            {show2 ? <ModalDeleteCadeaux setShow2={setShow2}  index2={index2} code={code}  t={t} />:  null}
            {showEdit ? <ModalUpdateCadeaux id={code} setShowEdit={setShowEdit} index={index2} object={object}  t={t} listeMerch={listeMerch} /> : null}
            <ModalImportCadeaux  t={t} />

        </div>
    )
}
