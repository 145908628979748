/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
//-------------- Web service --------------
import { getListeSocietes, getSocieteByCode } from '../../../../services/Produits/configurationProduits/ConfigurationAPI'
//--------------------- UTILS --------------
import { SearchItems } from '../../../../utils/SearchUtils'
//------------------ MODAL ADD/EDIT societe -----------
import ModalAddSociete from './ModalAddSociete'
import ModalEditSociete from './ModalEditSociete'
//------------------- Component -----------------
import SqaureLoader from '../../../../components/Loader/SqaureLoader'
import PaginationTable from '../../../../components/Pagination/PaginationTable'

export default function ListeSocite({ t }) {

    //------------------------------ REFERENCES ---------------------
    const inputEl = useRef("")
    //----------------------- SEARCH INPUT ---------------------------- 
    const [search, setSearch] = useState('')
    //------- FORMULAIRE ITEMS  ---------
    const [listeSociete, setlisteSociete] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //------ Formulaire -----------
    const [data, setData] = useState(null)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(5);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeSociete?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)
    //Loader 
    const [Loading, setLoading] = useState(true)
    //Edit Items 
    const [show, setShow] = useState(true)
    const [showEdit, setShowEdit] = useState(false)
    const [index, setIndex] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    //MODAL  IMPROT EXCEL
    const [ok, setOk] = useState(false)

    useEffect(() => {
        setLoading(true)
        getListeSocietes()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteSociete(result?.data?.data)
                    setSaveList(result?.data.data)

                }
            }).catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [show])
    
    const changedetaiils = (code) => {
        getSocieteByCode(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setObject(result?.data?.data[0])
                }
            }).catch((err) => {
                console.log(err)
            })
    }



    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeSociete, search)
            setSearchlist(newList)
        } else {
            setlisteSociete(saveList)
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-3 float-left">{t('users.listesocietes')}</div>
                            <button className="btn btn-azure float-right" data-toggle="modal" data-target="#addSociete"> <i className="icon icon-plus" /></button>
                        </div>
                        <div className="card-body">
                            <div className="search1 search-elements-sm mb-4 ">
                                <div className="form row no-gutters">
                                    <div className="col-6"></div>
                                    <div className="form-group  col-xl-5 col-lg-5 col-md-5 mb-0">
                                        <input type="text" ref={inputEl} value={search}
                                            onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary" id="text1" placeholder={t('users.chercherSociete')} />
                                    </div>

                                    <div className="col-xl-1 col-lg-1 col-md-1 mb-0">
                                        <a href="#" className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"><i className="icon icon-magnifier" /></a>
                                    </div>
                                </div>
                            </div>
                            {Loading ? (
                                <SqaureLoader />
                            ) : (
                                <div className="table-responsive product-datatable">
                                    <table
                                        id="example"
                                        className="table table-striped table-bordered "
                                    >
                                        <thead className="text-center">
                                            <tr>
                                                <th className="w-15p fs-12">{t('rapports.code')}</th>
                                                <th className="wd-15p fs-12">{t('dashboard.Libelle')}</th>
                                                <th className="wd-15p fs-12">{t('login.email')}</th>
                                                <th className="wd-15p fs-12">{t('conventions.bloquagemagasin')}</th>
                                                <th className='wd-15p fs-12'>{t('conventions.expireCMD')}</th>
                                                <th className="wd-15p fs-12">{t('Magasins.Type')}</th>
                                                <th className="wd-15p fs-12">{t('relevePrix.relevePrix')}</th>
                                                <th className="wd-15p fs-12">{t('conventions.photovisite')}</th>
                                          </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {currentListe?.map((items, keys) => (
                                                <tr key={keys}>
                                                    <td className='fs-12'>{items.code} </td>
                                                    <td className='fs-12' >{items.libelle} </td>
                                                    <td className='fs-12' >{items.email} </td>
                                                    <td className='fs-12' >{items.list_pdv === "0"? "Oui" :"Non"} </td>
                                                    <td className='fs-12'>{items.expire_commande === 0 ? t('users.illimite') : t('users.apres') + ` ${items.expire_commande} ` + t('users.jours')}</td>
                                                    <td className='fs-12' >{items.type} </td>
                                                    <td className='fs-12' >{items.releve_prix} </td>
                                                    <td className='fs-12' >{items.photo_visite} </td>
                                                    <td className='fs-12' style={{ cursor: "pointer" }}>
                                                        <a data-toggle="modal" data-target="#modal-editerS" onClick={() => {
                                                            changedetaiils(items.code); setCode(items.code); setIndex(keys); setShowEdit(true)
                                                        }}> <i className="icon icon-note text-warning" /></a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="float-right">
                                        <PaginationTable
                                            postsPerPage={stocksPerPage}
                                            totalPosts={search.length < 1 ? listeSociete?.length : searchList?.length}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>
                    <ModalAddSociete setShow={setShow} t={t} />
                    <ModalEditSociete code={code} setShow={setShow} keys={index} setOk={setOk} object={object} t={t} />

                </div>
            </div>
        </div>
    )
}
