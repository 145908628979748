import React from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search'

export default function AddConventions({t,libelle,setlibelle, produit, dateDebut, setdateDebut, dateFin , setDateFin,  listeEnseignes  ,codeenseigne ,  listeCategorieEnse , enseigne,handleEnseigne,listeTypes ,listeCategorie,codeCategorie,categorie,handleCategorie, saveConvention }) {
  return (
    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
    <div className="panel panel-default active">
        <div className="panel-heading " role="tab" id="headingOne">
            <h4 className="panel-title ">
                <a role="button" className="fs-12 text-primary" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <i className='icon icon-plus mr-1' /> {t('conventions.ajouterconvention')}
                    <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                </a>
            </h4>
        </div>
        <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
            <div className="panel-body">
                <div className='row'>
                    <div className="form-group col-6">
                        <label className="form-control-label fs-12"> {t('dashboard.Libelle')} <span className="text-danger">*</span></label>
                        <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control  fs-12" placeholder='Libellé' />
                    </div>
                    <div className="form-group col-6">
                        <label className="form-control-label  fs-12"> {t('Produits.Produits')} <span className="text-danger">*</span></label>
                        <input type="text" value={produit} readOnly disabled className="form-control  fs-12" />
                    </div>
                    <div className="form-group col-12">
                        <label className="form-control-label  fs-12">{t('dashboard.Enseigne')}  <span className="text-danger">*</span></label>
                        <SelectSearch
                            options={listeEnseignes}
                            search
                            value={codeenseigne}
                            ref={enseigne}
                            onChange={handleEnseigne}
                            filterOptions={fuzzySearch}
                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                            placeholder={t('Magasins.Choisirenseigne')}
                        />
                    </div>
                    {codeenseigne !== "-1" &&
                        (listeCategorieEnse?.length === 0 ?
                            <div className="form-group col-12">
                                <label className="form-control-label  fs-12">{t('rapportrupture.categorie')}  <span className="text-danger">*</span> <span className='text-azure'>
                                    {listeTypes?.map((items, keys) => (
                                        items.libelle + " , "
                                    ))} </span></label>

                                <SelectSearch
                                    options={listeCategorie}
                                    search
                                    value={codeCategorie}
                                    ref={categorie}
                                    onChange={handleCategorie}
                                    filterOptions={fuzzySearch}
                                    emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                    placeholder={t('Magasins.ChoisirCategorie')}
                                />
                            </div> :
                            <div className="form-group col-12">
                                <label className="form-control-label  fs-12">{t('rapportrupture.categorie')}  <span className="text-danger">*</span> <span className='text-azure'>
                                    {listeTypes?.map((items, keys) => (
                                        items.libelle + " , "
                                    ))} </span></label>

                                <SelectSearch
                                    options={listeCategorieEnse}
                                    search
                                    value={codeCategorie}
                                    ref={categorie}
                                    onChange={handleCategorie}
                                    filterOptions={fuzzySearch}
                                    emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                    placeholder={t('Magasins.ChoisirCategorie')}
                                />
                            </div>)
                    }
                      <div className="form-group col-6">
                        <label className="form-control-label fs-12">{t('Routings.datedebut')}  <span className="text-danger">*</span></label>
                        <input type="date" value={dateDebut} onChange={(e) => setdateDebut(e.target.value)} className="form-control fs-12" />

                    </div>
                    <div className="form-group col-6">
                        <label className="form-control-label  fs-12">{t('Routings.datefin')} <span className="date-danger">*</span></label>
                        <input type="date" value={dateFin} onChange={(e) => setDateFin(e.target.value)} className="form-control fs-12" />

                    </div>
                    <div className="form-group col-12">
                        <button className='btn btn-primary col-12  fs-12' onClick={() => saveConvention()}> <i className="ti-save mr-1" /> {t('Magasins.Enregistrer')}  </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}
