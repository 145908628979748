/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getSelctEnseignes } from '../../../services/Produits/ProduitsAPI'
import { getRapportContact } from '../../../services/Rapports/RapportsApi'
import { getSelectMagasins } from '../../../services/Ruptures/ruptureAPI'
import { getMerch } from '../../../services/Users/UsersApi'
import { getSelectZone } from '../../../services/Zoning/ZoningApi'
import { useTranslation } from 'react-i18next';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ModalToutExporter from './ModalToutExporter'
import ExportToExcel from '../../../components/exportExcel/ExportToExcel'
import TableGestionStock from './TableGestionStock'
import CollapseFitler from './CollapseFitler'

export default function RapportContact() {
    //--------- PARAMETERE -------------
    const { datedebut, datefin, user_code } = useParams('')
    //------ History -----------------
    const history = useHistory()
    //---------- FORMULAIRE --------------
    const [date_d, setdate_d] = useState(datedebut)
    const [date_f, setdate_f] = useState(datefin)
    const [codeUser, setcodeUser] = useState(user_code)
    const [codezone, setcodezone] = useState("")
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [codePdv, setcodePdv] = useState("")
    //--------- LISTES ---------------------
    const [listeUsers, setlisteUsers] = useState([])
    const [listeZones, setlisteZones] = useState([])
    const [listeTotal, setlisteTotal] = useState([])
    const [listeEnseigne, setlisteEnseigne] = useState([])
    const [listePdv, setlistePdv] = useState([])
    const [listeContacts, setlisteContacts] = useState([])
    const [saveListe, setsaveListe] = useState([])
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(20);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    const [index, setindex] = useState(-1)
    const [show, setShow] = useState(false)
    var [codeContact, setcodeContact] = useState("")
    //--------- Loader ------------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteUsers(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            })
        getSelectMagasins().then((result) => {
            if (result?.data?.success === 1) {
                setlistePdv(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnseigne(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectZone().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        traitement()

    }, [])


    const traitement = () => {

        getRapportContact(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteContacts(result?.data?.liste)
                    setsaveListe(result?.data?.liste)
                    setlisteTotal(result?.data?.totalListeContact)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }

    /**
   * Redirect function 
   */
    const redirection = () => {
        history.push({
            pathname:
                "/rapports/rapportContacts&datedebut=" + date_d + "&datefin=" + date_f + "&user_code=" + codeUser,
        })
        traitement()

    }

    const handleChangeEnseigne = (e) => {
        setcodeEnseigne(e.target.value)
        if (e.target.value !== "") {
            const liste = saveListe?.filter((element) => element.enseigne_code === e.target.value)
            setlisteContacts(liste)
        } else {
            setlisteContacts(saveListe)
        }
    }

    const handlechangePdv = (e) => {
        setcodePdv(e.target.value)
        if (e.target.value !== "") {
            const liste = saveListe?.filter((element) => element.pdv_code === e.target.value)
            setlisteContacts(liste)
        } else {
            setlisteContacts(saveListe)
        }
    }

    const handleChangeZone = (e) => {
        setcodezone(e.target.value)
        if (e.target.value !== "") {
            const liste = saveListe?.filter((element) => element.zone_code === e.target.value)
            setlisteContacts(liste)
        } else {
            setlisteContacts(saveListe)
        }
    }

    const saveData = () => {
        redirection()
        traitement()
    }

    const showDetailsUsers = (code) => {
        if (code !== "" && code !== "all") {
            setcodeUser(code)
            const liste = saveListe?.filter((element) => element.user_code === code)
            setlisteContacts(liste)
        } else {
            setlisteContacts(saveListe)
        }
        history.push({
            pathname:
                "/rapports/rapportContacts&datedebut=" + date_d + "&datefin=" + date_f + "&user_code=" + code,
        })

    }

    const listeOfData = () => {
        const list_u = listeTotal?.filter((element) => element.code === codeContact)
        return <>
            {list_u?.map((items, k) => (
                <tr key={k}>
                    <td>{items.nompdv}</td>
                    <td>{items.produit_code === "" ? <span className='text-secondary'> - </span> : items.produit_code}</td>
                    <td>{items.libelle === null ? <span className='text-secondary'> - </span> : items.libelle}</td>
                    <td>{items.codeContact}</td>
                    <td>{items.qte_conculante}</td>

                </tr>))}

        </>
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('contacts.contacts')}  </li>
                </ol>
            </div>
            {/** ------------- FILTRE RAPPORT CONTACT ------------------------------ */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" /> {t('contacts.detailContacts')}

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                   
                    <CollapseFitler date_d={date_d}  setdate_d={setdate_d}  date_f={date_f}  setdate_f={setdate_f} listeZones={listeZones} codezone={codezone} handleChangeZone={handleChangeZone}  handleChangeEnseigne={handleChangeEnseigne} codeEnseigne={codeEnseigne} listeEnseigne={listeEnseigne} codePdv={codePdv} handlechangePdv={handlechangePdv} listePdv={listePdv} saveData={saveData}  showDetailsUsers={showDetailsUsers} listeUsers={listeUsers}  t={t} />
                </div>
            </div>
            
            {/** ------------- TABLEAU RAPPORT CONTACT ------------------------------ */}
            <div className='card'>
                <div className='card-header'>
                    <div className='card-title text-azure  text-center mt-3'>
                        {t('contacts.listepdv')}
                    </div>
                </div>
                {loading ? <SqaureLoader /> : (
                    <div className='card-body'>
                        <div className='float-right '>
                            <ExportToExcel id="table-to-xls" namefile="rapportContact" t={t} />
                        </div>
                        <button className='mr-1 ml-1 btn btn-secondary mb-2 btn-pill fs-12 mt-0 float-right' data-toggle="modal" data-target="#largeModal" ><i className="icon icon-share-alt mr-1" /> {t('toutexporter')} </button>
                        {/** ------------------------------- TABLEAU STOCK DU RAPPORT CONTACT ---------------------------------------------------------------- */}
                        <TableGestionStock listeOfData={listeOfData} listeContacts={listeContacts} index={index} show={show} t={t} setindex={setindex} setShow={setShow} setcodeContact={setcodeContact} />
                    </div>)}
            </div>
            <ModalToutExporter t={t} />
        </div>
    )
}
