import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

export default function TableauSuiviRupture({ liste, listeGrouped, t }) {
    const [datess, setdatess] = useState([])

    useEffect(() => {
        var test = _.sortBy(liste, 'date');
        const p = _.groupBy(test, function (item) {
            return moment(item.date).format("YYYY-MM-DD")
        });
        const gr = Object.keys(p)
        setdatess(gr)
    }, [liste])

    const findOnListe = (produit_code, user_code, date) => {
        const findEl = liste?.filter((el) => el.produit_code === produit_code  && el.user_code === user_code && moment(el.date).format("YYYY-MM-DD") === date)
        if (findEl[0]?.etat_repture === undefined )  {
            return null
        } else return findEl[0]?.etat_repture === "1"? <b className='text-danger'>{t("rapportrupture.rupture")+" ("+findEl[0]?.cause+")"} </b> :<b className='text-success'>{t("rapportrupture.disponible")}</b>
    }
  return (
    <div className='col-12'>
              <div className='float-right '>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 "
                    table="table-to-xls"
                    filename="Rapport_Suivi_Releve_Prix"
                    sheet="tablexls"
                    buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                />
            </div> 
            <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '600px' }}>
                <table id="table-to-xls" className="table  table-bordered  mt-2">
                    <thead className="text-center bg-azure-lighter" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>

                        <tr>
                            <th className="text-center" colSpan={3}></th>
                            {datess?.map((items, k) => (
                                <th className="text-center b-top b-left  b-right fs-10"> {moment(items).format("DD/MM/YYYY")} </th>
                            ))}
                        </tr>
                        <tr>
                            <th className="w-15p fs-10">{t('rapportrupture.merch')}</th>
                            <th className="w-15p fs-10">{t('rapportrupture.codeproduit')}</th>
                            <th className="w-15p fs-10">{t('rapportrupture.produit')}</th>
                            {datess?.map((items, k) => (
                                <th className="text-center b-left b-right fs-10 " key={k}> Etat</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='bg-white'>
                        {listeGrouped?.map((items, keys) => (
                             <tr key={keys} className="fs-10 text-center">
                                 <td> {items.nom}</td>
                                 <td> {items.code_a_barre}</td>
                                 <td> {items.nom_produit}</td>
                                 {datess?.map((i, k) => (
                                     <td key={k}>{findOnListe(items.produit_code, items.user_code, i) !== null ?findOnListe(items.produit_code, items.user_code, i) :"-" }</td>
                                ))}
                             </tr>
                        ))} 
                    </tbody>
                </table>
            </div>
        </div>
  )
}
