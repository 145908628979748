/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { getSelectMagasins } from '../../../services/Ruptures/ruptureAPI'
import { getMerch } from '../../../services/Users/UsersApi'
import { getSelectZone } from '../../../services/Zoning/ZoningApi'

export default function RapportVisites() {
    //--------- PARAMETERE -------------
    const { datedebut, datefin } = useParams('')
    //------ History -----------------
    const history = useHistory()
    //---------- FORMULAIRE --------------
    const [date_d, setdate_d] = useState(datedebut)
    const [date_f, setdate_f] = useState(datefin)
    const [codeUser, setcodeUser] = useState("")
    const [codezone, setcodezone] = useState("")
    const [codePdv, setcodePdv] = useState("")
    //--------- LISTES ---------------------
    const [listeUsers, setlisteUsers] = useState([])
    const [listeZones, setlisteZones] = useState([])
    const [listePdv, setlistePdv] = useState([])
    //--------- Loader ------------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteUsers(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            })
        getSelectMagasins().then((result) => {
            if (result?.data?.success === 1) {
                setlistePdv(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });

        getSelectZone().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })

    }, [])
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" />  {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> Rapport Visites </li>
                </ol>
            </div>
            {/** ------------- rapport : VISITES ------------------------------ */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" /> Rapport Visites

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className="form row no-gutters">
                                <div className="form-group  col-xl-2 col-lg-2 col-md-12 mb-0">
                                    <input type="date" className='form-control' value={date_d} onChange={(e) => setdate_d(e.target.value)} />
                                </div>

                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 col-6 mb-0 ">
                                    <input type="date" className='form-control' value={date_f} onChange={(e) => setdate_f(e.target.value)} />
                                </div>
                                <div className="form-group  col-xl-1 col-lg-1 col-md-1 col-6 mb-0">
                                    <select className="form-control  br-md-0  fs-12 text-primary" value={codeUser} onChange={(e) => setcodeUser(e.target.value)}>
                                        <option value={""} >Merchandiseur</option>
                                        {listeUsers?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group  col-xl-1 col-lg-1 col-md-1 mb-0">
                                    <select className="form-control  br-md-0  fs-12 text-primary" value={codezone} >
                                        <option value={""} >Zones</option>
                                        {listeZones?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 mb-0">
                                    <select className="form-control  br-md-0  fs-12 text-primary" value={codePdv} >
                                        <option value={""}>Magasins</option>
                                        {listePdv?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-14" ><i className='ti ti-search' /></button>

                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-14" ><i className='ti ti-close' /></button>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
