import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { addExcelSFamille } from '../../../services/Produits/ProduitsAPI';
import SFamilles from './Files/SFamilles.xlsx'

export default function ModalImportExcel({setOk,t}) {

    const [filename, setFileName] = useState(t('Produits.choisirfichier'))
    const [file, setfile] = useState("");

     //UPLOAD IMAGE WITH PREVIEW image Commerce
     const selectFile = (event) => {
        setFileName(event?.target?.files[0]?.name)
        if (event?.target?.files?.length) {
            setfile(event?.target?.files[0])
        }
    }

    const uploadFile = () => {
        // FILE FUNCTIONS
          // FILE FUNCTIONS
          addExcelSFamille(file)
          .then((result) => {
              console.log(result)
              if (result?.data?.success === 1) {
                  toast(t('Produits.donneeajoutee'), { type: "success", position: 'top-right' })
                  setOk(true)
              } else {
                  toast(t('Produits.probajout'), { type: "error", position: 'top-right' })

              }
          }).catch((err) => {
              console.log(err)

          });
    }
    
  return (
    <div className="modal fade" id="excelSf" tabIndex={-1} role="dialog" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="excelMarque">{t('Produits.importerfichier')}  </h5>
                <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" /> </span>

            </div>
            <div className="modal-body">
                <form>
                    <div className="form-group">
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" onChange={(e) => selectFile(e)} name="file" />
                            <label className="custom-file-label"> ... {filename} </label>
                            <p className=" text-azure " > ... {filename} </p>

                        </div>

                    </div>
                    <div className="form-group">
                        <a className="text-primary" href={SFamilles} download> <u>{t('Produits.telechargerModele')} </u></a>
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => uploadFile()}><i className="icon icon-check mr-1" /> {t('Magasins.Enregistrer')} </button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><i className="icon icon-close mr-1" /> {t('Zoning.Fermer')}</button>
            </div>
        </div>
    </div>
</div>
  )
}
