import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { updateRoutings } from '../../../services/Routings/RoutingsApi';
import { getMerch } from '../../../services/Users/UsersApi'

export default function DuplicateModal({ code, routing_code, codeUser, setCodeUser, setok, t }) {
    const [listeUsers, setlisteUsers] = useState([])

    useEffect(() => {
        getMerch().then((result) => {
            if (result?.data?.success === 1) {
                setlisteUsers(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });

    }, [])
    
    const editTournee = (code) => {
        const data = {
            user_code: codeUser
        }
        updateRoutings(routing_code, data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('Routings.modifieSuccess'), { type: "success", position: "top-right" })
                } else {
                    toast(t('Routings.erreur'), { type: "error", position: "top-right" })
                }
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <div className="modal fade" id="duplicate" tabIndex={-1} role="dialog" aria-labelledby="modal" aria-hidden="true" >
            <div className="modal-dialog modal-danger" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal">{t('Routings.affecter') + " : " + code} </h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setok(true)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="py-3 text-center">

                            <h6 className="heading mt-4 fs-12">{t('Routings.modifierMerch')}</h6>
                            <select value={codeUser} onChange={(e) => setCodeUser(e.target.value)} className="form-control  custom-select col-6 br-md-0">
                                {listeUsers?.map((items, keys) => (
                                    <option value={items.value}>{items.name}</option>)
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => { editTournee(code) }}> <i className="icon icon-check mr-1" /> {t('Routings.confirmer')}</button>
                        <button type="button" className="btn btn-secondary text-white ml-auto" data-dismiss="modal" onClick={() => setok(true)}><i className="icon icon-close mr-1" /> {t('Routings.annueler')} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
