/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { TileLayer, MapContainer, Popup } from "react-leaflet";
import Marker from "react-leaflet-enhanced-marker";
import "leaflet/dist/leaflet.css";
import "../Routings/Route/map.css";
import { useTranslation } from "react-i18next";

export default function PdvRelevePrix({ listemagasins, loading }) {

    const ZOOM_LEVEL = 8;
    const mapRef = useRef();

    const [center, setCenter] = useState({
        lat: isNaN(listemagasins[0]?.latitude) ? "36.8842439" : parseFloat(listemagasins[0]?.latitude),
        lng: isNaN(listemagasins[0]?.logitude) ? "10.1625628" : parseFloat(listemagasins[0]?.logitude),
    });
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    return <div>
        <div className="left-container" style={{ position: "sticky" }}>
            <MapContainer center={center} zoom={ZOOM_LEVEL} map={mapRef} >
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
                />
                {listemagasins?.map((items, index) => (

                    <Marker popupOpen position={[items?.latitude === null || undefined ? -1 : parseFloat(items?.latitude), items?.logitude === null || undefined ? -1 : parseFloat(items?.logitude)]} icon={<i className="icon icon-basket-loaded  fa-3x" style={{ color: "#ff6600" }} />} key={index} className="marker" >

                        <Popup popupOpen>
                            <b>
                                {t('dashboard.Magasin')} :  {items?.nompdv}
                                <hr className='hr1' />
                                {t('rapportrupture.produit')} : {items.produits}
                                <hr className='hr1' />
                                {t('rapports.prix')} : {items.permanent}
                                <hr className='hr1' />
                                {t('rapports.promotion')} : {items.promo}
                            </b>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    </div>;
}
