/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getRapportGestionStock, getUsersRapport } from '../../../services/Rapports/RapportsApi'
import { useTranslation } from 'react-i18next';
import ExportToExcel from '../../../components/exportExcel/ExportToExcel'
import TableauStock from './TableauStock'

export default function RapportGestionStock() {
    //--------- PARAMETERE -------------
    const { datedebut, datefin } = useParams('')
    //------ History -----------------
    const history = useHistory()
    //--------- Search --------------------
    const [date_d, setdate_d] = useState(datedebut)
    const [date_f, setdate_f] = useState(datefin)
    //------------ LISTE ---------------
    const [codeUser, setCodeUser] = useState("")
    const [listeUsers, setlisteUsers] = useState([])
    const [liste, setliste] = useState([])
    const [saveListe, setsaveListe] = useState([])

    const [data, setdata] = useState({
        total_contact: 0,
        contact_sans_achat: 0,
        contact_avec_achat: 0

    })
    //----------- LODER ----------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    const onChangeValue = () => {
        if (date_d > date_f) {
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
        } else {
            history.push({
                pathname:
                    '/rapports/rapportStock&datedebut=' + date_d + '&datefin=' + date_f,
            })
            traitement()

        }
    }

    const traitement = () => {
        setloading(true)
        getUsersRapport(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteUsers(result?.data?.listeUsers)
                }
            }).catch((err) => {
                console.log(err)
            });
        getRapportGestionStock(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setdata({
                        total_contact: result?.data?.total_contact.total_contact,
                        contact_sans_achat: result?.data?.contact_sans_achat?.contacts_sans_achat,
                        contact_avec_achat: result?.data?.contact_avec_achat.contact_avec_achat
                    })
                    setliste(result?.data?.liste)
                    setsaveListe(result?.data?.liste)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }

    useEffect(() => {
        traitement()
    }, [])
 
    const onClickUser = (e) => {
        console.log(e)
        if (e !== "all") {
            setCodeUser(e)
            const liste = saveListe.filter((element) => element.user_code === e)
            setliste(liste)

        } else {
            setliste(saveListe)
            setCodeUser('')

        }
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.Rapports')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.RapportGestionStock')} </li>
                </ol>
            </div>
            {/** ------------- rapport : Suivi Tournee  ------------------------------ */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" />  {t('rapports.GestiondeStock')}

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'></div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control text-primary fs-12" />
                                        <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control text-primary fs-12" />
                                        
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => onChangeValue()}><i className='ti-search' /></button>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'></div>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>

                                    <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                        <button className="btn tag bg-azure text-white" onClick={() => onClickUser("all")}> {t('rapports.ALL')} </button>

                                        {listeUsers?.map((items, keys) => (
                                            <button className="btn tag bg-purple" key={keys} onClick={() => onClickUser(items.user_code)}> {items.nom} : ({items.total})</button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-12'>
                            <div className='card'>
                                {loading ?
                                <SqaureLoader /> : (
                                    <div className='card-body'>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                <div className=' float-right'>
                                                </div>
                                            <ExportToExcel id="table-to-xls" namefile="Rapport_Gestion_Stock" t={t} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <h6 className='text-center text-azure fs-12'> {t('rapports.ContactsTotal')} : {data?.total_contact}  </h6>
                                            </div>
                                            <div className='col-4'>
                                                <h6 className='text-center text-azure fs-12'>  {t('rapports.ContactsAvecAchat')}:  <a href={"/rapports/rapportContacts&datedebut=" + date_d + "&datefin=" + date_f + "&user_code=''"}> <u style={{ cursor: "pointer" }}> {data?.contact_avec_achat}</u> </a> </h6>
                                            </div>
                                            <div className='col-4'>
                                                <h6 className='text-center text-azure fs-12'>   {t('rapports.ContactsSansAchat')} : {data?.contact_sans_achat}    </h6>

                                            </div>
                                        </div>
                                       <TableauStock liste={liste} t={t} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
