/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { addMailUser } from '../../../../services/Produits/configurationProduits/ConfigurationAPI'
import { myRnId } from '../../../../utils/DateUtils'

export default function AddMailingUser({ setShow ,t}) {
    
    const [email, setemail] = useState("")
    const [code, setCode] = useState("Mail-"+myRnId())

    const saveData= ()=>{
        if(email !=="" && code !=="" ){
            const data={
                code : code,
                email : email,
            }
            addMailUser(data) 
            .then((result) => {
                if (result?.data?.success === 1) {

                    toast(t('messageErreur.ajoutavecsuccee'), { type: 'success', position: 'top-right' })
                } else {
                    toast(t('Zoning.Erreur'), { type: 'error', position: 'top-right' })
                }
            }).catch((err) => {
                console.log(err)
            });
        }else {
            toast(t('messageErreur.veuillezsaisir'), { type: "warning", position: 'top-right' })

        }
    }
  return (
    <div className="modal fade" id="addMailingUser" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addMailingUser"> Ajouter E-Mail administrateur  </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>

                            <div className='row'>
                               
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="mr-2 mt-1 mb-sm-1"> {t('rapports.code')}<span className="text-danger">*</span></label>
                                        <input type="text" value={code} readOnly disabled  className="form-control" />

                                    </div>
                                </div>
                          
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('login.email')} :</label>
                                        <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                
                            </div>
                  
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => saveData()} ><i className='ti-save mr-1'/> {t('rapports.enregistrer')} </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShow(false)}> <i className='ti-close mr-1'/> {t('Routings.annueler')}</button>

                    </div>
                </div>
            </div>
        </div>
  )
}
