import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

/**
 * DOTENV : CONFIGURATION
 */
dotenv.config()
const { REACT_APP_API_URL } = process.env;

//GET URL BACKEND 
const url = REACT_APP_API_URL

/**
 * ADD MESSAGE
 * @param {*} data 
 * @returns 
 */
 export async function addMessages(data) {
    try {
        const response = await axios.post(url + "reminder/",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * UPDATE MESSAGE
 * @param {*} data 
 * @returns 
 */
 export async function updateMessages(code,data) {
    try {
        const response = await axios.patch(url + "reminder/"+code,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * DELETE MESSAGE 
 * @param {*} code 
 * @returns 
 */
export async function deleteMessages(code) {
    try {
        const response = await axios.delete(url + "reminder/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET REMINDER BY CODE
 * @param {*} code 
 * @returns 
 */
export async function getReminderByCode(code) {
    try {
        const response = await axios.get(url + "reminder/code/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * GET LISTE REMINDER
 * @param {*} user_code 
 * @returns 
 */
export async function getListeReminder(user_code) {
    try {
        const response = await axios.get(url + "reminder/user/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * GET NOTIFICATIONS
 * @param {*} user_code 
 * @param {*} today 
 * @returns 
 */
export async function getNotifications(user_code,today) {
    try {
        const response = await axios.get(url + "reminder/notification/"+user_code+"/"+today, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}