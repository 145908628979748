import React from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import Loader from '../../../components/Loader/Loader'
import Chartjs from './Chartjs'
import EnteteStock from './EnteteStock'
import FiltreStock from './FiltreStock'
import TableauStock from './TableauStock'

export default function MainStock({t,loading,headerGammes, headerEnseignes,groupedByGammes,Labels,options, loadingChart, Saveenseignes ,datedebut ,setDatedebut ,  datefin , setDateFin , saveData ,codeEnseigne , searchInput ,  handleChangeEnseigne ,calculAverage,findElementTotal}) {
  return (
    <div><EnteteStock t={t} />
    <FiltreStock Saveenseignes={Saveenseignes}  datedebut={datedebut} setDatedebut={setDatedebut} t={t} datefin={datefin} setDateFin={setDateFin}  saveData={saveData} codeEnseigne={codeEnseigne} searchInput={searchInput} handleChangeEnseigne={handleChangeEnseigne} />
    <div className="row">
      <div className="col-12">
        <div className="float-left">
          <table className="table table-responsive">
            <tr className="fs-10 ">
              <td> Disponible : </td>
              <td style={{ background: "#D9F8C4" }}> D </td>
              <td> Rupture : </td>
              <td style={{ background: "#FFFDA2" }}> R </td>
              <td> Hors collection : </td>
              <td style={{ background: "#ABD9FF" }}> HA </td>
            </tr>
          </table>
        </div>
        <div className="float-right ">
          <ReactHtmlTableToExcel
            id="test-table-xls-button"
            className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 "
            table="table-to-xls"
            filename="Rapport_Rupture_Merch"
            sheet="tablexls"
            buttonText={
              <span className=" text-center">
                <i className="icon icon-share-alt mr-1" />{" "}
                {t("rapportrupture.exporter")}
              </span>
            }
          />
        </div>
        <div
          className="table table-responsive  product-datatable "
          style={{ overflow: "auto" }}
        >
          {loading ? (
            <Loader />
          ) : (
           <TableauStock headerGammes={headerGammes}  headerEnseignes={headerEnseignes} groupedByGammes={groupedByGammes} findElementTotal={findElementTotal} calculAverage={calculAverage}  />
          )}
          <Chartjs
            loadingChart={loadingChart}
            Labels={Labels}
            options={options}
          />
        </div>
      </div>
    </div></div>
  )
}
