import moment from 'moment'
import React from 'react'
import Marquee from 'react-fast-marquee'

export default function RelevePrixMarquuee({listemessages ,t}) {
  return (
    <div className="col-xl-12 col-md-12 col-lg-12">
                 
    <div className="best-ticker" id="newsticker1" style={{ height: "40px!important" }}>
        <div className="font-weight-bold bn-label fs-12 ">{t('dashboard.Information')} </div>

        <Marquee speed={40} gradientWidth={50}  >
            <ul className='mt-2'>
                <li>
                    <h5 className="font-weight-normal mt-3 mr-2 mb-2 fs-12" >
                            {listemessages?.map((items,keys)=>(
                                <b key={keys}> <b className='text-success'>{items.nompdv}</b> {"("+moment(items.date).format("DD-MM-YYYY")+") "+items.nom+"   , "} </b>
                            ))}
                    </h5>
                </li>
            </ul>
        </Marquee>
    </div>
</div>
  )
}
