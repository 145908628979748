/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import _ from 'lodash';
import { generateRandomColor } from '../../../utils/Colors';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function GraphGammes({ total,graphMonth, listeGammes, listeEnseigne, graphEnseigne, date1, date2, totalEnsGamme, loading ,t }) {
    //--------------- GRAPH 1 ---------------
    const [labels, setlabels] = useState([])
    const [data, setdata] = useState([])
    /**----------- Graphe 1 ----------------- */
    var [label, setlabel] = useState([])
    var [data1, setdata1] = useState([])
    var [color, setcolor] = useState([])
    /** ------------- Graphe 2 -----------  */
    var [label2, setlabel2] = useState([])
    var [data2, setdata2] = useState([])
    var [color2, setcolor2] = useState([])
    /** ------------- Graphe 3 -----------  */
    var [label3, setlabel3] = useState([])
    var [data3, setdata3] = useState([])
    var [color3, setcolor3] = useState([])

    useEffect(() => {


        //------------ GRAPHE 1 ------------------------
        const total_ensg = _.groupBy(totalEnsGamme, 'enseigne_code')
        const x = []
        // GROUPED Labels par enseigne exemple : ["monoprix","UHD"]
        graphEnseigne?.map((items, k) => {
            x.push(items.enseigne)
        })
        const l = _.uniq(x)
        setlabels(l)
        // Groupement par gamme 
        const gr = _.groupBy(graphEnseigne, 'libelle')
        const arr = []
        for (let value of Object.values(gr)) {
            const te = []
            const lg = value.length
            value.map((i, k) => {

                if (lg === l.length) {
                    te.push(parseFloat((i.total / total_ensg[i.enseigne_code][0]?.total) * 100).toFixed(2))
                } else {
                    l.map((y, z) => {
                        if (i.nom_enseigne === y) {
                            te.splice(z, z, parseFloat((i.total / total_ensg[i.enseigne_code][0]?.total) * 100).toFixed(2));
                        } else {
                            te.splice(z, z, 0);
                        }
                    })
                }
            })
            arr.push({
                label: value[0].libelle,
                backgroundColor: value[0].color,
                borderWidth: 1,
                data: te
            })
            setdata(arr)
        }
        /** lDognuts  */
        var l1 = []
        var d1 = []
        var c1 = []
        listeGammes?.map((items) => {
            l1.push(items.gamme + " (" + parseFloat(((items.total) / (total)) * 100).toFixed(2) + " %)")
            d1.push(parseFloat(((items.total) / (total)) * 100).toFixed(2))
            c1.push(items.color)
        })
        setlabel(l1)
        setdata1(d1)
        setcolor(c1)
        //-------------GRAPHE 3 --------------------------
        const pl = []
        graphMonth?.map((items, k) => {
            pl.push(items.gamme)
        })
        const pl1 = _.uniq(pl)
        setlabel3(pl1)
        const gr3 = _.groupBy(graphMonth, 'month')
        const arr3 = []
        for (let value of Object.values(gr3)) {
            const te = []
            const lg = value.length
            value.map((i) => {
                if (lg === pl1.length) {
                    te.push(parseFloat(i.sumtot).toFixed(3))
                } else {
                    pl1.map((y, z) => {
                        if (i.gamme === y) {
                            te.splice(z, z, parseFloat(i.sumtot).toFixed(3));
                        } else {
                            te.splice(z, z, 0);
                        }
                    })
                }
            })
            arr3.push({
                label: value[0].month,
                backgroundColor: generateRandomColor(),
                borderWidth: 1,
                data: te
            })
            setdata3(arr3)

        }
    }, [listeGammes])

    return (
        <>
            {/****--------- RUPTURE par ENSEIGNE ET GAMME ------------------ */}
            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12'>
                <div className='card'>
                    <div className='card-body'>
                        {loading ? <SqaureLoader /> :
                            <Bar
                                style={{ height: "400px" }}
                                data={{
                                    labels: labels,
                                    datasets: data
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    indexAxis: 'y',
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            possition: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: t('dashboard.rupturegammeE')+' '+ date1 + '-' + date2
                                        },
                                    },
                                }}
                            />
                        }

                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-xl-4 col-md-12 col-sm-12 col-12'>
                <div className='card'>
                    <div className='card-body'>
                        {loading ? <SqaureLoader /> : (

                            <Doughnut
                                style={{ height: "400px" }}
                                data={{
                                    labels: label,
                                    datasets: [
                                        {
                                            label: label,
                                            data: data1,
                                            backgroundColor: color,
                                            borderColor: color,
                                        }]
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            possition: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: t('dashboard.rupturepargamme')+' '+ date1 + '-' + date2
                                        },
                                    },
                                }}
                            />)}

                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12'>
                <div className='card'>
                    <div className='card-body'>
                    {loading ? <SqaureLoader /> :
                            <Bar
                                style={{ height: "400px" }}
                                data={{
                                    labels: label3,
                                    datasets: data3
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    indexAxis: 'x',
                                    plugins: {
                                        legend: {
                                            possition: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: t('dashboard.graphPartLineaire')+' '+ date1 + '-' + date2
                                        },
                                    },
                                }}
                            />
                        }
                    </div>
                </div>
            </div>
        </>

    )
}
