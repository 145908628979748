/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import Loader from '../components/Loader/Loader'
import { getContratUser, getLicence } from '../services/Users/UsersApi'
import Footer from '../shared/Footer'
import Header from '../shared/Header'
import RightSideBar from '../shared/RightSideBar'
import SideBar from '../shared/SideBar'

//------------------ UTILS ------------------

export default function OthersPages(props) {
    const [loading, setloading] = useState(true)
    const today = new Date()
    const [days, setdays] = useState("")
    const [backgroundColor, setbackgroundColor] = useState({ backgroundColor: "#edeff5" })
    const [message, setmessage] = useState("")
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    //GET ETAT CONTRAT 
    useEffect(() => {
        getContratUser().then((result) => {
            if (result?.data?.success === 1) {
                getLicence(result?.data?.getDetailsUsers?.contrat).then((result) => {
                    if (result?.status === 200) {
                        var data = result?.data // RESULTS
                        var date1 = new Date(data?.date_debut); // Date debut  Contrat
                        var date2 = new Date(data?.date_fin); // Date Fin
                        var difference = date2.getTime() - today.getTime();
                        var days_diff= Math.ceil(difference / (1000 * 3600 * 24));
                       
                        setdays(parseInt(days_diff))
                        if (date1 <= today && today<= date2) {
                            if (days_diff> 0 && days_diff <= 7) {
                                setmessage(t('VOTRE LICENCE SERA EXPIREE dans') +Math.abs(parseInt(days_diff))+t("Jours"));
                                setbackgroundColor({ backgroundColor: "#ffddd6" })
                            } else {
                                setmessage("")
                                setbackgroundColor({ backgroundColor: "#edeff5" })
                            }
                        } else {
                            setmessage(t("VOTRE LICENCE EST EXPIREE VEUILLEZ CONTACTER LA SOCIETE CLEDISS POUR RENOUVELER VOTRE COTNRAT "));
                            setbackgroundColor({ backgroundColor: "#ffddd6" })

                        }
                    }
                }).catch((err) => {
                    console.log(err)

                }).finally(() => {
                    setloading(false)
                })
            }else {
                setloading(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    }, [])

    return (
        <div>
            {/* -------------- Verifier si l'utilisateur est connecté ----------------------- */}
            {loading ? <Loader /> :
                <div className="page ">

                    <div className="page-main">
                        {/* -------- Top-header opened ----- */}
                        <Header />

                        <RightSideBar />
                        {/*-------------- Horizontal-menu  --------------*/}
                        <SideBar />
                        <div className="app-content icon-content" style={backgroundColor}>
                        <ToastContainer />
                            <div className="section">
                            {message.length !== 0 ? <div className="page-header">
                            <Marquee className='mt-3' speed={40} gradientWidth={50} >
                            <h5 className='text-danger'> {message}</h5>
                            </Marquee>
                            </div> : null}
                                <React.Fragment>{props.children}</React.Fragment>
                            </div>
                        </div>
                    </div>
                    {/*-------------- Footer opened -----------------*/}
{/*                     <Footer />
 */}                    {/*----------------- Footer closed ----------------*/}
                </div>}


        </div>
    )
}
