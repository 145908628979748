import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;
const url = REACT_APP_API_URL


/**
 * GET FILTRE KPI by DATE
 * @param {DATE} date  
 * @returns 
 */
export async function getFiltreKPI(date_debut,date_fin) {
    try {
        const response = await axios.get(url + "rapports/filtrekpiSales/" + date_debut+"/"+date_fin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status
    }
}