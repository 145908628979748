import moment from "moment";
import React, { useState } from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SqaureLoader from "../../../components/Loader/SqaureLoader";
import FiltreKpiSales from "./FiltreKpiSales";
import TabeauKpiSales from "./TabeauKpiSales";

export default function KPISales() {
  const { date_debut, date_fin } = useParams("")
  //------ History -----------------
  var today = moment(new Date()).format("YYYY-MM-DD");
  //------------------ VAR FILTRE --------------------------------
  //const [date, setdate] = useState(today); // DATE DE TRAVEAUX MERCH
  const [datedebut, setdatedebut] = useState(date_debut)
  const [datefin, setdatefin] = useState(date_fin)

  //---------------------- DATA OF EL. FILTRE --------------
  const [listeSocietes, setlisteSocietes] = useState([])
  const [listeRegion, setlisteRegion] = useState([]); //ARRAY OF REGION
  const [listeMerch, setlisteMerch] = useState([]); // ARRAY OF MERCH
  const [listeMG, setlisteMG] = useState([]); // ARRAT OF PDVS (Magasins )
  const [listeMG_GROUPED, setlisteMG_GROUPED] = useState([])
  const [saveListeMG, setsaveListeMG] = useState([]) // Save array pdvs
  const [savelisteMG_GROUPED, setsavelisteMG_GROUPED] = useState([]) // Save array pdvs

  const [listepointage, setlistepointage] = useState([]);
  // RESULTS
  const [ListeRuptures, setListeRuptures] = useState([]);
  const [saveListeRuptures, setsaveListeRuptures] = useState([]);

  const [gammes, setgammes] = useState([]);
  const [dates, setdates] = useState([]);

  //---------------------- HEADERS TABLE ---------------------------
  //HEADER ASSORTIMENTS
  const [headerAssortiments, setheaderAssortiments] = useState([]); //LISTE DES PRODUITS DISTINCTS
  const [listeproduits, setlisteproduits] = useState([]); //LISTE DES PRODUITS DISTINCTS

  const [groupedProduits, setgroupedProduits] = useState([]); // GROUPED PRODUITS for assortiments BY GAMME
  // HEADER QUALITE 
  const [headerQualite, setheaderQualite] = useState([]); //LISTE QUALITES
  const [groupedQualite, setgroupedQualite] = useState([]); // grouped qualite by equipement
  const [totalEquipement, settotalEquipement] = useState(0); // total equipement
  //-------------- VISIBILITE -----------------------------
  //HEADER VISIBILITE
  const [HeaderVisibilité, setHeaderVisibilité] = useState([]); // HEADER VISIBILTE
  const [groupedVisibilite, setgroupedVisibilite] = useState([]); // VISIBILTE
  const [totalVisibilite, settotalVisibilite] = useState(0); // Total visibilite eq
  // liste VISIBILITE QUALITE -----------------------------
  const [listeVisibilite, setlisteVisibilite] = useState([]);
  const [listeQualite, setlisteQualite] = useState([]);
  // HEADER RELEVE PRIX
  const [relevePrix, setRelevePrix] = useState([])
  const [saveReleveprix, setsaveReleveprix] = useState([])
  const [produit_releve, setproduit_releve] = useState([])
  // HEADER PL 
  const [headerGammePL, setheaderGammePL] = useState([])
  const [partLineaire, setpartLineaire] = useState([])
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //--------- Loader ------------------------
  const [loading, setloading] = useState(true);

  /**
   * FIND ETAT ASSORTIMENTS
   * @param {*} produit_code
   * @param {*} pdv_code
   * @returns
   */
  const findEtatAssProduit = (produit_code, pdv_code, date) => {
    var L = ListeRuptures?.filter(
      (el) => el.produit_code === produit_code && el.pdv_code === pdv_code && moment(el.date).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY")
    );

    if (L[0]?.etat_repture !== undefined) {
      if (L[0]?.etat_repture === 1 || L[0]?.etat_repture === "1") {
        return 0
      } else if (L[0]?.etat_repture === 0 || L[0]?.etat_repture === "0") {
        return 1
      }
    } else return "x"
  };

  /**
   * FIND ETAT QUALITE
   */
  const findEtatQualite = (equipement, libelle, pdv_code, date) => {
    var L = listeQualite?.filter(
      (el) =>
        el.equipement === equipement &&
        el.libelle === libelle &&
        el.pdv_code === pdv_code && moment(el.date).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY")
    );
    return L[0]?.etat !== undefined ? (L[0]?.etat) : "x";
  };
  /**
   * FIND ETAT VISIBILITE
   */
  const findEtatVisibiltie = (equipement, libelle, pdv_code, date) => {
    var L = listeVisibilite?.filter(
      (el) =>
        el.equipement === equipement &&
        el.libelle === libelle &&
        el.pdv_code === pdv_code && moment(el.date).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY")
    );
    return L[0]?.etat !== undefined ? L[0]?.etat : "x";
  };
  /**
   * FIND ETAT PRODUITS releve prix
   */
  const findRelevePrix = (produit_code, pdv_code, prix_min, prix_max, date) => {
    const L = relevePrix?.filter((el) => el.produit_code === produit_code && el.pdv_code === pdv_code && moment(el.date).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY"))
    if (L[0]?.prix >= prix_min && L[0]?.prix <= prix_max) {
      return <b className="text-purple">1</b>
    } else if (L[0]?.prix === undefined) {
      return "-"
    } else return <b className="text-danger">0</b>
  }
  const findPrixRelevePrix = (produit_code, pdv_code, date) => {
    const L = relevePrix?.filter((el) => el.produit_code === produit_code && el.pdv_code === pdv_code && moment(el.date).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY"))
    if (L[0]?.prix !== undefined) {
      return L[0]?.prix
    } else {
      return "-"
    }
  }
  const findPartLineaire = (produit_code, pdv_code) => {
    const L = partLineaire?.filter((el) => el.produit_code === produit_code && el.pdv_code === pdv_code)
    if (L[0]?.total !== undefined) {
      return parseFloat(L[0]?.total).toFixed(2)
    } else {
      return "-"
    }
  }
  const ComparePartLineaire = (gamme_code) => {
    const L = headerGammePL?.filter((el) => el.gamme_code === gamme_code)
    if (L[0]?.plcontrat_avg !== undefined) {
      return L[0]?.plcontrat_avg
    } else {
      return "-"
    }
  }

  return (
    <div>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/rapports">
              <i className="fe fe-life-buoy mr-1" /> {t("rapports.Rapport")}
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Rapport Sales
          </li>
        </ol>
      </div>
      <FiltreKpiSales
        t={t}
        setheaderAssortiments={setheaderAssortiments}
        setlisteproduits={setlisteproduits}
        listeMG={listeMG}
        setlisteMG={setlisteMG}
        listeMerch={listeMerch}
        setlisteMerch={setlisteMerch}
        listeRegion={listeRegion}
        setlisteRegion={setlisteRegion}
        ListeRuptures={ListeRuptures}
        setListeRuptures={setListeRuptures}
        saveListeRuptures={saveListeRuptures}
        setsaveListeRuptures={setsaveListeRuptures}
        setheaderQualite={setheaderQualite}
        setgroupedQualite={setgroupedQualite}
        setgammes={setgammes}
        setdates={setdates}
        setgroupedProduits={setgroupedProduits}
        settotalEquipement={settotalEquipement}
        setHeaderVisibilité={setHeaderVisibilité}
        setgroupedVisibilite={setgroupedVisibilite}
        settotalVisibilite={settotalVisibilite}
        setlisteVisibilite={setlisteVisibilite}
        setlisteQualite={setlisteQualite}
        setlistepointage={setlistepointage}
        setdatedebut={setdatedebut}
        setdatefin={setdatefin}
        datedebut={datedebut}
        datefin={datefin}
        setRelevePrix={setRelevePrix}
        saveReleveprix={saveReleveprix}
        setsaveReleveprix={setsaveReleveprix}
        setproduit_releve={setproduit_releve}
        saveListeMG={saveListeMG}
        setsaveListeMG={setsaveListeMG}
        setheaderGammePL={setheaderGammePL}
        setpartLineaire={setpartLineaire}
        setlisteSocietes={setlisteSocietes}
        listeSocietes={listeSocietes}
        setlisteMG_GROUPED={setlisteMG_GROUPED}
        listeMG_GROUPED={listeMG_GROUPED}
        setsavelisteMG_GROUPED={setsavelisteMG_GROUPED}
        savelisteMG_GROUPED={savelisteMG_GROUPED}
        setloading={setloading}
      />
      {loading ? (
        <SqaureLoader />
      ) : (
        <div>
          {listepointage?.length === 0 ? (
            <div className="alert bg-purple mb-5 py-4" role="alert">
              <div className="d-flex">
                <i className="icon icon-info mr-3 fs-30" />
                <div>
                  <h4 className="alert-heading">
                    Veuillez sélectionner une date valide
                  </h4>
                  <p> </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="float-right mb-3">
                <ReactHtmlTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-azure btn-pill fs-12 mt-0 "
                  table="table-to-xls"
                  filename="Rapport_KPI"
                  sheet="tablexls"
                  buttonText={
                    <span className=" text-center">

                      <i className="icon icon-share-alt mr-1" />
                      {t("rapportrupture.exporter")}
                    </span>
                  }
                />
              </div>
              <TabeauKpiSales listeMG_GROUPED={listeMG_GROUPED} listeproduits={listeproduits} dates={dates} ComparePartLineaire={ComparePartLineaire} findPartLineaire={findPartLineaire} headerGammePL={headerGammePL} headerAssortiments={headerAssortiments} totalEquipement={totalEquipement} totalVisibilite={totalVisibilite} produit_releve={produit_releve} setdatedebut={setdatedebut} setdatefin={setdatefin} datedebut={datedebut} datefin={datefin} partLineaire={partLineaire} gammes={gammes} groupedProduits={groupedProduits} headerQualite={headerQualite} groupedQualite={groupedQualite} HeaderVisibilité={HeaderVisibilité} groupedVisibilite={groupedVisibilite} listeMG={listeMG} findEtatAssProduit={findEtatAssProduit} findEtatVisibiltie={findEtatVisibiltie} findEtatQualite={findEtatQualite} findRelevePrix={findRelevePrix} findPrixRelevePrix={findPrixRelevePrix} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
