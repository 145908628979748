/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ToastContainer } from 'react-toastify';
import TableauRuptureEnseignes from './TableauRuptureEnseignes';
import TableauRupturePDV from './TableauRupturePDV';
import TableauRuptureProduit from './TableauRuptureProduit';

export default function RuptureAnnuel() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")

    //url parametre
    const { year } = useParams('')
    const [dateYear, setdateYear] = useState(year);
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.Ruptures')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('sidebar.ruptureAnnuel')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href={"/ruptures/details&datedebut="+date1+"&datefin="+date2} className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-settings mr-1" />
                            {t('sidebar.detailRuptures')}
                        </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="col-12">
                <div className="row ">
                    <TableauRuptureEnseignes t={t} dateYear={dateYear} />
                </div>
                <div className="row ">
                    <TableauRupturePDV t={t} dateYear={dateYear} />
                </div>
                <div className="row ">
                    <TableauRuptureProduit t={t} dateYear={dateYear} />
                </div>
            </div>
        </div>
    )
}
