/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getDetailsUsers, getHistoriquesUser } from '../../services/Users/UsersApi';
import Gallerie from './Gallerie';
import Profile from './Profile';
import Tournee from './Tournee';
import TravauxMerch from './TravauxMerch';
import moment from "moment"
import Loader from '../../components/Loader/Loader';
import MapVisitesMerch from './MapVisitesMerch';
export default function DetailsMerch() {

    const { user_code } = useParams("")
    const [detailsUser, setdetailsUser] = useState({})
    const [listeTournee, setlisteTournee] = useState([])
    const [gallerie, setgallerie] = useState([])
    const [saveList, setSaveList] = useState([])
    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const [year, setYear] = useState(moment(firstDay).format('YYYY'))
    //TRAVEAUX MERCH
    const [nbrRuptureMois, setnbrRuptureMois] = useState([])
    const [nbrBisite, setnbrBisite] = useState([])
    const [partLineaire, setpartLineaire] = useState([])
    const [VisiteMagasins, setVisiteMagasins] = useState([])
    //LAST YEAR
    const [nbrRuptureMois2, setnbrRuptureMois2] = useState([])
    const [partLineaire2, setpartLineaire2] = useState([])
    const [nbrBisite2, setnbrBisite2] = useState([])
    //------------- LOADING ------------------
    const [loading, setloading] = useState(true)
    const [loadingTable, setloadingTable] = useState(false)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    
    
    useEffect(() => {
        getHistoriquesUser(user_code,year).then((result) => {
            if (result?.data?.success === 1) {
                setnbrRuptureMois(result?.data?.nbrRuptures)
                setpartLineaire(result?.data?.partlineaire)
                setnbrBisite(result?.data?.nbrVisites)
                setVisiteMagasins(result?.data?.vistePDV)
            }
        }).catch((err) => {
            console.log(err)
        });
        getHistoriquesUser(user_code, parseInt(year)-1).then((result) => {
            if (result?.data?.success === 1) {
                setnbrRuptureMois2(result?.data?.nbrRuptures)
                setpartLineaire2(result?.data?.partlineaire)
                setnbrBisite2(result?.data?.nbrVisites)
            }
        }).catch((err) => {
            console.log(err)
        });
        getDetailsUsers(user_code).then((result) => {
            if (result?.data?.success === 1) {
                setdetailsUser(result?.data?.getDetailsUsers)
                setlisteTournee(result?.data?.tournee)
/*                 setgallerie(result?.data?.gallerie)
 */                setSaveList(result?.data?.tournee)

            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }, [user_code])
    const onChangeYear = (e) => {
        setloadingTable(true)
        setYear(e.target.value)

        getHistoriquesUser(user_code,e.target.value).then((result) => {
            if (result?.data?.success === 1) {
                setnbrRuptureMois(result?.data?.nbrRuptures)
                setpartLineaire(result?.data?.partlineaire)
                setnbrBisite(result?.data?.nbrVisites)
            }
        }).catch((err) => {
            console.log(err)

        });
        getHistoriquesUser(user_code, parseInt(e.target.value)-1).then((result) => {
            if (result?.data?.success === 1) {
                setnbrRuptureMois2(result?.data?.nbrRuptures)
                setpartLineaire2(result?.data?.partlineaire)
                setnbrBisite2(result?.data?.nbrVisites)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloadingTable(false)
        })
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.utilisateurs')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">Détails Merchandiseur</li>
                </ol>

            </div>
            <div className="row" id="user-profile">
                {loading ? <Loader /> : (
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="wideget-user">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-6 col-md-12">
                                            <div className="wideget-user-desc d-flex">
                                                <div className="wideget-user-img">
                                                    <img className="rounded-circle" src={detailsUser?.image === "" || detailsUser?.image === "NULL" ? "../assets/images/users/2.jpg" : detailsUser?.image} style={{ height: "150px" }} alt="USER" />
                                                </div>
                                                <div className="user-wrap mt-lg-0">
                                                    <h4>{detailsUser?.nom}</h4>
                                                    <h6 className="text-muted mb-3 font-weight-normal">Inscrit depuis : {moment(detailsUser?.userCreated).format('DD MMMM, YYYY')}</h6>
                                                    <a href={"/users/modifierutilisateur&id=" + user_code} className="btn btn-info fs-12 mt-2"><i className="icon icon-note mr-1" /> Modifier</a>
                                                 
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-top">
                                <div className="wideget-user-tab p-4">
                                    <div className="tab-menu-heading">
                                        <div className="tabs-menu1">
                                            <ul className="nav">
                                                <li className><a href="#tab-51" className="active show" data-toggle="tab">Profile</a></li>
                                                <li><a href="#tab-61" data-toggle="tab" >Tournée</a></li>
{/*                                                 <li><a href="#tab-71" data-toggle="tab" className>Gallerie</a></li>
 */}                                                <li><a href="#tab-81" data-toggle="tab" >Travaux Merchandiseur</a></li>
                                                <li><a href="#tab-82" data-toggle="tab" >Visites Magasins</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        
                        <div className="card">
                            <div className="card-body">
                                <div className="border-0">
                                    <div className="tab-content">
                                        <Profile detailsUser={detailsUser} t={t} />
                                        <Tournee listeTournee={listeTournee} saveList={saveList} t={t} />
{/*                                         <Gallerie gallerie={gallerie} t={t} />
 */}                                        <TravauxMerch  t={t} year={year} onChangeYear={onChangeYear} nbrRuptureMois={nbrRuptureMois} partLineaire={partLineaire} nbrBisite={nbrBisite}
                                         nbrRuptureMois2={nbrRuptureMois2} partLineaire2={partLineaire2} nbrBisite2={nbrBisite2} loadingTable={loadingTable}
                                        />
                                        <MapVisitesMerch VisiteMagasins={VisiteMagasins} loading={loading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>

        </div>
    )
}
