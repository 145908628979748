import React, { useRef, useState } from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast } from 'react-toastify';
import { addTourneeNewPdv } from '../../../services/Routings/RoutingsApi';

export default function ModalAddPdv({ t, tournee, frequence, routing, code_jour, listepdv ,datedebut , datefin , setok , user_code}) {
    //------------- REFERENCES -------------------
    const searchInput = useRef();
    //--------- code pdv -----------
    const [codePDV, setcodePDV] = useState("")
    const [dates, setdates] = useState("")
    // Handle Change Marque
    const handleChangePdv = (...args) => {
        setcodePDV(args[1].value)
    };

    const addPdvOnListe = () => {
        const data = {
            code: tournee,
            code_jour: code_jour,
            routing_code: routing,
            pdv_code: codePDV,
            frequence: frequence,
            date_debut :datedebut,
            date_fin : datefin,
            rang : 1,
            actif : "1"
        }
        if(frequence==="Par_dates"){
            Object.assign(data,{
                dates : dates,
                user_code : user_code
            })
        }
        if(frequence !== "Par_dates" && codePDV !== ""){
            addTourneeNewPdv(data).then((result) => {
                if(result?.data?.success === 1){
                    toast(t("messageErreur.ajoutavecsuccee"),{type:"success",position:'top-right'})
                }
        }).catch((err) => {
            console.log(err)
        });
        }
       else if(dates>= datedebut && dates <= datefin && dates !== ""){
            addTourneeNewPdv(data).then((result) => {
                    if(result?.data?.success === 1){
                        toast(t("messageErreur.ajoutavecsuccee"),{type:"success",position:'top-right'})
                    }
            }).catch((err) => {
                console.log(err)
            });

        }else{
            toast(t("messageErreur.saisiedatevalide"),{type:"warning",position:"top-right"})
        }

      //  if(re)
    }
    return (
        <div id="largeModal" className="modal fade">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content ">
                    <div className="modal-header pd-x-20">
                        <h6 className="modal-title">{t('Magasins.AjouterPDV')} </h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={() => setok(true)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body pd-20">
                        <div className='row'>
                                <div className='col-12 '>
                                <h6 className=" lh-3 mg-b-20"><a href className="font-weight-bold">{t("Ajouter Pdv dans la tournée")} : <span className='text-azure fs-10'> ({tournee}) </span> </a></h6>

                                    {frequence !== "Par_dates" ? null :                                 
                                    <input type="date" className="form-control text-primary fs-12 mb-2" value={dates} onChange={(e)=>setdates(e.target.value)} />}
                                <SelectSearch
                                    options={listepdv}
                                    search
                                    ref={searchInput}
                                    onChange={handleChangePdv}
                                    filterOptions={fuzzySearch}
                                    emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                    placeholder={t('Magasins.Magasins')}
                                />
                            </div>
                        </div>


                    </div>{/* modal-body */}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-azure fs-12" onClick={()=>addPdvOnListe()} > <i className='icon icon-plus' /> {t('Routings.confirmer')} </button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal"  onClick={() => setok(true)}><i className='icon icon-close' /> {t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>{/* modal-dialog */}
        </div>

    )
}
