import React, { useEffect, useRef, useState } from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { toast } from 'react-toastify';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { updateObjectSousFamille } from '../../../services/Produits/configurationProduits/ConfigurationAPI';

export default function EditModalSF({ keys, setShowEdit, listefamilles, setShow, object, t }) {

    //-------- REFERENCES --------------------
    const familles = useRef();
    //------- FORMULAIRE --------------
    const [code, setCode] = useState("")
    const [libelle, setLibelle] = useState("")
    const [codeFamilles, setcodeFamilles] = useState("")
    const [actif, setactif] = useState(0)
    //---------- loader -----------
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        setCode(object.code)
        setLibelle(object.libelle)
        setcodeFamilles(object.famille_code)

        setLoading(false)
    }, [object])
    // Handle Change Famille
    const handleFamilles = (...args) => {
        setcodeFamilles(args[1].value)
    };
    // SAVE UPDATES
    const saveData = () => {
        //Data of code & libelle FOR OBJECT PAYS
        if (code !== "" && libelle !== "") {
            const data = {
                code: code,
                libelle: libelle,
                actif: actif,
                famille_code: codeFamilles
            }
            updateObjectSousFamille(code, data)
                .then((result) => {
                    if (result?.data?.success === 0) {
                        toast(t('Zoning.Erreur'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        toast("sous famille a été ajouté avec succée !", {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    return (
        <div className="modal fade" id="editSF" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="editSF">  <i className=' icon icon-equalizer mr-1 text-azure' /> {t('Produits.modifiersfamille')}  </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} /> </span>

                    </div>
                    {loading ? <SqaureLoader /> : (
                        <div className="modal-body">
                            <form>
                                <div className='row'>
                                <div className="form-group col-6">
                                    <label className="form-control-label">{t('rapports.code')} :</label>
                                    <input type="text" disabled value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="mr-2 mt-1 mb-sm-1"> {t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                    <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} className="form-control" />

                                </div>
                                <div className="form-group col-6">
                                   <label className="mr-2 mt-1 mb-sm-1"> {t('Routings.ACTIF')} <span className="text-danger">*</span></label>
                                   <select className="form-control br-md-0" value={actif} onChange={(e) => setactif(e.target.value)}>
                                       <option value={1}>{t('rapports.oui')}</option>
                                       <option value={0}>{t('rapports.non')}</option>
                                   </select>

                               </div>
                                    
                                </div>
                               
                                <div className="form-group">
                                    <label className="form-control-label">{t('Produits.FAMILLE')} :  <span className="text-danger">*</span></label>
                                    <SelectSearch
                                        options={listefamilles}
                                        search
                                        ref={familles}
                                        value={codeFamilles}
                                        onChange={handleFamilles}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Produits.choisirFamille')}
                                    />
                                </div>

                            </form>
                        </div>
                    )}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-azure fs-12" onClick={() => saveData()} > <i className='icon icon-cloud-download mr-1' /> {t('rapports.enregistrer')} </button>
                        <button type="button" className="btn btn-secondary  fs-12" data-dismiss="modal" onClick={() => setShow(false)}><i className="ti-close mr-1" />{t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
