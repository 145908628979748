/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { ruptureGamme } from '../../../services/Magasins/PdvApi';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function RuptureGamme({code,year,t  }) {
    
    const [label, setlabel] = useState([])
    const [data, setdata] = useState([])
    const [color, setcolor] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(()=>{
    //Current Year
    ruptureGamme(code,year).then((result) => {
        if(result?.data?.success===1){
            var l = []
            var d = []
            var c = []
            result?.data?.rupturegamme.map((items,keys) => {
                l.push(items.gammes + " (" + items.total + ")")
                d.push(items.total)
                c.push(items.color)
            })
            setTimeout(() => {
                setlabel(l)
                setdata(d)
                setcolor(c)
                 
            }, (3000));
         
        }
    }).catch((err) => {
        console.log(err)
    }).finally(()=>{
        setloading(false)
    })
    },[])

    return (
        <div className="panel-group" id="acc7" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
        <div className="panel panel-default active">
            <div className="panel-heading " role="tab" id="hav7">
                <h4 className="panel-title text-primary">
                    <a role="button" data-toggle="collapse" data-parent="#acc7" href="#collapse7" aria-expanded="true" aria-controls="collapse7">
                        <i className="icon icon-folder-alt mr-2" /> {t('dashboard.Gamme')}/ {t('sidebar.Ruptures')}
                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                    </a>
                </h4>
            </div>
            <div id="collapse7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="hav7">
                <div className="panel-body">
                    <div className="panel panel-body">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-12 ">
                            {loading ? <SqaureLoader /> : (
                                data.length === 0 ?
                                <h6 className="text-danger text-center"> 
                                {t('Zoning.Aucunresultat')}
                                </h6>:
                                <Doughnut
                                style={{ height: "400px" }}
                                    data={{
                                        labels: label,
                                        datasets: [
                                            {
                                                label: label,
                                                data: data,
                                                backgroundColor: color,
                                                borderColor: color,
                                            }]
                                    }}
                                 
                                    options={{
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                possition: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: t('dashboard.rupturepargamme')
                                            },
                                        },
                                     
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    </div>
                </div>
             {/*    <div className="card-footer">
                    <h6 style={{ fontSize: "12px" }}> <span className=" text-info"> <i className="icon icon-exclamation mr-2" /> {t('dashboard.Information')} :</span> Couleur Gamme <span className="text-danger"> * </span>  Vous devez attribuer une couleur pour chaque gamme pour visualiser le graphe</h6>

                </div> */}
            </div>
        </div>
        </div>
    )
}
