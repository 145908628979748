import { useState } from "react";

export const useToken = () => {
  const [token, setTokenInternal] = useState(() => {
    return localStorage.getItem("user");
  });

  const setToken = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    setTokenInternal(data);
  };
  return [token, setToken];
};
