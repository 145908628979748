import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

export default function TableauRelevePrix({ liste, listeGrouped, t }) {

    const [datess, setdatess] = useState([])

    useEffect(() => {
        var test = _.sortBy(liste, 'date');
        const p = _.groupBy(test, function (item) {
            return moment(item.date).format("YYYY-MM-DD")
        });
        const gr = Object.keys(p)
        setdatess(gr)
    }, [liste])

    const findOnListe = (produit_code, pdv_code, date) => {
        const findEl = liste?.filter((el) => el.produit_code === produit_code  && el.pdv_code === pdv_code && moment(el.date).format("YYYY-MM-DD") === date)
        if (findEl[0]?.permanent === undefined || findEl[0]?.promo === undefined )  {
            return null
        } else return findEl[0]
    }
    return (
        <div className='col-12'>
              <div className='float-right '>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 "
                    table="table-to-xls"
                    filename="Rapport_Suivi_Releve_Prix"
                    sheet="tablexls"
                    buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                />
            </div> 
            <div className="table-responsive  product-datatable ">
                <table id="table-to-xls" className="table  table-bordered  mt-2">
                    <thead className="text-center bg-azure-lighter" style={{ position: "-webkit-sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>

                        <tr>
                            <th className="text-center" colSpan={6}></th>
                            {datess?.map((items, k) => (
                                <th className="text-center b-top b-left  b-right fs-10" key={k} colSpan={"2"}> {moment(items).format("DD/MM/YYYY")} </th>
                            ))}
                        </tr>
                        <tr>
                            <th className="w-15p fs-10">{t('rapportrupture.merch')}</th>
                            <th className="w-15p fs-10">{t('dashboard.Magasin')}</th>
                            <th className="w-15p fs-10">{t('rapportrupture.codeproduit')}</th>
                            <th className="w-15p fs-10">{t('rapports.CODEABARRE')}</th>
                            <th className="w-15p fs-10">{t('Produits.SousFamille')}</th>
                            <th className="w-15p fs-10">{t('rapportrupture.produit')}</th>
                            {datess?.map((items, k) => (
                                <>
                                  <th className="text-center b-left b-right fs-10 "  key={k}>{t('relevePrix.permanenet')}</th>
                                  <th className="text-center b-left b-right fs-10 "  key={k}>{t('relevePrix.promo')}</th>
                                </>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='bg-white'>
                        {listeGrouped?.map((items, keys) => (
                             <tr key={keys} className="fs-10 text-center">
                                 <td> {items.nom}</td>
                                 <td> {items.nompdv}</td>
                                 <td> {items.produit_code}</td>
                                 <td> {items.cab}</td>
                                 <td> {items.sousfamilles}</td>
                                 <td> {items.libelle}</td>
                                 {datess?.map((i, k) => (
                                     <>
                                    <td key={k}>{findOnListe(items.produit_code, items.pdv_code, i) !== null ?findOnListe(items.produit_code, items.pdv_code, i)?.permanent :"-" }</td>
                                    <td key={k}>{findOnListe(items.produit_code, items.pdv_code, i) !== null ?findOnListe(items.produit_code, items.pdv_code, i)?.promo :"-" }</td>
                                     </>
                                ))}
                             </tr>
                        ))} 
                    </tbody>
                </table>
            </div>
        </div>
    )
}
