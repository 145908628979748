/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getListeNotes, getListeNotifications, getListeProduitsNotifications } from '../services/Notifications/NotifApi';
import { useHistory } from "react-router-dom";
import IdleTimer from '../idleTimer';
import { getListeGratuite } from '../services/Produits/ProduitsAPI';
import { getNotifications } from '../services/Messages/MessagesAPI';

export default function Header() {

    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
    const [listeNotes, setlisteNotes] = useState([])
    const [listeProduits, setlisteProduits] = useState([])
    const [listeMessages, setlisteMessages] = useState([])
    // ---- outil de Traduction ----------
    const [t, i18n] = useTranslation('global')
    // ----- Language global selectionné
    const language = Cookies.get('locale')
    const [lg, setLg] = useState(language === 'fr' ? 'Français' : language === "ar" ? "Arabe" : "English")
    const [isOpen, setIsOpen] = useState(false) // theme dark/light

    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")
    var today = moment(date).format("YYYY-MM-DD")
    const [isTimeout, setisTimeout] = useState(false)
    { language === "ar" ? require('moment/locale/ar-tn') :language === "fr"  ? require('moment/locale/fr') :require('moment/locale/en-au') }
    const [listeGratuite, setlisteGratuite] = useState([])
    //----------------
    const [dlc, setDlc] = useState([])
    const [messages, setmessages] = useState([])
    const history = useHistory();
    // SELECT CHANGE LANGUAGES 
    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
        if (language === 'ar') {
            setLg('Arabe')
        } else if (language === 'fr') {
            setLg('Français')
        } else if (language === 'en') {
            setLg('English')
        }
        Cookies.set('locale', language)
    }
    const seDeconnecter = () => {
        localStorage.removeItem("user");
        history.push({
            pathname: "/login"
        })
    }

    const handleClick = () => {
        if (isOpen) {
          document.body.classList.remove('dark-mode')
          document.body.classList.remove('app')
          setIsOpen(!isOpen)
        } else {
          document.body.classList.add('app')
          document.body.classList.add('dark-mode')
          setIsOpen(!isOpen)
        }
      }
    useEffect(() => {
        getListeGratuite().then((result) => {
            if(result?.data?.success === 1){
                setlisteGratuite(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        getNotifications(details?.code,today).then((result) => {
            if(result?.data?.success===1){
                setlisteMessages(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getListeNotifications(today, today)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setDlc(result?.data.DLC)
                }
            }).catch((err) => {
                console.log(err)
            });
            getListeNotes(today)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteNotes(result?.data.notes)
                }
            }).catch((err) => {
                console.log(err)
            });
            getListeProduitsNotifications(today)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteProduits(result?.data.produits)
                }
            }).catch((err) => {
                console.log(err)
            });
     /*    const timer = new IdleTimer({
            timeout: 3000, //expire after 10 seconds
            onTimeout: () => {
                setisTimeout(true);
            },
            onExpired: () => {
                // do something if expired on load
                setisTimeout(true);
            }
        });

        return () => {
            timer.cleanUp();
        } */
    }, [])
/*     useEffect(()=>{
        if(isTimeout=== true){
            seDeconnecter()
        }
    },[isTimeout])
 */
    return (
        <div className="header-main header sticky" style={{ overflow: 'unset' }} >
            <div className="app-header header top-header navbar-collapse " style={{ overflow: 'unset' }}>
                <div className="container-fluid">
                    <div className="d-flex">
                        <a className="header-brand" href="/">
                            <img
                                src="../assets/images/brand/logo.png"
                                className="header-brand-img desktop-logo "
                                alt="Dashlot logo"
                            />
                            <img
                                src="../assets/images/brand/logo1.png"
                                className="header-brand-img desktop-logo-1 "
                                alt="Dashlot logo"
                            />
                            <img
                                src="../assets/images/brand/favicon.png"
                                className="mobile-logo"
                                alt="Dashlot logo"
                            />
                            <img
                                src="../assets/images/brand/favicon1.png"
                                className="mobile-logo-1"
                                alt="Dashlot logo"
                            />
                        </a>
                    {/*     {isTimeout ? seDeconnecter()
                            : null} */}
                        <a href="#" data-toggle="sidebar" className="nav-link icon toggle">
                            <i className="fe fe-align-justify fs-20" />
                        </a>
                        <div className="form-group mt-3">
                            <select
                                className="form-control select2 text-primary fs-12 "
                                value={language}
                                onChange={(e) =>
                                    changeLanguage(e.target.value)
                                } >
                                <option value="fr" > Français</option>
                                <option value="ar"> العربية</option>
                                <option value="en"> English </option>

                            </select>
                            
                        </div><a className='text-primary ml-3 mt-5' href='/documentation' style={{cursor:"pointer"}}> <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-file-text mr-2" viewBox="0 0 16 16">
                            <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                         </svg>
                        <b>Documentation</b></a>

                        <div className="d-flex header-right ml-auto">

                            {/* Fullscreen */}

                            {/* Search */}
                            <div className="text-success mt-2 mb-2  ml-auto">
                                <a href='/calendrier' className=" btn bg-purple icon-bg icon-service text-white">
                                    <b>
                                        <i className='icon icon-calendar fs-12 '
                                            width={80}
                                            height={80} />
                                    </b>
                                </a>
                            </div>
                            <div className="text-success mt-2 mb-2  ml-2">
                                <a href={'/images/' + today} className=" btn bg-pink icon-bg icon-service text-white">
                                    <b>
                                        <i className='icon icon-picture fs-12 '
                                            width={80}
                                            height={80} />
                                    </b>
                                </a>

                            </div>
                               {/* Message- Notes  */}
                               <div className="dropdown d-md-flex d-cart">
                                <a className="nav-link icon" data-toggle="dropdown">
                                    <i className="icon icon-envelope " />
                                    <span className="badge badge-danger pulse-danger fs-10"> {listeMessages?.length}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right animated bounceInDown dropdown-menu-arrow">
                                    <div className="dropdown-header bg-header-image text-white w-300 p-5 mb-2">
                                        <h5 className="dropdown-title mb-1 font-weight-semibold text-drak fs-14">
                                           {t("Messages")} ( {t("sidebar.notes")})
                                        </h5>
                                        <p className="dropdown-title-text subtext mb-0 pb-0 fs-10 ">
                                            {t('rapportDLC.vousavez')}  <b>{dlc?.length}</b>  {t("Messages")}
                                        </p>
                                        <h6 className="fs-12 mt-1" >{t('dashboard.Le')} {moment(today).format("DD-MM-YYYY")}</h6>
                                    </div>
                                    <div className="drop-cart" style={{ overflow: 'scroll', height: "300px" }}>

                                        {listeMessages?.length === 0 ? <>
                                            <div className="border-bottom">
                                                <div className="d-flex pl-3 pr-4 pt-2 pb-3 cart align-items-center">
                                                   {t('Aucun message')}
                                                </div>
                                            </div>
                                        </> : listeMessages?.map((items, keys) => (
                                            <div className="border-bottom" key={keys}>
                                                <div className="d-flex pl-3 pr-4 pt-2 pb-3 cart align-items-center">

                                                    <div className="flex dropdown-cart">
                                                        <div className="d-flex align-items-center">
                                                            <div className="pl-3">
                                                            <span className="fs-12 font-weight-semibold d-block">
                                                                    {items.objet}
                                                                </span>
                                                                <span className="fs-14 font-weight-semibold d-block">
                                                                    {items.message}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="dropdown-divider mb-0 mt-0" />
                                    <a href={"/messages/datedebut=" + date1 + "&datefin=" + date2} className="dropdown-item text-center p-3">
                                       {t("Consulter les messages")} ( {t("sidebar.notes")})
                                    </a>
                                </div>
                            </div>
                            {/* Message-box */}
                            <div className="dropdown d-md-flex d-cart">
                                <a className="nav-link icon" data-toggle="dropdown">
                                    <i className="icon icon-drawer " />
                                    <span className="badge badge-danger pulse-danger fs-10"> {dlc?.length}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right animated bounceInDown dropdown-menu-arrow">
                                    <div className="dropdown-header bg-header-image text-white w-300 p-5 mb-2">
                                        <h5 className="dropdown-title mb-1 font-weight-semibold text-drak fs-14">
                                            {t('sidebar.DLC')}
                                        </h5>
                                        <p className="dropdown-title-text subtext mb-0 pb-0 fs-10 ">
                                            {t('rapportDLC.vousavez')}  <b>{dlc?.length}</b>  {t('rapportDLC.dlc')}
                                        </p>
                                        <h6 className="fs-12 mt-1" >Le {moment(today).format("DD-MM-YYYY")}</h6>
                                    </div>
                                    <div className="drop-cart" style={{ overflow: 'scroll', height: "300px" }}>

                                        {dlc?.length === 0 ? <>
                                            <div className="border-bottom">
                                                <div className="d-flex pl-3 pr-4 pt-2 pb-3 cart align-items-center">
                                                    {t('rapportDLC.auncenotif')}
                                                </div>
                                            </div>

                                        </> : dlc?.map((items, keys) => (
                                            <div className="border-bottom" key={keys}>
                                                <div className="d-flex pl-3 pr-4 pt-2 pb-3 cart align-items-center">

                                                    <div className="flex dropdown-cart">
                                                        <div className="d-flex align-items-center">
                                                            <div className="pl-3">
                                                                <span className="fs-14 font-weight-semibold d-block">
                                                                    {items.produits}
                                                                </span>
                                                                <div className="para fs-12 text-muted">
                                                                    {t('dashboard.Magasin')} : {items.magasin} , {t("Quantite")} :{items.quantite}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                    <div className="dropdown-divider mb-0 mt-0" />
                                    <a href={"/produits/dlc&datedebut=" + date1 + "&datefin=" + date2} className="dropdown-item text-center p-3">
                                        {t('rapportDLC.visiterlapagerapportDLC')}
                                    </a>
                                </div>
                            </div>
                            {/**--------------------- NOTIFICATION NOTES ----------------------------------- */}
                            <div className="dropdown d-md-flex message">
                                <a className="nav-link icon" data-toggle="dropdown">
                                    <i className="typcn typcn-messages" />
                                    <span className="badge badge-secondary pulse-secondary">{listeNotes?.length}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right animated bounceInDown dropdown-menu-arrow">
                                    <div className="dropdown-header bg-header-image text-white w-300 p-5 mb-2">
                                        <h5 className="dropdown-title mb-1 font-weight-semibold text-drak"> {t('sidebar.notes')}</h5>
                                        <p className="dropdown-title-text subtext mb-0 pb-0 fs-10 ">
                                            {t('rapportDLC.vousavez')} <b>{listeNotes?.length}</b>  {t('sidebar.notes')}
                                        </p>                                </div>
                                    <div className="drop-scroll" style={{ overflow: 'scroll', height: "300px" }}>
                                         {listeNotes?.length === 0 ? <>
                                            <div className="border-bottom">
                                                <div className="d-flex pl-3 pr-4 pt-2 pb-3 cart align-items-center">
                                                  {t('Aucune notification note')}
                                                </div>
                                            </div>

                                        </> :listeNotes?.map((items, keys) => (
                                            <a href="#" className="p-3 d-flex border-bottom" key={keys}>
                                                <div className="w-80">
                                                    <div className="d-flex">
                                                        <h5 className="mb-2 fs-12">{items.objet}</h5>
                                                    </div>
                                                    <p className="mb-1 fs-12">{items.message}</p>
                                                    <span className="font-weight-normal fs-13 text-muted fs-10">{moment(items.created_at).format('DD-MM-YYYY')}</span>
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                    <div className="dropdown-divider mb-0 mt-0" />
                                    <a href={"/notes/datedebut=" + date1 + "&datefin=" + date2} className="dropdown-item text-center p-3">
                                        {t('Liste des notes')} 
                                    </a>
                                </div>

                            </div>
                            {/**--------------------- NOTIFICATION DEMANDE GRATUITE ------------------------ */}
                            <div className="dropdown d-md-flex message">
                                <a className="nav-link icon" data-toggle="dropdown">
                                    <i className="ti-announcement" />
                                    <span className="badge badge-primary pulse-primary">{listeGratuite?.length}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right animated bounceInDown dropdown-menu-arrow">
                                    <div className="dropdown-header bg-header-image text-white w-300 p-5 mb-2">
                                        <h5 className="dropdown-title mb-1 font-weight-semibold text-drak"> {t('sidebar.validationGratuite')}</h5>
                                        <p className="dropdown-title-text subtext mb-0 pb-0 fs-10 ">
                                            {t('rapportDLC.vousavez')} <b>{listeGratuite?.length}</b>  {t('rapports.gratuite')}
                                        </p>                                </div>
                                    <div className="drop-scroll" style={{ overflow: 'scroll', height: "300px" }}>
                                        {listeGratuite?.length === 0 ? <>
                                            <div className="border-bottom">
                                                <div className="d-flex pl-3 pr-4 pt-2 pb-3 cart align-items-center">
                                                   {t("Aucune notification demande gratuité")}
                                                </div>
                                            </div>

                                        </> :listeGratuite?.map((items, keys) => (
                                            <a href={"/produits/validationGratuite"} className="p-3 d-flex border-bottom" key={keys}>
                                                <div className="w-80">
                                                    <div className="d-flex">
                                                        <h5 className="mb-2 fs-12">{items.nommagasin}</h5>
                                                    </div>
                                                    <p className="mb-1 fs-12">{items.produit}</p>
                                                    <span className="font-weight-normal fs-13 text-muted fs-10">{moment(items.created_at).format('DD-MM-YYYY')}</span>
                                                </div>
                                            </a>
                                        ))}


                                    </div>
                                    <div className="dropdown-divider mb-0 mt-0" />
                                    <a href="/produits/validationGratuite" className="dropdown-item text-center p-3">
                                        {t('Liste des demandes gratuité')} 
                                    </a>
                                </div>

                            </div>
                            {/**
                             * ---------------------------------- notification PRODUITS ------------------------------------------
                             */}
                            <div className="dropdown d-md-flex message">
                                <a className="nav-link icon" data-toggle="dropdown">
                                    <i className="icon icon-handbag" />
                                    <span className="badge badge-success pulse-success">{listeProduits?.length}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right animated bounceInDown dropdown-menu-arrow">
                                    <div className="dropdown-header bg-header-image text-white w-300 p-5 mb-2">
                                        <h5 className="dropdown-title mb-1 font-weight-semibold text-drak"> {t('sidebar.produits')}</h5>
                                        <p className="dropdown-title-text subtext mb-0 pb-0 fs-10 ">
                                            {t('rapportDLC.vousavez')} <b>{listeProduits?.length}</b>  {t('sidebar.produits')}
                                        </p>                                </div>
                                    <div className="drop-scroll" style={{ overflow: 'scroll', height: "300px" }}>
                                        {listeProduits?.length === 0 ? <>
                                            <div className="border-bottom">
                                                <div className="d-flex pl-3 pr-4 pt-2 pb-3 cart align-items-center">
                                                    {t("Aucune notification produit")} 
                                                </div>
                                            </div>

                                        </> :listeProduits?.map((items, keys) => (
                                            <a href={"/produits/modifierProduits&id="+items.code} className="p-3 d-flex border-bottom" key={keys}>
                                                <div className="w-80">
                                                    <div className="d-flex">
                                                        <h5 className="mb-2 fs-12">{items.code}</h5>
                                                    </div>
                                                    <p className="mb-1 fs-12">{items.libelle}</p>
                                                    <span className="font-weight-normal fs-13 text-muted fs-10">{moment(items.created_at).format('DD-MM-YYYY')}</span>
                                                </div>
                                            </a>
                                        ))}


                                    </div>
                                    <div className="dropdown-divider mb-0 mt-0" />
                                    <a href="/produits/liste" className="dropdown-item text-center p-3">
                                        {t("Liste des produits")}
                                    </a>
                                </div>

                            </div>
                       {/*      <div>
                    <a
                      href="#"
                      className="nav-link icon full-screen-link"
                      onClick={handleClick}
                    >
                      <i className="mdi mdi-lightbulb-on-outline fs-20 " />
                    </a>
                  </div> */}
                            {/* Cart */}
                            <div className="dropdown drop-profile">
                                <a
                                    className="nav-link pr-0 leading-none"
                                    href="#"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div className="profile-details mt-1">
                                        <span className="mr-3 mb-0  fs-15 font-weight-semibold">
                                            {details?.nom}
                                        </span>
                                        <small className="text-muted mr-3">{details?.role_code}</small>
                                    </div>
                                    <img
                                        className="avatar avatar-md brround"
                                        src="../assets/images/users/2.jpg"
                                        alt="image1"
                                    />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow animated bounceInDown w-250">
                                    <div className="user-profile bg-header-image border-bottom p-3">
                                        <div className="user-image text-center">
                                            <img
                                                className="user-images"
                                                src="../assets/images/users/2.jpg"
                                                alt="image2"
                                            />
                                        </div>
                                        <div className="user-details text-center">
                                            <h4 className="mb-0"> {details?.nom}</h4>
                                            <p className="mb-1 fs-13 text-white-50">
                                                {details?.email}
                                            </p>
                                        </div>
                                    </div>
                                   
                                    <div className="dropdown-divider" />

                                    <a className="dropdown-item mb-1" onClick={() => seDeconnecter()} >
                                        <i className="dropdown-icon mdi  mdi-logout-variant" /> {t('sedeconnecter')}
                                    </a>
                                </div>
                            </div>
                
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
