/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ToastContainer } from 'react-toastify'
import ListeDelegation from './Delegation/Liste.Delegation'
import ListeGouvernorat from './Gouvernorat/Liste.Gouvernorat'
import ListeLocalite from './Localite/Liste.Localite'
import Listepays from './Pays/Liste.pays'
import Listeregions from './Regions/Liste.regions'
import ListeSecteurs from './secteurs/Liste.Secteurs'
import ListeZone from './Zone/ListeZone'
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'

export default function Zoning() {
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item "><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.zoning')} </a></li>
                    <li className="breadcrumb-item active " aria-current="page">{t('Zoning.Parametrage')}</li>
                </ol>
            </div>
            <ToastContainer />

            <div className="row mt-6">
                {/** Parametrage Pays */}
                <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                    <Listepays t={t} />
                </div>
                {/** Parametrage REGIONS */}
                <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                    <Listeregions t={t} />
                </div>
                {/** Parametrage Gouvernorat */}
                <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                    <ListeGouvernorat t={t} />
                </div>
                {/** Parametrage Delegations */}
                <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                    <ListeDelegation t={t} />
                </div>
                {/** Parametrage Secteurs */}
                <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                    <ListeSecteurs t={t} />
                </div>
                {/** Parametrage lOCALITE */}
                <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                    <ListeZone t={t} />
                {/** Parametrage Zone */}
                </div>
                <div className="col-12 col-md-12 col-lg-12  col-sm-12 col-xl-12">
                    <ListeLocalite t={t} />
                </div>
            </div>
        </>
    )
}
