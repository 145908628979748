/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
//--------------------- CONVERT HTML TO EXCEL FILE --------------------------
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
//--------------------- TRADUCTION --------------------------------------
import { useTranslation } from 'react-i18next';
//--------------------- SEARCH INTO SELECT ITEMS ----------------------------
import SelectSearch, { fuzzySearch } from 'react-select-search';
//---------------------- ALERTE ------------------------------------------
import { toast, ToastContainer } from 'react-toastify';
// -------------------- WEB SERVICES ----------------------------
import { getListeReleve } from '../../services/RelevePrix/relevePrixApi';
import { getLastRelve } from '../../services/Rapports/RapportsApi';
//-------------------------UTILS ----------------------------------------
import moment from 'moment';
//---------------------- COMPONENTS  -----------------------------------
import RelevePrixMarquuee from '../Rapport/Suivi/marquee/RelevePrixMarquee';
import PdvRelevePrix from './PdvRelevePrix';
import { getRelevePrixSociete } from '../../services/Produits/configurationProduits/ConfigurationAPI';

export default function RelevePrix() {

  //----------- Parametres ----------------
  const { datedebut } = useParams('')
  //------ History -----------------
  const history = useHistory()
  //---------- Reference ----------- 
  const inputEl = useRef("")
  //---------SEARCH ------------------------------
  const [search, setSearch] = useState("")
  //------- USE REF ------------------
  const searchInput = useRef();
  const [date_debut, setdate_debut] = useState(moment(datedebut).format("YYYY-MM-DD"));
  //----------- Liste releve Prix -----------
  const [liste, setliste] = useState([]);
  const [saveListe, setsaveListe] = useState([]);
  const [listeUsers, setlisteUsers] = useState([]);
  const [listepdvs, setlistepdvs] = useState([]);
  const [savePDVS, setsavePDVS] = useState([]);
  const [listeProtuis, setlisteProtuis] = useState([]);
  const [SaveListeProduits, setSaveListeProduits] = useState([]);
  const [listeSF, setlisteSF] = useState([]);
  const [codeSF, setcodeSF] = useState("");
  const [codeUser, setcodeUser] = useState("");
  const [codeProduit, setCodeProduits] = useState([])
  const [listemessages, setlistemessages] = useState([])

  //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");

  //--------- LOADER ------------------
  const [loading, setloading] = useState(true)
  useEffect(() => {
    getLastRelve().then((result) => {
      if (result?.data?.success === 1) {
        setlistemessages(result?.data?.liste)
      }
    }).catch((err) => {
      console.log(err)
    });
    traitement()
  }, [])

  const traitement = () => {
    
    getListeReleve(date_debut)
      .then((result) => {
        if (result?.data?.success === 1) {
          setliste(result?.data?.liste)
          setsaveListe(result?.data?.liste)
          setlisteUsers(result?.data?.listeUsers)
          setlisteSF(result?.data?.listeSousFamilles)
          setlistepdvs(result?.data?.listepdvs)
          setsavePDVS(result?.data?.listepdvs)
          setlisteProtuis(result?.data?.listeproduits)
          setSaveListeProduits(result?.data?.listeproduits)

        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        setloading(false)
      })
  }
  const handleChangeSousFamille = (...args) => {
    setcodeSF(args[1].value)
    const l = saveListe?.filter((el) => el.sousfamille_code === args[1].value)
    const l1 = savePDVS?.filter((el) => el.sousfamille_code === args[1].value)
    const l2 = SaveListeProduits?.filter((el) => el.sousfamille_code === args[1].value)
    setliste(l)
    setlistepdvs(l1)
    setlisteProtuis(l2)
  }
  const handleChangeCodeProduit = (...args) => {
    setCodeProduits(args[1].value)
    const l1 = savePDVS?.filter((el) => el.produit_code === args[1].value)
    const l2 = SaveListeProduits?.filter((el) => el.value === args[1].value)
    setlistepdvs(l1)
    setlisteProtuis(l2)

  }
  const onSelectCodeUset = (e) => {
    setcodeUser(e)
    const l = saveListe?.filter((el) => el.user_code === e)
    setliste(l)
  }
  const onChangeDateFormat = () => {


    history.push({
      pathname:
        '/produits/relevePrix&datedebut=' + date_debut,
    })
    traitement()

  }
  return (<div>
    <div className="page-header">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><i className="fe fe-life-buoy mr-1" /> {t('relevePrix.Produits')} </li>
        <li className="breadcrumb-item active" aria-current="page"> {t('relevePrix.relevePrix')} </li>
      </ol>
    </div>
    <ToastContainer />
    {/**---------------------- INFORMATIONS (Derniers Relve prix) --------------------------- */}
    <div className="row mb-3">
      <RelevePrixMarquuee t={t} listemessages={listemessages} />
    </div>
    <div className="row">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
          <div className="panel panel-default active">
            <div className="panel-heading " role="tab" id="headingOne">
              <h4 className="panel-title">
                <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <i className="icon icon-grid text-primary mr-2" /> {t('relevePrix.listeRelevePrix')}
                  <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
              <div className="panel-body">
                <div className='row mb-2'>
                  <div className='col-2'></div>
                  <div className='col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12'>
                    <div className="input-group">
                      <input type="date" value={date_debut} onChange={(e) => setdate_debut(e.target.value)}
                        className="form-control br-tr-md-0 br-br-md-0" />
                      <SelectSearch
                        options={listeSF}
                        search
                        value={codeSF}
                        ref={searchInput}
                        onChange={handleChangeSousFamille}
                        filterOptions={fuzzySearch}
                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                        placeholder={t('Produits.choisirSFamille')}
                      />

                      <span className="input-group-append">
                        <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                        <button className="btn btn-yellow" type="button" onClick={() => onChangeDateFormat()}><i className='ti-close' /></button>

                      </span>
                    </div>
                  </div>
                  <div className='col-2'></div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <ReactHtmlTableToExcel
                      id="test-table-xls-button"
                      className="btn btn-success text-center float-right "
                      table="table-to-xls"
                      filename="Releve_prix"
                      sheet="tablexls"
                      buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                    />
                  </div>
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>

                  <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                    {listeUsers?.map((items, keys) => (
                      <button className="btn tag bg-purple" key={keys} onClick={() => onSelectCodeUset(items.value)}> {items.name}</button>
                    ))}
                  </div>
                </div>
                <div className="card">
                  <div className='card-body'>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      {/** Tableau Stock */}
                      <div className="table-responsive product-datatable" style={{ overflow: 'auto', height: '600px' }}>
                        <table
                          id="table-to-xls"
                          className="table table-striped table-bordered  bg-white "
                        >
                          <thead className="text-center bg" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#Z" }}>

                            <tr>
                              <th className="w-15p fs-12 "> {t('relevePrix.cab')} </th>
                              <th className="wd-15p fs-12">{t('rapportrupture.produit')}</th>
                              <th className="wd-15p fs-12">{t('dashboard.Merchandiseur')}</th>
                              <th className="wd-15p fs-12">{t('dashboard.Magasin')}</th>
                              <th className="wd-15p fs-12">{t('relevePrix.permanenet')}</th>
                              <th className="wd-15p fs-12">{t('relevePrix.promo')}</th>
                              <th className='wd-15p fs-12'>{t('rapports.S.FAMILLE')} </th>
                              <th className="wd-15p fs-12">{t('relevePrix.marque')}</th>
                              <th className="wd-15p fs-12">{t('relevePrix.date')}</th>

                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {liste.length === 0 ?
                              <tr>
                                <td colSpan={5}> <b className='text-danger'> {t('Zoning.Aucunresultat')} </b> </td>
                              </tr>
                              :
                              liste?.map((items, keys) => (
                                <>
                                  <tr key={keys} className='text-center fs-12'>
                                    <td>{items.cab}</td>
                                    <td>{items.produits}</td>
                                    <td>{items.nom}</td>
                                    <td>{items.nompdv}</td>
                                    <td>{parseFloat(items.permanent).toFixed(3)}</td>
                                    <td>{parseFloat(items.promo).toFixed(3)}</td>
                                    <td>{items.sousfamilles}</td>
                                    <td>{items.marque}</td>
                                    <td>  <b className="text-info">
                                            {moment(items.date).format("DD/MM/YYYY")}
                                          </b>
                                   </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        {/** Import or ADD produits to  Stock */}
        <SelectSearch
          options={listeProtuis}
          search
          value={codeProduit}
          ref={searchInput}
          onChange={handleChangeCodeProduit}
          filterOptions={fuzzySearch}
          emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
          placeholder={t('Produits.choisirProduit')}
        />
        <PdvRelevePrix listemagasins={listepdvs} loading={loading} />
      </div>

    </div>/

  </div>)
}
