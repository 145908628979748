/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import { TileLayer, MapContainer, Popup } from "react-leaflet";
import Marker from "react-leaflet-enhanced-marker";
import "leaflet/dist/leaflet.css";
import "./Historique/map.css";
import SqaureLoader from '../../components/Loader/SqaureLoader';

export default function ListePdvLocalisation({ listeEnseinges, loading ,t}) {

    const [center, setCenter] = useState({
        lat: listeEnseinges[0]?.latitude === "-1" || isNaN(listeEnseinges[0]?.latitude) || listeEnseinges[0]?.latitude===""  ? "36.8842439" : parseFloat(listeEnseinges[0]?.latitude),
        lng: listeEnseinges[0]?.logitude === "-1" ||isNaN(listeEnseinges[0]?.logitude) || listeEnseinges[0]?.logitude===""? "10.1625628" : parseFloat(listeEnseinges[0]?.logitude),
    });
    const ZOOM_LEVEL = 8;
    const mapRef = useRef();
    return (
        <div >
            <div className="left-container" style={{position:"sticky"}}>
                {loading ? <SqaureLoader /> : (
                    <MapContainer center={center} zoom={ZOOM_LEVEL} map={mapRef} style={{height:"800px"}} >
                        <TileLayer
                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
                        />
                        {listeEnseinges?.map((items, index) => (
                            <Marker position={[ items?.latitude === null || items?.latitude === "" || items?.latitude === "null" ||isNaN(listeEnseinges[0]?.latitude) ||undefined ? -1: parseFloat(items?.latitude),items?.logitude==="" || items?.logitude  === null ||items?.logitude  === "null" ||isNaN(listeEnseinges[0]?.logitude) ||undefined  ? -1 : parseFloat(items?.logitude)]} icon={<i className="ion-location  fa-3x"  style={{color: items.color !== null ? items.color : "#ff6600" }}/>} key={index} className="marker" >
                                <Popup>
                                    <b>
                                        {t('Routings.nomEnseigne')}:  {items?.nomenseigne}
                                        <hr className='hr1' />
                                        {t('Magasins.Magasins')} :  {items?.nompdv}
                                        <hr className='hr1' />
                                        {t('rapports.code')} :   <a href={"/pdv/historique&code=" + items.code}> {items?.code} </a>

                                    </b>
                                </Popup>
                            </Marker>
                        ))}
                    </MapContainer>

                )}

            </div>
        </div>
    )
}
