import moment from 'moment';
import React from 'react';

export default function TableauVente({ listeNonVendus, data, date_d, date_f, t }) {
    return (
        <>  <div className='row'>
            <div className='col-4'>
                <div className="item-box text-center">
                    <div className="bg-purple-transparent icon-bg icon-service text-purple">
                        <i className='icon icon-people' />
                    </div>
                    <div className="item-box-wrap">
                        <h6 className="mb-2"> {t('rapports.ContactsTotal')} : {data?.total_contact} </h6>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div className="item-box text-center">
                    <div className="bg-purple-transparent icon-bg icon-service text-purple">
                        <i className='icon icon-basket-loaded' />
                    </div>
                    <div className="item-box-wrap">
                        <h6 className="mb-2">   {t('rapports.ContactsAvecAchat')}:  <a href={"/rapports/rapportContacts&datedebut=" + date_d + "&datefin=" + date_f + "&user_code=''"}> <u style={{ cursor: "pointer" }}> {data?.contact_avec_achat}</u> </a> ,  (  {parseFloat((data?.contact_avec_achat / data?.total_contact) * 100).toFixed(2)} %) </h6>
                    </div>
                </div>

                </div>
            <div className='col-4'>
                <div className="item-box text-center">
                    <div className="bg-purple-transparent icon-bg icon-service text-purple">
                        <i className='icon icon-basket' />
                    </div>
                    <div className="item-box-wrap">
                        <h6 className="mb-2">   {t('rapports.ContactsSansAchat')}:  {data?.contact_sans_achat} , ( {parseFloat((data?.contact_sans_achat / data?.total_contact) * 100).toFixed(2)}  %)  </h6>
                    </div>
                </div>
                </div>
        </div>
            <div className="table-responsive  product-datatable" style={{ overflow: 'auto', height: '800px' }}>

                {/*Liste des produits hors mécanisme vendus*/}
                <table className="table  table-bordered  mt-2" id="table-to-xls">
                    <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>


                        <tr className="text-center">
                            <th className="w-15p fs-10">{t('dashboard.Merchandiseurs')}</th>
                            <th className="w-15p fs-10">{t('dashboard.Date')}</th>
                            <th className="w-15p fs-10">{t('dashboard.Magasin')}</th>
                            <th className="w-15p fs-10">{t('rapports.Zones')}</th>
                            <th className="w-15p fs-10">{t('Zoning.Delegation')}</th>
                            <th className="w-15p fs-10">{t('rapportVentes.typevisite')} </th>
                            <th className="w-15p fs-10">{t('rapports.code')} </th>
                            <th className="w-15p fs-10">{t('contacts.produit')} </th>
                            <th className="w-15p fs-10">{t('dashboard.Gamme')} </th>
                            <th className="w-15p fs-10">{t('rapportVentes.qteVendu')} </th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {listeNonVendus?.map((items, keys) => (
                            <tr key={keys} className={keys % 2 === 1 ? 'text-center bg-azure-lightest fs-10' : "fs-10"}>
                                <td>{items.nom}</td>
                                <td>{moment(items.date).format("DD/MM/YYYY")} </td>
                                <td>{items.nompdv}</td>
                                <td>{items.zone}</td>
                                <td>{items.delegation}</td>
                                <td>{items.type_visite === null ? '-' : items.type_visite}</td>
                                <td>{items.produit_code}</td>
                                <td>{items.produit}</td>
                                <td>{items.gamme}</td>
                                <td>{items.quantite}</td>

                            </tr>
                        ))}
                        <tr className="text-center">
                            <td colSpan={4}>
                                <div className="item-box text-center">

                                    <div className="item-box-wrap">
                                        <h6 className="mb-2"> {t('rapports.ContactsTotal')} : {data?.total_contact} </h6>
                                    </div>
                                </div>
                            </td>
                            <td colSpan={3}>
                                <div className="item-box text-center">

                                    <div className="item-box-wrap">
                                        <h6 className="mb-2">   {t('rapports.ContactsAvecAchat')}:   {data?.contact_avec_achat},  (  {parseFloat((data?.contact_avec_achat / data?.total_contact) * 100).toFixed(2)} %) </h6>
                                    </div>
                                </div>

                            </td>
                            <td colSpan={3}>
                                <div className="item-box text-center">

                                    <div className="item-box-wrap">
                                        <h6 className="mb-2">   {t('rapports.ContactsSansAchat')}:  {data?.contact_sans_achat} , ( {parseFloat((data?.contact_sans_achat / data?.total_contact) * 100).toFixed(2)}  %)  </h6>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}
