import React from 'react'
import { toast } from 'react-toastify';
import { deletePdvTournee } from '../../../services/Routings/RoutingsApi';

export default function AlerteTournee({ code, codepdv, setok, t }) {

    const deleteTournee = (code, codepdv) => {
        deletePdvTournee(code, codepdv)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('Routings.lemagasinsupprime'), { type: "success", position: "top-right" })
                } else {
                    toast(t('Routings.erreursupp'), { type: "error", position: "top-right" })
                }
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <div className="modal fade" id="modal-notification" tabIndex={-1} role="dialog" aria-labelledby="modal-notification" aria-hidden="true" >
            <div className="modal-dialog modal-danger" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal-title-notification">{t('Routings.supprimerlignetournee') + " : " + code} </h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setok(true)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="py-3 text-center">
                            <i className="ti-trash fa-3x" />
                            <h4 className="heading mt-4"> {t('Routings.etesvoussure')} </h4>
                            <p>  {t('Routings.supprimer') + " " + codepdv + " " + t('Routings.delatournee') + " " + code + " ?"}  </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => { deleteTournee(code, codepdv) }}> <i className="icon icon-check mr-1" /> {t('Routings.confirmer')}</button>
                        <button type="button" className="btn btn-secondary text-white ml-auto" data-dismiss="modal" onClick={() => setok(true)}><i className="icon icon-close mr-1" /> {t('Routings.annueler')} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
