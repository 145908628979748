import React from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function ModalExportMarque({ listeMarques, t }) {
  return (
<div className="modal fade" id="exportMarque" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3">  <i className="icon icon-list mr-1 text-azure" /> {t('Produits.ListedesMarques')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" /> </span>

                    </div>
                    <div className="modal-body">
                    <div className="table-responsive product-datatable"  style={{ overflow: "scroll", height: "400px" }}> 
                                    <table
                                        id="example"
                                        className="table table-striped table-bordered "
                                    >
                                        <thead className="text-center"  style={{ position: "sticky", top: 0, zIndex: 1 ,backgroundColor:"white"}}>
                                            <tr>
                                                <th className="w-15p fs-12">{t('rapports.code')}</th>
                                                <th className="w-15p fs-12">{t('Produits.Libelle')}</th>
                                                <th className="w-15p fs-12">{t('Produits.Principale')}</th>
                                                <th className="w-15p fs-12">{t('Produits.Actif')}</th>

                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {listeMarques?.map((items, keys) => (
                                                <tr key={keys}   className={
                                                    keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                                }>
                                                    <td>{items.code} </td>
                                                    <td>{items.libelle} </td>
                                                    <td>{items.principale} </td>
                                                    <td>{items.actif} </td>
                                                   
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                   

                                </div>
                    </div>
                    <div className="modal-footer">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-azure"
                            table="table-gammes"
                            filename="liste_gammes"
                            sheet="tablexls"
                            buttonText={<span className=" text-center"> <i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')}</span>}
                        />
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><i className="icon icon-close mr-1" />  {t('Zoning.Fermer')}</button>
                    </div>
                </div>
            </div>
        </div>
  )
}
