/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useTranslation } from 'react-i18next'
import ListeFamille from './Famille/ListeFamille'
import ListeGamme from './Gamme/ListeGamme'
import ListeMarque from './Marque/ListeMarque'
import ListeSousFamille from './SousFamille/ListeSousFamille'

export default function ConfigurationProduits() {
//------------- Traduction ---------------
const [t, i18n] = useTranslation("global");

    return (
        <div>
             <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" />  {t('Produits.Produits')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('Produits.ConfiguraionProduits')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                    <a href="/produits/cadeaux" className="btn btn-yellow btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-present mr-1" />
                            {t('Produits.Cadeaux')}
                        </a>
                        <a href="/produits/validationGratuite" className="btn btn-green btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="ion-speakerphone mr-1" />
                            {t('sidebar.validationGratuite')}
                        </a>
                        <a href="/produits/liste" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                        <i className="icon icon-list mr-1" />
                            {t('Produits.Listeproduits')}
                        </a>
                        <a  href="/produits/codeMETI" className="btn btn-azure btn-icon-text mr-2 mb-2 mb-md-0  fs-12">
                            <i className="fe fe-printer" />
                            {t('Produits.ConfigurationCodeMETI')}
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
            <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                 <ListeMarque t={t}/>
                </div>
                <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                <ListeGamme t={t} />
                </div>
            </div>
            <div className="row mt-3">
            <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                    <ListeFamille t={t} />
                </div>
                <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                    <ListeSousFamille  t={t}/>
                </div>
            </div>
          
        </div>
    )
}
