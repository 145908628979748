/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getRapportSuiviPointage, getUsersRapport } from '../../../services/Rapports/RapportsApi'
import moment from "moment"
import { distance } from '../../../utils/Distance'
import ModalImagePointageDebut from './ModalImagePointageDebut'
import ModalImagePointageFin from './ModalImagePointageFin'
import ModalMapPointages from './ModalMapPointages'
import { useTranslation } from 'react-i18next'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ModalDebutPause from './ModalDebutPause'
import ModalFinPause from './ModalFinPause'
import _ from 'lodash'

export default function SuiviPointage() {

    //------ History -----------------
    const history = useHistory()
    //--------- PARAMETERE -------------
    const { datedebut, datefin, code } = useParams('')
    //--------- Search --------------------
    const [date_d, setdate_d] = useState(datedebut)
    const [date_f, setdate_f] = useState(datefin)
    //------------ LISTE ---------------
    const [listeSuivi, setlisteSuivi] = useState([])
    const [saveSuivi, setSaveSuivi] = useState([])
    const [codeUser, setCodeUser] = useState(code)
    const [visiteCode, setvisiteCode] = useState("")
    const [listeUsers, setlisteUsers] = useState([])
    //----------- MODAL ITEMS ------------------
    var [user_code, setuser_code] = useState("")
    var [date, setdate] = useState("")
    var [pdv_code, setpdv_code] = useState("")
    const [ok1, setok1] = useState(false)
    const [ok2, setok2] = useState(false)
    const [ok3, setok3] = useState(false)
    const [ok4, setok4] = useState(false)
    const [ok5, setok5] = useState(false)

   
    //------------ Loader ---------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");


    const traitement = () => {
        getUsersRapport(date_d, date_f)
        .then((result) => {
            if (result?.data?.success === 1) {
                setlisteUsers(result?.data?.listeUsers)
            }
        }).catch((err) => {
            console.log(err)
        });
        getRapportSuiviPointage(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    if (code === "''") {
                        const L = _.uniq(result?.data?.clotures).concat(_.uniq(result?.data?.liste));
                        setlisteSuivi(_.orderBy(L,"date", ['desc'] ))
                        setSaveSuivi(_.orderBy(L,"date", ['desc'] ))
                    } else {
                        const liste = result?.data?.liste?.filter((el) => el.user_code === code)
                        console.log(liste);
                        const L = _.uniq(result?.data?.clotures).concat(_.uniq(liste));
                        setlisteSuivi(_.orderBy(L,"date", ['desc'] ))
                        setSaveSuivi(_.orderBy(L,"date", ['desc'] ))
                    }

                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }
    useEffect(() => {
        traitement()
    }, [])


    const onChangeValue = () => {
        if (date_d > date_f) {
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
        } else {
            history.push({
                pathname:
                    '/rapports/rapportSuiviPointage&datedebut=' + date_d + '&datefin=' + date_f + "&user_code=" + codeUser,
            })

            traitement()
        }
    }
    const onClickUser = (e) => {
        if (e !== "all") {
            setCodeUser(e)
            const liste = saveSuivi.filter((element) => element.user_code === e || element.code === e)
            setlisteSuivi(_.orderBy(liste,"date", ['desc'] ))
            history.push({
                pathname:
                    '/rapports/rapportSuiviPointage&datedebut=' + date_d + '&datefin=' + date_f + "&user_code=" + e,
            })
        } else {
            setlisteSuivi(saveSuivi)
            setCodeUser('')
            history.push({
                pathname:
                    '/rapports/rapportSuiviPointage&datedebut=' + date_d + '&datefin=' + date_f + "&user_code=''",
            })
        }
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('rapports.RapportSuiviPointage')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/rapports/parametragePointage" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-settings mr-1" />
                            {t('rapports.parametragepointage')}
                        </a>
                    </div>

                </div>
            </div>
            {/** ------------- rapport : Suivi Tournee  ------------------------------ */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" />{t('rapports.RapportSuiviPointage')}

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'></div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control text-primary fs-12" />
                                        <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control text-primary fs-12" />
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => onChangeValue()}><i className='ti-search' /></button>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'></div>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>
                                    <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                        <button className="btn tag bg-azure text-white" onClick={() => onClickUser("all")}> {t('rapports.ALL')} </button>
                                        {listeUsers?.map((items, keys) => (
                                            <button className="btn tag bg-purple" key={keys} onClick={() => onClickUser(items.user_code)}> {items.nom} : ({items.total})</button>

                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="row">

                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className='btn btn-secondary float-right mr-1 fs-12 mb-3'
                                table="suivi-pointage"
                                filename="suivi-pointage"
                                sheet="tablexls"
                                buttonText={<span className=" text-center"> <i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')}</span>}
                            />
                            {loading ?
                                <SqaureLoader /> : (
                                    <div className="table-responsive product-datatable">
                                        <table className="table table-striped table-bordered " id="suivi-pointage">
                                            <thead className="text-center bg-azure-lighter ">
                                                <tr>
                                                    <th className="w-15p fs-10"> {t('rapportrupture.date')} </th>
                                                    <th className="w-15p fs-10">{t('dashboard.Magasin')}</th>
                                                    <th className="w-15p fs-10"> {t('rapportrupture.merch')}</th>
                                                    <th className="w-15p fs-10 border_table_top border_table_left ">{t('rapports.H.ENTREE')}</th>
                                                    <th className="w-15p fs-10  border_table_top">{t('rapports.DISTANCE(M)')}</th>
                                                    <th className="w-15p fs-10  border_table_top  border_table_right">{t('rapports.CONSTAT')}</th>
                                                    <th className="w-15p fs-10 ">{t('rapports.H.ENTREE')}</th>
                                                    <th className="w-15p fs-10">{t('rapports.PAUSE')}</th>
                                                    <th className="w-15p fs-10 ">{t('rapports.H.SORTIE')}</th>
                                                    <th className="w-15p fs-10">{t('rapports.CONSTAT')}</th>
                                                    <th className="w-15p fs-10 border_table_left  border_table_top">{t('rapports.H.SORTIE')}</th>
                                                    <th className="w-15p fs-10  border_table_top">{t('rapports.DISTANCE(M)')}</th>
                                                    <th className="w-15p fs-10  border_table_top">{t('rapports.H.TRAVAILLE')}</th>
                                                    <th className="w-15p fs-10  border_table_top  border_table_right">{t('rapports.CONSTAT')}</th>
                                                    <th className="w-15p fs-10">{t('rapports.COMMANDE')}</th>
                                                    <th className="w-15p fs-10">H.Serveur</th>

                                                </tr>
                                            </thead>
                                            <tbody className='text-center fs-10'>
                                                {listeSuivi?.map((items, keys) => (
                                                    <tr key={keys} className="fs-10">
                                                      {items.user_code !== undefined ? 
                                                      <>
                                                      <td> <a className='text-primary' href={"/detailsMagasins/rapportJournalier&date=" + moment(items.date).format('YYYY-MM-DD') + "&usercode=" + items.user_code + "&pdv_code=" + items.pdv_code}>{moment(items.date).format('DD/MM/YYYY')}</a> </td>
                                                      <td> <a className='text-primary' href={"/detailsMagasins/rapportJournalier&date=" + moment(items.date).format('YYYY-MM-DD') + "&usercode=" + items.user_code + "&pdv_code=" + items.pdv_code}>{items.nompdv}</a> </td>
                                                      <td>  <a href={'/rapports/localisationPointage&date=' + items.date + '&pdv_code=' + items.pdv_code + '&user_code=' + items.user_code}/*  data-toggle="modal" data-target="#exampleModalLocalisation" */ className='text-yellow'/*  onClick={() => { setuser_code(items.user_code); setdate(items.date); setpdv_code(items.pdv_code) ;setok3(true) }} */> {items.nom}</a> </td>
                                                      <td className='border_table_left '> <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#exampleModal" className='text-success' onClick={() => { setuser_code(items.user_code); setdate(items.date); setpdv_code(items.pdv_code); setok1(true);setvisiteCode(items.visite_code)}}> {items.heure_debut}</a> </td>
                                                      <td>{isNaN(distance(parseFloat(items.latitude), parseFloat(items.longitude), parseFloat(items.lat_pdv), parseFloat(items.long_pdv),"K").toFixed(3)) ? "-" : distance(parseFloat(items.latitude), parseFloat(items.longitude), parseFloat(items.lat_pdv), parseFloat(items.long_pdv),"K").toFixed(3)} </td>
                                                      <td className={items.calcul_retard === "ok" ? "border_table_right bg-green-lighter " : " border_table_right  bg-red-lighter"}> {items.calcul_retard === "ok" ? t('rapports.alheure') : t('rapports.Enretard')}  </td>
                                                      <td> <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#debutPause" className='text-success' onClick={() => { setuser_code(items.user_code); setdate(items.date); setpdv_code(items.pdv_code); setok4(true) }}>{items.heure_debut_pause}</a>  </td>
                                                      <td> {items.total_pause} </td>
                                                      <td> <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#finPause" className='text-success' onClick={() => { setuser_code(items.user_code); setdate(items.date); setpdv_code(items.pdv_code); setok5(true) }}>{items.heure_fin_pause}</a>  </td>
                                                      <td className={items.calcul_retard === "ok" ? "border_table_right bg-green-lighter " : " border_table_right  bg-red-lighter"}> {items.calcul_retard === "ok" ? t('rapports.Respecte') : t('rapports.Nonrespecte')} </td>
                                                      <td className='border_table_left '> {items.heure_fin === 'null' ? "-" : <a style={{ cursor: "pointer" }} data-toggle="modal" data-target="#exampleModalfin" className='text-success' onClick={() => { setuser_code(items.user_code); setdate(items.date); setpdv_code(items.pdv_code); setok2(true) ;setvisiteCode(items.visite_code)}}> {items.heure_fin} </a>}</td>
                                                      <td>{isNaN(distance(parseFloat(items.latitude_sortir), parseFloat(items.longitude_sortir), parseFloat(items.lat_pdv), parseFloat(items.long_pdv),"K").toFixed(3)) ? "-" : distance(parseFloat(items.latitude_sortir), parseFloat(items.longitude_sortir), parseFloat(items.lat_pdv), parseFloat(items.long_pdv),"K").toFixed(3)} </td>
                                                      <td>{items.difference !== null ? (items.difference).slice(0, 8) :"-"}</td>
                                                      <td className={items.respect_travail === "respecte" ? "border_table_right bg-green-lighter " : " border_table_right  bg-red-lighter"}> {items.respect_travail === "respecte" ? t('rapports.Respecte') : t('rapports.Nonrespecte')} </td>
                                                      <td> {items.commande === 1 ? t('rapports.oui') : t('rapports.non')} </td>
                                                      <td>{moment(items.created_at).format('HH:mm:ss')}</td>
                                                      </>
                                                    : <td colSpan={16} className="bg-danger-transparent"> Clôture (Date : <b>{moment(items.date).format("DD/MM/YYYY")}</b> à <b>{items.time_date}</b>)</td>
                                                    }  
                                                       
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            
            {ok1 ? <ModalImagePointageDebut date={date} pdv_code={pdv_code} user_code={user_code} setok1={setok1} visiteCode={visiteCode} t={t} />
                : null}
            {ok2 ? <ModalImagePointageFin date={date} pdv_code={pdv_code} user_code={user_code} setok2={setok2} visiteCode={visiteCode} t={t} />
                : null}
            {ok3 ? <ModalMapPointages date={date} pdv_code={pdv_code} user_code={user_code} setok3={setok3} t={t} />
                : null}
            {ok4 ? <ModalDebutPause date={date} user_code={user_code} setok4={setok4} t={t} />
                : null}
            {ok5 ? <ModalFinPause date={date} user_code={user_code} setok5={setok5} t={t} />
                : null}
        </div>
    )
}
