/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React from 'react'

export default function CardNotes({ items ,setCode , changeDetailsRoute ,t}) {
    return (
        <div className=" col-md-6 col-sm-12 col-12 col-xl-4">
        <div className="card">
            <img className="card-img-top br-tr-7 br-tl-7" src={items?.image !== null ? items?.image : "../assets/images/users/23.png"} alt="notes" style={{height:"350px"}} />
            <div className="card-body d-flex flex-column">
                <div className="img-info">
                    <p className="text-muted mb-1 float-left"> {moment(items?.date).format("DD MMMM YYYY")}  </p>
                    <p className="text-danger mb-1 float-right"> <a  data-toggle="modal" data-target="#modal-notification" onClick={()=>setCode(items.code)}> <i className="ti-trash"/> </a> </p>
                    <hr className="mb-1" style={{ borderColor:"transparent"}}/>
                    
                    <h4 className="font-weight-semibold"> <a  onClick={()=>{ changeDetailsRoute(items.code);setCode(items.code) }} className="text-primary" data-toggle="modal" data-target="#exampleModal3"> {items?.objet}</a> </h4>
                    <div className="text-muted">{items?.message}</div>
                    <h6 className="time-title text-muted p-0 leading-normal mt-2 float-right">{t('Catalogues.Commercial')}  : {items?.commercial}</h6>
                    <h6 className="time-title text-muted p-0 leading-normal mt-2 float-left"> {t('Catalogues.Client')} : {items?.client}</h6>

                </div>
            </div>

        </div>
    </div>
    )
}
