import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL


/**
 * Get liste  : Produits
 * @returns 
 */
 export async function getListeStockDepot() {
    try {
        const response = await axios.get(url + "stockdepot/liste", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * POST ADD  CODE METI : EXCEL
 * @returns 
 */
 export async function addExcelStockDepot(file) {


    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(url + "stockdepot/excel", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * POST ADD  : produits
 * @returns 
 */
 export async function addStockDepot(data) {
    try {
        const response = await axios.post(url + "stockdepot/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error.response.status
    }
}

/**
 * POST ADD  : produits
 * @returns 
 */
 export async function getStockDepot(code) {
    try {
        const response = await axios.get(url + "stockdepot/code/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error.response.status
    }
}

/**
 * Delete ADD  : produits
 * @returns 
 */
 export async function deleteStockDepot(code) {
    try {
        const response = await axios.delete(url + "stockdepot/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error.response.status
    }
}
/**
 * Delete ADD  : produits
 * @returns 
 */
 export async function updateStockDepot(code,data) {
    try {
        const response = await axios.patch(url + "stockdepot/"+code, data,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error.response.status
    }
}