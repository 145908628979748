import moment from "moment";
import React from "react";
import { verif_rang } from "./Traitement_auto_evaluation";

export default function ModalRespectTournee({
  days,
  setok2,
  t,
  tournee,
  respect_rang,
  respect_tournee,
}) {
 
  return (
    <div
      className="modal fade"
      id="tournee"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="tournee">
              Note respect tournée : du {moment(days).format("DD/MM/YYYY")}
            </h5>
            <span>
              <i
                className="icon icon-close text-azure"
                style={{ cursor: "pointer" }}
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setok2(false)}
              />{" "}
            </span>
          </div>

          <div className="modal-body">
            <table className="table table-responsive ">
              <thead>
                <tr>
                  <th>Critéres </th>
                  <th className="text-center">Pdv Plannifié</th>
                  <th className="text-center">Pdv visité</th>
                  <th className="text-center">Note</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Respect des tournée </td>
                  <td className="text-center">
                    {respect_tournee[days]!== undefined? respect_tournee[days][0]?.pdv_planifie : "-"}
                  </td>
                  <td className="text-center">
                    {respect_tournee[days]!== undefined? respect_tournee[days][0]?.pdv_visite : "-"}
                  </td>
                  <td className="text-center">
                    {respect_tournee[days]!== undefined? respect_tournee[days][0]?.respect_tournee :"-"}
                  </td>
                </tr>
                <tr>
                  <td>Respect des rang tournée </td>
                  <td className="text-center" colSpan={3}>
                   Respect :  {verif_rang(days , respect_rang)}
                  </td>
               
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>
                    Note Total : <b>{tournee(days , respect_tournee)+verif_rang(days , respect_rang)}/2</b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setok2(false)}
            >
              <i className=" ti-close mr-1" /> {t("dashboard.Fermer")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
