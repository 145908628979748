/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { getHistoriquesImages } from '../../../services/Magasins/PdvApi';

export default function InterfaceHistoriqueImages() {
    const { code, year } = useParams("")

    const [listeImages, setlisteImages] = useState([])
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //-------------- Loading 
    const [loading, setloading] = useState(true)

    useEffect(() => {
        /**
         * Liste Historiques Images
         */
        getHistoriquesImages(code, year)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteImages(result?.data.images)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [])

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb mt-2">
                    <li className="breadcrumb-item fs-12"><a href="/pdv/listePdv"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Magasins')} </a></li>
                    <li className="breadcrumb-item  fs-12" aria-current="page">  <a href={'/pdv/historique&code=' + code} >{t('historiqueMagasin.historiquemagasin')} : {code}</a> </li>
                    <li className="breadcrumb-item active fs-12" aria-current="page"> {t('listeImages')} </li>
                </ol>
            </div>

            {loading ? <SqaureLoader /> : (
                <>
                    <div className="col-lg-12 mt-3">
                        <div className="card">


                            <div className="card-body">

                                <div className="content row">
                                    {listeImages?.map((items, keys) => (
                                        <a className="elem col-md-4" data-lcl-thumb={items.path} data-lcl-txt={"Date " + moment(items.created_at).format("DD-MM-YYYY")} href={items.path} title={items.type}>
                                            <img className="img-responsive" src={items.path} alt="Thumb-2" title="Image 01" />
                                            <span className="img-responsive" data-image-src={items.path} />
                                        </a>
                                    ))}
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            )}
        </div>
    )
}
