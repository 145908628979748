import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getSuiviCommande } from "../../../services/Rapports/RapportsApi";
import moment from "moment";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import ExportExcel from "../../commandes/ExportExcel"
import LoaderTable from "../../../components/Loader/LoaderTable";

export default function SuiviCommandes() {
  // -------- PARAMETES---------------------
  const { date_debut, date_fin } = useParams("");
  // -------- DATE DEBUT / DATE FIN -----------
  const [dateDebut, setdateDebut] = useState(date_debut);
  const [dateFin, setdateFin] = useState(date_fin);
  // FILTRE ------

  //---------- LISTE DES COMMANDES --------
  const [LISTE, setLISTE] = useState([]);
  //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");
  const [loading, setloading] = useState(true);

  const traitement = () => {
    getSuiviCommande(dateDebut, dateFin)
      .then((result) => {
        if (result?.data?.success === 1) {
          setLISTE(result?.data?.liste_commandes);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
  };
  const onChangeDateFormat = () => {
    traitement();
  };
 
  useEffect(() => {
    traitement();
  }, []);

  return (
    <div>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/commandes">
              <i className="fe fe-life-buoy mr-1" /> Suivi Commandes
            </a>
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="row mb-2">
            <div className="col-3"></div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="input-group">
                <input
                  type="date"
                  value={dateDebut}
                  onChange={(e) => setdateDebut(e.target.value)}
                  className="form-control text-primary"
                  placeholder="Search for..."
                />
                <input
                  type="date"
                  value={dateFin}
                  onChange={(e) => setdateFin(e.target.value)}
                  className="form-control text-primary"
                  placeholder="Search for..."
                />
                <span className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => onChangeDateFormat()}
                  >
                    <i className="ti-search" />
                  </button>
                  <ExportExcel excelData={LISTE} fileName={"Suivi commandes"} noHandle={true} />
                </span>
                
              </div>
            </div>
            <div className="col-3"></div>
           
          </div>

          {loading ? (
            <LoaderTable />
          ) : (
            <div className="row">
              <div className="card">
                <div className="card-header mt-2 text-primary">
                  <b className="mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-inboxes mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562A.5.5 0 0 0 1.884 9h12.234a.5.5 0 0 0 .496-.438L14.933 6zM3.809.563A1.5 1.5 0 0 1 4.981 0h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393zm.941.83.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438l.32-2.562H10.45a2.5 2.5 0 0 1-4.9 0H1.066z" />
                    </svg>
                    Liste des commandes 
                  </b>

                  <div className="float-right">
                    <ReactHtmlTableToExcel
                      id="test-table-xls-button"
                      className="btn btn-azure fs-12 mt-0 "
                      table="table-to-xls"
                      filename="suivi commandes"
                      sheet="tablexls"
                      buttonText={
                        <span className=" text-center">
                          {" "}
                          <i className="icon icon-share-alt mr-1" />{" "}
                          {t("rapportrupture.exporter")}
                        </span>
                      }
                    />
                
                  </div>
                </div>
                <div className="card-body">
                 
                 
                  <div className="table-responsive  product-datatable">
                    <table className="table  table-bordered " id="table-to-xls">
                      <thead className="text-center">
                        <tr>
                          <th className="w-15p fs-10"> Code </th>
                          <th className="wd-15p fs-10">Numero </th>
                          <th className="wd-15p fs-10">Merchandiseur</th>
                          <th className="wd-15p fs-10">PDV</th>
                          <th className="wd-15p fs-10">Total Ht</th>
                          <th className="wd-15p fs-10">Total ttc</th>
                          <th className="wd-15p fs-10">Date reception</th>
                          <th className="wd-15p fs-10">Etat</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {LISTE?.map((items, keys) => (
                          <tr
                            key={keys}
                            className={
                              items.visibile_commande === 1
                                ? "bg-red-lightest fs-10"
                                : "fs-10"
                            }
                          >
                            <td>{items.code}</td>
                            <td>{items.num_commande}</td>
                            <td>{items.merch}</td>
                            <td>{items.nompdv}</td>
                            <td>{items.total_ht}</td>
                            <td>{items.total_ttc}</td>
                            <td>
                              <b className="text-info">
                                {moment(items.date_reception).format("DD/MM/YYYY")}
                              </b>
                            </td>
                           
                            <td>
                            <label className="fs-10 ml-2">
                                {" "}
                                {items.visibile_commande === 1 ? "Livré" : "Non Livré"}{" "}
                              </label> 
                       
                            </td>
                        
                          </tr>
                        ))}
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
