/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getHistoriqueRupture } from '../../../services/Rapports/RapportsApi'

export default function ModalHistoriqueRupture({ pdv_code, produit_code, setOk ,t }) {

    const [liste, setliste] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {
        getHistoriqueRupture(pdv_code, produit_code).then((result) => {
            if (result?.data?.success === 1) {
                setliste(result?.data?.histo)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }, [])


    return (
        <div className="modal fade" id="exampleModal" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog" aria-hidden="true" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3">{t('rapports.historiquerupture')} :   </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close"  /> </span>

                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <table className="table  table-bordered ">
                                <thead className="text-center">
                                    <tr>
                                    <th className="w-15p fs-10"> {t('rapportrupture.date')} </th>
                                    <th className="w-15p fs-10"> {t('rapportrupture.merch')}</th>
                                    <th className="w-15p fs-10">{t('rapports.QUANTITE')} </th>
                                        <th className="w-15p fs-10">{t('rapports.prix')} </th>
                                        <th className="w-15p fs-10">{t('relevePrix.cause')} </th>
                                        <th className="w-15p fs-10">{t('rapports.Etat')} </th>
                                    </tr>
                                </thead>
                                {loading ? <SqaureLoader /> : (
                                    <tbody>
                                        {liste?.map((items, keys) => (
                                            <tr key={keys} className={items.etat==="Disponible"?"bg-green-lighter fs-10 ":'bg-red-lighter fs-10'}>
                                                <td>{moment(items.date).format('DD-MM-YYYY')}</td>
                                                <td>{items.user}</td>
                                                <td>{items.qte}</td>
                                                <td>{items.prix}</td>
                                                <td>{items.cause}</td>
                                                <td>{items.etat}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                )}

                            </table>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button className='btn btn-yellow fs-12'  type='button' data-dismiss="modal" aria-label="Close" onClick={() => setOk(false)} > <i  className='ti-close mr-1'/> {t('Zoning.Fermer')} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
