import React from 'react'
import moment from 'moment'
import ModalListePromo from './ModalListePromo'
import SqaureLoader from '../../../components/Loader/SqaureLoader'

export default function CardsDashboard({ data, today, listePromo, t ,user ,loadingCard}) {
    const evolutiolPl = parseFloat(((data?.partLineaire_today - data?.partLineaireprecedent) / data?.partLineaireprecedent) * 100).toFixed(2)

    return (
        <div>
            {/* row opened */}
            <div className="row">
                {/**------------ CARD MERCHANDISEURS ------------------------------- */}
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            {loadingCard ? <SqaureLoader/> : 
                                <> 
                                <h6 className="card-title font-weight-normal fs-14 mb-4 text-primary">
                                <b className="mr-2" >{data?.merch_today}</b> {user?.type==="promotrice" ? t('promotrice') :t('dashboard.Merchandiseur')}
                                <span className='float-right'>
                                    {t('dashboard.Le')}  {moment(today).format("DD-MM-YYYY")}
                                </span>
                            </h6>
                            <div className="d-flex  align-items-center">
                                <h4 className="font-weight-normal mb-0 fs-14">
                                    <b>{data?.merch_month}</b>  {t('dashboard.actifs')}  ({moment(today).format("MMMM")})
                                </h4>
                              
                                <div className="text-primary mt-0 mb-2  ml-auto">
                                <div className="item-box text-center">
                                    <div className="bg-primary-transparent icon-bg icon-service text-primary">
                                        <i className='icon icon-people'   
                                        width={80}
                                        height={80} />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <hr style={{ marginTop: "15px", marginBottom: "15px" }} />
                            <p className="text-muted  mb-1  mt-3 ">
                                <h5 className="font-weight-normal text-muted fs-14" style={{ marginTop: '10px' }}>
                                    <b>{data?.nbUser}</b>   {user?.type==="promotrice" ? "Total Promotrices actives ": t('dashboard.TotalMerchandiseurs')}
                                </h5>
                            </p>
                                
                                </>}
                            
                        </div>
                    </div>
                </div>
                {/**------------ CARD VISITES ------------------------------- */}
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                        {loadingCard ? <SqaureLoader/> : 
                                <> 
                            <h6 className="card-title font-weight-normal  fs-14 mb-4 text-primary">
                                <span className="pulse bg-success" style={{ position: "relative" }} />
                                <a className="mr-2" href={"/rapports/visitesMagasins&datedebut=" + today + "&datefin=" + today}> <b> {data?.visite_today}</b>  </a>
                                {t('dashboard.Visites')} ({data?.pdv_planifiee} {t('dashboard.planifie')})
                                <span className='float-right'>
                                    {t('dashboard.Le')} {moment(today).format("DD-MM-YYYY")}
                                </span>
                            </h6>
                            <div className="d-flex  align-items-center">
                                <h4 className=" mb-0 fs-14">
                                    <b>{data?.visite_month}</b>   ({moment(today).format("MMMM")})
                                </h4>
                                <div className="item-box text-center">
                                   
                                </div>
                                <div className="text-primary mt-0 mb-2  ml-auto">
                                <div className="bg-primary-transparent icon-bg icon-service text-primary">
                                        <i className='ti-map-alt'   
                                        width={80}
                                        height={80} />
                                    </div>
                                </div>
                            </div>
                            <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                            <p className="text-muted   ">
                                <h5 className="font-weight-normal text-muted fs-14" style={{ marginTop: '10px' }}>
                                    {t('dashboard.totPdvs')}  : {data?.nbrPdv}
                                    <h5 className='float-right font-weight-normal fs-14'>
                                        {t('dashboard.TxCouverture')}: <span className='text-success'> {Math.round(parseFloat(data?.visite_month / data?.nbrPdv) * 100)} %</span>
                                    </h5>
                                </h5>
                            </p>
                            </>
                            }
                        </div>
                    </div>
                </div>
                {/**------------ CARD Ruptures ------------------------------- */}
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                        {loadingCard ? <SqaureLoader/> : 
                                <> 
                            <h5 className="card-title font-weight-normal fs-14 mb-4 text-primary">
                                <b className='mr-2'>{data?.rupture_today}</b>
                                {t('dashboard.rupture')}
                                <span className='float-right'>
                                    {t('dashboard.Le')} {moment(today).format("DD-MM-YYYY")}
                                </span>
                            </h5>
                            <div className="d-flex  align-items-center">
                                <h4 className=" mb-0 fs-14">
                                    {data?.rupture_month}  ({moment(today).format("MMMM")})
                                </h4>
                                <div className="text-primary mt-0 mb-2  ml-auto">
                                <div className="bg-primary-transparent icon-bg icon-service text-primary">
                                        <i className='ti-na'   
                                        width={80}
                                        height={80} />
                                    </div>
                                </div>
                              
                            </div>
                            <hr style={{ marginTop: "15px", marginBottom: "15px" }} />
                            <p className="text-muted  mb-1  ">
                                <h5 className="font-weight-normal text-muted fs-14" style={{ marginTop: '10px' }} >
                                    {t('dashboard.Evolution')} : <span className='text-success' data-toggle="tooltip" data-placement="top" title={t('dashboard.titleRupture')}> 
                                    {parseFloat(((data?.rupture_month - data?.rupture_monthPrecedent) / data?.rupture_monthPrecedent) * 100).toFixed(2)} %</span>
                                </h5>
                            </p>
                            </>
                            }
                        </div>
                    </div>
                </div>
                {/**------------ CARD Moyenne Rupture ------------------------------- */}
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            {loadingCard ? <SqaureLoader/> : 
                            <> 
                            <h5 className="card-title font-weight-normal fs-14 mb-4 text-primary">
                                {t('dashboard.MoyRupture')}
                            </h5>
                            <div className="d-flex  align-items-center"> {/**
                             * SUM RUPTURE MONTH / SUM Visite DISTINCT PDV DAY MONTH GROUP BY 
                             */}
                                <h4 className=" mb-0 fs-14">
                                    <b className='text-primary' data-toggle="tooltip" data-placement="top" title={t('dashboard.formuleMoyenneRupture')}>
                                        {parseFloat(data?.rupture_month / data?.totalVisiteDistinct).toFixed(2)} </b>
                                         en ({moment(today).format("MMMM")}) ({data?.totalVisiteDistinct}  {t('dashboard.visites')})
                                </h4>
                                <div className="text-primary mt-0 mb-2  ml-auto">
                                <div className="bg-primary-transparent icon-bg icon-service text-primary">
                                        <i className='icon icon-graph '   
                                        width={80}
                                        height={80} />
                                    </div>
                                </div>
                            </div>
                            <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                            <p className="text-muted  mb-1  ">
                                <h5 className="font-weight-normal text-muted fs-14" style={{ marginTop: '10px' }}>
                                    {parseFloat(data?.rupture_annee / data?.totalVisiteDistinctYear).toFixed(2)} :   
                                    ({moment().format('YYYY')})  ({data?.totalVisiteDistinctYear}  {t('dashboard.visites')})
                                </h5>
                            </p>
                             </>}
                        </div>
                    </div>
                </div>
                   {/**------------ CARD ALERTE ------------------------------- */}
                   <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="card overflow-hidden ">
                        <div className="card-body">
                        {loadingCard ? <SqaureLoader/> : 
                            <>
                            <h6 className="card-title  font-weight-normal fs-14 mb-4 text-primary">
                                {t('dashboard.Alerte')}
                            </h6>
                            <div className="d-flex  align-items-center">
                                <h4 className="font-weight-normal mb-0 fs-12">
                                    <span className="pulse bg-danger" style={{ position: "relative" }} />
                                    <span className='fs-18 ' data-toggle="modal" data-target="#exampleModal"> {data?.nbr_promo > 0 ? <b className='text-danger'>{data?.nbr_promo}</b> : data?.nbr_promo} </span>   {t('dashboard.Promotionsnonrespectees')}
                                </h4>
                                <div className="text-danger mt-0 mb-2  ml-auto">
                                <div className="bg-danger-transparent icon-bg icon-service text-danger">
                                        <i className='ti-alert bell-animations '   
                                        width={80}
                                        height={80} />
                                    </div>
                                </div>
                               
                            </div>
                            <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                            <p className=" text-muted mb-1  ">
                                <h5 className="font-weight-normal fs-14" style={{ marginTop: '10px' }}>
                                    {t('dashboard.Manqueagagner')} ( {moment(today).format("MMMM")}) :<span className='text-danger fs-16' title={t('dashboard.titleRupture')}>  {data?.manque_a_gagner}</span>

                                </h5>
                            </p>
                             </>
                            }
                        </div>
                    </div>
                </div>
                {/**------------ CARD Part Linéaire ------------------------------- */}
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                        {loadingCard ? <SqaureLoader/> : 
                            <>
                            <h6 className="card-title font-weight-normal fs-14 mb-4 text-primary">
                                <b>{Math.round(data?.partLineaire_today === 'NULL' ? 0 : data?.partLineaire_today)} %  </b>  {t('dashboard.pl')}  ({(data?.pdv_partLineaire_today)} {t('dashboard.Magasin')})
                                <span className='font-weight-normal float-right'>
                                    {t('dashboard.Le')} {moment(today).format("DD-MM-YYYY")}
                                </span>
                            </h6>
                            <div className="d-flex  align-items-center">
                                <h4 className=" mb-0 fs-14">
                                    {Math.round(data?.partLineaire)} %   en ({moment(today).format("MMMM")})
                                    ({(data?.pdv_partLineaire_month)} {t('dashboard.Magasin')})
                                </h4>
                                <div className="text-primary mt-0 mb-2  ml-auto">
                                <div className="bg-primary-transparent icon-bg icon-service text-primary">
                                        <i className='icon icon-grid  '   
                                        width={80}
                                        height={80} />
                                    </div>
                                </div>
                               
                            </div>
                            <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                            <p className="text-muted  mb-1  ">
                                <h5 className="font-weight-normal fs-14" style={{ marginTop: '10px' }}>
                                    {t('dashboard.Evolution')}   :  <span className={evolutiolPl > 0 ? 'text-success' : 'text-danger'} 
                                    data-toggle="tooltip" data-placement="top" title={t('dashboard.titlePl')}>
                                        <b> {evolutiolPl} %</b>
                                    </span>
                                </h5>
                            </p>
                            </>}
                        </div>
                    </div>
                </div>
             
            </div>
            {/* row closed */}
            <ModalListePromo listePromo={listePromo} t={t} />

        </div>
    )
}
