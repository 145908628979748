import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getListedelegations, getListeSecteurs, getListZones, updateLocalitesByCode } from '../../../services/Zoning/ZoningApi'

export default function ModalUpdateLocalite({ code, index,object, setShow ,setShowEdit,t }) {

  const [libelle, setlibelle] = useState("")
  const [codeDelegation, setcodeDelegation] = useState(0)
  const [codeSecteurs, setcodeSecteurs] = useState(0)
  const [codeZones, setcodeZones] = useState(0)
  //  Liste des delegations 
  const [listeDelegation, setlisteDelegation] = useState([])
  const [listeSecteurs, setlisteSecteurs] = useState([])
  const [listeZones, setlisteZones] = useState([])
   //-------- Loading -----------------------
   const [loading, setloading] = useState(true)

  useEffect(() => {
    
    setloading(true) 
    setlibelle(object?.libelle)
    if(object?.delegation_code !== null){
      setcodeDelegation(object?.delegation_code)
    }
    if(object?.zone_code !== null){
      setcodeZones(object?.zone_code)
    }
    if(object?.secteur_code !== null){
      setcodeSecteurs(object?.secteur_code)
    }

    getListedelegations()
        .then((result) => {
          if(result?.data?.success === 1){
            setlisteDelegation(result?.data?.data)
          }
        }).catch((err) => {
            console.log(err)
        })
    getListeSecteurs()
        .then((result) => {
          if(result?.data?.success === 1){
            setlisteSecteurs(result?.data?.data)
          }
        }).catch((err) => {
            console.log(err)
        })
    getListZones()
        .then((result) => 
        {
          if(result?.data?.success === 1){
            setlisteZones(result?.data?.data)
          }
        }).catch((err) => {
            console.log(err)
        })
        setloading(false)

}, [object])

const saveData = () =>{
  if (code !== "" && libelle !== "") {
    const data = {
        code: code,
        libelle: libelle
    }
    if(codeDelegation !== 0) {
      Object.assign(data,{
        delegation_code : codeDelegation
      })
    }
    if(codeSecteurs !== 0) {
      Object.assign(data,{
        secteur_code : codeSecteurs
      })
    }
    if(codeZones !== 0) {
      Object.assign(data,{
        zone_code : codeZones
      })
    }
    updateLocalitesByCode(code, data)
        .then((result) => {
            if (result?.data?.success === 0) {
                toast(t('Zoning.Erreur'), {
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else if (result?.data?.success === 1) {
                toast("Localité est modifié avec succée !", {
                    type: "success",
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
        }).catch((err) => {
            console.log(err)
        });
}
}
  return (
    <div className="modal fade" id="updateLocalite" tabIndex={-1} role="dialog" aria-hidden="true" key={index} >
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title text-azure" id="updateLocalite"> <i className='icon icon-map mr-1' /> {t('Zoning.modifierlocalite')} </h5>
                <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() =>{setShow(false)}} /> </span>
            </div>
            <div className="modal-body">
                {loading ? <SqaureLoader /> : (
                    <form>
                        <div className='row'>
                            <div className=" form-group col-6">
                                <label className="form-control-label">{t('rapports.code')} <span className="text-danger">*</span></label>
                                <input type="text" value={code} disabled className="form-control" />
                            </div>
                            <div className="form-group col-6">
                                <label className="form-control-label">{t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group col-4">
                            <label className="mr-2 mt-1 mb-sm-1"> {t('Zoning.secteur')} <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codeSecteurs}
                                    onChange={(e) => {
                                        setcodeSecteurs(e.target.value)
                                    }}>
                                    <option value={0}> {t('Zoning.choisirsecteur')}  </option>
                                    {listeSecteurs?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group col-4">
                            <label className="mr-2 mt-1 mb-sm-1"> Delegations <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codeDelegation}
                                    onChange={(e) => {
                                        setcodeDelegation(e.target.value)
                                    }}>
                                    <option value={0}>  {t('Zoning.choisirdelegation')}  </option>
                                    {listeDelegation?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                            <div className='form-group col-4'>
                            <label className="mr-2 mt-1 mb-sm-1"> Zones <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codeZones}
                                    onChange={(e) => {
                                        setcodeZones(e.target.value)
                                    }}>
                                    <option value={0}> {t('Zoning.choisirzone')}  </option>
                                    {listeZones?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                     
                    </form>
                )}

            </div>
            <div className="modal-footer ">
            <button type="button" className="btn btn-azure fs-12 " onClick={() => { saveData(); setShowEdit(true); setShow(false) }} > <i className='icon icon-cloud-download mr-1' />Enregister </button>
            <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" > <i className="ti-close mr-1" />Fermer</button>

            </div>
        </div>
    </div>
</div>
  )
}
