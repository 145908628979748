import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getListeGouvernorat, postObjectDelegation } from '../../../services/Zoning/ZoningApi'
import { myRnId } from '../../../utils/DateUtils'

export default function ModalAddDelegations({ setShow ,t}) {

    //------ Formulaire -----------
    const [code, setCode] = useState("")
    const [libelle, setlibelle] = useState("")
    const [codeGouvernorat, setCodeGouvernorat] = useState(0)
    //  Liste des pays 
    const [listeGouvernorat, setlisteGouvernorat] = useState([])


    useEffect(() => {
        getListeGouvernorat()
        .then((result) => {
            if(result?.data?.success === 1){
                setlisteGouvernorat(result?.data.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    const traitement = (data)=>{
        postObjectDelegation(data)
        .then((result) => {
            if (result.data.success === 0 ){
                toast(t('Zoning.Erreur'), {
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT,
                  })
            }else if (result.data.success === 1){
                toast("Délegation  ajouté avec succée !", {
                    type: "success",
                    position: toast.POSITION.TOP_RIGHT,
                  })
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const saveData = () => {

        //Data of code & libelle FOR OBJECT PAYS
        if(code === "" && libelle !== ""){
            var generateCode = libelle.slice(0, 3)
            setCode(generateCode + "-" + myRnId())
            const data= {
                code : generateCode + "-" + myRnId(),
                libelle : libelle
            }
            if(codeGouvernorat !== 0){
                Object.assign(data,{
                    gouvernorat_code : codeGouvernorat
                })
            }
            traitement(data)
        }else if (code !=="" && libelle !==""){
            const data= {
                code :code,
                libelle : libelle
            }
            if(codeGouvernorat !== 0){
                Object.assign(data,{
                    gouvernorat_code : codeGouvernorat
                })
            }
            traitement(data)
        }
    }
    return (
        <div className="modal fade" id="exampleModal7" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3">Ajouter une délegation </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>setShow(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label className="form-control-label">{t('rapports.code')} <span className="text-danger">*</span></label>
                                <input type="text" value={code} onChange={(e)=>setCode(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">{t('dashboard.Libelle')} <span className="text-danger">*</span></label>
                                <input type="text" value={libelle} onChange={(e)=>setlibelle(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="mr-2 mt-1 mb-sm-1"> Gouvernorat <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codeGouvernorat}
                                    onChange={(e) => {
                                        setCodeGouvernorat(e.target.value)
                                    }}>
                                    <option value={0}> choisir </option>
                                    {listeGouvernorat?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-azure fs-12 " onClick={() => saveData()} > <i className='icon icon-cloud-download mr-1' />Enregister </button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" onClick={() => setShow(false)}> <i className="ti-close mr-1" />Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
