import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader';


dotenv.config()
const { REACT_APP_API_URL } = process.env;

//GET URL BACKEND 
const url = REACT_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
const role=user?.result?.role_code
const user_code =user?.result?.code
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getDashboardItems( today ) {
    try {
        const response = await axios.get(url + "dashboard/cards/"+today+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getMessageDashboard( today ) {
    try {
        const response = await axios.get(url + "dashboard/messages/"+today, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getGammesRuptures( datedebut, datefin ) {
    try {
        const response = await axios.get(url + "dashboard/ruptureGammes/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getGraphGammes( datedebut , datefin ) {
    try {
        const response = await axios.get(url + "dashboard/gammes/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getGraphRegions( datedebut , datefin ) {
    try {
        const response = await axios.get(url + "dashboard/regions/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getGraphEnseigne( datedebut , datefin ) {
    try {
        const response = await axios.get(url + "dashboard/enseignes/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getTouByCode( code ) {
    try {
        const response = await axios.get(url + "dashboard/getTournee/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getListeTournee( today , codejour ) {
    try {
        const response = await axios.get(url + "dashboard/tournees/"+today+"/"+codejour+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * get Details Ruptures
 * @param {*} today 
 * @param {*} pdv_code 
 * @param {*} user_code 
 * @returns 
 */
 export async function getDetailsRuptures( today , user_code) {
    try {
        const response = await axios.get(url + "dashboard/getDetailsRuptures/"+today+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} month 
 * @param {*} year 
 * @param {*} pdv_code 
 * @param {*} produit_code 
 * @returns 
 */
export async function getDetailsRupturesByPdvandMonth( month,year,pdv_code,produit_code ) {
    try {
        const response = await axios.get(url + "dashboard/getDetailsRupturesByMonthPdv/"+pdv_code+"/"+month+"/"+year+"/"+produit_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * get Details PL pour le modal
 * @param {*} today 
 * @param {*} pdv_code 
 * @param {*} user_code 
 * @returns 
 */
 export async function getDetailsPL( today , user_code) {
    try {
        const response = await axios.get(url + "dashboard/getDetailPl/"+today+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * Get Details Releve Prix
 * @param {*} today 
 * @param {*} pdv_code 
 * @param {*} user_code 
 * @returns 
 */
export async function getDetailRelvePrix( today , user_code) {
    try {
        const response = await axios.get(url + "dashboard/getDetailsRelevePrix/"+today+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

export async function getListePdvPlanfiee( today , user_code) {
    try {
        const response = await axios.get(url + "dashboard/getpdvplanifiee/"+today+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function getListePdvVisite( today , user_code) {
    try {
        const response = await axios.get(url + "dashboard/getpdvVisite/"+today+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET ALL LISTE PDV 
 * @param {*} today 
 * @param {*} user_code 
 * @returns 
 */
export async function getListeAllPDV( today ,code_jour, user_code) {
    try {
        const response = await axios.get(url + "dashboard/getallplanifiee/"+today+"/"+code_jour+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET LISTE IMAGES
 * @param {*} today 
 * @returns 
 */
 export async function getImages( today) {
    try {
        const response = await axios.get(url + "dashboard/images/"+today, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET LISTE IMAGES
 * @param {*} today 
 * @returns 
 */
 export async function getImagesRayons( date_debut, date_fin , data) {
    try {
        const response = await axios.post(url + "dashboard/photorayons/"+date_debut+"/"+date_fin,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function getImagesRayonsByPdv( date_debut, date_fin ,pdv_code, data) {
    try {
        const response = await axios.post(url + "dashboard/photorayonsbypdv/"+date_debut+"/"+date_fin+"/"+pdv_code,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * get Detail Promotions
 * @param {*} today 
 * @param {*} pdv_code 
 * @param {*} user_code 
 * @returns 
 */
export async function getDetailPromotions( today , user_code) {
    try {
        const response = await axios.get(url + "dashboard/getDetailsPromotions/"+today+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getTableauTournee( today ,codejour ) {
    try {
        const response = await axios.get(url + "dashboard/rapportTournee/"+today+"/"+codejour+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getTableauDayMonthDashboard( today ,codejour ) {
    try {
        const response = await axios.get(url + "dashboard/tableTournee/"+today+"/"+codejour+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}