import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { postObjectRoutings } from '../../../services/Routings/RoutingsApi'
import { SketchPicker } from 'react-color';
import { getListeUsers } from '../../../services/Users/UsersApi';

export default function ModalAddRoutes({setShow,t}) {

      //------ Formulaire -----------
      const [code, setCode] = useState("")
      const [color, setColor] = useState("#fffff" )
      const [userCode,setUserCode] = useState("")
      const [listeUsers,setListeUsers ]= useState([])
  
      useEffect(()=>{
          
          getListeUsers()
          .then((result) => {
              setListeUsers(result?.data.data)    
          }).catch((err) => {
              console.log(err)
          });
      },[])


      const saveData = () => {
          //Data of code & libelle FOR OBJECT PAYS
          if (code !== "" && color !== "") {
              const data = {
                  code: code,
                  color: color,
                  user_code : userCode
              }
              postObjectRoutings(data)
                  .then((result) => {
                      if (result?.data?.success === 0) {
                          toast(t('Zoning.Erreur'), {
                              type: "error",
                              position: toast.POSITION.TOP_RIGHT,
                          })
                      } else if (result?.data?.success === 1) {
                          toast(t('messageErreur.ajoutavecsuccee'), {
                              type: "success",
                              position: toast.POSITION.TOP_RIGHT,
                          })
                      }
                  }).catch((err) => {
                      console.log(err)
                  });
          }
      }

      const handleChangeComplete = (color ) =>{
          setColor(color.hex)
          
      }
    return (
        <div className="modal fade" id="exampleModal3" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3">{t('Routings.ajouterRoute')}  </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className='row'>
                                <div className='col-6'>
                                <div className="form-group">
                                <label className="form-control-label">{t('rapports.code')} :</label>
                                <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                 </div>
                                </div>
                                <div className='col-6'>
                                <div className="form-group">
                                <label className="mr-2 mt-1 mb-sm-1"> {t('Produits.UTILISATEUR')} <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={userCode}
                                    onChange={(e) => {
                                        setUserCode(e.target.value)
                                    }}>
                                    <option value={0}> {t('Routings.choisir')}  </option>
                                    {listeUsers?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.nom} </option>
                                    ))}
                                </select>
                            </div>
                                </div>
                            </div>
                           
                            
                            <div className="form-group">
                                <label className="form-control-label">{t('Produits.Couleur')} :</label>
                                <SketchPicker
                                    color={color}
                                    onChangeComplete={handleChangeComplete}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => saveData()} > <i className='ti-save mr-1'/> {t('rapports.enregistrer')} </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShow(false)}> <i className='ti-close mr-1'/> {t('Zoning.Fermer')}</button>

                    </div>
                </div>
            </div>
        </div>
    )
}
