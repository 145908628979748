/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import PaginationTable from '../../components/Pagination/PaginationTable'
import { getListeUsers } from '../../services/Users/UsersApi'
import { getListZones } from '../../services/Zoning/ZoningApi'
import { SearchItems } from '../../utils/SearchUtils'

export default function ListeUtilisateurs() {

    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    //-------- LISTE USERS -------------------
    const [listeUsers, setlisteUsers] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //Loader 
    const [loading, setLoading] = useState(true)

    const [codeZone, setcodeZone] = useState(-1) // CODE ZONE
    //-------- LISTE USER ------------
    const [listeZones, setlisteZones] = useState([])

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(30);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search?.length < 1 ? listeUsers?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getListZones()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteZones(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        //--- GET LISTE USERS +---
        getListeUsers()
            .then((result) => {
                if (result?.data !== null) {
                    setlisteUsers(result?.data?.data)
                    console.log(result?.data.data)
                    setSaveList(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [])
    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeUsers, search)
            setSearchlist(newList)
        } else {
            setlisteUsers(saveList)
        }
    }
    const handleChangeZone = (e)=>{
        setcodeZone(e.target.value)
        const liste = saveList?.filter((el)=>el.zone_code===e.target.value)
        setlisteUsers(liste)
    }
    return (

        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.utilisateurs')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('sidebar.listeUtilisateurs')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/users/parametrage" className="btn btn-azure fs-12 btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-settings mr-1" />
                            {t('sidebar.parametrageUtilisateur')}
                        </a>
                        <a href="/users/ajouterutilisateur" className="btn btn-azure fs-12 btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-wrench mr-1" />
                            {t('sidebar.ajoutUser')}
                        </a>

                    </div>
                </div>
            </div>
            <div className="  mb-4 ">
                <div className="form row no-gutters">
                    <div className="col-7"></div>
                    <div className=" col-xl-5 col-lg-5 col-md-5 mb-0">
                        <div className="input-group">
                            <input type="text" ref={inputEl} value={search}
                                onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary"
                                id="text1" placeholder="Rechercher un utilisateur" />
                            <select value={codeZone} onChange={(e) => handleChangeZone(e)} className="form-control  custom-select br-md-0">
                                <option value={-1} > {t('rapports.Zones')} ... </option>
                                {listeZones?.map((items, keys) => (
                                    <option value={items.code} key={keys}>{items.libelle} </option>
                                ))}
                            </select>

                            <button className="btn bg-primary"  ><i className="ti-search"></i></button>

                        </div>


                    </div>

                </div>
            </div>
            {/** -------------------------- Liste UTILISATEURS -----------------------------  */}
            {loading ? <SqaureLoader /> : (
                <>
                    <div className="row">
                        {currentListe?.map((items, keys) => (

                            <div className="col-xl-4 col-lg-6 col-md-12" key={keys}>
                                <div className="card box-widget widget-user">
                                    <div className="widget-user-header bg-purple">
                                        <a className="text-white h6" href={"/users/modifierutilisateur&id=" + items.code}>{items.code + " : " + items.nom} <span className=" float-right" style={{ fontSize: "12px" }}>
                                            {items.role_code}
                                            <i className="icon icon-note text-warning ml-1" />
                                        </span>
                                        </a>
                                        <h6 >{items.email}</h6>

                                    </div>
                                    <div className="widget-user-image"><img alt="User Avatar" className="rounded-circle" src={items.image === "" || items.image ==="NULL"  ? "../assets/images/users/2.jpg" : items.image } /></div>
                                    <div className="box-footer">
                                        <div className="row">
                                            <div className="col-sm-6 border-right border-top">
                                                <div className="description-block">
                                                    <h5 className="description-header fs-12">{items.cin !== null ? items.cin : "-"}</h5><span className="text-muted">{t('users.cin')}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 border-right border-top">
                                                <div className="description-block">
                                                    <h5 className="description-header fs-12">{items.telephone !== null ? items.telephone : "-"}</h5><span className="text-muted">{t('users.phone')}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4 border-right border-top">
                                                <div className="description-block">
                                                    <h5 className="description-header fs-12">{items.societe !== null ? items.societe : "-"}</h5><span className="text-muted">{t('Produits.Societe')}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 border-right border-top">
                                                <div className="description-block">
                                                    <h5 className="description-header fs-12">{items.regions !== null ? items.regions : "-"}</h5><span className="text-muted">{t('dashboard.region')}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4  border-top">
                                                <div className="description-block">
                                                    <h5 className="description-header fs-12">{items.zones !== null ? items.zones : "-"}</h5><span className="text-muted">{t('Routings.Zone')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                            <div className="description-block">
                                                    <h5 className="description-header fs-12"> <a href={"/users/details&user_code="+items.code} className="text-primary"> <u>Historique Merchandiseur</u></a> </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}

                    </div>
                    <div className="float-right">
                        <PaginationTable
                            postsPerPage={stocksPerPage}
                            totalPosts={search?.length < 1 ? listeUsers?.length : searchList?.length}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </>
            )}

        </div>
    )
}
