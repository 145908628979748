import React, { useRef, useState } from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { toast } from 'react-toastify';
import { postObjectSousFamille } from '../../../services/Produits/configurationProduits/ConfigurationAPI';
import { myRnId } from '../../../utils/DateUtils';

export default function AddModalSF({ listefamilles, setShow, t }) {
    //------- FORMULAIRE --------------
   // const [code, setCode] = useState("")
    const [libelle, setLibelle] = useState("")
    const [codeFamilles, setcodeFamilles] = useState("")
    //-------- REFERENCES --------------------
    const familles = useRef();

    const saveData = () => {
        //Data of code & libelle FOR OBJECT PAYS
        if ( codeFamilles !== "" && libelle !== "") {
            var generateCode = libelle.slice(0, 3)
            const data = {
                code: generateCode+ "-" + myRnId(),
                libelle: libelle,
                famille_code: codeFamilles
            }
            postObjectSousFamille(data)
                .then((result) => {
                    console.log(result.data.success)
                    if (result?.data?.success === 0) {
                        toast(t('Zoning.Erreur'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        toast(t('messageErreur.ajoutavecsuccee'), {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    // Handle Change Famille
    const handleFamilles = (...args) => {
        setcodeFamilles(args[1].value)
    };
    return (
        <div className="modal fade" id="addSFamille" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addSFamille">  <i className=' icon icon-equalizer mr-1 text-azure' />{t('Produits.AjouteruneSousFamille')}  </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} /> </span>

                    </div>
                    <div className="modal-body">
                        <form>
                            <div className='row'>
                             {/*    <div className="form-group col-6">
                                    <label className="form-control-label">{t('rapports.code')} :</label>
                                    <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                </div> */}
                                <div className="form-group col-12">
                                    <label className="mr-2 mt-1 mb-sm-1 fs-12"> {t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                    <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} className="form-control" />

                                </div>
                            </div>

                            <div className="form-group">
                                <label className="form-control-label">{t('Produits.FAMILLE')} :  <span className="text-danger">*</span></label>
                                <SelectSearch
                                    options={listefamilles}
                                    search
                                    ref={familles}
                                    onChange={handleFamilles}
                                    filterOptions={fuzzySearch}
                                    emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                    placeholder={t('Produits.choisirFamille')}
                                />
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer ">
                        <button type="button" className="btn btn-azure fs-12 " onClick={() => saveData()} > <i className='icon icon-cloud-download mr-1' />{t('Magasins.Enregistrer')}</button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" onClick={() => setShow(false)}> <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
                    </div>

                </div>
            </div>
        </div>
    )
}
