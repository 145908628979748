import React from 'react'
import { toast } from 'react-toastify';
import { editTourneeByCode } from '../../../services/Routings/RoutingsApi';

export default function ModalEditListeTournee({ code, setok, setcodeActif, codeActif, dateDebut, setdateDebut, jour, dateFin, setdateFin, setjour, t }) {

    const editTournee = (code, codepdv) => {
        const data = {
            actif: codeActif,
            date_debut: dateDebut,
            date_fin: dateFin,
            code_jour: jour

        }
        editTourneeByCode(code, data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('Routings.modifieSuccess'), { type: "success", position: "top-right" })
                } else {
                    toast(t('Routings.erreur'), { type: "error", position: "top-right" })
                }
            }).catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className="modal fade" id="modal" tabIndex={-1} role="dialog" aria-labelledby="modal" aria-hidden="true" >
            <div className="modal-dialog modal-danger" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal"> {t('Routings.editer') + " : " + code} </h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setok(true)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="py-3 text-center">
                            <h6 className="heading mt-4 fs-12"> {t('Routings.modifierValeur') + " : " + code + " ?"} </h6>
                            <div className="row">
                                <div className="col-6">
                                    <label className='fs-12' > {t('dashboard.actifs')} </label>
                                    <select value={codeActif} onChange={(e) => setcodeActif(e.target.value)} className="form-control  custom-select br-md-0 fs-12 text-center">
                                        <option value={"1"}>{t('rapports.oui')}</option>
                                        <option value={"0"}>{t('rapports.non')}</option>
                                    </select>
                                </div>
                                <div className="col-6">
                                    <label > {t('Routings.Jour')} </label>
                                    <select value={jour} onChange={(e) => setjour(e.target.value)} className="form-control  custom-select br-md-0 fs-12 text-center">
                                        <option value={"-1"} > {t('dashboard.jours')}  </option>
                                        <option value={"Lundi"}> {t('dashboard.lundi')}</option>
                                        <option value={"Mardi"}>{t('dashboard.Mardi')}</option>
                                        <option value={"Mercredi"}>{t('dashboard.Mercredi')}</option>
                                        <option value={"Jeudi"}>{t('dashboard.Jeudi')}</option>
                                        <option value={"Vendredi"}>{t('dashboard.Vendredi')}</option>
                                        <option value={"Samedi"}>{t('dashboard.Samedi')}</option>
                                        <option value={"Dimanche"}>{t('dashboard.Dimanche')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <label className='fs-12' >{t('Routings.datedebut')} </label>
                                    <input
                                        className="form-control fc-datepicker fs-12 text-center"
                                        type="date"
                                        value={dateDebut}
                                        onChange={(e) => setdateDebut(e.target.value)}
                                    />
                                </div>
                                <div className="col-6">
                                    <label className='fs-12' >{t('Routings.datefin')} </label>
                                    <input
                                        className="form-control fc-datepicker fs-12 text-center"
                                        type="date"
                                        value={dateFin}
                                        onChange={(e) => setdateFin(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary fs-12" onClick={() => { editTournee(code) }}> <i className="icon icon-check mr-1" /> {t('Routings.confirmer')}</button>
                        <button type="button" className="btn btn-secondary text-white ml-auto fs-12" data-dismiss="modal" onClick={() => setok(true)}><i className="icon icon-close mr-1" /> {t('Routings.annueler')} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
