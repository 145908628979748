import React from 'react'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function ModalListeMagasins({ listeMagasins, t, loading }) {
    return (
        <div className="modal fade" id="exportMagasins" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-azure fs-12" id="ListePDV"> {t('sidebar.listeMagasins')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close"  /> </span>
                        
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="table-responsive  product-datatable" style={{ overflow: 'auto', height: '500px' }}>
                                <table className="table  table-bordered " id="table-magasins">
                                    <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >
                                        <tr>
                                            <th className="w-15p fs-10" >{t('dashboard.Enseigne')}</th>
                                            <th className="wd-15p fs-10" >{t('rapports.code')}</th>
                                            <th className="wd-15p fs-10" >{t('dashboard.Magasin')}</th>
                                            <th className="wd-15p fs-10" >{t('Magasins.Assortiments')}</th>
                                            <th className="wd-15p fs-10" >{t('dashboard.region')}</th>
                                            <th className="wd-15p fs-10" >{t('rapportrupture.merch')}</th>
                                            <th className="wd-15p fs-10" >{t('rapportrupture.categorie')}</th>
                                        </tr>
                                    </thead>
                                    {loading ? <SqaureLoader /> : (
                                        <tbody>
                                            {listeMagasins?.map((items, keys) => (
                                                <tr key={keys} className="text-center pb-0 pt-0">
                                                    <td className='fs-10 text-left'>
                                                        <b>{items.nomenseigne}</b> </td>
                                                    <td className='fs-10'>
                                                      {items.code}
                                                    </td>
                                                    <td className='fs-10'> {items.nompdv}  </td>
                                                    <td className='fs-10'> {items.sum_ass} </td>
                                                    <td className='fs-10'>{items.regions !== null ? items.regions : "Aucune"}</td>
                                                    <td className='fs-10'>{items.nom !== null ? items.nom : <h6 className="text-danger"> - </h6>}</td>
                                                    <td className='fs-10'>{items.categoriepdv}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    )}

                                </table>

                            </div>
                        </div>
                    </div>
                 
                    <div className="modal-footer ">
                    <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-azure fs-10"
                            table="table-magasins"
                            filename="liste_Magasins"
                            sheet="tablexls"
                            buttonText={<span className=" text-center"> <i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')}</span>}
                        />
                        <button type="button" className="btn btn-secondary fs-10" data-dismiss="modal" > <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
