import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'


dotenv.config()
const { REACT_APP_API_URL } = process.env;

//GET URL BACKEND 
const url = REACT_APP_API_URL


/*** WEB SERVICE notes / catalogue / réclamations  */

/**
 * 
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} datedebut 
 * @param {*} datefin 
 * @returns 
 */
 export async function getListeNotes(datedebut,datefin) {
    try {
        const response = await axios.get(url + "notes/datedebut="+datedebut+"&datefin="+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * 
 * @param {*} code 
 * @returns 
 */
export async function getNoteByCode(code) {
    try {
        const response = await axios.get(url + "notes/notes/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function deleteNoteByCode(code) {
    try {
        const response = await axios.delete(url + "notes/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * Get liste  : Catalogue
 * @returns 
 */
 export async function getListeCatalogue(datedebut,datefin) {
    try {
        const response = await axios.get(url + "notes/catalogue/datedebut="+datedebut+"&datefin="+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getCatalogueByCode(code) {
    try {
        const response = await axios.get(url + "notes/catalogues/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * Get liste  : Catalogue
 * @returns 
 */
 export async function searchNotes(datedebut,datefin ,data) {
    try {
        const response = await axios.post(url +"notes/notesearch/datedebut="+datedebut+"&datefin="+datefin , data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * Get liste  : Catalogue
 * @returns 
 */
 export async function searchCatalogue(datedebut,datefin ,data) {
    try {
        const response = await axios.post(url +"notes/catalogueSearch/datedebut="+datedebut+"&datefin="+datefin , data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * Get liste  : RECLAMATION
 * @returns 
 */
 export async function getReclamations(datedebut,datefin ) {
    try {
        const response = await axios.get(url +"reclamations/datedebut="+datedebut+"&datefin="+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
