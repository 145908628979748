import React from 'react'

export default function ModalRapportJournalier({ listeImages ,t }) {
    return (
        <div className="modal fade" id="exampleModal2" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog" aria-hidden="true" >
            <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ width: "660px" }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3"> <i className='icon icon-picture text-azure mr-2'/>{t('contacts.imagerapportJournalier')}   </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close"  /> </span>

                    </div>
                    <div className="row">
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className="card">
                                <div className="card-body">
                                    <div className="content row">
                                        {listeImages?.length===0 ? 
                                        <div className='col-12 text-center'>
                                           <span className='text-danger'> {t('rapports.aucuneinfo')}</span> 
                                            <hr className='hr1' />
                                            <img src="../assets/images/photos/no.png" alt='noData' style={{ height: "200px" }} />
                                            </div>
                                         
                                        :listeImages?.map((items, keys) => (
                                            <a className="elem col-md-4" data-lcl-thumb={items.images} data-lcl-txt="Note" href={items.images} title="Note">
                                                <img className="img-responsive" src={items.images} alt="Thumb-2" title="Image 01" />
                                                <span className="img-responsive" data-image-src={items.images} />
                                            </a>
                                        ))}
                                        <br />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
