/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import { rapportRupturetMerch } from '../../../services/Rapports/RapportsApi';
import { getMerch } from '../../../services/Users/UsersApi';
import TableauDetailsMerch from './TableauDetailsMerch';

export default function RuptureByMerchPdv() {

    const history = useHistory();

    const { datedebut, datefin ,user_code} = useParams('')
    const searchInput = useRef();
    //----------- FILTRE ( date debut et date fin + Liste des merch) --------------
    const [date_debut, setdate_debut] = useState(datedebut)
    const [date_fin, setdate_fin] = useState(datefin)
    const [codeMerch, setcodeMerch] = useState(user_code)
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [listeMerch, setlisteMerch] = useState([])
    const [codePdvs, setcodePdvs] = useState([])
    //---------------- Liste pdvs ---------------
    const [pdvs, setpdvs] = useState([])
    const [savePdvs, setsavePdvs] = useState([])
    //--------------- LISTE ENSEIGNES ----------
    const [enseignes, setEnseignes] = useState([])
    const [Saveenseignes, setSaveEnseignes] = useState([])

    //Results of serach
    const [liste, setliste] = useState([])
    const [listeGrouped, setlisteGrouped] = useState([])
    const [listeMarques, setlisteMarques] = useState([])
    const [grouped, setgrouped] = useState([])
    
    //--------- Loader ------------------------
    const [loadingSearch, setloadingSearch] = useState(true)
    const [loading, setloading] = useState(true)

    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
      
        setloadingSearch(true)
        getMerch().then((result) => {
            if (result?.data?.success === 1) {
                setlisteMerch(result?.data?.data);
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloadingSearch(false)
        })
    }, []);

    const handleChangeMerch = (...args) => {
        setcodeMerch(args[1].value)
    }
    const handleChangeEnseigne= (...args) => {
        setcodeEnseigne(args[1].value)
        const L = Saveenseignes?.filter((el)=> el.value ===args[1].value )
        setEnseignes(L)
    }
    const handleChangePdvs= (...args) => {
        setcodePdvs(args[1].value)
        const L = savePdvs?.filter((el)=> el.value ===args[1].value )
        setpdvs(_.groupBy(L,"enseigne_code"))
        var enseigne = L[0]?.enseigne_code
        const L1 = Saveenseignes?.filter((el)=>el.value === enseigne)

       setEnseignes(L1)
    }
    const saveData = () => {
        setloading(true)
        if (date_debut !== "" && date_fin !== "" && codeMerch !=="") {
            if (date_debut <= date_fin) {
                setloading(true)
                history.push({
                    pathname: "/ruptures/merch&datedebut=" + date_debut + "&datefin=" + date_fin+"&user_code="+codeMerch
                })
                rapportRupturetMerch(date_debut, date_fin, codeMerch)
                    .then((result) => {
                        if (result?.data?.success === 1) {
                           var selectedListe = _.groupBy(result?.data?.listetotal, function(item) {
                            return item.pdv_code;
                          });
                          _.forEach(selectedListe, function(value, key) {
                            selectedListe[key] = _.groupBy(selectedListe[key], function(item) {
                              return moment(item.date).format("YYYY-MM-DD");
                            });
                          });
                          setgrouped(selectedListe);
                            setliste(result?.data?.listetotal)
                            setlisteMarques(result?.data?.listeMarques);
                            setlisteGrouped(result?.data?.listeGrouped);
                            var array_pdv = []
                            result?.data?.listePdvs?.map((items)=>{
                                array_pdv.push({
                                    value: items.value,
                                    name : items.pdv,
                                    enseigne_code : items.enseigne_code
                                })
                            })
                            setpdvs(_.groupBy(array_pdv,"enseigne_code"))
                            setsavePdvs(_.uniqBy(array_pdv,"value"))
                            console.log(_.uniqBy(array_pdv,"value"));
                            setEnseignes(result?.data?.listeEnseigne)
                            setSaveEnseignes(result?.data?.listeEnseigne)
                        }
                    }).catch((err) => {
                        console.log(err)
                    }).finally(() => {
                        setloading(false)
                    })
            } else if (codeMerch === ""){
                toast(t('Veuillez Choisir un Merchandiseur'), { type: "warning", position: "top-right" })
            } else if (date_fin < date_debut) {
                toast(t("messages.dateinf"), { type: "warning", position: "top-right" })
            }
        }

    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.rupture')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Détails Rupture by Merch')}</li>
                </ol>
            </div>
            <ToastContainer />
            {loadingSearch ? <Loader /> :
                <div className='row'>
                    <div className='col-2' />
                    <div className='col-8'>
                        <div className='input-group'>
                            <input type="date" className='form-control fs-12' value={date_debut} onChange={(e) => setdate_debut(e.target.value)} />
                            <input type="date" className='form-control fs-12' value={date_fin} onChange={(e) => setdate_fin(e.target.value)} />
                            
                            <SelectSearch
                                value={codeMerch}
                                options={listeMerch}
                                search
                                ref={searchInput}
                                onChange={handleChangeMerch}
                                filterOptions={fuzzySearch}
                                emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                placeholder={t('dashboard.Merchandiseurs')}
                            />
                           
                            <button className='btn btn-azure' onClick={() => saveData()}> <i className='ti-search' /></button>

                        </div>
                    </div>
                    <div className='col-2' />
                    
                   {enseignes?.length !== 0 && <div className='col-12'>
                   
                    <div className='col-8 mt-4'>
                    <div className='input-group'>
                    <label className='mt-2 mr-2'> Filtre :  </label>
                   <SelectSearch
                                value={codeEnseigne}
                                options={Saveenseignes}
                                search
                                ref={searchInput}
                                onChange={handleChangeEnseigne}
                                filterOptions={fuzzySearch}
                                emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                placeholder="Enseigne"
                            />
                             <SelectSearch
                                value={codePdvs}
                                options={savePdvs}
                                search
                                ref={searchInput}
                                onChange={handleChangePdvs}
                                filterOptions={fuzzySearch}
                                emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                placeholder="Magasins"
                            />
                            </div>
                            </div>
                            <div className='col-2' />

                        </div> } 
                </div>}
            <div className='row mt-4'>
                {loading ?
                    <div className='col-12'>
                        <div className="alert bg-purple mb-5 py-4" role="alert">
                            <div className="d-flex">
                                <i className="icon icon-info mr-3 fs-30"></i>
                                <div>
                                    <h4 className="alert-heading fs-16 mt-3"> {t('Vous devez sélectionner les champs nécessaires pour visualiser le tableau de rupture des Merchandiseurs par magasin')} </h4>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <TableauDetailsMerch enseignes={enseignes} grouped={grouped} liste={liste} listeGrouped={listeGrouped} listeMarques={listeMarques} pdvs={pdvs} t={t} setloading={setloading} />

                }
            </div>

        </div>
    )
}
