import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader';


dotenv.config()
const { REACT_APP_API_URL } = process.env;

//GET URL BACKEND 
const url = REACT_APP_API_URL


/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getListeReleve( datedebut ) {
    try {
        const response = await axios.get(url + "relevePrix/releve/"+datedebut, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getListeRelevePrix( datedebut ,datefin ,enseigne_code) {
    try {
        const response = await axios.get(url + "relevePrix/produit/"+datedebut+"/"+datefin+"/"+enseigne_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

export async function getListeRelevePrixSF ( datedebut ,datefin ,enseigne_code) {
    
    try {
        const response = await axios.get(url + "relevePrix/sousfamilles/"+datedebut+"/"+datefin+"/"+enseigne_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}