/***RECHERCHER UN ELEMENT DANS UNE LISTE */
export function SearchItems(array,search) {
    const newList = array?.filter((el) => {
        return Object.values(el)
        .join(" ")
        .toLowerCase()
        .includes(search.toLowerCase())
        
        })
    return newList
  }


  export function groupBy(arraytable,props){
    return arraytable.reduce(function(acc,obj){
        var cle = obj[props];
        if(!acc[cle]){
            acc[cle]=[];
        }
        acc[cle].push(obj);
        return acc;
    },{})
}
// FOR PAGE PRODUCT 
export function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }