/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getDetailsRapportByCode, getUsersRapport } from '../../../services/Rapports/RapportsApi'
import { distance } from '../../../utils/Distance'
import Localisationpdvs from './Localisationpdvs'
import { useTranslation } from 'react-i18next';
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import ExportExcel from "../../commandes/ExportExcel"


export default function RapportJournalier() {
    //------ History -----------------
    const history = useHistory()
    //------ PARAM --------------------
    const { date, user_code } = useParams('')
    //------ References -----------------
    const [today, settoday] = useState(date)
    // Formulaire 
    const [listeUsers, setlisteUsers] = useState([])
    const [listeMagasins, setlisteMagasins] = useState([])
    const [listeReleve, setlisteReleve] = useState([])
    const [listePointages, setlistePointages] = useState([])
    const [localisation, setlocalisation] = useState([])
    const [times, settimes] = useState("")
    const [inmagasin, setinmagasin] = useState("")
    const [loading, setloading] = useState(false)
    // EXPORT  
    const [LISTE, setLISTE] = useState([])

    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        traitement()
        if (user_code !== "") {
            showDetailsMagasinsPointage(user_code)
        }
    }, [])

    const traitement = () => {
        getUsersRapport(today, today)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteUsers(result?.data?.listeUsers)
                    setLISTE(result?.data?.liste_export)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }
    const onChangeDateFormat = () => {

        history.push({
            pathname:
                '/rapports/rapportJournalier&date=' + today + "&usercode=" + user_code,
        })
        traitement()

    }

    const showDetailsMagasinsPointage = (code) => {
        history.push({
            pathname:
                '/rapports/rapportJournalier&date=' + today + "&usercode=" + code,
        })
        getDetailsRapportByCode(code, today)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setloading(true)
                    setlisteReleve(result?.data?.listeReleve)
                    settimes(result?.data?.totaletime.totaletime)
                    setlisteMagasins(result?.data?.listeMagasins)
                    setlistePointages(result?.data?.listePointages)
                    setlocalisation(result?.data?.localisation)
                    setinmagasin(result?.data?.inMagasins[0].inMagasins)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.RapportJournalier')} </li>
                </ol>
            </div>
            <div className="row">

                {/** --------------- Tableau Magasins ----------------------- */}
                <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'>
                    <div className='row mb-2'>
                        <div className='col-3'></div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                            <div className="input-group">
                                <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary" placeholder="Search for..." />
                                <span className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                                    <ExportExcel excelData={LISTE} fileName={"Rapport_Journalier"} noHandle={true} />

                                </span>
                            </div>
                        </div>
                        <div className='col-3'></div>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>

                            <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                {listeUsers?.map((items, keys) => (
                                    <button className="btn tag bg-purple" key={keys} onClick={() => showDetailsMagasinsPointage(items.user_code)}> {items.nom} : ({items.total})</button>
                                ))}
                            </div>
                        </div>
                    </div>


                    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                        <div className="panel panel-default active">
                            <div className="panel-heading " role="tab" id="headingOne">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <i className="icon icon-grid text-primary mr-2" /> {t('rapports.VisiteMagasins')}
                                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    <div className="row">
                                        <div className='col-12'>
                                            <div className='float-right '>
                                                <ReactHtmlTableToExcel
                                                    id="test-table-xls-button"
                                                    className="btn btn-azure btn-pill fs-12 mt-0 mb-2"
                                                    table="table-to-xls"
                                                    filename="Rapport_Journalier"
                                                    sheet="tablexls"
                                                    buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {loading ? <SqaureLoader /> : (
                                            <div className="table-responsive  product-datatable">
                                                {listeMagasins?.length === 0 ?
                                                    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                                        <div className="alert bg-purple mb-5 py-4" role="alert">
                                                            <div className="d-flex">
                                                                <i className="icon icon-info mr-3 fs-30"></i>
                                                                <div>
                                                                    <h4 className="alert-heading"> {t('rapports.titleInfo')} </h4>
                                                                    <p>  {t('rapports.textInfo')}  </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <table className="table  table-bordered " id="table-to-xls">
                                                            <thead className="text-center">
                                                                <tr className='bg-purple'>
                                                                    <td colSpan="9" className='fs-10'>
                                                                        {t('rapports.HeureTravail') + " "} {times !== "null" ? times : times.slice(0, 5)}{" - " + t('rapports.Heuremagasin') + " " + inmagasin}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="w-15p fs-10 font-weight-normal">{t('rapports.etape')}  </th>
                                                                    <th className="w-15p fs-10 font-weight-normal"> {t('rapports.MAGASIN')}</th>
                                                                    <th className="w-15p fs-10 font-weight-normal">{t('rapports.OBJET')}</th>
                                                                    <th className="w-15p fs-10 font-weight-normal">{t('rapports.TOTAL')}</th>
                                                                    <th className="w-15p fs-10 font-weight-normal">{t('rapports.TAUX')}</th>
                                                                    <th className="w-15p fs-10 font-weight-normal">{t('rapports.H.DEBUT')}</th>
                                                                    <th className="w-15p fs-10 font-weight-normal">{t('rapports.H.FIN')}</th>
                                                                    <th className="w-15p fs-10 font-weight-normal">{t('rapports.TEMPS')}</th>
                                                                    <th className="w-15p fs-10 font-weight-normal">{t('rapports.DISTANCE(M)')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='text-center font-weight-normal'>
                                                                {listeMagasins?.map((items, keys) => (
                                                                    <tr key={keys} className="fs-10 text-center">
                                                                        <td >{keys + 1}</td>
                                                                        <td><a className='text-primary' style={{ fontWeight: "bold" }} href={"/detailsMagasins/rapportJournalier&date=" + today + "&usercode=" + user_code + "&pdv_code=" + items.pdv_code}> {items.pdv}</a> </td>
                                                                        <td>{items.type === "repture" ? <a href={"/ruptureproduit/rapportJournalier&date=" + today + "&usercode=" + user_code + "&pdv_code=" + items.pdv_code} className='text-primary' style={{ fontWeight: "bold" }}> {"Rupture"} </a> : items.type} </td>
                                                                        <td>{items.type === "repture" ? <a href={"/ruptureproduit/rapportJournalier&date=" + today + "&usercode=" + user_code + "&pdv_code=" + items.pdv_code} className='text-primary' style={{ fontWeight: "bold" }}> {items.countrp} </a> : items.countrp} </td>
                                                                        <td> {items.type === "repture" ? parseFloat((items.countrp / items.sum_ass) * 100).toFixed(2) + ' %' : 0}</td>
                                                                        <td>{items.heure_debut} </td>
                                                                        <td>{items.heure_fin} </td>
                                                                        <td>{items.difference}</td>
                                                                        <td>{distance(parseFloat(items.latitude_pdv), parseFloat(items.longitude_pdv), parseFloat(items.latitude_pointage), parseFloat(items.longitude_pointage), "K").toFixed(3)} (KM) </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        {listeReleve?.length !== 0 ? (<table className="table  table-bordered ">
                                                            <tbody className='text-center'>
                                                                <tr>
                                                                    <td colSpan={9} className="bg-azure-lighter fs-10"> Relevé Prix </td>
                                                                </tr>
                                                                {listeReleve?.map((b, x) => (
                                                                    <tr key={x} className="fs-10">
                                                                        <td >{x + 1}</td>
                                                                        <td><a className='text-primary' style={{ fontWeight: "bold" }} href={"/detailsMagasins/rapportJournalier&date=" + today + "&usercode=" + user_code + "&pdv_code=" + b.pdv_code}> {b.nompdv}</a> </td>
                                                                        <td> {t("relevePrix.relevePrix")} </td>
                                                                        <td> {parseFloat(b.permanent).toFixed(2)} </td>
                                                                        <td> - </td>
                                                                        <td>{b.heure_debut} </td>
                                                                        <td>{b.heure_fin} </td>
                                                                        <td>{b.difference}</td>
                                                                        <td> - </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>) : null}

                                                    </>
                                                }

                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*------------- Tableau Pointage -----------------*/}
                    <div className="panel-group" id="accordion2" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                        <div className="panel panel-default active">
                            <div className="panel-heading " role="tab" id="headingOne">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion2" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                        <i className="icon icon-grid text-primary mr-2" /> {t('rapports.Pointage')}
                                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    {loading ? <SqaureLoader /> : (
                                        <div className="row">
                                            <div className="table-responsive  product-datatable"> {listePointages?.length === 0 ?

                                                <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                                    <div className="alert bg-purple mb-5 py-4" role="alert">
                                                        <div className="d-flex">
                                                            <i className="icon icon-info mr-3 fs-30"></i>
                                                            <div>
                                                                <h4 className="alert-heading">{t('rapports.titleInfo')} </h4>
                                                                <p> {t('rapports.textInfo')} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <table className="table  table-bordered ">
                                                    <thead className="text-center">
                                                        <tr className='bg-purple'>
                                                            <td colSpan="8" className='fs-10'>
                                                                {t('rapports.Pointage')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="w-15p fs-10" > {t('rapports.ordre')} </th>
                                                            <th className="wd-15p fs-10" >{t('rapports.MAGASIN')}</th>
                                                            <th className="wd-15p fs-10" >{t('rapports.COMMANDE')} </th>
                                                            <th className="wd-15p fs-10" >{t('dashboard.type')} </th>
                                                            <th className="wd-15p fs-10" >{t('rapports.HEUREDEBUT')}</th>
                                                            <th className="wd-15p fs-10" >{t('rapports.HEUREFIN')}</th>
                                                            <th className="wd-15p fs-10" >{t('rapports.TEMPS')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-center'>
                                                        {listePointages?.map((items, keys) => (
                                                            <tr key={keys} className="fs-10">
                                                                <td>{keys + 1}</td>
                                                                <td><a className='text-primary' style={{ fontWeight: "bold" }} href={"/detailsMagasins/rapportJournalier&date=" + today + "&usercode=" + user_code + "&pdv_code=" + items.pdv_code}> {items.nompdv}</a> </td>
                                                                <td>{items.commande === 1 ? "OUI" : "NON"} </td>
                                                                <td>{items.typeVisite !== null ? items.typeVisite : "-"} </td>
                                                                <td>{items.heure_debut} </td>
                                                                <td>{items.heure_fin} </td>
                                                                <td>{items.difference}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>}
                                            </div>
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/**----------------------- Localisation -------------------- */}
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="card">
                        <Localisationpdvs localisation={localisation} loading={loading} t={t} />
                    </div>
                </div>
            </div>
        </div>
    )
}
