/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getListeTournee } from '../../../services/Dashboard/FirstPage'

export default function TourneeMerch({ today, settoday, t ,user}) {
    const date = new Date(today).getDay()
    const [days, setdays] = useState(date)
    //--- TOURNEE LISTE ----
    const [listeTournee, setlisteTournee] = useState([])
    const [allTournee, setallTournee] = useState([])
    const [loading, setloading] = useState(true)
    const data =
    {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        0: "Dimanche"
    }

    const traitement = (today) => {
        setloading(true)
        const todayDays = data[date]
        setdays(date)

        getListeTournee(today, todayDays)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTournee(result?.data?.tournees)
                    setallTournee(result?.data?.allTournees)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)

            })
    }
    useEffect(() => {
        traitement(today)
    }, [today])


    const getTourneeByCode = (code) => {

        const liste = allTournee?.filter((element) => element.code === code)
        return liste;

    }
    const onChangeTournee = (e) => {
        settoday(e.target.value)
        var date= new Date(e.target.value)
        var n = date.getDay();
        setdays(n)
        traitement(e.target.value)
    }
    const searchTournee = () => {
        traitement(today)
    }

    return (
        <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
                <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title">
                        <a className=' text-center fs-14 text-primary font-weight-normal' role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div className="text-primary mt-0 mb-2  ml-auto">
                                <div className="bg-primary-transparent icon-bg icon-service text-primary fs-12">
                                    <i className='ti-truck mr-1'
                                        width={80}
                                        height={80} />{t('dashboard.suiviTournee')}
                                </div>
                            </div>
              
                        </a>
                    </h4>
                </div>
                <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                        {/** SEARCH ITEMS ------------ DATE BY DAY AND CALENDAR  */}
                        <div className="search1 search-elements-sm">
                            <div className="form row no-gutters">
                                <div className='form-group col-xl-4 ' />
                                <div className="form-group col-xl-2 col-lg-2  col-md-5 mb-0">
                                    <select name="days" value={days} onChange={(e) => setdays(e.target.value)} className="form-control  fs-12 text-primary custom-select fs-12 br-md-0">
                                        <option value={1}> {t('dashboard.lundi')}</option>
                                        <option value={2}> {t('dashboard.Mardi')}</option>
                                        <option value={3}> {t('dashboard.Mercredi')}</option>
                                        <option value={4}>{t('dashboard.Jeudi')}</option>
                                        <option value={5}>{t('dashboard.Vendredi')}</option>
                                        <option value={6}>{t('dashboard.Samedi')}</option>
                                        <option value={0}>{t('dashboard.Dimanche')}</option>
                                    </select>
                                </div>
                                <div className="form-group col-xl-2 col-lg-2  col-md-5 mb-0">
                                    <input type="date" value={today} onChange={(e) => onChangeTournee(e)} className="form-control text-primary fs-12 " />
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 mb-0">
                                    <button className="btn btn-primary" type="button" onClick={() => searchTournee()}><i className='ti-search' /></button>
                                </div>
                                <div className='form-group col-xl-3 '>
                                </div>
                            </div>
                        </div>
                        {/**  ------------------- Tableau -----------------------  */}
                        <div className="table-responsive  product-datatable" >
                            <table className="table table-responsive ">
                                <thead>
                                    <tr className='text-center text-muted'>
                                        <td className="w-5p fs-12 font-weight-bold">{t('dashboard.codetournee')}</td>
                                        <td className="w-5p fs-12 font-weight-bold"  > {t('dashboard.totTournee')} </td>
                                        <td className="w-5p fs-12 font-weight-bold">{user?.type==="promotrice" ? t('promotrice') :t('dashboard.Merchandiseur')} </td>
                                        <td className=" fs-12 font-weight-bold" colSpan={2}> {t('dashboard.Trournees')} </td>
                                    </tr>
                                </thead>
                                {loading ? <SqaureLoader /> : (
                                    <tbody >
                                        {listeTournee?.map((items, keys) => (
                                            <tr className='text-center' key={keys}>
                                                <td className="w-5p fs-12 font-weight-bold" > <a href={"tournee/code=" + items.code} className='text-primary'> {items.code}</a> </td>
                                                <td className="w-5p fs-12 font-weight-bold"> {items.pdv_planfie} </td>
                                                <td className="w-5p fs-12 font-weight-bold"> {items.nom} </td>
                                                <td className="w-5p fs-12  font-weight-bold"> <a href={"/detailsTourness/" + items.code}>
                                                <div className="bg-warning-transparent icon-bg icon-service text-yellow mr-2" style={{ height: "30px", width: "30px" }}>
                                                                <i className="ti-map-alt fs-14" />
                                                            </div>
                                                    {/*  <img src="../assets/images/brand/map.png" alt='mapimage' style={{ height: "30px" }} /> */}</a>  </td>
                                                <td className="w-20p fs-12 ">
                                                    <div className="align-content-center" style={{ display: "flex", alignItems: "baseline" }} >
                                                        {items.heure_debut === null ?
                                                            <div className="bg-danger-transparent icon-bg icon-service text-danger mr-2" style={{ height: "30px", width: "30px" }}>
                                                                <i className="fa fa-clock-o fs-14" />
                                                            </div>
                                                            : <a href={'/rapports/rapportSuiviPointage&datedebut=' + today + '&datefin=' + today + "&user_code=" + items.user_code} className=' text-primary mr-2' style={{ fontWeight: "bold" }}> {items.heure_debut}</a>}
                                                        {getTourneeByCode(items.code).map((items, k) => (
                                                            <>
                                                                {items.heure_debut === null ? <span className=" text-muted fs-12">
                                                                <div className="d-flex align-items-center mr-2" >
                                                                        <div className={items.type === "pointage" ? "w-3 h-3 bg-green  mr-2" : "w-3 h-3 bg-danger  mr-2"} data-toggle="tooltip" data-placement="top" title={"Magasin : "+items.nompdv} />
                                                                    </div>
                                                                </span>: <a href={"/detailsMagasins/rapportJournalier&date=" + today + "&usercode=" + items.user_code + "&pdv_code=" + items.pdv_code} className=" text-muted fs-12">
                                                                    <div className="d-flex align-items-center mr-2" >
                                                                        <div className={items.type === "pointage" ? "w-3 h-3 bg-green  mr-2" : "w-3 h-3 bg-danger  mr-2"}  data-toggle="tooltip" data-placement="top" title={"Magasin : "+items.nompdv}/>
                                                                    </div>
                                                                </a>}
                                                               
                                                            </>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
