import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Footer from "../shared/Footer";

export default function LoginLayout(props) {
    const [backgroundColor, setbackgroundColor] = useState({ backgroundColor: "#f3f6ff" })

    return (
        <div>
            
            <ToastContainer />
            <div className="page" style={backgroundColor}>
                    <React.Fragment>{props.children}</React.Fragment>   
                    <Footer />
            </div>
        </div>
    );
}
