/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import SqaureLoader from '../../components/Loader/SqaureLoader'

export default function TravauxMerch({ t, year, onChangeYear, nbrRuptureMois, partLineaire, nbrBisite, nbrRuptureMois2, partLineaire2, nbrBisite2, loadingTable }) {
    const liste = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    // FIND VISITE OF CURRENT YEAR
    const findOnVisite = (mois) => {
        const liste = nbrBisite?.find((el) => el?.mois === mois)
        return liste?.visite
    }
    const findOnVisitePrev = (mois) => {
        const liste = nbrBisite2?.find((el) => el?.mois === mois)
        return liste?.visite
    }
    // FIND Rupture OF CURRENT YEAR
    const findRupture = (mois) => {
        const liste = nbrRuptureMois?.find((el) => el?.mois === mois)
        return liste?.rupture
    }
    const findRupturePrev = (mois) => {
        const liste = nbrRuptureMois2?.find((el) => el?.mois === mois)
        return liste?.rupture
    }
    // FIND PART LINEAIRE 
    const findPartLineaire = (mois) => {
        const liste = partLineaire?.find((el) => el?.mois === mois)
        return liste?.pl
    }
    const findPartLineairePrev = (mois) => {
        const liste = partLineaire2?.find((el) => el?.mois === mois)
        return liste?.pl
    }
    return (
        <div className="tab-pane" id="tab-81">
            <div className='row mb-3'>
                <div className='col-4' />
                <div className='col-4'>  <select name="annee" className="form-control float-right select2 custom-select text-center" value={year} onChange={(e) => onChangeYear(e)}>
                    <option value>{t('conventions.annee')}</option>
                    <option value={2023}>2023</option>
                    <option value={2022}>2022</option>
                    <option value={2021}>2021</option>
                    <option value={2020}>2020</option>
                    <option value={2019}>2019</option>
                    <option value={2018}>2018</option>
                </select></div>
                <div className='col-4' />
            </div>

            <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '300px' }}>
                <table id="table-to-xls2" className="table  table-bordered ">
                    <thead className="text-center bg-azure-lighter" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >
                        <tr>
                            <th style={{ fontSize: "11px" }}></th>
                            <th className="wd-15p fs-10" colSpan={2}>{t('janvier')}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("fevrier")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("Mars")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("avril")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("mai")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("juin")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("juillet")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("aout")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("Septembre")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("Octobre")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("Novembre")}</th>
                            <th className="wd-15p fs-10" colSpan={2}>{t("decembre")}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>
                            <th className="wd-15p fs-10" >{year}</th>
                            <th className="wd-15p fs-10" >{parseInt(year) - 1}</th>


                        </tr>
                    </thead>
                    {loadingTable ? <SqaureLoader /> : (
                        <tbody>
                            <tr className='fs-10'>
                                <td className="text-center"> {t('historiqueMagasin.nombreVisite')} </td>
                                {liste?.map((items) => (
                                    <>
                                        <td>{findOnVisite(items) !== undefined ? <b className="text-primary">{findOnVisite(items)}</b> : 0}</td>
                                        <td>{findOnVisitePrev(items) !== undefined ? <b className="text-primary">{findOnVisitePrev(items)}</b> : 0}</td>
                                    </>
                                ))}
                            </tr>
                            <tr className='fs-10'>
                                <td className="text-center"> {t('historiqueMagasin.nombrerupture')} </td>
                                {liste?.map((items) => (
                                    <>
                                        <td>{findRupture(items) !== undefined ? <span className="text-primary">{findRupture(items)}</span> : "-"}</td>
                                        <td >{findRupturePrev(items) !== undefined ? <span className="text-primary">{findRupturePrev(items)}</span> : "-"}</td>
                                    </>
                                ))}

                            </tr>
                            <tr className='fs-10'>
                                <td className="text-center">  {t('dashboard.pl')} </td>
                                {liste?.map((items) => (
                                    <>
                                        <td>{findPartLineaire(items) !== undefined ? <span className="text-primary">{findPartLineaire(items)}</span> : "-"}</td>
                                        <td>{findPartLineairePrev(items) !== undefined ? <span className="text-primary">{findPartLineairePrev(items)}</span> : "-"}</td>

                                    </>
                                ))}
                            </tr>
                        </tbody>
                    )}

                </table>
            </div>
        </div>
    )
}
