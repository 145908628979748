/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast, ToastContainer } from 'react-toastify';
import { getGammesRuptures } from '../../../services/Dashboard/FirstPage';
import GrapheparGamme from './GrapheparGamme';
import GraphparEnseignes from './GraphparEnseignes';
import GraphparFamille from './GraphparFamille';
import GraphparMarque from './GraphparMarque';
import GraphparRegions from './GraphparRegions';
import GraphparSousFamilles from './GraphparSousFamilles';

export default function ListeGraphe() {
    
    //------ History -----------------
    const history = useHistory()
    //------ PARAM --------------------
    const { datedebut, datefin } = useParams('')
    //----------- liste ----------------------
    const [gammes, setgammes] = useState([])
    const [listeGammes, setlisteGammes] = useState([])
    const [familles, setfamilles] = useState([])
    const [enseignes, setenseignes] = useState([])
    const [listeFamilles, setlisteFamilles] = useState([])
    const [sousfamilles, setsousfamilles] = useState([])
    const [listeSF, setlisteSF] = useState([])
    const [marques, setmarques] = useState([])
    const [listeMarques, setlisteMarques] = useState([])
    const [regions, setregions] = useState([])
    const [listeRegions, setlisteRegions] = useState([])
    const [listeEnseignes, setlisteEnseignes] = useState([])
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //-------------- Loadig -------------------
    const [loading, setloading] = useState(true)
    const [date_d, setdate_d] = useState(moment(datedebut).format('YYYY-MM-DD'))
    const [date_f, setdate_f] = useState(moment(datefin).format('YYYY-MM-DD'))

    const onChangeDateFormat = () => {
      
        if(date_d>date_f){
            toast(' Erreur , la date début doit être inférieur a la date fin ', { type: "warning", position: "top-right" })
        }else{
            history.push({
                pathname:
                    '/rapportsGraphe/datedebut=' + date_d + '&datefin=' + date_f,
            })
            setloading(true)
            traitement()
        }
    }
    const traitement = () => {
        getGammesRuptures(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    //------------ ITEMS GAMMES ------
                    setgammes(result?.data?.gammes)
                    setlisteGammes(result?.data?.listeGammes)
                    //---------- Items familles ---------
                    setfamilles(result?.data?.familles)
                    setlisteFamilles(result?.data?.listefamilles)
                    //---------- Items Sfamilles ---------
                    setsousfamilles(result?.data?.sousfamilles)
                    setlisteSF(result?.data?.listeSousFamilles)
                    //---------- Items Marques ---------
                    setmarques(result?.data?.marques)
                    setlisteMarques(result?.data?.listemarques)
                    //---------- Items Régions ---------
                    setregions(result?.data?.regions)
                    setlisteRegions(result?.data?.listeRegions)
                    //---------- Items Enseignes ---------
                    setenseignes(result?.data?.enseignes)
                    setlisteEnseignes(result?.data?.listeEnseignes)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }

    useEffect(() => {
        // ------- Liste gAMMES
        traitement()
    }, [])

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item" aria-current="page"> {t('rapportGraph.rapportgraphes')} </li>
                </ol>
            </div>
            <div className='row'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <div className='row mb-2'>
                        <div className='col-3' />
                        <div className='col-6'>
                            <div className="input-group">
                                <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control text-primary fs-12" />
                                <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control text-primary fs-12" />
                                <span className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                                </span>
                            </div>
                        </div>
                        <div className='col-3' />
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className='row mt-2'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <GrapheparGamme t={t} listeGammes={listeGammes} gammes={gammes} loading={loading} date1={date_d} date2={date_f} />
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <GraphparFamille t={t} listeFamilles={listeFamilles} familles={familles} loading={loading} date1={date_d} date2={date_f} />
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <GraphparSousFamilles t={t} listeSousFamilles={listeSF} sousfamilles={sousfamilles} loading={loading} date1={date_d} date2={date_f} />
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <GraphparMarque t={t} listeMarques={listeMarques} marques={marques} loading={loading} date1={date_d} date2={date_f} />
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <GraphparRegions t={t} listeRegions={listeRegions} regions={regions} loading={loading} date1={date_d} date2={date_f}/>
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <GraphparEnseignes t={t} listeEnseignes={listeEnseignes} enseignes={enseignes} loading={loading} date1={date_d} date2={date_f}/>
                </div>
            </div>

        </div>
    )
}
