/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import { TileLayer, MapContainer, Popup } from "react-leaflet";
import Marker from "react-leaflet-enhanced-marker";
import "leaflet/dist/leaflet.css";
import "../../Magasins/Historique/map.css";
import SqaureLoader from '../../../components/Loader/SqaureLoader';

export default function Localisationpdvs({ localisation, loading ,t }) {

    const [center, setCenter] = useState({
        lat: isNaN(localisation[0]?.latitude) ? "36.8842439" : parseFloat(localisation[0]?.latitude),
        lng: isNaN(localisation[0]?.logitude) ? "10.1625628" : parseFloat(localisation[0]?.logitude),
    });
    const ZOOM_LEVEL = 8;
    const mapRef = useRef();

    return (
        <div className='card-body'>
           <i className="ion-location  fa-2x" style={{ color:  "#ff6600" }} /> {t('rapports.AdresseMagasins')} 
           <i className="ion-location  fa-2x" style={{ color: "#07A4E5" }} />{t('rapports.VisitesMagasins')} 
            <div className="left-container" style={{ position: "sticky" }}>
                {loading ? <SqaureLoader /> : (
                    <MapContainer center={center} zoom={ZOOM_LEVEL} map={mapRef} >
                        <TileLayer
                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
                        />
                        {localisation?.map((items, index) => (<>
                            <Marker position={[items?.latitude === "null" || undefined ? -1 : parseFloat(items?.latitude), items?.logitude === "null" || undefined ? -1 : parseFloat(items?.logitude)]} icon={<i className="ion-location  fa-4x" style={{ color:  "#ff6600" }} />} key={index} className="marker" >
                                <Popup>
                                    <b className='text-secondary text-center'>
                                    {t('rapports.AdresseMagasins')}
                                        <hr className='hr1' />
                                        {t('rapports.MAGASIN')+" : "+items?.nompdv} 
                                        <hr className='hr1' />
                                        {t('rapports.code')+" : "}    <a href={"/pdv/historique&code=" + items.pdv_code}> {items?.pdv_code} </a>

                                    </b>
                                </Popup>
                            </Marker>

                            <Marker position={[items?.lat === "null" || undefined ? -1 : parseFloat(items?.lat), items?.t_long === "null" || undefined ? -1 : parseFloat(items?.t_long)]} icon={<i className="ion-location  fa-4x" style={{ color: "#07A4E5" }} />} key={index} className="marker" >
                                <Popup>
                                    <b className='text-azure'>
                                         {t('rapports.VisitesMagasins')}
                                        <hr className='hr1' />
                                        {t('rapports.MAGASIN')+" : "+items?.nompdv} 
                                        <hr className='hr1' />
                                        {t('rapports.code')+" : "}  <a href={"/pdv/historique&code=" + items.pdv_code}> {items?.pdv_code} </a>

                                    </b>
                                </Popup>
                            </Marker>
                        </>

                        ))}
                    </MapContainer>

                )}

            </div>
        </div>
    )
}
