/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { ToastContainer } from 'react-toastify'
import { getTableauTournee } from '../../../services/Dashboard/FirstPage'
import _ from 'lodash'
import TableauTournee from './TableauTournee'
import { useTranslation } from 'react-i18next'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'


export default function RapportSuiviTournee() {

    //------ History -----------------
    const history = useHistory()
    //------ PARAM --------------------
    const { date } = useParams('')
    //------ References -----------------
    //const inputEl = useRef("")
    // Formulaire 
    const [today, settoday] = useState(moment(date).format('YYYY-MM-DD'))
    const [listeTournee, setlisteTournee] = useState([])
    const [countPdv, setcountPdv] = useState([]) //today
    const [countPdvMonth,setCountPdvMonth] = useState([]) //month
    const [countPdvYear,setCountPdvYear] = useState([]) // year
    const [countRupture, setcountRupture] = useState([]) //rupture
    const [countPL, setcountPL] = useState([]) //pl
    const [countReleve, setcountReleve] = useState([]) //releve prix
    const [countPromotions, setcountPromotions] = useState([]) //promotions
    const [inMagasins,setInMagasins] = useState([])
    const [outMagasins,setoutMagasins] = useState([])
    const [loading, setloading] = useState(false)
    
   //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    const datetime =
    {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        0: "Dimanche"
    }
    const traitement = () => {
        const days = new Date(today).getDay()
        const todayDays = datetime[days]
        getTableauTournee(today,todayDays)
            .then((result) => {
                if (result?.data?.success === 1) {
                    //COUNT DAILY PDV
                    const gr =_.groupBy(result.data.nbr_pdvJour,"user_code")
                    setcountPdv(gr)
                    const gr1 =_.groupBy(result.data.nbr_pdvPlanfieeMonth,"user_code")
                    setCountPdvMonth(gr1)
                    const gr2 =_.groupBy(result.data.nbr_pdvPlanfieeYear,"user_code")
                    setCountPdvYear(gr2)
                   //PL
                   const gr3 = _.groupBy(result.data.totalPl, "code")
                   setcountPL(gr3)
                   //Promotions
                   const gr4 = _.groupBy(result.data.total_promotions, "code")
                   setcountPromotions(gr4)
                   //Releve Prix
                   const gr5 = _.groupBy(result.data.total_releve, "code")
                   setcountReleve(gr5)
                   const gr6 = _.groupBy(result.data.inMagasins,"user_code")
                   setInMagasins(gr6)
                   const gr7 = _.groupBy(result.data.outPdv,"user_code")
                   setoutMagasins(gr7)
                    var merged = _.merge(_.keyBy(result?.data?.tableauJour, 'code'), _.keyBy(result?.data?.tableauMonth, 'code'), _.keyBy(result?.data?.tableauYear, 'code'));
                    var values = _.values(merged);
                    setlisteTournee(values)
                }
            }).catch((err) => {
                console.log(err)
            });
    }
    useEffect(() => {
        traitement()
    }, [])

    const onChangeDateFormat = () => {

        history.push({
            pathname:
                '/rapports/suiviTournee&date=' + today,
        })
        traitement()

    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.SuiviTournees')} </li>
                </ol>
            </div>
            {/** ------------- rapport : Suivi Tournee  ------------------------------ */}
            <div className="row">

                {/** --------------- Tableau Magasins ----------------------- */}
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <div className='row mb-2'>
                        <div className='col-3'></div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                            <div className="input-group">
                                <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary" />
                                <span className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                                </span>
                            </div>
                        </div>
                        <div className='col-3'></div>
                    </div>
                    <div className='row mb-2'>
                         <div className='col-12'>
                             <ReactHtmlTableToExcel
                                 id="test-table-xls-button"
                                 className="btn btn-azure btn-pill fs-12 mt-0 float-right "
                                 table="table-to-xls"
                                 filename="Rapport_Suivi_Trournee"
                                 sheet="tablexls"
                                 buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                             />
                        </div>
                    </div>
                    <ToastContainer />
                    <TableauTournee listeTournee={listeTournee} today={today}   countPdv={countPdv} countPdvMonth={countPdvMonth} countPdvYear={countPdvYear} countPromotions={countPromotions} countPL={countPL} countReleve={countReleve} countRupture={countRupture} inMagasins={inMagasins} outMagasins={outMagasins}  t={t} />
                </div>
            </div>
        </div>
    )
}
