/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { toast, ToastContainer } from 'react-toastify'
import { getRapportVente, getRapportVenteContact, getTop5, getUsersRapport } from '../../../services/Rapports/RapportsApi'
import { getSelectTypeVisite, getSelectZone } from '../../../services/Zoning/ZoningApi'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import CardTopVente from './CardTopVente'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import TableauVente from './TableauVente'
import { useTranslation } from 'react-i18next'

export default function RapportVente() {

    //--------- PARAMETERE -------------
    const { datedebut, datefin } = useParams('')
    //------ History -----------------
    const history = useHistory()
    //---------- FORMULAIRE --------------
    const [date_d, setdate_d] = useState(moment(datedebut).format("YYYY-MM-DD"))
    const [date_f, setdate_f] = useState(moment(datefin).format("YYYY-MM-DD"))
    const [listeUsers, setlisteUsers] = useState([])
    //--------------------- LISTE VENTES ----------------------------
    const [data, setdata] = useState({
        total_contact: 0,
        contact_sans_achat: 0,
        contact_avec_achat: 0
    })
    const [saveData, setsaveData] = useState({
        total_contact: 0,
        contact_sans_achat: 0,
        contact_avec_achat: 0
    });
    const [codeUser, setcodeUser] = useState("");
    const [listeZone, setListeZone] = useState([])
    const [saveListe2, setsaveListe2] = useState([]);
    const [codeZone, setcodeZone] = useState("");
    const [codeTypeV, setcodeTypeV] = useState("");
    const [listeTypeVisite, setlisteTypeVisite] = useState([]);
    const [listeNonVendus, setlisteNonVendus] = useState([])
    const [saveListe, setSaveListe] = useState([])
    const [codePdvs, setCodePdvs] = useState("")
    const [listepdvs, setlistepdvs] = useState([]);
    //-------------- LISTE TOP 5 ---------------
    const [flop5, setflop5] = useState([]);
    const [toprpoduits, settoprpoduits] = useState([]);
    //--------- Loader ------------------------
    const [loading, setloading] = useState(true)
  //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");

    const onChangeDateFormat = () => {
        setloading(true)
        if (date_d > date_f) {
            toast(t('Routings.dateinf'), { type: "warning", position: "top-right" })
        } else {
            history.push({
                pathname:
                    "/rapports/rapportvente&datedebut=" + date_d + "&datefin=" + date_f,
            })

            traitement()
        }
    }
    const traitement = () => {
        //--------- GET USER RAPPORT --------
        getUsersRapport(date_d, date_f)
        .then((result) => {
            if (result?.data?.success === 1) {
              
                setlisteUsers(result?.data?.listeUsers)
            }
        }).catch((err) => {
            console.log(err)
        })

        getTop5(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    var listeFlop = []
                    result?.data?.flop5.map((items)=>{
                        const found = result?.data?.top5produits.filter((el)=>el.produit_code===items.produit_code)
                        if(found?.length === 0){
                            listeFlop.push(items)
                        }
                    })
                    setflop5(listeFlop) 
                    settoprpoduits(result?.data?.top5produits)
                }
            }).catch((err) => {
                console.log(err)
            });
        getRapportVente(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setdata({
                        total_contact: result?.data?.total_contact.total_contact,
                        contact_sans_achat: result?.data?.contact_sans_achat?.contacts_sans_achat,
                        contact_avec_achat: result?.data?.contact_avec_achat.contact_avec_achat
                    })
                    setsaveData({
                        total_contact: result?.data?.total_contact.total_contact,
                        contact_sans_achat: result?.data?.contact_sans_achat?.contacts_sans_achat,
                        contact_avec_achat: result?.data?.contact_avec_achat.contact_avec_achat
                    })
                    setlistepdvs(result?.data?.pdvs)
                    setlisteNonVendus(result?.data?.prod_non_vendus)
                    setSaveListe(result?.data?.prod_non_vendus)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }

    useEffect(() => {
    
        // --- ZONE ---------  
        getSelectZone()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListeZone(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        //------- TYPE VISITE --------
        getSelectTypeVisite()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTypeVisite(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
   
        //--------- TRAITEMENT --------------
        traitement()
    }, [])

    // Filtre Zone
    const onChangeFilterZone = (e) => {
        setcodeZone(e.target.value)
        if (e.target.value === "" && codeUser === 'all') {
            setlisteNonVendus(saveListe)
            setdata(saveData)
        } else if (e.target.value === "" && codeUser !== "all") {
            setlisteNonVendus(saveListe2)
        }
        else {
            const liste = listeNonVendus?.filter((el) => el.zone_code === e.target.value)
            setlisteNonVendus(liste)
        }
    }
    //FILTRE PDVS
    const onChangeFilterPdvs = (e) => {
        setCodePdvs(e.target.value)
        if (e.target.value === "" && codeUser === 'all') {
            setlisteNonVendus(saveListe)
            setdata(saveData)
        } else if (e.target.value === "" && codeUser !== "all") {
            setlisteNonVendus(saveListe2)
        }
        else {
            const liste = listeNonVendus?.filter((el) => el.pdv_code === e.target.value)
            setlisteNonVendus(liste)
        }
    }
    // Filtre type Vente
    const onChangeTypeVente = (e) => {
        setcodeTypeV(e.target.value)
        if (e.target.value === "" && codeUser === 'all') {
            setlisteNonVendus(saveListe)
            setdata(saveData)
        } else if (e.target.value === "" && codeUser !== "all") {
            setlisteNonVendus(saveListe2)
        }
        else {
            const liste = listeNonVendus?.filter((el) => el.type_visite === e.target.value)
            setlisteNonVendus(liste)
        }
    }
    const onClickUser = (e) => {
        setcodeUser(e)
        if (e !== "all") {
            setloading(true)
            const liste = saveListe?.filter((element) => element.user_code === e)
            setlisteNonVendus(liste)
            setsaveListe2(liste)
            getRapportVenteContact(date_d, date_f, e)
                .then((result) => {
                    if (result?.data?.success === 1) {
                       
                        setdata({
                            total_contact: result?.data?.total_contact.total_contact,
                            contact_sans_achat: result?.data?.contact_sans_achat?.contacts_sans_achat,
                            contact_avec_achat: result?.data?.contact_avec_achat.contact_avec_achat
                        })
                        setsaveData({
                            total_contact: result?.data?.total_contact.total_contact,
                            contact_sans_achat: result?.data?.contact_sans_achat?.contacts_sans_achat,
                            contact_avec_achat: result?.data?.contact_avec_achat.contact_avec_achat
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setloading(false)
                })
        } else {
            setlisteNonVendus(saveListe)
            setdata(saveData)
        }

    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapportVentes.rapportVente')} </li>
                </ol>
            </div>
            {/** ------------- rapport : Rupture 1 ------------------------------ */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" />{t('rapportVentes.listeVentes')}

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className="form row no-gutters">
                                <div className="form-group  col-xl-2 col-lg-2 col-md-12 mb-0">
                                    <input type="date" className='form-control' value={date_d} onChange={(e) => setdate_d(e.target.value)} />
                                </div>

                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 col-6 mb-0 ">
                                    <input type="date" className='form-control' value={date_f} onChange={(e) => setdate_f(e.target.value)} />
                                </div>

                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 col-6 mb-0">
                                    <select className="form-control  br-md-0  fs-12 text-primary" value={codeTypeV} onChange={(e) => onChangeTypeVente(e)}>
                                        <option value={""} >{t('rapportVentes.typevisite')}</option>
                                        {listeTypeVisite?.map((items, keys) => (
                                            <option key={keys} value={items.name}>{items.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 col-6 mb-0">
                                    <select className="form-control  br-md-0  fs-12 text-primary" value={codeZone} onChange={(e) => onChangeFilterZone(e)} >
                                        <option value={""} >{t('rapports.Zones')}</option>
                                        {listeZone?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}

                                    </select>
                                </div>
                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-6 mb-0">
                                    <select className="form-control  br-md-0  fs-12 text-primary" value={codePdvs} onChange={(e) => onChangeFilterPdvs(e)}>
                                        <option value={""} >{t('rapports.Magasins')}</option>
                                        {listepdvs?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}

                                    </select>
                                </div>
                                <span className="col-auto">
                                    <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className="fe fe-search"></i></button>
                                </span>
                                <span className="col-auto">
                                    <button className="btn bg-yellow" type="button" onClick={() => onClickUser("all")}><i className="ti-close"></i></button>
                                </span>

                            </div>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-3'>
                                <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                    <button className="btn tag bg-azure text-white" onClick={() => onClickUser("all")}> {t('rapports.ALL')} </button>
                                    {listeUsers?.map((items, keys) => (
                                        <button className="btn tag bg-purple" key={keys} onClick={() => onClickUser(items.user_code)}> {items.nom} : ({items.total})</button>
                                    ))}
                                </div>
                            </div>
                            <span className='text-azure mt-3' > <i className='icon icon-info mr-1' /> {t('rapportVentes.information')}</span>

                        </div>
                    </div>
                </div>
            </div>

            <div className="panel-group" id="acc2" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="head5">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#acc2" href="#collapse5" aria-expanded="true" aria-controls="collapse5">
                                <i className="icon icon-grid text-primary mr-2" />{t('rapportVentes.listedesproduitsvendus')}

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapse5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="head5">
                        <div className="panel-body">
                            <div className='row'>
                                <div className='col-12'>

                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-success text-center float-right "
                                        table="table-to-xls"
                                        filename="Rapport_vente"
                                        sheet="tablexls"
                                        buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                                    />
                                </div>
                            </div>
                            {loading ? <SqaureLoader /> : (
                                <TableauVente listeNonVendus={listeNonVendus} data={data} date_d={date_d} date_f={date_f} t={t}/>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <CardTopVente flop5={flop5}  toprpoduits={toprpoduits} t={t} />
        </div>

    )
}
