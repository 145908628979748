import React, { useEffect, useRef, useState } from 'react'
import SqaureLoader from '../../../../components/Loader/SqaureLoader'
import { getAffectationsUsers } from '../../../../services/Affectations/AffectationAPI'
import { SearchItems } from '../../../../utils/SearchUtils'
import AddAffectationUser from './AddAffectationUser'

export default function TableauUser({t}) {
    //-------------- LOADER ------------------
    const [Loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')
    const inputEl = useRef("")
    //------- FORMULAIRE ---------
    const [liste, setListe] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    const currentListe = search.length < 1 ? liste : searchList
    const [show, setShow] = useState(true)

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(liste, search)
            setSearchlist(newList)
        } else {
            setListe(saveList)
        }
    }
    useEffect(() => {
        getAffectationsUsers().then((result) => {
            if (result?.data?.success === 1) {
                setListe(result?.data?.data)
                setSaveList(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })

    }, [show])
    return (
        <div className="row">
            <div className="col-md-12 col-lg-12">

                <div className="card">
                    <div className="card-header">
                        <div className="card-title mt-3 float-left">Affectations Merch aux Chefs </div>
                        <button className="btn btn-azure float-right" data-toggle="modal" data-target="#addUser"> <i className="icon icon-plus" /></button>

                    </div>
                    <div className="card-body">
                        <div className="search1 search-elements-sm mb-4 ">
                            <div className="form row no-gutters">
                                <div className="col-6"></div>
                                <div className="form-group  col-xl-5 col-lg-5 col-md-5 mb-0">
                                    <input type="text" ref={inputEl} value={search}
                                        onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary fs-10" id="text1" placeholder="Recherche .." />
                                </div>

                                <div className="col-xl-1 col-lg-1 col-md-1 mb-0">
                                    <a href="#" className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"><i className="icon icon-magnifier" /></a>
                                </div>
                            </div>
                        </div>
                        {Loading ? (
                            <SqaureLoader />
                        ) : (
                            <div className="table-responsive product-datatable">
                                <table
                                    id="example"
                                    className="table table-striped table-bordered "
                                >
                                    <thead className="text-center">
                                        <tr>
                                            <th className="w-15p fs-12">{t('rapports.code')}</th>
                                            <th className="wd-15p fs-12">{t('chef')}</th>
                                            <th className="wd-15p fs-12">{t('dashboard.Merchandiseur')}</th>
{/*                                             <th className="wd-15p fs-12">{t('Magasins.ACTION')}</th>
 */}                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {currentListe?.map((items, keys) => (
                                            <tr key={keys}>
                                                <td className='fs-12'>{items.code} </td>
                                                <td className='fs-12' >{items.chef} </td>
                                                <td className='fs-12' >{items.merch} </td>
{/*                                                 <td className='fs-12' > <i className="ti-trash text-danger" /> </td>
 */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        )}

                    </div>
                </div>
            </div>           
             <AddAffectationUser t={t}  setShow={setShow}/>

        </div>
    )
}
