/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getHistoriqueRupture2 } from '../../../services/Rapports/RapportsApi'

export default function ModalHistoriqueProduit({ pdv_code, produit_code, setOk, t }) {
    const [liste, setliste] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {
        getHistoriqueRupture2(pdv_code, produit_code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.histo)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [])

    return (
        <div className="modal fade" id="exampleModal" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog" aria-hidden="true" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fs-14" id="example-Modal3"> <i className='icon icon-folder-alt mr-2 text-azure'/>{t('rapports.historiquerupture')} :   </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" /> </span>

                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-7 float-left'>
                                <h6 className='fs-10'> <b className='text-muted'>{t('dashboard.Enseigne')}</b> : {liste[0]?.enseigne} </h6>
                                <h6 className='fs-14 text-azure'> {liste[0]?.produit} </h6>
                            </div>
                            <div className='col-5 float-right'>
                                <h6 className='fs-10'> <b className='text-muted'>{t('rapportrupture.codeproduit')}</b> : {liste[0]?.produit_code} </h6>
                                <h6 className='fs-10'> <b className='text-muted'>{t('Produits.CODEABARRE')}</b> : {liste[0]?.code_a_barre} </h6>

                                <h6 className='fs-10'> <b className='text-muted'>{t('rapportrupture.codeMeti')} </b>: {liste[0]?.code_meti !== null ? liste[0]?.code_meti : "-"} </h6>
                            </div>
                            <table className="table  table-bordered mt-2">
                                <thead className="text-center">
                                    <tr>
                                        <th className="w-15p fs-10"> {t('rapportrupture.date')} </th>
                                        <th className="w-15p fs-10"> {t('rapportrupture.merch')}</th>
                                        <th className="w-15p fs-10">{t('rapports.QUANTITE')} </th>
                                        <th className="w-15p fs-10">{t('rapports.prix')} </th>
                                        <th className="w-15p fs-10">{t('relevePrix.cause')} </th>
                                        <th className="w-15p fs-10">{t('rapports.Etat')} </th>
                                    </tr>
                                </thead>
                                {loading ? <SqaureLoader /> : (
                                    <tbody>
                                        {liste?.map((items, keys) => (
                                            <tr key={keys}  className={
                                                keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center " : "fs-10 text-center"
                                            }>
                                                <td>{moment(items.date).format('DD/MM/YYYY')}</td>
                                                <td>{items.user}</td>
                                                <td>{items.qte === null ? "-" : items.qte}</td>
                                                <td>{items.prix === null ? "-" : items.prix}</td>
                                                <td>{items.cause  === null ? "-" : items.cause}</td>
                                                <td className='text-justify'> <i className={items.etat_repture === "1" ? "fa fa-circle text-danger mr-1": " fa fa-circle text-green mr-1"} /> {items.etat_repture === "1" ? t('dashboard.rupture'): t('rapportrupture.disponible')} </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                )}

                            </table>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button className='btn btn-yellow fs-12' type='button' data-dismiss="modal" aria-label="Close" onClick={() => setOk(false)} > <i className='ti-close mr-1' /> {t('Zoning.Fermer')} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
