import React from 'react'

export default function ModalDetailsNote({ object ,t }) {
    return (
        <div className="modal fade" id="exampleModal3" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3"> {t('Note.detailsNotes')}  </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>  <div className="modal-body">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="item-card9-imgs">
                                        <img src={object?.image !== null ? object?.image : "../assets/images/users/23.png"} alt="img" className="cover-image" style={{ width: "400px" }} />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="item-card9">
                                        <h6><b className='text-muted'> {t("dashboard.Magasin")}</b>  : {object.nompdv}</h6>
                                        <h6> <b className='text-muted'> {t('Catalogues.Commercial')}  </b> : {object.nom}</h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                 

               </div>
            </div>

        </div>
    )
}
