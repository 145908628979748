import React from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search'

export default function FiltreStock({Saveenseignes , datedebut, setDatedebut , t ,datefin, setDateFin , saveData ,codeEnseigne,searchInput,handleChangeEnseigne }) {
  return (
    <div>  <div className="row mb-3">
    <div className="col-2" />
    <div className="col-8">
      <div className="input-group">
        <input
          type="date"
          value={datedebut}
          onChange={(e) => setDatedebut(e.target.value)}
          className="form-control br-tr-md-0 br-br-md-0  fs-10 text-primary"
          placeholder={t("rapportrupture.rechercher")}
        />
        <input
          type="date"
          value={datefin}
          onChange={(e) => setDateFin(e.target.value)}
          className="form-control br-tr-md-0 br-br-md-0  fs-10 text-primary"
          placeholder={t("rapportrupture.rechercher")}
        />
        <button className="btn btn-azure" onClick={() => saveData()}>
          <i className="ti-search" />
        </button>
      </div>
    </div>
    <div className="col-2" />
  </div>
  {Saveenseignes?.length !== 0 && (
    <div className="col-12">
      <div className="col-8 mt-4 mb-2">
        <div className="input-group">
          <label className="mt-2 mr-2"> Filtre : </label>
          <SelectSearch
            value={codeEnseigne}
            options={Saveenseignes}
            search
            ref={searchInput}
            onChange={handleChangeEnseigne}
            filterOptions={fuzzySearch}
            emptyMessage={() => (
              <div style={{ textAlign: "center", fontSize: "0.8em" }}>
                {t("Zoning.Aucunresultat")}
              </div>
            )}
            placeholder="Enseigne"
          />
        </div>
      </div>
      <div className="col-2" />
    </div>
  )}
</div>
  )
}
