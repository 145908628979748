/* eslint-disable array-callback-return */
import _ from "lodash"

/**
   * CALCUL NOTE DE PONCTUALITE
   * @param {*} days
   * @returns
   */
export const sommePonctualite = (arra_days, jrFerie , jrRepos, array_merch) =>{
    var nbr_jours = parseInt(arra_days?.length)-(parseFloat(jrFerie)+parseFloat(jrRepos))
    /**
     * REPECT HEURE DEBUT PAR JOUR 
     */
    var L1 = array_merch?.respect_horaire_travail?.reduce(function (
      accumulateur,
      valeurCourante
    ) {return accumulateur + (valeurCourante.valeur_respect_horaire)}, 0)
    var L2 = array_merch?.respect_horaire_debut?.reduce(function (
      accumulateur,
      valeurCourante
    ) {return accumulateur + (valeurCourante.valeur_respect_horaire)}, 0)
/*     var L3 = array_merch?.respect_horaire_fin?.reduce(function (
      accumulateur,
      valeurCourante
    ) {return accumulateur + (valeurCourante.valeur_respect_horaire)}, 0) */
    return(parseFloat((L1 + L2 )/nbr_jours).toFixed(2));
  }


  /**
   * Moyenne de reporting
   */
 export  const sommeReporting = (arra_days,jrFerie, jrRepos,array_merch ) =>{
    var nbr_jours = parseInt(arra_days?.length)-(parseFloat(jrFerie)+parseFloat(jrRepos))
    var L1 = array_merch?.respect_rupture?.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      return accumulateur + (valeurCourante.respect_etat_rupture);
    },
    0)
    return parseFloat(L1/nbr_jours).toFixed(2)
  }
  export const somme_array = (array )=>{
    var L2 = array?.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      return accumulateur + (valeurCourante.respect_rang_tournee) ;
    },
    0)
    return L2
  }
/**
 * 
 * @param {*} arra_days 
 * @param {*} jrFerie 
 * @param {*} jrRepos 
 * @param {*} array_merch 
 * @returns 
 */
  export  const sommeTournee = (arra_days,jrFerie, jrRepos,array_merch ) =>{
    var nbr_jours = parseInt(arra_days?.length)-(parseFloat(jrFerie)+parseFloat(jrRepos))
    var L1 = array_merch?.respect_tournee?.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      return accumulateur + (valeurCourante.respect_tournee) 
    },
    0)
    var respect_rang = _.groupBy(array_merch?.respect_rang,"date")
    var sum_rang = 0;
    Object.keys(respect_rang)?.map((items)=>
    {  
      if(somme_array(respect_rang[items])===respect_rang[items]?.length){
        sum_rang ++
      }
    })
  
    
    return parseFloat((L1+sum_rang)/nbr_jours).toFixed(2)
  }

/**
 * SOMME INITIATIVE
 * @param {*} arra_days 
 * @param {*} jrFerie 
 * @param {*} jrRepos 
 * @param {*} array_merch 
 * @returns 
 */
  export  const sommeInitiative = (arra_days,jrFerie, jrRepos,array_merch ) =>{
    var nbr_jours = parseInt(arra_days?.length)-(parseFloat(jrFerie)+parseFloat(jrRepos))
    var L2 = array_merch?.respect_note?.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      return accumulateur + (valeurCourante.respect_note) ;
    },
    0)

    return parseFloat(( L2)/nbr_jours).toFixed(2)
  }
  /**
   * GET PONCTUALTIE
   */
export const poctualite = (days , respect_heure_debut_par_jour , respect_heure_fin_par_jour , respect_horaire_travail) => {
    var note =
      (respect_heure_debut_par_jour[days] !== undefined
        ? respect_heure_debut_par_jour[days][0]?.valeur_respect_horaire
        : 0) +
      (respect_heure_fin_par_jour[days] !== undefined
        ? respect_heure_fin_par_jour[days][0]?.valeur_respect_horaire
        : 0) +
      (respect_horaire_travail[days] !== undefined
        ? respect_horaire_travail[days][0]?.valeur_respect_horaire
        : 0);
    return note;
  };
  export const poctualite2 = (days , respect_horaire_travail ,respect_horaire_debut) => {
    var note =(respect_horaire_travail[days] !== undefined ? respect_horaire_travail[days][0]?.valeur_respect_horaire : 0)+
    /* (respect_horaire_fin[days] !== undefined ? respect_horaire_fin[days][0]?.valeur_respect_horaire : 0)+ */
    (respect_horaire_debut[days] !== undefined ? respect_horaire_debut[days][0]?.valeur_respect_horaire : 0) 
    return note;
  };

  /**
   * Calcul note tournee
   * @param {*} days
   */
export const tournee = (days , respect_tournee) => {
    var note =
      respect_tournee[days] !== undefined
        ? respect_tournee[days][0]?.respect_tournee +
          ((respect_tournee[days][0]?.pdv_visite /
            respect_tournee[days][0]?.pdv_planifie) *
            100 >=
          70
            ? 1
            : 0)
        : 0;
    return note;
  };

  export const tournee2 = (days , respect_tournee) => {
    var note =
      respect_tournee[days] !== undefined
        ? respect_tournee[days][0]?.respect_tournee
        : 0;
    return note;
  };
  /**
   * CALCUL INTIATIVE
   * @param {*} days
   */
export const initiative = (days,respect_note) => {
    var note =
   /*    (respect_rayons[days] !== undefined
        ? respect_rayons[days][0]?.respect_rayons
        : 0) + */
      (respect_note[days] !== undefined
        ? respect_note[days][0]?.respect_note
        : 0);
    return note;
  };
  /**
   * CALCUL RESPECT RUPTURE PAR JOUR
   * @param {*} days
   */
 export const rupture = (days , respect_rupture) => {
    var note =
      respect_rupture[days] !== undefined
        ? respect_rupture[days][0]?.respect_etat_rupture
        : 0;
    return note;
  };

  export const verif_rang = (days , respect_rang) => {
    var L1 = respect_rang[days]?.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      return accumulateur + (valeurCourante.respect_rang_tournee) ;
    },
    0)
    if(respect_rang[days] !== undefined && L1 === respect_rang[days]?.length){
      return 1
    }else
    return 0 ;
  };