/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast, ToastContainer } from 'react-toastify'
import {  addFileConvention_1, getConventionByCode, getSelectCategorie, typeConvention, updateConventions } from '../../../services/Magasins/PdvApi'
import { getListeSousFamilles, getSelecteMarque, getSelectFamilles, getSelectGamme } from '../../../services/Produits/configurationProduits/ConfigurationAPI'
import { getSelctEnseignes, getSelectProduits } from '../../../services/Produits/ProduitsAPI'
import convention from './modele_convention_1.xlsx'

export default function EditConventions() {
    //--------- CODE -----------
    const { code } = useParams('')
    const produits = useRef();
    const categorie = useRef();
    //--------- LIBELLE ---------------------
    const [libelle, setlibelle] = useState("")
    //----------- TYPES ----------------
    const [listeTypes, setlisteTypes] = useState([])
    const [codeType, setcodeType] = useState(-1)
    //-------DATE DEBUT / DATE  FIN 
    const [datedebut, setdatedebut] = useState("")
    const [datefin, setdatefin] = useState("")
    //---------- ENSEIGNES ------------
    const [listeEnseignes, setlisteEnseignes] = useState([])
    const [codeEnseigne, setcodeEnseigne] = useState("")
    //----------- categorie ----------
    const [listeCategorie, setlisteCategorie] = useState([])
    const [codeCategorie, setcodeCategorie] = useState("")
    const [Categorie, setCategorie] = useState("")
    //Produits -----------------
    const [listeProduits, setListeProduits] = useState([])
    const [codeproduit, setcodeproduit] = useState("")
    const [Produits, setProduits] = useState("")
    //---------- GAMMES ------------
    const [listeGamme, setlisteGamme] = useState([])
    const [codeGamme, setcodeGamme] = useState("")
    //---------- MARQUES ------------
    const [listeMarque, setlisteMarque] = useState([])
    const [codeMarque, setcodeMarque] = useState("")
    //---------- Familles ------------
    const [listeFamille, setlisteFamille] = useState([])
    const [codeFamille, setcodeFamille] = useState(-1)
    //----------Sous Familles ------------
    const [listeSFamille, setlisteSFamille] = useState([])
    const [codeSfamille, setcodeSfamille] = useState(-1)
    /*** PL Champs */
    const [plContrat, setplContrat] = useState("")
    const [implentation, setimplentation] = useState("")
    // keeped state:
    const [etat, setetat] = useState();
    const [etat2, setetat2] = useState()
    //---------- FICHIER ------------ 
    const [filename, setFileName] = useState("Choisir un fichier ")
    const [file, setfile] = useState("");
       //------------- Traduction ---------------
       const [t, i18n] = useTranslation("global");

    // change handling:
    const handleChange = e => {
        const target = e.target;
        if (target.checked) {
            setetat(target.value);
        }
    };
    // change handling:
    const handleChangeEtat = e => {
        const target = e.target;
        if (target.checked) {
            setetat2(target.value);
        }
    };
    useEffect(() => {
        getConventionByCode(code).then((result) => {
            if(result?.data?.success === 1){
                var details = result?.data?.data[0]
                setlibelle(details?.libelle)
                setcodeType(details?.type)
                setcodeEnseigne(details?.enseigne_code)
                setdatedebut(moment(details?.date_debut).format("YYYY-MM-DD"))
                setdatefin(moment(details?.date_fin).format("YYYY-MM-DD"))
                setetat("ajouter")

                if(details?.type==="pl"){
                    setplContrat(details?.plcontrat)
                    setimplentation(details?.implentation)
                }
                if(details?.produit_code!== null){
                    setcodeproduit(details?.produit_code)
                    setProduits(details?.produit)
                }
                if(details?.categorie_code !== null){
                    setcodeCategorie(details?.categorie_code)
                    setCategorie(details?.categorie)
                }
                if(details?.famille_code !== null){
                    setcodeFamille(details?.famille_code)
                    setetat2("famille")
                }
                if(details?.sousfamille_code !== null){
                    setcodeSfamille(details?.sousfamille_code)
                    setetat2("sousfamille")
                }
                if(details?.gamme_code !== null){
                    setcodeGamme(details?.gamme_code)
                    setetat2("gamme")
                }
                
                if(details?.marque_code !== null){
                    setcodeMarque(details?.marque_code)
                    setetat2("marque")
                }
         
            }
        }).catch((err) => {
            console.log(err)

        });
        typeConvention().then((result) => {
            if (result?.data?.success === 1) {
                setlisteTypes(result?.data?.type)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelctEnseignes().then((result) => {
            if (result?.data?.success === 1) {
                setlisteEnseignes(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectProduits().then((result) => {
            if (result?.data?.success === 1) {
                setListeProduits(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        getSelectGamme().then((result) => {
            if (result?.data?.success === 1) {
                setlisteGamme(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectFamilles().then((result) => {
            if (result?.data?.success === 1) {
                setlisteFamille(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getListeSousFamilles().then((result) => {
            if (result?.data?.success === 1) {
                setlisteSFamille(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelecteMarque().then((result) => {
            if (result?.data?.success === 1) {
                setlisteMarque(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectCategorie()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteCategorie(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)

            });
    }, [])
    // Handle Change Famille
    const handleProduits = (...args) => {
        setcodeproduit(args[1].value)
    };
    // Handle Change Famille
    const handleCategorie = (...args) => {
        setcodeCategorie(args[1].value)
    };
    //UPLOAD IMAGE WITH PREVIEW image Commerce
    const selectFile = (event) => {
        setFileName(event?.target?.files[0]?.name)
        if (event?.target?.files?.length) {
            setfile(event?.target?.files[0])
        }
    }

    const saveData = () => {
        if (datedebut > datefin) {
            toast(t('messages.dateinf'), { type: 'error', position: 'top-right' })
        } else if (datedebut === "" || datefin === "" || libelle === "" || codeEnseigne === "" || codeCategorie === "" ) {
            toast(t('conventions.messageformulaire'), { type: 'error', position: 'top-right' })
        }  else {
            const data = {
                libelle: libelle,
                date_debut: datedebut,
                date_fin: datefin,
                type: codeType,
                enseigne_code: codeEnseigne,
                categorie_code: codeCategorie,

            }
            Object.assign(data, codeType === 'pl' ? { plcontrat: plContrat, implentation: implentation } : {});
            Object.assign(data, codeproduit !== "" ? { produit_code: codeproduit } : {})
            Object.assign(data, codeGamme !== "" ? { gamme_code: codeGamme } : {})
            Object.assign(data, codeMarque !== "" ? { marque_code: codeMarque } : {})
            Object.assign(data, codeFamille !== -1 ? { famille_code: codeproduit } : {})
            Object.assign(data, codeSfamille !== -1 ? { sousfamille_code: codeSfamille } : {})

            updateConventions(code,data).then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('messageErreur.modifsuccee'), { type: "success", position: 'top-right' })
                }
            }).catch((err) => {
                console.log(err)
            });
        }
    }
    const uploadFile = () => {
        if (datedebut > datefin) {
            toast(t('messages.dateinf'), { type: 'error', position: 'top-right' })
        } else if (datedebut === "" || datefin === "" || libelle === "" || codeEnseigne === "" || codeCategorie === "" ) {
            toast(t('conventions.messageformulaire'), { type: 'error', position: 'top-right' })
        }else {
        const data = {
            libelle: libelle,
            date_debut: datedebut,
            date_fin: datefin,
            enseigne_code : codeEnseigne,
            type: codeType}

            Object.assign(data, codeType === 'pl' ? { plcontrat: plContrat, implentation: implentation } : {});
        // FILE FUNCTIONS
        addFileConvention_1(data,file)
            .then((result) => {
                console.log(result)
                if (result?.data?.success === 1) {
                    toast(t('messageErreur.ajoutavecsuccee'), { type: "success", position: 'top-right' })
                } else {
                    toast(t('conventions.problemedajout'), { type: "error", position: 'top-right' })

                }
            }).catch((err) => {
                console.log(err)

            })
        }
    }
    return (
        <div >
        <div className="page-header">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('conventions.convention')} </a></li>
                <li className="breadcrumb-item active" aria-current="page">{t('conventions.modifierconvention')} : {code} </li>
            </ol>
            <div className="mt-3 mt-lg-0">
                <div className="d-flex align-items-center flex-wrap text-nowrap">
                    <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                        <i className="icon icon-settings mr-1" />
                        {t('Magasins.Parametragemagasins')}
                    </a>
                    <a href="/pdv/ajouterPDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                        <i className="icon icon-plus mr-1" />
                         {t('Magasins.AjouterPDV')}
                    </a>
       
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header">
                <h5 className="card-title mt-3">  {t('conventions.modifierconvention')}</h5>
            </div>
            <ToastContainer />
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">

                                <div className="form-group">
                                    <label className="form-label">{t('dashboard.Libelle')} <span className="text-danger">*</span></label>
                                    <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control" name="example-text-input" placeholder="Name" />
                                </div>
                            </div>
                            <div className="col-md-4">

                                <div className="form-group">
                                    <label className="form-label">{t('Magasins.Type')}</label>
                                    <select name="country" value={codeType} onChange={(e) => setcodeType(e.target.value)} className="form-control  custom-select">
                                        <option>---- {t('Magasins.Type')} -----</option>
                                        {listeTypes?.map((items, keys) => (
                                            <option key={keys} value={items.type}>{items.type}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label">{t('rapports.Enseignes')}</label>
                                    <select name="country" value={codeEnseigne} onChange={(e) => setcodeEnseigne(e.target.value)} className="form-control  custom-select">
                                        <option>---- Enseignes -----</option>
                                        {listeEnseignes?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {codeType === "pl" ?
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label className="form-label">{t('conventions.plcontractuelle')} </label>
                                        <input type="text" value={plContrat} onChange={(e) => setplContrat(e.target.value)} className="form-control" name="example-disabled-input" placeholder="plContrat" />
                                    </div>
                                    <div className="form-group col-6">
                                        <label className="form-label">{t('conventions.implentation')}</label>
                                        <input type="text" value={implentation} onChange={(e) => setimplentation(e.target.value)} className="form-control" name="example-disabled-input" placeholder="Implementation" />
                                    </div>
                                </div> : null}
                                <div className="row">
                                <div className="form-group col-6">
                                    <label className="form-label">{t('Routings.datedebut')}  </label>
                                    <input type="date" value={datedebut} onChange={(e) => setdatedebut(e.target.value)} className="form-control" name="example-disabled-input" placeholder="Date Début" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="form-label">{t('Routings.datefin')}  </label>
                                    <input type="date" value={datefin} onChange={(e) => setdatefin(e.target.value)} className="form-control" name="example-disabled-input" placeholder="Date Début" />
                                </div>
                            </div>
                            <div className="form-group text-center">
                                <label className="form-label">{t('conventions.choisiroption')}</label>
                                <div>
                                    <label className="mr-2">
                                        <input type="radio" value="ajouter" className=" mr-2" checked={etat === 'ajouter'}
                                            onChange={handleChange} />
                                        <span>{t('conventions.ajouterConvention')}</span>
                                    </label>
                                    <label className="mr-2">
                                        <input className="mr-2" type="radio" value="importer" checked={etat === 'importer'}
                                            onChange={handleChange} />
                                        <span>{t('conventions.importerConvention')}</span>
                                    </label>
                                </div>
                            </div>
                    </div>
                    {etat === 'ajouter' ? (
                            <>
                                <div className="col-md-6 ">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapportrupture.produit')}  <span className="text-danger">*</span></label>
                                        <SelectSearch
                                            options={listeProduits}
                                            search
                                            ref={produits}
                                            onChange={handleProduits}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Produits.choisirProduit')}
                                        /> </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapportrupture.categorie')}  <span className="text-danger">*</span></label>
                                        <SelectSearch
                                            options={listeCategorie}
                                            search
                                            ref={categorie}
                                            onChange={handleCategorie}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Magasins.ChoisirCategorie')}
                                        /> </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group text-center">
                                        <div>
                                            <label className="mr-2">
                                                <input type="radio" value="gamme" className=" mr-2" checked={etat2 === 'gamme'}
                                                    onChange={handleChangeEtat} />
                                                <span>{t('dashboard.Gamme')}</span>
                                            </label>
                                            <label className="mr-2">
                                                <input className="mr-2" type="radio" value="marque" checked={etat2 === 'marque'}
                                                    onChange={handleChangeEtat} />
                                                <span>{t('Produits.Marque')}</span>
                                            </label>
                                            <label className="mr-2">
                                                <input className="mr-2" type="radio" value="famille" checked={etat2 === 'famille'}
                                                    onChange={handleChangeEtat} />
                                                <span>{t('Produits.FAMILLE')}</span>
                                            </label>
                                            <label className="mr-2">
                                                <input className="mr-2" type="radio" value="sousfamille" checked={etat2 === 'sousfamille'}
                                                    onChange={handleChangeEtat} />
                                                <span>{t('Produits.SousFamille')}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    {etat2 === "gamme" ?
                                        <div className="form-group">
                                            <label className="form-label">{t('dashboard.Gamme')}</label>
                                            <select name="country" value={codeGamme} onChange={(e) => setcodeGamme(e.target.value)} className="form-control  custom-select">
                                                <option>---- {t('dashboard.Gamme')} -----</option>
                                                {listeGamme?.map((items, keys) => (
                                                    <option key={keys} value={items.value}>{items.name}</option>
                                                ))}
                                            </select>
                                        </div> : etat2 === "marque" ?
                                            <div className="form-group">
                                                <label className="form-label">{t('Produits.Marque')}</label>
                                                <select name="country" value={codeMarque} onChange={(e) => setcodeMarque(e.target.value)} className="form-control  custom-select">
                                                    <option>---- {t('Produits.Marque')} -----</option>
                                                    {listeMarque?.map((items, keys) => (
                                                        <option key={keys} value={items.value}>{items.name}</option>
                                                    ))}
                                                </select>
                                            </div> : etat2 === "famille" ?
                                                <div className="form-group">
                                                    <label className="form-label">{t('Produits.FAMILLE')}</label>
                                                    <select name="country" value={codeFamille} onChange={(e) => setcodeFamille(e.target.value)} className="form-control  custom-select">
                                                        <option>---- {t('Produits.FAMILLE')} -----</option>
                                                        {listeFamille?.map((items, keys) => (
                                                            <option key={keys} value={items.value}>{items.name}</option>
                                                        ))}
                                                    </select>
                                                </div> : etat2 === "sousfamille" ?
                                                    <div className="form-group">
                                                        <label className="form-label">{t('Produits.SousFamille')}</label>
                                                        <select name="country" value={codeSfamille} onChange={(e) => setcodeSfamille(e.target.value)} className="form-control  custom-select">
                                                            <option>---- {t('Produits.SousFamille')} -----</option>
                                                            {listeSFamille?.map((items, keys) => (
                                                                <option key={keys} value={items.code}>{items.libelle}</option>
                                                            ))}
                                                        </select>
                                                    </div> : null}
                                </div>
                            </>
                        )
                            : null}
                   {etat === 'importer' ? (
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <label className="form-label">{t('Produits.Importer')}</label>
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" onChange={(e) => selectFile(e)} name="file" />
                                        <label className="custom-file-label"> ... {filename} </label>
                                        <p className=" text-azure " > ... {filename} </p>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <a className="text-primary" href={convention} download> <u>{t('Produits.telechargerModele')} </u></a>
                                </div>
                            </div>
                        )
                            : null}
                </div>
            </div>
            <div className='card-footer'>
                {etat === 'ajouter' ? (
                    <button className='btn btn-primary mt-2 float-right' onClick={() => saveData()}><i className='ti-save mr-1' /> {t('Magasins.Enregistrer')} </button>
                ) : (<button className='btn btn-primary mt-2 float-right' onClick={() => uploadFile()}><i className='ti-save mr-1' /> {t('Magasins.Enregistrer')} </button>
                )}
            </div>

        </div></div>
    )
}
