/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { TileLayer, MapContainer, Popup } from "react-leaflet";
import Marker from "react-leaflet-enhanced-marker";
import "leaflet/dist/leaflet.css";
import "../../Magasins/Historique/map.css";
import { useParams } from 'react-router-dom';
import { getRapportSuiviPointageLocalisation } from '../../../services/Rapports/RapportsApi';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

export default function LocalisationPointage() {
    const { date, pdv_code, user_code } = useParams("")
    const ZOOM_LEVEL = 8;
    const mapRef = useRef();
    //------ History -----------------
    const history = useHistory()
    var [localisation, setlocalisation] = useState([])
    const [loading, setloading] = useState(true)
    const [center, setCenter] = useState({ lat: "36.8842439", lng: "10.1625628" });
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getRapportSuiviPointageLocalisation(date, pdv_code, user_code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlocalisation(result.data.liste)
                    setCenter({
                        lat: isNaN(result.data.liste[0]?.lat_pdv) ? "36.8842439" : parseFloat(result.data.liste[0]?.lat_pdv),
                        lng: isNaN(result.data.liste[0]?.long_pdv) ? "10.1625628" : parseFloat(result.data.liste[0]?.long_pdv),
                    })
                }
            }).catch((err) => {
                console.log(err)
            })
            .finally(() => setloading(false))
    }, [])

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.RapportSuiviPointage')} </li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.localisationpointage')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <button className='btn bg-purple mr-2' onClick={() => history.goBack()} ><i className='ti-arrow-left mr-2' /> {t('rapports.retour')} </button>
                        <a href="/rapports/parametragePointage" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-settings mr-1" />
                            {t('rapports.parametragepointage')}
                        </a>
                    </div>

                </div>
            </div>
            <div className='row'>
                <div className='col-4'>
                    <h6 className='text-danger text-center' >  {t('rapports.positionpdv')} :  <hr className='hr1' /> [{localisation[0]?.lat_pdv} ,{localisation[0]?.long_pdv}] </h6>
                </div>
                <div className='col-4'>
                    <h6 className='text-primary text-center'> {t('rapports.positionVisiteDebut')} :  <hr className='hr1' /> [{localisation[0]?.lat_pointage} ,{localisation[0]?.long_pointage}] </h6>
                </div>
                <div className='col-4'>
                    <h6 className='text-success text-center'> {t('rapports.positionVisiteFin')} :  <hr className='hr1' /> [{localisation[0]?.latitude_sortir} ,{localisation[0]?.longitude_sortir}] </h6>
                </div>

            </div>
            <div className='card-body'>
                <div className="left-container" style={{ position: "sticky" }}>
                    {loading ?
                        <SqaureLoader /> : (
                            <MapContainer center={center} zoom={ZOOM_LEVEL} map={mapRef}>
                                <TileLayer
                                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                    url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
                                />
                                {localisation?.map((items, index) => (
                                    <div key={index}>
                                        <Marker position={[items?.lat_pdv === null || undefined ? -1 : parseFloat(items?.lat_pdv), items?.long_pdv === null || undefined ? -1 : parseFloat(items?.long_pdv)]} icon={<i className="ion-location text-danger fa-4x" />} className="marker" >
                                            <Popup>
                                                <b>
                                                    {t('rapports.MAGASIN')}

                                                </b>
                                            </Popup>
                                        </Marker>

                                        <Marker position={[items?.lat_pointage === null || undefined ? -1 : parseFloat(items?.lat_pointage), items?.long_pointage === null || undefined ? -1 : parseFloat(items?.long_pointage)]} icon={<i className="ion-location  text-primary fa-4x" />} className="marker" >
                                            <Popup>
                                                <b>
                                                    {t('rapports.pointagedebut')}
                                                </b>
                                            </Popup>
                                        </Marker>

                                        <Marker position={[items?.latitude_sortir === null || undefined ? -1 : parseFloat(items?.latitude_sortir), items?.longitude_sortir === null || undefined ? -1 : parseFloat(items?.longitude_sortir)]} icon={<i className="ion-location text-green fa-4x" />} className="marker" >
                                            <Popup>
                                                <b>
                                                    {t('rapports.pointagefin')}
                                                </b>
                                            </Popup>
                                        </Marker>
                                    </div>

                                ))}

                            </MapContainer>

                        )}

                </div>
            </div>

        </div>
    )
}
