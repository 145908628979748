import React from 'react'

export default function Footer() {
    return (
        <div>
            {/* Footer opened */}
            <footer className="footer-main icon-footer mt-4">
                <div className="container">
                    <div className="  mt-2 mb-2 text-center">
                        Clediss © 2022 <u className="fs-14 text-primary">Merbiss</u>.
                    </div>
                </div>
            </footer>
            {/* Footer closed */}
        </div>

    )
}
