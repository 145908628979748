import React from 'react'
import moment from "moment"

export default function ModalPartLineaire({days ,setok5 , all_pl_tournee ,t}) {
  return (
    <div
    className="modal fade"
    id="pl"
    data-backdrop="static"
    data-keyboard="false"
    tabIndex={-1}
    role="dialog"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="pl">
            Note de la partlinéaire : du {moment(days).format("DD/MM/YYYY")}
          </h5>
          <span>
            <i
              className="icon icon-close text-azure"
              style={{ cursor: "pointer" }}
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setok5(false)}
            />{" "}
          </span>
        </div>

        <div className="modal-body">
          <table className="table  ">
            <thead>
              <tr >
                <th className="fs-10 text-center"># </th>
                <th className="fs-10 text-center">Point de vente </th>
                <th className="fs-10 text-center">PartLinéaire</th>
              </tr>
            </thead>
            <tbody>
             {all_pl_tournee?.map((items,keys)=>(
                <tr key={keys} className="fs-10 text-center">
                     <td>{keys+1}</td>
                    <td>{items.nompdv}</td>
                    <td className={items.pl !=="-" && "text-success"}>{items.pl}</td>
                </tr>
             ))}
            </tbody>
           
          </table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-yellow fs-10 float-right"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setok5(false)}
          >
            <i className=" ti-close mr-1" /> {t("dashboard.Fermer")}
          </button>
        </div>
      </div>
    </div>
  </div>)
}
