/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast } from 'react-toastify';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { getCodeMetiByCode, updateCodeMeti } from '../../../services/Produits/ProduitsAPI';

/**
 * ModalEditCodeMeti
 * @param {*} param0 
 * @returns 
 */
export default function ModalEditCodeMeti({ listeproduits, listeenseignes, id, index, setOk , t }) {
    //----- USE REFERENCE ------------
    const enseigne = useRef();
    const produits = useRef();
    //-------- FORMULAIRE -----------
    const [code, setCode] = useState("")
    const [codeproduit, setcodeproduit] = useState(0)
    const [codeenseigne, setcodeenseigne] = useState(0)
    const [loading, setLoading] = useState(true)

    // Handle Change Famille
    const handleEnseigne = (...args) => {
        setcodeenseigne(args[1].value)
    };

    // Handle Change Famille
    const handleProduits = (...args) => {
        setcodeproduit(args[1].value)
    };


    useEffect(() => {
        
        setLoading(true)
        getCodeMetiByCode(id)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setCode(result?.data?.data[0]?.code)
                    setcodeenseigne(result?.data?.data[0]?.enseigne_code)
                    setcodeproduit(result?.data?.data[0]?.produit_code)
                } else {
                    toast("Probléme d'affichage des valuer ! ", { type: 'error', position: 'top-right' })
                }

            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)

            })

    }, [id, index])

    const updateCode = () => {
        const data = {
            code: code,
            enseigne_code: codeenseigne,
            produit_code: codeproduit
        }
        updateCodeMeti(id, data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('messageErreur.modifsuccee'), { type: "success", position: 'top-right' })
                } else {
                    toast(t('messageErreur.erreur'), { type: "error", position: 'top-right' })
                }
            }).catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className="modal fade" id="modal-editer" tabIndex={-1} role="dialog" aria-labelledby="modal-editer" aria-hidden="true" key={index}>
            <div className="modal-dialog modal-danger" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal-title-editer">{t('Produits.modifiercodemeti')}: {id} </h2>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close"  /> </span>

                    </div>
                    <div className="modal-body">
                        {loading ?
                            <SqaureLoader/>
                            : (
                                <div className="card-body">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapportrupture.codeMeti')}<span className="text-danger">*</span></label>
                                        <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.PRODUIT')}  <span className="text-danger">*</span></label>
                                        <SelectSearch
                                            options={listeproduits}
                                            search
                                            value={codeproduit}
                                            ref={produits}
                                            onChange={handleProduits}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')} </div>}
                                            placeholder={t('Produits.choisirProduit')}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Produits.Enseigne')}  <span className="text-danger">*</span></label>
                                        <SelectSearch
                                            options={listeenseignes}
                                            search
                                            value={codeenseigne}
                                            ref={enseigne}
                                            onChange={handleEnseigne}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')} </div>}
                                            placeholder={t('Magasins.Choisirenseigne')}
                                            />
                                        
                                        
                                    </div>
                                </div>
                            )}

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => updateCode()} ><i className="ti-save mr-2" />  {t('Routings.confirmer')}</button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" onClick={() => setOk(true)}> <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
