import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Get liste  : Routings
 * @returns 
 */
 export async function getListeRoutings (){
    try{
        const response = await axios.get(url+"routings/",{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else if(error?.response?.status === 422 ){
              return 422
          }
    }
}
/**
 * Get liste  : Routings
 * @returns 
 */
 export async function updateRoutingMerch (data){
    try{
        const response = await axios.post(url+"routings/merch",data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else return error.response.status
    }
}
/**
 * Post add data  : Routings
 * @returns 
 */
 export async function postObjectRoutings (data){
    try{
        const response = await axios.post(url+"routings/",data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else {
            return error?.response?.status
        }
    }
}
/**
 * Get detilas  : routings
 * @returns 
 */
 export async function getDetailsRoutings (code){
    try{
        const response = await axios.get(url+"routings/"+code,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else {
            return error?.response?.status
        }
    }
}
/**
 * Get detilas  : routings
 * @returns 
 */
 export async function updateRoutings (code,data){
    try{
        const response = await axios.patch(url+"routings/"+code,data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else {
              return error?.response?.status
          }
    }
}
/**
 * Get detilas  : routings
 * @returns 
 */
 export async function getMagasinsRoutings (code){
    try{
        const response = await axios.get(url+"routings/magasins/"+code,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else {
            return error?.response?.status
        }
    }
}
/**
 *  patch update data  : Routings
 * @returns 
 */
 export async function patchObjectRoutings (code, data){
    try{
        const response = await axios.patch(url+"routings/"+code,data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else {
            return error?.response?.status
        }
    }
}
/*********************** TOURNEE ------------------------------------- */

/**
 * Get liste  : Routings
 * @returns 
 */
 export async function getListeTournee(datedebut,datefin,data){
    try{
        const response = await axios.post(url+"tournees/tournee/"+datedebut+"/"+datefin,data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
/**
 * Get liste  : Routings
 * @returns 
 */
 export async function getIdTournee(code){
    try{
        const response = await axios.get(url+"tournees/id/"+code,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
/**
 * GET LISTE OF PDVS BY ROUTINGS CODE
 * @param {*} routing_code 
 * @returns 
 */
export async function getListePdvByRoutingsCode(routing_code){
    try{
        const response = await axios.get(url+"tournees/listepdvs/"+routing_code,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
export async function addTourneeNewPdv(data){
    try{
        const response = await axios.post(url+"tournees/newPdv/",data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
/**
 * Get liste  : Routings
 * @returns 
 */
 export async function getListeTourneeByCode(code){
    try{
        const response = await axios.get(url+"tournees/byCode/"+code,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
export async function getListeTourneeRoute(code){
    try{
        const response = await axios.get(url+"tournees/route/"+code,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
export async function getListeByZone(code){
    try{
        const response = await axios.get(url+"tournees/zones/"+code,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
export async function ShowListeByZone(code,datedebut,datefin){
    try{
        const response = await axios.get(url+"tournees/showzones/"+code+"/"+datedebut+"/"+datefin,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
export async function addTournee(data){
    try{
        const response = await axios.post(url+"tournees/",data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
export async function addTourneeFinale(data){
    try{
        const response = await axios.post(url+"tournees/tourneeFinale/",data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
/**
 * Delete TOURNEE AGENDA
 * @param {*} code 
 * @param {*} dates 
 * @returns 
 */
export  function deleteTourneeAgenda(code,datedebut,datefin){
    try{
        const response =  axios.delete(url+"tournees/tourneeDelete/"+code+"/"+datedebut+"/"+datefin,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }

}
/**
 * 
 */
export  function addTourneeTrade(data){
    try{
        const response =  axios.post(url+"tournees/trade/",data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }

}
export async function getListeTourneeTrade(zone_code,datedebut,datefin){
    try{
        const response = await axios.get(url+"tournees/listeTourneeValidation/"+zone_code+"/"+datedebut+"/"+datefin,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
export async function getListeTourneeRegion(code){
    try{
        const response = await axios.get(url+"tournees/region/"+code,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
/**
 * Get liste  : Routings
 * @returns 
 */
 export async function deletePdvTournee(code, codepdv){
    try{
        const response = await axios.delete(url+"tournees/"+code+"/"+codepdv,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
/**
 * Get liste  : Routings
 * @returns 
 */
 export async function deleteTourneeByCode(code){
    try{
        const response = await axios.delete(url+"tournees/"+code,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}

/**
 * Get liste  : Routings
 * @returns 
 */
 export async function editActifTournee(code, codepdv ,data){
    try{
        const response = await axios.patch(url+"tournees/"+code+"/"+codepdv,data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}
/**
 * Get liste  : Routings
 * @returns 
 */
 export async function editTourneeByCode(code ,data){
    try{
        const response = await axios.patch(url+"tournees/"+code,data,{ headers: authHeader() },{
            IsEssential: true
          });
          return response;
    }catch(error){
        if (error.response === undefined){
            return undefined
          }
          else  return error?.response?.status
    }
}