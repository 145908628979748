/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react'
import PaginationTable from '../../components/Pagination/PaginationTable'
import { filterProduit, getListeProduits } from '../../services/Produits/ProduitsAPI'
import { geSelectSousFamilles, getSelecteMarque, getSelectFamilles, getSelectGamme } from '../../services/Produits/configurationProduits/ConfigurationAPI'
import { SearchItems } from '../../utils/SearchUtils'
import SelectSearch, { fuzzySearch } from 'react-select-search';
import "./style.css"
import AddModalProduits from './AddProduit/AddModalProduits'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import { useTranslation } from 'react-i18next'
import ModalExporterProduits from './ModalExporterProduits'
import ModalDesactiver from './desactivProduits/ModalDesactiver'

export default function ListeProduits() {

    // Liste Produits 
    const [listeProduits, setListeProduits] = useState([])
    const [saveList, setSaveList] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [codeProduit, setcodeProduit] = useState("")
    const [codemarque, setcodemarque] = useState(-1)
    const [codegammes, setcodegammes] = useState(-1)
    const [codefamille, setcodefamille] = useState(-1)
    const [codesousfamille, setcodesousfamille] = useState(-1)
    const [listeMarque, setlisteMarque] = useState([])
    const [listeGammes, setlisteGammes] = useState([])
    const [listeFamilles, setlisteFamilles] = useState([])
    const [listeSousFamilles, setlisteSousFamilles] = useState([])
    //USE REF
    const inputEl = useRef("")
    const searchInput = useRef();
    // Formulaire 
    const [search, setSearch] = useState([])
    // Loading 
    const [loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(20);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeProduits?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)
    const [ok, setOk] = useState(false) // show modal and edit on same time
    //For détails Rows
    const [index, setindex] = useState(-1)
    const [show, setShow] = useState(false)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getSelecteMarque()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteMarque(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });

        getSelectFamilles()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteFamilles(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });

        geSelectSousFamilles()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteSousFamilles(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });

        getSelectGamme()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteGammes(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });

        getListeProduits()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setListeProduits(result?.data?.data)
                    setSaveList(result?.data.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [])

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (inputEl.current.value !== "") {
            const newList = SearchItems(listeProduits, inputEl.current.value)
            setSearchlist(newList)
        } else {
            setListeProduits(saveList)
        }
    }
    // Handle Change Famille
    const handleChangeFamille = (...args) => {
        setcodefamille(args[1].value)
    };
    // Handle Change Marque
    const handleChangeMarque = (...args) => {
        setcodemarque(args[1].value)
    };
    // Handle Change Famille
    const handleChangeSousFamille = (...args) => {
        setcodesousfamille(args[1].value)
    };
    // Handle Change Marque
    const handleChangeGammes = (...args) => {
        setcodegammes(args[1].value)
    };

    const getFilterListe = () => {
        filterProduit(codefamille, codemarque, codegammes, codesousfamille)
            .then((result) => {
                if(result?.data?.success === 1){
                    setListeProduits(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
    }
    const back = () =>{
        setcodefamille(-1)
        setcodefamille(-1)
        setcodemarque(-1)
        setcodegammes(-1)
        setListeProduits(saveList)
    }

    useEffect(()=>{
        if(codefamille!==-1 || codefamille !== -1 ||codegammes  !== -1 || codemarque  !== -1){
            filterProduit(codefamille, codemarque, codegammes, codesousfamille)
            .then((result) => {
                if(result?.data?.success === 1){
                    setListeProduits(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        }else{
            getListeProduits()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setListeProduits(result?.data?.data)
                    setSaveList(result?.data.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
        }
    },[ok])
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Produits.Produits')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Produits.Listedesproduits')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/produits/cadeaux" className="btn btn-yellow btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-present mr-1" />
                            {t('Produits.Cadeaux')}
                        </a>
                        <a href="/produits/validationGratuite" className="btn btn-green btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="ion-speakerphone mr-1" />
                            {t('sidebar.validationGratuite')}
                        </a>
                        <a href="/produits/Configurations" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-equalizer mr-1" />
                            {t('Produits.ConfiguraionProduits')}
                        </a>
                        <a href="/produits/codeMETI" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-wrench mr-1" />
                            {t('Produits.ConfigurationCodeMETI')}
                        </a>
                     
                    </div>
                </div>
            </div>
            {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" />   {t('Produits.Listeproduits')}
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className="form row no-gutters">
                                <div className="form-group   col-xl-2 col-lg-2 col-md-12 mb-0">
                                    <input type="text" ref={inputEl} value={search}
                                        onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 fs-12"
                                        placeholder={t('Produits.Rechercherdanslalistedesproduits')} />
                                </div>

                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 col-6 mb-0 ">
                                    <SelectSearch
                                        options={listeMarque}
                                        search
                                        ref={searchInput}
                                        onChange={handleChangeMarque}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Produits.ChoisirMarque')}
                                    />
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 col-6 mb-0">
                                    <SelectSearch
                                        options={listeGammes}
                                        search
                                        ref={searchInput}
                                        onChange={handleChangeGammes}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Produits.choisirGamme')}
                                    />
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-2  mb-0">
                                    <SelectSearch
                                        options={listeFamilles}
                                        search
                                        ref={searchInput}
                                        onChange={handleChangeFamille} 
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Produits.choisirFamille')}
                                    />
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 mb-0">
                                    <SelectSearch
                                        options={listeSousFamilles}
                                        search
                                        ref={searchInput}
                                        onChange={handleChangeSousFamille}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Produits.choisirSFamille')}
                                    />
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-14" onClick={() => getFilterListe()}><i className='ti ti-search' /></button>
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button className="br-tl-md-0 br-bl-md-0 btn btn-block text-white btn-warning fs-14" onClick={() => back()}><i className='ti ti-close' /></button>
                                </div>
                            </div>
                            <a className="btn btn-azure float-right text-white fs-12 mt-2" href="/produits/ajouterProduits"> <i className="icon icon-plus" /> {t('Produits.Ajouter')} </a>
                            <button className='btn btn-green float-right fs-12 mr-1 mt-2' data-toggle="modal" data-target="#exampleModal3" ><i className="fa fa-file-excel-o mr-1" /> {t('Produits.Importer')} </button>
                            <button className='btn btn-secondary float-right fs-12 mr-1 mt-2' data-toggle="modal" data-target="#exportProduits" ><i className=" icon icon-share-alt mr-1" />  {t('rapportrupture.exporter')} </button>

                            <h6 className=" mt-2 text-muted fs-12">  <span className="text-info"> <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span> <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                                <div className="w-3 h-3 bg-danger  mr-2" />
                                <div>
                                    <strong> {t('Produits.Produitsinactifs')}</strong>
                                    <br />
                                </div>
                            </div>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-0">

                    <div className="card">

                        <div className="card-body">

                            {loading ? <SqaureLoader /> : (
                                <div className="table-responsive  product-datatable">
                                    <table className="table  table-bordered ">
                                        <thead className="text-center">
                                            <tr className='bg-purple'>
                                                <td colSpan="11" className='fs-12'> {t('Produits.TotalProduits') + " : " + listeProduits?.length} </td>
                                            </tr>
                                            <tr>
                                                <th className="w-5p fs-10"> # </th>
                                                <th className="w-15p fs-10">{t('rapports.code')}</th>
                                                <th className="wd-15p fs-10">{t('Produits.CODEABARRE')}</th>
                                                <th className="wd-15p fs-10">{t('Produits.Produits')}</th>
                                                <th className="wd-15p fs-10">{t('rapports.prix')}</th>
                                                <th className="wd-15p fs-10">{t('Produits.SOCIETE')}</th>
                                                <th className="wd-15p fs-10">{t('Produits.MARQUE')}</th>
                                                <th className="wd-15p fs-10">{t('Produits.FAMILLE')}</th>
                                                <th className="wd-15p fs-10">{t('Produits.SousFamille')}</th>
                                                <th className="wd-15p fs-10">{t('Produits.Gamme')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {currentListe?.map((items, keys) => (
                                                <>
                                                    <tr key={keys} className={items.actif !== 1 ? 'bg-red-lightest' : " "}>
                                                        <td className='fs-10'> {index === keys && show ? <i className='ti-angle-up' onClick={() => { setindex(-1); setShow(false) }} /> : <i className='ti-angle-down' onClick={() => { setindex(keys); setShow(true) }} />} </td>
                                                        <td className='fs-10 '>  <a className='text-primary' href={"/produits/modifierProduits&id=" + items.code}>{items.code}  </a></td>
                                                        <td className='fs-10'>{items.code_a_barre} </td>
                                                        <td className='fs-10'>{items.libelle} </td>
                                                        <td className='fs-10'>{parseFloat(items.prix).toFixed(3)} </td>
                                                        <td className='fs-10'>{items.societe !== null ? items.societe : "Aucune"} </td>
                                                        <td className='fs-10'>{items.marques} </td>
                                                        <td className='fs-10'>{items.familles} </td>
                                                        <td className='fs-10'>{items.sousfamilles} </td>
                                                        <td className='fs-10'>{items.gammes} </td>
                                                    </tr>
                                                    {index === keys && show ?
                                                        <tr>
                                                            <td colSpan="10">
                                                                <table className="table table-striped table-hover">
                                                                    <thead className="text-center ">
                                                                        <th className="fs-10" >{t('Produits.CodeMETI')}</th>
                                                                        <th className="fs-10" >{t('Produits.Ordre')}</th>
                                                                        <th className="fs-10" >{t('Produits.Colisage')}</th>
                                                                        <th className="fs-10" >{t('Produits.description')}</th>
                                                                        <th className="fs-10" >{t('rapports.ACTION')}</th>
                                                                    </thead>
                                                                     <tbody className="text-center ">
                                                                        <td className='fs-10'>{items.code_meti === null ? <span className='text-danger'> - </span> : items.code_meti} </td>
                                                                        <td className='fs-10'>{items.ordre} </td>
                                                                        <td className='fs-10'>{items.colisage} </td>
                                                                        <td className='fs-10'>{items.description !== null ? items.description : "Aucune"} </td>
                                                                        <td className='fs-10 text-center'> <a href={"/convention/" + items.code} className="btn btn-primary btn-sm btn-square"> <i className='ti-write mr-2' />{t('sidebar.convention')} </a>  
                                                                         <button className="btn btn-danger btn-sm btn-square text-center" data-toggle="modal" data-target="#desactiver" onClick={()=>{setcodeProduit(items.code)}} > <i className='ti-na mr-2' /> Désactiver </button></td>

                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr> : null
                                                    }

                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="float-right">
                                        <PaginationTable
                                            postsPerPage={stocksPerPage}
                                            totalPosts={search?.length < 1 ? listeProduits?.length : searchList?.length}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <ModalExporterProduits listeProduits={listeProduits} t={t}  loading={loading} />
            {codeProduit!=="" &&<ModalDesactiver  setOk={setOk} t={t} code={codeProduit} /> }                                    
            <AddModalProduits setOk={setOk} t={t} />
        </div>
    )
}
