import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Footer from "../shared/Footer";
import HeaderEmptyLayout from "../shared/HeaderEmptyLayout";

export default function EmptyLayout(props) {
    const [backgroundColor, setbackgroundColor] = useState({ backgroundColor: "#edeff5" })

    return (
        <div>
            <HeaderEmptyLayout />
            <ToastContainer />
            <div className="page" style={backgroundColor}>
                    <React.Fragment>{props.children}</React.Fragment>   
                 <Footer />
            </div>
        
        </div>
    );
}
