import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL , REACT_APP_API_CONTRAT} = process.env;

const url = REACT_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
const role=user?.result?.role_code
const user_code =user?.result?.code

/**
 * LICENCE API 
 */
const URL_API = REACT_APP_API_CONTRAT

/**
 * Get Licence  
 * @returns 
 */
 export async function getLicence(code) {
    try {
        const response = await axios.get(URL_API+code , {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

//*************************************** API : CRUD USER  ---------------------------------*/
/**
 * Get liste  : USERS
 * @returns 
 */
export async function getListeUsers() {
    try {
        const response = await axios.get(url + "users/liste/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : USERS
 * @returns 
 */
 export async function getContratUser() {
    try {
        const response = await axios.get(url + "users/contrat/LICENCE", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : USERS
 * @returns 
 */
export async function getMerch() {
    try {
        const response = await axios.get(url + "users/grouped/merch/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : USERS
 * @returns 
 */
export async function addUser(data) {
    const formData = new FormData();
    if (data.image !== undefined) {
        formData.append("image", data.image);
    }
    if(data.synchronisation !== undefined){
        formData.append("synchronisation", data.synchronisation);

    }
    formData.append("code", data.code);
    formData.append("adresse", data.adresse);
    formData.append("email", data.email);
    formData.append("telephone", data.telephone);
    formData.append("login", data.login);
    formData.append("paye_code", data.paye_code);
    formData.append("region_code", data.region_code);
    formData.append("zone_code", data.zone_code);
    formData.append("role_code", data.role_code);
    formData.append("societe_code", data.societe_code);
    formData.append("password", data.password);
    formData.append("cin", data.cin);
    formData.append("isactif", data.isactif);
    formData.append("nom", data.nom);

    try {
        const response = await axios.post(url + "users/register", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else {
            return error?.response?.status
        }
    }
}
/**
 * Get liste  : USERS
 * @returns 
 */
export async function getUserbycode(code) {
    try {
        const response = await axios.get(url + "users/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else {
            return error?.response?.status
        }
    }
}
/**
 * Get liste  : USERS
 * @returns 
 */
 export async function getDetailsUsers(code) {
    try {
        const response = await axios.get(url + "users/details/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else {
            return error?.response?.status
        }
    }
}
/**
 * Get liste  : USERS
 * @returns 
 */
 export async function getHistoriquesUser(code,year) {
    try {
        const response = await axios.get(url + "users/historiqueUser/" + code +"/"+year, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else {
            return error?.response?.status
        }
    }
}
/**
 * update Users
 * @returns 
 */
export async function updateUserbycode(code, data) {
    const formData = new FormData();
    if (data.image !== undefined) {
        formData.append("image", data.image);
    }
    if(data.synchronisation !== undefined){
        formData.append("synchronisation", data.synchronisation);

    }
    formData.append("adresse", data.adresse);
    formData.append("email", data.email);
    formData.append("telephone", data.telephone);
    formData.append("paye_code", data.paye_code);
    formData.append("region_code", data.region_code);
    formData.append("zone_code", data.zone_code);
    formData.append("role_code", data.role_code);
    formData.append("societe_code", data.societe_code);
    formData.append("cin", data.cin);
    formData.append("isactif", data.isactif);
    formData.append("nom", data.nom);
    try {
        const response = await axios.patch(url + "users/" + code, formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else {
            return error?.response?.status
        }
    }
}
/**
 * Get liste  : USERS
 * @returns 
 */
export async function getListeRoles() {
    try {
        const response = await axios.get(url + "roles/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : USERS
 * @returns 
 */
export async function getRolebyCode(code) {
    try {
        const response = await axios.get(url + "roles/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else {
            return error?.response?.status
        }
    }
}/**
 * Get liste  : USERS
 * @returns 
 */
export async function updateRolebyCode(code, data) {
    try {
        const response = await axios.patch(url + "roles/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}/**
 * POST  : add ROLES
 * @returns 
 */
export async function addRoles(data) {
    try {
        const response = await axios.post(url + "roles/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}