/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function ListeRouteCarte() {
    return (
        <div>
             <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> Routings </a></li>
                    <li className="breadcrumb-item active" aria-current="page">Routes Cartes</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                    <a href="/routings/routings&route=-1&region=-1" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="fe fe-download mr-1" />
                            Création Tournée
                        </a>
                        <a href="/routings/TourneeZone" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="fe fe-download mr-1" />
                            Création Tournée (Agenda)
                        </a>
                        <a href="/routings/tournee" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="fe fe-download-cloud " />
                            Tournée
                        </a>
                        <a href="/routings/suiviRoutings" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="fe fe-download-cloud " />
                            Suivi Routings
                        </a>
                        <a href="/routings/routes" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="fe fe-download-cloud " />
                             Routes
                        </a>
                        <a href="/routings/depositaire" className="btn btn-azure btn-icon-text mr-2 mr-2 d-none d-md-block">
                            <i className="fe fe-printer" />
                            Depositaire
                        </a>
                      
                    </div>
                </div>
            </div>  
        </div>
    )
}
