import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getListePays, postObjectRegions } from '../../../services/Zoning/ZoningApi'

export default function ModalAddRegions({ setShow , t }) {
    //------ Formulaire -----------
    const [code, setCode] = useState("")
    const [libelle, setlibelle] = useState("")
    const [codepays, setCodePays] = useState(0)
    //  Liste des pays 
    const [listePays, setListePays] = useState([])


    useEffect(() => {
        getListePays()
            .then((result) => {
                if(result?.data?.success === 1){
                    setListePays(result?.data?.data)
                  }
            }).catch((err) => {
                console.log(err)
            })
    }, [])

    // SAVE DATA 
    const saveData = () => {

        //Data of code & libelle FOR OBJECT Region
        if (code !== "" && libelle !== "" && codepays !== 0) {
            const data = {
                code: code,
                libelle: libelle,
                paye_code :codepays
            }
            postObjectRegions(data)
                .then((result) => {
                    console.log(result.data.success)
                    if (result.data.success === 0) {
                        toast(t('Zoning.Erreur'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result.data.success === 1) {
                        toast("La région a été ajoutée avec succée !", {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }

    return (
        <div className="modal fade" id="exampleModal4" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3">Ajouter une région </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label className="form-control-label">{t('rapports.code')} <span className="text-danger">*</span></label>
                                <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">{t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="mr-2 mt-1 mb-sm-1"> Pays <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codepays}
                                    onChange={(e) => {
                                        setCodePays(e.target.value)
                                    }}>
                                    <option value={0}> choisir </option>
                                    {listePays?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">*
                    <button type="button" className="btn btn-azure fs-12 " onClick={() => saveData()} > <i className='icon icon-cloud-download mr-1' />{t('rapports.enregistrer')} </button>
                    <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" onClick={() => setShow(false)}> <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
