/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ToastContainer } from 'react-toastify';
import AddTask from './AddTask';
import ListOfTask from './ListOfTask';

export default function CheckUser() {
  const { datedebut, datefin } = useParams('')
  //USE REF
  const inputEl = useRef("")
  //Search 
  const [search, setSearch] = useState('')
  //ADD 
  const [add, setadd] = useState(false)
  //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");

  const getSearchTerm = () => {

  }
  return (
    <div>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item mt-2"><i className="icon icon-equalizer text-azure mr-1" /> {t('affectationtaches.affectationtaches')} </li>
        </ol>
        <ToastContainer />

      </div>
      <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
        <div className="panel panel-default active">
          <div className="panel-heading " role="tab" id="headingOne">
            <h4 className="panel-title">
              <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <i className='icon icon-plus mr-2 text-azure' /> {t('affectationtaches.attribuerTaches')}
                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
              </a>
            </h4>
          </div>
          <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
            <div className="panel-body">
              <AddTask t={t} setadd={setadd} add={add} />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
          <ListOfTask t={t} datedebut={datedebut} datefin={datefin} add={add} />
        </div>
      </div>
    </div>
  )
}
