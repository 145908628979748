import moment from 'moment'
import React from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'

export default function DescriptionConventions({t,listeConventions, Loading, SupprimerConventions, code , description}) {
  return (
    <div><div className="panel-group" id="accordion2" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
    <div className="panel panel-default active">
        <div className="panel-heading " role="tab" id="headingOne">
            <h4 className="panel-title">
                <a role="button" className="fs-12 text-primary"  data-toggle="collapse" data-parent="#accordion2" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                    <i className="icon icon-grid text-primary mr-2" />           {t('conventions.descriptionProduit') + " : "}<span className=" fs-12"> {t('rapports.code')}  {code} , {description?.libelle}  </span> 
                    <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                </a>
            </h4>
        </div>
        <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
            <div className="panel-body">
            <h6 className='fs-12'>{t('rapports.TOTAL') + ' : ' + listeConventions?.length} </h6>
                    <div className="table-responsive product-datatable">
                        {Loading ? <SqaureLoader /> : (
                            <table className="table  table-bordered ">
                                <thead className="text-center" >

                                    <tr className='text-center'>
                                        <th className="fs-12" >{t('Produits.Libelle')}</th>
                                        <th className="fs-12" >{t('rapportrupture.categorie')}</th>
                                        <th className="fs-12" >{t('dashboard.Enseigne')}</th>
                                        <th className="fs-12" >{t('Routings.datedebut')}</th>
                                        <th className="fs-12" >{t('Routings.datefin')}</th>
                                        <th className="fs-12 " >{t('Magasins.ACTION')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listeConventions?.map((items, keys) => (
                                        <tr className={items.etat === 0 ? 'text-center bg-pink-lightest  fs-12' : "text-center   fs-12"} key={keys}>
                                            <td>{items.libelle} </td>
                                            <td>{items.categorie} </td>
                                            <td>{items.enseignes} </td>
                                            <td>{moment(items.date_debut).format("DD-MM-YYYY")} </td>
                                            <td>{moment(items.date_fin).format("DD-MM-YYYY")} {items.etat === 0 ? <i className='icon icon-exclamation text-pink' data-toggle="tooltip" data-placement="top" title="Expired date" /> : null} </td>
                                            <td> <i className='ti-trash fs-14 text-danger' onClick={() => SupprimerConventions(items.code)} /> </td>


                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}

                    </div>
            </div>
        </div>
    </div>
</div></div>
  )
}
