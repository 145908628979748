/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import Loader from '../../../components/Loader/Loader'
import { getDetailsRoutings, getListeRoutings } from '../../../services/Routings/RoutingsApi'
import { SearchItems } from '../../../utils/SearchUtils'
import MapRoutes from './MapRoutes'
import ModalAddRoutes from './ModalAddRoutes'
import ModalEditRoute from './ModalEditRoute'

export default function ListeRoutes() {
    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    //---------- LISTE Routes -------------
    const [listeRoutings, setListeRoutings] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    const [localisation, setlocalisation] = useState([])
    const [saveLocalisation, setsaveLocalisation] = useState([]);
    //Loader 
    const [Loading, setLoading] = useState(true)
    //Modal items
    const [show, setShow] = useState(true)
    //Edit Items 
    const [showEdit, setShowEdit] = useState(false)
    const [index, setIndex] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")

    const currentListe = search.length < 1 ? listeRoutings : searchList

    useEffect(() => {

        getListeRoutings()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListeRoutings(result?.data?.getListeRoutes)
                    setSaveList(result?.data?.getListeRoutes)
                    setlocalisation(result?.data?.localisation)
                    setsaveLocalisation(result?.data?.localisation)
                }
                setLoading(false)
            }).catch((err) => {
                console.log(err)
            })

    }, [])

    //ONChange 
    useEffect(() => {
        if (show === false) {
            getListeRoutings()
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setListeRoutings(result?.data.getListeRoutes)
                        setSaveList(result?.data.getListeRoutes)
                    }
                    setShow(true)
                }).catch((err) => {
                    console.log(err)
                })
        }
    }, [show])

    /**
     * Change Routes 
     */
    const changeDetailsRoute = (code) => {
        setLoading(true)
        getDetailsRoutings(code)
            .then((result) => {
                setObject(result.data.data[0])
            }).catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }

    const onClickColor = (codeRoute) => {
        const l = saveLocalisation?.filter((el) => el.route === codeRoute)
        setlocalisation(l)
    }

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeRoutings, search)
            setSearchlist(newList)
        } else {
            setListeRoutings(saveList)
        }
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Routings.routings')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">  {t('Routings.Route')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href={'/routings/tournee&datedebut=' + date1 + '&datefin=' + date2} className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="fe fe-download-cloud " />
                            {t('Routings.Tournee')}
                        </a>
                        {details?.role_code !== "chef" ?
                            <>
                                <a href="/routings/routings&route=-1&region=-1" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                                    <i className=" icon icon-plus mr-1" />
                                    {t('Routings.CreationTournee')}
                                </a>
                                <a href="/routings/TourneeZone" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                                    <i className="icon icon-event mr-1" />
                                    {t('Routings.creationAgenda')}
                                </a>
                            </>
                            : <a href="/routings/validationTournee" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                                <i className=" icon icon-plus mr-1" />
                                {t('rapports.validationTournee')}
                            </a>}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-7">
                    {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
                    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                        <div className="panel panel-default active">
                            <div className="panel-heading " role="tab" id="headingOne">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <i className="icon icon-grid text-primary mr-2" /> {t('Routings.listeroutes')}
                                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    <div className="form row no-gutters">
                                        <div className="col-7"></div>
                                        <div className=" col-xl-5 col-lg-5 col-md-5 mb-0">
                                            <div className="input-group">
                                                <input type="text" ref={inputEl} value={search}
                                                    onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary"
                                                    id="text1" placeholder="Rechercher route" />

                                                <button className="btn bg-primary fs-10"  ><i className="ti-search"></i></button>

                                            </div>


                                        </div>

                                    </div>

                                    <h6 className=" mt-2 text-muted" style={{ fontSize: "12px" }}>
                                        <span className="text-info"> <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span>{t('Routings.messagesRoutes')} <i className="icon icon-note text-warning" /> , {t('Routings.restedumessage')}<u className="text-primary"> Exemple </u> </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    {Loading ? <Loader /> : (
                        <div className="card">

                            <div className="card-body">
                            <button className="btn btn-azure float-right mb-2 fs-10" data-toggle="modal" data-target="#exampleModal3"> <i className="icon icon-plus mr-1" /> {t('Routings.ajouterRoute')} </button>

                            <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '530px' }}>
                                    <table
                                        id="example"
                                        className="table table-striped table-bordered "
                                    >
                                        <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                            <tr className="bg-purple">
                                                <td colSpan="4" className='fs-10'>  {t('rapports.TOTAL')}: {currentListe?.length} </td>
                                            </tr>
                                            <tr>
                                                <th className="w-15p fs-10" >{t('Routings.Route')}</th>
                                                <th className="wd-15p fs-10">{t('dashboard.Merchandiseurs')}</th>
                                                <th className="w-5p fs-10">{t('Produits.Couleur')}</th>
                                                <th className="wd-15p fs-10">{t('Routings.editer')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center fs-10">
                                            {currentListe?.map((items, keys) => (
                                                <tr key={keys} className="text-center">
                                                    <td>{items.code} </td>
                                                    <td> <a href={"/details_routes/code=" + items.code} className="text-primary"  > <u>{items.nom} </u> </a> </td>
                                                    <td className='text-center'> <div className="w-6 h-3 text-center " onClick={() => onClickColor(items.code)} style={{ backgroundColor: items.color, cursor: "pointer" }} /> </td>
                                                    <td  className="text-center" style={{ cursor: "pointer" }} > <a data-toggle="modal" data-target="#exampleModal" onClick={() => {
                                                        changeDetailsRoute(items.code); setCode(items.code); setIndex(keys); setShowEdit(true)
                                                    }}> <i className="icon icon-note text-warning" /></a> </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                            </div></div>

                    )}
                    <ToastContainer />
                    <ModalAddRoutes setShow={setShow} t={t} />
                </div>
                {showEdit ? <ModalEditRoute setShowEdit={setShowEdit} keys={index} codeRoute={code} object={object} t={t} /> : null}
                <div className="col-5">
                    <MapRoutes listemagasins={localisation} loading={Loading} t={t} />
                </div>
            </div>
        </div>
    )
}
