import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { postObjectGouvernorat } from '../../../services/Zoning/ZoningApi'
import { myRnId } from '../../../utils/DateUtils'

export default function ModalAddGouvernorat({setShow ,t}) {
    
    //------ Formulaire -----------
     const [code, setCode] = useState("")
     const [libelle, setlibelle] = useState("")

     const traitement = (data) =>{
        postObjectGouvernorat(data)
        .then((result) => {
            if (result.data.success === 0) {
                toast(t('Zoning.Erreur'), {
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else if (result.data.success === 1) {
                toast("La région est ajoutée avec succée !", {
                    type: "success",
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
        }).catch((err) => {
            console.log(err)
        });
     }
    // SAVE DATA 
    const saveData = () => {
        if (code === "" && libelle !== "") {
            var generateCode = libelle.slice(0, 3)
            setCode(generateCode + "-" + myRnId())
            const data = {
                code: generateCode + "-" + myRnId(),
                libelle: libelle
            }
            traitement(data)
        } else if(code !== "" && libelle !== "" ) {
            const data = {
                code: code,
                libelle: libelle,
            }
            traitement(data)

        }
    }
     return (
        <div className="modal fade" id="exampleModal6" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="example-Modal3">Ajouter un gouvernorat</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>setShow(false)}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="form-group">
                            <label className="form-control-label">{t('rapports.code')}:</label>
                            <input type="text" value={code} onChange={(e)=>setCode(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label className="form-control-label">{t('dashboard.Libelle')}:</label>
                            <input type="text" value={libelle} onChange={(e)=>setlibelle(e.target.value)} className="form-control" />
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>saveData()} > Enregister </button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>setShow(false)}>Fermer</button>

                </div>
            </div>
        </div>
    </div>
    )
}
