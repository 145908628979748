/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../components/Loader/SqaureLoader';
import { addEntete, listeEntete } from '../../services/Evaluation/EvaluationAPI';

export default function ListeEvaluation() {

    //Liste Entete 
    const [listeEntet, setlisteEntet] = useState([])
    // Form Add 
    const [libelle, setlibelle] = useState("")
    const [code, setcode] = useState("")
    //Languages
    const [t, i18n] = useTranslation("global");
    /** LOADER */
    const [loading, setloading] = useState(true)
    //----------- MISE A JOUR AUTOMATIQUE ---------------
    const [ok, setok] = useState(true)


    const saveData = () => {
        const data = {
            code : code,
            libelle: libelle
        }
        const arr = []
        arr.push(data)
        addEntete(arr).then((result) => {
            if(result?.data?.success===1){
                setok(!ok)
                toast(t("messageErreur.ajoutavecsuccee"),{type:"success",position:"top-right"})
            }else {
                toast(t("messageErreur.erreur"),{type:"success",position:"top-right"})
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    useEffect(() => {
        listeEntete().then((result) => {
            if (result?.data?.success === 1) {
                setlisteEntet(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })

    }, [ok])

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item mt-2"><i className="icon icon-equalizer text-azure mr-1" /> {t("Questions d'évaluation")}</li>
                </ol>
            </div>
            <ToastContainer />

            <div className='row'>
                {/** Tableau  */}
                <div className='col-12 col-lg-8 col-md-12 col-xl-8 col-sm-12'>
                    <div className='card'>
                        <div className='card-header mt-2'>
                            <i className="mdi mdi-format-list-bulleted  text-primary mr-2" />  {t("Tableau de questions d'évaluation")}
                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-success btn-icon float-right"
                                table="tableau_entetecharge"
                                filename="tableau_entetecharge"
                                sheet="tablexls"
                                buttonText={<i className=" mdi mdi-export  text-white" />}
                            />
                        </div>
                        <div className='card-body'>
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <div className='card-body'>
                                {loading ? <SqaureLoader /> :
                                    <div className="table-responsive">
                                        <table id="tableau_entetecharge" className="table table-bordered card-body fs-10">
                                            <thead>
                                                <tr className='text-center fs-12'>
                                                    <td>#</td>
                                                    <td>{t('rapports.code')}</td>
                                                    <td>{t('Produits.Libelle')}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listeEntet?.map((items, keys) => (
                                                    <tr className={
                                                        keys % 2 === 1 ? "fs-12  text-center bg-azure-lightest" : "fs-12 text-center "
                                                    }>
                                                        <td>{keys + 1}</td>
                                                        <td>{items.code}</td>
                                                        <td>{items.libelle}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {/** ADD CARD */}
                <div className='col-12 col-lg-4 col-md-12 col-xl-4  col-sm-12 ' >
                    <div className='card'>
                        <div className='card-header mt-2'>
                            {t("Ajouter Questions")}
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputText1"  className='fs-12'>{t('rapports.code')} <span className='text-danger'>*</span></label>
                                        <input type="text" className="form-control" value={code} onChange={(e) => setcode(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputText1" className='fs-12'>{t('Produits.Libelle')} <span className='text-danger'>*</span></label>
                                        <input type="text" className="form-control" value={libelle} onChange={(e) => setlibelle(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary mr-2 float-right" onClick={() => saveData()}><i className='mdi mdi-plus mr-1' /> {t('Enregistrer')} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
