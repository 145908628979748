import moment from 'moment';
import React from 'react';

export default function TableauTournee({ listeTournee, today, countPdv, countPdvMonth, countPdvYear, countPromotions, countPL, countReleve, countRupture, inMagasins, outMagasins, t }) {

    return <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-0">
            <div className="card">
                <div className="card-body">

                    <div className="table-responsive  product-datatable">
                        <table className="table  table-bordered "  id="table-to-xls">
                            <thead className="text-center" >
                                <tr >
                                    <td colSpan="1" style={{ borderBottomColor: "#f4faff" }}></td>
                                    <td colSpan="9" className='fs-10' style={{ borderBottomColor: "#f4faff", textAlign:"center" }}> {moment(today).format('DD-MM-YYYY')} </td>
                                    <td colSpan="5" className='fs-10   bg-azure-lighter' style={{ textAlign:"center"}} > {moment().format('MM-YYYY')} </td>
                                    <td colSpan="7" className='fs-10' style={{ borderBottomColor: "#f4faff", textAlign:"center" }}> {moment().format('YYYY')} </td>

                                </tr>
                                <tr className='text-center' style={{ textAlign:"center"}}>
                                    <th className="w-5p fs-10"> {t('dashboard.Merchandiseur')} </th>
                                    <td className="w-10p fs-10">{t('dashboard.heuredebut')}</td>
                                        <td className="w-10p fs-10">{t('dashboard.heureFin')}</td>
                                        <td className="wd-10p fs-10">{t('dashboard.hinpdv')}</td>
                                        <td className="wd-10p fs-10">{t('dashboard.houtpdv')}</td>
                                        <td className="wd-10p fs-10">{t('dashboard.pdvvisite')}</td>
                                        <td className="wd-10p fs-10">{t('dashboard.pdvvisiteplanifiee')}</td>
                                        <td className="wd-10p fs-10">{t('dashboard.pdvplanifiee')}</td>
                                        <td className="wd-10p fs-10">{t('dashboard.txrespectournee')}</td>
                                        <td className="wd-10p fs-10">{t('dashboard.txsuccees')}</td>
                           
                                    <td className="wd-10p fs-10 bg-azure-lighter" >{t('dashboard.pdvvisite')}</td>
                                    <td className="wd-10p fs-10 bg-azure-lighter " >{t('dashboard.pdvvisiteplanifiee')}</td>
                                    <td className="wd-10p fs-10 bg-azure-lighter" >{t('dashboard.pdvplanifiee')}</td>
                                    <td className="wd-10p fs-10 bg-azure-lighter" >{t('dashboard.txrespectournee')}</td>
                                    <td className="wd-10p fs-10  bg-azure-lighter" >{t('dashboard.txsuccees')}</td>

                                    <th className="wd-10p fs-10">{t('dashboard.pdvvisite')}</th>
                                    <th className="wd-10p fs-10">{t('dashboard.pdvvisiteplanifiee')}</th>
                                    <th className="wd-10p fs-10">{t('dashboard.pdvplanifiee')}</th>
                                    <th className="wd-10p fs-10">{t('dashboard.txrespectournee')}</th>
                                    <th className="wd-10p fs-10">{t('dashboard.txsuccees')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listeTournee?.map((items, keys) => (
                                    <tr className='text-center fs-10' key={keys} style={{ textAlign:"center"}}>
                                        <td> {items.nom} </td>
                                        <td className='fs-10'> {items.heure_debut === null ?
                                            <div className="bg-danger-transparent icon-bg icon-service text-danger" style={{ height: "20px", width: "20px" }}>
                                                <i className="fa fa-clock-o fs-12" />
                                            </div> : <a href={'/rapports/rapportSuiviPointage&datedebut=' + today + '&datefin=' + today + "&user_code=" + items.code} ><b className='text-azure'>{items.heure_debut === 'null' ? '-' : (items.heure_debut).slice(0, 5)}</b></a>}  </td>
                                        <td className='fs-10'>{items.heure_fin === 'null' ? t('dashboard.encours') : items.heure_fin !== null ? (items.heure_fin).slice(0, 5) : '-'}</td>
                                        <td className="wd-10p fs-10">{inMagasins[items.code] === undefined || inMagasins[items.code][0]?.inMagasins === "00:00:00.000000" || inMagasins[items.code][0]?.inMagasins === null ? '-' : (inMagasins[items.code][0]?.inMagasins).slice(0, 5)}</td>
                                        <td className="wd-10p fs-10">{outMagasins[items.code] === undefined || outMagasins[items.code][0]?.OutPDV === "00:00:00" || outMagasins[items.code][0]?.OutPDV === null ? '-' : ((outMagasins[items.code][0]?.OutPDV).slice(0, 5))}</td>
                                        <td> {items.pdv_visite !== undefined ? items.pdv_visite : 0} </td>
                                        <td> {items.pdv_visite_tournee !== undefined ? items.pdv_visite_tournee : 0} </td>
                                        <td> {countPdv[items.code] === undefined ? 0 : countPdv[items.code][0]?.pdv_planifiee} </td>
                                        <td data-toggle="tooltip" data-placement="top" title={t('dashboard.pdvvisiteplanifiee') + "/" + t('dashboard.pdvplanifiee')}> {countPdv[items.code] === undefined ? 0 : (parseFloat((items.pdv_visite_tournee / countPdv[items.code][0]?.pdv_planifiee) * 100) >= 100 ? "100 %" : Math.round(parseFloat((items.pdv_visite_tournee / countPdv[items.code][0]?.pdv_planifiee) * 100).toFixed(2)) + "%")} </td>
                                        <td> {items.total_cmd === 0 ? 0 : (parseFloat((items.total_cmd / items.pdv_visite) * 100) >= 100 ? "100 %" : Math.round(parseFloat((items.total_cmd / items.pdv_visite) * 100).toFixed(2)) + "%")}  </td>
                                         {/** ------------------ PL / Rupture /  Releve // Promotions ---- */}
                                  {/*        <td className="wd-10p fs-10"  > {countPL[items.code][0]?.total_pl} </td>
                                            <td className="wd-10p fs-10">{countRupture[items.code][0]?.total_rupture}</td>
                                            <td className="wd-10p fs-10"> {countReleve[items.code][0]?.total_relevePrix}</td>
                                            <td className="wd-10p fs-10" >{countPromotions[items.code][0]?.total_promotions}</td> */}
                                    
                                            {/**------ MONTH PART ------------------- */}
                                            <td className=' fs-10 bg-azure-lighter' ><strong> {items.pdv_visite_month !== undefined ? items.pdv_visite_month : 0}</strong></td>
                                            <td className=' fs-10 bg-azure-lighter' > <strong>{items.pdv_visite_planifiee_month !== undefined ? items.pdv_visite_planifiee_month : 0}</strong></td>
                                            <td className=' fs-10 bg-azure-lighter' ><strong>{countPdvMonth[items.code] === undefined ? 0 : countPdvMonth[items.code][0]?.nbr_pdv_month}</strong></td>
                                            <td className=' fs-10 bg-azure-lighter' ><strong>{countPdvMonth[items.code] === undefined ? 0 : (parseFloat((items.pdv_visite_planifiee_month / countPdvMonth[items.code][0]?.nbr_pdv_month) * 100).toFixed(2) >= 100 ? "100" : Math.round(parseFloat(((items.pdv_visite_planifiee_month !== undefined ? items.pdv_visite_planifiee_month : 0) / countPdvMonth[items.code][0]?.nbr_pdv_month) * 100).toFixed(2)))} %</strong> </td>
                                            <td className=' fs-10 bg-azure-lighter' ><strong>{items.total_cmd_month === 0 || items.pdv_visite_month === undefined ? 0 : Math.round(parseFloat((items.total_cmd_month / items.pdv_visite_month) * 100).toFixed(2))}  %</strong> </td>
                                  
                                        {/**------ YEAR PART ------------------- */}
                                        <td>{items.pdv_visite_year === undefined ? "-" : items.pdv_visite_year}</td>
                                        <td>{items.pdv_visite_planifiee_year===undefined ? "-" : items.pdv_visite_planifiee_year}</td>
                                        <td>{countPdvYear[items.code] === undefined ? 0 : countPdvYear[items.code][0]?.nbr_pdv_month}</td>
                                        <td>{countPdvYear[items.code] === undefined ? 0 : (parseFloat((items.pdv_visite_planifiee_year / countPdvYear[items.code][0]?.nbr_pdv_month) * 100).toFixed(2) > 100 ? "100" : Math.round(parseFloat(((items.pdv_visite_planifiee_year / countPdvYear[items.code][0]?.nbr_pdv_month) * 100).toFixed(2)))) } %</td>
                                        <td> {items.total_cmd_year === 0 ? 0 :
                                            parseFloat((items.total_cmd_year / items.pdv_visite_year) * 100).toFixed(2)}  %</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>;
}
