import React from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'

export default function ExportToExcel({id, namefile,t, }) {
  return (
    <div>
         <ReactHtmlTableToExcel
         id="test-table-xls-button"
         className="btn btn-azure btn-pill fs-12 mt-0 "
         table={id}
         filename={namefile}
         sheet="tablexls"
         buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
        />
    </div>
  )
}
