/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { getallRupturesEnseignes } from '../../../services/Ruptures/ruptureAPI';
import { groupBy } from '../../../utils/GroupBy';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import SqaureLoader from '../../../components/Loader/SqaureLoader';

export default function TableauRuptureEnseignes({ t, dateYear }) {

    const [listeEnseignes, setlisteEnseignes] = useState([])
    const [listeRuptures, setlisteRuptures] = useState([])
    const [listeEnsPres, setlisteEnsPres] = useState([])
    const [listeRupturePresc, setlisteRupturePresc] = useState([])
    const [loading, setloading] = useState(true)
    const [year, setyear] = useState(dateYear);

    useEffect(() => {
        getallRupturesEnseignes(parseInt(year)-1)
        .then((result) => {
            if (result?.data?.success === 1) {
                const ensg = groupBy(result?.data.data, "nom_enseigne")
                const x = Object.keys(ensg)
                setlisteEnsPres(x)
                const liste = groupBy(result.data.data, "mois")
                setlisteRupturePresc(liste)
            }
        }).catch((err) => {
            console.log(err)
        })
        getallRupturesEnseignes(year)
            .then((result) => {
                if (result?.data?.success === 1) {
                    const ensg = groupBy(result?.data.data, "nom_enseigne")
                    const x = Object.keys(ensg)
                    setlisteEnseignes(x)
                    const liste = groupBy(result.data.data, "mois")
                    setlisteRuptures(liste)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [])

    const valueByMonth = (month, enseigne) => {
        if (listeRuptures[month] !== undefined) {
            const liste = listeRuptures[month].find((elemnents) => elemnents.nom_enseigne === enseigne)
            if (liste !== undefined) {
                return liste.total
            } else return undefined
        }
    }
    const valueByMonthPrecedent = (month, enseigne) => {
        if (listeRupturePresc[month] !== undefined) {
            const liste = listeRupturePresc[month].find((elemnents) => elemnents.nom_enseigne === enseigne)
            if (liste !== undefined) {
                return liste.total
            } else return undefined
        }
    }

    const onChangeYear = (e) => {
        setloading(true)
        setyear(e.target.value)
        getallRupturesEnseignes(parseInt(e.target.value)-1)
        .then((result) => {
            if (result?.data?.success === 1) {
                const ensg = groupBy(result?.data.data, "nom_enseigne")
                const x = Object.keys(ensg)
                setlisteEnsPres(x)
                const liste = groupBy(result.data.data, "mois")
                setlisteRupturePresc(liste)
            }
        }).catch((err) => {
            console.log(err)
        })
        getallRupturesEnseignes(e.target.value)
            .then((result) => {
                if (result?.data?.success === 1) {
                    const ensg = groupBy(result?.data.data, "nom_enseigne")
                    const x = Object.keys(ensg)
                    setlisteEnseignes(x)
                    const liste = groupBy(result.data.data, "mois")
                    setlisteRuptures(liste)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }
    return (
        <div className="card">
            <div className="card-header">
                <div className="card-title mt-3 float-left"> {t('historiqueMagasin.ruptureparenseigne')}  </div>
                <div className="float-right">
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-success text-center "
                        table="table-to-xls"
                        filename="rupture_enseigne"
                        sheet="tablexls"
                        buttonText={<span className=" text-center fs-10"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                    />
                </div>
            </div>
            <div className="card-body">
                <div className='row mb-3'>
                    <div className='col-4' />
                    <div className='col-4'>
                        <select name="annee" className="form-control select2 custom-select text-center" value={year} onChange={(e) => onChangeYear(e)}>
                        <option value={""}>{t('conventions.annee')}</option>
                            <option value={2022}>2022</option>
                            <option value={2021}>2021</option>
                            <option value={2020}>2020</option>
                            <option value={2019}>2019</option>
                            <option value={2018}>2018</option>
                        </select>
                    </div>
                    <div className='col-4' />

                </div>
                {loading ? <SqaureLoader /> : (
                    <div className="table-responsive table-striped product-datatable" style={{ overflow: 'auto', height: 'auto' }}>
                        <table
                            id="table-produit"
                            className="table  table-bordered "
                        >
                            <thead className="text-center " style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                                
                                
                                <tr>
                                    <th></th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t('janvier')}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("fevrier")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("Mars")}</th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t("avril")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("mai")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("juin")}</th>
                                    <th className="wd-15p fs-10" colSpan={2} >{t("juillet")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("aout")}</th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t("Septembre")}</th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t("Octobre")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("Novembre")}</th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t("decembre")}</th>
                                </tr>
                                <tr >
                                    <th className="w-15p  fs-10"  >{t('dashboard.Enseigne')} </th>
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>                                    
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>                                
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>                                  
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>                                   
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>    
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>     
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>  
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>  
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>  
                                    <th className="wd-15p fs-10" >{year}</th>
                                    <th className="wd-15p fs-10" >{parseInt(year) -1}</th>                         
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listeEnseignes?.length === 0 ?
                                        <tr>
                                            <td colSpan={13} className='text-center text-danger'> {t('Zoning.Aucunresultat')}</td>
                                        </tr> :
                                        listeEnseignes?.map((items, keys) => (
                                            <tr key={keys}   className={
                                                keys % 2 === 1 ? "fs-12 bg-azure-lightest text-center" : "fs-12 text-center"
                                            }>
                                                <td> {items}</td>
                                                <td> {valueByMonth(1, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(1, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(1, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(1, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(2, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(2, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(2, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(2, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(3, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(3, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(3, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(3, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(4, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(4, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(4, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(4, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(5, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(5, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(5, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(5, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(6, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(6, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(6, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(6, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(7, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(7, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(7, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(7, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(8, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(8, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(8, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(8, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(9, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(9, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(9, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(9, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(10, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(10, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(10, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(10, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(11, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(11, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(11, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(11, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(12, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(12, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(12, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(12, items)}</h6> : 0} </td>

                                            </tr>
                                        ))}
                            </tbody>
                        </table>
                    </div>
                )}

            </div>
        </div>
    )
}
