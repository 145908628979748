/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { toast, ToastContainer } from 'react-toastify'
import { getSelctEnseignes } from '../../../services/Produits/ProduitsAPI'
import { getGraphPl, getGraphPl1 } from '../../../services/Rapports/RapportsApi'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import _ from 'lodash'
import { generateRandomColor } from '../../../utils/Colors'
import { useTranslation } from 'react-i18next';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export default function RapportPartLineaire() {
    //------ History -----------------
    const history = useHistory()
    //------ PARAM --------------------
    const { datedebut, datefin, code } = useParams('')
    const test = ""
    // Formulaire
    const [today, settoday] = useState(moment(datedebut).format('YYYY-MM-DD'))
    const [fin, setfin] = useState(moment(datefin).format('YYYY-MM-DD'))

    const [ListeEnseinges, setListeEnseinges] = useState([])
    const [loading, setloading] = useState(true)
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [listePl, setlistePl] = useState([])
    //CHarts 
    const [label, setlabel] = useState([])
    const [labels, setlabels] = useState([])

    const [data, setdata] = useState([])
    const [data2, setdata2] = useState([])
    const [color, setcolor] = useState([])

    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListeEnseinges(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
    }, [])

    const onChangeDateFormat = () => {

        if (today > fin) {
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
        } else {
            while (label.length) {
                label.pop();
            }
            while (data.length) {
                data.pop();
            }
            while (color.length) {
                color.pop();
            }

            setloading(true)
            history.push({
                pathname:
                    "/rapports/rapportPL&datedebut=" + today + "&datefin=" + fin + "&enseigne=" + codeEnseigne,
            })

            traitement()
        }


    }
    const onChangeDates = () => {
        setlabels([])
        history.push({
            pathname:
                "/rapports/rapportPL&datedebut=" + today + "&datefin=" + fin + "&enseigne=''",
        })

        getGraphPl1(today, fin).then((result) => {
            if (result?.data?.success === 1) {

                const x = []
                result?.data?.groupedEnseigne?.map((items, k) => {
                    x.push(items.nom_enseigne)
                })
                setlabels(x)
                const gr = _.groupBy(result?.data?.graphpl, 'cible')
                const arr = []
                for (let value of Object.values(gr)) {
                    const te = []
                    const lg = value.length
                    value.map((i, k) => {
                        if (lg === x.length) {
                            te.push(parseFloat((i.total_plconstate / i.total_pltotal) * 100).toFixed(2))
                        } else {
                            x.map((y, z) => {
                                if (i.nom_enseigne === y) {
                                    te.splice(z, z, parseFloat((i.total_plconstate / i.total_pltotal) * 100).toFixed(2));
                                } else {
                                    te.splice(z, z, 0);

                                }
                            })
                        }
                    })
                    arr.push({
                        label: value[0].cible,
                        backgroundColor: generateRandomColor(),
                        borderWidth: 1,
                        data: te
                    })
                }
                setdata2(arr)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }
    const traitement = () => {
        getGraphPl(datedebut, datefin, codeEnseigne).then((result) => {
            if (result?.data?.success === 1) {
                setlistePl(result?.data?.graphpl)
                result?.data?.graphpl?.map((items) => {
                    label.push(items.cible + " (" + parseFloat((items.total_plconstate / items.total_pltotal) * 100).toFixed(2) + " % )")
                    data.push(parseFloat((items.total_plconstate / items.total_pltotal) * 100).toFixed(2))
                    color.push(generateRandomColor())
                })
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.graphPartLineaire')} </li>
                </ol>
            </div>
            <div className="panel-group" id="accor2" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accor2" href="#acc2" aria-expanded="true" aria-controls="acc2">
                                <i className="icon icon-grid text-primary mr-2" /> {t('rapports.FiltrePartLineaire')}

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="acc2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-3'></div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary" />
                                        <input type="date" value={fin} onChange={(e) => setfin(e.target.value)} className="form-control text-primary" />

                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => onChangeDates()}><i className='ti-search' /></button>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-3'></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <Bar

                                style={{ height: "400px" }}
                                data={{
                                    labels: labels,
                                    datasets: data2
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            possition: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: t('rapportpl.partlineairemagasin')
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" /> {t('rapports.FiltrePartLineaire')}

                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-3'></div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary" />
                                        <input type="date" value={fin} onChange={(e) => setfin(e.target.value)} className="form-control text-primary" />
                                        <select className="form-control  br-md-0  fs-12 text-primary" value={codeEnseigne} onChange={(e) => setcodeEnseigne(e.target.value)}>
                                            <option value={""}>------  {t('rapports.Enseignes')} -----  </option>
                                            {ListeEnseinges?.map((items, keys) => (
                                                <option key={keys} value={items.value}>{items.name}</option>
                                            ))}
                                        </select>
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-3'></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {codeEnseigne === test ?
                <div className='row'>
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                        <div className="alert bg-purple mb-5 py-4" role="alert">
                            <div className="d-flex">
                                <i className="icon icon-info mr-3 fs-30"></i>
                                <div>
                                    <h4 className="alert-heading">{t('rapportpl.message1')} </h4>
                                    <p>{t('rapportpl.graph')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div className='row'>
                    {listePl.length === 0 ?

                        <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                            <div className="alert bg-purple mb-5 py-4" role="alert">
                                <div className="d-flex">
                                    <i className="icon icon-info mr-3 fs-30"></i>
                                    <div>
                                        <h4 className="alert-heading"> {t('rapportpl.aucuneresultat')} {codeEnseigne} ! </h4>
                                        <p>  {t('rapportpl.choisirdate')} </p>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    {loading ? <SqaureLoader /> : (
                                        <Doughnut
                                            style={{ height: "400px" }}
                                            data={{
                                                labels: label,
                                                datasets: [
                                                    {
                                                        label: label,
                                                        data: data,
                                                        backgroundColor: color,
                                                        borderColor: color,
                                                    }]
                                            }}
                                            options={{
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        possition: 'top',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: t('rapportpl.partlineairenseigne') + " " + codeEnseigne,
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </div>}
        </div>
    )
}
