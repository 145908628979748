/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import SqaureLoader from "../../components/Loader/SqaureLoader";
import { getalldocumentations } from "../../services/task/taskAPI";

export default function Documentations() {

    const [LISTE, setLISTE] = useState([])
    const [Loading, setLoading] = useState(true)

    useEffect(() => {
        getalldocumentations()
        .then((result) => {
            if(result?.data?.success===1){
                setLISTE(result?.data?.liste)
            }
        }).catch((err) => {
            console.log(err);
        }).finally(()=>{
            setLoading(false)
        })
    }, [])
    
  return (
    <div>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-file-text text-azure mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
            </svg>
            Documentation{" "}
          </li>
        </ol>
      </div>
     
     {Loading? <SqaureLoader /> :<div className="row">
        {LISTE?.map((items,keys)=>
         <div className="col-6" key={keys}>
         <div className="card">
           <div className="card-header mb-0">
             <h4 className="card-title mt-2 text-azure">
               {keys+1}) {items.titre_fr}
             </h4>
           </div>
           <div className="card-body mt-0">
             <iframe
               src={items.url}
               width={640}
               height={480}
               allow="autoplay"
             />
           </div>
         </div>
       </div>
        )}
       
     
      </div> } 
    </div>
  );
}
