import React from 'react'
import { toast } from 'react-toastify';
import { deleteTask } from '../../services/task/taskAPI';

export default function DeleteTask({code,index2,setShow2,t}) {

    const deleteCode = () =>{
    
        deleteTask(code)
        .then((result) => {
            if(result?.data.success === 1){
                toast(t('messageErreur.suppression'),{type:'success',position:'top-right'})
            }else{
                toast(t('messageErreur.erreursupp'),{type:"error",position:'top-right'})

            }
        }).catch((err) => {
            console.log(err)
        });
    }

  return (
    <div className="modal fade" id="modal-notification" tabIndex={-1} role="dialog" aria-labelledby="modal-notification" aria-hidden="true" key={index2}>
    <div className="modal-dialog modal-danger" role="document">
        <div className="modal-content border-0">
            <div className="modal-header">
                <h2 className="modal-title" id="modal-title-notification">{t('affectationtaches.supprimerTaches')} </h2>
                <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" /> </span>
            </div>
            <div className="modal-body">
                <div className="py-3 text-center">
                    <i className="ti-trash fa-3x" />
                    <h4 className="heading mt-4">{t('Produits.etesvoussure')}</h4>
                    <p> {t('affectationtaches.etesvoussure')} </p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={()=>{deleteCode(code)}}> <i className="icon icon-check mr-1" /> {t('Routings.confirmer')} </button>
                <button type="button" className="btn btn-secondary text-white ml-auto" data-dismiss="modal" onClick={()=>setShow2(false)}><i className="icon icon-close mr-1"/> {t('Routings.annueler')} </button>
            </div>
        </div>
    </div>
</div>
  )
}
