/* eslint-disable no-loop-func */
import React, { useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export default function GraphparRegions({ t, listeRegions, regions, loading, date1, date2 }) {

    const [title, settitle] = useState("")
    //---------- ITEMS Graphes --------------
    const [labels, setlabels] = useState([])
    const [data, setdata] = useState([])

    const detailsGammes = (g, name) => {
        if (g === "all") {
            // Grouped  by date Exemple ['2022-02-01','2022-01-17']

        } else {
            settitle(name)
            const li = listeRegions?.filter((el) => el.region_code === g)
            const gr = _.groupBy(li, "date")
            const x = []
            for (let value of Object.keys(gr)) {
                x.push(moment(value).format("YYYY-MM-DD"))
            }
            const l = _.uniq(x)
            setlabels(l)
            var y = []
            var arr = []
            for (let value of Object.values(gr)) {
                y.push(value[0].total)
            }
            arr.push({
                label: t('rapportGraph.ruptureparregion')+" " + name,
                backgroundColor:   'rgba(75, 192, 192, 0.2)',
                borderColor :  'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                data: y
            })
            setdata(arr)
        }

    }
    return (
        <div className="panel-group" id="accordion5" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
                <div className="panel-heading " role="tab" id="headingFive">
                    <h4 className="panel-title">
                        <a role="button" data-toggle="collapse" data-parent="#accordion5" className='fs-12' href="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                            <i className="icon icon-grid text-primary mr-2" />  {t('rapportGraph.ruptureparregion')}
                            <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                        </a>
                    </h4>
                </div>
                <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                    <div className="panel-body">
                        {loading ? <SqaureLoader /> :
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>
                                <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                    <label className='mr-3 fs-12'><i className='icon icon-equalizer mr-1' /> {t('rapportGraph.filtreparregion')}: </label>
                                    <button className="btn tag bg-azure text-white" onClick={() => detailsGammes("all", "all")}>  {t('rapports.ALL')} </button>

                                    {regions?.map((items, keys) => (
                                        <button className="btn tag bg-purple" key={keys} onClick={() => detailsGammes(items.region_code, items.region)}> {items.region}</button>
                                    ))}
                                </div>
                                <Bar
                                    data={{
                                        labels: labels,
                                        datasets: data
                                    }}

                                    options={{
                                        plugins: {
                                            legend: {
                                                possition: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: t('rapportGraph.ruptureparregion')+"  " + title
                                            }
                                        },
                                        scales: {
                                            x: {
                                              display: true,
                                              title: {
                                                display: true,
                                                text: t('dashboard.Date'),
                                                font: {
                                                    family: 'Poppins',
                                                    size: 16,
                                                    style: 'normal',
                                                    lineHeight: 1.2
                                                },
                                                padding: {top: 20, left: 0, right: 0, bottom: 0}
                                              }
                                            },
                                            y: {
                                              display: true,
                                              title: {
                                                display: true,
                                                text: t('Produits.Quantite'),
                                                font: {
                                                  family: 'Poppins',
                                                  size: 16,
                                                  style: 'normal',
                                                  lineHeight: 1.2
                                                },
                                                padding: {top: 30, left: 0, right: 0, bottom: 0}
                                              }
                                            }
                                          }
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
