import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getDetailPromotions } from '../../../services/Dashboard/FirstPage'

export default function ModalPromotions({ t, user_code, setok2, today }) {
    //------------- LISTE Promotions ----------
    const [listePromotions, setlistePromotions] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {
        setloading(true)

        //Call WebService with liste of promotions
        getDetailPromotions(today, user_code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlistePromotions(result?.data?.promotions)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [today,user_code])

    return (
        <div className="modal fade" id="exampleModal5" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="exampleModal5" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={{ width: "max-content" }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModal5"> <i className='fe fe-tag text-azure mr-1' />  {t('sidebar.detailPromo')}  </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setok2(false);setlistePromotions([])}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {loading ? <SqaureLoader /> : (
                            <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '500px' }}>
                                <table className="table  table-bordered ">
                                    <thead className="text-center bg-purple" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                    <td className='fs-10'>#</td>
                                        <td className="wd-15p fs-10">{t('dashboard.Magasin')}</td>
                                        <td className="wd-15p fs-10">{t('rapportrupture.produit')}</td>
                                        <td className="wd-15p fs-10">{t('rapports.TAUX')}</td>
                                        <td className="wd-15p fs-10">{t('rapports.miseavant')}</td>
                                        <td className="wd-15p fs-10">{t('rapportrupture.etat')}</td>

                                    </thead>
                                    <tbody>
                                        {listePromotions?.map((items, keys) => (
                                            <tr key={keys} className="text-center ">
                                                <td className='fs-10'>{keys+1}</td>
                                                <td className="wd-15p fs-10">{items.nompdv}</td>
                                                <td className="wd-15p fs-10">{items.produits}</td>
                                                <td className="wd-15p fs-10">{items.taux_promotion !== null ? items.taux_promotion + " %" : '-'} </td>
                                                <td className="wd-15p fs-10">{items.mise_avant !== null ? items.mise_avant : "-"}</td>
                                                <td className="wd-15p fs-10">{items.etat_promotion === "1" ? <span className='text-green'><i className='icon icon-check mr-1' />  {t('rapports.Respecte')} </span> : <span className='text-danger'><i className='icon icon-exclamation mr-1' /> {t('rapports.Nonrespecte')} </span>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    {/*  <div className='modal-footer '>
                        <button className='btn btn-secondary float-right ' onClick={() => setok2(false)}><i className='ti-close mr-1'/> Fermer</button>
                    </div> */}
                </div>
            </div>
        </div>)
}
