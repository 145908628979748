import moment from 'moment'
import React, { useState } from 'react'
import DeleteNote from './DeleteNote'

export default function TableauNotes({ listeReminder, t }) {
     //Modal items
     const [index2, setindex2] = useState(-1)
     const [code, setCode] = useState("")
     const [show2, setShow2] = useState(false)

    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title mt-2">{t("Liste des messages")} </h4>
                    </div>                 
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-bordered border-top table-hover mb-0 text-nowrap">
                                <thead>
                                    <tr className='text-center fs-10'>
                                        <th className='fs-10'>#</th>
                                        <th className='fs-10'>{t("rapports.OBJET")}</th>
                                        <th className='fs-10'>{t("Emetteur")}</th>
                                        <th className='fs-10'>{t("Message")}</th>
                                        <th className='fs-10'>{t('Magasins.Datedebut')}</th>
                                        <th className='fs-10'>{t('Magasins.Datefin')}</th>
                                        <th className='fs-10'>{t("Recepteur")}</th>
                                        <th className='fs-10'>{t("dashboard.Enseigne")}</th>
                                        <th className='fs-10'>{t("rapportrupture.categorie")}</th>
                                        <th className='fs-10'>{t("rapportrupture.etat")}</th>
                                        <th className='fs-10'>{t("Magasins.ACTION")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listeReminder?.map((items, keys) => (
                                        <tr key={keys} className="text-center fs-10">
                                            <td>{keys + 1}</td>
                                            <td>{items.objet}</td>
                                            <td>{items.emetteur_nom}</td>
                                            <td>{items.message}</td>
                                            <td>{moment(items.date_debut).format("DD/MM/YYYY")}</td>
                                            <td>{moment(items.date_fin).format("DD/MM/YYYY")}</td>
                                            <td>{items.recepteur_nom === null ? "Tout les users" : items.recepteur_nom}</td>
                                            <td>{items.enseigne === null ? "-" : items.enseigne}</td>
                                            <td>{items.categorie === null ? "-" : items.categorie}</td>
                                            <td className={items.etat === "sender" ? 'bg-yellow-lighter' : "bg-azure-lighter"}>{items.etat}</td>
                                            <td>{items.etat !== "sender" && <i className='ti-trash text-danger'  data-toggle="modal" data-target="#modal-notification" onClick={() => { setCode(items.code); setindex2(keys); setShow2(true) }}/>} {items.etat === "sender" && <a href={"/editMessage/"+items.code}> <i className='icon icon-note text-info '/></a>}  </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {show2 ? <DeleteNote setShow2={setShow2} index2={index2} code={code} t={t} /> : null}

        </div>

    )
}
