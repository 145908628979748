/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import SqaureLoader from "../../components/Loader/SqaureLoader";
import { getListeConge, updateCongee } from "../../services/Conge/CongeAPI";
import ModalDeleteConge from "./ModalDeleteConge";

export default function CongeInterface() {
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //--------- Search --------------------
    const [date_d, setdate_d] = useState("");
    const [date_f, setdate_f] = useState("");
    //------- LISTE -------------------
    const [listeconge, setlisteconge] = useState([]);
    const [loading, setloading] = useState(true)

    const [index, setIndex] = useState(-1)
    const [ok, setok] = useState(false)
    //Modal items
    const [index2, setindex2] = useState(-1)
    const [code, setCode] = useState("")
    const [show2, setShow2] = useState(false)

    useEffect(()=>{
        getListeConge().then((result) => {
            if(result?.data?.success ===1){
                setlisteconge(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloading(false)
        })
    },[setShow2])

    const onChangeValue = () => { };
    const handleChange = (e, index) => {
        const clonedData = [...listeconge]
        clonedData[index][e.target.name] = e.target.checked
        setlisteconge(clonedData)
    }
    const updateConge = (data, code) => {
        const el={ 
            validation : data.validation === true ? 1 :0,     
        }
        updateCongee(el,code).then((result) => {
            if(result?.data?.success===1){
                toast("Modification avec succès",{type:"success",position:"top-right"})
            }else {
                toast("Erreur !!",{type:"success",position:"top-right"})

            }
        }).catch((err) => {
            console.log(err)
        });
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="#">
                            <i className="fe fe-life-buoy mr-1" />
                            Congé
                        </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Liste des congés
                    </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a
                            href="/users/addconge"
                            className="btn btn-azure btn-icon-text fs-12  mr-2 d-none d-md-block"
                        >
                            <i className="ti-plus mr-1" />
                            Ajouter un congé
                        </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header mt-3 mb-0 pb-0">
                            <i className="icon icon-list mr-2 text-azure" /> Liste des congés{" "}
                        </div>
                        <div className="card-body">
                           {/*  <div className="row mb-2">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12"></div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="input-group">
                                        <input
                                            type="date"
                                            value={date_d}
                                            onChange={(e) => setdate_d(e.target.value)}
                                            className="form-control text-primary fs-12"
                                        />
                                        <input
                                            type="date"
                                            value={date_f}
                                            onChange={(e) => setdate_f(e.target.value)}
                                            className="form-control text-primary fs-12"
                                        />
                                        <span className="input-group-append">
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={() => onChangeValue()}
                                            >
                                                <i className="ti-search" />
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12"></div>
                            </div> */}
                            {loading ? <SqaureLoader /> : (
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered border-top table-hover mb-0 text-nowrap">
                                                <thead className="text-center">
                                                    <tr className="fs-10">
                                                        <td>#</td>
                                                        <td>Merchandiseur</td>
                                                        <td>Date Début</td>
                                                        <td>Date Fin</td>
                                                        <td>Nbr Jours</td>
                                                        <td>
                                                            Solde
                                                            <i
                                                                className="icon icon-exclamation ml-1 text-info"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Solde Congé"
                                                            />
                                                        </td>
                                                        <td>Validation</td>
                                                        <td>Action</td>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listeconge?.map((items, keys) => (
                                                        <tr className="fs-10 text-center">
                                                            <td>{keys + 1}</td>
                                                            <td>{items.user}</td>
                                                            <td>{moment(items.date_debut).format("DD/MM/YYYY")}</td>
                                                            <td>{moment(items.date_fin).format("DD/MM/YYYY")}</td>
                                                            <td>{items.nbrjour}</td>
                                                            <td>{items.solde_conge}</td>
                                                            {ok === true && index === keys ? (<td> <input
                                                        type="checkbox"
                                                        name="validation"
                                                        value={items.validation}
                                                        checked={items.validation}
                                                        onChange={(e) => handleChange(e, keys)}
                                                    /></td> ):    <td> {items.validation === 0 ? <i className='icon icon-close text-danger' /> :<i className='icon icon-check  text-success' />}</td>}
                                                            <td>  
                                                                <i className='icon-note text-azure mr-1' onClick={() => { setok(!ok); setIndex(keys) }} />
                                                                <i className='icon-trash text-danger' data-toggle="modal" data-target="#modal-notification" onClick={() => { setCode(items.code); setindex2(keys); setShow2(true) }} />

                                                                {ok === true && index === keys ? (<i className='ti-save-alt text-primary mr-1 ml-1' onClick={() => { updateConge(listeconge[keys], items.code); setok(!ok); }} />) : null}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            {show2 ? <ModalDeleteConge setShow2={setShow2} index2={index2} code={code} t={t} /> : null}

        </div>
    );
}
