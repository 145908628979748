/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { TileLayer, MapContainer, Popup } from "react-leaflet";
import Marker from "react-leaflet-enhanced-marker";
import "leaflet/dist/leaflet.css";
import "../../Magasins/Historique/map.css";
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { getRapportSuiviPointageLocalisation } from '../../../services/Rapports/RapportsApi';

export default function ModalMapPointages({date,pdv_code,user_code ,setok3,t}) {
    var [localisation, setlocalisation] = useState([])
    const [loading, setloading] = useState(true)
    const [center, setCenter] = useState({
        lat: isNaN(localisation?.lat_pdv) ? "36.8842439" : parseFloat(localisation?.lat_pdv),
        lng: isNaN(localisation?.long_pdv) ? "10.1625628" : parseFloat(localisation?.long_pdv),
    });
    const ZOOM_LEVEL = 8;
    const mapRef = useRef();

    useEffect(()=>{
    getRapportSuiviPointageLocalisation(date,pdv_code,user_code)
    .then((result) => {
       setlocalisation(result.data.liste[0])
    }).catch((err) => {
        console.log(err)
    }).finally(()=>setloading(false))
    },[])
    
    return (
        <div className="modal fade" id="exampleModalLocalisation" tabIndex={-1} role="dialog" aria-hidden="true" >
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title" id="example-Modal3"> {t('rapports.localisationpointage')}: </h6>
                    <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close"  /> </span>

                </div>
                <div className="modal-body">
                    <div className='row'>
                        <div className='col-4'>
                            <h6 className='text-danger text-center' >{t('rapports.positionpdv')} : <hr className='hr1'/> [{localisation?.lat_pdv} ,{localisation?.long_pdv}] </h6>
                        </div>
                        <div className='col-4'>
                            <h6 className='text-primary text-center' >{t('rapports.positionVisiteDebut')} :  <hr className='hr1'/> [{localisation?.lat_pointage} ,{localisation?.long_pointage}] </h6>
                        </div>
                        <div className='col-4'>
                        <h6 className='text-success text-center' >{t('rapports.positionVisiteFin')}:  <hr className='hr1'/> [{localisation?.latitude_sortir} ,{localisation?.longitude_sortir}] </h6>
                        </div>

                    </div>
                    <div className='row'>
                    <div className="left-container">
                  {loading ? <SqaureLoader /> : (
                    <MapContainer center={center} zoom={ZOOM_LEVEL} map={mapRef}>
                    <TileLayer
                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
                        />
                       
                            <Marker position={[localisation?.lat_pdv === null || undefined ? -1 : parseFloat(localisation?.lat_pdv), localisation?.long_pdv === null || undefined ? -1 : parseFloat(localisation?.long_pdv)]} icon={<i className="ion-location  fa-4x" style={{ color:  "#ff6600" }} />} className="marker" >
                            <Popup>
                                    <b>
                                         {t('rapports.MAGASIN')}
                                    </b>
                                </Popup>
                            </Marker>

                            <Marker position={[localisation?.lat_pointage === null || undefined ? -1 : parseFloat(localisation?.lat_pointage), localisation?.long_pointage === null || undefined ? -1 : parseFloat(localisation?.long_pointage)]} icon={<i className="ion-location  fa-4x" style={{ color: "#07A4E5" }} />} className="marker" >
                            <Popup>
                                    <b>
                                        {t('rapports.Pointage')}
                                    </b>
                                </Popup>
                            </Marker>
                            <Marker position={[localisation?.latitude_sortir === null || undefined ? -1 : parseFloat(localisation?.latitude_sortir), localisation?.longitude_sortir === null || undefined ? -1 : parseFloat(localisation?.longitude_sortir)]} icon={<i className="ion-location  fa-4x" style={{ color: "#07A5" }} />} className="marker" >
                            <Popup>
                                    <b>
                                    {t('rapports.MAGASIN')}

                                    </b>
                                </Popup>
                              </Marker>
                        
                    </MapContainer>

                )}

            </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    )
}
