import React from 'react'

export default function TableRelevePrix({ listeRelevePrix, t }) {
    return (
        <div className='card'>

            <div className='card-header'>
                <h6 className='card-title mt-3 text-center '> {t('relevePrix.relevePrix')} </h6>
            </div>
            <div className='card-body'>
                <div className="panel panel-body mb-2" style={{ overflow: 'scroll', height: "300px" }}>
                 
                    {/** Tableau rupture Produits  */}
                    <div className="table-responsive  product-datatable">
                        <table className="table  table-bordered ">
                            <thead className="text-center bg-azure-lighter">
                                <tr>
                                    <th className="w-15p fs-12"> {t('rapports.CODEABARRE')}</th>
                                    <th className="wd-15p fs-12">{t('rapportrupture.produit')}</th>
                                    <th className="wd-15p fs-12">{t('rapports.S.FAMILLE')}</th>
                                    <th className="wd-15p fs-12">{t('relevePrix.marque')}</th>
                                    <th className="wd-15p fs-12">{t('rapports.permanent')}</th>
                                    <th className="wd-15p fs-12">{t('dashboard.Promotions')}</th>
                                    <th className="wd-15p fs-12">{t('rapports.position')}</th>
                                </tr>
                            </thead>
                            <tbody className='text-center fs-12'>
                                {listeRelevePrix.length === 0 ?
                                    <tr>
                                        <td colSpan={7} className='text-center text-danger'>{t('rapports.aucuneinfo')}
                                            <hr className='hr1' />
                                            <img src="../assets/images/photos/no.png" alt='noData' style={{ height: "200px" }} />
                                        </td>                                    </tr> :
                                    listeRelevePrix?.map((items, keys) => (
                                        <tr  key={keys} className={
                                            keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                        } >
                                            <td>{items.cab}</td>
                                            <td>{items.nomproduits}</td>
                                            <td>{items.sousfamilles}</td>
                                            <td>{items.marques}</td>
                                            <td>{parseFloat(items.permanent).toFixed(3)}</td>
                                            <td>{parseFloat(items.promo).toFixed(3)}</td>
                                            <td>{items.position}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
