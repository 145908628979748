import React from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'

export default function CardGeneralInformations({ historique, loading ,t }) {
    return (
        <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
                <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title text-primary">
                        <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i className="icon icon-book-open mr-2" />{t('rapports.detailMagasins')}: {historique?.nompdv}
                            <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                        </a>
                    </h4>
                </div>
                <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                                <div className="row">
                                    <div className='col-12'>
                                    <table id="example"  className="table  table-bordered " >
                                        <thead className="text-center bg-azure-lighter">
                                            <tr>
                                                <th className='fs-10'>{t('rapportrupture.categorie')}</th>
                                                <th className='fs-10'> {t('Magasins.Assortiments')} </th>
                                                <th className='fs-10'>{t('historiqueMagasin.activite')}</th>
                                                <th className='fs-10'>{t('historiqueMagasin.qualite')}</th>
                                                <th className='fs-10'>{t('historiqueMagasin.chefrayon')} </th>
                                                <th className='fs-10'>{t('historiqueMagasin.telchefrayon')}</th>
                                                <th className='fs-10'>{t('historiqueMagasin.mailChefrayon')}</th>
                                                <th className='fs-10'>{t('dashboard.rupture')}</th>
                                                <th className='fs-10'>{t('historiqueMagasin.nombreVisite')}</th>
                                                <th className='fs-10'>{t('historiqueMagasin.nombretg')}</th>
                                                <th className='fs-10'>{t('historiqueMagasin.nombretr')}</th>
                                                <th className='fs-10'>{t('Magasins.Centralise')}</th>
                                                <th className='fs-10'>{t('historiqueMagasin.distributeur')}</th>
                                            </tr>
                                        </thead>
                                        {loading ? <SqaureLoader /> : (
                                            <tbody>
                                                <tr className="text-center">
                                                    <td className='fs-10'>{historique?.categoriepdv} </td>
                                                    <td className='fs-10'> {historique?.sum_ass} </td>
                                                    <td className='fs-10'> {historique?.typepdv} </td>
                                                    <td className='fs-10'> {historique?.qualiterelationnel !== null ? historique?.qualiterelationnel : "-"} </td>
                                                    <td className='fs-10'> {historique?.chefrayon !== null ? historique?.chefrayon : "-"} </td>
                                                    <td className='fs-10'> {historique?.telchefrayon !== null ? historique?.telchefrayon : "-"} </td>
                                                    <td className='fs-10'> {historique?.mailchefrayon !== null ? historique?.mailchefrayon : "-"} </td>
                                                    <td className='fs-10'> {historique?.rupture !== null ? historique?.rupture : 0} </td>
                                                    <td className='fs-10'> {historique?.visite !== null ? historique?.visite : 0} </td>
                                                    <td className='fs-10'> {historique?.TG !== null ? historique?.TG : 0} </td>
                                                    <td className='fs-10'> {historique?.TR !== null ? historique?.TR : 0} </td>
                                                    <td className='fs-10'> {historique?.centralise === 0 ? "Non" : historique?.centralise} </td>
                                                    <td className='fs-10'> {historique?.distributeur !== null ? historique?.distributeur : "-"} </td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <h6 className='fs-10'> <span className=" text-info"> <i className="icon icon-exclamation mr-2" /> Informations :</span> TR <span className="text-danger"> * </span>  (Tête de rayon ) / TG <span className="text-danger"> * </span> : (Tête de gondole)  </h6>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
