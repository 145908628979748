import React from 'react'

export default function NoResult({title , subtitle}) {
  return (
    <div className="row">
    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
        <div className="alert bg-purple mb-5 py-4" role="alert">
            <div className="d-flex">
                <i className="icon icon-info mr-3 fs-30"></i>
                <div>
                    <h4 className="alert-heading"> {title} </h4>
                    <p> {subtitle} </p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
