/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { getGraphRegions } from '../../../services/Dashboard/FirstPage';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import _ from 'lodash';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { generateRandomColor } from '../../../utils/Colors';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function GraphRegion({ date1, date2 ,t}) {
    /**----------- Graphe 1 ----------------- */
    var [label, setlabel] = useState([])
    var [data1, setdata1] = useState([])
    var [color, setcolor] = useState([])
    //--------------- GRAPH 2 ---------------
    const [labels, setlabels] = useState([])
    const [data, setdata] = useState([])
       /** ------------- Graphe 3 -----------  */
       var [label3, setlabel3] = useState([])
       var [data3, setdata3] = useState([])
       var [color3, setcolor3] = useState([])
    /** ----------- LOADER ------------------ */
    const [loading, setloading] = useState(true)

    useEffect(() => {
        /**
         * Date debut : date 1 / date2 : date fin
         */
        getGraphRegions(date1, date2)
            .then((result) => {
                if (result?.data?.success === 1) {
                    //----------- Traitement Graphe 1 ------------
                    var total = result?.data?.totalRegions
                    var l1 = []
                    var d1 = []
                    var c1 = []
                    result?.data?.ruptureparRegions?.map((items) => {
                        l1.push(items.region + " (" + items.total + ") " + parseFloat(((items.total) / (total)) * 100).toFixed(2) + " % ")
                        d1.push(parseFloat(((items.total) / (total)) * 100).toFixed(2))
                        c1.push(generateRandomColor())
                    })
                    setlabel(l1)
                    setdata1(d1)
                    setcolor(c1)
                    //----------- Traitement Graphe 2 ----------------                
                    const total_ensg = _.groupBy(result?.data?.detailsRegions, 'region_code')
                    console.log(total_ensg)
                    const x = []
                    // GROUPED Labels par enseigne exemple : ["monoprix","UHD"]
                    result?.data?.detailsRegions?.map((items, k) => {
                        x.push(items.region)
                    })
                    const l = _.uniq(x)
                    setlabels(l)
                    // Groupement par gamme 
                    const gr = _.groupBy(result?.data?.detailsRegions, 'libelle')
                    const arr = []
                    for (let value of Object.values(gr)) {
                        const te = []
                        const lg = value.length
                        value.map((i, k) => {
                            if (lg === l.length) {
                                te.push(parseFloat((i.total / total) * 100).toFixed(2))

                            } else {
                                l.map((y, z) => {
                                    if (i.nom_enseigne === y) {
                                        te.splice(z, z, parseFloat((i.total / total) * 100).toFixed(2));
                                    } else {
                                        te.splice(z, z, 0);
                                    }
                                })
                            }
                        })
                        arr.push({
                            label: value[0].libelle,
                            backgroundColor: value[0].color,
                            borderWidth: 1,
                            data: te
                        })
                        setdata(arr)
                    }
                     //-------------GRAPHE 3 --------------------------
        const pl = []
        result?.data?.detailsPartLineaires?.map((items, k) => {
            pl.push(items.region)
        })
        const pl1 = _.uniq(pl)
        setlabel3(pl1)
        const gr3 = _.groupBy(result?.data?.detailsPartLineaires, 'month')
        const arr3 = []
        for (let value of Object.values(gr3)) {
            const te = []
            const lg = value.length
            value.map((i) => {
                if (lg === pl1.length) {
                    te.push(parseFloat(i.sumtot).toFixed(3))
                } else {
                    pl1.map((y, z) => {
                        if (i.region === y) {
                            te.splice(z, z, parseFloat(i.sumtot).toFixed(3));
                        } else {
                            te.splice(z, z, 0);
                        }
                    })
                }
            })
            arr3.push({
                label: value[0].month,
                backgroundColor: generateRandomColor(),
                borderWidth: 1,
                data: te
            })
            setdata3(arr3)
        }
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })

    }, [])

    return (
        <>
         <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12'>
                <div className='card'>
                    <div className='card-body'>
                        {loading ? <SqaureLoader/> : 
                             <Bar
                             style={{ height: "400px" }}
                             data={{
                                 labels: labels,
                                 datasets: data
                             }}
                             options={{
                                 maintainAspectRatio: false,
                                 responsive: true,
                                 indexAxis: 'y',

                                 plugins: {
                                     legend: {
                                         possition: 'top',
                                     },
                                     title: {
                                         display: true,
                                         text: t('dashboard.ruptureparregiongamme')+" " + date1 + '-' + date2
                                     },
                                 },
                             }}
                         />
                        }
                   
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12'>
                <div className='card'>
                    <div className='card-body'>
                        {loading ?
                            <SqaureLoader /> :
                            <Doughnut
                                style={{ height: "400px" }}
                                data={{
                                    labels: label,
                                    datasets: [
                                        {
                                            label: label,
                                            data: data1,
                                            backgroundColor: color,
                                            borderColor: color,
                                        }]
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            possition: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: t('dashboard.ruptureregion')+" " + date1 + '-' + date2
                                        },
                                    },
                                }}
                            />}

                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12'>
                <div className='card'>
                <div className='card-body'>
                    {loading ? <SqaureLoader /> :
                            <Bar
                                style={{ height: "400px" }}
                                data={{
                                    labels: label3,
                                    datasets: data3
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    indexAxis: 'x',
                                    plugins: {
                                        legend: {
                                            possition: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: t('dashboard.graphPartLineaireregion')+" " + date1 + '-' + date2
                                        },
                                    },
                                }}
                            />
                        }

                    </div>
                </div>
            </div>
        </>
    )
}
