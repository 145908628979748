/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import moment from "moment"
import _ from 'lodash'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ModalHistoriqueProduit from './ModalHistoriqueProduit';

export default function TableauRupture2({ liste, listeProdNSaisie, listeGrouped, t }) {

    const [datess, setdatess] = useState([])
    const [settings, setsettings] = useState(false)
    const [show1, setshow1] = useState(true)
    const [show2, setshow2] = useState(true)
    const [show3, setshow3] = useState(true)
    const [show4, setshow4] = useState(true)
    const [show5, setshow5] = useState(true)
    const [show6, setshow6] = useState(true)
    const [show7, setshow7] = useState(true)
    const [show8, setshow8] = useState(true)
    const [show9, setshow9] = useState(true)
    //Modal items 
    var [produit_code, setproduit_code] = useState('')
    var [pdv, setpdv] = useState('')
    const [ok, setOk] = useState(false)

    useEffect(() => {
        var test = _.sortBy(liste, 'date');
        const p = _.groupBy(test, function (item) {
            return moment(item.date).format("YYYY-MM-DD")
        });
        const gr = Object.keys(p)
        setdatess(gr)
    }, [liste])

    const findOnListe = (produit_code, enseigne_code, pdv_code, date) => {
        const findEl = liste?.filter((el) => el.produit_code === produit_code && el.enseigne_code === enseigne_code && el.pdv_code === pdv_code && moment(el.date).format("YYYY-MM-DD") === date)
        if (findEl[0]?.qte === undefined) {
            return null
        } else return findEl[0]?.qte
    }

    return (
        <div className='col-12'>
            <div className='float-right '>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 "
                    table="table-to-xls"
                    filename="Rapport_Rupture"
                    sheet="tablexls"
                    buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                />
                <button className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 " onClick={() => setsettings(!settings)} ><i className='ti-filter' /> </button>
            </div>
            {settings ?
                <div className='row'>
                    <div className="selectgroup selectgroup-pills">
                        <label className="selectgroup-item ">
                            <input type="checkbox" value={show1}
                                checked={show1}
                                onChange={(e) => setshow1(e.target.checked)} className="selectgroup-input " />
                            <span className="selectgroup-button fs-10">{t('rapportrupture.merch')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show2} checked={show2}
                                onChange={(e) => setshow2(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.Enseignes')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show3}
                                checked={show3}
                                onChange={(e) => setshow3(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('dashboard.Magasin')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show4}
                                checked={show4}
                                onChange={(e) => setshow4(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapportrupture.codeproduit')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show5}
                                checked={show5}
                                onChange={(e) => setshow5(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.CODEABARRE')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show6}
                                checked={show6}
                                onChange={(e) => setshow6(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.CODEMETI')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show7}
                                checked={show7}
                                onChange={(e) => setshow7(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('Produits.SousFamille')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show8}
                                checked={show8}
                                onChange={(e) => setshow8(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapportrupture.produit')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show9}
                                checked={show9}
                                onChange={(e) => setshow9(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.stockDepot')}</span>
                        </label>
                    </div>
                </div>
                : null}
            <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '600px' }}>

                <table id="table-to-xls" className="table  table-bordered  mt-2">
                    <thead className="text-center bg-azure-lighter" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                        <tr>
                            <th className="text-center" colspan={(show1 ? 1 : 0) + (show2 ? 1 : 0) + (show3 ? 1 : 0) + (show4 ? 1 : 0) + (show5 ? 1 : 0) + (show6 ? 1 : 0) + (show7 ? 1 : 0) + (show8 ? 1 : 0) + (show9 ? 1 : 0)}></th>
                            {datess?.map((items, k) => (
                                <th className="text-center b-top b-left  b-right fs-10"> {moment(items).format("DD/MM/YYYY")} </th>
                            ))}
                        </tr>

                        <tr>
                            {show1 ? <th className="w-15p fs-10"> {t('rapportrupture.merch')}</th> : null}
                            {show2 ? <th className="w-15p fs-10">{t('Produits.Enseigne')}</th> : null}
                            {show3 ? <th className="w-15p fs-10">{t('dashboard.Magasin')}</th> : null}
                            {show4 ? <th className="w-15p fs-10">{t('rapportrupture.codeproduit')}</th> : null}
                            {show5 ? <th className="w-15p fs-10">{t('rapports.CODEABARRE')} </th> : null}
                            {show6 ? <th className="w-15p fs-10">{t('rapports.CODEMETI')}</th> : null}
                            {show7 ? <th className="w-15p fs-10">{t('Produits.SousFamille')} </th> : null}
                            {show8 ? <th className="w-15p fs-10">{t('rapportrupture.produit')} </th> : null}
                            {show9 ? <th className="w-15p fs-10">{t('rapports.stockDepot')} </th> : null}
                            {datess?.map((items, k) => (
                                <th className="text-center b-left b-right fs-10 ">{t('rapports.QUANTITE')}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='bg-white'>
                        {listeGrouped?.map((items, keys) => (
                            <tr key={keys} className="fs-10 text-center">
                                {show1 ? <td> {items.nom}</td> : null}
                                {show2 ? <td> {items.enseigne}</td> : null}
                                {show3 ? <td> {items.nompdv}</td> : null}
                                {show4 ? <td> {items.produit_code}</td> : null}
                                {show5 ? <td> {items.code_a_barre}</td> : null}
                                {show6 ? <td> {items.code_meti}</td> : null}
                                {show7 ? <td> {items.sousfamilles}</td> : null}
                                {show8 ? <td> <a href='#' className='text-primary' data-toggle="modal" data-target="#exampleModal" data-backdrop="static" data-keyboard="false" onClick={() => { setOk(true); setproduit_code(items.produit_code); setpdv(items.pdv_code) }}>{items.libelle}</a></td> : null}
                                {show9 ? <td> {items.stock === null ? '-' : items.stock}</td> : null}

                                {datess?.map((i, k) => (
                                    <td key={k} className={items.stock === null && findOnListe(items.produit_code, items.enseigne_code, items.pdv_code, i) === 0 ? "bg-yellow-lighter" : findOnListe(items.produit_code, items.enseigne_code, items.pdv_code, i) === 0 && items.stock > 0 ? 'bg-red-lighter' : ((findOnListe(items.produit_code, items.enseigne_code, items.pdv_code, i) > 0 ? "bg-green-lighter" : "bg-gray-lighter"))}> {findOnListe(items.produit_code, items.enseigne_code, items.pdv_code, i) > 0 || findOnListe(items.produit_code, items.enseigne_code, items.pdv_code, i) === 0 ? findOnListe(items.produit_code, items.enseigne_code, items.pdv_code, i) : "-"}  </td>
/*                                     <td key={k} className={i === moment(items.date).format("YYYY-MM-DD") ? (items.qte === "0" && items.stock === null ? "bg-yellow-lighter" : items.qte === "0" && items.stock > 0 ? 'bg-red-lighter' : "bg-green-lighter") : "bg-gray-lighter"} > {i === moment(items.date).format("YYYY-MM-DD") ? items.qte : '-'}</td>
 */                                ))}

                            </tr>
                        ))}
                    </tbody>
                    <thead className="text-center bg-azure-lighter mt-3" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                        <tr>
                            <th colSpan={10 + datess?.length}>{t('rapports.PRODUITSNONSAISIES')} </th>
                        </tr>
                        <tr>
                            <th className="w-15p fs-10">{t('Produits.Enseigne')}</th>
                            <th className="w-15p fs-10">{t('rapportrupture.codeproduit')}</th>
                            <th className="w-15p fs-10">{t('rapports.CODEABARRE')} </th>
                            <th className="w-15p fs-10">{t('rapports.CODEMETI')}</th>
                            <th className="w-15p fs-10">{t('Produits.SousFamille')} </th>
                            <th className="w-15p fs-10" colSpan={datess?.length + 4}>{t('rapportrupture.produit')} </th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                    {console.log(listeProdNSaisie)}
                        {listeProdNSaisie?.map((items, keys) => (
                            <tr key={keys} className='bg-gray-lighter fs-10' >
                                <td>{items.enseigne}</td>
                                <td>{items.produit_code}</td>
                                <td>{items.code_a_barre}</td>
                                <td>{items.code_meti}</td>
                                <td>{items.sousfamilles}</td>
                                <td colSpan={datess?.length + 4}>{items.produit}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/** Tableau Produits Non Saisi  */}
                <table id="table-to-xls" className="table  table-bordered  mt-2">
                </table>
            </div>
            {ok ? <ModalHistoriqueProduit setOk={setOk} produit_code={produit_code} pdv_code={pdv} t={t} />
                : null}
        </div>
    )
}
