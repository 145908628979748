/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast, ToastContainer } from 'react-toastify'
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { addCodeMeti, getListeCodeMETI, getSelctEnseignes, getSelectProduits } from '../../../services/Produits/ProduitsAPI'
import { SearchItems } from '../../../utils/SearchUtils'
import AlerteDeleteCode from './AlerteDeleteCode'
import ModalEditCodeMeti from './ModalEditCodeMeti'
import ModalImportExcel from './ModalImportExcel'
import { myRnId } from '../../../utils/DateUtils'

export default function CodeMETI() {


    const enseigne = useRef();
    const produits = useRef();
    //DELETE
    const [index, setIndex] = useState(-1)
    const [id, setId] = useState(0)
    const [ok, setOk] = useState(false)

    //------ Formulaire -----------
    const [code, setCode] = useState("METI-"+myRnId())
    const [codeproduit, setcodeproduit] = useState(0)
    const [codeenseigne, setcodeenseigne] = useState(0)
    //Liste Code METI ---------------------
    const [listeMETI, setlisteMETI] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //----------- LISTE PRODUITS -----------
    const [listeProduits, setListeProduits] = useState([])
    const [listeEnseignes, setlisteEnseignes] = useState([])
    //----------- REFRENCES  --------------------
    const inputEl = useRef("")
    //------------- Search  --------------------
    const [search, setSearch] = useState('')
    //Loader 
    const [Loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(30);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeMETI.slice(indexOfFirstPost, indexOfLastPost) : searchList.slice(indexOfFirstPost, indexOfLastPost)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeMETI, search)
            setSearchlist(newList)
        } else {
            setlisteMETI(saveList)
        }
    }

    useEffect(() => {
        setLoading(true)
        getListeCodeMETI()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteMETI(result?.data?.data)
                    setSaveList(result?.data?.data)

                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectProduits().then((result) => {
            if (result?.data?.success === 1) {
                setListeProduits(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnseignes(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        getListeCodeMETI()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteMETI(result?.data?.data)
                    setSaveList(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        setOk(false)

    }, [ok])

    // Handle Change Famille
    const handleEnseigne = (...args) => {
        setcodeenseigne(args[1].value)
    };

    // Handle Change Famille
    const handleProduits = (...args) => {
        setcodeproduit(args[1].value)
    };
    const save = () => {

        if (code !== "" && codeenseigne !== 0 && codeproduit !== 0) {
            const data = {
                code: code,
                produit_code: codeproduit,
                enseigne_code: codeenseigne
            }
            addCodeMeti(data)
                .then((result) => {
                    if (result?.data?.success === 0) {
                        toast(t('Zoning.Erreur'), { type: 'warning', position: 'top-right' })
                    } else if (result?.data.success === 1) {
                        toast("Code METI Ajouté avec succées ", { type: 'success', position: 'top-right' })
                        getListeCodeMETI()
                            .then((result) => {
                                if (result?.data?.success === 1) {
                                    setlisteMETI(result?.data?.data)
                                    setSaveList(result?.data?.data)

                                }
                            }).catch((err) => {
                                console.log(err)
                            });
                    }
                }).catch((err) => {

                });
        }
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Produits.Produits')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('rapportrupture.codeMeti')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/produits/cadeaux" className="btn btn-yellow btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-present mr-1" />
                            {t('Produits.Cadeaux')}
                        </a>
                        <a href="/produits/liste" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-list mr-1" />
                            {t('Produits.Listeproduits')}
                        </a>
                        <a href="/produits/Configurations" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="fe fe-download mr-1" />
                            {t('Produits.ConfiguraionProduits')}
                        </a>
                    </div>
                </div>

            </div>
            <ToastContainer />
            <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    {/** Tableaux CODE METI  */}
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-3 float-left"><i className='icon icon-list mr-1 text-azure' /> {t('Produits.ListeCODEMETI')}</div>
                            <button className='btn btn-green float-right fs-12' data-toggle="modal" data-target="#exampleModal3" ><i className="fa fa-file-excel-o mr-1" /> {t('Produits.Importer')} </button>

                        </div>
                        <div className="card-body">
                            <div className="search1 search-elements-sm mb-4 ">
                                <div className="form row no-gutters">
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <span className="input-group-append">
                                            <input
                                                type="text"
                                                ref={inputEl}
                                                value={search}
                                                onChange={() => getSearchTerm()}
                                                className="form-control br-tr-md-0 br-br-md-0 text-azure fs-12"
                                                id="text1"
                                                placeholder={t("Produits.cherchercodemeti")}
                                            />
                                            <i className="text-azure ti-search ml-2 mt-4" />
                                        </span>
                                    </div>


                                </div>
                            </div>
                            <div className="table-responsive product-datatable" style={{ overflow: "scroll", height: "400px" }}>
                                <table id="example" className="table table-striped table-bordered ">
                                    <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
                                        <tr>
                                            <th className="w-15p fs-12">{t('rapportrupture.codeMeti')}</th>
                                            <th className="wd-15p fs-12">{t('rapports.CODEP')}</th>
                                            <th className="wd-15p fs-12">{t('rapports.PRODUIT')}</th>
                                            <th className="wd-15p fs-12">{t('Produits.Enseigne')}</th>
                                            <th className="wd-15p fs-12">{t('Magasins.ACTION')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {currentListe?.map((items, keys) => (
                                            <tr key={keys} className={
                                                keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                            }>
                                                <td >{items.code} </td>
                                                <td >{items.produit_code} </td>
                                                <td >{items.produits} </td>
                                                <td >{items.enseignes} </td>
                                                <td style={{ cursor: "pointer" }}>
                                                    <i className="  icon icon-note text-azure mr-1" data-toggle="modal" data-target="#modal-editer" onClick={() => { setId(items.id); setIndex(keys) }} />
                                                    <i className="  ti-trash text-danger" data-toggle="modal" data-target="#modal-notification" onClick={() => { setId(items.id); setIndex(keys) }} />
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                            <div className="float-right mt-3">
                                <PaginationTable
                                    postsPerPage={stocksPerPage}
                                    totalPosts={listeMETI.length}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    {/** Card add produits  */}
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-3 float-left"><i className='icon icon-plus mr-1 text-azure' /> {t('Produits.AjouterCODEMETI')} </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label className="form-control-label">{t('rapportrupture.codeMeti')} <span className="text-danger">*</span></label>
                                <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">{t('rapports.PRODUIT')}  <span className="text-danger">*</span></label>
                                <SelectSearch
                                    options={listeProduits}
                                    search
                                    ref={produits}
                                    onChange={handleProduits}
                                    filterOptions={fuzzySearch}
                                    emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')} </div>}
                                    placeholder={t('Produits.choisirProduit')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">{t('Produits.Enseigne')}  <span className="text-danger">*</span></label>
                                <SelectSearch
                                    options={listeEnseignes}
                                    search
                                    ref={enseigne}
                                    onChange={handleEnseigne}
                                    filterOptions={fuzzySearch}
                                    emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                    placeholder={t('Magasins.Choisirenseigne')}
                                />
                            </div>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-primary col-12 " onClick={() => save()}><i className="ti-save mr-2" />  {t('Produits.Ajouter')} </button>
                        </div>
                    </div>
                    <ModalEditCodeMeti id={id} index={index} setOk={setOk} listeproduits={listeProduits} listeenseignes={listeEnseignes} t={t} />
                    <AlerteDeleteCode id={id} index={index} setOk={setOk} t={t} />
                    <ModalImportExcel setOk={setOk} t={t} />
                </div>
            </div>
        </div>
    )
}
