import moment from 'moment';
import React from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader';

export default function ListeCdeaux({ t, loader, liste, index, show, setindex, setShow, setuser, setdeb, setfin, loader2, listeOfData }) {
    return (
        <div className="card">
            <div className="card-header">
                <div className="card-title mt-3 text-azure float-left"><i className="icon icon-present mr-1" /> {t('Produits.ListeCadeaux')} </div>
                <button className='btn btn-azure float-right fs-12' data-toggle="modal" data-target="#exampleModal3" ><i className="fa fa-file-excel-o mr-1" /> {t('Produits.Importer')} </button>
            </div>
            <div className='card-body'>

                <div className="table-responsive  product-datatable">
                    <table className="table  table-bordered ">
                        <thead className="text-center ">
                            <tr>
                                <th> </th>
                                <th className="w-15p fs-12">{t('Produits.UTILISATEUR')}</th>
                                <th className="wd-15p fs-12"> {t('Routings.datedebut')}</th>
                                <th className="wd-15p fs-12">{t('Produits.DATEFIN')}</th>

                            </tr>
                        </thead>
                        {loader ? <SqaureLoader /> : (
                            <tbody className='text-center'>
                                {liste?.map((items, keys) => (
                                    <>
                                        <tr key={keys} className={
                                            keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                        }>
                                            <td className='fs-12'> {index === keys && show ? <i className='ti-angle-up text-primary' onClick={() => { setindex(-1); setShow(false); }} /> : <i className='ti-angle-down text-azure' onClick={() => { setindex(keys); setShow(true); setuser(items.user_code); setdeb(items.date_debut); setfin(items.date_fin) }} />} </td>
                                            <td>{items.nom} </td>
                                            <td>{moment(items.date_debut).format("DD-MM-YYYY")} </td>
                                            <td>{moment(items.date_fin).format("DD-MM-YYYY")} </td>

                                        </tr>
                                        {index === keys && show ?
                                            <tr>
                                                <td colSpan="10">
                                                    <table className="table table-striped table-hover">
                                                        <thead className="text-center ">
                                                            <th className="fs-12" >{t('Produits.Libelle')}</th>
                                                            <th className="fs-12" >{t('Produits.Ordre')}</th>
                                                            <th className="fs-12" >{t('Produits.Alaprochaine')}</th>
                                                            <th className="fs-12" >{t('Produits.Quantite')}</th>
                                                            <th className="fs-12" >{t('Produits.AvantPause')}</th>
                                                            <th className="fs-12" >{t('Produits.ApresPause')}</th>
                                                            <th className="fs-12" >{t('Magasins.Type')}</th>
                                                            <th className="fs-12" colSpan={2}>{t('rapports.ACTION')}</th>

                                                        </thead>
                                                        {loader2 ? <tbody className="text-center "><SqaureLoader /> </tbody> : (
                                                            <tbody className="text-center ">
                                                                {listeOfData()}
                                                            </tbody>
                                                        )}

                                                    </table>
                                                </td>
                                            </tr> : null
                                        }

                                    </>


                                ))}



                            </tbody>
                        )}
                    </table>
                </div>

            </div>
        </div>
    )
}
