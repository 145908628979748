import React from 'react'
import Chart from "react-apexcharts";
import SqaureLoader from '../../../components/Loader/SqaureLoader';

export default function Chartjs({options,loadingChart}) {


   
  return (
    <div> 
    {loadingChart? <SqaureLoader /> : 
    <div className="col-12">
          <div className="mixed-chart">
        <Chart
          options={options?.options}
          series={options?.series}
          type="pie"
          width={380}
        />
      </div>
    </div>}</div>
  )
}
