import _ from "lodash";
import { rapportSalesIssues } from "../../../services/Rapports/RapportsApi";

export   const traitement = (date_debut, date_fin , setproduit_conventions ,setproduit_ruptures , setHeaderGammes, setSaveEnseignes, setheaderEnseignes  ,settotalProduits , settotalRuptures , setLabels , produit_conventions , produit_ruptures  ,liste_1 , setSeries , setloading , setloadingChart) => {
    rapportSalesIssues(date_debut, date_fin)
      .then((result) => {
        if (result?.data?.success === 1) {
          var data = result?.data?.totalProduit;
          setproduit_conventions(result?.data?.totalConventions)
          setproduit_ruptures(result?.data?.totalProduitRD)
          // LISTE DES MARQUES
          var gammes = [];
          data?.map((i) => {
            gammes.push({
              gamme_code: i.gamme_code,
              gammes: i.gammes,
            });
          });
          // GAMMES
          setHeaderGammes(_.uniqBy(gammes, "gamme_code"));
          var enseigne = [];
          result?.data?.liste?.map((items) =>
            enseigne.push({
              value: items.enseigne_code,
              name: items.nom_enseigne,
            })
          );
          setSaveEnseignes(_.uniqBy(enseigne, "value"));
          setheaderEnseignes(_.uniqBy(enseigne, "value"));
          settotalProduits(data);
          //Rutpures
          settotalRuptures(result?.data?.Totalruptures);
          //CHARTS JS
          setLabels(["AV%", "OOS%"]);
          _.uniqBy(gammes, "gamme_code")?.map((items) =>
            result?.data?.liste?.map((i) => {
              var L = result?.data?.Totalruptures?.filter(
                (el) =>
                  el.enseigne_code === i.enseigne_code &&
                  el.gamme_code === items.gamme_code
              );
              if (L?.length > 0) {
                const L1 = data?.filter(
                  (el) => el.gamme_code === items.gamme_code
                ); // total produits dans la gamme
                var L3 =produit_conventions?.filter((el)=>el.enseigne_code === i.enseigne_code && el.gamme_code === items.gamme_code) //TOTAL CONVENTION
                var L2 =produit_ruptures?.filter((el)=>el.enseigne_code === i.enseigne_code && el.gamme_code === items.gamme_code) //TOTAL PRODUIT
                var rupture =
                  (parseFloat(L[0]?.total_ruptures / L1[0]?.total) ) *
                  100;
                var disponible =
                  (parseFloat(L[0]?.total_disponible / L1[0]?.total) 
                    ) *
                  100;
                  var ha = L2[0]?.total_produits>=L3[0]?.total? parseFloat((L2[0]?.total_produits - L3[0]?.total)/(L2[0]?.total_produits))*100 : 0

                liste_1.push({
                  rupture: rupture,
                  disponible: disponible,
                  ha: ha,
                  gamme_code: items.gamme_code,
                  enseigne_code: i.enseigne_code,
                });
              }
            })
          );
          const total_disponible = liste_1?.reduce(function (
            accumulateur,
            valeurCourante
          ) {
            return accumulateur + valeurCourante.disponible;
          },
          0);
          const total_rupture = liste_1?.reduce(function (
            accumulateur,
            valeurCourante
          ) {
            return accumulateur + valeurCourante.rupture;
          },
          0);
      /*     var ha = liste_1?.reduce(function (accumulateur, valeurCourante) {
            return accumulateur + valeurCourante.ha;
          }, 0); */

          setSeries([total_disponible, total_rupture]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
        setloadingChart(false);
      });
  };
