import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
const role=user?.result?.role_code
const user_code =user?.result?.code
/**
 * Get liste  : Ruptures
 * @returns 
 */
 export async function getListeRuptures(date1,date2, data) {
    try {
        const response = await axios.post(url + "ruptures/"+date1+"/"+date2+"/"+role+"/"+user_code,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Get liste  : Ruptures
 * @returns 
 */
 export async function getallRupturesEnseignes(year) {
    try {
        const response = await axios.get(url + "ruptures/enseignes/annee="+year+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error?.response?.status
    }
}
/**
 * Get liste  : Ruptures
 * @returns 
 */
 export async function getallRupturesProduits(year) {
    try {
        const response = await axios.get(url + "ruptures/produit/annee="+year+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error?.response?.status
    }
}
/**
 * Get liste  : Ruptures
 * @returns 
 */
 export async function getSelectMagasins() {
    try {
        const response = await axios.get(url + "magasins/select/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error?.response?.status
    }
}

/**
 * Get liste  : Ruptures
 * @returns 
 */
 export async function getallRupturesPDV(year) {
    try {
        const response = await axios.get(url + "ruptures/pdv/annee="+year+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error?.response?.status
    }
}
/**
 * Get liste  : Ruptures
 * @returns 
 */
 export async function groupedPdv() {
    try {
        const response = await axios.get(url + "ruptures/groupedPdv", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return(error.response.status)
    }
}

/**
 * Get liste  : Ruptures
 * @returns 
 */
 export async function groupedEnseigne() {
    try {
        const response = await axios.get(url + "ruptures/groupedEnseigne", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return(error.response.status)
    }
}
/**
 * Get liste  : Ruptures
 * @returns 
 */
 export async function groupedUser() {
    try {
        const response = await axios.get(url + "ruptures/groupedUser", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return(error.response.status)
    }
}
/**
 * Get liste  : Ruptures
 * @returns 
 */
export async function getPDV() {
    try {
        const response = await axios.get(url + "ruptures/groupedUser", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return(error.response.status)
    }
}