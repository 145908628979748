import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL


export async function getalldocumentations() {
    try {
        const response = await axios.get(url + "documentations/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Task
 * @returns 
 */
 export async function getListeTask(datedebut ,datefin) {
    try {
        const response = await axios.get(url + "task/getTaskByDate/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * UPDATE TASK
 * @param {*} code 
 * @param {*} data 
 * @returns 
 */
export async function updateTaskMerch(code ,data) {
    try {
        const response = await axios.patch(url + "task/"+code,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * UPDATE TASK
 * @param {*} code 
 * @param {*} data 
 * @returns 
 */
 export async function deleteTask(code) {
    try {
        const response = await axios.delete(url + "task/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Task
 * @returns 
 */
 export async function addTask(data) {
    try {
        const response = await axios.post(url + "task/",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
