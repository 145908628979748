/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import { getImages } from '../../services/Dashboard/FirstPage'
import { useTranslation } from 'react-i18next'

export default function ListeImages() {
    //-------------- Parma -------------------
    const { date } = useParams("")
    //-------------- Date -----------------
    const [today, settoday] = useState(moment(date).format('YYYY-MM-DD'))
    //----------- Liste Images 
    const [listeImages, setlisteImages] = useState([])
    //------------ Loader ---------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");



    const traitement = () => {
        getImages(today).then((result) => {
            if (result?.data?.success === 1) {
                setlisteImages(result?.data?.listeImages)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })

    }
    useEffect(() => {
        traitement()
    }, [])

    const onChangeDateFormat = () => {
        traitement()
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb mt-2">
                    <li className="breadcrumb-item"><a href="/images"><i className="fe fe-life-buoy mr-1" /> {t('contacts.images')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('listeImages')} </li>
                </ol>
            </div>
            <div className="row">

                {/** --------------- Tableau Magasins ----------------------- */}
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <div className='row mb-2'>
                        <div className='col-3'></div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                            <div className="input-group">
                                <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary" />
                                <span className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                                </span>
                            </div>
                        </div>
                        <div className='col-3'></div>

                    </div>
                </div>

                {loading ? <SqaureLoader /> : (
                    <>
                        <div className="col-lg-12 mt-3">
                        

                                    <div className="content row">
                                        <div className='row'>
                                            {listeImages?.map((items, keys) => (
                                                <div className='col-4' key={keys}>

                                                    <div className="card">

                                                        <a className="elem " data-lcl-thumb={items.path} data-lcl-txt={"Date " + moment(items.created_at).format("DD-MM-YYYY")} href={items.path} title={items.type}>
                                                            <img className="card-img-top" src={items.path} alt="Thumb-2" title="Image 01" />
                                                            <span className="img-responsive" data-image-src={items.path} />
                                                        </a>
                                                        <div className="card-body">
                                                            <p className="card-text text-center">{items.type}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <br />
                                    </div>
                                </div>
                          

                    </>
                )}
            </div>
        </div>
    )
}
