import React from 'react'
import SqaureLoader from '../../components/Loader/SqaureLoader'

export default function ModalDetailsImage({ listeImage, setshow, loading, t }) {

    return (
        <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {t('rapports.imagerayon')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" /> </span>

                    </div>
                    <div className="modal-body">
                        {loading ? <SqaureLoader /> : (
                            <div className="row">
                                <div className="card-body">
                                    {listeImage?.length === 0 ? <div className="row">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                            <div className="alert bg-purple mb-5 py-4" role="alert">
                                                <div className="d-flex">
                                                    <i className="icon icon-info mr-3 fs-30"></i>
                                                    <div>
                                                        <h4 className="alert-heading">{t('rapports.aucuneimage')} </h4>
                                                        <p> {t('rapports.aucuneimagerayon')}  </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : listeImage?.map((items, keys) => (
                                        <div className="product-item text-center" key={keys}>
                                            <img src={items.path === null ? "../assets/images/media/toys.png" : items.path} alt="img" />
                                        </div>
                                    ))}

                                </div>
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary float-right" data-dismiss="modal" onChange={() => setshow(false)}><i className="ti-close mr-1" />  {t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>

    )
}
