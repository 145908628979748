/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { listeRuptures } from '../../../services/Magasins/PdvApi'

export default function ModalListeRuptures({t,month,annee,pdv_code, setok1}) {
    //------------- LOADING ---------------------
    const [loading, setloading] = useState(true)
    //-------------- LISTE ------------------------
    const [listeRupture, setlisteRuptures] = useState([])

    useEffect(()=>{
        listeRuptures(month,annee,pdv_code).then((result) => {
                if(result?.data?.success===1){
                    setlisteRuptures(result?.data?.ruptures)
                }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloading(false)
        })
    },[month,annee])
  return (
    <div className="modal fade" id="exampleModal3" tabIndex={-1} role="dialog" aria-labelledby="exampleModal3" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content" style={{ width: "max-content" }}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModal3"> {t('sidebar.Ruptures')} : {month+"/"+annee} </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setok1(false)}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                {loading ? <SqaureLoader /> : (
                    <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '500px' }}>
                        <table className="table  table-bordered ">
                            <thead className="text-center bg-purple fs-12" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                <td className="wd-15p fs-10">{t('Produits.Enseigne')}</td>
                                <td className="wd-15p fs-10">{t('dashboard.Magasin')}</td>
                                <td className="wd-15p fs-10">{t('rapportrupture.produit')}</td>
                                <td className="wd-15p fs-10">{t('dashboard.Utilisateur')}</td>
                                <td className="wd-15p fs-10">{t('relevePrix.cause')}</td>

                            </thead>
                            <tbody className='fs-10 text-center'>
                                {listeRupture?.length === 0 ? 
                                <tr>
                                    <td colSpan={5} className="text-center text-danger"> {t('Zoning.Aucunresultat')} </td>
                                </tr>:listeRupture?.map((items, keys) => (
                                    <tr key={keys}>
                                        <td className="wd-15p fs-10">{items.nom_enseigne}</td>
                                        <td className="wd-15p fs-10">{items.nom_pdv}</td>
                                        <td className="wd-15p fs-10">{items.nom_produit}</td>
                                        <td className="wd-15p fs-10">{items.nom_user}</td>
                                        <td className="wd-15p fs-10">{items.cause}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            {/*    <div className='modal-footer '>
                <button className='btn btn-secondary float-right ' onClick={() => setok1(false)}><i className='ti-close mr-1'/> Fermer</button>
            </div> */}
        </div>
    </div>
</div>
  )
}
