/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useParams ,useHistory} from 'react-router';
import { ToastContainer } from 'react-toastify'
import { listeEnseignes } from '../../services/Magasins/PdvApi';
import { getImageByCode, getListeRayons } from '../../services/Produits/ProduitsAPI';
import { getMerch } from '../../services/Users/UsersApi';
import { SearchItems } from '../../utils/SearchUtils';
import ListePdvLocalisation from '../Magasins/ListePdvLocalisation';
import PaginationTable from '../../components/Pagination/PaginationTable';
import ModalDetailsImage from './ModalDetailsImage';
import { useTranslation } from 'react-i18next';

export default function ListeRayons() {

    //USE REFERENCES 
    const inputEl = useRef("")
   //------ History -----------------
    const history = useHistory()
    //PARAMETRE
    const { debut, fin } = useParams()
    //------ DATE SEARCH (debut / fin) -----------
    const [dateDebut, setDateDebut] = useState(debut);
    const [dateFin, setDateFin] = useState(fin);
    //----------- LISTE -----------------------
    const [liste, setliste] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [localisation, setlocalisation] = useState([])
    const [saveList, setSaveList] = useState([])
    const [listeImage, setlisteImage] = useState([])
    const [codeUser, setcodeUser] = useState("")
    const [listeUsers, setlisteUsers] = useState([])
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [pdv, setpdv] = useState([])
    //Search 
    const [search, setSearch] = useState('')
    const [ok, setok] = useState(false)
    const [show, setshow] = useState(false)
    const [index, setindex] = useState("")

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(36);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? liste?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)
    //Loader 
    const [loading, setloading] = useState(true)
    const [loadingImage, setloadingImage] = useState(true)
  //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");

    const traitement = () => {
        getListeRayons(dateDebut, dateFin)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.liste)
                    setSaveList(result?.data?.liste)
                    setlocalisation(result?.data?.localisation)
                }
                setloading(false)

            }).catch((err) => {
                console.log(err)
            });
    }

    useEffect(() => {
        listeEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setpdv(result?.data?.data)
                    console.log(result.data.data)

                }
            }).catch((err) => {
                console.log(err)
            });
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteUsers(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        traitement()
    }, [])
    
    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(liste, search)
            setSearchlist(newList)
        } else {
            setliste(saveList)
        }
    }

    const onClickImage = (code, type) => {
        getImageByCode(code, type)
            .then((result) => {
                setloadingImage(true)
                if (result?.data?.success === 1) {
                    setlisteImage(result?.data?.liste)
                }
                setloadingImage(false)

            }).catch((err) => {
                console.log(err)
            });
    }
    
    const searchDate = ()=>{
        redirection()
    }
    
    /**
   * Redirect function 
   */
     const redirection = () => {
        history.push({
            pathname:
                "/rayons/datedebut=" + dateDebut + "&datefin=" + dateFin,
        })
        traitement()

    }
    const onChangeEnseigne =(value)=>{
        setcodeEnseigne(value)
        if (value !== "") {
            console.log(liste)
            const l = saveList?.filter((element) => element.enseigne_code === value)
            setliste(l)
        } else {
            setliste(saveList)
        }
    }
  const showDetailsUsers =(code) =>{
      console.log(code)
        setcodeUser(code)
        if (code !== "") {
            const l = saveList?.filter((element) => element.user_code === code)
            setliste(l)
        } else {
            setliste(saveList)
        }
    }
    
    return (
        <div>
            {/** ----------------------------- Header Page Stock --------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.rayons')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapportVentes.listerayons')}  </li>
                </ol>
            </div>
            <ToastContainer />
            {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" />  {t('rapportVentes.listerayons')} 
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                        <div className='row mb-2'>
                        <div className='col-1'></div>
                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12'>
                            <div className="input-group">
                            <input className="form-control"  type="texte" placeholder="Rechercher ... " ref={inputEl} value={search} onChange={() => getSearchTerm()}/>
                            <input className="form-control fc-datepicker" type="date" value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                            <input className="form-control fc-datepicker" type="date" value={dateFin} onChange={(e) => setDateFin(e.target.value)} />
                            <select className="form-control  br-md-0  fs-12 text-primary" value={codeEnseigne} onChange={(e) => onChangeEnseigne(e.target.value)}>
                                        <option value={""} >{t('rapports.Enseignes')}</option>
                                        {pdv?.map((items, keys) => (
                                            <option key={keys} value={items.code}>{items.nomenseigne}</option>
                                        ))}
                                    </select>
                                    <button  className="btn btn-primary" type="button" onClick={()=>searchDate()}><i className='ti ti-search' /></button>
                                <button className="btn btn-yellow" type="button"><i className='ti ti-close' /></button>
                             </div>                           
        
                            </div>
                        </div>
                        <div className='col-1'></div>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>

                            <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                {listeUsers?.map((items, keys) => (
                                    <button className="btn tag bg-purple" key={keys} onClick={() => showDetailsUsers(items.value)}> {items.name}</button>
                                ))}
                            </div>
                        </div>
                           
                          
                            <h6 className=" mt-2 text-muted" style={{ fontSize: "12px" }}>  <span className="text-info"> <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span> {t('rapportVentes.messageRayons')} </h6>

                        </div>
                    </div>
                </div>
            </div>
            {/** ------------------BODY OF PAGE ----------------------------- */}
            <div className="row">
                <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12" >
                    {liste?.length === 0 ?
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                <div className="alert bg-purple mb-5 py-4" role="alert">
                                    <div className="d-flex">
                                        <i className="icon icon-info mr-3 fs-30"></i>
                                        <div>
                                            <h4 className="alert-heading">{t('Zoning.Aucunresultat')} ! </h4>
                                            <p> {t('rapportVentes.aucunearticlerayon')} {moment(dateDebut).format("DD-MM-YYYY") + " - " + moment(dateFin).format("DD-MM-YYYY")} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : (<>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="table-responsive  product-datatable">
                                            <table id="example" className="table  table-bordered ">
                                                <thead className="text-center">
                                                    <tr>
                                                        <td colSpan="14" className="text-center bg-purple text-white" > Total : {liste.length}  Dans la  page : {currentPage} , Promo Par page : {stocksPerPage} </td>
                                                    </tr>
                                                    <tr className="text-center">
                                                        <th className="fs-12" >{t('rapportrupture.date')} </th>
                                                        <th className="fs-12">{t('rapportrupture.merch')}</th>
                                                        <th className="fs-12">{t('Produits.Enseigne')} </th>
                                                        <th className="fs-12">{t('dashboard.Magasin')}
                                                            <span className="text-info ml-1"
                                                                data-container="body" data-toggle="popover" data-popover-color="default" data-placement="top" title="Cliquez sur le nom du magasin pour visualiser les détails du rayon !!"
                                                            >
                                                                <i className="icon icon-exclamation mr-1" />
                                                            </span> </th>
                                                        <th className="fs-12">{t('rapportrupture.produit')}</th>
                                                        <th className="fs-12">{t('rapports.prix')}</th>
                                                        <th className="fs-12">{t('rapports.QUANTITE')}</th>
                                                        <th className="fs-12">{t('rapports.facing')}</th>
                                                        <th className="fs-12">{t('rapports.remise')} (%) </th>
                                                        <th className="fs-12">{t('rapports.gratuite')}</th>

                                                    </tr>
                                                </thead>
                                                <tbody className="text-center">
                                                    {currentListe?.map((items, keys) => (
                                                        <>
                                                            <tr key={keys}>
                                                                <td className="fs-12">{moment(items.date).format("DD MM YYYY")}</td>
                                                                <td className="fs-12">{items.user}</td>
                                                                <td className="fs-12">{items.enseigne}</td>
                                                                <td className="fs-12"><a className="text-primary" onClick={() => { setok(!ok); setindex(keys) }}> {items.magasin} </a> </td>
                                                                <td className="fs-12">{items.produits}</td>
                                                                <td className="fs-12">{items.prix}</td>
                                                                <td className="fs-12">{items.qte}</td>
                                                                <td className="fs-12">{items.facing}</td>
                                                                <td className="fs-12">{items.remise} </td>
                                                                <td className="fs-12">{items.gratuite} </td>
                                                            </tr>
                                                            {ok && index === keys ?
                                                                <tr>
                                                                    <td colSpan="10">
                                                                        <table className="table  table-bordered ">
                                                                            <thead className="text-center">
                                                                                <th className="fs-12" >{t('rapports.tg')}</th>
                                                                                <th className="fs-12" >{t('rapports.tr')}</th>
                                                                                <th className="fs-12" >{t('rapports.sp')}</th>
                                                                                <th className="fs-12" >{t('rapports.rayon')}</th>
                                                                                <th className="fs-12" >{t('rapports.ac')}</th>
                                                                                <th className="fs-12" >{t('rapports.jeux')}</th>
                                                                                <th className="fs-12" >{t('rapports.gadget')}</th>
                                                                                <th className="fs-12" >{t('rapports.animation')}</th>
                                                                                <th className="fs-12" >{t('rapports.degustation')}</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                <td className="fs-12">{parseInt(items.tg) !== 0 ? <span className="text-success" data-toggle="modal" data-target="#exampleModal" style={{ fontWeight: "bold" }} onClick={() => { onClickImage(items.code, "tg"); setshow(true) }}>{items.tg}</span> : items.tg}  </td>
                                                                                <td className="fs-12">{parseInt(items.tr) !== 0 ? <span className="text-success" data-toggle="modal" data-target="#exampleModal" style={{ fontWeight: "bold" }} onClick={() => { onClickImage(items.code, 'tr'); setshow(true) }}>{items.tr}</span> : items.tr}  </td>
                                                                                <td className="fs-12">{parseInt(items.sp) !== 0 ? <span className="text-success" data-toggle="modal" data-target="#exampleModal" style={{ fontWeight: "bold" }} onClick={() => { onClickImage(items.code, 'sp'); setshow(true) }}>{items.sp}</span> : items.sp}  </td>
                                                                                <td className="fs-12">{parseInt(items.rayon) !== 0 ? <span className="text-success" data-toggle="modal" data-target="#exampleModal" style={{ fontWeight: "bold" }} onClick={() => { onClickImage(items.code, 'rayon'); setshow(true) }}>{items.rayon}</span> : items.rayon}  </td>
                                                                                <td className="fs-12">{parseInt(items.ac) !== 0 ? <span className="text-success" data-toggle="modal" data-target="#exampleModal" style={{ fontWeight: "bold" }} onClick={() => { onClickImage(items.code, 'ac'); setshow(true) }}>{items.ac}</span> : items.ac}  </td>
                                                                                <td className="fs-12">{parseInt(items.jeux) !== 0 ? <span className="text-success" data-toggle="modal" data-target="#exampleModal" style={{ fontWeight: "bold" }} onClick={() => { onClickImage(items.code, 'jeux'); setshow(true) }}>{items.jeux}</span> : items.jeux}  </td>
                                                                                <td className="fs-12">{parseInt(items.gadget) !== 0 ? <span className="text-success" data-toggle="modal" data-target="#exampleModal" style={{ fontWeight: "bold" }} onClick={() => { onClickImage(items.code, 'gadget'); setshow(true) }}>{items.gadget}</span> : items.gadget}  </td>
                                                                                <td className="fs-12">{parseInt(items.animation) !== 0 ? <span className="text-success" data-toggle="modal" data-target="#exampleModal" style={{ fontWeight: "bold" }} onClick={() => { onClickImage(items.code, 'animation'); setshow(true) }}>{items.animation}</span> : items.animation}  </td>
                                                                                <td className="fs-12">{parseInt(items.degustation) !== 0 ? <span className="text-success" data-toggle="modal" data-target="#exampleModal" style={{ fontWeight: "bold" }} >{items.degustation}</span> : items.degustation}  </td>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr> : null}
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="float-right">
                                                <PaginationTable
                                                    postsPerPage={stocksPerPage}
                                                    totalPosts={search.length < 1 ? liste.length : searchList.length}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        )}
                </div>
                <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12" >
                    <ListePdvLocalisation listeEnseinges={localisation} loading={loading}  t={t}/>

                </div>
            </div>
            {show ? <ModalDetailsImage listeImage={listeImage} setshow={setshow} loading={loadingImage}  t={t}/> : null}
        </div>
    )
}
