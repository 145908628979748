/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import PaginationTable from '../../../components/Pagination/PaginationTable';
import { getDelegationByCode, getListedelegations } from '../../../services/Zoning/ZoningApi';
import { SearchItems } from '../../../utils/SearchUtils';
import ModalAddDelegations from './ModalAddDelegations';
import ModalDeleteDelegation from './ModalDeleteDelegation';
import ModalUpdateDelegation from './ModalUpdateDelegation';

export default function ListeDelegation({ t }) {
    //Liste Delegation 
    const [listeDelegation, setListeDelegation] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //Search 
    const [search, setSearch] = useState('')
    //USE REF
    const inputEl = useRef("")
    // Loader
    const [Loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(50);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeDelegation?.slice(indexOfFirstPost, indexOfLastPost) : searchList.slice(indexOfFirstPost, indexOfLastPost)
    //Modal items
    const [show, setShow] = useState(true)
    const [index, setIndex] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    const [showEdit, setShowEdit] = useState(false)

    //Modal DELete
    const [OK1, setOK1] = useState(false)
    const [deleteCode, setdeleteCode] = useState("")
    const [indexDelete, setindexDelete] = useState(-1)
    useEffect(() => {
        //Get all lsite regions 
        setLoading(true)
        getListedelegations()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListeDelegation(result?.data?.data)
                    setSaveList(result?.data.data)
                }
            }).catch((err) => {
                console.log(err)
            })
            .finally(() => setLoading(false))
    }, [OK1])
    //ONChange Pays
    useEffect(() => {
        if (show === false || showEdit === true) {
            getListedelegations()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListeDelegation(result?.data?.data)
                    setSaveList(result?.data.data)
                }

            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                    setShow(true);
                });
        }
    }, [show, showEdit]);
    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeDelegation, search)
            setSearchlist(newList)
        } else {
            setListeDelegation(saveList)
        }
    }
    const changedetails = (code) => {
        getDelegationByCode(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setObject(result?.data?.data[0])

                }
            }).catch((err) => {
                console.log(err)
            })
    }
    return (
        <div className="row">
            <div className="col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title mt-3 float-left  fs-14" > <i className='icon icon-map text-azure mr-2' /> {t('Zoning.ListeDelegations')}</div>
                        <button className="btn btn-azure float-right  fs-12" data-toggle="modal" data-target="#exampleModal7"> <i className="icon icon-plus mr-1" /> {t('Produits.Ajouter')} </button>
                    </div>
                    <div className="card-body" >
                        <div className="search1 search-elements-sm mb-3 ">
                            <div className="form row no-gutters">
                                <div className="col-7"></div>
                                <div className="  col-xl-5 col-lg-5 col-md-5 mb-0">
                                    <span className="input-group-append">
                                        <input type="text" ref={inputEl} value={search}
                                            onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-azure fs-12" id="text1" placeholder="Chercher un gouvernorat" />
                                        <i className='text-azure ti-search ml-2 mt-4' />

                                    </span>
                                </div>

                            </div>
                        </div>
                        {Loading ? <SqaureLoader /> : (
                            <div className="table-responsive product-datatable" style={{ overflow: 'auto', height: '300px' }}>
                                <table id="example" className="table table-striped table-bordered ">
                                    <thead className="text-center"  style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                        <tr>
                                            <th className="w-15p fs-12">{t('rapports.code')}</th>
                                            <th className="wd-15p fs-12">{t('Produits.Libelle')}</th>   
                                            <th className="wd-20p fs-12">{t('Zoning.Gouvernorat')}</th>
                                            <th className="wd-15p fs-12">{t('Routings.editer')}</th>

                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {currentListe?.map((items, keys) => (
                                            <tr key={keys}  className={keys%2===1 ? "fs-12 bg-azure-lightest" :"fs-12"}>
                                                <td>{items.code} </td>
                                                <td>{items.libelle} </td>
                                                <td>{items.gouvernorats === null ? "-" : items.gouvernorats} </td>
                                                <td style={{ cursor: "pointer" }}>
                                                    <a data-toggle="modal" data-target="#updateDelegation" onClick={() => {
                                                        changedetails(items.code);
                                                        setCode(items.code); setIndex(keys);setShowEdit(true)
                                                    }}><b> <i className="icon icon-note text-azure" /></b> </a> 
                                            <a   data-toggle="modal" data-target="#modal-delete-delegation" onClick={() => { setdeleteCode(items.code); setindexDelete(keys) }}><i className="icon icon-trash text-danger"/></a>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                    </div>
                    <div className='card-footer'>
                        <div className='float-right'>
                            <PaginationTable
                                postsPerPage={stocksPerPage}
                                totalPosts={listeDelegation.length}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>
                    <ModalDeleteDelegation deleteCode={deleteCode} indexDelete={indexDelete} setOK1={setOK1} t={t} />

                    <ModalAddDelegations setShow={setShow} t={t}/>
                    {showEdit ? <ModalUpdateDelegation code={code} index={index} object={object} setShowEdit={setShowEdit}  setShow={setShow} t={t} /> :null}
                    {/* table-wrapper */}
                </div>
            </div>
        </div>

    )
}
