/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getRupturesProduits } from '../../../services/Rapports/RapportsApi'
import { useTranslation } from 'react-i18next';

export default function ListeProduitsRuptures() {
    //------ History -----------------
    const history = useHistory()
    //------ PARAMS ------------------
    const { pdv_code, date, user_code } = useParams('')
    const [listeRuptures, setlisteRuptures] = useState([])
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getRupturesProduits(user_code, date, pdv_code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteRuptures(result?.data?.listeruptures)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [])

    return (

        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.Rapports')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">  {t('rapports.RapportJournalier')} </li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.rapportProduit')}:{pdv_code} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <button className="btn bg-primary " onClick={() => history.goBack()}>
                            <i className="fe fe-arrow-left" />
                            {t('rapports.retour')}
                        </button>

                    </div>
                </div>
            </div>

            <div className='card'>
                <div className='card-body'>
                    {/** Tableau rupture Produits  */}
                    {loading ? <SqaureLoader /> : (
                        <div className="table-responsive  product-datatable">
                            <table className="table  table-bordered ">
                                <thead className="text-center bg-azure-lighter">
                                    <tr>
                                        <th className="w-15p fs-12">{t('rapports.code')}</th>
                                        <th className="w-15p fs-12">{t('rapports.CODEABARRE')}</th>
                                        <th className="wd-15p fs-12">{t('contacts.produit')}</th>
                                        <th className="wd-15p fs-12">{t('dashboard.Enseigne')}</th>
                                        <th className="wd-15p fs-12">{t('relevePrix.cause')}</th>
                                        <th className="wd-15p fs-12">{t('rapportrupture.etat')}</th>

                                    </tr>
                                </thead>
                                <tbody className='text-center fs-12'>
                                    {listeRuptures.length === 0 ?
                                        <tr>
                                            <td colSpan={5} className='text-center text-danger'> {t('rapports.aucuneinfo')}
                                                <hr className='hr1' />
                                                <img src="../assets/images/photos/no.png" alt='noData' style={{ height: "200px" }} />
                                            </td>
                                        </tr> :
                                        listeRuptures?.map((items, keys) => (
                                            <tr key={keys} >
                                                <td >  <a className='text-primary' href={"/produits/modifierProduits&id=" + items.produit_code}>{items.produit_code}  </a></td>
                                                <td > {items.code_a_barre}</td>
                                                <td > {items.nom_produit}</td>
                                                <td > {items.nom_enseigne}</td>
                                                <td > {items.cause}</td>
                                                <td className='fs-10' ><div className=" mb-0" style={{ textAlign: "center", display: "inline-flex" }}>
                                                            <div className={items.etat_repture === "0" ? "w-3 h-3 bg-success text-center " : "w-3 h-3 bg-danger text-center "} />                                                            <div>
                                                            </div>
                                                        </div>
                                                        </td>

                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}
