/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import SqaureLoader from "../../../components/Loader/SqaureLoader";
import { TileLayer, MapContainer, Popup } from "react-leaflet";
import Marker from "react-leaflet-enhanced-marker";
import "leaflet/dist/leaflet.css";

import "./map.css";
//import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export default function MapRoutes({  listemagasins , loading , t}) {

    const [center, setCenter] = useState({
        lat: isNaN(listemagasins[0]?.latitude) ? "36.8842439" : parseFloat(listemagasins[0]?.latitude),
        lng: isNaN(listemagasins[0]?.logitude)? "10.1625628" : parseFloat(listemagasins[0]?.logitude),
    });
    const ZOOM_LEVEL = 8;
    const mapRef = useRef();
    return (
        <div >
            <div className="left-container" style={{position:"sticky"}}>
                {loading ? <SqaureLoader /> : (
                    <MapContainer center={center} zoom={ZOOM_LEVEL} map={mapRef} style={{height:"800px"}}>
                        <TileLayer
                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
                        />
                        {listemagasins?.map((items, index) => (
                            <Marker position={[ (items?.latitude === null || items?.latitude ===undefined || isNaN(items?.latitude)) ? -1: parseFloat(items?.latitude), (items?.logitude === null || items?.logitude ===undefined || isNaN(items?.logitude)) ? -1 : parseFloat(items?.logitude)]} icon={<i className="ion-location  fa-3x"  style={{color: items.color !== null ? items.color : "#ff6600" }}/>} key={index} className="marker" >
                                <Popup>
                                    <b>
                                    {t('dashboard.Magasin')+" : "+items?.magasin} 
                                    <hr className='hr1' />
                                    {t('rapports.code')+" : " }<a href={"/pdv/historique&code=" + items.pdv_code}> {items?.pdv_code} </a>  

                                    </b>
                                </Popup>
                            </Marker>
                        ))}
                    </MapContainer>

                )}

            </div>
        </div>
    )
}
