/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getSelectMagasins } from '../../../services/Ruptures/ruptureAPI'
//------------------- tools TO SEARCH INPUT -----------------
import SelectSearch, { fuzzySearch } from 'react-select-search'
//-------------------- ALERT TOOLS ---------------------------
import { toast, ToastContainer } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { getSelctEnseignes } from '../../../services/Produits/ProduitsAPI'
import { getSelectZone } from '../../../services/Zoning/ZoningApi'
import { getMerch } from '../../../services/Users/UsersApi'
import { getRapportRupture2Post } from '../../../services/Rapports/RapportsApi'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import TableauRupture2 from './TableauRupture2'

export default function RapportFlower() {

    //--------- PARAMETERE -------------
    const { datedebut, datefin } = useParams('')
    //------ History -----------------
    const history = useHistory()
    //---------- FORMULAIRE --------------
    const [date_d, setdate_d] = useState(datedebut)
    const [date_f, setdate_f] = useState(datefin)
    const [codeUser, setcodeUser] = useState("")
    const [codezone, setcodezone] = useState("")
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [codePdv, setcodePdv] = useState("")
    const [valeurEtat, setvaleurEtat] = useState("")
    //---- LISTE DES MERCHANDISEURS ----------------
    const [listeUsers, setlisteUsers] = useState([])
    //-----LISTE DES PDVS ----------------------
    const [listePdvs, setlistePdvs] = useState([])
    const [savePdvs, setsavePdvs] = useState([])
    //-----LISTE DES PDVS ----------------------
    const [listeEnseignes, setlisteEnseignes] = useState([])
    //------- LISTE ZONES ----------------
    const [listeZones, setlisteZones] = useState([])
    /**
     * Tableau ruptures 2
     */
    const [listeT, setlisteT] = useState([])
    const [listeProdNSaisie, setlisteProdNSaisie] = useState([])
    const [listeGrouped, setlisteGrouped] = useState([])
    //-------------- ACTION ON SEARCH FILTRE ----------------
    const [ok, setok] = useState(false)
    //---------- SEARCH REFERENCES ---------------------
    const { searchZone, searchEnseigne, searchPDV, searchMerch } = useRef();
    //--------- Loader ------------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        /**
         * WEB SERVICES Magasins : Format( name , value )
         */
        getSelectMagasins()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlistePdvs(result?.data?.data)
                    setsavePdvs(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        /**
         * WEB SERVICES ENSEIGNES : FORMAT (name , value )
         */
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnseignes(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        /**
         * WEB SERVICES ZONES : FORMAT (name , value )
         */
        getSelectZone().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        /**
         * WEB SERVICES MERCHANDISEURS : FORMAT (name , value )
         */
        getMerch().then((result) => {
            if (result?.data?.success === 1) {
                setlisteUsers(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }, [])
    /**
     * ON change elements on INPUT SEARCH 
     * @param  {...any} args 
     */
    const handleChangeZone = (...args) => {
        setcodezone(args[1].value)
    };
    const handleChangeEnseigne = (...args) => {
        setcodeEnseigne(args[1].value)
        const liste = savePdvs?.filter((el) => el.enseigne_code === args[1].value)
        setlistePdvs(liste)
    };
    const handleChangePdv = (...args) => {
        setcodePdv(args[1].value)
    };
    const handleChangeUser = (...args) => {
        setcodeUser(args[1].value)
    };
    const handleChangeEtat = (e) => {
        setvaleurEtat(e.target.value)
    }
    /**
     * ON CHANGE DATE DEBUT / DATE FIN AND SELECT ELEMENTS
     */
    const onChangeDateFormat = () => {
        if (date_d > date_f) {
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })

        }/*  else if (codePdv === "") {
            toast(t('selectionnermagasin'), { type: "warning", position: "top-right" })
        }  */else {
            setloading(true)
            setok(true)
            redirection()
            const data = {}
            if (codePdv !== "") {
                Object.assign(data, {
                    pdv_code: codePdv
                })
            }
            if (codezone !== "") {
                Object.assign(data, {
                    zone_code: codezone
                })
            }
            if (codeEnseigne !== "") {
                Object.assign(data, {
                    enseigne_code: codeEnseigne
                })
            }
            if (codeUser !== "") {
                Object.assign(data, {
                    user_code: codeUser
                })
            }
            if (valeurEtat !== "" && valeurEtat !== "epuise") {
                Object.assign(data, {
                    etat_repture: valeurEtat
                })
            }
            getRapportRupture2Post(date_d, date_f, data)
                .then((result) => {
                    if(result?.data?.success === 1){
                        setlisteProdNSaisie(result?.data?.liste_conventions)
                        setlisteT(result?.data?.liste)
                        setlisteGrouped(result?.data?.listeGrouped)
                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setloading(false)
                })
        }

    }
    const back = () => {
        setok(false)
        setlisteProdNSaisie([])
        setlisteT([])
        setlisteGrouped([])
        setcodezone("")
        setcodePdv("")
        setcodeEnseigne("")
        setvaleurEtat("")
        setcodeUser("")
    }
    const redirection = () => {
        history.push({
            pathname:
                "/rapports/rupture2&datedebut=" + date_d + "&datefin=" + date_f,
        })
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" />  {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">  {t('rapports.rupture2')} </li>
                </ol>
            </div>
            <ToastContainer />

            {/** ------------- rapport : Rupture 1 ------------------------------ */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-equalizer text-azure mr-2" />{t('rapports.rupture2')}
                                <span className="float-right"> <i className="fe fe-search  text-azure" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control br-tr-md-0 br-br-md-0  fs-10 text-primary" placeholder={t('rapportrupture.rechercher')} />
                                        <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control br-tr-md-0 br-br-md-0  fs-10 text-primary" placeholder={t('rapportrupture.rechercher')} />
                                        <SelectSearch
                                            options={listeZones}
                                            search
                                            value={codezone}
                                            ref={searchZone}
                                            onChange={handleChangeZone}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('rapports.Zones')}
                                        />
                                        <SelectSearch
                                            options={listeEnseignes}
                                            search
                                            value={codeEnseigne}
                                            ref={searchEnseigne}
                                            onChange={handleChangeEnseigne}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('rapports.Enseignes')}
                                        />
                                        <SelectSearch
                                            options={listePdvs}
                                            search
                                            value={codePdv}
                                            ref={searchPDV}
                                            onChange={handleChangePdv}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('rapports.Magasins')}
                                        />
                                        <SelectSearch
                                            options={listeUsers}
                                            search
                                            value={codeUser}
                                            ref={searchMerch}
                                            onChange={handleChangeUser}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('dashboard.Merchandiseurs')}
                                        />
                                        <select className="form-control  br-md-0  fs-10 text-primary" value={valeurEtat} onChange={(e) => handleChangeEtat(e)}>
                                            <option value={""}>{t('rapports.Etat')}</option>
                                            <option value={"1"}>{t('rapports.Rupture')}</option>
                                            <option value={"0"}>{t('rapports.Disponible')}</option>
                                            <option value={"epuise"}>{t('rapports.Epuise')}</option>
                                        </select>
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                                            <button className="btn bg-yellow" type="button" onClick={() => back()}><i className="ti-close"></i></button>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h6 className=" mt-2 text-muted fs-12">
                                <span className="text-info">
                                    <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span> <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                                    {/*      Veuillez choisir un merchandiseur */}
                                    <div className="w-3 h-3 bg-danger  mr-2" />
                                    <div>
                                        <strong> {t('rapports.Rupture')} </strong>
                                        <br />
                                    </div>
                                    <div className="w-3 h-3 bg-green ml-2  mr-2" />
                                    <div>
                                        <strong> {t('rapports.Disponible')} </strong>
                                        <br />
                                    </div>
                                    <div className="w-3 h-3 bg-yellow ml-2  mr-2" />
                                    <div>
                                        <strong> {t('rapports.Epuise')} </strong>
                                        <br />
                                    </div>
                                    <div className="w-3 h-3 bg-gray ml-2  mr-2" />
                                    <div>
                                        <strong>{t('rapports.NonSaisie')} </strong>
                                        <br />
                                    </div>
                                </div>
                            </h6>

                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                {ok ?

                    (loading ? <SqaureLoader /> : <TableauRupture2 liste={listeT} listeProdNSaisie={listeProdNSaisie} listeGrouped={listeGrouped} t={t} />)
                    :
                    <div className='col-12'>
                        <div className="alert bg-purple mb-5 py-4" role="alert">
                            <div className="d-flex">
                                <i className="icon icon-info mr-3 fs-30"></i>
                                <div>
                                    <h4 className="alert-heading">{t("rapport7")} </h4>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}
