/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function RightSideBar() {

    const user = JSON.parse(localStorage.getItem('user'));
    const details = user

    return (
        <div>
            {/* Right-sidebar*/}
            <div className="sidebar sidebar-right sidebar-animate">
                <div className="p-3">
                    <a href="#" className="text-right float-right" data-toggle="sidebar-right" data-target=".sidebar-right"><i className="fe fe-x" /></a>
                </div>
                <div className="tab-menu-heading  siderbar-tabs border-0">
                    <div className="tabs-menu ">
                        {/* Tabs */}
                        {/* <ul className="nav panel-tabs">
                            <li className><a href="#tab" className="active show" data-toggle="tab">Profile</a></li>
                            <li className><a href="#tab1" data-toggle="tab" className>Friends</a></li>
                            <li><a href="#tab2" data-toggle="tab" className>Activity</a></li>
                            <li><a href="#tab3" data-toggle="tab" className>Todo</a></li>
                        </ul> */}
                    </div>
                </div>
                <div className="panel-body tabs-menu-body side-tab-body p-0 border-0 ">
                    <div className="tab-content border-top">
                        <div className="tab-pane active" id="tab">
                            <div className="card-body p-0">
                                <div className="header-user text-center mt-4 pb-4">
                                    <span className="avatar avatar-xxl brround"><img src="../assets/images/users/2.jpg" alt="Profile-img" className="avatar avatar-xxl brround" /></span>
                                    <div className="dropdown-item text-center font-weight-semibold user h3 mb-0">{details?.nom}</div>
                                    <small>{details?.email}</small>
                                  {/*   <div className="card-body">
                                        <div className="form-group ">
                                            <label className="form-label  text-left">Offline/Online</label>
                                            <select className="form-control mb-4 nice-select " data-placeholder="Choose one">
                                                <option value={1}>Online</option>
                                                <option value={2}>Offline</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-5 mb-6">
                                            <label className="form-label text-left">Website</label>
                                            <select className="form-control nice-select " data-placeholder="Choose one">
                                                <option value={1}>Spruko.com</option>
                                                <option value={2}>sprukosoft.com</option>
                                                <option value={3}>sprukotechnologies.com</option>
                                                <option value={4}>sprukoinfo.com</option>
                                                <option value={5}>sprukotech.com</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                               {/*  <a className="dropdown-item  border-top" href="#">
                                    <i className="dropdown-icon mdi mdi-account-edit" /> Edit Profile
                                </a>
                                <a className="dropdown-item  border-top" href="#">
                                    <i className="dropdown-icon mdi mdi-account-outline" /> Spruko technologies
                                </a>
                                <a className="dropdown-item border-top" href="#">
                                    <i className="dropdown-icon  mdi mdi-account-plus" /> Add Another Account
                                </a>
                                <a className="dropdown-item  border-top" href="#">
                                    <i className="dropdown-icon mdi mdi-comment-check-outline" /> Message
                                </a>
                                <a className="dropdown-item  border-top" href="#">
                                    <i className="dropdown-icon zmdi zmdi-pin-help" /> Need Help?
                                </a>
                                <div className="card-body border-top">
                                    <h4>Gallery</h4>
                                    <div className="row mt-4">
                                        <div className="col-12">
                                           <button className="btn btn-primary col-12" onClick={()=>seDeconnecter()}> Se deconnecter </button>
                                        </div>
                                    </div>
                                </div> */}
                              {/*   <div className="card-body border-top border-bottom">
                                    <div className="row">
                                        <div className="col-4 text-center">
                                            <a href><i className="dropdown-icon mdi  mdi-message-outline fs-20 m-0 leading-tight" /></a>
                                            <div>Inbox</div>
                                        </div>
                                        <div className="col-4 text-center">
                                            <a  href><i className="dropdown-icon mdi mdi-tune fs-20 m-0 leading-tight" /></a>
                                            <div>Settings</div>
                                        </div>
                                        <div className="col-4 text-center">
                                            <a  href><i className="dropdown-icon mdi mdi-logout-variant fs-20 m-0 leading-tight" /></a>
                                            <div>Sign out</div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="tab-pane" id="tab1">
                            <div className="chat">
                                <div className="contacts_card">
                                    <div className="input-group mb-0 p-3">
                                        <input type="text" placeholder="Search..." className="form-control search" />
                                        <div className="input-group-prepend mr-0">
                                            <span className="input-group-text  search_btn  btn-secondary"><i className="fa fa-search text-white" /></span>
                                        </div>
                                    </div>
                                    <ul className="contacts mb-0">
                                        <li className="active">
                                            <div className="d-flex bd-highlight w-100">
                                                <div className="img_cont">
                                                    <img src="../assets/images/users/12.jpg" className="rounded-circle user_img" alt="img11" />
                                                    <span className="online_icon" />
                                                </div>
                                                <div className="user_info">
                                                    <h5 className="mt-1 mb-1">Maryam Naz</h5>
                                                    <small className="text-muted">is online</small>
                                                </div>
                                                <div className="float-right text-right ml-auto mt-auto mb-auto"><small>01-02-2019</small></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex bd-highlight w-100">
                                                <div className="img_cont">
                                                    <img src="../assets/images/users/2.jpg" className="rounded-circle user_img" alt="img12" />
                                                    <span className=" online_icon" />
                                                </div>
                                                <div className="user_info">
                                                    <h5 className="mt-1 mb-1">Sahar Darya</h5>
                                                    <small className="text-muted">left 7 mins ago</small>
                                                </div>
                                                <div className="float-right text-right ml-auto mt-auto mb-auto"><small>01-02-2019</small></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex bd-highlight w-100">
                                                <div className="img_cont">
                                                    <img src="../assets/images/users/5.jpg" className="rounded-circle user_img" alt="img13" />
                                                    <span className="online_icon" />
                                                </div>
                                                <div className="user_info">
                                                    <h5 className="mt-1 mb-1">Maryam Naz</h5>
                                                    <small className="text-muted">online</small>
                                                </div>
                                                <div className="float-right text-right ml-auto mt-auto mb-auto"><small>01-02-2019</small></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex bd-highlight w-100">
                                                <div className="img_cont">
                                                    <img src="../assets/images/users/7.jpg" className="rounded-circle user_img" alt="img14" />
                                                    <span className="online_icon" />
                                                </div>
                                                <div className="user_info">
                                                    <h5 className="mt-1 mb-1">Yolduz Rafi</h5>
                                                    <small className="text-muted">online</small>
                                                </div>
                                                <div className="float-right text-right ml-auto mt-auto mb-auto"><small>02-02-2019</small></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex bd-highlight w-100">
                                                <div className="img_cont">
                                                    <img src="../assets/images/users/8.jpg" className="rounded-circle user_img" alt="img15" />
                                                    <span className="online_icon" />
                                                </div>
                                                <div className="user_info">
                                                    <h5 className="mt-1 mb-1">Nargis Hawa</h5>
                                                    <small className="text-muted">30 mins ago</small>
                                                </div>
                                                <div className="float-right text-right ml-auto mt-auto mb-auto"><small>02-02-2019</small></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex bd-highlight w-100">
                                                <div className="img_cont">
                                                    <img src="../assets/images/users/3.jpg" className="rounded-circle user_img" alt="img16" />
                                                    <span className="online_icon" />
                                                </div>
                                                <div className="user_info">
                                                    <h5 className="mt-1 mb-1">Khadija Mehr</h5>
                                                    <small className="text-muted">50 mins ago</small>
                                                </div>
                                                <div className="float-right text-right ml-auto mt-auto mb-auto"><small>03-02-2019</small></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex bd-highlight w-100">
                                                <div className="img_cont">
                                                    <img src="../assets/images/users/14.jpg" className="rounded-circle user_img" alt="img17" />
                                                    <span className="online_icon" />
                                                </div>
                                                <div className="user_info">
                                                    <h5 className="mt-1 mb-1">Petey Cruiser</h5>
                                                    <small className="text-muted">1hr ago</small>
                                                </div>
                                                <div className="float-right text-right ml-auto mt-auto mb-auto"><small>03-02-2019</small></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex bd-highlight w-100">
                                                <div className="img_cont">
                                                    <img src="../assets/images/users/11.jpg" className="rounded-circle user_img" alt="img18" />
                                                </div>
                                                <div className="user_info">
                                                    <h5 className="mt-1 mb-1">Khadija Mehr</h5>
                                                    <small className="text-muted">2hr ago</small>
                                                </div>
                                                <div className="float-right text-right ml-auto mt-auto mb-auto"><small>03-02-2019</small></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab2">
                            <div className="list d-flex align-items-center border-bottom p-4">
                                <div>
                                    <span className="avatar bg-primary brround avatar-md">CH</span>
                                </div>
                                <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                        <b>New Websites is Created</b>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="mdi mdi-clock text-muted mr-1" />
                                            <small className="text-muted ml-auto">30 mins ago</small>
                                            <p className="mb-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list d-flex align-items-center border-bottom p-4">
                                <div>
                                    <span className="avatar bg-danger brround avatar-md">N</span>
                                </div>
                                <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                        <b>Prepare For the Next Project</b>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="mdi mdi-clock text-muted mr-1" />
                                            <small className="text-muted ml-auto">2 hours ago</small>
                                            <p className="mb-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list d-flex align-items-center border-bottom p-4">
                                <div>
                                    <span className="avatar bg-info brround avatar-md">S</span>
                                </div>
                                <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                        <b>Decide the live Discussion Time</b>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="mdi mdi-clock text-muted mr-1" />
                                            <small className="text-muted ml-auto">3 hours ago</small>
                                            <p className="mb-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list d-flex align-items-center border-bottom p-4">
                                <div>
                                    <span className="avatar bg-warning brround avatar-md">K</span>
                                </div>
                                <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                        <b>Team Review meeting</b>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="mdi mdi-clock text-muted mr-1" />
                                            <small className="text-muted ml-auto">4 hours ago</small>
                                            <p className="mb-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list d-flex align-items-center border-bottom p-4">
                                <div>
                                    <span className="avatar bg-success brround avatar-md">R</span>
                                </div>
                                <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                        <b>Prepare for Presentation</b>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="mdi mdi-clock text-muted mr-1" />
                                            <small className="text-muted ml-auto">1 days ago</small>
                                            <p className="mb-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list d-flex align-items-center  border-bottom p-4">
                                <div>
                                    <span className="avatar bg-pink brround avatar-md">MS</span>
                                </div>
                                <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                        <b>Prepare for Presentation</b>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="mdi mdi-clock text-muted mr-1" />
                                            <small className="text-muted ml-auto">1 days ago</small>
                                            <p className="mb-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list d-flex align-items-center border-bottom p-4">
                                <div>
                                    <span className="avatar bg-purple brround avatar-md">L</span>
                                </div>
                                <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                        <b>Prepare for Presentation</b>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="mdi mdi-clock text-muted mr-1" />
                                            <small className="text-muted ml-auto">45 mintues ago</small>
                                            <p className="mb-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list d-flex align-items-center border-bottom p-4">
                                <div>
                                    <span className="avatar bg-primary brround avatar-md">CH</span>
                                </div>
                                <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                        <b>New Websites is Created</b>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="mdi mdi-clock text-muted mr-1" />
                                            <small className="text-muted ml-auto">30 mins ago</small>
                                            <p className="mb-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list d-flex align-items-center p-4">
                                <div>
                                    <span className="avatar bg-blue brround avatar-md">U</span>
                                </div>
                                <div className="wrapper w-100 ml-3">
                                    <p className="mb-0 d-flex">
                                        <b>Prepare for Presentation</b>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="mdi mdi-clock text-muted mr-1" />
                                            <small className="text-muted ml-auto">2 days ago</small>
                                            <p className="mb-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab3">
                            <div>
                                <div className="d-flex p-3">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" defaultChecked />
                                        <span className="custom-control-label">Do Even More..</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="d-flex p-3 border-top">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox2" defaultValue="option2" defaultChecked />
                                        <span className="custom-control-label">Find an idea.</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="d-flex p-3 border-top">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox3" defaultValue="option3" defaultChecked />
                                        <span className="custom-control-label">Hangout with friends</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="d-flex p-3 border-top">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox4" defaultValue="option4" />
                                        <span className="custom-control-label">Do Something else</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="d-flex p-3 border-top">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox5" defaultValue="option5" />
                                        <span className="custom-control-label">Eat healthy, Eat Fresh..</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="d-flex p-3 border-top">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox6" defaultValue="option6" defaultChecked />
                                        <span className="custom-control-label">Finsh something more..</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="d-flex p-3 border-top">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox7" defaultValue="option7" defaultChecked />
                                        <span className="custom-control-label">Do something more</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="d-flex p-3 border-top">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox8" defaultValue="option8" />
                                        <span className="custom-control-label">Updated more files</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="d-flex p-3 border-top">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox9" defaultValue="option9" />
                                        <span className="custom-control-label">System updated</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="d-flex p-3 border-top border-bottom">
                                    <label className="custom-control custom-checkbox mb-0">
                                        <input type="checkbox" className="custom-control-input" name="example-checkbox10" defaultValue="option10" />
                                        <span className="custom-control-label">Settings Changings...</span>
                                    </label>
                                    <span className="ml-auto">
                                        <a href="#"><i className="si si-pencil text-primary mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Edit" /></a>
                                        <a href="#"><i className="si si-trash text-danger mr-2" data-toggle="tooltip" title data-placement="top" data-original-title="Delete" /></a>
                                    </span>
                                </div>
                                <div className="text-center pt-5">
                                    <a href="#" className="btn btn-primary">Add more</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

    )
}
