/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import { getImagesRayons, getImagesRayonsByPdv } from '../../services/Dashboard/FirstPage'
import { getMerch } from '../../services/Users/UsersApi'

export default function PhotoRayons() {
    //------ History -----------------
    const history = useHistory()
    const { date_debut, date_fin } = useParams("")
    const [date_d, setdate_d] = useState(date_debut)
    const [date_f, setdate_f] = useState(date_fin)
    const [codeUser, setCodeUser] = useState("")
    const [listeUsers, setlisteUsers] = useState([])
    const searchInput = useRef();
    //----------- Liste Images 
    const [liste, setliste] = useState([])
    const [listeImages, setlisteImages] = useState([])
    //------------ Loader ---------------------
    const [loading, setloading] = useState(true)
    const [loadingImages, setloadingImages] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    const [show, setshow] = useState(false)
    const onChangeDateFormat = () => {
        if (date_d > date_f) {
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
        } else {
            const data = {
                user_code: codeUser
            }
            history.push({
                pathname:
                    '/photorayons/datedebut=' + date_d + '&datefin=' + date_f,
            })
            setloading(true)
            getImagesRayons(date_d, date_f, data).then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.listeImages)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
        }
    }
    useEffect(() => {
        getMerch().then((result) => {
            if (result?.data?.success === 1) {
                setlisteUsers(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        const data = {
            user_code: ""
        }
        setloadingImages(true)
        getImagesRayons(date_debut, date_fin, data).then((result) => {
            if (result?.data?.success === 1) {
                setliste(result?.data?.listeImages)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
            setloadingImages(false)
        })
    }, [])

    const handleChangeUsers = (...args) => {
        setCodeUser(args[1].value)
    }
    const onClickPdv = (pdv_code) => {
        var data = {}
        if (codeUser !== "") {
            Object.assign(data, {
                user_code: codeUser
            })
        } else {
            Object.assign(data, {
                user_code: ""
            })
        }
        getImagesRayonsByPdv(date_debut, date_fin, pdv_code, data).then((result) => {
            if (result?.data?.success === 1) {
                setlisteImages(result?.data?.listeImages)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb mt-2">
                    <li className="breadcrumb-item"><a href="/images"><i className="fe fe-life-buoy mr-1" /> Photo Rayons </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('listeImages')} </li>
                </ol>
            </div>
            <ToastContainer />
            <div className='row'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <div className='row mb-2'>
                        <div className='col-3'></div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                            <div className="input-group">
                                <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control text-primary" />
                                <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control text-primary" />
                                <SelectSearch
                                    options={listeUsers}
                                    search
                                    ref={searchInput}
                                    onChange={handleChangeUsers}
                                    filterOptions={fuzzySearch}
                                    emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                    placeholder="Liste Merchandiseurs"
                                />
                                <span className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                                </span>
                            </div>
                        </div>
                        <div className='col-3'></div>

                    </div>
                </div>
                {loading ? <SqaureLoader /> : (
                    <>
                        <div className='col-lg-12 mt-3'>
                            <div className="content row">
                                <div className='row'>
                                    {liste?.map((items, keys) => (
                                        <div className="col-xl-4 col-lg-4 col-md-12" key={keys}>
                                            <div className="info-box">
                                                <span className="info-box-icon bg-purple"><i className="ti-home text-white" /></span>
                                                <div className="info-box-content"><h6 className="mt-3  fs-12"> {items.pdv_code} : <u onClick={() => { onClickPdv(items.pdv_code); setshow(true) }} style={{ cursor: "pointer" }}> {items.nompdv} </u></h6></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </>
                )}
                {show ? <div className="col-lg-12 mt-3">
                    <div className="content row">
                        <div className='col-12'>
                        <button className='float right btn btn-azure mb-2 mt-2' onClick={()=> setshow(false)}><i className='ti-close'/></button>

                        </div>
                        {loadingImages ? <SqaureLoader /> : (
                            <div className='row'>
                                {listeImages?.map((items, keys) => (
                                    <div className='col-4' key={keys}>
                                        <div className="card">
                                            <a className="elem " data-lcl-thumb={items.image} data-lcl-txt={"Date " + moment(items.created_at).format("DD-MM-YYYY")} href={items.image} title={items.type}>
                                                <img className="card-img-top" src={items.image} alt="Thumb-2" title="Image 01" />
                                                <span className="img-responsive" data-image-src={items.image} />
                                            </a>
                                            <div className="card-body">
                                                <p className="card-text text-center"> {moment(items.date).format("DD/MM/YYYY")} : {items.type}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <br />
                    </div> </div> : null}
            </div>
        </div>
    )
}
