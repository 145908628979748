import React from 'react'

export default function TablePromotions({ listePromotions, summ_ass_promotions, t }) {
    return (
        <div className='card'>
            <div className='card-header'>
                <h6 className='card-title mt-3 text-center '> {t('dashboard.Promotions')}  </h6>
            </div>
            <div className='card-body'>
                <div className="panel panel-body mb-2" style={{ overflow: 'scroll', height: "350px" }}>
                    <h6 className=" mt-2 text-muted fs-12">  <span className="text-info"> <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span> <div className="d-flex align-items-center  mb-3 mt-3 mr-2">

                        <div className="w-3 h-3 bg-green ml-2 mr-2" />
                        <div>
                            <strong> {t('dashboard.Promotions')}  </strong>
                            <br />
                        </div>
                        <div className="w-3 h-3 bg-danger  ml-2 mr-2" />
                        <div>
                            <strong> {t('rapports.non')} </strong>
                            <br />
                        </div>
                    </div>

                    </h6>
                    <div className='float-right'>
                        <strong className='text-muted'> {t('Magasins.Assortiments')}  ({summ_ass_promotions})  </strong>
                    </div>
                    {/** Tableau rupture Produits  */}
                    <div className="table-responsive  product-datatable">
                        <table className="table  table-bordered ">
                            <thead className="text-center bg-azure-lighter">
                                <tr>
                                    <th className="w-15p fs-12"> {t('rapports.promotion')} </th>
                                    <th className="wd-15p fs-12">{t('rapports.code')}</th>
                                    <th className="wd-15p fs-12">{t('rapportrupture.produit')}</th>
                                    <th className="wd-15p fs-12">{t('rapports.miseavant')}</th>
                                    <th className="wd-15p fs-12">{t('rapports.prix')}</th>
                                    <th className="wd-15p fs-12">{t('dashboard.type')}</th>

                                </tr>
                            </thead>
                            <tbody className='text-center fs-12'>
                                {listePromotions.length === 0 ?
                                    <tr>
                                        <td colSpan={5} className='text-center text-danger'>{t('rapports.aucuneinfo')}
                                            <hr className='hr1' />
                                            <img src="../assets/images/photos/no.png" alt='noData' style={{ height: "200px" }} />

                                        </td>
                                    </tr> :
                                    listePromotions?.map((items, keys) => (
                                        <tr key={keys}>
                                            <td>{items.libelle}</td>
                                            <td>{items.code}</td>
                                            <td>{items.nomproduit}</td>
                                            <td>{items.mise_avant === null ? "-" : items.mise_avant}</td>
                                            <td>{parseFloat(items.prix).toFixed(3)}</td>
                                            <td>{items.etat_promotion === "1" ?<i className='ion-stop text-green' /> : <i className='ion-stop text-danger' />} </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
