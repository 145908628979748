import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SqaureLoader from "../../components/Loader/SqaureLoader";
import {
  getListeCommandes,
  updateListeCommandes,
} from "../../services/Produits/ProduitsAPI";
import moment from "moment";
import { toast } from "react-toastify";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import ModalExport from "./ModalExport";

export default function CommandeGMS() {
  // -------- PARAMETES---------------------
  const { date_debut, date_fin } = useParams("");
  // -------- DATE DEBUT / DATE FIN -----------
  const [dateDebut, setdateDebut] = useState(date_debut);
  const [dateFin, setdateFin] = useState(date_fin);
  // FILTRE ------
  const [LISTE_ENSEIGNES, setLISTE_ENSEIGNES] = useState([]);
  //---------- LISTE DES COMMANDES --------
  const [LISTE, setLISTE] = useState([]);
  const [SaveListe, setSaveListe] = useState([]);
  const [allProduits, setallProduits] = useState([])
  //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");
  const [loading, setloading] = useState(true);

  const traitement = () => {
    getListeCommandes(dateDebut, dateFin)
      .then((result) => {
        if (result?.data?.success === 1) {
          setLISTE(result?.data?.commandes);
          setSaveListe(result?.data?.commandes);
          setLISTE_ENSEIGNES(result?.data?.enseignes);
          setallProduits(result?.data?.all)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
  };
  const onChangeDateFormat = () => {
    traitement();
  };
  const handleChange = (e, index,commande_code) => {
    const clonedData = [...LISTE];
    const clonedData2 = [...allProduits]
    var value = e=== true ? 1 : 0;
    clonedData2?.map((items,keys)=>{ 
      if(commande_code=== items.commande_code){
        clonedData2[keys].etat= value
      }})
    clonedData[index].etat = value;
    setLISTE(clonedData);
    setallProduits(clonedData2)
    updateListe(clonedData2)
  };
  const updateListe = (array_produits) => {
    updateListeCommandes(array_produits)
      .then((result) => {
        if (result?.data?.success === 1) {
          toast("Modifié avec succès!", {
            type: "success",
            position: "top-right",
          });
        } else {
          toast("Erreur temporaire !", {
            type: "error",
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    traitement();
  }, []);

  const filteEnseigne = (value) => {
    const L = SaveListe?.filter((el) => el.enseigne_code === value);
    console.log(L);
  };
  return (
    <div>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/commandes">
              <i className="fe fe-life-buoy mr-1" /> Commandes
            </a>
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="row mb-2">
            <div className="col-3"></div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="input-group">
                <input
                  type="date"
                  value={dateDebut}
                  onChange={(e) => setdateDebut(e.target.value)}
                  className="form-control text-primary"
                  placeholder="Search for..."
                />
                <input
                  type="date"
                  value={dateFin}
                  onChange={(e) => setdateFin(e.target.value)}
                  className="form-control text-primary"
                  placeholder="Search for..."
                />
                <span className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => onChangeDateFormat()}
                  >
                    <i className="ti-search" />
                  </button>
                </span>
              </div>
            </div>
            <div className="col-3"></div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
              <div
                className="tags mb-2  text-center"
                style={{ alignContent: "center" }}
              >
                {LISTE_ENSEIGNES?.map((items, keys) => (
                  <button
                    className="btn tag bg-purple"
                    key={keys}
                    onClick={() => filteEnseigne(items.value)}
                  >
                    {" "}
                    {items.name}{" "}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {loading ? (
            <SqaureLoader />
          ) : (
            <div className="row">
              <div className="card">
                <div className="card-header mt-2 text-primary">
                  <b className="mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-inboxes mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562A.5.5 0 0 0 1.884 9h12.234a.5.5 0 0 0 .496-.438L14.933 6zM3.809.563A1.5 1.5 0 0 1 4.981 0h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393zm.941.83.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438l.32-2.562H10.45a2.5 2.5 0 0 1-4.9 0H1.066z" />
                    </svg>
                    Gestion des commandes GMS
                  </b>

                  <div className="float-right">
                    <ReactHtmlTableToExcel
                      id="test-table-xls-button"
                      className="btn btn-azure fs-12 mt-0 "
                      table="table-to-xls"
                      filename="Rapport_commandes_gms"
                      sheet="tablexls"
                      buttonText={
                        <span className=" text-center">
                          {" "}
                          <i className="icon icon-share-alt mr-1" />{" "}
                          {t("rapportrupture.exporter")}
                        </span>
                      }
                    />
                 
                    <ModalExport dateDebut={dateDebut} dateFin={dateFin} commande_code={""} noHandle={false}/>

                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                    <div className="w-3 h-3 bg-danger  mr-2" />
                    <div>
                      <strong>
                        Les commandes exportés (
                        <i
                          className="ti-check-box text-info"
                          data-toggle="tooltip"
                          title
                          data-original-title="ti-check-box"
                        />
                        )
                      </strong>
                      <br />
                    </div>
                   
                  </div>
                  {/* <div className="float-right">
                    <button
                      className="btn btn-primary fs-12 mt-0 ml-2  mb-2"
                      onClick={updateListe}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-download mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                      Enregistrer
                    </button>
                    </div> */}
                  <div className="table-responsive  product-datatable">
                    <table className="table  table-bordered " id="table-to-xls">
                      <thead className="text-center">
                        <tr>
                          <th className="w-15p fs-10"> Enseigne </th>
                          <th className="wd-15p fs-10">Magasin </th>
                          <th className="wd-15p fs-10">Merchandiseur</th>
                          <th className="wd-15p fs-10">Date</th>
                          <th className="wd-15p fs-10">Commentaire</th>
                          <th className="wd-15p fs-10">Etat</th>
                          <th className="wd-15p fs-10">Détails</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {LISTE?.map((items, keys) => (
                          <tr
                            key={keys}
                            className={
                              items.etat === 1
                                ? "bg-red-lightest fs-10"
                                : "fs-10"
                            }
                          >
                            <td>{items.enseigne}</td>
                            <td>{items.nompdv}</td>
                            <td>{items.nom}</td>
                            <td>
                              <b className="text-info">
                                {moment(items.date).format("DD/MM/YYYY")}
                              </b>
                            </td>
                            <td>{items.commentaire}</td>
                            <td>
                            <ModalExport dateDebut={dateDebut} dateFin={dateFin} commande_code={items.commande_code} handleChange={handleChange} keys={keys} noHandle={false}/>
                            <label className="fs-10 ml-2">
                                {" "}
                                {items.etat === 1 ? "Exporté" : "Non exporté"}{" "}
                              </label> 
                          {/*     <input
                                type="checkbox"
                                name="etat"
                                defaultChecked={items.etat}
                                onChange={(e) => handleChange(e, keys,items.commande_code)}
                                className="fcustom-control-input fs-10"
                              />
                              <label className="fs-10 ml-2">
                                {" "}
                                {items.etat === 1 ? "livré" : "Non livré"}{" "}
                              </label> */}
                            </td>
                            <td>
                              <a className="text-primary fs-10" href={"commandes&commande_code="+items.commande_code+"&date_debut="+dateDebut+"&date_fin="+dateFin}><u>Voir la commande</u></a>
                            </td>
                     
                          </tr>
                        ))}
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
