import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
const role=user?.result?.role_code
const user_code =user?.result?.code

//-------------------------------------------- SERVICE PAYS -----------------------------------

/**
 * Get Liste pays 
 * @returns 
 */
export async function getListePays() {
    try {
        const response = await axios.get(url + "pays/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}


/**
 * GET Détails Pays by CODE 
 * @param {*} code 
 * @returns 
 */
export async function getDetailsPays(code) {
    try {
        const response = await axios.get(url + "pays/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 *  ADD Pays
 * @param {*} data 
 * @returns 
 */
export async function postObjectPays(data) {
    try {
        const response = await axios.post(url + "pays/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Update Pays by Code
 * @param {*} code 
 * @param {*} data 
 * @returns 
 */
export async function patchObjectPays(code, data) {
    try {
        const response = await axios.patch(url + "pays/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * DELETE PAYS by Code
 * @param {*} code 
 * @returns 
 */
export async function deleteObjectPays(code) {
    try {
        const response = await axios.delete(url + "pays/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

//-------------------------------------------- SERVICE REGIONS  -----------------------------------

/**
 * GET LISTE REGIONS 
 * @returns 
 */
export async function getListeRegions() {
    try {
        const response = await axios.get(url + "regions/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Add Region 
 * @param {*} data 
 * @returns 
 */
export async function postObjectRegions(data) {
    try {
        const response = await axios.post(url + "regions/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * GET Détails Pays by CODE 
 * @param {*} code 
 * @returns 
 */
 export async function getDetailsRegions(code) {
    try {
        const response = await axios.get(url + "regions/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Update Pays by Code
 * @param {*} code 
 * @param {*} data 
 * @returns 
 */
 export async function patchObjectRegions(code, data) {
    try {
        const response = await axios.patch(url + "regions/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/***
 * DELETE REGION
 * @param {*} code 
 * @returns 
 */
export async function deleteObjectRegion(code) {
    try {
        const response = await axios.delete(url + "regions/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
//-------------------------------------------- SERVICE GOUVERNORAT  -----------------------------------

/**
 * Get liste  : Gouvernorats
 * @returns 
 */
export async function getListeGouvernorat() {
    try {
        const response = await axios.get(url + "gouvernorat/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Post add data  : Gouvernorats
 * @returns 
 */
export async function postObjectGouvernorat(data) {
    try {
        const response = await axios.post(url + "gouvernorat/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * GET Détails GOUVERNORAT by CODE 
 * @param {*} code 
 * @returns 
 */
 export async function getDetailsGouvernorat(code) {
    try {
        const response = await axios.get(url + "gouvernorat/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Update GOUVERNORAT by Code
 * @param {*} code 
 * @param {*} data 
 * @returns 
 */
 export async function patchObjectGouvernorat(code, data) {
    try {
        const response = await axios.patch(url + "gouvernorat/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * DELETE OBJECT GOUVERNOART
 * @param {*} code 
 * @returns 
 */
export async function deleteObjectGouvernorat(code) {
    try {
        const response = await axios.delete(url + "gouvernorat/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
//-------------------------------------------- SERVICE DELEGATIONS  -----------------------------------

/**
 * Get liste  : DELEGATIONS
 * @returns 
 */
export async function getListedelegations() {
    try {
        const response = await axios.get(url + "delegations/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Post add data  : Delegation
 * @returns 
 */
export async function postObjectDelegation(data) {
    try {
        const response = await axios.post(url + "delegations/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get Delegation by Code
 * @param {string} code 
 * @returns 
 */
export async function getDelegationByCode(code) {
    try {
        const response = await axios.get(url + "delegations/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Update Delegation By Code
 * @param {string} code 
 * @param {object} data 
 * @returns 
 */
export async function updateDelegationByCode(code, data) {
    try {
        const response = await axios.patch(url + "delegations/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

export async function deleteObjectDelegation(code) {
    try {
        const response = await axios.delete(url + "delegations/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
//-------------------------------------------- SERVICE SECTEURS  -----------------------------------

/**
 * Get liste  : SECTEURS
 * @returns 
 */
export async function getListeSecteurs() {
    try {
        const response = await axios.get(url + "secteurs/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Post add data  : secteurs
 * @returns 
 */
export async function postObjectSecteurs(data) {
    try {
        const response = await axios.post(url + "secteurs/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Get SECTEURS by Code
 * @param {string} code 
 * @returns 
 */
export async function getSecteurByCode(code) {
    try {
        const response = await axios.get(url + "secteurs/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Update Delegation By Code
 * @param {string} code 
 * @param {object} data 
 * @returns 
 */
export async function updateSecteurByCode(code, data) {
    try {
        const response = await axios.patch(url + "secteurs/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Delete Secteur
 * @param {*} code 
 * @returns 
 */
export async function deleteObjectSecteur(code) {
    try {
        const response = await axios.delete(url + "secteurs/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

//-------------------------------------------- SERVICE ZONES  -----------------------------------

/**
 * Get liste  : Zones
 * @returns 
 */
export async function getListZones() {
    try {
        const response = await axios.get(url + "zones/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Zones
 * @returns 
 */
export async function getDetailsZones(code) {
    try {
        const response = await axios.get(url + "zones/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

export async function updateZone(code, data) {
    try {
        const response = await axios.patch(url + "zones/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function deleteZone(code) {
    try {
        const response = await axios.delete(url + "zones/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function getSelectZone() {
    try {
        const response = await axios.get(url + "zones/select/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

export async function getSelectTypeVisite() {
    try {
        const response = await axios.get(url + "zones/typeVisite", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Post add data  : zones
 * @returns 
 */
export async function postObjectZones(data) {
    try {
        const response = await axios.post(url + "zones/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
//-------------------------------------------- SERVICE LOCALITES  -----------------------------------

/**
 * Get liste  : LOCALITES
 * @returns 
 */
export async function getListLocalites() {
    try {
        const response = await axios.get(url + "localite/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Post add data  : localite
 * @returns 
 */
export async function postObjectLocalite(data) {
    try {
        const response = await axios.post(url + "localite/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Post add data  : localite ==> Pour les filtre de recherche 
 * @returns 
 */
export async function getSelectLocalite() {
    try {
        const response = await axios.get(url + "localite/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Get Localites by Code
 * @param {string} code 
 * @returns 
 */
export async function getLocalitesByCode(code) {
    try {
        const response = await axios.get(url + "localite/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Update Localites By Code
 * @param {string} code 
 * @param {object} data 
 * @returns 
 */
export async function updateLocalitesByCode(code, data) {
    try {
        const response = await axios.patch(url + "localite/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Delete Localite
 * @param {*} code 
 * @returns 
 */
 export async function deleteObjectLocalite(code) {
    try {
        const response = await axios.delete(url + "localite/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}