import React from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import _ from "lodash"
import moment from 'moment'
export default function ResultatRoulette({ saveRoulette , setCommercial_Code ,Commercial_Code, dateDebut, setdateDebut, dateFin, setdateFin, onChangeDateFormat, loader, Roulette ,t }) {
    return (
        <div className="card">
            <div className="card-header">
                <div className="card-title mt-3 text-azure float-left">
                    <i className="icon icon-present mr-1" />  Résultat Roulette</div>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-success btn-icon float-right"
                    table="tableau_entetecharge"
                    filename="Roulette"
                    sheet="tablexls"
                    buttonText={<i className=" mdi mdi-export  text-white" />}
                />
            </div>
            <div className='card-body'>
                <div className="input-group">
                    <input type="date" value={dateDebut} onChange={(e) => setdateDebut(e.target.value)} className="form-control text-primary" placeholder="Search for..." />
                    <input type="date" value={dateFin} onChange={(e) => setdateFin(e.target.value)} className="form-control text-primary" placeholder="Search for..." />
                    <select className='form-control' required value={Commercial_Code} onChange={(e)=>setCommercial_Code(e.target.value)}>
                        <option value={""}>Choisir Commercial</option>
                        {_.uniqBy(saveRoulette,"commercial_code")?.map((items,keys)=>
                        <option key={keys} value={items.commercial_code}>{items?.commercial}</option>)}
                    </select>
                    <span className="input-group-append">
                        <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>

                    </span>
                </div>
                <div className="table-responsive  product-datatable">
                    <table id="tableau_entetecharge" className="table  table-bordered ">
                        <thead className="text-center ">
                            <tr>
                                <th> </th>
                                <th className="w-15p fs-12">{t('Produits.UTILISATEUR')}</th>
                                <th className="wd-15p fs-12"> Libellé</th>
                                <th className="wd-15p fs-12">Commentaire</th>
                                <th className="wd-15p fs-12">Date</th>

                            </tr>
                        </thead>
                        {loader ? <SqaureLoader /> : (
                            <tbody className='text-center'>
                                {Roulette?.map((items, keys) => (
                                    <>
                                        <tr key={keys} className={
                                            keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                        }> <td>{keys + 1}</td>
                                            <td>{items.commercial} </td>
                                            <td>{items.libelle} </td>
                                            <td>{items.commentaire} </td>
                                            <td>{moment(items.created_at).format("DD/MM/YYYY")} </td>

                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>

            </div>
        </div>
    )
}
