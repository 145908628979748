/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import SqaureLoader from '../../../../components/Loader/SqaureLoader'
import { updateCategorie } from '../../../../services/Magasins/PdvApi'

export default function ModalEditCategorie({ keys, setShowEdit, setShow, object }) {

    //----- Parametrages ----------
    const [code, setCode] = useState("")
    const [libelle, setLibelle] = useState("")
    //------------- Loader -----
    const [loading, setLoading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        setLoading(true)
        setCode(object.code)
        setLibelle(object.libelle)
        setLoading(false)
    }, [object])

    /**
 * SAVE DATA
 */
    const saveData = () => {
        //Data of code & libelle FOR OBJECT PAYS
        if (code !== "" && libelle !== "") {
            const data = {
                code: code,
                libelle: libelle
            }
            updateCategorie(code, data)
                .then((result) => {
                    console.log(result)
                    if (result?.data?.success === 0) {
                        toast(t('Zoning.Erreur'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        toast(t('messageErreur.modifsuccee'), {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    return (
        <div className="modal fade" id="updateCategorie" tabIndex={-1} role="dialog" aria-hidden="true" key={keys}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-azure" id="updateZone"> <i className='icon icon-map mr-1' />{t('Magasins.modifiercategorie')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() => { setShowEdit(false) }} /> </span>
                    </div>
                    <div className="modal-body">
                        {loading ?
                            <SqaureLoader /> : (
                                <form>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.code')} :</label>
                                        <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2 mt-1 mb-sm-1"> {t('dashboard.Libelle')}<span className="text-danger">*</span></label>
                                        <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} className="form-control" />
                                    </div>
                                </form>
                            )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-azure fs-12 " onClick={() => { saveData(); setShowEdit(true); setShow(false) }} > <i className='icon icon-cloud-download mr-1' />{t('Magasins.Enregistrer')} </button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" > <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>)
}
