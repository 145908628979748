/* eslint-disable no-unused-vars */
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function AjouterDesConventions() {
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/pdv/convention"><i className="fe fe-life-buoy mr-1" /> Convention </a></li>
                    <li className="breadcrumb-item active" aria-current="page">Ajouter Convention </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Parametragemagasins')}
                        </a>
                        <a href="/pdv/ajouterPDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-plus mr-1" />
                            {t('Magasins.AjouterPDV')}
                        </a>
                        <a href="/pdv/groupePDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-organization mr-1" />
                            Groupe PDV
                        </a>

                    </div>
                </div>
            </div>
        </div>
    )
}
