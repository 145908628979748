/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React from 'react'
export default function CardReclamation({items ,t}) {
    
    return (
        <div className="col-xl-4 col-lg-12 col-md-12">
        <div className="card">
            <div className="item7-card-img">
                <a href="#" />
                <img src={items.image === 'NULL' ? "../assets/images/users/17.jpg" : items.image} alt="img" className="cover-image" />
                
            </div>
            <div className="card-body">
                <div className="d-flex fs-14 mb-3">
                    <div className="float-left">
                        <a href="#" className="mr-3 border-right mr-3 pr-3"><span className="text-muted"><i className="fa fa-calendar-o text-muted mr-2" /> {moment(items.date).format("DD MMMM YYYY")} </span></a>
                        <a href="#" className="mr-3  mr-3 pr-3"><span className="text-muted"> {items.user}</span></a>
                    </div>
                   
                </div>
              <h6 className="font-weight-semibold">{items.marque} : {items.produit}</h6>
              <h6 className="mt-2">{items.message}</h6>

              <span className="float-left">{t('Catalogues.Client')} : {items.client}</span>
              <span className="float-right">{t('users.phone')} : {items.telephone}</span>
            </div>
        </div>
    </div>
    )
}
