/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { toast } from 'react-toastify'
import { getSelctEnseignes } from '../../../services/Produits/ProduitsAPI'
import { getRapportRupture1Post } from '../../../services/Rapports/RapportsApi'
import { getSelectMagasins } from '../../../services/Ruptures/ruptureAPI'
import { getMerch } from '../../../services/Users/UsersApi'
import { getSelectZone } from '../../../services/Zoning/ZoningApi'
//------------------- tools TO SEARCH INPUT -----------------
import SelectSearch, { fuzzySearch } from 'react-select-search'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import moment from 'moment'
import ModalHistoriqueRupture from './ModalHistoriqueRupture'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function RuptureFlower1() {
    //--------- PARAMETERE -------------
    const { datedebut, datefin } = useParams('')
    //------ History -----------------
    const history = useHistory()
    //---------- FORMULAIRE --------------
    const [date_d, setdate_d] = useState(datedebut)
    const [date_f, setdate_f] = useState(datefin)
    const [codeUser, setcodeUser] = useState("")
    const [codezone, setcodezone] = useState("")
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [codePdv, setcodePdv] = useState("")
    const [valeurEtat, setvaleurEtat] = useState("")
    //---- LISTE DES MERCHANDISEURS ----------------
    const [listeUsers, setlisteUsers] = useState([])
    //-----LISTE DES PDVS ----------------------
    const [listePdvs, setlistePdvs] = useState([])
    const [savePdvs, setsavePdvs] = useState([])
    //-----LISTE DES PDVS ----------------------
    const [listeEnseignes, setlisteEnseignes] = useState([])
    //------- LISTE ZONES ----------------
    const [listeZones, setlisteZones] = useState([])
    /**
     * Tableau ruptures 2
     */
    const [listeRuptures, setlisteRuptures] = useState([])
    const [saveListeRuptures, setsaveListeRuptures] = useState([])
    //Modal items 
    var [produit_code, setproduit_code] = useState('')
    var [pdv, setpdv] = useState('')
    const [ok, setOk] = useState(false)
    //-------------- ACTION ON SEARCH FILTRE ----------------
    const [ok1, setok1] = useState(false)
    //---------- SEARCH REFERENCES ---------------------
    const { searchZone, searchEnseigne, searchPDV, searchMerch } = useRef();
    //--------- Loader ------------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    /**
     * SETTINGS TABLEAU
     */
    const [settings, setsettings] = useState(false)
    const [show1, setshow1] = useState(true)
    const [show2, setshow2] = useState(true)
    const [show3, setshow3] = useState(true)
    const [show4, setshow4] = useState(true)
    const [show5, setshow5] = useState(true)
    const [show6, setshow6] = useState(true)
    const [show7, setshow7] = useState(true)
    const [show8, setshow8] = useState(true)
    const [show9, setshow9] = useState(true)
    const [show10, setshow10] = useState(true)
    const [show11, setshow11] = useState(true)
    const [show12, setshow12] = useState(true)
    const [show13, setshow13] = useState(true)

    useEffect(() => {
        /**
         * WEB SERVICES Magasins : Format( name , value )
         */
        getSelectMagasins()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlistePdvs(result?.data?.data)
                    setsavePdvs(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        /**
         * WEB SERVICES ENSEIGNES : FORMAT (name , value )
         */
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnseignes(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        /**
         * WEB SERVICES ZONES : FORMAT (name , value )
         */
        getSelectZone().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        /**
         * WEB SERVICES MERCHANDISEURS : FORMAT (name , value )
         */
        getMerch().then((result) => {
            if (result?.data?.success === 1) {
                setlisteUsers(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }, [])

    /**
     * ON change elements on INPUT SEARCH 
     * @param  {...any} args 
     */
    const handleChangeZone = (...args) => {
        setcodezone(args[1].value)
    };
    const handleChangeEnseigne = (...args) => {
        setcodeEnseigne(args[1].value)
        const liste = savePdvs?.filter((el) => el.enseigne_code === args[1].value)
        setlistePdvs(liste)
    };
    const handleChangePdv = (...args) => {
        setcodePdv(args[1].value)
    };
    const handleChangeUser = (...args) => {
        setcodeUser(args[1].value)
    };
    const handleChangeEtat = (e) => {
        setvaleurEtat(e.target.value)
    }
    const redirection = () => {
        history.push({
            pathname:
                "/rapports/rupture1&datedebut=" + date_d + "&datefin=" + date_f,
        })
    }
    /**
    * ON CHANGE DATE DEBUT / DATE FIN AND SELECT ELEMENTS
    */
    const onChangeDateFormat = () => {
        if (date_d > date_f) {
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })

        } else {
            setloading(true)
            setok1(true)
            redirection()
            const data = {}
            if (codePdv !== "") {
                Object.assign(data, {
                    pdv_code: codePdv
                })
            }
            if (codezone !== "") {
                Object.assign(data, {
                    zone_code: codezone
                })
            }
            if (codeEnseigne !== "") {
                Object.assign(data, {
                    enseigne_code: codeEnseigne
                })
            }
            if (codeUser !== "") {
                Object.assign(data, {
                    user_code: codeUser
                })
            }
            if (valeurEtat !== "" && valeurEtat !== "epuise") {
                Object.assign(data, {
                    etat_repture: valeurEtat
                })
            }
            getRapportRupture1Post(date_d, date_f, data)
                .then((result) => {
                    if(result?.data?.success === 1){
                    setlisteRuptures(result?.data?.listeruptures)
                    setsaveListeRuptures(result?.data?.listeruptures)
                    }
                    
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setloading(false)
                })
        }

    }

    const back = () => {
        setok1(false)
        setlisteRuptures([])
        setsaveListeRuptures([])
        setcodezone("")
        setcodePdv("")
        setcodeEnseigne("")
        setvaleurEtat("")
        setcodeUser("")
    }
    return (
        <div> 
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('rapports.rupture1')}</li>
                </ol>
            </div>
             {/** ------------- rapport : Rupture 1 ------------------------------ */}
             <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-equalizer text-azure mr-2" /> {t('rapports.rupture1')}
                                <span className="float-right"> <i className="fe fe-search  text-azure" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control br-tr-md-0 br-br-md-0  fs-10 text-primary" placeholder={t('rapportrupture.rechercher')} />
                                        <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control br-tr-md-0 br-br-md-0  fs-10 text-primary" placeholder={t('rapportrupture.rechercher')} />
                                        <SelectSearch
                                            options={listeZones}
                                            search
                                            value={codezone}
                                            ref={searchZone}
                                            onChange={handleChangeZone}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('rapports.Zones')}
                                        />
                                        <SelectSearch
                                            options={listeEnseignes}
                                            search
                                            value={codeEnseigne}
                                            ref={searchEnseigne}
                                            onChange={handleChangeEnseigne}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('rapports.Enseignes')}
                                        />
                                        <SelectSearch
                                            options={listePdvs}
                                            search
                                            value={codePdv}
                                            ref={searchPDV}
                                            onChange={handleChangePdv}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('rapports.Magasins')}
                                        />
                                        <SelectSearch
                                            options={listeUsers}
                                            search
                                            value={codeUser}
                                            ref={searchMerch}
                                            onChange={handleChangeUser}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('dashboard.Merchandiseurs')}
                                        />
                                        <select className="form-control  br-md-0  fs-10 text-primary" value={valeurEtat} onChange={(e) => handleChangeEtat(e)}>
                                            <option value={""}>{t('rapports.Etat')}</option>
                                            <option value={"1"}>{t('rapports.Rupture')}</option>
                                            <option value={"0"}>{t('rapports.Disponible')}</option>
                                            <option value={"epuise"}>{t('rapports.Epuise')}</option>
                                        </select>
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                                            <button className="btn bg-yellow" type="button" onClick={() => back()}><i className="ti-close"></i></button>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h6 className=" mt-2 text-muted fs-12">
                                <span className="text-info">
                                    <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span> <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                                    {/*      Veuillez choisir un merchandiseur */}
                                    <div className="w-3 h-3 bg-danger  mr-2" />
                                    <div>
                                        <strong> {t('rapports.Rupture')} </strong>
                                        <br />
                                    </div>
                                    <div className="w-3 h-3 bg-green ml-2  mr-2" />
                                    <div>
                                        <strong> {t('rapports.Disponible')} </strong>
                                        <br />
                                    </div>
                                    <div className="w-3 h-3 bg-yellow ml-2  mr-2" />
                                    <div>
                                        <strong> {t('rapports.Epuise')} </strong>
                                        <br />
                                    </div>
                                    <div className="w-3 h-3 bg-gray ml-2  mr-2" />
                                    <div>
                                        <strong>{t('rapports.NonSaisie')} </strong>
                                        <br />
                                    </div>
                                </div>
                            </h6>

                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                {ok1 ?

                    (loading ? <SqaureLoader /> : 
                    <div className='card-body'>
                        <div className='float-right '>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-azure mb-2 btn-pill fs-12 mt-0 "
                                table="table-to-xls"
                                filename="RapportRelevePrix"
                                sheet="tablexls"
                                buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                            />
                        <button className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 " onClick={() => setsettings(!settings)} ><i className='ti-filter' /> </button>
                        </div>
                        {settings ?
                <div className='row'>
                    <div className="selectgroup selectgroup-pills">
                        <label className="selectgroup-item ">
                            <input type="checkbox" value={show1}
                                checked={show1}
                                onChange={(e) => setshow1(e.target.checked)} className="selectgroup-input " />
                            <span className="selectgroup-button fs-10">{t('rapportrupture.date')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show2} checked={show2}
                                onChange={(e) => setshow2(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10"> {t('rapportrupture.merch')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show3}
                                checked={show3}
                                onChange={(e) => setshow3(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('Produits.Enseigne')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show4}
                                checked={show4}
                                onChange={(e) => setshow4(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('dashboard.Magasin')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show5}
                                checked={show5}
                                onChange={(e) => setshow5(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapportrupture.codeproduit')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show6}
                                checked={show6}
                                onChange={(e) => setshow6(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapportrupture.produit')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show7}
                                checked={show7}
                                onChange={(e) => setshow7(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.CODEABARRE')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show8}
                                checked={show8}
                                onChange={(e) => setshow8(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.CODEMETI')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show9}
                                checked={show9}
                                onChange={(e) => setshow9(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('Produits.SousFamille')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show10}
                                checked={show10}
                                onChange={(e) => setshow10(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.STOCK')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show11}
                                checked={show11}
                                onChange={(e) => setshow11(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.QUANTITE')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show12}
                                checked={show12}
                                onChange={(e) => setshow12(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.Etat')}</span>
                        </label>
                        <label className="selectgroup-item">
                            <input type="checkbox" value={show13}
                                checked={show13}
                                onChange={(e) => setshow13(e.target.checked)} className="selectgroup-input" />
                            <span className="selectgroup-button fs-10">{t('rapports.ACTION')}</span>
                        </label>
                    </div>
                </div>
                : null}
                <div className='card'>

                        <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '600px' }}>
                            <table id="table-to-xls" className="table  table-bordered ">
                                <thead className="text-center bg-azure-lighter" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                    <tr className='bg-purple'>
                                        <td colSpan="14" className='fs-10'>
                                            {t('rapportrupture.totalrupture')}  : {listeRuptures?.length}
                                        </td>
                                    </tr>
                                    <tr>
                                       {show1 ? <th className="w-15p fs-10"> {t('rapportrupture.date')} </th>: null}
                                       {show2 ? <th className="w-15p fs-10"> {t('rapportrupture.merch')}</th>: null}
                                       {show3 ? <th className="w-15p fs-10">{t('Produits.Enseigne')}</th>: null}
                                       {show4 ? <th className="w-15p fs-10">{t('dashboard.Magasin')}</th>: null}
                                       {show5 ? <th className="w-15p fs-10">{t('rapportrupture.codeproduit')}</th>: null}
                                       {show6 ? <th className="w-15p fs-10">{t('rapportrupture.produit')} </th>: null}
                                       {show7 ? <th className="w-15p fs-10">{t('rapports.CODEABARRE')} </th>: null}
                                       {show8 ? <th className="w-15p fs-10">{t('rapports.CODEMETI')}</th>: null}
                                       {show9 ? <th className="w-15p fs-10">{t('Produits.SousFamille')} </th>: null}
                                       {show10 ? <th className="w-15p fs-10">{t('rapports.STOCK')} </th>: null}
                                       {show11 ? <th className="w-15p fs-10">{t('rapports.QUANTITE')} </th>: null}
                                       {show12 ? <th className="w-15p fs-10">{t('rapports.Etat')} </th>: null}
                                       {show13 ? <th className="w-15p fs-10">{t('rapports.ACTION')} </th>: null}

                                    </tr>
                                </thead>
                                <tbody className='text-center fs-10'>
                                    {listeRuptures?.map((items, keys) => (
                                        <tr key={keys} className={
                                            keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center " : "fs-10 text-center"
                                        } >
                                         {show1 ? <td> {moment(items.date).format("DD/MM/YYYY")} </td>: null}
                                         {show2 ? <td>{items.nom_user} </td>: null}
                                         {show3 ? <td>{items.nom_enseigne} </td>: null}
                                         {show4 ? <td>{items.nom_pdv} </td>: null}
                                         {show5 ? <td>{items.produit_code} </td>: null}
                                         {show6 ? <td>{items.produit} </td>: null}
                                         {show7 ? <td>{items.code_a_barre} </td>: null}
                                         {show8 ? <td>{items.code_meti == null ? "Aucun " : items.code_meti} </td>: null}
                                         {show9 ? <td>{items.sousfamille} </td>: null}
                                         {show10 ?<td>{items.stock === null ? 0 : items.stock} </td>: null}
                                         {show11 ?<td>{items.qte === null ? 0 : items.qte} </td>: null}
                                         {show12 ?<td>{items.etat_repture === "1" ? <i className='fa fa-square text-danger'/> :<i className='fa fa-square text-success'/>} </td>: null}
                                         {show13 ?<td> <u style={{ fontWeight: "bold" , cursor:'pointer' }} className='text-primary' data-toggle="modal" data-target="#exampleModal" data-backdrop="static" data-keyboard="false" onClick={() => { setOk(true); setproduit_code(items.produit_code); setpdv(items.pdv_code) }} >{t('rapports.historique')}</u> </td> : null}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                    
                    )
                    :
                    <div className='col-12'>
                        <div className="alert bg-purple mb-5 py-4" role="alert">
                            <div className="d-flex">
                                <i className="icon icon-info mr-3 fs-30"></i>
                                <div>
                                    <h4 className="alert-heading">{t("rapport7")} </h4>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {ok ? <ModalHistoriqueRupture setOk={setOk} produit_code={produit_code} pdv_code={pdv} t={t} />
                : null}
        </div>
    )
}
