/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { getListeRoutings, getListeTournee } from '../../../services/Routings/RoutingsApi'
import { getListeSecteurs } from '../../../services/Zoning/ZoningApi'
import DeleteTournee from './DeleteTournee'
import DuplicateModal from './DuplicateModal'
import MapTournee from './MapTournee'
import ModalEditListeTournee from './ModalEditListeTournee'

export default function ListeTournee() {
    //------------- Param -------------
    const {debut,fin}= useParams("")
    //------ History -----------------
    const history = useHistory()
    //----------------- users details ----------------
    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
    //---------- LISTE Routes -------------
    const [listeTournee, setlisteTournee] = useState([])
    const [saveList, setSaveList] = useState([])
    //Search 
    const [listeRegions, setlisteRegions] = useState([])
    const [listeSecteurs, setListeSecteurs] = useState([])
    const [listeroutes, setlisteRoutes] = useState([])
    const [localisation, setlocalisation] = useState([])
    //-----------Filtre -------------------
    //--------- Search --------------------
    const [date_d, setdate_d] = useState(debut)
    const [date_f, setdate_f] = useState(fin)

    //--------- CODE -----------------
    const [codeRouting, setcodeRouting] = useState("-1")
    const [codeSecteur, setcodeSecteur] = useState("-1")
    const [codeRegion, setcodeRegion] = useState("-1")
    const [codeJour, setcodeJour] = useState("-1")
    const [actif, setactif] = useState("-1")
    const [codeActif, setcodeActif] = useState("")
    const [dateDebut, setdateDebut] = useState("")
    const [dateFin, setdateFin] = useState("")
    const [jour, setjour] = useState("")
    const [code, setcode] = useState("")
    const [codeUser, setCodeUser] = useState("")
    const [ok, setok] = useState(false)
    const [routing, setrouting] = useState("")
    //Loader 
    const [Loading, setLoading] = useState(true)
    //Modal items
    const [show, setShow] = useState(false)
    //Edit Items 
    const [showEdit, setShowEdit] = useState(false)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(30);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //Current Liste 

    const [loading, setloading] = useState(true)

    const traitement = () => {
        const data ={}
        if(codeRouting !== "-1"){
            Object.assign(data,{
                routing_code :codeRouting
            })
        }
        if(codeJour !== "-1"){
            Object.assign(data,{
                code_jour :codeJour
            })
        }
        if(codeRegion !== "-1"){
            Object.assign(data,{
                region_code :codeRegion
            })
        }
        getListeTournee(date_d, date_f,data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTournee(result?.data.data)
                    var L = result?.data.data
                    var region = []
                    L?.map((items)=>
                    region.push({
                        region_code: items.region_code,
                        region : items.region
                    }))
                    setlisteRegions(_.uniqBy(region,"region_code"))
                    setSaveList(result?.data.data)
                    setlocalisation(result?.data?.localisation)
                }
                setloading(false)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }
    const onChangeValue = ()=>{
        if (date_d > date_f) {
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
        } else {
            history.push({
                pathname:
                    '/routings/tournee&datedebut=' + date_d + '&datefin=' + date_f ,
            })
            traitement()
        }
    }
    useEffect(() => {

        getListeSecteurs()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListeSecteurs(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });

        getListeRoutings()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteRoutes(result?.data?.getListeRoutes)
                }
            }).catch((err) => {
                console.log(err)
            });
        /// GET LISTE TOURNEES 
        traitement()
    }, [])

    const filterDataRoute = (e) => {
        if (e.target.value !== "-1") {
            setcodeRouting(e.target.value)
            const liste = listeTournee?.filter((element) => element.routing_code === e.target.value)
            setlisteTournee(liste)
        } else {
            setlisteTournee(saveList)
        }

    }

    const filterDataSecteur = (e) => {
        if (e.target.value !== "-1") {
            setcodeSecteur(e.target.value)
            const liste = listeTournee?.filter((element) => element.secteur_code === e.target.value)
            setlisteTournee(liste)
        }
        else {
            setlisteTournee(saveList)
        }
    }
    const filterDataActif = (e) => {
        if (e.target.value !== "-1") {
            setactif(e.target.value)
            const liste = listeTournee?.filter((element) => element.actif === e.target.value)
            setlisteTournee(liste)
        } else {
            setlisteTournee(saveList)
        }
    }
    const filterDataJour = (e) => {
        if (e.target.value !== "-1") {
            setcodeJour(e.target.value)
            const liste = listeTournee?.filter((element) => element.code_jour === e.target.value)
            setlisteTournee(liste)
        }
        else {
            setlisteTournee(saveList)
        }
    }
    useEffect(() => {
        if (ok === true) {
            traitement()
            setok(false)
        }
    }, [ok])

    

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Routings.routings')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Routings.Tournee')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                    {details?.role_code!=="sup" ?
                    <>
                    <a href="/routings/routings&route=-1&region=-1" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className=" icon icon-plus mr-1" />
                            {t('sidebar.creationTournee')}
                        </a>
                           <a href={"/routings/TourneeZone"} className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                           <i className="icon icon-event mr-1" />
                           {t('rapports.creationAgenda')}
                       </a>
                    </> 
                        :<a href="/routings/validationTournee" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className=" icon icon-plus mr-1" />
                            {t('rapports.validationTournee')} 
                        </a>} 
                     
                        <a href="/routings/routes" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-map  mr-1" />
                            {t('Routings.Route')}
                        </a>
                        <a href="/routings/tournee" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                         <i className="icon icon-map mr-1" />
                         {t('Tournee agenda par zone')}
                     </a>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'>
                    {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
                    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                        <div className="panel panel-default active">
                            <div className="panel-heading " role="tab" id="headingOne">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" className='fs-12' data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <i className="icon icon-grid text-primary mr-2" /> {t('Routings.listeTournee')}
                                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                <div className='row mb-2'>
                                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12'></div>
                                <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control text-primary fs-12" />
                                        <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control text-primary fs-12" />
                                                  <select value={codeRouting} onChange={(e)=>setcodeRouting(e.target.value)} className="form-control custom-select  br-md-0 fs-12">
                                                    <option value={"-1"}  >{t('Routings.Route/user')}</option>
                                                    {listeroutes?.map((items, keys) => (
                                                        <option value={items.code} key={keys}> {items.code} / {items.nom} </option>
                                                    ))}
                                                    </select>
                                                     <select value={codeJour} onChange={(e)=>setcodeJour(e.target.value)} className="form-control  custom-select br-md-0 fs-12">
                                                    <option value={"-1"} > {t('dashboard.jours')}  </option>
                                                    <option value={"Lundi"}> {t('dashboard.lundi')}</option>
                                                    <option value={"Mardi"}>{t('dashboard.Mardi')}</option>
                                                    <option value={"Mercredi"}>{t('dashboard.Mercredi')}</option>
                                                    <option value={"Jeudi"}>{t('dashboard.Jeudi')}</option>
                                                    <option value={"Vendredi"}>{t('dashboard.Vendredi')}</option>
                                                    <option value={"Samedi"}>{t('dashboard.Samedi')}</option>
                                                    <option value={"Dimanche"}>{t('dashboard.Dimanche')}</option>
                                                </select>
                                                <select value={codeRegion} onChange={(e)=>setcodeRegion(e.target.value)} className="form-control custom-select  br-md-0 fs-12">
                                                    <option value={"-1"}  >Région</option>
                                                    {listeRegions?.map((items, keys) => (
                                                        <option value={items.region_code} key={keys}> {items.region}  </option>
                                                    ))}
                                                    </select>
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => onChangeValue()}><i className='ti-search' /></button>
                                        </span>
                                        <span className="input-group-append">
                                        <ReactHtmlTableToExcel
                                                id="table-to-xls"
                                                className="btn btn-azure fs-12 mt-0 "
                                                table="table-to-xls_tournee"
                                                filename="Exporter"
                                                sheet="tablexls"
                                                buttonText={
                                                    <span className=" text-center">
                                                    {" "}
                                                    <i className="icon icon-share-alt mr-1" />{" "}
                                                    {t("rapportrupture.exporter")}
                                                    </span>
                                                }
                                                />
                                        </span>
                                    </div>
                                </div>
                                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12'></div>
                         
                            </div>
                                  {/*   <div className="search1 search-elements-sm mb-4">
                                        <div className="form row no-gutters">
                                        <label className='col-form-label ml-2 mr-2 mt-1'> <i className=' icon icon-equalizer mr-1'/>  Filtre : </label>

                                            <div className="form-group col-xl-2 col-lg-2  col-md-12 mb-0">
                                                <select value={codeRouting} onChange={(e) => filterDataRoute(e)} className="form-control custom-select  br-md-0 fs-12">
                                                    <option value={"-1"}  >{t('Routings.Route/user')}</option>
                                                    {listeroutes?.map((items, keys) => (
                                                        <option value={items.code} key={keys}> {items.code} / {items.nom} </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3  col-md-4 col-sm-4 mb-0">
                                                <select value={codeSecteur} onChange={(e) => filterDataSecteur(e)} className="form-control custom-select fs-12  br-md-0">
                                                    <option value={"-1"} >{t('Routings.Secteurs')}</option>
                                                    {listeSecteurs?.map((items, keys) => (
                                                        <option value={items.code} key={keys}>{items.libelle}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3  col-sm-4  col-md-4 mb-0">
                                                <select value={codeJour} onChange={(e) => filterDataJour(e)} className="form-control  custom-select br-md-0 fs-12">
                                                    <option value={"-1"} > {t('dashboard.jours')}  </option>
                                                    <option value={"Lundi"}> {t('dashboard.lundi')}</option>
                                                    <option value={"Mardi"}>{t('dashboard.Mardi')}</option>
                                                    <option value={"Mercredi"}>{t('dashboard.Mercredi')}</option>
                                                    <option value={"Jeudi"}>{t('dashboard.Jeudi')}</option>
                                                    <option value={"Vendredi"}>{t('dashboard.Vendredi')}</option>
                                                    <option value={"Samedi"}>{t('dashboard.Samedi')}</option>
                                                    <option value={"Dimanche"}>{t('dashboard.Dimanche')}</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-sm-4 col-md-4 mb-0">
                                                <select value={actif} onChange={(e) => filterDataActif(e)} className="form-control  custom-select br-md-0 fs-12">
                                                    <option value={"-1"} >{t('dashboard.actifs')} </option>
                                                    <option value={"1"}>{t('rapports.oui')}</option>
                                                    <option value={"0"}>{t('rapports.non')}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {Loading ? <SqaureLoader /> : (
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive product-datatable" style={{ overflow: 'auto', height: '600px' }}>
                                    <table className="table table-striped table-bordered " id="table-to-xls_tournee">

                                        <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                            <tr>
                                                <th className="wd-10p fs-10" >{t('rapports.code')}</th>
                                                <th className="wd-15p fs-10" >{t('Routings.CODEJOUR')}</th>
                                                <th className="wd-15p fs-10" >{t('Routings.frequence')}</th>
                                                <th className="wd-15p fs-10" >{t('dashboard.actifs')}</th>
                                                <th className="wd-15p fs-10" >{t('Routings.DEBUT')}</th>
                                                <th className="wd-15p fs-10" >{t('Routings.FIN')}</th>
                                                <th className="wd-15p fs-10" >{t('Routings.ROUTE/MERCH')}</th>
                                            
                                                 <th className="wd-15p fs-10" >{t('rapports.Magasins')}</th>
                                                <th className="wd-10p fs-10 " >{t('rapports.ACTION')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {listeTournee?.map((items, keys) => (
                                                <tr key={keys} className="fs-10">
                                                    <td><a className="text-primary" href={"/tournee/code=" + items.code}><u>{items.code}</u> </a> </td>
                                                    <td>{items.code_jour} </td>
                                                    <td>{items.frequence} </td>
                                                    <td style={{ fontSize: "12px" }} ><div className=" mb-0" style={{ textAlign: "center", display: "inline-flex" }}>
                                                        <div className={items.actif === "1" ? "w-3 h-3 bg-success text-center " : "w-3 h-3 bg-danger text-center "} />
                                                        <div>
                                                        </div>
                                                    </div>
                                                    </td>                                
                                                    <td>{moment(items.date_debut).format('DD-MM-YYYY')} </td>
                                                    <td>{moment(items.date_fin).format('DD-MM-YYYY')} </td>
                                                    <td>{items.routing_code}/{items.nomuser} </td>
                                                   
                                                     <td>{items.magasins} </td>
                                                    <td style={{ cursor: "pointer" }} >
                                                        <a data-toggle="modal" data-target="#modal" onClick={() => { setShow(true); setcode(items.code); setcodeActif(items.actif); setdateDebut(items.date_debut); setdateFin(items.date_fin); setjour(items.code_jour) }} > <i className="icon icon-note text-warning" /></a>
                                                        <a data-toggle="modal" data-target="#duplicate" onClick={() => { setcode(items.code); setrouting(items.routing_code) }}> <i className="icon icon-docs text-azure" /></a>
                                                        <a data-toggle="modal" data-target="#modal-notification" onClick={() => { setcode(items.code) }}> <i className="icon icon-trash text-danger" /></a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        
                                    </table>
                                 
                                </div>
                        </div>
                    </div>
                    )}
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="card" >
                        <MapTournee listeEnseinges={localisation} loading={loading} t={t} />
                    </div>
                </div>
            </div>
            {show ?
                <ModalEditListeTournee code={code} setok={setok} setcodeActif={setcodeActif} codeActif={codeActif} dateDebut={dateDebut} setdateDebut={setdateDebut} jour={jour} setjour={setjour} dateFin={dateFin} setdateFin={setdateFin} t={t}/>
                : null}
            <DeleteTournee code={code} setok={setok} t={t} />
            <DuplicateModal code={code} routing_code={routing} codeUser={codeUser} setCodeUser={setCodeUser} setok={setok}  t={t}/>

        </div>
    )
}
