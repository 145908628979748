import React from 'react'

export default function HeaderAutoEval({t}) {
  return (
    <div className="page-header">
    <ol className="breadcrumb">
      <li className="breadcrumb-item mt-2">
        <i className="icon icon-equalizer text-azure mr-1" />{" "}
        {t("Evaluation Merchandiseurs")}
      </li>
    </ol>
  </div>
  )
}
