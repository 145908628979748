import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import LoaderTable from "../../../components/Loader/LoaderTable";
import { getRapportMerchandiseur } from "../../../services/Rapports/RapportsApi";
import ExportExcel from "../../commandes/ExportExcel";

export default function RapportMerchandiseur() {
  //------ History -----------------
  const history = useHistory();
  //------ PARAM --------------------
  const { date_debut, date_fin } = useParams("");
  //------ DATE DEBUT/ DATE FIN -----------------
  const [dateDebut, setdateDebut] = useState(date_debut);
  const [dateFin, setdateFin] = useState(date_fin);
  //------------- COUNTS --------------------
  const [weeks, setweeks] = useState([])
  const [days, setdays] = useState([])
  const [month, setmonth] = useState([])
  //--------- LISTE OF CONTENT
  const [days_nbr_magasins, setdays_nbr_magasins] = useState([])
  const [week_nbr_magasins, setweek_nbr_magasins] = useState([])
  //------------ Loader --------------------
  const [Loading, setLoading] = useState(true)
  // EXPORT
  const [LISTE, setLISTE] = useState([]);

  //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
   traitement()
  }, []);
  const traitement = () => {
    setLoading(true)

    getRapportMerchandiseur(dateDebut, dateFin)
    .then((result) => {
      if (result?.data?.success === 1) {
          //----------------- days -------------------
          var worked_days = [] // les jours travaillés
          var days_nbr_magasins = result?.data?.days_nbr_magasins
          setdays_nbr_magasins(days_nbr_magasins)
          days_nbr_magasins?.map((items,keys)=>{
              worked_days.push({
                  date : items.date
              })
          })
          var uniq_days = _.uniqBy(days_nbr_magasins,"date")
          setdays(_.sortBy(uniq_days,"date"))
          //------------------ WEEKS --------------------
          var worked_week = []
          var week_nbr_magasins = result?.data?.week_nbr_magasins
          week_nbr_magasins?.map((items,keys)=>{
              worked_week.push({week : items.Week})
          })
          var all_week = _.uniqBy(worked_week,"week")
          setweeks(_.sortBy(all_week,"week"))
          setweek_nbr_magasins(week_nbr_magasins)
          setmonth(result?.data?.month_nbr_magasins)
      }
    })
    .catch((err) => {
      console.log(err);
    }).finally(()=>  setLoading(false)
    )
  }
  const getWeeks = (user_code, week)=>{
    //---------- FILTRE WEEK ----------
    const L = week_nbr_magasins?.filter((el)=>el.Week === week && el.user_code === user_code)
    return L[0]?.total_visite
  }

  const getDays = (user_code , day) =>{
    const L = days_nbr_magasins?.filter((el)=>el.date === day && el.user_code === user_code)
    return L[0]?.total_visite
  }
  const onChangeDateFormat = () => {
    if (dateDebut > dateFin) {
        toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
    }else {
        traitement()
        history.push({
            pathname:
                "/rapport_merchandiseur/date_debut="+dateDebut+"&date_fin="+dateFin,
        })
    }
  };
  return (
    <div>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/rapports">
              <i className="fe fe-life-buoy mr-1" /> {t("rapports.Rapport")}{" "}
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {" "}
            Rapport des merchandiseurs{" "}
          </li>
        </ol>
      </div>
      {Loading? <LoaderTable /> :
        <div className="row">
        {/** --------------- Tableau Magasins ----------------------- */}
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="row mb-2">
            <div className="col-3"></div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="input-group">
                <input
                  type="date"
                  value={dateDebut}
                  onChange={(e) => setdateDebut(e.target.value)}
                  className="form-control text-primary"
                  placeholder="Search for..."
                />
                <input
                  type="date"
                  value={dateFin}
                  onChange={(e) => setdateFin(e.target.value)}
                  className="form-control text-primary"
                  placeholder="Search for..."
                />

                <span className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => onChangeDateFormat()}
                  >
                    <i className="ti-search" />
                  </button>
                </span>
           
              </div>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="float-right mt-2">
            <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 "
                    table="table-to-xls"
                    filename="Rapport_Rupture"
                    sheet="tablexls"
                    buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                />
            </div>
        <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '600px' }}>
            <table id="table-to-xls" className="table  table-bordered  mt-2">
                <thead  className="text-center bg-azure-lighter">
                    <tr>
                        <th>Merchandiseurs</th>
                        {days?.map((items,keys)=>
                        <th key={keys}>  {moment(items.date).format("DD/MM/YYYY")}
                        </th>)}
                        {weeks?.map((items,keys)=><th key={keys}>
                        Semaine   {keys+1}
                        </th>)}
                        <th>Mois</th>
                    </tr>
                </thead>
                <tbody className='bg-white text-center'>
                 {month?.map((items,keys)=><tr key={keys}>
                    <td>{items.nom}</td>
                    {days?.map((i,k)=>
                        <th key={k}>  
                        {getDays(items.user_code,i.date)!== undefined? getDays(items.user_code,i.date) : 0}
                        </th>)}
                        {weeks?.map((i,K)=><th key={K}>
                        {getWeeks(items.user_code,i.week)!== undefined? getWeeks(items.user_code,i.week) : 0}
                        </th>)}
                        <td>{items.total_visite}</td>
                 </tr>)}
                </tbody>
            </table>
    </div>
        </div>
      </div>
      }
    
    </div>
  );
}
