/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { getRapportJournalier_1 } from '../../../services/Rapports/RapportsApi'
import { getMerch } from '../../../services/Users/UsersApi'
import _ from 'lodash'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { useTranslation } from 'react-i18next'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function RapportVP() {
    const [date_d, setdate_d] = useState("")
    const [codeUser, setcodeUser] = useState("")
    const [listeUsers, setlisteUsers] = useState([])
    const [listeVP, setlisteVP] = useState([])
    const [saveListe, setsaveListe] = useState([])
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteUsers(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })

    }, [])

    const onChangeData = () => {

        if (date_d !== "") {
            getRapportJournalier_1(date_d)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setlisteVP(result?.data?.users)
                        setsaveListe(result?.data?.users)
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }

    const filtreData = (e) => {
        setcodeUser(e)
        if (listeVP.length !== 0) {
            const liste = saveListe?.filter((element) => element.user_code === e)
            setlisteVP(liste)
        } else if (e === "") {
            setlisteVP(saveListe)
        }
    }
    return (
        <div>
            {/** --------------------- HEADER --------------------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.rapportPointageVisites')} </li>
                </ol>
            </div>
            {/** ---------------------- BODY ------------------------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" className='fs-12'>
                                <i className="icon icon-grid text-azure mr-2" />  {t('rapports.FiltresRapportVisite&pointages')}
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-3'></div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <div className="input-group">
                                        <input type="date" className='form-control fs-12' value={date_d} onChange={(e) => setdate_d(e.target.value)} />
                                        <span className="input-group-append">
                                            <button className="btn btn-azure" type="button" onClick={() => onChangeData()}><i className='ti-search' /></button>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-3'></div>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>

                                    <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                        {listeVP?.length !== 0 ? listeUsers?.map((items, keys) => (
                                            <button className="btn tag bg-purple" key={keys} onClick={() => filtreData(items.value)}> {items.name} </button>
                                        )) : null}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {/** ----------------------- Tableaux --------------------------------- */}
            <div className='card'>
                <div className='card-body'>
                    <div className='float-right '>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-azure mb-2 btn-pill fs-12 mt-0 "
                            table="table-to-xls"
                            filename="RapportSuiviPointage"
                            sheet="tablexls"
                            buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                        />
                    </div>
                    <div className="table-responsive product-datatable">
                        <table id="table-to-xls" className="table table-striped table-bordered ">
                            <thead className="text-center bg-azure-lighter ">
                                {/* <tr className='text-center'>
                                    <td className='fs-12 text-azure' colSpan={1}> </td>
                                    <td className='fs-12 text-azure' colSpan={5}>{t('rapports.Pointage')}</td>
                                    <td className='fs-12 text-azure' colSpan={5}>{t('rapports.Visite')}</td>
                                </tr> */}
                                <tr>
                                    <th className="w-15p fs-10 text-azure">{t('rapports.COMMERCIAL')}</th>
                                    <th className="w-15p fs-10 text-azure">{t('dashboard.heuredebut')}	</th>
                                    <th className="w-15p fs-10 text-azure">{t('dashboard.heureFin')}</th>
                                    <th className="w-15p fs-10 text-azure">{t('rapports.INMAGASIN')}</th>
                                    <th className="w-15p fs-10 text-azure">{t('rapports.OUTMAGASIN')}</th>
                                    <th className="w-15p fs-10 text-azure">{t('rapports.TOTAL')}</th>
                                    <th className="w-15p fs-10 text-azure">{t('rapports.PDVVISITE')}</th>

                                    {/* <th className="w-15p fs-10 text-azure">{t('dashboard.heuredebut')}	</th>
                                    <th className="w-15p fs-10 text-azure">{t('dashboard.heureFin')}</th>
                                    <th className="w-15p fs-10 text-azure">{t('rapports.INMAGASIN')}</th>
                                    <th className="w-15p fs-10 text-azure">{t('rapports.OUTMAGASIN')}	</th>
                                    <th className="w-15p fs-10 text-azure">{t('rapports.TOTAL')}</th> */}

                                </tr>
                            </thead>
                            {loading ? <SqaureLoader /> :
                                <tbody >
                                    {listeVP?.map((items, keys) => (
                                        <tr key={keys}  className={
                                            keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"
                                        }>
                                            <td> {items?.nom} </td>
                                            {/* <td> {items?.heure_debut_pointage !== undefined && items?.heure_debut_pointage!== null  ? items?.heure_debut_pointage : "-"} </td>
                                            <td> {items?.heure_fin_pointage !== undefined && items?.heure_fin_pointage!== null ? items?.heure_fin_pointage : "-"} </td>
                                            <td> {items?.inMagasins !== undefined  && items?.inMagasins!== null ? items?.inMagasins : "-"} </td>
                                            <td> {items?.OutPDV !== undefined && items?.OutPDV !== null ? items?.OutPDV : "-"} </td>

                                            <td> {items?.total_pointages !== undefined  && items?.total_pointages!== null ? items?.total_pointages : "-"} </td>*/}
                                            <td> {items?.heure_debut !== undefined && items?.heure_debut!== null   ? items?.heure_debut : "-"} </td> 
                                            <td> {items?.heure_fin !== undefined   && items?.heure_fin!== null ? items?.heure_fin : "-"} </td>
                                            <td> {items?.inMagasins !== undefined  && items?.inMagasins!== null  ? items?.inMagasins : "-"} </td>
                                            <td> {items?.OutPDV !== undefined && items?.OutPDV !== null ? items?.OutPDV : "-"} </td>
                                            <td> {items?.totMagasin !== undefined  && items?.totMagasin!== null  ? items?.totMagasin : "-"} </td>
                                            <td> {items?.pdv_visite !== undefined  && items?.pdv_visite!== null  ? items?.pdv_visite : "-"} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>


        </div>
    )
}
