/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getListePdvVisite } from '../../../services/Dashboard/FirstPage'

export default function ModalPDVvisites({t,user_code, setok6, today}) {
       //------------- LISTE Promotions ----------
       const [listePdvUser, setlistePdvUser] = useState([])
       const [loading, setloading] = useState(true)
       
   useEffect(() => {
    setloading(true)
       //Call WebService with liste of promotions
       getListePdvVisite(today, user_code)
           .then((result) => {
               if (result?.data?.success === 1) {
                   setlistePdvUser(result?.data?.data)
               }
           }).catch((err) => {
               console.log(err)
           }).finally(() => {
               setloading(false)
           })
   },  [today,user_code])
   
  return (
    <div className="modal fade" id="pdvVisité" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="pdvVisité" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content" style={{ width: "max-content" }}>
            <div className="modal-header">
                <h5 className="modal-title" id="pdvVisité"> <i className='fe fe-tag text-azure mr-1' /> {t('visitepdv')}   </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setok6(false);setlistePdvUser([])}}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
            {loading ? <SqaureLoader /> : (
              <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '500px' }}>
                <table className="table  table-bordered ">
                  <thead className="text-center  bg-purple fs-10" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                  <td className='fs-10'>#</td>
                    <td className="wd-15p fs-10">{t('dashboard.Magasin')}</td>
                    <td className="wd-15p fs-10">{t('dashboard.heuredebut')}</td>
                    <td className="wd-15p fs-10">{t('dashboard.heureFin')}</td>
                    <td className="wd-15p fs-10">{t('rapportrupture.date')}</td>
                    <th className="wd-15p fs-10 text-white">{t('Routings.type')}</th>
          
                  </thead>
                  <tbody>
                    {listePdvUser?.map((items, keys) => (
                      <tr key={keys} className="text-center fs-10">
                        <td className='fs-10'>{keys+1}</td>
                        <td>{items.nompdv}</td>
                        <td>{items.heure_debut}</td>
                        <td>{items.heure_fin === "null" ? '-' : items.heure_fin}</td>
                        <td>{moment(items.date).format('DD/MM/YYYY')}</td>
                        <td > {items.typevisite} </td>
    
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            </div>
            {/*  <div className='modal-footer '>
                <button className='btn btn-secondary float-right ' onClick={() => setok2(false)}><i className='ti-close mr-1'/> Fermer</button>
            </div> */}
        </div>
    </div>
</div>
  )
}
