/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { addTypePdv } from '../../../../services/Magasins/PdvApi'

export default function ModalAddType({setShow}) {

    
    const [code, setCode] = useState("")
    const [libelle, setLibelle] = useState("")
      //------------- Traduction ---------------
   const [t, i18n] = useTranslation("global");

    const saveData = () => {
    //Data of code & libelle FOR OBJECT PAYS
    if (code !== "" && libelle !== "") {
       const data = {
           code: code,
           libelle: libelle
       }
       addTypePdv(data)
           .then((result) => {
               console.log(result.data.success)
               if (result?.data?.success === 0) {
                   toast(t('Zoning.Erreur'), {
                       type: "error",
                       position: toast.POSITION.TOP_RIGHT,
                   })
               } else if (result?.data?.success === 1) {
                   toast(t('messageErreur.ajoutavecsuccee'), {
                       type: "success",
                       position: toast.POSITION.TOP_RIGHT,
                   })
               }
           }).catch((err) => {
               console.log(err)
           });
            }
        }
    return (
        <div className="modal fade" id="addTypePDV" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="addTypePDV">{t('Magasins.ajoutertypepdv')} </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="form-group">
                            <label className="form-control-label">{t('rapports.code')} :</label>
                            <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label className="mr-2 mt-1 mb-sm-1"> {t('dashboard.Libelle')}<span className="text-danger">*</span></label>
                            <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} className="form-control" />

                        </div>
                
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => saveData()} > <i className="ti-save mr-1" />{t('Magasins.Enregistrer')} </button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShow(false)}><i className="ti-close mr-1" /> {t('Routings.annueler')}</button>

                </div>
            </div>
        </div>
    </div>
    )
}
