/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { SearchItems } from '../../../utils/SearchUtils';

export default function RuptureProduits({ produits, saveProduits,t, loading, setProduits }) {

    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')

    const [searchList, setSearchlist] = useState([])

    const currentListe = search.length < 1 ? produits : searchList
    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(produits, search)
            setSearchlist(newList)
        } else {
            setProduits(saveProduits)
        }
    }
    return (
        <div className="panel-group" id="acc6" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
                <div className="panel-heading " role="tab" id="heaving6">
                    <h4 className="panel-title text-primary">
                        <a role="button" data-toggle="collapse" data-parent="#acc6" href="#collapse6" aria-expanded="true" aria-controls="collapse6">
                            <i className="icon icon-folder-alt mr-2" /> Top 10 : {t('rapportrupture.produit')}/{t('sidebar.Ruptures')}
                            <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                        </a>
                    </h4>
                </div>
                <div id="collapse6" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heaving6">
                    <div className="panel-body">
                        <div className="search1 search-elements-sm mb-4 ">
                            <div className="form row no-gutters">
                                <div className="form-group  col-xl-9 col-lg-9 col-md-9 mb-0">
                                    <input type="text" ref={inputEl} value={search}
                                        onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary" style={{ fontsize: "10px" }} placeholder="Chercher un produit" />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 mb-0">
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-14 "
                                        table="table-to-xls3"
                                        filename="RuptureMerbiss"
                                        sheet="tablexls"
                                        buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-body" style={{ overflow: 'scroll', height: "535px" }}>
                            <div className="row">
                                <table id="table-to-xls3" className="table table-bordered ">
                                    <thead className="text-center bg-azure-lighter">
                                        <tr>
                                            <th style={{ fontSize: "11px" }}>{t('rapportrupture.produit')}</th>
                                            <th style={{ fontSize: "11px" }}>{t('dashboard.rupture')}</th>

                                        </tr>
                                    </thead>
                                    {loading ? <SqaureLoader /> : (
                                        produits.length === 0 ?
                                            <tr>
                                                <td colSpan="2">
                                                    <h6 className="text-danger text-center">
                                                        {t('Zoning.Aucunresultat')}
                                                    </h6>
                                                </td>
                                            </tr>
                                            :
                                            <tbody>

                                                {currentListe?.map((items, keys) => (
                                                    <tr key={keys}>
                                                        <td className="text-center">{items.produit}</td>
                                                        <td className="text-center">{items.sum}</td>
                                                    </tr>
                                                ))}
                                            </tbody>)}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
