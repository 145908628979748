import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { addCategorie } from '../../../../services/Magasins/PdvApi'
import { myRnId } from '../../../../utils/DateUtils'

export default function ModalAddCategorie({setShow ,t}) {

    const [code, setCode] = useState("Categ-"+myRnId())
    const [libelle, setLibelle] = useState("")
    
    /**
     * SAVE DATA
     */
     const saveData = () => {
        //Data of code & libelle FOR OBJECT PAYS
        if (code !== "" && libelle !== "") {
            const data = {
                code: code,
                libelle: libelle
            }
            addCategorie(data)
                .then((result) => {
                    console.log(result.data.success)
                    if (result?.data?.success === 0) {
                        toast(t('Zoning.Erreur'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        toast(t('messageErreur.ajoutavecsuccee'), {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }

    return (
        <div className="modal fade" id="addCategorie" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addCategorie">{t('Magasins.ajouterunecategorie')} </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label className="form-control-label">{t('rapports.code')} :</label>
                                <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="mr-2 mt-1 mb-sm-1"> {t('dashboard.Libelle')}<span className="text-danger">*</span></label>
                                <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} className="form-control" />

                            </div>
                    
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => saveData()} > <i className="ti-save mr-1" />{t('Magasins.Enregistrer')} </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShow(false)}><i className="ti-close mr-1" /> {t('Routings.annueler')}</button>

                    </div>
                </div>
            </div>
        </div>
    )
}
