import React from 'react';

export default function ModalListePromo({ listePromo ,t}) {
    return <div>
        <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={{ width: "max-content" }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> <i className='fe fe-tag text-azure mr-1' />  {t('dashboard.listePromo')}  </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive  product-datatable">
                            <table className="table  table-bordered ">
                                <thead className="text-center bg-purple" >
                                    <td>{t('dashboard.Libelle')}</td>
                                    <td>{t('dashboard.Date')}</td>
                                    <td>{t('dashboard.Magasin')}</td>
                                    <td>{t('dashboard.Promotions')}</td>
                                    <td>{t('dashboard.Utilisateur')}</td>
                                </thead>
                                <tbody>
                                    {listePromo?.map((items, keys) => (
                                        <tr key={keys}>
                                            <td>{items.libelle}</td>
                                            <td>{items.date}</td>
                                            <td>{items.nompdv}</td>
                                            <td>{items.promo}</td>
                                            <td>{items.nom}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type='button' className='btn btn-yellow float-right'  data-dismiss="modal" aria-label="Close"><i className='ti-close mr-1' /> {t('dashboard.Fermer')} </button>
                    </div>
                </div>


            </div>

        </div>


    </div>;
}
