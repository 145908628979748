/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getLastRelve, PostrapportSuiviRelevePrix, rapportSuiviRelevePrix } from '../../../services/Rapports/RapportsApi';
import { MultiSelect } from "react-multi-select-component";
import SelectSearch, { fuzzySearch } from 'react-select-search';
import TableauRelevePrix from './TableauRelevePrix';
import { toast, ToastContainer } from 'react-toastify';
import RelevePrixMarquuee from './marquee/RelevePrixMarquee';

export default function SuiviReleveprix() {
    const history = useHistory();

    const { datedebut, datefin } = useParams('')
    const searchInput = useRef();
    const [date_debut, setdate_debut] = useState(datedebut)
    const [date_fin, setdate_fin] = useState(datefin)
    const [ok, setok] = useState(false)

    const [codePDV, setCodePdv] = useState("");
    const [selected1, setSelected1] = useState([]);
    const [listemessages, setlistemessages] = useState([])
    const [listeSF, setlisteSF] = useState([])
    const [listepdvs, setlistepdvs] = useState([])
    //Results of serach
    const [liste, setliste] = useState([])
    const [listeGrouped, setlisteGrouped] = useState([])
    //--------- Loader ------------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(()=>{
        getLastRelve().then((result) => {
            if(result?.data?.success === 1){
                setlistemessages(result?.data?.liste)
            }
        }).catch((err) => {
            console.log(err)
        });
    },[])
    const searchItems = () => {
        setloading(true)
        PostrapportSuiviRelevePrix(date_debut, date_fin, codePDV, selected1)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.liste)
                    setlisteGrouped(result?.data?.listeGrouped)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }
    const saveData = () => {
        //Search sous familles / gammes / famille in intervalle [datedebut , datefin]
        setloading(true)
        if (date_debut !== "" && date_fin !== "" ) {
            if (date_debut <= date_fin) {
                setloading(true)
                history.push({
                    pathname: "/suivi/suiviRelevePrix&datedebut=" + date_debut + "&datefin=" + date_fin
                })
        rapportSuiviRelevePrix(date_debut, date_fin).then((result) => {
            if (result?.data?.success === 1) {
                setok(true)
                setlisteSF(result?.data?.listeSousFamilles)
                setlistepdvs(result?.data?.listePDVS)

            }
        }).catch((err) => {
            console.log(err)
        })}
        else if (date_fin < date_debut) {
            toast(t("messages.dateinf"), { type: "warning", position: "top-right" })
        }
    }}
    // Handle Change Marque
    const handleChangePDV = (...args) => {
        setCodePdv(args[1].value)
    };
    
    return (
        <div>
            {/** Header Page */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('suivi')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Suivi Relevé Prix')}</li>
                </ol>
            </div>
             {/**---------------------- INFORMATIONS (Derniers Relve prix) --------------------------- */}
             <div className="row mb-3">
               <RelevePrixMarquuee t={t} listemessages={listemessages} />
            </div>
            <div className='row'>
                <div className='col-4' />
                <div className='col-4'>
                    <div className='input-group'>
                        <input type="date" className='form-control fs-12' value={date_debut} onChange={(e) => setdate_debut(e.target.value)} />
                        <input type="date" className='form-control fs-12' value={date_fin} onChange={(e) => setdate_fin(e.target.value)} />
                        <button className='btn btn-azure' onClick={() => saveData()}> <i className='ti-search' /></button>

                    </div>
                </div>
                <ToastContainer />
                <div className='col-4' />
                {ok ? <>
                    <div className='col-2' />
                    <div className='col-8 mt-2'>
                        <div className='input-group text-center'>
                            <SelectSearch
                                options={listepdvs}
                                search
                                ref={searchInput}
                                onChange={handleChangePDV}
                                filterOptions={fuzzySearch}
                                emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                placeholder={t('Choisir un magasin')}
                            />
                            <MultiSelect
                                options={listeSF}
                                value={selected1}
                                onChange={setSelected1}
                                className="col-7 fs-10"
                                labelledBy="Select"
                            />
                            <button className='btn btn-secondary' onClick={() => searchItems()}> <i className='ti-search' /></button>

                        </div>
                    </div>
                    <div className='col-2' />
                </> : null}
            </div>
            <div className='row mt-4'>
                {loading ? <div className='col-12'>
                    <div className="alert bg-purple mb-5 py-4" role="alert">
                        <div className="d-flex">
                            <i className="icon icon-info mr-3 fs-30"></i>
                            <div>
                                <h4 className="alert-heading fs-16 mt-3"> {t("Vous devez sélectionner les champs nécessaires pour visualiser le tableau de suivi des Releves Prix")} </h4>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div> :
                    <TableauRelevePrix liste={liste} listeGrouped={listeGrouped} t={t} />
                }
            </div>
        </div>)
}
