/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { getEnseigneByCode, listeEnseignes } from '../../../services/Magasins/PdvApi'
import { SearchItems } from '../../../utils/SearchUtils'
// --------------- MODAL ENSEIGNES -----------------------
import ModalAddEnseigne from './ModalsEnseignes/modalAddEnseigne';
import ModalDeleteEnseigne from './ModalsEnseignes/modalDeleteEnseigne'
import ModalUpdateEnseignes from './ModalsEnseignes/ModalUpdateEnseignes'


export default function ListeEnseigne() {
    const [listeTypeMagasins, setlisteTypeMagasins] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    //Loader 
    const [Loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(5);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeTypeMagasins?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)
    //Edit Items 
    const [show, setShow] = useState(true)
    const [showEdit, setShowEdit] = useState(false)
    const [index, setIndex] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    //MODAL  IMPROT EXCEL
    const [ok, setOk] = useState(false)
 //------------- Traduction ---------------
 const [t, i18n] = useTranslation("global");

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeTypeMagasins, search)
            setSearchlist(newList)
        } else {
            setlisteTypeMagasins(saveList)
        }
    }

    useEffect(() => {
        listeEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTypeMagasins(result?.data?.data)
                    setSaveList(result?.data?.data)
                }

            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        listeEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTypeMagasins(result?.data?.data)
                    setSaveList(result?.data?.data)
                }

            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [show])
    //GET DETAILS ENSEIGNES BY CODE 

    const changeDetailsEnseignes = (code) => {
        setLoading(true)
        getEnseigneByCode(code)
            .then((result) => {
                console.log(result.data.data[0])
                setObject(result.data.data[0])

            }).catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }
    return (
        <div className="row">
            <div className="col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                    <div className="card-title mt-3 float-left"><i className="icon icon-settings text-azure mr-2" />{t('Magasins.ListedesEnseignes')} </div>
                        <button className="btn btn-azure float-right fs-12" data-toggle="modal" data-target="#addEnseignes"> <i className="icon icon-plus mr-1" /> {t("Produits.Ajouter")}</button>

                    </div>
                    <ToastContainer />
                    <div className="card-body">
                    <div className="search1 search-elements-sm mb-3 ">
                            <div className="form row no-gutters">
                                <div className="col-7" />
                                <div className="  col-xl-5 col-lg-5 col-md-5 mb-0">
                                    <span className="input-group-append">
                                        <input type="text" ref={inputEl} value={search}
                                            onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-azure fs-12" placeholder={t('Magasins.Chercheruneeseigne')} />
                                        <i className=' ti-search text-azure ml-2 mt-4' />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {Loading ? (
                            <SqaureLoader />
                        ) : (
                            <div className="table-responsive product-datatable">
                                <table
                                    id="example"
                                    className="table table-striped table-bordered "
                                >
                                    <thead className="text-center">
                                        <tr>
                                            <th className="w-15p" style={{ fontSize: "12px" }}>{t('rapports.code')}</th>
                                            <th className="wd-15p" style={{ fontSize: "12px" }}>{t('rapports.Enseignes')}</th>
                                            <th className="wd-15p" style={{ fontSize: "12px" }}>{t('rapports.Magasins')}</th>
                                            <th className="wd-15p" style={{ fontSize: "12px" }}>{t('Produits.Couleur')}</th>
                                            <th className="wd-15p" style={{ fontSize: "12px" }} >{t('rapports.ACTION')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {currentListe?.map((items, keys) => (
                                            <tr key={keys}
                                            className={
                                                keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                            }>
                                                <td style={{ fontSize: "12px" }}>{items.code} </td>
                                                <td style={{ fontSize: "12px" }}>{items.nomenseigne} </td>
                                                <td style={{ fontSize: "12px" }}>{items.total} </td>

                                                <td style={{ fontSize: "12px" }} className="text-center">
                                                    <div className=" mb-0" style={{ textAlign: "center", display: "inline-flex" }}>
                                                        <div className="w-6 h-4  text-center " style={{ background: items.color, alignContent: "center" }} />                                                            <div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ cursor: "pointer" }}>
                                                    <a data-toggle="modal" data-target="#exampleModal" onClick={() => {
                                                        changeDetailsEnseignes(items.code); setCode(items.code); setIndex(keys); setShowEdit(true)
                                                    }}> <i className="icon icon-note text-azure" /></a>
                                                    <a data-toggle="modal" data-target="#modal-notification" onClick={() => {
                                                        setCode(items.code); setIndex(keys);
                                                    }}> <i className="ti-trash text-danger" /></a>

                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="float-right">
                                    <PaginationTable
                                        postsPerPage={stocksPerPage}
                                        totalPosts={search.length < 1 ? listeTypeMagasins?.length : searchList?.length}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>
            {showEdit ? <ModalUpdateEnseignes setShowEdit={setShowEdit} setShow={setShow} keys={index} object={object} /> : null}
            <ModalAddEnseigne setShow={setShow} />
            <ModalDeleteEnseigne code={code} index={index} setOk={setOk} />

        </div>
    )
}
