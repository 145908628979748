/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getListeAllPDV } from '../../../services/Dashboard/FirstPage'

export default function ModalAllPlanifiee({ t, user_code, code_jour, setok7, today }) {
  //------------- LISTE Promotions ----------
  const [listePdvUser, setlistePdvUser] = useState([])
  const [loading, setloading] = useState(true)

  useEffect(() => {
    setloading(true)
    //Call WebService with liste of promotions
    getListeAllPDV(today, code_jour, user_code)
      .then((result) => {
        if (result?.data?.success === 1) {
          setlistePdvUser(result?.data?.data)
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        setloading(false)
      })
  }, [today, user_code])

  return (
    <div className="modal fade" id="getALLpdv" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="getALLpdv" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" style={{ width: "max-content" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="getALLpdv"> <i className='fe fe-tag text-azure mr-1' /> {t('dashboard.pdvplanifiee')}   </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setok7(false);setlistePdvUser([])}}> 
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {loading ? <SqaureLoader /> : (
              <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '500px' }}>
                <table className="table  table-bordered ">
                  <thead className="text-center  bg-purple fs-10" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                  <td className='fs-10'>#</td>
                    <td className="wd-15p fs-10">{t('dashboard.Magasin')}</td>
                    <td className="wd-15p fs-10">{t('Routings.frequence')}</td>
                    <td className="wd-15p fs-10">{t('rapports.Routings')}</td>
                  </thead>
                  <tbody>
                    {listePdvUser?.map((items, keys) => (
                      <tr key={keys} className="text-center fs-10">
                        <td className='fs-10'>{keys+1}</td>
                        <td>{items.nompdv}</td>
                        <td>{items.frequence}</td>
                        <td><a href={"/tournee/code="+items.code} className="text-primary">{items.code}</a></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  )
}
