import React, { useEffect, useState } from 'react'
import { getAllCMD } from '../../services/Produits/ProduitsAPI';
import SqaureLoader from '../../components/Loader/SqaureLoader';
import ExportExcel from './ExportExcel';
import _ from 'lodash';


export default function ModalExport({dateDebut,dateFin ,commande_code ,keys, handleChange ,noHandle}) {
    const [loader, setloader] = useState(true)
    const [LISTE, setLISTE] = useState([])
    const [ListeCMD, setListeCMD] = useState([])
    useEffect(() => {
        getAllCMD(dateDebut,dateFin).then((result) => {
            if (result?.data?.success === 1) {
                setLISTE(result?.data?.commandes);
                if(commande_code!==""){
                   var L= result?.data?.commandes?.filter((el)=>el.commande_code===commande_code)
                   var Array_CMD = []
                   L.map((items)=>{
                    Array_CMD.push({
                        produit_code : items.produit_code,
                        quantite : items.quantite
                    })
                   })
                   setListeCMD(_.uniqBy(Array_CMD,"produit_code"))
                }
            }
        }).catch((err) => {
            console.log(err);
        }).finally(()=>{
            setloader(false)
        })
    }, [])
    
  return (
    <>
        {loader?<SqaureLoader/> : 
        <ExportExcel excelData={commande_code!==""? ListeCMD: LISTE} fileName={"Commandes"} commande_code={commande_code} keys={keys} handleChange={handleChange} noHandle={noHandle}/>}
    </>
  )
}
