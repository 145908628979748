/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useTranslation } from 'react-i18next';
import TableauUser from './ParmetrageUser/affectationUser/TableauUser';
import TableauZone from './ParmetrageUser/affectationZone/TableauZone';
import TableMailingUser from './ParmetrageUser/MailingUser/TableMailingUser';
import ListeRoles from './ParmetrageUser/Roles/ListeRoles'
import ListeSocite from './ParmetrageUser/Societe/ListeSocite'

export default function ListeParametrage() {

    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.utilisateurs')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">                            {t('sidebar.parametrageUtilisateur')}
                    </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/users/liste" className="btn btn-azure btn-icon-text fs-12  mr-2 d-none d-md-block">
                            <i className="fe fe-download" />
                            {t('sidebar.listeUtilisateurs')}
                        </a>
                        <a href="/users/ajouterutilisateur" className="btn btn-azure fs-12 btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-wrench mr-1" />
                            {t('sidebar.ajoutUser')}
                        </a>
                       
                    </div>
                </div>
            </div>
            <div className="row">
                {details?.role_code==="admin"  ?  
                <>
                <div className="col-12 col-md-12 col-lg-12  col-sm-12 col-xl-12">
                    <TableMailingUser t={t} />
                </div>
                              </> : null} 
                <div className="col-12 col-md-12 col-lg-12  col-sm-12 col-xl-12">
                    <ListeSocite t={t} />
                </div>
                <div className="col-12 col-md-12 col-lg-12  col-sm-12 col-xl-12">
                    <ListeRoles t={t} />
                </div>
                <div className="col-12 col-md-6 col-lg-6  col-sm-12 col-xl-6">
                    <TableauUser t={t} />
                </div>
                <div className="col-12 col-md-6 col-lg-6  col-sm-12 col-xl-6">
                    <TableauZone t={t} />
                </div>
            </div>

        </div>
    )
}
