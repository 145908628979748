/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React, { useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import { generateRandomColor } from '../../../utils/Colors';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function GrapheparGamme({ t, gammes, listeGammes, loading, date1, date2 }) {

    const [title, settitle] = useState("")
    //---------- ITEMS Graphes --------------
    const [labels, setlabels] = useState([])
    const [data, setdata] = useState([])

    const detailsGammes = (g, name) => {
        if (g === "all") {
            // Grouped  by date Exemple ['2022-02-01','2022-01-17']
            const gr = _.groupBy(listeGammes, "date")
            const x = []
            for (let value of Object.keys(gr)) {
                x.push(moment(value).format("YYYY-MM-DD"))
            }
            const l = _.uniq(x)
            setlabels(l)
            const gr2 = _.groupBy(listeGammes, 'gamme')
            var ga = []
            for (let value of Object.keys(gr2)) {
                ga.push(value)
            }
            const l1 = _.uniq(ga)

            console.log(l1)
            const arr = []
            for (let value of Object.values(gr2)) {
                const te = []
                const lg = value.length
                value.map((i, k) => {
                    if (lg === l.length) {
                        te.push(i.total)
                    } else {
                        l.map((y, z) => {
                            if (moment(i.date).format('YYYY-MM-DD') === y) {
                                te.splice(z, z, i.total);
                            } else {
                                te.splice(z, z, 0);
                            }
                        })
                    }
                })
                arr.push({
                    label: value[0].gamme,
                    backgroundColor: generateRandomColor(),
                    borderWidth: 1,
                    data: te
                })
                setdata(arr)
            }
        } else {
            settitle(name)
            const li = listeGammes?.filter((el) => el.gamme_code === g)
            const gr = _.groupBy(li, "date")
            const x = []
            for (let value of Object.keys(gr)) {
                x.push(moment(value).format("YYYY-MM-DD"))
            }
            const l = _.uniq(x)
            setlabels(l)
            var y = []
            var colors = []
            var arr = []
            for (let value of Object.values(gr)) {
                y.push(value[0].total)
                colors.push(generateRandomColor())
            }
            arr.push({
                label: t('dashboard.rupturepargamme') + title,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                data: y
            })
            console.log(arr)
            setdata(arr)
        }

    }
    return (
        <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
                <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title">
                        <a role="button" data-toggle="collapse" data-parent="#accordion1" className='fs-12' href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <i className="icon icon-grid text-primary mr-2" /> {t('dashboard.rupturepargamme')}
                            <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                        </a>
                    </h4>
                </div>
                <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                        {loading ? <SqaureLoader /> :
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>
                                <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                    <label className='mr-3 fs-12'><i className='icon icon-equalizer mr-1' /> {t('rapportGraph.filtrepargamme')} : </label>
                                    <button className="btn tag bg-azure text-white" onClick={() => detailsGammes("all", "all")}> {t('rapports.ALL')} </button>

                                    {gammes?.map((items, keys) => (
                                        <button className="btn tag bg-purple" key={keys} onClick={() => detailsGammes(items.gamme_code, items.gamme)}> {items.gamme}</button>
                                    ))}
                                </div>
                                <Bar
                                    data={{
                                        labels: labels,
                                        datasets: data
                                    }}

                                    options={{
                                        plugins: {
                                            legend: {
                                                possition: 'top',
                                            },
                                            title: {
                                                display: true,
                                                text: t('dashboard.rupturepargamme') + " " + title
                                            }
                                        },
                                        scales: {
                                            x: {
                                                display: true,
                                                title: {
                                                    display: true,
                                                    text: t('dashboard.Date'),
                                                    font: {
                                                        family: 'Poppins',
                                                        size: 16,
                                                        style: 'normal',
                                                        lineHeight: 1.2
                                                    },
                                                    padding: { top: 20, left: 0, right: 0, bottom: 0 }
                                                }
                                            },
                                            y: {
                                                display: true,
                                                title: {
                                                    display: true,
                                                    text: t('Produits.Quantite'),
                                                    font: {
                                                        family: 'Poppins',
                                                        size: 16,
                                                        style: 'normal',
                                                        lineHeight: 1.2
                                                    },
                                                    padding: { top: 30, left: 0, right: 0, bottom: 0 }
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
