/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getDetailsPL } from '../../../services/Dashboard/FirstPage'

export default function ModalPL({ t, user_code, setok4, today }) {

  //------------- LISTE PartLinéaires ----------
  const [listePL, setlistePL] = useState([])
  const [loading, setloading] = useState(true)

  useEffect(() => {
    setloading(true)
    //Call WebService with liste of pl
    getDetailsPL(today, user_code)
      .then((result) => {
        if (result?.data?.success === 1) {
          setlistePL(result?.data?.pl)
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        setloading(false)
      })
  },  [today,user_code])
  
  return (
    <div className="modal fade" id="exampleModal2"   data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="exampleModal2" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" style={{ width: "max-content" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModal2"> <i className='fe fe-tag text-azure mr-1' /> <u> <a href={"/rapports/rapportTableauPL&datedebut=" + today + "&datefin=" + today} > {t('sidebar.detailPl')} </a> </u>    </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setok4(false);setlistePL([])}}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {loading ? <SqaureLoader /> : (
              <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '500px' }}>
                <table className="table  table-bordered ">
                  <thead className="text-center  bg-purple fs-10" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                  <td className='fs-10'>#</td>
                    <td className="wd-15p fs-10">{t('Produits.Enseigne')}</td>
                    <td className="wd-15p fs-10">{t('dashboard.Magasin')}</td>
                    <td className="wd-15p fs-10">{t('dashboard.cible')}</td>
                    <td className="wd-15p fs-10">{t('Produits.Gamme')}</td>
                    <td className="wd-15p fs-10">{t('dashboard.type')}</td>
                    <th className="wd-15p fs-10 text-white">{t('rapports.facing')}</th>
                    <th className="wd-15p fs-10 text-white">{t('rapports.facingtotal')}</th>
                    <th className="wd-15p fs-10 text-white" >{t("dashboard.pl")} %</th>
                  </thead>
                  <tbody>
                    {listePL?.map((items, keys) => (
                      <tr key={keys} className="text-center fs-10">
                        <td className='fs-10'>{keys+1}</td>
                        <td>{items.nom_enseigne}</td>
                        <td>{items.nompdv}</td>
                        <td>{items.cible}</td>
                        <td>{items.gamme}</td>
                        <td>{items.type}</td>
                        <td > {items.plconstate} </td>
                        <td > {items.pltotal} </td>
                        <td > {parseFloat((items.plconstate / items.pltotal) * 100).toFixed(2)} </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}
