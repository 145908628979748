import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'


dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
const role=user?.result?.role_code
const user_code =user?.result?.code

/**
 * 
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} datedebut 
 * @param {*} datefin 
 * @returns 
 */
 export async function getlistePartLineaire(limit,offset , dateDebut , dateFin) {
    try {
        const response = await axios.get(url + "partlineaire/limit="+limit+"&offset="+offset+"/datedebut="+dateDebut+"&datefin="+dateFin+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

export async function searchPartLineaire(dateDebut , dateFin  ,data) {
    try {
        const response = await axios.post(url + "partlineaire/search/datedebut="+dateDebut+"&datefin="+dateFin+"/"+role+"/"+user_code, data,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function searchPartLineaireTotal(dateDebut , dateFin  ,data) {
    try {
        const response = await axios.post(url + "partlineaire/total/datedebut="+dateDebut+"&datefin="+dateFin+"/"+role+"/"+user_code, data,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

export async function partLineaireA(annee ) {
    try {
        const response = await axios.get(url + "partlineaire/partlineaireAnnuel/"+annee+"/"+role+"/"+user_code,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function partLineaireM( dateDebut , dateFin) {
    try {
        const response = await axios.get(url + "partlineaire/partlineaireMensuelle/"+dateDebut+"/"+dateFin+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}