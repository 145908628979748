/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {  updateRolebyCode } from '../../../../services/Users/UsersApi'

export default function ModalEdIitRole({ code, index, setOk ,object ,setShow ,t}) {

       //-------- FORMULAIRE -----------
       const [libelle, setlibelle] = useState("")

       useEffect(()=>{
        setlibelle(object?.libelle)
       },[object])

       const updateCode = () => {
        const data = {
            libelle : libelle
        }
        updateRolebyCode (code, data)
        .then((result) => {
            if (result?.data?.success === 1) {
                toast(t('messageErreur.modifsuccee'), { type: "success", position: 'top-right' })
                setShow(false)
            } else {
                toast(t('messageErreur.erreurdemodification'), { type: "error", position: 'top-right' })
            }
            
        }).catch((err) => {
            console.log(err)
        });

     }

    return (
       <div className="modal fade" id="modal-editer" tabIndex={-1} role="dialog" aria-labelledby="modal-editer" aria-hidden="true" key={index}>
            <div className="modal-dialog modal-danger" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal-title-editer">{t('users.modifierRole')} : {code} </h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                     
                                <div className="card-body">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.code')} </label>
                                        <input type="text" value={code} disabled readOnly  className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('dashboard.Libelle')} <span className="text-danger">*</span></label>
                                        <input type="text" value={libelle} onChange={(e)=>setlibelle(e.target.value)}  className="form-control" />
                                    </div>
                                </div>
                        

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => updateCode()} >{t('rapports.enregistrer')}</button>
                        <button type="button" className="btn btn-secondary text-white ml-auto" data-dismiss="modal" onClick={() => setOk(true)}>{t('Routings.annueler')} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
