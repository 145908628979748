/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { toast} from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import {  getListeRegions, getListeSecteurs, updateZone } from '../../../services/Zoning/ZoningApi'

export default function ModalUpdateZone({ code, index,object, setShow ,t }) {
    //------ Formulaire -----------
    const [libelle, setlibelle] = useState()
    const [codeSecteur, setCodeSecteur] = useState("")
    const [codeRegion, setcodeRegion] = useState("")
    // ------ Liste des secteurs ------------
    const [listeSecteurs, setListeSecteurs] = useState([])
    const [listeRegions, setListeRegions] = useState([])
    //-------- Loading -----------------------
    const [loading, setloading] = useState(true)

    useEffect(() => {
        setloading(true)
        setlibelle(object?.libelle)
        setCodeSecteur(object?.secteur_code)
        setcodeRegion(object?.region)

         /**
         * Get liste Secteurs
         */
          getListeSecteurs()
          .then((result) => {
              if (result?.data?.success === 1) {
                  setListeSecteurs(result?.data?.data)
              }
          }).catch((err) => {
              console.log(err)
          })
      /**
       * Get Liste Regions
       */
      getListeRegions()
          .then((result) => {
              if (result?.data?.success === 1) {
                  setListeRegions(result?.data?.data)
              }
          }).catch((err) => {
              console.log(err)
          })
        //Get liste of zone 
        setloading(false)
     
    }, [object])

    const saveData = () =>{
        //Data of code & libelle FOR OBJECT PAYS
        if (code !== "" && libelle!=="") {
            const data = {
                code: code,
                libelle: libelle
            }
            if(codeRegion !== null){
                Object.assign(data,{ region: codeRegion})
            }
            if(codeSecteur !== null){
                Object.assign(data,{ secteur_code: codeSecteur})
            }

            updateZone(code, data)
                .then((result) => {
                    if (result?.data?.success === 0) {
                        toast(t('Zoning.Erreur'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        toast("La zone est modifié avec succée !", {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    return (
        <div className="modal fade" id="updateZone" tabIndex={-1} role="dialog" aria-hidden="true" key={index} >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-azure" id="updateZone"> <i className='icon icon-map mr-1' /> {t('Zoning.modifierZone')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() =>{setShow(false)}} /> </span>
                    </div>

                    <div className="modal-body">
                        {loading ? <SqaureLoader /> : (
                            <form>
                                <div className='row'>
                                    <div className=" form-group col-6">
                                        <label className="form-control-label">{t('rapports.code')} <span className="text-danger">*</span></label>
                                        <input type="text" value={code} disabled className="form-control" />
                                    </div>
                                    <div className="form-group col-6">
                                        <label className="form-control-label">{t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                        <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="mr-2 mt-1 mb-sm-1"> {t('dashboard.region')} <span className="text-danger">*</span></label>
                                    <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                        value={codeRegion}
                                        onChange={(e) => {
                                            setcodeRegion(e.target.value)
                                        }}>
                                        <option value={0}> choisir </option>
                                        {listeRegions?.map((items, keys) => (
                                            <option value={items.code} key={keys}> {items.libelle} </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="mr-2 mt-1 mb-sm-1"> {t('Zoning.Secteur')} <span className="text-danger">*</span></label>
                                    <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                        value={codeSecteur}
                                        onChange={(e) => {
                                            setCodeSecteur(e.target.value)
                                        }}>
                                        <option value={0}> choisir </option>
                                        {listeSecteurs?.map((items, keys) => (
                                            <option value={items.code} key={keys}> {items.libelle} </option>
                                        ))}
                                    </select>
                                </div>
                            </form>
                        )}

                    </div>
                    <div className="modal-footer ">
                        <button type="button" className="btn btn-azure fs-12 " onClick={() => saveData()} > <i className='icon icon-cloud-download mr-1' />Enregister </button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" onClick={() => {setShow(false)}}> <i className="ti-close mr-1" />Fermer</button>

                    </div>
                </div>
            </div>
        </div>)
}
