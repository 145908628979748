/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { getRapportSuiviPointageImageSortie } from '../../../services/Rapports/RapportsApi';

export default function ModalImagePointageFin({ date, user_code, pdv_code, setok2,visiteCode,t }) {
    const [data, setdata] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getRapportSuiviPointageImageSortie(date, pdv_code, user_code,visiteCode)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setdata(result?.data?.liste[0])
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [])
    return (
        <div className="modal fade" id="exampleModalfin" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog" aria-hidden="true" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3">{t('rapports.pointagefin')}</h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }}   data-dismiss="modal" aria-label="Close" onClick={() => setok2(false)}  /> </span>

                    </div>
                    {loading ? <SqaureLoader /> : (
                        <>
                            <div className="modal-body">
                                <img src={data?.path === null || data?.path === undefined ? "../assets/images/users/17.jpg" : data?.path} alt="image_pointage" />
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-yellow" data-dismiss="modal" aria-label="Close" onClick={() => setok2(false)} >
                                    <i className=' ti-close mr-1' /> {t('dashboard.Fermer')}
                                </button>
                            </div>
                        </>)}
                </div>
            </div>
        </div>
    )
}
