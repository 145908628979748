/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { toast, ToastContainer } from 'react-toastify'
import { addConventionsProduits, deleteConventions, getSelectCategorie } from '../../../services/Magasins/PdvApi'
import { getListeConventionEnseignes, getListeConventions, getListeOfCategorieConventions, getSelctEnseignes } from '../../../services/Produits/ProduitsAPI'
import AddConventions from './AddConventions'
import DescriptionConventions from './DescriptionConventions'

export default function AjouterConventionProduits() {
    const { code } = useParams('')
    const enseigne = useRef();
    const categorie = useRef();

    const [listeConventions, setlisteConventions] = useState([])
    const [description, setdescription] = useState({})
    //-------------------- FORMULAIRE-----------------
    const [dateDebut, setdateDebut] = useState("")
    const [dateFin, setDateFin] = useState("")
    const [libelle, setlibelle] = useState("")
    const [produit, setProduit] = useState("")
    const [codeenseigne, setcodeenseigne] = useState("-1")
    const [listeEnseignes, setlisteEnseignes] = useState([])
    const [codeCategorie, setcodeCategorie] = useState("-1")
    const [listeCategorie, setlisteCategorie] = useState([])
    const [listeCategorieEnse, setlisteCategorieEnse] = useState([])
    const [listeTypes, setlisteTypes] = useState([])
    //Loader 
    const [Loading, setLoading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    const traitement = () => {
        getListeConventions(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteConventions(result?.data?.liste_convention)
                    setdescription(result?.data?.description)
                    setProduit(result?.data?.description?.libelle)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        getSelectCategorie()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteCategorie(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            })
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnseignes(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            })
        getListeConventions(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteConventions(result?.data?.liste_convention)
                    setdescription(result?.data?.description)
                }
            }).catch((err) => {
                console.log(err)
            });
        traitement()
    }, [])

    const saveConvention = () => {
        if (dateDebut !== "" && dateFin !== "" && libelle !== "" && codeenseigne !== "-1" && codeCategorie !== -1) {
            const arr = []
            if (listeTypes?.length !== 0) {
                listeTypes?.map((items) => {
                    const data = {
                        code: "conv-" + parseInt(Date.now() * Math.random()),
                        libelle: libelle,
                        produit_code: code,
                        date_debut: dateDebut,
                        date_fin: dateFin,
                        enseigne_code: codeenseigne,
                        categorie_code: items.categorie_code,
                        type: "rupture"
                    }
                    arr.push(data)
                })
            } else {
                const data = {
                    code: "conv-" + parseInt(Date.now() * Math.random()),
                    libelle: libelle,
                    produit_code: code,
                    date_debut: dateDebut,
                    date_fin: dateFin,
                    enseigne_code: codeenseigne,
                    categorie_code: codeCategorie,
                    type: "rupture"
                }
                arr.push(data)
            }

            const l = _.uniq(arr)
            addConventionsProduits(l).then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('messageErreur.ajoutavecsuccee'), { type: "success", position: "top-right" })
                    traitement()
                    setcodeenseigne("-1")
                    setcodeCategorie("-1")
                    setdateDebut("")
                    setDateFin("")
                    setlibelle("")

                } else {
                    toast(t('messageErreur.erreurajoutconv'), { type: "error", position: "top-right" })
                }
            }).catch((err) => {
                console.log(err)
            });
            //IF CONVENTION ADDED SUCCESSFULLY

        } else {
            if (dateDebut > dateFin) {
                toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
            }
            if (libelle === "") {
                toast(t('messageErreur.messageattconv'), { type: "warning", position: "top-right" })

            }
            if (dateDebut === "" || dateFin === "") {
                toast(t('messageErreur.saisiedatevalide'), { type: "warning", position: "top-right" })

            }
        }
    }
    // Handle Change Famille
    const handleEnseigne = (...args) => {
        setcodeenseigne(args[1].value)
        getListeConventionEnseignes(args[1].value).then((result) => {
            if (result?.data?.success === 1) {
                setlisteCategorieEnse(result?.data?.liste)
                console.log(result?.data?.liste)
            }
        }).catch((err) => {
            console.log(err)
        });
    };
    // Handle Change Famille
    const handleCategorie = (...args) => {
        setcodeCategorie(args[1].value)
        getListeOfCategorieConventions(codeenseigne, args[1].value)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTypes(result?.data?.getListeOfTypes)
                }
            }).catch((err) => {
                console.log(err)
            });
    };

    const SupprimerConventions = (code) => {
        deleteConventions(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast('La convention a été supprimé avec succée', { type: "success", position: 'top-right' })
                    traitement()
                } else {
                    toast('Erreur !!  ', { type: "error", position: 'top-right' })

                }
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><i className="fe fe-life-buoy mr-1" /> {t('conventions.convention')} </li>
                    <li className="breadcrumb-item active" aria-current="page">{t('conventions.ajoutconv') + " : " + code}</li>
                </ol>

            </div>
            <ToastContainer />
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <AddConventions t={t} libelle={libelle} setlibelle={setlibelle} produit={produit} dateDebut={dateDebut} setdateDebut={setdateDebut} dateFin={dateFin}  setDateFin={setDateFin}  listeEnseignes={listeEnseignes}  codeenseigne={codeenseigne}   listeCategorieEnse={listeCategorieEnse}  enseigne={enseigne} handleEnseigne={handleEnseigne} listeTypes={listeTypes} listeCategorie={listeCategorie} codeCategorie={codeCategorie} categorie={categorie} handleCategorie={handleCategorie} saveConvention={saveConvention}/>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <DescriptionConventions t={t} listeConventions={listeConventions} Loading={Loading} SupprimerConventions={SupprimerConventions} code={code} description={description}/>
                </div>
            </div>
        </div>
    )
}
