/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getDetailRelvePrix } from '../../../services/Dashboard/FirstPage'

export default function ModalRelevePrix({ t, user_code, setok3, today }) {
    //------------- LISTE Promotions ----------
    const [listeRelevePrix, setlisteRelevePrix] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {
        setloading(true)
        getDetailRelvePrix(today, user_code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteRelevePrix(result?.data?.relevePrix)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    },  [today,user_code])
    return (
        <div className="modal fade" id="exampleModal4"  data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="exampleModal4" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={{ width: "max-content" }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModal4"> <i className='fe fe-tag text-azure mr-1' />  {t('relevePrix.detailsReleve')} </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setok3(false);setlisteRelevePrix([])}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {loading ? <SqaureLoader /> : (
                            <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '500px' }}>
                                <table className="table  table-bordered ">
                                    <thead className="text-center bg-purple" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                        <td className='fs-10'>#</td>
                                        <td className="wd-15p fs-10">{t('rapports.CODEABARRE')}</td>
                                        <td className="wd-15p fs-10">{t('dashboard.Magasin')}</td>
                                        <td className="wd-15p fs-10">{t('rapportrupture.produit')}</td>
                                        <td className="wd-15p fs-10">{t('rapports.permanent')}</td>
                                        <td className="wd-15p fs-10">{t('rapports.promotion')}</td>

                                    </thead>
                                    <tbody>
                                        {listeRelevePrix?.map((items, keys) => (
                                            <tr key={keys} className="text-center">
                                                <td className='fs-10'>{keys+1}</td>
                                                <td className="wd-15p fs-10">{items.cab}</td>
                                                <td className="wd-15p fs-10">{items.nompdv}</td>
                                                <td className="wd-15p fs-10">{items.produit}</td>
                                                <td className="wd-15p fs-10">{items.permanent}</td>
                                                <td className="wd-15p fs-10">{items.promo}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    {/*  <div className='modal-footer '>
                        <button className='btn btn-secondary float-right ' onClick={() => setok3(false)}><i className='ti-close mr-1'/> Fermer</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
