/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getConventionByCode } from '../../../services/Magasins/PdvApi';

export default function DetailsConventions() {
    //--------- CODE -----------
    const { code } = useParams('')
    //-------- LISTE ----------
    const [details, setdetails] = useState({})

    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");


    useEffect(() => {
        getConventionByCode(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setdetails(result?.data?.data[0])
                }
            }).catch((err) => {
                console.log(err)
            });
    }, [])

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.Magasin')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('conventions.convention')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Parametragemagasins')}
                        </a>
                        <a href="/pdv/ajouterPDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-plus mr-1" />
                            {t('Magasins.AjouterPDV')}
                        </a>
                        <button className='btn btn-primary mr-2 d-none d-md-block fs-10 '> <i className='fe fe-arrow-left mr-1' /> {t('rapports.retour')} </button>

                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title mt-2 ">
                            <i className='icon icon-book-open text-azure  mr-1' /> {t('conventions.detailsconventions')}
                        </div>
                        <div className='card-body'>
                            <div className="table-responsive mb-5">
                                <table className="table row table-borderless w-100 m-0 border">
                                    <tbody className="col-lg-6 p-0">
                                        <tr>
                                            <td><strong>{t('conventions.codeConvention')} </strong> {code}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('conventions.plcontrat')} :</strong> {details?.plcontrat === null ? <i className='ti-minus text-azure ml-2' /> : details?.plcontrat}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('Routings.datedebut')}</strong> {moment(details?.date_debut).format("DD-MM-YYYY")} </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('Routings.datefin')}	 :</strong>{moment(details?.date_fin).format("DD-MM-YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('rapportrupture.produit')}  :</strong> {details?.produit} </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('dashboard.type')} :</strong> {details?.type} </td>
                                        </tr>
                                    </tbody>
                                    <tbody className="col-lg-6 p-0">
                                        <tr>
                                            <td><strong>{t('conventions.implentation')} :</strong> {details?.implentation === null ? <i className='ti-minus text-azure ml-2' /> : details?.implentation} </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('rapportrupture.categorie')} :</strong>{details?.categorie === null ? <i className='ti-minus text-azure ml-2' /> : details?.categorie}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('rapports.Enseignes')} :</strong>  {details?.enseignes === null ? <i className='ti-minus text-azure ml-2' /> : details?.enseignes} </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('dashboard.Gamme')} :</strong>  {details?.gamme === null ? <i className='ti-minus text-azure ml-2' /> : details?.gamme}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('Produits.FAMILLE')} :</strong>  {details?.famille === null ? <i className='ti-minus text-azure ml-2' /> : details?.famille}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{t('Produits.SousFamille')}</strong> {details?.sousfamilles === null ? <i className='ti-minus text-azure ml-2' /> : details?.sousfamilles} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
