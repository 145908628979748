import React from 'react';

export default function CardTopVente({  flop5, toprpoduits ,t }) {
    return (
        <div className='row'>
        
            <div className='col-lg-6 col-xl-6 col-12'>
                <div className="card" style={{overflow: "scroll", height:" 450px"}}>
                    <div className="card-body">
                        <div className="item-box text-center">
                            <div className="bg-pink-transparent icon-bg icon-service text-pink">
                                <i className='icon icon-arrow-down-circle' />
                            </div>
                            <div className="item-box-wrap">
                                <h5 className="mb-2"> {t('rapportVentes.flopproduit')} </h5>
                                <table className="table  table-bordered  mt-3">
                                    <thead>
                                        <tr  className='text-center'>
                                            <th>{t('rapportVentes.flop')}</th>
                                            <th>{t('sidebar.produits')}</th>
                                            <th>{t('Produits.TotalProduits')}</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                    {flop5?.map((items, keys) => (
                                        <tr className={ keys%2===1 ? 'text-center bg-pink-transparent ':""}>
                                            <td>{keys+1}</td>
                                            <td className='fs-14'>{items.produits}</td>
                                            <td>{items.totProduits}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className='col-lg-6 col-xl-6 col-12'>
                <div className="card" style={{overflow: "scroll", height:" 450px"}}>
                    <div className="card-body">
                        <div className="item-box text-center">
                            <div className="bg-success-transparent icon-bg icon-service text-success">
                                <i className="icon icon-badge" />
                            </div>
                            <div className="item-box-wrap">
                                <h5 className="mb-2">{t('rapportVentes.topproduits')}</h5>
                                <table className="table  table-bordered  mt-3" >
                                    <thead >
                                        <tr className='text-center'>
                                            <th>{t('rapportVentes.top')}</th>
                                            <th>{t('sidebar.produits')}</th>
                                            <th> {t('Produits.TotalProduits')}</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                    {toprpoduits?.map((items, keys) => (
                                        <tr className={ keys%2===1 ? 'text-center bg-success-transparent ':""}>
                                            <td>{keys+1}</td>
                                            <td className='fs-12'>{items.produits}</td>
                                            <td>{items.totProduits}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
              </div>
        </div>
    )
}
