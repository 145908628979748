import React from 'react'
import ExportToExcel from '../../../components/exportExcel/ExportToExcel'

export default function TableauGestion({ countPDV_planifieee, t, listeDash , pdv_planifiee,releve, pl ,promot , rupture  }) {
    
    const findElement = (type , user_code)=>{
       /*  if(type === "pdv_planifiee"){
            const liste = pdv_planifiee?.filter((el) => el.user_code === user_code )
            if(liste[0]?.pdv_planifiee !== undefined){
                return liste[0]?.pdv_planifiee
            }else return 0
        }else */ if (type === "rupture"){
            const liste = rupture?.filter((el) => el.user_code === user_code )
            if(liste[0]?.total_rupture !== undefined){
                return liste[0]?.total_rupture
            }else return 0
        }else if (type === "releve"){
            const liste = releve?.filter((el) => el.user_code === user_code )
            if(liste[0]?.total_relevePrix !== undefined){
                return liste[0]?.total_relevePrix
            }else return 0
        }else if (type === "promotion"){
            const liste = promot?.filter((el) => el.user_code === user_code )
            if(liste[0]?.total_promotions !== undefined){
                return liste[0]?.total_promotions
            }else return 0
        }else if (type === "pl"){
            const liste = pl?.filter((el) => el.user_code === user_code )
            if(liste[0]?.total_pl !== undefined){
                return liste[0]?.total_pl
            }else return 0
        }
    }
    return (
        <div className="card-body">
            <span className="fs-12 text-azure mb-3"><i className='icon icon-exclamation mr-1' /> {t('dashboard.formatheure')}</span>
            <div className='float-right'>
                <ExportToExcel id="table-to-xls" namefile="rapport_dashboard" t={t} />
            </div>
            <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '600px' }}>
                <table id="table-to-xls" className="table  table-bordered  " >
                    <thead className="text-center font-weight-normal" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >

                        <tr >
                            <td className="w-5p fs-10 text-center "> {t('dashboard.Merchandiseur')} </td>
                            <td className="w-10p fs-10">{t('dashboard.heuredebut')}</td>
                            <td className="w-10p fs-10">{t('dashboard.heureFin')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.hinpdv')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.houtpdv')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.pdvvisite')}</td>
                            <td className="wd-10p fs-10">{t('nbpdvvisite')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.pdvvisiteplanifiee')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.pdvplanifiee')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.txrespectournee')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.txsuccees')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.partl')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.rupture')}</td>
                            <td className="wd-10p fs-10">{t('relevePrix.relevePrix')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.Promotions')}</td>

                        </tr>
                    </thead>
                    <tbody>
                        {listeDash?.map((items, keys) => (
                            <tr className='text-center fs-10' key={keys}>
                                <td className='text-left'> <b> {items.user} </b>
                                    {items.validation === 1 ? <span className="ml-1 badge badge-success  mr-1 mb-1 mt-1">{t('Congé')}</span> : (items.validation === 0 ? <span className=" badge badge-danger  mr-1 mb-1 mt-1"><a href='/users/conge' className='text-white'> {t("Demande Congé")}</a> </span> : null)}
                                </td>
                                <td className='fs-10'>{items.heure_debut !== null ? (items.heure_debut).slice(0,5) : "-"}</td>
                                <td className='fs-10'>{items.heure_fin !== null ? (items.heure_fin).slice(0,5) : "-"}</td>
                                <td className="wd-10p fs-10">{items?.inMagasins === "00:00:00.000000" || items?.inMagasins === null ? '-' : (items?.inMagasins).slice(0, 5)}</td>
                                <td className="wd-10p fs-10">{items?.OutPDV === "00:00:00" || items?.OutPDV === null ? '-' : ((items?.OutPDV).slice(0, 5))}</td>
                                <td data-toggle="modal" data-target="#pdvVisité"><b style={{ cursor: 'pointer' }} className={items.pdv_visite_month !== 0 ? "text-primary" : ""}> {items.pdv_visite_month !== undefined ? items.pdv_visite_month : 0} </b></td>
                                <td>{items.nbpdvvisite} </td>
                                <td data-toggle="modal" data-target="#pdvplanifiee"> <b style={{ cursor: 'pointer' }} className={items.pdv_visite_planifiee_month !== 0 ? "text-primary" : ""}> {items.pdv_visite_planifiee_month !== undefined ? items.pdv_visite_planifiee_month : 0} </b> </td>
                                <td data-toggle="modal" data-target="#getALLpdv"> <b style={{ cursor: 'pointer' }} className={countPDV_planifieee[items?.user_code] !== undefined ? "text-primary" : ""}> {countPDV_planifieee[items?.user_code] !== undefined ? countPDV_planifieee[items?.user_code][0]?.nbr_pdv_month : 0} </b> </td>
                                <td> <b style={{ cursor: 'pointer' }} className={countPDV_planifieee[items?.user_code] !== undefined ? "text-primary" : ""}> {countPDV_planifieee[items?.user_code] === undefined  ? 0 : (parseFloat((items.pdv_visite_planifiee_month / countPDV_planifieee[items?.user_code][0]?.nbr_pdv_month ) * 100) >= 100 ? "100 %" : Math.round(parseFloat((items.pdv_visite_planifiee_month / countPDV_planifieee[items?.user_code][0]?.nbr_pdv_month  ) * 100).toFixed(2)) + "%")}</b> </td>
                              <td className=' fs-10 bg-azure-lighter' ><strong>{items?.total_cmd_month === 0 || items?.pdv_visite_month === null ? 0 : Math.round(parseFloat((items?.total_cmd_month / items?.pdv_visite_month) * 100).toFixed(2))}  %</strong> </td>
                                {/** ------------------ PL / Rupture /  Releve // Promotions ---- */}
                                <td className="wd-10p fs-10"> {findElement("pl",items.user_code) >0 ? <b className='text-primary'>{findElement("pl",items.user_code)}</b> :0}</td>
                                <td className="wd-10p fs-10"> {findElement("rupture",items.user_code) >0 ? <b className='text-primary'>{findElement("rupture",items.user_code)}</b> :0}</td>
                                <td className="wd-10p fs-10"> {findElement("releve",items.user_code) >0 ? <b className='text-primary'>{findElement("releve",items.user_code)}</b> :0}</td>
                                <td className="wd-10p fs-10"> {findElement("promotion",items.user_code) >0 ? <b className='text-primary'>{findElement("promotion",items.user_code)}</b> :0}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
