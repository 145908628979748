/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import { login } from '../../services/Authentification/LoginApi'
import { useForm } from "react-hook-form";
import { useToken } from '../../services/Authentification/useToken'

export default function Login() {

    const history = useHistory()
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    //-------- FORMULAIRE D AUTHENTIFICATION
    const { register, handleSubmit } = useForm();
    
    const [token, setToken] = useToken();

    // -------------- Loading -----------
    const [loading, setLoading] = useState(false)
    //DATE TODAY

    const today = moment().format('YYYY-MM-DD')


    const authenfication = (data) => {
        /**
         * Authentification par E-Mail / 
         */
        if (data.email !== "" && data.password !== "") {
            setLoading(true)
       

            login(data.email, data.password)
                .then((result) => {
                    if (result?.data?.success === 0) {
                        toast(t('login.messageerror'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        // Redirect To home page
                        setToken(result.data);
                        //localStorage.setItem("user", JSON.stringify());
                        toast(t('login.messagesuccee'), {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        var user = JSON.parse(localStorage.getItem('user'));
                        var details = user?.result
                        if (details?.role_code === "sup") {
                            history.push({
                                pathname: '/routings/validationTournee'
                            })
                        } else if (details?.role_code === "chef") {
                            history.push({
                                pathname: '/routings/TourneeZone'
                            })
                        } else if (details?.role_code === "admin" || details?.role_code === "chef_merch") {
                            history.push({
                                pathname: '/'
                            })
                        } else if (details?.role_code === "agence") {
                            history.push({
                                pathname: "/rapports/rapportSuiviPointage&datedebut=" + today + "&datefin=" + today + "&user_code=''"
                            })
                        }
                        else {
                            toast("Vous n'êtes pas autorisé , seuls les administrateurs ou superviseur ou chef ou responsable agence peuvent accéder a Merbiss", { type: "warning", position: "top-right" })
                        }

                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setLoading(false)
                })
        }
    }
    return (
        <div className="h-100vh mt-8">
            <div className="col col-login mx-auto mb-4" style={{ borderRadius: "20px" }}>

            </div>
            <ToastContainer />
            {/* container opened */}
            <div className="container">
            <div className="col col-login mx-auto"> <div className="text-center"> <img src="../assets/images/brand/logo.png" className="header-brand-img desktop-logo h-100 mb-5" alt="Dashlot logo" /> <img src="../assets/images/brand/logo1.png" className="header-brand-img dark-theme h-100 mb-5 " alt="Dashlot logo" /> </div> </div>

                <div className="row">

                    <div className="col-xl-9 justify-content-center mx-auto text-center">
                        <div className="card" style={{ borderRadius: "20px" }}>
                            <div className="row">
                                <div className="col-md-12 col-lg-7 pr-0 d-none d-lg-block">
                                    <img src="../assets/images/photos/login.png"  alt="img" className="br-tl-2 br-bl-2 " />
                                </div>
                                <div className="col-md-12 col-lg-5 pl-0 ">
                                    <form onSubmit={handleSubmit(authenfication)}>
                                        <div className="card-body mt-8 pt-6 about-con pabout" >
                                            <div className="card-title text-center mt-4 mb-4">{t('login.login')}</div>
                                            <div className="input-group mb-3" >
                                                <input type="text" className="form-control fs-12" id="email" aria-describedby="emailHelp" placeholder="Enter email"{...register('email')} required  />
                                                <span className="input-group-text" id="basic-addon1"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope-at" viewBox="0 0 16 16">
                                                    <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                                                    <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                                                </svg>
                                                </span>
                                            </div>
                                            <div className="input-group mb-3">
                                            <input type="password" className="form-control  fs-12" id="password" placeholder="Password" {...register('password')} required />
                                                <span className="input-group-text" id="basic-addon1"> 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-123" viewBox="0 0 16 16">
                                                        <path d="M2.873 11.297V4.142H1.699L0 5.379v1.137l1.64-1.18h.06v5.961h1.174Zm3.213-5.09v-.063c0-.618.44-1.169 1.196-1.169.676 0 1.174.44 1.174 1.106 0 .624-.42 1.101-.807 1.526L4.99 10.553v.744h4.78v-.99H6.643v-.069L8.41 8.252c.65-.724 1.237-1.332 1.237-2.27C9.646 4.849 8.723 4 7.308 4c-1.573 0-2.36 1.064-2.36 2.15v.057h1.138Zm6.559 1.883h.786c.823 0 1.374.481 1.379 1.179.01.707-.55 1.216-1.421 1.21-.77-.005-1.326-.419-1.379-.953h-1.095c.042 1.053.938 1.918 2.464 1.918 1.478 0 2.642-.839 2.62-2.144-.02-1.143-.922-1.651-1.551-1.714v-.063c.535-.09 1.347-.66 1.326-1.678-.026-1.053-.933-1.855-2.359-1.845-1.5.005-2.317.88-2.348 1.898h1.116c.032-.498.498-.944 1.206-.944.703 0 1.206.435 1.206 1.07.005.64-.504 1.106-1.2 1.106h-.75v.96Z" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="form-footer mt-1">
                                                {loading ? 
                                                <div className="lds-ring"><div /><div /><div /><div /></div>
                                                : <button className="btn btn-azure btn-block fs-12" style={{ borderRadius:"10px"}}type='submit'><i className='icon icon-lock-open mr-2' /> {t('login.seconnecter')}</button>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}
