import moment from 'moment'
import React from 'react'

export default function TableDN({listeHeaders ,array_pdv , t , listeMarques , listeMarqueFamille , listeProduits , test}) {
  return (
    <div className="table-responsive product-datatable" >
    <table id="table-to-xls" className="table table-striped table-bordered " >
        <thead className='bg-white text-center'>
            <tr className='bg-purple pb-0 pt-0 fs-10' >
                <th colSpan={2} className='text-white fs-10'> {t('rapports.Zones')} </th>
                {listeHeaders?.map((items, keys) => (
                    <td key={keys} >{items.zone}</td>))}
                <td colSpan={array_pdv?.length + 1}></td>
            </tr>
            <tr className='bg-azure-lightest pb-0 pt-0 fs-10'>
                <th colSpan={2} className='fs-10'>  {t('rapportVentes.datedevisite')}</th>
                {listeHeaders?.map((items, keys) => (
                    <td key={keys}>{moment(items.date).format('DD-MM-YYYY')} </td>))}
                <td colSpan={array_pdv?.length + 1}></td>
            </tr>
            <tr className='bg-azure-lightest pb-0 pt-0 fs-10'>
                <th colSpan={2} className='fs-10'>  {t('rapportVentes.heureVisite')}  </th>
                {listeHeaders?.map((items, keys) => (
                    <td className='text-azure' key={keys}><b>{items.heure_debut} </b><i className='fa fa-clock-o fs-12 text-azure' /></td>))}
                <td colSpan={array_pdv?.length + 1}></td>
            </tr>
            <tr className='bg-azure-lightest pb-0 pt-0 fs-10'>
                <th colSpan={2} className='fs-10'>  {t('Routings.adresse')}  </th>
                {listeHeaders?.map((items, keys) => (
                    <td key={keys}>{items.adresse === null ? "-" : items.adresse}</td>))}
                <td colSpan={array_pdv?.length + 1}></td>
            </tr>
            <tr className='bg-azure-lightest pb-0 pt-0 fs-10'>

                <th colSpan={2} className='fs-10'>  {t('rapportrupture.categorie')}  </th>
                {listeHeaders?.map((items, keys) => (
                    <td  key={keys}>{items.categorie === null ? "-" : items.categorie}</td>))}
                <td colSpan={array_pdv?.length + 1}></td>
            </tr>
            <tr className='bg-purple pb-0 pt-0 fs-10'>
                <th colSpan={2} className='text-white fs-10'> {t('dashboard.Magasin')} </th>
                {listeHeaders?.map((items, keys) => (
                    <td key={keys} className='fs-10   mt-2'>{items.nompdv}</td>))}
                <td colSpan={array_pdv?.length + 1}></td>
            </tr>
            <tr className='bg-purple-lightest pb-0 pt-0 fs-10'>
                <td className='fs-10'>{t('relevePrix.marque')}</td>
                <td className='fs-10'> {t('relevePrix.Produits')}</td>
                {listeHeaders?.map((items, keys) => (<td key={keys}>  {t('rapportVentes.disponibilite')} </td>))}
                <td className='fs-10'>  {t('rapportVentes.totdisponible')} </td>

                <td className='fs-10' colSpan={array_pdv.length}> {t('rapportVentes.totalSku')} </td>
            </tr>
        </thead>
        <tbody className='bg-white text-center pb-0 pt-0 fs-12'>
            {listeMarques?.map((items, keys) => (
                <>
                    <tr key={keys} >
                        <td className='fs-12' style={{ verticalAlign: "middle", WebkitTransform: 'rotate(42deg)' }} rowSpan={(2 * listeMarqueFamille[items.marque_code]?.length) + (listeProduits[items.marque_code]?.length) + 1}>{items.marque}</td>
                    </tr>
                    {listeMarqueFamille[items.marque_code]?.map((it, k) =>
                        <> {test(items.marque_code, it.famille_code, it)} </>
                    )}
                </>
            ))}
        </tbody>
    </table>
</div>
  )
}
