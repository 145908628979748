/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import PaginationTable from '../../../components/Pagination/PaginationTable';
import { getListeDLC, getSearchDLC } from '../../../services/Produits/ProduitsAPI';
import { getMerch } from '../../../services/Users/UsersApi';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useTranslation } from 'react-i18next';

export default function PageDLC() {

    const { debut, fin } = useParams()
    //------ History -----------------
    const history = useHistory();
    //------ DATE SEARCH (debut / fin) -----------
    const [dateDebut, setDateDebut] = useState(debut);
    const [dateFin, setDateFin] = useState(fin);
    //-----------  Filtre Value ------------------
    const [listeMerch, setlisteMerch] = useState([])
    const [codeMerch, setcodeMerch] = useState("")
    const [listeDLC, setlisteDLC] = useState([])
    const [saveListe, setsaveListe] = useState([])
    const [total, settotal] = useState("")
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(36);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = listeDLC?.slice(indexOfFirstPost, indexOfLastPost)
    //Loader 
    const [Loading, setLoading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //ON CHNAGE current page
    useEffect(() => {

        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteMerch(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getListeDLC(currentPage, stocksPerPage, dateDebut, dateFin)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteDLC(result?.data?.DLC)
                    setsaveListe(result?.data?.DLC)
                    settotal(result?.data?.totalDLC?.total)
                } setLoading(false)
            }).catch((err) => {
                console.log(err)
            });

    }, [])


    const backListe = () => {
    }

    const onSearchData = () => {
        history.push({
            pathname: "/produits/dlc&datedebut=" + dateDebut + "&datefin=" + dateFin
        })
        if (codeMerch === "") {
            getListeDLC(currentPage, stocksPerPage, dateDebut, dateFin)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setlisteDLC(result?.data?.DLC)
                        setsaveListe(result?.data?.DLC)
                        settotal(result?.data?.totalDLC?.total)
                    } setLoading(false)
                }).catch((err) => {
                    console.log(err)
                });

        } else {
            //search function
            const data = {
                user_code: codeMerch
            }
            getSearchDLC(currentPage, stocksPerPage, dateDebut, dateFin, data)
                .then((result) => {
                    setLoading(true)
                    if (result?.data?.success === 1) {
                        setlisteDLC(result?.data?.DLC)
                        settotal(result?.data?.totalDLC?.total)
                    } setLoading(false)
                }).catch((err) => {
                    console.log(err)
                });

        }

    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Produits.Produits')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('sidebar.DLC')} </li>
                </ol>
            </div>
            {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" /> {t('sidebar.DLC')}
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className="form row no-gutters">
                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-6 mb-0">
                                    <input
                                        className="form-control fc-datepicker  fs-12"
                                        type="date"
                                        value={dateDebut}
                                        onChange={(e) => setDateDebut(e.target.value)}
                                    />
                                </div>
                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-6 mb-0">
                                    <input
                                        className="form-control fc-datepicker fs-12"
                                        type="date"
                                        value={dateFin}
                                        onChange={(e) => setDateFin(e.target.value)}
                                    />
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-3 mb-0">
                                    <div className="d-flex" >
                                        <select className="form-control  border-bottom-0 br-md-0 fs-12" value={codeMerch} onChange={(e) => setcodeMerch(e.target.value)} data-placeholder="Choisir merchandiser">
                                            <option value="">---- Merchandiseur----</option>
                                            <optgroup label="Merchandiser">
                                                {listeMerch?.map((items, keys) => (
                                                    <option value={items.value}>{items.name}</option>
                                                ))}

                                            </optgroup>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button
                                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-azure fs-16" onClick={() => onSearchData()}>
                                        <i className="fe fe-search" />
                                    </button>
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button
                                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16" onClick={() => backListe()}>
                                        <i className="ti-close" />
                                    </button>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-12 col-12 mb-0">
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-green fs-16"
                                        table="table-pdv"
                                        filename="liste_dlc"
                                        sheet="tablexls"
                                        buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                                    />
                                </div>
                            </div>
                            <h6 className=" mt-2 text-muted" style={{ fontSize: "12px" }}>  <span className="text-info"> <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span>  {t('rapportDLC.information')} </h6>

                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="table-responsive  product-datatable">
                            {Loading ? <SqaureLoader /> :
                                (
                                    <table id="table-pdv" className="table  table-bordered ">
                                        <thead className="text-center">
                                            <tr>
                                                <th className="w-15p fs-12" >{t('dashboard.Date')}</th>
                                                <th className="wd-15p fs-12" >{t('Produits.CODEPRODUIT')}</th>
                                                <th className="wd-15p fs-12" >{t('rapportrupture.produit')}</th>
                                                <th className="wd-15p fs-12" >{t('dashboard.Merchandiseur')}</th>
                                                <th className="wd-15p fs-12" >{t('dashboard.Magasin')}</th>
                                                <th className="wd-15p fs-12" >{t('dashboard.region')}</th>
                                                <th className="wd-15p fs-12" >{t('Magasins.Zone')}</th>
                                                <th className="wd-15p fs-12" >{t('rapportDLC.jourdlc')}</th>
                                                <th className="wd-15p fs-12" >{t('Produits.Quantite')}</th>


                                            </tr>
                                        </thead>
                                        {total === 0 ?
                                            <tr>
                                                <td colSpan="10" className="text-center text-danger ">
                                                    <h5 className="mt-2" >{t('Zoning.Aucunresultat')} du {moment(dateDebut).format("DD-MM-YYYY") + " au " + moment(dateFin).format("DD-MM-YYYY")}</h5>
                                                </td>
                                            </tr> :
                                            <tbody>
                                                {currentListe?.map((items, keys) => (
                                                    <tr key={keys}  className={
                                                        keys % 2 === 1 ? "fs-12 bg-azure-lightest text-center" : "fs-12 text-center"
                                                    }>
                                                        <td  > {moment(items.date).format('DD/MM/YYYY')} </td>
                                                        <td > {items.produit_code} </td>
                                                        <td > {items.produit} </td>
                                                        <td > {items.merch} </td>
                                                        <td > {items.pdv} </td>
                                                        <td > {items.region} </td>
                                                        <td > {items.zone} </td>
                                                        <td > {items.jour_dlc} </td>
                                                        <td > {items.quantite} </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        }

                                    </table>

                                )}
                            <div className="float-right">
                                <PaginationTable
                                    postsPerPage={stocksPerPage}
                                    totalPosts={total}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
