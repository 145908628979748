//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export function getDistanceFromLatLng(lat1, lng1, lat2, lng2, miles)  // miles optional
{
  if (typeof miles === "undefined") { miles = false; }
  function deg2rad(deg) { return deg * (Math.PI / 180); }
  function square(x) { return Math.pow(x, 2); }
  var r = 6371; // radius of the earth in km
  lat1 = deg2rad(lat1);
  lat2 = deg2rad(lat2);
  var lat_dif = lat2 - lat1;
  var lng_dif = deg2rad(lng2 - lng1);
  var a = square(Math.sin(lat_dif / 2)) + Math.cos(lat1) * Math.cos(lat2) * square(Math.sin(lng_dif / 2));
  var d = 2 * r * Math.asin(Math.sqrt(a));
  if (miles) { return d * 0.621371; } //return miles
  else { return d; } //return km
}
export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}


export function diff_minutes(dt2, dt1, date) {
  if (dt2 !== 'null' && dt1 !== 'null') {
    const date_debut = new Date(date + " " + dt1)
    const date_fin = new Date(date + " " + dt2)

    var diff = date_fin.getTime() - date_debut.getTime();
    var diff_as_date = new Date(diff);

    return diff_as_date.getHours() - 1 + ":" + diff_as_date.getMinutes() + ":" + diff_as_date.getSeconds();
  } else return null

}
//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export function calcCrow(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1c = toRad(lat1);
  var lat2c = toRad(lat2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1c) * Math.cos(lat2c);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

export function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 === lat2) && (lon1 === lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit === "K") { dist = dist * 1.609344 }
		if (unit === "N") { dist = dist * 0.8684 }
		return dist;
	}
}
// Converts numeric degrees to radians
export function toRad(Value) {
  return Value * Math.PI / 180;
}

export function compareTwoTimes(dt2, dt1, date) {
  const date_debut = new Date(date + " " + dt1)
  const date_fin = new Date(date + " " + dt2)
  //best to use .getTime() to compare dates
  if (date_debut.getTime() === date_fin.getTime()) {
    return true
  } else if (date_debut.getTime() <= date_fin.getTime()) {
    return true
  } else {
    return false
  }



}