/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useRef, useState } from 'react'
import { SearchItems } from '../../utils/SearchUtils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function Tournee({ listeTournee, saveList, t }) {
    const [searchList, setSearchlist] = useState([])
    const inputEl = useRef("")
    const [search, setSearch] = useState([])
    const currentListe = search.length > 1 ? searchList : listeTournee 
    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (inputEl.current.value !== "") {
            const newList = SearchItems(saveList, inputEl.current.value)
            setSearchlist(newList)
        } else {
            setSearchlist(saveList)
        }
    }
    return (
        <div className="tab-pane" id="tab-61">
            <div className="search1 search-elements-sm mb-4 ">
                <div className="form row no-gutters">
                    <div className="form-group  col-xl-6 col-lg-6 col-md-6 mb-0" >  <h6 className=' mb-2 mt-2 fs-12 text-primary'><i className='icon icon-home mr-1' /> Liste des magasins programmé dans les tournées </h6></div>
                    <div className="form-group  col-xl-5 col-lg-5 col-md-5 mb-0">
                        <input type="text" ref={inputEl} value={search}
                            onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary fs-10" id="text1" placeholder={t('Magasins.chercherunmagasins')} />
                    </div>
                  
                    <span className="input-group-append  mb-0">
                        <a href="#" className="btn btn-primary"><i className="icon icon-magnifier" /></a>
                        <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="btn btn-info"
                                    table="table-to-xls"
                                    filename="magasins_tournee"
                                    sheet="tablexls"
                                    buttonText={<i className="icon icon-share-alt" />}
                                />
                    </span>
                </div>
            </div>
          
            <div className="table-responsive product-datatable" style={{ overflow: 'auto', height: '600px' }}>

                <table className="table table-striped table-bordered "  id="table-to-xls">

                    <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                        <tr>
                            <th className="wd-10p fs-10" >{t('rapports.code')}</th>
                            <th className="wd-10p fs-10" >{t('Magasins.Magasins')}</th>
                            <th className="wd-15p fs-10" >{t('Routings.CODEJOUR')}</th>
                            <th className="wd-15p fs-10" >{t('Routings.frequence')}</th>
                            <th className="wd-15p fs-10" >{t('rapportrupture.date')}</th>
                            <th className="wd-15p fs-10" >{t('Routings.DEBUT')}</th>
                            <th className="wd-15p fs-10" >{t('Routings.FIN')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {currentListe?.map((items, keys) => (
                            <tr key={keys} className="fs-10">
                                <td><a className="text-primary" href={"/tournee/code=" + items.code}><u>{items.code}</u> </a> </td>
                                <td>{items.nompdv} </td>
                                <td>{items.code_jour} </td>
                                <td>{items.frequence}</td>
                                <td>{items.dates !== null ? moment(items.dates).format('DD-MM-YYYY') : "-"}</td>

                                <td>{moment(items.date_debut).format('DD-MM-YYYY')} </td>
                                <td>{moment(items.date_fin).format('DD-MM-YYYY')} </td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    )
}
