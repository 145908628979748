import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * 
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} datedebut 
 * @param {*} datefin 
 * @returns 
 */
 export async function getListePromo(limit,offset , dateDebut , dateFin) {
    try {
        const response = await axios.get(url + "promotions/limit="+limit+"&offset="+offset+"/datedebut="+dateDebut+"&datefin="+dateFin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

export async function searchPromo( dateDebut , dateFin  ,data) {
    try {
        const response = await axios.post(url + "promotions/search/datedebut="+dateDebut+"&datefin="+dateFin, data,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}