import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify'
import { getListeReminder } from '../../services/Messages/MessagesAPI';
import TableauNotes from './TableauNotes'

export default function CreationNote() {
    const [listeReminder, setlisteReminder] = useState([])
      //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");
  const user = JSON.parse(localStorage.getItem('user'));
  const details = user?.result
  
  useEffect(()=>{
        getListeReminder(details?.code).then((result) => {
            if(result?.data?.success===1){
                setlisteReminder(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    },[])

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                <li className="breadcrumb-item mt-2"><i className="icon icon-equalizer text-azure mr-1" />{t("Liste des messages")}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                    <a  href='/addMessages' className='btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12 text-white'><i className="ti-plus" /> Ajouter un message</a>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        <TableauNotes listeReminder={listeReminder} t={t}/>
        </div>
    )
}
