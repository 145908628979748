/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ToastContainer } from 'react-toastify';
import SqaureLoader from '../../components/Loader/SqaureLoader';
import PaginationTable from '../../components/Pagination/PaginationTable';
import { listeEnseignes } from '../../services/Magasins/PdvApi';
import { getListePromo, searchPromo } from '../../services/Promotions/promotionAPI';
import { getMerch } from '../../services/Users/UsersApi';

export default function Promotions() {

    const { debut, fin } = useParams()
    const history = useHistory();
    const [liste, setliste] = useState([])
    const [total, settotal] = useState("")
    //------ DATE SEARCH (debut / fin) -----------
    const [dateDebut, setDateDebut] = useState(debut);
    const [dateFin, setDateFin] = useState(fin);
    const [codeMerch, setcodeMerch] = useState("")
    const [listeMerch, setlisteMerch] = useState([])
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [actif, setactif] = useState("")
    const [pdv, setpdv] = useState([])
    const [ok, setok] = useState(false)
    const [index, setindex] = useState("")
 //PAGINATION
 const [currentPage, setCurrentPage] = useState(1);
 const [stocksPerPage, setStockPerPage] = useState(36);
 //Get current stock
 const indexOfLastPost = currentPage * stocksPerPage;
 const indexOfFirstPost = indexOfLastPost - stocksPerPage;
 //Current Liste 
 const currentListe = liste?.slice(indexOfFirstPost, indexOfLastPost)
 //Loader 
    const [Loading, setLoading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        listeEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setpdv(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
            getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteMerch(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
            const data = {}
            searchPromo( dateDebut, dateFin ,data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    console.log(result?.data?.liste)
                    setliste(result?.data?.liste)
                    settotal(result?.data?.total?.total)
                }
                setLoading(false)
            }).catch((err) => {
                console.log(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onClickBack = () => {
        const data = {}
        setLoading(true)
        searchPromo( dateDebut, dateFin ,data)
        .then((result) => {
            if (result?.data?.success === 1) {
                setliste(result?.data?.liste)
                settotal(result?.data?.total?.total)
            }
            setLoading(false)
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setLoading(false)
        })
    }
    const onSearchData = () => {
        setLoading(true)

        history.push({
            pathname: "/promotion/datedebut=" + dateDebut + "&datefin=" + dateFin
        })
        const data = {}
        if (codeMerch !== "") {
            Object.assign(data, {
                user_code: codeMerch
            })
        }
        if (codeEnseigne !== "") {
            Object.assign(data, {
                enseigne_code: codeEnseigne
            })
        }
        if (actif !== "") {
            Object.assign(data, {
                etat_promotion: actif
            })
        }
        
            searchPromo( dateDebut, dateFin ,data)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setliste(result?.data?.liste)
                        settotal(result?.data?.total?.total)
                    }
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                }).finally(()=>{
                    setLoading(false)
                })
        
    }

    return (
        <div>
            {/** ----------------  PART LINEAIRE ------------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.Promotions')}</a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('listepromotions')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a className='btn btn-azure text-white' href="/planningPromotions"><i className='icon icon-tag mr-1' />  {t('planningPromotions')} </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" />  {t('listepromotions')}
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className="form row no-gutters">
                                <div className="form-group  col-xl-3 col-lg-3 col-md-2 col-6 mb-0">
                                    <input
                                        className="form-control fc-datepicker fs-12"
                                        type="date"
                                        value={dateDebut}
                                        onChange={(e) => setDateDebut(e.target.value)}
                                    />
                                </div>
                                <div className="form-group  col-xl-3 col-lg-3 col-md-2 col-6 mb-0">
                                    <input
                                        className="form-control fc-datepicker fs-12"
                                        type="date"
                                        value={dateFin}
                                        onChange={(e) => setDateFin(e.target.value)}
                                    />
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 mb-0">
                                    <select className="form-control br-md-0 fs-12" value={codeEnseigne} onChange={(e) => setcodeEnseigne(e.target.value)}>
                                        <option value="">  Choisir Enseigne  </option>
                                        {pdv?.map((items, keys) => (
                                            <option key={keys} value={items.code}> {items.nomenseigne} </option>
                                        ))}

                                    </select>
                                </div>
                                {/*   <div className="form-group  col-xl-2 col-lg-2 col-md-3 mb-0">
                                    <div className="d-flex" >
                                        <select className="form-control  border-bottom-0 br-md-0" value={codeMerch} onChange={(e) => setcodeMerch(e.target.value)} data-placeholder="Choisir merchandiser">
                                            <option value="">---- Merchandiseur ----</option>
                                            <optgroup label="Merchandiser">
                                                {listeMerch?.map((items, keys) => (
                                                    <option key={keys} value={items.value}>{items.name}</option>
                                                ))}

                                            </optgroup>
                                        </select>
                                    </div>
                                </div> */}
                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 mb-0">
                                    <select className="form-control br-md-0" value={actif} onChange={(e) => setactif(e.target.value)}>
                                        <option value={""}> {t('Routings.choisir')} </option>
                                        <option value={1}> {t('Routings.ACTIF')}  </option>
                                        <option value={0}> {t('Promotions.Inactif')} </option>
                                    </select>
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button
                                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16" onClick={() => onSearchData()}>
                                        <i className="fe fe-search" />
                                    </button>
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button
                                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16" onClick={()=>onClickBack()}>
                                        <i className="ti-close" />
                                    </button>
                                </div>
                            </div>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>

                                <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                    {listeMerch?.map((items, keys) => (
                                        <button className="btn tag bg-purple" key={keys} onClick={() => setcodeMerch(items.value)}> {items.name} </button>
                                    ))}
                                </div>
                            </div>
                            <h6 className=" mt-2 text-muted fs-12">
                                <span className="text-info">
                                    <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span> <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                                    <div className="w-3 h-3 bg-danger  mr-2" />
                                    <div>
                                        <strong> {t('Inactif')} </strong>
                                        <br />
                                    </div>
                                    <div className="w-3 h-3 bg-green ml-2  mr-2" />
                                    <div>
                                        <strong> {t('Actif')} </strong>
                                        <br />
                                    </div>
                                </div>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            {/** ----------------- CONTENUE DE LA PAGE -------------------- */}
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="table-responsive  product-datatable">
                            <table id="example" className="table  table-bordered ">
                                <thead className="text-center">
                                    <tr>
                                        <td colSpan="11" className="text-center bg-purple text-white fs-10" > {t('rapports.TOTAL')} : {total} </td>
                                    </tr>
                                    <tr>
                                        <th className="w-15p fs-12" >{t('contacts.produit')}</th>
                                        <th className="w-15p fs-12" >{t('rapportrupture.date')}</th>
                                        <th className="w-15p fs-12" >{t('rapportrupture.merch')}</th>
                                        <th className="w-15p fs-12" >{t('relevePrix.promo')}</th>
                                        <th className="w-15p fs-12" >{t('dashboard.cible')}</th>
                                        <th className="w-15p fs-12" >{t('dashboard.Magasin')}</th>
                                        <th className="w-15p fs-12" >{t('rapports.miseavant')}</th>
                                        <th className="w-15p fs-12" >{t('rapports.prix')}</th>
                                        <th className="w-15p fs-12" >{t('rapports.TAUX')}</th>
                                        <th className="w-15p fs-12" >{t('rapports.Etat')}</th>

                                    </tr>
                                </thead>
                                {Loading ? <SqaureLoader /> : (
                                    <tbody>
                                        {currentListe?.map((items, keys) => (
                                            <>
                                                <tr key={keys} className={items.etat_promotion === "1" ? "text-center pb-0 pt-0" : "text-center pb-0 pt-0 bg-red-lightest"}>
                                                    <td className='fs-12' > {items.produits} </td>
                                                    <td className='fs-12' > {moment(items.date).format("DD-MM-YYYY")} </td>
                                                    <td className='fs-12' > {items.merch} </td>
                                                    <td className='fs-12' ><a className="text-info" onClick={() => { setindex(keys); setok(!ok) }}> {items.libelle}  </a> </td>
                                                    <td className='fs-12' > {items.cible} </td>
                                                    <td className='fs-12' > {items.magasin} </td>
                                                    <td className='fs-12' > <b >{items.mise_avant} </b></td>
                                                    <td className='fs-12' > {items.prix} </td>
                                                    <td className='fs-12' > {items.taux_promotion === null ? 0 : items.taux_promotion} </td>
                                                    <td className='fs-12' > {items.etat_promotion === "1" ? <div className="w-3 h-3 bg-green" /> : <div className="w-3 h-3 bg-danger" />} </td>
                                                </tr>
                                                {ok === true && index === keys ?
                                                    <tr>


                                                        <td colSpan="11">
                                                            <div className="row">
                                                                <div className="col-lg-4  col-4 text-center">
                                                                    <div className="card  box-shadow-0 mb-lg-0">
                                                                        <div className="card-body">
                                                                            <div className="d-flex no-block align-items-center">
                                                                                <div>
                                                                                    <h6 className="text-left"> {t('Produits.Enseigne')} </h6>
                                                                                    <h3 className=" text-left fs-12 m-0">{items.enseigne}</h3>
                                                                                </div>
                                                                                <div className="ml-auto">
                                                                                    <div className="bg-purple-transparent icon-bg icon-service text-purple">
                                                                                        <i className="icon icon-home " />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className="col-lg-4  col-4 text-center">
                                                                    <div className="card  box-shadow-0 mb-lg-0">
                                                                        <div className="card-body">
                                                                            <div className="d-flex no-block align-items-center">
                                                                                <div>
                                                                                    <h6 className="text-left"> {t('dashboard.Promotions')} </h6>
                                                                                    <h3 className=" text-left fs-12 m-0">{items.cible}</h3>
                                                                                </div>
                                                                                <div className="ml-auto">
                                                                                    <div className="bg-warning-transparent icon-bg icon-service text-warning">
                                                                                        <i className="icon icon-calculator" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className="col-lg-4   col-4 text-center">
                                                                    <div className="card  box-shadow-0 mb-lg-0">
                                                                        <div className="card-body">
                                                                            <div className="d-flex no-block align-items-center">
                                                                                <div>
                                                                                    <h6 className="text-left">  {t('dashboard.Date')} </h6>
                                                                                    <h3 className=" text-left fs-12 m-0"> {moment(items.date_debut).format("DD-MM-YYYY")} /  {moment(items.date_fin).format("DD-MM-YYYY")}</h3>
                                                                                </div>
                                                                                <div className="ml-auto">
                                                                                    <div className="bg-pink-transparent icon-bg icon-service text-pink">
                                                                                        <i className="icon icon-calendar" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </td>

                                                    </tr>
                                                    : null}
                                            </>
                                        ))}
                                    </tbody>
                                )}

                            </table>
                            <div className="float-right">
                                <PaginationTable
                                    postsPerPage={stocksPerPage}
                                    totalPosts={total}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
