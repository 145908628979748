import React from 'react'
//--------------------- CONVERT HTML TABLE TO EXCEL ----------
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
//----------------------- LOADER -----------------------------
//----------------------- DATE UTILS -------------------------
import moment from 'moment'
import SqaureLoader from '../../../components/Loader/SqaureLoader';


export default function TableRelvePrix({inputEl , search ,getSearchTerm ,t ,liste ,loading ,currentListe , releve}) {
  return (
    <div className="card">
    <div className="card-body">
        <div className="row">
            {/** ---------------------------- HEADER (FILTER)  ------------------------------------------ */}
            <div className='col-12 mb-2'>
                <div className='float-left'>
                <span className="input-group-append">
                            <input
                                type="text"
                                ref={inputEl}
                                value={search}
                                onChange={() => getSearchTerm()}
                                className="form-control br-tr-md-0 br-br-md-0 text-azure fs-12"
                                id="text1"
                                placeholder={t("Chercher un relevé prix")}
                            />
                            <i className="text-azure ti-search ml-2 mt-4" />
                </span>
                </div>
                <div className='float-right '>
                    <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 "
                        table="table-to-xls"
                        filename="Rapport_Releve_Prix"
                        sheet="tablexls"
                        buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                    />
                </div>
            </div>

            {/** ----------------------------- TABLE CONTENT -------------------------------------------- */}
            <div className="table-responsive  product-datatable" style={{ overflow: 'auto', height: '600px' }}>
                <table id="table-to-xls" className="table  table-bordered ">
                    <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                        <tr>
                            <td colSpan="13" className="text-center bg-purple text-white fs-10" > {t('total')}  : {liste?.length}  </td>
                        </tr>
                        <tr>
                            <th className="w-15p fs-10"> {t('rapportrupture.date')} </th>
                            <th className="wd-15p fs-10">{t('dashboard.Merchandiseurs')}</th>
                            <th className="wd-15p fs-10">{t('dashboard.Magasin')}</th>
                            {releve === "produit" ? <th className="wd-15p fs-10">{t('rapportrupture.produit')}</th> : <th className="wd-15p fs-10">{t('Produits.SousFamille')}</th>} 
                            <th className="wd-15p fs-10">{t('rapports.position')}</th>
                            <th className="wd-15p fs-10">{t('rapports.permanent')}</th>
                            <th className="wd-15p fs-12">{t('relevePrix.promo')}</th>


                        </tr>
                    </thead>
                    {loading ? <SqaureLoader /> : (
                        <tbody>
                            {currentListe?.map((items, keys) => (
                                <tr key={keys} className={
                                    keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"
                                }>
                                    <td > {moment(items.date).format("DD/MM/YYYY")} </td>
                                    <td > {items.user} </td>
                                    <td > {items.nompdv} </td>
                                    {releve === "produit" ?    <td > {items.produit} </td> : <td > {items.sousfamilles} </td>}
                                    <td > {items.position} </td>
                                    <td > {parseFloat(items.permanent).toFixed(3)} </td>
                                    <td > {parseFloat(items.promo).toFixed(3)} </td>

                                </tr>
                            ))}
                        </tbody>
                    )}

                </table>

            </div>
        </div>
    </div>

</div>
  )
}
