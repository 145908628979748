/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from "lodash";
import React, { useState } from "react";
import { getAllDaysInMonth } from "../../../utils/DateUtils";
import moment from "moment";
import { initiative, poctualite2, rupture, sommeInitiative, sommePonctualite, sommeReporting, sommeTournee, tournee2, verif_rang } from "./Traitement_auto_evaluation";
import ModalReporting from "./ModalReporting";
import ModalPonctualite from "./ModalPonctualite";
import ModalRespectTournee from "./ModalRespectTournee";
import ModalInitiative from "./ModalInitiative";
import ModalPartLineaire from "./ModalPartLineaire";

export default function TableauAutoEvaluation2({ month, year, t, date, listeUsers, codeUser, array_merch}) {
 
    const [days, setdays] = useState("");
  //---------- JOURS NON TRAVAILE -----
  const [jrFerie, setjrFerie] = useState(0);
  const [jrRepos, setjrRepos] = useState(0);
  // ----------- UTILS SHOW MODAL ---
  const [ok1, setok1] = useState(false);
  const [ok2, setok2] = useState(false);
  const [ok3, setok3] = useState(false);
  const [ok4, setok4] = useState(false);
  const [ok5, setok5] = useState(false);

  /**
   * ALL DAYS OF MONTH
   */
  const date_y = new Date(year, month - 1, 1);
  var arra_days = getAllDaysInMonth(date_y.getFullYear(), date_y.getMonth());
  var respect_horaire_travail = _.groupBy(array_merch?.respect_horaire_travail, "date");
  var respect_horaire_debut =  _.groupBy(array_merch?.respect_horaire_debut, "date");
 /*  var respect_horaire_fin =  _.groupBy(array_merch?.respect_horaire_fin, "date"); */
  var liste_pdvs = []
 var pl_pdvs = _.groupBy(array_merch?.respect_pl,"pdv_code")
  array_merch?.liste_pdvs?.map((items)=>
  liste_pdvs.push({
    pdv_code : items.pdv_code,
    nompdv : items.nompdv,
    pl : pl_pdvs[items.pdv_code] !== undefined ? "Traité":"-"
  }))
  var all_pl_tournee = _.uniqBy(liste_pdvs,"pdv_code")
  var respect_tournee = _.groupBy(array_merch?.respect_tournee, "day");
  var respect_rang = _.groupBy(array_merch?.respect_rang,"date")
  //var respect_note = _.groupBy(array_merch?.respect_note, "date");
  var respect_note = _.groupBy(array_merch?.respect_note, d => moment(d.date).format("YYYY-MM-DD"));

  var respect_rupture = _.groupBy(array_merch?.respect_rupture, "date");
  var respect_releve_prix = array_merch?.respect_releve_prix;
  var liste_jour_travaille= _.groupBy(array_merch?.jour_travaille,"date")
  //var note_total = ((parseFloat(respect_pl?.respect_pl)* 4)  +( parseFloat(respect_*releve_prix?.total_releve)*4) +(sommeTournee(arra_days,jrFerie, jrRepos,array_merch)*2)+ parseFloat(sommePonctualite(arra_days, jrFerie , jrRepos, array_merch)) + (sommeReporting(arra_days,jrFerie, jrRepos,array_merch)*4))/4
  var name_user = listeUsers?.filter((el) => el.value === codeUser);
 
  var moyenne = ((sommePonctualite(arra_days, jrFerie , jrRepos, array_merch)*10)+(sommeTournee(arra_days,jrFerie, jrRepos,array_merch )*10)+(sommeInitiative(arra_days,jrFerie, jrRepos,array_merch)*20)+(sommeReporting(arra_days,jrFerie, jrRepos,array_merch)*20)+((respect_releve_prix?.total_releve)*20)+(((array_merch?.liste_pdvs?.length === array_merch?.respect_pl?.length)&& (array_merch?.liste_pdvs?.length !==0) ?1:0)*20))/6

  return (
    <div className="row mt-2">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h5 className="text-center text-azure mt-2">
              Evaluation merchandiseur
            </h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="form-control-label fs-10 mt-2 mr-3">
                      Nombre de jours fériés
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <input
                    placeholder="Jours férié"
                    value={jrFerie}
                    onChange={(e) => setjrFerie(e.target.value)}
                    type="text"
                    className="form-control fs-10"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="form-control-label fs-10 mt-2 mr-3">
                      Jours OFF
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <input
                    placeholder="Jours OFF"
                    value={jrRepos}
                    onChange={(e) => setjrRepos(e.target.value)}
                    type="text"
                    className="form-control fs-10"
                  />
                </div>
              </div>
            </div>
            <table className="table table-responsive " id="table-to-xls">
            <thead>
                <tr>
                  <th
                    colSpan={arra_days?.length + 2}
                    className="text-center text-azure"
                  ></th>
                </tr>
                <tr>
                  <th className="fs-12" colSpan={arra_days?.length + 2}>
                    <b className="mr-2">Mois-Année :</b> {month + "/" + year}{" "}
                    <br />
                    <b className="mr-2">Merchandiseur:</b> {name_user[0]?.name}
                    <br/>
                    <b>Nbr. Jours de travail </b> {parseInt(arra_days?.length)-(parseFloat(jrFerie)+parseFloat(jrRepos))}
                  </th>
                </tr>
                <tr>
                  <th scope="col" className="fs-10">
                    Critéres
                  </th>
                  {arra_days?.map((items, keys) => (
                    <th scope="col" className={liste_jour_travaille[moment(items).format("YYYY-MM-DD")] === undefined ? "fs-10 text-center bg-danger text-white" :"fs-10 text-center"} key={keys} >
                      J-{keys + 1}
                    
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" className="fs-10">
                    Ponctualité
                  </th>
                  {arra_days?.map((items, keys) => (
                    <td key={keys} className={liste_jour_travaille[moment(items).format("YYYY-MM-DD")] === undefined ? "fs-10 text-center bg-danger" :"fs-10 text-center"}>
                      <a
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#exampleModal"
                        className={liste_jour_travaille[moment(items).format("YYYY-MM-DD")] === undefined ? "fs-10 text-white bg-danger" :"fs-10 text-success"}
                        onClick={() => {
                          setdays(moment(items).format("YYYY-MM-DD"));
                          setok1(true);
                        }}
                      >                       
                        {poctualite2(moment(items).format("YYYY-MM-DD"), respect_horaire_travail ,respect_horaire_debut)} /2

                      </a>
                    </td>
                  ))}
                </tr>
                <tr className="fs-10">
                  <th scope="row" className="fs-10">
                    Respect Tournée
                  </th>
                  {arra_days?.map((items, keys) => (
                    <td className={liste_jour_travaille[moment(items).format("YYYY-MM-DD")] === undefined ? "fs-10 text-center bg-danger" :"fs-10 text-center"} key={keys} >
                      <a
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#tournee"
                        className={liste_jour_travaille[moment(items).format("YYYY-MM-DD")] === undefined ? "fs-10 text-white bg-danger" :"fs-10 text-success"}
                        onClick={() => {
                          setdays(moment(items).format("YYYY-MM-DD"));
                          setok2(true);
                        }}
                      >
                        {tournee2(moment(items).format("YYYY-MM-DD"), respect_tournee)+verif_rang(moment(items).format("YYYY-MM-DD") , respect_rang) }/2
                      </a>
                    </td>
                  ))}
                </tr>
              
               
                <tr className="fs-10">
                  <th scope="row" className="fs-10">
                    Initiative
                  </th>
                  {arra_days?.map((items, keys) => (
                    <td className={liste_jour_travaille[moment(items).format("YYYY-MM-DD")] === undefined ? "fs-10 text-center bg-danger" :"fs-10 text-center"} key={keys}>
                      <a
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#initiative"
                        className={liste_jour_travaille[moment(items).format("YYYY-MM-DD")] === undefined ? "fs-10 text-white bg-danger" :"fs-10 text-success"}
                        onClick={() => {
                          setdays(moment(items).format("YYYY-MM-DD"));
                          setok4(true);
                        }}
                      >
                        {initiative(moment(items).format("YYYY-MM-DD"),respect_note)}/1
                      </a>
                    </td>
                  ))}
                </tr>
                <tr className="fs-10">
                  <th scope="row" className="fs-10">
                  Rapport rupture
                  </th>
                  {arra_days?.map((items, keys) => (
                    <td className={liste_jour_travaille[moment(items).format("YYYY-MM-DD")] === undefined ? "fs-10 text-center bg-danger" :"fs-10 text-center"}key={keys}>
                      <a
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#rupture"
                        className={liste_jour_travaille[moment(items).format("YYYY-MM-DD")] === undefined ? "fs-10 text-white bg-danger" :"fs-10 text-success"}
                        onClick={() => {
                          setdays(moment(items).format("YYYY-MM-DD"));
                          setok3(true);
                        }}
                      >
                        {rupture(moment(items).format("YYYY-MM-DD"),respect_rupture)}/1
                      </a>
                    </td>
                  ))}
                </tr>
                <tr className="fs-10">
                  <th scope="row" className="fs-10">
                    Releve Prix
                  </th>
                  <td colSpan={arra_days?.length}>
                    {" "}
                    Total Relevé Prix du Mois :{" "}
                    {respect_releve_prix?.respect_releve_prix} , (note{" "}{respect_releve_prix?.total_releve}/1)
                  </td>
                </tr>
                <tr className="fs-10">
                  <th scope="row" className="fs-10">
                    Part Linéaire
                  </th>
                  <td colSpan={arra_days?.length}>
                    {" "}

                    Total partlinéaire du Mois : {array_merch?.respect_pl?.length} , (note{" "}
                    <a
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#pl"
                        className="text-success"
                        onClick={() => {
                          setok5(true);
                        }}
                       
                      >        {(array_merch?.liste_pdvs?.length === array_merch?.respect_pl?.length) && (array_merch?.respect_pl?.length!==0) ?1:0}/1)</a>
                  </td>
                </tr>
                <tr className="fs-10">
                  <td
                    className="text-right"
                    style={{ textAlign: "right" }}
                    colSpan={arra_days?.length}
                  >
                    Moyenne de ponctualité
                  </td>
                  <td>
                    {sommePonctualite(arra_days, jrFerie , jrRepos, array_merch)*10}/20
                  </td>
                </tr>
                <tr className="fs-10">
                  <td
                    className="text-right"
                    style={{ textAlign: "right" }}
                    colSpan={arra_days?.length}
                  >
                    Moyenne de respect tournée
                  </td>
                  <td>
                    {sommeTournee(arra_days,jrFerie, jrRepos,array_merch )*10}/20
                  </td>
                </tr>
               
                <tr className="fs-10">
                  <td
                    className="text-right"
                    style={{ textAlign: "right" }}
                    colSpan={arra_days?.length}
                  >
                    Moyenne d'initiative
                  </td>
                  <td>
                    {sommeInitiative(arra_days,jrFerie, jrRepos,array_merch)*20}/20
                  </td>
                </tr>
                <tr className="fs-10">
                  <td
                    className="text-right"
                    style={{ textAlign: "right" }}
                    colSpan={arra_days?.length}
                  >
                    Moyenne de rapport rupture
                  </td>
                  <td>
                    {sommeReporting(arra_days,jrFerie, jrRepos,array_merch)*20}/20
                  </td>
                </tr>
                <tr className="fs-10">
                  <td
                    className="text-right" 
                    style={{ textAlign: "right" }}
                    colSpan={arra_days?.length}
                  >
                    RELEVE PRIX{" "}
                  </td>
                  <td>{(respect_releve_prix?.total_releve)*20}/20</td>
                </tr>
                <tr className="fs-10">
                  <td
                    className="text-right"
                    style={{ textAlign: "right" }}
                    colSpan={arra_days?.length}
                  >
                    {" "}
                    Part Linéaire{" "}
                  </td>
                  <td>{((array_merch?.liste_pdvs?.length === array_merch?.respect_pl?.length)&& (array_merch?.liste_pdvs?.length !==0) ?1:0)*20}/20</td>
                </tr>
                <tr className="fs-10">
                  <td
                    className="text-right"
                    style={{ textAlign: "right" }}
                    colSpan={arra_days?.length}
                  >
                  Note total 
                  </td>
                  <td  data-toggle="tooltip" data-placement="top" title={"[(PartLinéaire*20)+(releve_Prix*20)+(Moyenne(respect_tournee))+ (Moyenne(respect_ponctualite)*6.667)+ (Moyenne(respect_reporting)*20)]/20"}>{parseFloat(moyenne).toFixed(2)}/20</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {ok1 ? (
        <ModalPonctualite
          days={days}
          setok1={setok1}
          poctualite={poctualite2}
          respect_horaire_travail={respect_horaire_travail}
          respect_horaire_debut={respect_horaire_debut}
         /*  respect_horaire_fin={respect_horaire_fin} */
          t={t}
        />
      ) : null}
      {ok2 ? (
        <ModalRespectTournee
          days={days}
          setok2={setok2}
          t={t}
          tournee={tournee2}
          respect_rang={respect_rang}
          respect_tournee={respect_tournee}
        />
      ) : null}
      {ok3 ? (
        <ModalReporting
          days={days}
          setok3={setok3}
          t={t}
          respect_rupture={respect_rupture}
          rupture={rupture}
        />
      ) : null}
      {ok4 ? (
        <ModalInitiative
          days={days}
          setok4={setok4}
          t={t}
          respect_note={respect_note}
          initiative={initiative}
        />
      ) : null}
       {ok5 ? (
        <ModalPartLineaire
          days={days}
          setok5={setok5}
          t={t}
          all_pl_tournee={all_pl_tournee}
        />
      ) : null}
    </div>
  );
}
