import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SqaureLoader from "../../../components/Loader/SqaureLoader";
import {
  autoEvaluation,
  getUsers,
} from "../../../services/Evaluation/EvaluationAPI";
//-------------- COMPONENTS -----------------------------
import HeaderAutoEval from "./HeaderAutoEval";
import InfoCard from "./InfoCard";
import SearchAutoEvaluation from "./SearchAutoEvaluation";
import TableauAutoEvaluation2 from "./TableauAutoEvaluation2";

export default function AutoEvaluation2() {
  //------------ SEARCH INPUT -------------
  const searchInput1 = useRef();
  //----------- FILTRE --------------------
  const [codeUser, setcodeUser] = useState("");
  //-------------- Array of parametrage Pointage -----
  const [array_merch, setArray_merch] = useState([]);
  // DATE ---------------------------------
  const date = new Date();
  const [month, setmonth] = useState("");
  const [yearValue, setyearValue] = useState("");
  //----------- LISTE ---------------------
  const [listeUsers, setlisteUsers] = useState([]);
  //----------- MONTH ---------------------
  const liste = [
    { code: 1, libelle: "Janvier" },
    { code: 2, libelle: "Février" },
    { code: 3, libelle: "Mars" },
    { code: 4, libelle: "Avril" },
    { code: 5, libelle: "Mai" },
    { code: 6, libelle: "Juin" },
    { code: 7, libelle: "Juillet" },
    { code: 8, libelle: "Aôut" },
    { code: 9, libelle: "Septembre" },
    { code: 10, libelle: "Octobre" },
    { code: 11, libelle: "Novembre" },
    { code: 12, libelle: "Décembre" },
  ];
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //--------------show form ---------------
  const [showForm, setshowForm] = useState(false);
  const [showInfo, setshowInfo] = useState(true);
  //------------- Loading ------------------
  const [loading, setloading] = useState(true);

  useEffect(() => {
    /**
     * GET LISTE OF MERCH
     * role : merch
     * return : array []
     */
    getUsers()
      .then((result) => {
        if (result?.data?.success === 1) {
          setlisteUsers(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
  }, []);
  const handleChangeUsers = (...args) => {
    setcodeUser(args[1].value);
  };
  const onClickUser = () => {
    if (codeUser !== "") {
      setshowInfo(false);
      setloading(true);
      autoEvaluation({
        month: month,
        year: yearValue,
        day :new Date(yearValue, month - 1, 1),
        user_code: codeUser,
      })
        .then((result) => {
          if (result?.data?.success === 1) {
            setArray_merch(result?.data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setloading(false);
        });
    }
  };
  return (
    <div>
      {/**----------------------------------
       * HEADER AUTO EVALUATION
       * title : of table auto evaluation
       * -----------------------------------
       */}
      <HeaderAutoEval t={t} />
      {/**---------------------------------
       * SEARCH FILTRE
       * INPUT (MONTH VALUE , MERCH  CODE)
       * ----------------------------------
       */}
      <SearchAutoEvaluation
        month={month}
        setmonth={setmonth}
        yearValue={yearValue}
        setyearValue={setyearValue}
        liste={liste}
        codeUser={codeUser}
        listeUsers={listeUsers}
        searchInput1={searchInput1}
        handleChangeUsers={handleChangeUsers}
        onClickUser={onClickUser}
        showInfo={showInfo}
        t={t}
      />
      {/**----------------------------------
       * Tableau d'AUTO EVALUATION
       * (criteres, ponctualité, respect tournée , reporting , initiative)
       * -----------------------------------
       */}
      {!showForm && !showInfo && (
        <>
          {/**----------------------------------
           * Tableau d'AUTO EVALUATION
           * (criteres, ponctualité, respect tournée , reporting , initiative)
           * -----------------------------------
           */}
          {loading ? (
            <SqaureLoader />
          ) : (
            <TableauAutoEvaluation2
              month={month}
              year={yearValue}
              t={t}
              date={date}
              array_merch={array_merch}
              listeUsers={listeUsers}
              codeUser={codeUser}
            />
          )}
        </>
      )}
      {showInfo && (
        <>
          {/**----------------------------------
           * FIRST ALERT INFO FOR ALL USER BEFORE SEARCH
           * (month-year, user_code)
           * ----------------------------------
           */}
          <InfoCard />
        </>
      )}
    </div>
  );
}
