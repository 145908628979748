/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import arLocale from '@fullcalendar/core/locales/ar-ly';
import enLocale from '@fullcalendar/core/locales/es-us';
import frLocale from '@fullcalendar/core/locales/fr';
import { useTranslation } from 'react-i18next';
import SqaureLoader from '../../components/Loader/SqaureLoader';
import moment from 'moment';
import { getCalendrier } from '../../services/Calendrier/CalendrierAPI';
import listPlugin from '@fullcalendar/list'

export default function Calendrier() {

    //--------- EVENENT ----------------
    const [event, setEvent] = useState([])
    //Languages
    const [t, i18n] = useTranslation("global");
    //Loader
    const [loading, setLoading] = useState(true);
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    //var today = moment(date).format("YYYY-MM-DD")
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")

    useEffect(() => {
        // WEB SERVICE promotions
        //WEB SERVICE congés
        //WEB SERVICE Tournée
        getCalendrier(date1, date2)
            .then((res) => {
                if (res?.data?.success === 1) {
                    console.log(res);
                    const events = res?.data?.listepromotions?.map(data => {
                        return ({
                            title: data.libelle + ", Type :" + data.type + ", Categorie :" + data.categorie + " , Enseigne " + data.enseignes,
                            start: data.date_debut,
                            end: data.date_fin,
                            color: "#0080ff",
                        })
                    
                    })
                    setEvent(events)
                    console.log("HI")
                }
                setLoading(false)
            }).catch((error) => console.log(error))
    }, [])

    return (
        <div>
            {/**  PAGE HEADER  */}
            <div className="page-header">
                <ol className="breadcrumb mt-2">
                    <li className="breadcrumb-item"><a href="/calendrier"><i className="fe fe-life-buoy mr-1" /> {t('calendrier.calendrier')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('calendrier.pageCalendrier')} </li>
                </ol>
            </div>
            <div className='row'>
                <div className="col-md-12">
                    <div className='card'>
                        <div className='card-body'>
                            {loading ? <SqaureLoader /> :
                                <FullCalendar
                                    plugins={[dayGridPlugin, listPlugin]}
                                    headerToolbar={{
                                        left: 'title',
                                        right: 'prev,today,dayGridDay,dayGridWeek,dayGridMonth,listWeek,next'
                                    }}
                                    initialView="dayGridMonth"
                                    locales={[arLocale, frLocale, enLocale]}
                                    locale={i18n.language === "ar" ? 'ar-ly' : i18n.language === 'en' ? 'en-au' : 'fr'}
                                    events={event}

                                />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
