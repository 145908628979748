import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
const role = user?.result?.role_code
const user_code = user?.result?.code

/**
 * Add 
 * @param {*} file 
 * @returns 
 */
export async function addEnteteFile(file) {
    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(url + "evaluation/add/enteteQuestions", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function getUsers() {
    try {
        const response = await axios.get(url + "evaluation/listeUsers/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function getEvaluationYear(annee) {
    try {
        const response = await axios.get(url + "evaluation/YearEval/"+annee+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function getEvaluationResults(merch_code, month, annee) {
    try {
        const response = await axios.get(url + "evaluation/results/"+merch_code+"/"+month+"/"+annee+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * liste Entete
 * @returns 
 */
export async function listeEntete() {
    try {
        const response = await axios.get(url + "evaluation/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function addEntete(data) {
    try {
        const response = await axios.post(url + "evaluation/",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function addEvaluationn (data) {
    try {
        const response = await axios.post(url + "evaluation/evaluation/",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * AUTO EVALUATION
 * 
 */
export async function autoEvaluation(data) {
    try {
        const response = await axios.post(url + "auto_evaluation/",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}