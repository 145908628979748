/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import moment from 'moment'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
//import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast, ToastContainer } from 'react-toastify'
import { getSelectMagasins } from '../../../services/Magasins/PdvApi'
import { getSelctEnseignes } from '../../../services/Produits/ProduitsAPI'
import { getUsersRapport, rapportDN, rapportDNSearch } from '../../../services/Rapports/RapportsApi'
import { getSelectZone } from '../../../services/Zoning/ZoningApi'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useTranslation } from 'react-i18next';
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import TableDN from './TableDN'
import NoResult from '../../../components/aucunResultat/NoResult'
import ExportToExcel from '../../../components/exportExcel/ExportToExcel'
import { sumFamilleSDK } from './FunctionDN'

export default function RapportDN() {
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    const { date } = useParams('')
    //------ History -----------------
    const history = useHistory()
    const [today, settoday] = useState(moment(date).format('YYYY-MM-DD'))
    const [listeZones, setlisteZones] = useState([])
    const [listePdv, setlistePdv] = useState([])
    const [listeEnseignes, setlisteEnseignes] = useState([])
    const [loading, setloading] = useState(true)
    const [listeHeaders, setlisteHeaders] = useState([])
    const [listeMarques, setlisteMarques] = useState([])
    const [listeMarqueFamille, setlisteMarqueFamille] = useState([])
    const [listeProduits, setlisteProduits] = useState([])
    const [allProduits, setallProduits] = useState([])
    var [array_pdv, setarray_pdv] = useState([])
    const [codeUser, setCodeUser] = useState("")
    const [listeUsers, setlisteUsers] = useState([])

 

    useEffect(() => {
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnseignes(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectMagasins()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlistePdv(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectZone().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });

        traitement()
    }, [])


    const traitement = () => {

        getUsersRapport(today, today)
        .then((result) => {
            if (result?.data?.success === 1) {
                setlisteUsers(result?.data?.listeUsers)
            }
        }).catch((err) => {
            console.log(err)
        });
        rapportDN(today)
            .then((result) => {
                setloading(true)
                if (result?.data?.success === 1) {
                    var listePdvs = result?.data?.headerListe
                    const l = _.groupBy(result?.data?.listeFamilleMarque, 'marque_code')
                    const p = _.groupBy(result?.data?.allproduits, function (item) {
                        return item.marque_code
                    });
                    const i = [] //Liste des points de ventes avec code pdv_code
                    listePdvs?.map((items) => {
                        i.push(items.pdv_code)
                    })
                    setarray_pdv(i) //arrays pdv = i 
                    setlisteHeaders(listePdvs)
                    setlisteMarques(result?.data?.listeMarques)
                    setlisteMarqueFamille(l)
                    setlisteProduits(p)
                    const p2 = _.groupBy(result?.data?.listeProduits, function (item) {
                        return item.code;
                    });
                    //total des magasins 
                    var lg_i = i.length
                    const arr = []
                    for (let value of Object.values(p2)) {
                        var te = []
                        i.map((v) => { // Parcourire la liste des pdvs
                            te.push(-1) //Intialiser avec les valeurs -1
                        })
                        te.splice(lg_i, lg_i, -2)
                        te.splice(lg_i + 1, lg_i + 1, -3)
                        const lg = value.length
                        value.map((y, k) => {
                            if (lg === i.length) {
                                te.splice(k, k, y.etat_repture)
                            } else {
                                i.map((b, z) => {
                                    if (y.pdv_code === b) {
                                        te[i.indexOf(y.pdv_code)] = y.etat_repture
                                    }
                                })
                            }
                        })
                        var sum = 0
                        var lg_te = te.length

                        for (var o = 0; o < lg_te - 2; o++) {
                            if (te[o] === '0') {
                                sum = sum + 1
                            }
                        }
                        te.splice(lg_i, lg_i, sum)
                        te.splice(lg_i + 1, lg_i + 1, parseFloat((sum / lg_i) * 100).toFixed(2) + "%")
                        arr.push({
                            libelle: value[0].libelle,
                            marque_code: value[0].marque_code,
                            famille_code: value[0].famille_code,
                            te: te
                        })
                    }
                    setallProduits(arr)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }

    const onClickButton = () => {
        history.push({
            pathname:
                "/rapports/rapportDN&date=" + today,
        })
        traitement()
    }

  
    const test = (marque_code, famille_code, it) => {
        const l = allProduits?.filter((el) => el.marque_code === marque_code && el.famille_code === famille_code)
        return (
            <>
                {l?.map((i, ki) => (
                    <tr key={ki} className='bg-white fs-12'>
                        <td >
                            {i.libelle}
                        </td>
                        {i?.te?.map((x, y) => (
                            <td key={y} > {x === "0" ? <b className='text-green'>{t('rapports.oui')}</b> : x === "1" ? <b className='text-red'>{t('rapports.non')}</b> : x > 0 ? <b> {x} </b> : x === -1 ? <i className='ti-minus' style={{ fontWeight: 'bold' }} /> : <b>{x} </b>}</td>
                        ))}
                    </tr>
                ))}
                {sumFamilleSDK(l, it,t ,array_pdv)}
            </>)
    }

    const filtreparUser = (user_code) =>{
        if(user_code !=="ALL"){
            rapportDNSearch(today,user_code)
            .then((result) => {
                setloading(true)
                if (result?.data?.success === 1) {
                    var listePdvs = result?.data?.headerListe
                    const l = _.groupBy(result?.data?.listeFamilleMarque, 'marque_code')
                    const p = _.groupBy(result?.data?.allproduits, function (item) {
                        return item.marque_code
                    });
                    const i = [] //Liste des points de ventes avec code pdv_code
                    listePdvs?.map((items) => {
                        i.push(items.pdv_code)
                    })
                    setarray_pdv(i) //arrays pdv = i 
                    setlisteHeaders(listePdvs)
                    setlisteMarques(result?.data?.listeMarques)
                    setlisteMarqueFamille(l)
                    setlisteProduits(p)
                    const p2 = _.groupBy(result?.data?.listeProduits, function (item) {
                        return item.code;
                    });
                    //total des magasins 
                    var lg_i = i.length
                    const arr = []
                    for (let value of Object.values(p2)) {
                        var te = []
                        i.map((v) => { // Parcourire la liste des pdvs
                            te.push(-1) //Intialiser avec les valeurs -1
                        })
                        te.splice(lg_i, lg_i, -2)
                        te.splice(lg_i + 1, lg_i + 1, -3)
                        const lg = value.length
                        value.map((y, k) => {
                            if (lg === i.length) {
                                te.splice(k, k, y.etat_repture)
                            } else {
                                i.map((b, z) => {
                                    if (y.pdv_code === b) {
                                        te[i.indexOf(y.pdv_code)] = y.etat_repture
                                    }
                                })
                            }
                        })
                        var sum = 0
                        var lg_te = te.length

                        for (var o = 0; o < lg_te - 2; o++) {
                            if (te[o] === '0') {
                                sum = sum + 1
                            }
                        }
                        te.splice(lg_i, lg_i, sum)
                        te.splice(lg_i + 1, lg_i + 1, parseFloat((sum / lg_i) * 100).toFixed(2) + "%")
                        arr.push({
                            libelle: value[0].libelle,
                            marque_code: value[0].marque_code,
                            famille_code: value[0].famille_code,
                            te: te
                        })
                    }
                    setallProduits(arr)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
        }else{
            traitement()
        }
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('rapports.RapportDN')} </li>
                </ol>
            </div>
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" className='fs-12' aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" /><b> {t('rapports.FiltreDN')} </b>
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-3'></div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <div className="input-group">
                                        <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary fs-12 text-center"  />
                                        <span className="input-group-append">
                                            <button className="btn btn-primary fs-12" type="button" onClick={() => onClickButton()} ><i className='ti-search' /></button>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-3'></div>
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>
                                    <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                                        <button className="btn tag bg-azure text-white"  onClick={()=>filtreparUser("ALL")}> {t('rapports.ALL')} </button>
                                        {listeUsers?.map((items, keys) => (
                                            <>
                                           
                                            <button className="btn tag bg-purple" key={keys} onClick={()=>filtreparUser(items.user_code)}> {items.nom} </button>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>

                         
                            <div className='row'>
                                <div className='col-6'>
                                    <h6 className=" mt-2 text-muted fs-12">
                                        <span className="text-info">
                                            <b>  <i className="icon icon-exclamation mr-1" />  {t('dashboard.Information')} :</b> </span> <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                                            <i className='ti-check text-green mr-2' />
                                            <div>
                                                <strong className='mr-3'> {t('rapportrupture.disponible')}  </strong>
                                                <br />
                                            </div>
                                            <i className='ti-close text-red mr-2' />
                                            <div className='mr-2'>
                                                <strong> {t('rapportrupture.rupture')} </strong>
                                                <br />
                                            </div>
                                        </div>
                                    </h6>
                                </div>
                                <div className='col-6'>
                                    <div className="float-right">
                                    <ExportToExcel id="table-to-xls" namefile="rapport_dn" t={t} />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {array_pdv?.length ===0 ? (
                <NoResult  title={t('Zoning.Aucunresultat')} subtitle={t('rapportVentes.aucunevisite')+" "+ moment(today).format("dddd LL")} />
            ): <div className='row' >
            <div className='col-12'>
                {loading ? <SqaureLoader /> : (
                    <TableDN  listeHeaders={listeHeaders} array_pdv={array_pdv} t={t} listeMarques={listeMarques} listeMarqueFamille={listeMarqueFamille}  listeProduits={listeProduits} test={test} />
                )}

            </div>
        </div> }
           
        </div>
    )
}
