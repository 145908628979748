import React from 'react';

export default function TableauGratuites({data ,listeProduits ,t , user}) {
    return (
        <div className='row'>
            {user?.result?.type ==="promotrice" ? 
            
            <div className='col-lg-12 col-xl-12 col-12'>
            <div className="card" style={{ overflow: "scroll", height: " 450px" }}>
                <div className="card-body">
                    <div className="table-responsive  product-datatable" style={{ overflow: 'auto', height: '500px' }}>
                            <table className="table  table-bordered " id="table-to-xls">
                        {/*Liste des produits hors mécanisme vendus*/}
                            <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                                <tr className="text-center">
                                    <td></td>
                                    <td colSpan={2}>
                                        <div className="item-box text-center">
                                            <div className="bg-purple-transparent icon-bg icon-service text-purple">
                                                <i className='icon icon-people' />
                                            </div>
                                            <div className="item-box-wrap">
                                            <h6 className="mb-2"> {t('rapports.ContactsTotal')+" : "+data?.total_contact} </h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td colSpan={2}>
                                        <div className="item-box text-center">
                                            <div className="bg-purple-transparent icon-bg icon-service text-purple">
                                                <i className='icon icon-basket-loaded' />
                                            </div>
                                            <div className="item-box-wrap">
                                            <h6 className="mb-2">{t('rapports.ContactsAvecAchat')+" : "+data?.contact_avec_achat}   </h6>
                                            </div>
                                        </div>

                                    </td>
                                    <td colSpan={2}>
                                        <div className="item-box text-center">
                                            <div className="bg-purple-transparent icon-bg icon-service text-purple">
                                                <i className='icon icon-basket' />
                                            </div>
                                            <div className="item-box-wrap">
                                            <h6 className="mb-2">{t('rapports.ContactsSansAchat')+" : "+data?.contact_sans_achat}   </h6>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="text-center">
                                    <th className="w-15p fs-10">{t('contacts.produit')}</th>
                                    <th className="w-15p fs-10">{t('rapports.code')}</th>
                                    <th className="w-15p fs-10">{t('rapports.STOCKDEBUT')}</th>
                                    <th className="w-15p fs-10">{t('Stock Rajout')}</th>
                                    <th className="w-15p fs-10">{t('rapports.STOCKFIN')}</th>
                                    <th className="w-15p fs-10">{t('rapports.variation')}</th>
                                    <th className="w-15p fs-10">{t('rapports.Vente')} </th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {listeProduits?.map((items,keys)=>
                                    <tr key={keys}  className={
                                        keys % 2 === 1 ? "fs-10 bg-azure-lightest" : "fs-10"
                                    }>
                                        <td>{items.produit} </td>
                                        <td>{items.produit_code} </td>
                                        <td>{items.stokdebut === null ? '-':items.stokdebut  } </td>
                                        <td>{items.stock_rajout === null ? '-':items.stock_rajout  } </td>
                                        <td>{items.stockfin === null ? '-': items.stockfin} </td>
                                        <td>{items?.variation  === null ? '-':items?.variation } </td>
                                        <td >{items.vendu === null? 0 : <b className='text-azure'>{items.vendu}</b>} </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
            :null}
        </div>
    )
}
