import moment from 'moment'
import React from 'react'

export default function TabeauKpiSales({ listeMG_GROUPED, listeproduits, dates, ComparePartLineaire, headerGammePL, headerAssortiments, totalEquipement, totalVisibilite, produit_releve, setdatedebut, setdatefin, datedebut, datefin, partLineaire, gammes, groupedProduits, headerQualite, groupedQualite, HeaderVisibilité, groupedVisibilite, listeMG, findEtatAssProduit, findEtatVisibiltie, findEtatQualite, findRelevePrix, findPrixRelevePrix, findPartLineaire }) {
    return (
        <div>
            <table
                className="table table-responsive table-bordered"
                style={{ borderCollapse: "collapse", width: "100%" }}
                id="table-to-xls"
            >
                <thead>
                    <tr
                        className=" text-center fz-10  p-0"
                        style={{ background: "#f0f8ff", border: "1px solid" }}
                    >
                        <td
                            style={{ border: "1px solid", background: "#f0f8ff" }}
                            colSpan={listeproduits?.length +
                                totalEquipement +
                                totalVisibilite +
                                headerGammePL?.length * 2 +
                                produit_releve?.length * 2 +
                                2}
                        >
                            <div className="float-right">
                                Dates : {moment(datedebut).format("DD/MM/YYYY")} / {moment(datefin).format("DD/MM/YYYY")}
                            </div>
                        </td>
                    </tr>
                    <tr className=" text-center fz-10  p-0">
                        <th
                            className="fs-10"
                            style={{ border: "1px solid", background: "#f0f8ff" }}
                            colSpan={listeproduits?.length +
                                totalEquipement +
                                totalVisibilite +
                                headerGammePL?.length * 2 +
                                produit_releve?.length * 2 +
                                2}
                        >
                            Fiche de sales KPI
                        </th>
                    </tr>
                    <tr className=" text-center fz-10  bg-blue-lightest">
                        <td
                            style={{ border: "1px solid", background: "#f0f8ff" }}
                            colSpan="3"
                        >
                            #
                        </td>
                        {headerAssortiments?.length !== 0 && (
                            <td
                                style={{ border: "1px solid", background: "#FFE69A" }}
                                colSpan={listeproduits?.length}
                            >
                                Assortiments
                            </td>
                        )}
                        {totalEquipement !== 0 && (
                            <td
                                style={{ border: "1px solid", background: "#C3E5AE" }}
                                colSpan={totalEquipement}
                            >
                                Qualité
                            </td>
                        )}
                        {totalVisibilite !== 0 && (
                            <td
                                style={{ border: "1px solid", background: "#FFCCCC" }}
                                colSpan={totalVisibilite}
                            >
                                Visibilité
                            </td>
                        )}
                        {produit_releve?.length !== 0 && (
                            <td
                                style={{ border: "1px solid", background: "#AAC4FF" }}
                                colSpan={produit_releve?.length * 2}
                            >
                                Prix Respecté
                            </td>
                        )}
                        {partLineaire?.length !== 0 && (
                            <td
                                style={{ border: "1px solid", background: "#646FD4" }}
                                colSpan={headerGammePL?.length * 2}
                            >
                                Part Linéaire
                            </td>
                        )}
                    </tr>
                    <tr className="text-center fz-10 bg-blue-lightest">
                        <td
                            style={{ border: "1px solid", background: "#f0f8ff" }} colSpan={3}
                        >
                            -
                        </td>

                        {gammes?.map((items, keys) => (
                            <td
                                style={{ border: "1px solid ", background: "#F9EBC8" }}
                                key={keys}
                                colSpan={groupedProduits[items.gamme_code]?.length}
                            >
                                {items.gammes}
                            </td>
                        ))}
                        {headerQualite?.map((items) => (
                            <td
                                style={{ border: "1px solid", background: "#ddffc9" }}
                                colSpan={groupedQualite[items.equipement]?.length}
                            >
                                {items.equipement}
                            </td>
                        ))}

                        {HeaderVisibilité?.map((items) => (
                            <td
                                style={{ border: "1px solid", background: "#ffe7e7" }}
                                colSpan={groupedVisibilite[items.equipement]?.length}
                            >
                                {items.equipement}
                            </td>
                        ))}

                        {produit_releve?.map((items) => (
                            <td
                                style={{ border: "1px solid", background: "#D2DAFF" }}
                                colSpan={2}
                            >
                                {items.nom_produit}
                            </td>
                        ))}
                        {partLineaire?.length !== 0 && headerGammePL?.map((items) => (
                            <td
                                style={{ border: "1px solid", background: "#9BA3EB" }}
                                colSpan={2}
                            >
                                {items.gammes + "(" + items.plcontrat_avg + "%)"}
                            </td>
                        ))}
                    </tr>
                    <tr className="text-center fz-10 ">
                        <td style={{ border: "1px solid", background: "#f0f8ff" }}>Date</td>

                        <td style={{ border: "1px solid", background: "#f0f8ff" }}>Magasins</td>
                        <td style={{ border: "1px solid", background: "#f0f8ff" }} >Merch.</td>

                        {gammes?.map((items) =>
                            groupedProduits[items.gamme_code]?.map((it, ke) => (
                                <td
                                    style={{
                                        border: "1px solid ",
                                        background: "#fffde9",
                                    }}
                                    key={ke}
                                >
                                    {it.nom_produit} (1/0)
                                </td>
                            ))
                        )}
                        {headerQualite?.map((items) =>
                            groupedQualite[items?.equipement]?.map((it, ke) => (
                                <td
                                    style={{ border: "1px solid", background: "#f0ffe4" }}
                                    key={ke}
                                >
                                    {it.libelle}
                                </td>
                            ))
                        )}

                        {HeaderVisibilité?.map((items) =>
                            groupedVisibilite[items?.equipement]?.map((it, ke) => (
                                <td
                                    style={{ border: "1px solid", background: "#fff5f5" }}
                                    key={ke}
                                >
                                    {it.libelle}
                                </td>
                            ))
                        )}

                        {produit_releve?.map((items) => (
                            <>

                                <td style={{ border: "1px solid", background: "#EEF1FF" }}>
                                    Prix
                                </td>
                                <td style={{ border: "1px solid", background: "#EEF1FF" }}>
                                    Respecté {" [" + items.prix_max + "-" + items.prix_max + "]"}
                                </td>
                            </>
                        ))}

                        {partLineaire?.length !== 0 && (
                            <>

                                <td style={{ border: "1px solid", background: "#DBDFFD" }}>
                                    (%)
                                </td>
                                <td style={{ border: "1px solid", background: "#DBDFFD" }}>
                                    Respecté(1/0)
                                </td>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                   
                            {listeMG_GROUPED?.map((i, k) =>
                                <tr className="text-center fz-10 " key={k}>
                                    <td style={{ border: "1px solid", background: "#f0f8ff" }} >{moment(i.date).format("DD/MM/YYYY")}</td>
                                    <td style={{ border: "1px solid", background: "#f0f8ff" }} >{i.name}</td>
                                    <td style={{ border: "1px solid", background: "#f0f8ff" }} >{i.merch}</td>
                                    {headerAssortiments?.map((y, ki) => (
                                        <td style={{ border: "1px solid", background: "#fffef8" }} key={ki}>
                                            <b>{findEtatAssProduit(y.produit_code, i.value, i.date)}</b>
                                        </td>
                                    ))}
                                    {headerQualite?.map((y) =>
                                        groupedQualite[y?.equipement]?.map((it, ke) => (
                                            <td
                                                style={{
                                                    border: "1px solid",
                                                    background: "#f8fff3",
                                                }}
                                                key={ke}
                                            >
                                                <b>
                                                    {findEtatQualite(y?.equipement, it.libelle, i.value, i.date)}
                                                </b>
                                            </td>
                                        ))
                                    )}
                                    {HeaderVisibilité?.map((y) =>
                                        groupedVisibilite[y?.equipement]?.map((it, ke) => (
                                            <td
                                                style={{
                                                    border: "1px solid",
                                                    background: "#fff9f9",
                                                }}
                                                key={ke}
                                            >
                                                <b>
                                                    {findEtatVisibiltie(y?.equipement, it.libelle, i.value, i?.date)}
                                                </b>
                                            </td>
                                        ))
                                    )}
                                    {produit_releve?.map((y) => (
                                        <>

                                            <td
                                                style={{ border: "1px solid", background: "#EEF1FF" }}
                                                className="text-center fz-10">
                                                {findPrixRelevePrix(y.produit_code, i.value, i.date)}
                                            </td>
                                            <td
                                                style={{ border: "1px solid", background: "#EEF1FF" }}
                                                className="text-center fz-10"
                                            >
                                                {findRelevePrix(
                                                    y.produit_code,
                                                    i.value,
                                                    y.prix_min,
                                                    y.prix_max,
                                                    i.date
                                                )}
                                            </td>
                                        </>
                                    ))}
                                    {partLineaire?.map((y) => (
                                        <>
                                            <td
                                                style={{ border: "1px solid", background: "#dfe2f7" }}
                                                className="text-center fz-10">
                                                <b>{findPartLineaire(
                                                    y.produit_code,
                                                    i.value,
                                                )}</b>
                                            </td>
                                            <td
                                                style={{ border: "1px solid", background: "#dfe2f7" }}
                                                className="text-center fz-10"
                                            >
                                                <b>{findPartLineaire(
                                                    y.produit_code,
                                                    i.value,
                                                ) >= ComparePartLineaire(
                                                    y.gamme_code,
                                                ) ? 1 : 0}</b>
                                            </td>

                                        </>
                                    ))}
                                </tr>
                            )}
                      
                </tbody>
            </table>
        </div>
    )
}
