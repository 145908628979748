/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getDashboardItems, getGraphEnseigne, getGraphGammes, getMessageDashboard, getTableauDayMonthDashboard } from '../../services/Dashboard/FirstPage'
import CardsDashboard from './CardsD/CardsDashboard'
import Tableau from './TableDashboard/Tableau'
import TourneeMerch from './Tournees/TourneeMerch'
import _ from 'lodash'
import GraphEnseignes from './graph/GraphEnseignes'
import GraphRegion from './graph/GraphRegion'
import GraphGammes from './graph/GraphGammes'
import { useTranslation } from 'react-i18next'
import Marquee from "react-fast-marquee";

export default function MainPage() {
    const object = JSON.parse(localStorage.getItem('user'))
    const user = object?.result
    const [today, settoday] = useState(moment().format('YYYY-MM-DD'))
    const [listeTournee, setlisteTournee] = useState([])
    const [listeAbsences, setlisteAbsences] = useState([])
    const [listemessages, setlistemessages] = useState([])
    const [countPdv, setcountPdv] = useState([]) // today
    const [countPdvMonth, setCountPdvMonth] = useState([]) //month
    const [countPDV_planifieee, setcountPDV_planifieee] = useState([]) //month

    const [countRupture, setcountRupture] = useState([]) //rupture
    const [countPL, setcountPL] = useState([]) //pl
    const [countReleve, setcountReleve] = useState([]) //releve prix
    const [countPromotions, setcountPromotions] = useState([]) //promotions
    const [inMagasins, setInMagasins] = useState([])
    const [outMagasins, setoutMagasins] = useState([])
    const [enseigne, setenseigne] = useState(false)
    const [gamme, setgamme] = useState(false)
    const [regions, setregions] = useState(false)
    const [listePromo, setListePromo] = useState([])
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")
    var pdv_planifie = 0;
    const [listeGammes, setlisteGammes] = useState([])
    const [totalGamme, settotalGamme] = useState(0)
    const [listeEnseigne, setlisteEnseigne] = useState([])
    //------------ GRAPH ENSEIGNE PAR Gamme
    const [graphEnseigne, setgraphEnseigne] = useState([])
    const [totalEnsGamme, settotalEnsGamme] = useState([])
    const [totalGammeEnse, settotalGammeEnse] = useState([])
    //------------ GRAPH Enseignes --------------
    const [totalEnseigne, settotalEnseigne] = useState(0)
    const [ruptureEnseigne, setruptureEnseigne] = useState([])
    const [partLineaireEnseigne, setpartLineaireEnseigne] = useState([])
    const [graphGamme, setgraphGamme] = useState([])
    const [graphMonth, setgraphMonth] = useState([])
    //-------------LOADER ----------------------
    const [loadingCard, setloadingCard] = useState(true)
    const [loadingTable, setloadingTable] = useState(true)
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    const [dateTime, setDateTime] = useState({
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds()
    });

    const datetime =
    {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        0: "Dimanche"
    }
    const [data, setdata] = useState({
        nbUser: "",
        visite_today: "",
        visite_month: "",
        nbrPdv: "",
        nbr_promo: "",
        rupture_today: "",
        rupture_yesterday: "",
        rupture_month: "",
        merch_month: "",
        merch_today: "",
        rupture_monthPrecedent: "",
        rupture_annee: "",
        centralise: "",
        distributeur: "",
        partLineaire: "",
        partLineaire_today: "",
        partLineaireprecedent: "",
        totalVisiteDistinct: "",
        contact_avec_achat_today: "",
        contact_avec_achat_hier: "",
        contact_sans_achat_today: "",
        contact_sans_achat_hier: "",
        contact_total_today: "",
        contact_total_hier: ""

    })

    const onChangeDateFormat = () => {
        traitement()
    }

    const traitement = () => {
        const days = new Date(today).getDay()
        const todayDays = datetime[days]
        getMessageDashboard(today).then((result) => {
            if (result?.data?.success === 1) {
                setlistemessages(result?.data?.message)
            }
        }).catch((err) => {
            console.log(err)
        });
        //Get Liste of Objects with month 
        getDashboardItems(today)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListePromo(result?.data?.listePromo)
                    setdata({
                        nbUser: result?.data?.nbrMerch?.nbruser,
                        visite_today: result?.data?.visite_today?.visite_today,
                        visite_month: result?.data?.visite_month?.visite_month,
                        nbrPdv: result?.data?.nombrePdv?.nbrpdv,
                        nbr_promo: result?.data?.nbr_promo?.nbrpromo,
                        rupture_today: result?.data?.rupture_today?.rupture_today,
                        rupture_yesterday: result?.data?.rupture_yesterday?.rupture_yesterday,
                        rupture_month: result?.data?.rupture_month?.rupture_month,
                        merch_month: result?.data?.merch_month?.merch_month,
                        merch_today: result?.data?.merch_today?.merch_today,
                        rupture_monthPrecedent: result?.data?.rupture_monthPrecedent?.rupture_monthPrecedent,
                        rupture_annee: result?.data?.rupture_annee?.rupture_annee,
                        manque_a_gagner: result?.data?.manque_a_gagner?.sum_manque_a_gagne,
                        partLineaire: result?.data?.partLineaire?.parlinear,
                        partLineaire_today: result?.data?.partLineaire_today?.partLineaire_today,
                        pdv_partLineaire_month: result?.data?.pdv_partLineaire_month?.pdv_partLineaire_month,
                        pdv_partLineaire_today: result?.data?.pdv_partLineaire_today?.pdv_partLineaire_today,
                        rupture_anneeprecedent: result?.data?.rupture_anneeprecedent?.rupture_annee,
                        partLineaireprecedent: result?.data?.partLineaireprecedent?.parlinear,
                        totalVisiteDistinct: result?.data?.totalVisiteDistinct?.total_visite_distinct,
                        totalVisiteDistinctYear: result?.data?.totalVisiteDistinctYear?.total_visite_distinct,
                        contact_avec_achat_today: result?.data?.contactstoday?.contacts_avec_achat,
                        contact_avec_achat_hier: result?.data?.contactsyesterday?.contacts_avec_achat,
                        contact_sans_achat_today: result?.data?.contactstoday?.contact_sans_achat,
                        contact_sans_achat_hier: result?.data?.contactsyesterday?.contact_sans_achat,
                        contact_total_today: result?.data?.contactstoday?.total_Contact,
                        contact_total_hier: result?.data?.contactsyesterday?.total_Contact,
                        pdv_planifiee: result?.data?.pdv_planifiee?.pdv_planifiee
                    })
                    setlisteAbsences(result?.data?.absence)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(()=>{
                setloadingCard(false)
            })
        getTableauDayMonthDashboard(today, todayDays)
            .then((result) => {
                if (result?.data?.success === 1) {
                    //COUNT DAILY PDV
                    const gr = _.groupBy(result?.data.nbr_pdvJour, "user_code")
                    setcountPdv(gr)
                    const gr1 = _.groupBy(result?.data.tableauMonth, "code")
                    const gr2 = _.groupBy(result?.data.nbr_pdvPlanfieeMonth, "user_code")
                    setcountPDV_planifieee(gr2)
                    setCountPdvMonth(gr1)
                    setlisteTournee(result?.data?.jour)
                    {listeTournee?.map((items, keys) => (
                        pdv_planifie+=items.pdv_planifiee
                    ))}
                }
            }).catch((err) => {
                console.log(err)
            }).finally(()=>{
                setloadingTable(false)
            })
        getGraphEnseigne(date1, date2)
            .then((result) => {
                if (result?.data?.success === 1) {
                    // GRAPHE N°2
                    setgraphEnseigne(result?.data?.detailsEnseigne)
                    settotalEnsGamme(result?.data?.totalparEnseigne)
                    // GRAPHE N°2
                    settotalEnseigne(result?.data?.totalEnseigne)
                    setruptureEnseigne(result?.data?.ruptureEnseigne)
                    // GRAPHE N°3
                    setpartLineaireEnseigne(result?.data?.detailsPartLineaires)

                }
            }).catch((err) => {
                console.log(err)
            });
        getGraphGammes(date1, date2)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteGammes(result?.data?.GrapheParGamme)
                    settotalGamme(result?.data?.total)
                    setgraphGamme(result?.data?.detailsEnseigne)
                    setlisteEnseigne(result?.data?.GraphEnseigneGamme)
                    settotalGammeEnse(result?.data?.totalparEnseigne)
                    setgraphMonth(result?.data?.detailsPartLineaires)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }

    useEffect(() => {
        traitement()
        const timer = setInterval(() => {
            const date = new Date();
            setDateTime({
                hours: date.getHours(),
                minutes: date.getMinutes(),
                seconds: date.getSeconds()
            });
        }, 1000);
        return () => clearInterval(timer);
    }, [])

    return (
        <div>
            {/* Page-header opened */}
            <div className="page-header">
                <div className="page-leftheader mt-3">
                    <h4 className="page-title mb-0 fs-14">{t('dashboard.title')} , {user?.nom} </h4>
                    <small className="text-muted mt-0 fs-14"> {t('dashboard.aujourdhui')}  {moment().format(" dddd LL")} : <b className='text-primary'>{dateTime.hours}:{dateTime.minutes}:{dateTime.seconds} <i className='fa fa-clock-o ' />  </b>{/* <time dateTime={response}>{response}</time>. */}</small>

                </div>
                <div className="page-rightheader">
                    <div className="ml-3 ml-auto d-flex mt-3">
                        <div className="mt-3 mt-md-0">
                        </div>
                        <span className="mt-3 mt-md-0 pg-header">
                            <div className="input-group">
                                <input type="date" value={today} onChange={(e) => settoday(e.target.value)} className="form-control text-primary fs-12" />
                                <span className="input-group-append">
                                    <button className="btn btn-primary fs-12" type="button" onClick={() => onChangeDateFormat()}><i className='ti-search' /></button>
                                </span>
                            </div>

                        </span>
                    </div>
                </div>
            </div>
            {/**---------------------- INFORMATIONS --------------------------- */}
            <div className="row mb-3">
                <div className="col-xl-12 col-md-12 col-lg-12">
                    <marquee> {listemessages?.map((items, keys) => (
                        <span className='text-primary'> {items.libelle + "  * "}</span>
                    ))}
                    </marquee>

                    <div className="best-ticker" id="newsticker1" style={{ height: "40px!important" }}>
                        <div className="font-weight-bold bn-label fs-12 ">{t('dashboard.Information')} </div>

                        <Marquee speed={40} gradientWidth={50}  >
                            <ul className='mt-2'>
                                <li>
                                    <h5 className="font-weight-normal mt-3 mr-2 mb-2 fs-12" >

                                        <b>{t('dashboard.rupture')}</b> ({moment(today).format("DD-MM-YYYY")}) :  {data?.rupture_today}
                                        {data?.rupture_today > data?.rupture_yesterday ? <i className="fe fe-arrow-up text-danger mr-1" /> : <i className="fe fe-arrow-down text-success mr-1" />}
                                        <b>{t('dashboard.hier')}</b>  {data?.rupture_yesterday}
                                        {user?.type === "promotrice" ? <>
                                            <b className='ml-2'>, {t('Nbr Contacts avec achats')}  ({moment(today).format("DD-MM-YYYY")}):  </b>  {data?.contact_avec_achat_today === null ? 0 : data?.contact_avec_achat_today} {data?.contact_avec_achat_today > data?.contact_avec_achat_hier ? <i className="fe fe-arrow-up text-success mr-2" /> : <i className="fe fe-arrow-down text-danger mr-2" />}
                                            <b>{t('dashboard.hier')}</b> {data?.contact_avec_achat_hier === null ? 0 : data?.contact_avec_achat_hier}

                                            <b className='ml-2'>, {t('Nbr Contacts sans achats')}  ({moment(today).format("DD-MM-YYYY")}) </b> {data?.contact_sans_achat_today === null ? 0 : data?.contact_sans_achat_today} {data?.contact_sans_achat_today > data?.contact_sans_achat_hier ? <i className="fe fe-arrow-up text-danger mr-2" /> : <i className="fe fe-arrow-down text-success mr-2" />}
                                            <b>{t('dashboard.hier')}</b>  {data?.contact_sans_achat_hier === null ? 0 : data?.contact_sans_achat_hier}

                                            <b className='ml-2'>,{t('Nbr Contacts total')}   ({moment(today).format("DD-MM-YYYY")})</b> {data?.contact_total_today} {data?.contact_total_today > data?.contact_total_hier ? <i className="fe fe-arrow-up text-success mr-2" /> : <i className="fe fe-arrow-down text-danger mr-2" />}
                                            <b>{t('dashboard.hier')}</b> {data?.contact_total_hier}
                                        </> : null}


                                    </h5>

                                </li>
                            </ul>
                        </Marquee>
                    </div>
                </div>
            </div>
            {/* ---------------  Card Dashboard ------------------------------*/}
            <CardsDashboard data={data} today={today} listePromo={listePromo} t={t} user={user} loadingCard={loadingCard} />
            {/* ---------------  Tournee Dashboard ------------------------------*/}
            <TourneeMerch today={today} settoday={settoday} t={t} user={user} />

            {listeAbsences?.length !== 0 ? (
                <div className="best-ticker mb-3" id="newsticker1" style={{ height: "40px!important" }}>
                    <div className="font-weight-bold bn-label fs-12 "> Absents  (hier) </div>
                    <Marquee speed={40} gradientWidth={50}  >
                            {listeAbsences?.map((items, keys) => (
                                <ul className='mt-2'>
                                <li>
                                    <h5 className="font-weight-normal mt-3 mr-2 mb-2 fs-12" key={keys}>
                                        <b className='ml-2 text-danger'> {items.nom}  </b>
                                    </h5>
                                </li>
                                </ul>
                            ))}
                    </Marquee>
                </div>
            ) : null}

            {/* ---------------  Tableau Tournee ------------------------------*/}
            <Tableau  countPDV_planifieee={countPDV_planifieee} loadingTable={loadingTable} datetime={datetime} listeTournee={listeTournee} today={today} countPdv={countPdv} countPdvMonth={countPdvMonth} countPromotions={countPromotions} countPL={countPL} countReleve={countReleve} countRupture={countRupture} inMagasins={inMagasins} outMagasins={outMagasins} t={t} />

            {/**-------------- BOUTONS -------------------------------------+ */}
            <div className='row mb-2'>
                <div className='col-4' />
                <div className='col-4 text-center'>
                    <button className='btn btn-azure mr-1 fs-12' onClick={() => setregions(!regions)} ><i className='icon icon-location-pin bell-animations mr-1' /> {t('dashboard.region')} </button>
                    <button className='btn btn-azure mr-1 fs-12' onClick={() => setgamme(!gamme)}><i className='icon icon-organization bell-animations  mr-1' /> {t('dashboard.Gamme')} </button>
                    <button className='btn btn-azure mr-1 fs-12' onClick={() => setenseigne(!enseigne)}><i className='icon icon-home bell-animations mr-1' /> {t('dashboard.Enseigne')} </button>
                </div>
                <div className='col-4' />

            </div>
            <div className='row'>
                {
                    enseigne ? <GraphEnseignes partLineaireEnseigne={partLineaireEnseigne} graphEnseigne={graphEnseigne} totalEnsGamme={totalEnsGamme} ruptureEnseigne={ruptureEnseigne} totalEnseigne={totalEnseigne} date1={date1} date2={date2} t={t} /> : null
                }
                {
                    gamme ? <GraphGammes graphMonth={graphMonth} graphEnseigne={graphGamme} totalEnsGamme={totalGammeEnse} total={totalGamme} listeGammes={listeGammes} listeEnseigne={listeEnseigne} loading={loading} date1={date1} date2={date2} t={t} /> : null
                }
                {
                    regions ? <GraphRegion date1={date1} date2={date2} t={t} /> : null
                }
            </div>

        </div>
    )
}
