/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { getListePdvByRoutingsCode, getListeTourneeByCode } from '../../../services/Routings/RoutingsApi'
import AlerteTournee from './AlerteTournee'
import EditDetailsTournee from './EditDetailsTournee'
import MapTournee from './MapTournee'
import ModalAddPdv from './ModalAddPdv'

export default function DetailsTournee() {
    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
    //CodeTournee
    const { code } = useParams('')
    const [codePdv, setcodePdv] = useState('')
    //---------- LISTE TOURNEES ----------------------
    const [listeTournee, setlisteTournee] = useState({})
    const [saveListe, setsaveListe] = useState([])
    const [listeLocalisation, setlisteLocalisation] = useState([])
    const [ok, setok] = useState(false)
    const [actif, setactif] = useState("")
    const [quota, setquota] = useState("")
    const [rang, setrang] = useState("")
    //------------- LISTE PDVS BY ROUTINGS CODE -----
    const [listePdvs, setlistePdvs] = useState([])
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //-------------------- LOADER ---------------
    const [loading, setloading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(30);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;

    //Current Liste 
    const currentListe = listeLocalisation?.slice(indexOfFirstPost, indexOfLastPost)
    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")

    const traitement = (code) => {

        getListeTourneeByCode(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTournee(result?.data?.data)
                    setsaveListe(result?.data?.localisation)
                    setlisteLocalisation(result?.data?.localisation)
                    getListePdvByRoutingsCode(result?.data?.data?.routing_code)
                        .then((result) => {
                            if (result?.data?.success === 1) {
                                setlistePdvs(result?.data?.data)
                            }
                        }).catch((err) => {
                            console.log(err)
                        })
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }

    useEffect(() => {
        /**
        * GET LISTE TOURNEE BY CODE
        */
        traitement(code)
        if (ok === true) {
            setok(false)
        }
    }, [ok])

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb mt-3">
                    <li className="breadcrumb-item fs-14"><a href={'/routings/tournee&datedebut=' + date1 + '&datefin=' + date2}><i className="fe fe-life-buoy mr-1" /> {t('Routings.Tournee')} </a></li>
                    <li className="breadcrumb-item active fs-12" aria-current="page">  {t('Routings.detailstournee')} {code}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        {details?.role_code !== "sup" ?
                            <>
                                <a href="/routings/routings&route=-1&region=-1" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                                    <i className=" icon icon-plus mr-1" />
                                    {t('Routings.CreationTournee')}
                                </a>
                                <a href="/routings/TourneeZone" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                                    <i className="icon icon-event mr-1" />
                                    {t('Routings.creationAgenda')}
                                </a>
                            </>
                            : <a href="/routings/validationTournee" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                                <i className=" icon icon-plus mr-1" />
                                {t('rapports.validationTournee')}
                            </a>}
                        <a href="/routings/routes" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-map mr-1" />
                            {t('Routings.Route')}
                        </a>
                        <a href="/routings/tournee" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                         <i className="icon icon-map mr-1" />
                         {t('Tournee agenda par zone')}
                     </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-9 col-lg-12 col-md-12">
                    <div className="item2-gl ">
                        <div className="item2-gl-nav bg-white d-flex">
                            <h6 className="mb-0 mt-2 text-primary  fs-12" > <i className="ti-truck mr-1" />{t('Routings.Tournee') + " "} <span className=" fs-12"> {t('Routings.CODE') + ' ' + code + ' ,' + t('Routings.Jour') + " : " + listeTournee?.frequence === "Par_dates" ? "-" : listeTournee?.code_jour + " ," + t('Routings.frequence') + " : " + listeTournee?.frequence + " ," + t('Routings.Route') + " : " + listeTournee?.routing_code + " ," + t('Routings.NPDV') + " : " + listeTournee?.magasins} </span> </h6>
                            <ul className="nav item2-gl-menu ml-auto">
                            </ul>
                            <div className="d-flex">
                                <label className="mr-2 mt-1 text-primary fs-12"><i className="ti-calendar mr-1" />  <b> {t('dashboard.Date') + " "} {moment(listeTournee?.date_debut).format('DD-MM-YYYY')} - {moment(listeTournee?.date_fin).format('DD-MM-YYYY')}</b></label>
                            </div>
                        </div>
                        <ToastContainer />
                        <div className="tab-content">
                            <div className="tab-pane active" id="tab-11">
                                {loading ? <SqaureLoader /> : (
                                    <div className="card">
                                        <div className="card-body">
                                            <div className=' mb-2'>
                                                <h6 className='fs-12 float-left text-primary'> <i className="ti-user mr-1" />  {t('dashboard.Merchandiseur')} : {listeTournee?.nomuser} </h6>
                                                <h6 className='fs-14 float-right'> 	<a href="#" data-toggle="modal" data-target="#largeModal" className="btn btn-azure btn-sm"><i class="icon icon-plus mr-2"></i>  {t('Magasins.AjouterPDV')} </a> </h6>
                                            </div>
                                            <div className="table-responsive product-datatable" style={{ overflow: 'auto', height: '600px' }}>
                                                <table className="table table-striped table-bordered ">
                                                    <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >
                                                        <tr>
                                                            <th className="fs-10" >{t('Routings.rang')}</th>
                                                            <th className="fs-10" >{t('dashboard.Magasin')}</th>
                                                            <th className="fs-10" >{t('Produits.REGION')}</th>
                                                            <th className="fs-10" >{t('Routings.adresse')}</th>
                                                            <th className="fs-10" >{t('dashboard.Date')}</th>
                                                            {details?.type === "promotrice" ? <th className="wd-15p fs-10" >{t('quota')}</th> : null}
                                                            <th className="fs-10" >{t('Routings.ACTIF')}</th>
                                                            <th className="fs-10 " >{t('rapports.ACTION')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-center">
                                                        {currentListe?.map((items, keys) => (
                                                            <tr className="mb-0 mt-0" key={keys}>
                                                                <td className="fs-10 "> {items.rang} </td>
                                                                <td className="fs-10"><a href={'/pdv/historique&code=' + items.pdv_code}>{items.magasin}</a> </td>
                                                                <td className="fs-10">{items.region === null ? <span className="text-warning"> Aucun </span> : items.region} </td>
                                                                <td className="fs-10">{items.adresse === null ? <span className="text-warning"> Aucune </span> : items.adresse} </td>
                                                                <td className="fs-10"> {items.dates}  </td>
                                                                {details?.type === "promotrice" ? <td className="fs-10"> {items.quota}  </td> : null}
                                                                <td className='fs-10' ><div className=" mb-0" style={{ textAlign: "center", display: "inline-flex" }}>
                                                                    <div className={items.actif === "1" ? "w-3 h-3 bg-success text-center " : "w-3 h-3 bg-danger text-center "} />
                                                                    <div>
                                                                    </div>
                                                                </div>
                                                                </td>
                                                                <td style={{ cursor: "pointer" }} >
                                                                    <a data-toggle="modal" data-target="#modal" onClick={() => { setcodePdv(items.pdv_code); setactif(items.actif) ; setquota(items.quota) ; setrang(items.rang)}} >
                                                                        <i className="icon icon-note text-azure mr-1" />
                                                                    </a>
                                                                    <a data-toggle="modal" data-target="#modal-notification" onClick={() => setcodePdv(items.pdv_code)}>
                                                                        <i className="icon icon-trash text-danger" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="float-right">
                                                    <PaginationTable
                                                        postsPerPage={stocksPerPage}
                                                        totalPosts={listeLocalisation.length}
                                                        currentPage={currentPage}
                                                        setCurrentPage={setCurrentPage}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-12 col-md-12">
                    <div className="card">
                        <MapTournee listeEnseinges={listeLocalisation} loading={loading} t={t} />
                    </div>
                </div>
            </div>
            <EditDetailsTournee code={code} codepdv={codePdv} setok={setok} actif={actif} setactif={setactif} quota={quota} setquota={setquota} rang={rang}setrang={setrang} t={t} />
            <AlerteTournee code={code} codepdv={codePdv} setok={setok} t={t} />
            <ModalAddPdv user_code={listeTournee?.user_code} t={t} tournee={code} setok={setok} frequence={listeTournee?.frequence} routing={listeTournee?.routing_code} code_jour={listeTournee?.code_jour} listepdv={listePdvs} datedebut={moment(listeTournee?.date_debut).format('YYYY-MM-DD')} datefin={moment(listeTournee?.date_fin).format('YYYY-MM-DD')} />
        </div>
    )
}
