import React, { useState } from 'react'
import { addRoles } from '../../../../services/Users/UsersApi'
import { toast } from 'react-toastify'

export default function ModalAddRoles({setShow , t}) {

    //------- FORMULAIRE --------------
    const [code, setCode] = useState("")
    const [libelle, setLibelle] = useState("")

    const saveData = () => {
        const data = {
            code : code,
            libelle : libelle
        }
        //Data of code & libelle FOR OBJECT Roles
        if(code !== "" && libelle !== ""){
            addRoles(data) 
            .then((result) => {
                if (result?.data?.success === 1) {
    
                    toast(t('messageErreur.ajoutavecsuccee'), { type: 'success', position: 'top-right' })
                } else {
                    toast(t('Zoning.Erreur'), { type: 'error', position: 'top-right' })
                }
            }).catch((err) => {
                console.log(err)
            });
        }
        
    }
    return (
        <div className="modal fade" id="addRoles" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="addRoles"> {t('users.ajouterrole')} </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="form-group">
                            <label className="form-control-label">{t('rapports.code')} :</label>
                            <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label className="mr-2 mt-1 mb-sm-1"> {t('dashboard.Libelle')}<span className="text-danger">*</span></label>
                            <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} className="form-control" />

                        </div>
                        
                    </form>
                </div>
                <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => saveData()} ><i className='ti-save mr-1'/> {t('rapports.enregistrer')} </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShow(false)}> <i className='ti-close mr-1'/> {t('Routings.annueler')}</button>

                    </div>
            </div>
        </div>
    </div>
    )
}
