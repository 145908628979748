/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast, ToastContainer } from 'react-toastify';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import PaginationTable from '../../../components/Pagination/PaginationTable';
import { getListeRuptures, groupedEnseigne, groupedPdv, groupedUser } from '../../../services/Ruptures/ruptureAPI'
import { SearchItems } from '../../../utils/SearchUtils'
import ModalExportExcel from './ModalExportExcel';

export default function Listeruptures() {
    const history = useHistory();

    const { datedebut, datefin } = useParams('')
    //------------- LISTE RUPTURES 
    const [listeRuptures, setListeRuptures] = useState([])
    const [saveList, setSaveList] = useState([])
    const [searchList, setSearchlist] = useState([])

    //----------- LISTE FILTRE  ------------------
    const [listeMerch, setlisteMerch] = useState([])
    const [listeEnseigne, setlisteEnseigne] = useState([])
    const [listeMagasin, setlisteMagasin] = useState([])
    const [saveListeMagasin, setsaveListeMagasin] = useState([])
    const [codeEtat, setCodeEtat] = useState(-1)
    const [codeUser, setcodeUser] = useState("")
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [codepdv, setcodepdv] = useState("")
    const [dateDeb, setdateDeb] = useState(datedebut)
    const [dateFin, setdateFin] = useState(datefin)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var year = moment(firstDay).format("YYYY")
    const [show, setshow] = useState(false);
    //USE REF
    const inputEl = useRef("")
    const searchInput = useRef();
    // Formulaire 
    const [search, setSearch] = useState([])
    // Loading 
    const [loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(50);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeRuptures.slice(indexOfFirstPost, indexOfLastPost) : searchList.slice(indexOfFirstPost, indexOfLastPost)

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (inputEl.current.value !== "") {
            const newList = SearchItems(listeRuptures, inputEl.current.value)
            setSearchlist(newList)
        } else {
            setListeRuptures(saveList)
        }
    }

    useEffect(() => {
        groupedUser()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteMerch(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });

        groupedPdv().then((result) => {
            setlisteMagasin(result?.data?.data)
            setsaveListeMagasin(result?.data?.data)
        }).catch((err) => {
            console.log(err)
        });

        groupedEnseigne()
            .then((result) => {
                setlisteEnseigne(result?.data?.data)
            }).catch((err) => {
                console.log(err)
            });
        traitement()
    }, [])

    const traitement = () => {
    
        const data = {}
        if(codeEnseigne !==""){
            Object.assign(data,{
                enseigne_code :codeEnseigne
            })
        }
        if(codeEtat !== -1){
            Object.assign(data,{
                etat_repture : codeEtat
            })
        }
        if(codeUser!==""){
            Object.assign(data,{
                user_code :codeUser
            })
        }
        if(codepdv !== ""){
            Object.assign(data,{
                pdv_code :codepdv
            })
        }
        getListeRuptures(dateDeb, dateFin,data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListeRuptures(result?.data?.data)
                    setSaveList(result?.data.data)
                }
            }).catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const backToInit = () => {

        /**
         * CODE FILTRE ENSEIGNE / MERCH / ETAT / PDV
         */
        setcodeEnseigne("")
        setcodeUser("")
        setCodeEtat(-1)
        setcodepdv("")
        /**
         * GET LISTE OF ALL RUPTURES
         */
        getListeRuptures(dateDeb, dateFin,{})
        .then((result) => {
            if (result?.data?.success === 1) {
                setListeRuptures(result?.data?.data)
                setSaveList(result?.data.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        .finally(() => {
            setLoading(false)
        })
    }


    const onChangeDate = () => {

        if (dateDeb !== "" && dateFin !== "") {
            if (dateDeb <= dateFin) {
                setLoading(true)
                history.push({
                    pathname: "/ruptures/details&datedebut=" + dateDeb + "&datefin=" + dateFin
                })
                traitement()
            } else if (dateFin < dateDeb) {
                toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
            }
        }
    }
    const onChangeEnseigne = (e) =>{
        setcodeEnseigne(e.target.value)
        const L= saveListeMagasin?.filter((el)=>el.enseigne_code===e.target.value)
        setlisteMagasin(L)
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.rupture')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> Détails Relevé</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href={"/ruptures/annuel&annee=" + year} className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="ti-write mr-2" />
                            {t('sidebar.ruptureAnnuel')}
                        </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            {/** ---------------------------------- DETAILS RUPTURES ---------------------------------- */}
            {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" /> {t('relevePrix.detailsReleve')}
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <div className="input-group">
                                        <input type="text" ref={inputEl} value={search} onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 fs-10 text-primary" placeholder={t('rapportrupture.rechercher')} />
                                        <input type="date" value={dateDeb} onChange={(e) => { setdateDeb(e.target.value) }} className="form-control br-tr-md-0 br-br-md-0  fs-10 text-primary" placeholder={t('rapportrupture.rechercher')} />
                                        <input type="date" value={dateFin} onChange={(e) => { setdateFin(e.target.value) }} className="form-control br-tr-md-0 br-br-md-0  fs-10 text-primary" placeholder={t('rapportrupture.rechercher')} />
                                        <select className="form-control  br-md-0  fs-10 text-primary" value={codeEnseigne} onChange={(e) => onChangeEnseigne(e)}>
                                            <option value={1} selected>{t('dashboard.Enseigne')}</option>
                                            {listeEnseigne?.map((items, keys) => (
                                                <option key={keys} value={items.value}>{items.name}</option>
                                            ))}
                                        </select>
                                        <select className="form-control  br-md-0  fs-10 text-primary" value={codeUser} onChange={(e) => setcodeUser(e.target.value)}>
                                            <option value={1} selected>{t('dashboard.Merchandiseur')}</option>
                                            {listeMerch?.map((items, keys) => (
                                                <option key={keys} value={items.value}>{items.name}</option>
                                            ))}
                                        </select>
                                        <select className="form-control  br-md-0  fs-10 text-primary" value={codepdv} onChange={(e) =>  setcodepdv(e.target.value)}>
                                            <option value={1} selected>{t('dashboard.Magasin')}</option>
                                            {listeMagasin?.map((items, keys) => (
                                                <option key={keys} value={items.value}>{items.name}</option>
                                            ))}
                                        </select>
                                        <select className="form-control br-md-0  fs-10 text-primary" value={codeEtat} onChange={(e) =>setCodeEtat(e.target.value)}>
                                            <option value={-1} selected>{t('rapportrupture.etat')}</option>
                                            <option value={"0"}>{t('rapportrupture.disponible')}</option>
                                            <option value={"1"}>{t('rapportrupture.rupture')}</option>
                                        </select>
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => onChangeDate()}><i className='ti-search' /></button>
                                            <button className="btn btn-secondary" type="button" onClick={() => backToInit()}><i className='ti-close' /></button>

                                        </span>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12'/>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'>
                                {/* <div className="input-group">
                                    <label className='col-form-label ml-2 mr-2 mt-1'> <i className=' icon icon-equalizer mr-1'/>  Filtre : </label>
                                    <select className="form-control  br-md-0  fs-10 text-primary" value={codeUser} onChange={(e) => filterbyenseigne(e)}>
                                            <option value={1} selected>{t('dashboard.Enseigne')}</option>
                                            {listeEnseigne?.map((items, keys) => (
                                                <option key={keys} value={items.value}>{items.name}</option>
                                            ))}
                                        </select>     
                                        <select className="form-control  br-md-0  fs-10 text-primary" value={codeUser} onChange={(e) => filterbyUser(e)}>
                                            <option value={1} selected>{t('dashboard.Merchandiseur')}</option>
                                            {listeMerch?.map((items, keys) => (
                                                <option key={keys} value={items.value}>{items.name}</option>
                                            ))}
                                        </select>                                   
                                        <select className="form-control  br-md-0  fs-10 text-primary" value={codepdv} onChange={(e) => filterbyMagasin(e)}>
                                            <option value={1} selected>{t('dashboard.Magasin')}</option>
                                            {listeMagasin?.map((items, keys) => (
                                                <option key={keys} value={items.value}>{items.name}</option>
                                            ))}
                                        </select>
                                        <select className="form-control br-md-0  fs-10 text-primary" value={codeEtat} onChange={(e) => filtreByEtat(e)}>
                                            <option value={-1} selected>{t('rapportrupture.etat')}</option>
                                            <option value={"0"}>{t('rapportrupture.disponible')}</option>
                                            <option value={"1"}>{t('rapportrupture.rupture')}</option>
                                        </select>
                                        <button className="btn bg-yellow" type="button" onClick={() => setListeRuptures(saveList)}><i className="ti-close"></i></button>

                                    </div> */}
                                </div>
                             
                            </div>
                            <div className='row mb-2'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                            <h6 className=" mt-2 fs-12">  <span className="text-info font-weight-normal"> <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span>   <div className="d-flex align-items-center  mb-3 mt-3 mr-2 fs-12">
                                <div className="w-3 h-3 bg-success  mr-2" />
                                <div className='fs-10 font-weight-normal text-muted'>
                                    <strong>{t('rapportrupture.etatDisponible')}</strong>
                                    <br />
                                </div>
                                <div className="w-3 h-3 bg-danger  mr-2 ml-2 fs-10" />
                                <div className='fs-10 font-weight-normal text-muted'>
                                    <strong>{t('rapportrupture.etatRupture')}</strong>
                                    <br />
                                </div>
                                </div>
                            </h6>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12'/>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'>
                                <span className="col-auto float-right mt-3">
                                    <button className="btn btn-azure btn-pill fs-12 mt-0" data-toggle="modal" data-target="#exampleModal3" type="button" onClick={() => { setshow(true) }}><i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')}</button>
                                </span>
                                </div>
                             </div>
                         
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12 col-lg-12 col-xl-12'>
                    <div className="card">
                        <div className="card-body">
                            {loading ? <SqaureLoader /> : ( 
                                 <div className="table-responsive table-striped product-datatable" style={{ overflow: 'auto', height: '800px' }}>
                                    <table
                                        id="example"
                                        className="table  table-bordered "
                                    >
                                        <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                            <tr>
                                                <td colSpan={11} className='bg-purple fs-12'> <b className='text-white'>{t('rapportrupture.totalrupture') + " " + (search.length < 1 ? listeRuptures.length : searchList.length) + " " + t('rapportrupture.du') + " " + dateDeb + "-" + dateFin}</b> </td>
                                            </tr>
                                            <tr >
                                                <th className="fs-10 w-15p"  >{t('rapportrupture.merch')}</th>
                                                <th className="fs-10 wd-15p" >{t('rapportrupture.date')}</th>
                                                <th className="fs-10 wd-15p" >{t('dashboard.Magasin')}</th>
                                                <th className="fs-10 wd-15p" >{t('dashboard.Enseigne')}</th>
                                                <th className="fs-10 wd-15p" >{t('rapportrupture.codeproduit')}</th>
                                                <th className="fs-10 wd-15p" >{t('rapportrupture.produit')}</th>
                                                <th className="fs-10 wd-15p" >{t('rapportrupture.categorie')}</th>
                                                <th className="fs-10 wd-15p" >{t('rapportrupture.famille')}</th>
                                                <th className="fs-10 wd-15p"  >{t('rapportrupture.codeMeti')}</th>
                                                <th className="fs-10 wd-15p" >{t('rapportrupture.etat')}</th>
                                                <th className="fs-10 wd-15p" >{t('rapportrupture.actioncause')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {currentListe.length === 0 ?
                                                <tr>
                                                    <td colSpan={11} className='text-danger text-center fs-14'>  {t('Zoning.Aucunresultat')} </td>
                                                </tr> :
                                                currentListe?.map((items, keys) => (
                                                    <tr key={keys}>
                                                        <td className='fs-10' >{items.nom_user} </td>
                                                        <td className='fs-10' >{moment(items.date).format("DD-MM-YYYY")} </td>
                                                        <td className='fs-10' ><a href={"/pdv/historique&code=" + items.pdv_code} className='text-primary'>{items.nom_pdv}</a> </td>
                                                        <td className='fs-10' >{items.nom_enseigne} </td>
                                                        <td className='fs-10' ><a href={"/produits/modifierProduits&id=" + items.produit_code} className='text-primary'><b>{items.produit_code}</b> </a> </td>
                                                        <td className='fs-10' >{items.nom_produit} </td>
                                                        <td className='fs-10' >{items.nom_categoriepdv} </td>
                                                        <td className='fs-10' >{items.famille} </td>
                                                        <td className='fs-10' >{items.code_meti !== null ? items.societe : "Aucun"} </td>
                                                        <td className='fs-10' ><div className=" mb-0" style={{ textAlign: "center", display: "inline-flex" }}>
                                                            <div className={items.etat_repture === "0" ? "w-3 h-3 bg-success text-center " : "w-3 h-3 bg-danger text-center "} />                                                            <div>
                                                            </div>
                                                        </div>
                                                        </td>
                                                        <td className='fs-10'>{items.cause !== null ? items.cause : "Aucune"} </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                    <div className="float-right">
                                        <PaginationTable
                                            postsPerPage={stocksPerPage}
                                            totalPosts={search.length < 1 ? listeRuptures.length : searchList.length}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {show ?
                            <ModalExportExcel listeRuptures={listeRuptures} t={t} />
                            : null}
                    </div>
                </div>
            </div>

        </div>
    )
}
