import React from 'react'
import SqaureLoader from '../../components/Loader/SqaureLoader'

export default function DetailsCatalogue({ object, t, Loading }) {
    return (
        <div className="modal fade" id="exampleModal3" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                {Loading ? <SqaureLoader /> : (
                    <div className="modal-content" style={{ width: "max-content" }}>
                        <div className="modal-header">
                            <h5 className="modal-title text-azure" id="example-Modal3"><i className='icon icon-book-open mr-1' /> {t('Catalogues.detailsCatalogue')}  </h5>
                            <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" /> </span>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="item-card9-imgs">
                                        <img src={object?.image !== null ? object?.image : "../assets/images/users/23.png"} alt="img" className="cover-image" style={{ width: "400px" }} />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="item-card9">
                                        <h6><b className='text-muted'> {t("dashboard.Magasin")}</b>  : {object?.nompdv}</h6>
                                        <h6> <b className='text-muted'> {t('Catalogues.Commercial')}  </b> : {object?.nom}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
