/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment, { ISO_8601 } from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useTranslation } from 'react-i18next';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { toast, ToastContainer } from 'react-toastify';
import LoaderTable from '../../../components/Loader/LoaderTable';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { getListeByZone, ShowListeByZone } from '../../../services/Routings/RoutingsApi';
import { getSelectZone } from '../../../services/Zoning/ZoningApi';
import { getDates } from '../../../utils/DateUtils';

export default function ShowAllTournee() {
  
    const searchInput = useRef();
   //--------- INTERVALLE DU TEMPS ----------------   
  const [date_debut, setdate_debut] = useState("")
  const [date_fin, setdate_fin] = useState("")
  //INFORMATIONS USERS
  const user = JSON.parse(localStorage.getItem('user'));
  const details = user?.result
   //------------- LISTE ---------------------
   const [liste, setliste] = useState([]) //DATES
   const [listeUsers, setlisteUsers] = useState([]) //users
   const [toutpdvs, settoutpdvs] = useState([]) //PDVS
   const [routings, setRoutings] = useState([])

  //ZONES
  const [listeZone, setlisteZone] = useState([])
  const [codeZone, setcodeZone] = useState("")
 //-------------- LOADER ---------------------
 const [loading, setloading] = useState(false)
 const [loadingTable, setloadingTable] = useState(true)
 //DATE TODAY
 var date = new Date();
 var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
 var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
 var date1 = moment(firstDay).format("YYYY-MM-DD")
 var date2 = moment(lastDay).format("YYYY-MM-DD")
 //------------- Traduction ---------------
 const [t, i18n] = useTranslation("global");

 // Handle Change Marque
 const handleChangeZones = (...args) => {
    setcodeZone(args[1].value)
};

useEffect(() => {
    getSelectZone()
        .then((result) => {
            if (result?.data?.success === 1) {
                setlisteZone(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });

}, [])

const findPdv = (date, user_code) => {
    const findEl = toutpdvs?.filter((el) =>  el.user_code === user_code && moment(el.dates).format("YYYY-MM-DD") === date)
    if (findEl[0]?.nompdv === undefined )  {
        return  <p className='fs-10 text-danger'>-</p>
    } else return findEl.map((i,k)=>(
        <>        <p  key={k} className='fs-10 text-azure'><b>{i.nompdv}</b></p>
        </>
    )) 

}
const onChangeValue = ()=>{
    if (date_debut > date_fin) {
        toast(t("messages.dateinf"), { type: "warning", position: "top-right" })
    } else if (codeZone === "") {
        toast(t("Routings.selectionnerZone"), { type: "warning", position: "top-right" })
    } else {
        setloading(true)
        const dates = getDates(new Date(date_debut), new Date(date_fin))
        setliste(dates)
        ShowListeByZone(codeZone,date_debut,date_fin)
        .then((result) => {
            if (result?.data?.success === 1) {
                //---- Liste de points de  ventes ------
                var list = []
                var tabulation = []
                dates?.map((items, keys) => {
                    var ob1 = Object.assign({}, {
                        date: moment(items).format("YYYY-MM-DD"),
                        pdvs: result?.data?.listePdvs
                    })
                    tabulation.push(ob1)
                })
                result?.data?.listePdvs?.map((i, k) => {
                    var obj = Object.assign({}, {
                        value: i.value,
                        name: i.name,
                        plannifie: 0
                    })
                    list.push(obj)
                })

                //--------- Liste PDV --------------
                settoutpdvs(result?.data?.liste)
                setlisteUsers(result?.data?.listeUsers)
                console.log(result?.data?.listeUsers);
                //------------------------------------
                setRoutings(result?.data?.listeroutings)
             
                
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })

    }

}
  return (
    <div>
        <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href={'/routings/tournee&datedebut='+date1+'&datefin='+date2}><i className="fe fe-life-buoy mr-1" />  {t('Routings.Tournee')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('Affichage des tournées par zone')}  </li>
                </ol>
                {details?.role_code==="admin" ||details?.role_code==="chef_merch"  ? 
                 <div className="mt-3 mt-lg-0">
                 <div className="d-flex align-items-center flex-wrap text-nowrap">
                     <a href={'/routings/tournee&datedebut='+date1+'&datefin='+date2} className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                         <i className="icon icon-directions mr-1" />
                         {t('Routings.Tournee')}
                     </a>
                     <a href="/routings/routings&route=-1&region=-1" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                         <i className="icon icon-plus mr-1" />
                         {t('Routings.CreationTournee')}
                     </a>
                     <a href="/routings/routes" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                         <i className="icon icon-map mr-1" />
                         {t('Routings.Route')}
                     </a>
                 </div>
             </div> : null
                }
            </div>
            <ToastContainer />
            <div>
            <div className='row'>
                    <div className='col-12'>
                    <div className='card' style={{ height: '200px' }}>
                            <div className='card-header'>
                                <h6 className='card-title text-center text-primary mt-3 fs-12'> {t('Affichage des tournées par zone')}</h6>
                            </div>
                            <div className='card-body'>
                                <div className='row mb-2'>
                                    <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12'></div>
                                    <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12'>
                                        <div className="input-group">
                                            <input type="date" value={date_debut} onChange={(e) => setdate_debut(e.target.value)} className="form-control text-primary fs-12" />
                                            <input type="date" value={date_fin} onChange={(e) => setdate_fin(e.target.value)} className="form-control text-primary fs-12" />
                                            <SelectSearch
                                                options={listeZone}
                                                search
                                                ref={searchInput}
                                                onChange={handleChangeZones}
                                                filterOptions={fuzzySearch}
                                                emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                                placeholder={t('Routings.choisirunezone')}
                                            />

                                            <span className="input-group-append">
                                                <button className="btn btn-primary fs-10" type="button" onClick={() => onChangeValue()} ><i className='ti-check' /></button>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12'></div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
            </div>
            <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                    <div className='float-right '>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 "
                    table="table-to-xls"
                    filename="Planning_Tournee"
                    sheet="tablexls"
                    buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                />
            </div> 
                        <div align="center">
                        <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '800px' }}>
                        <table id="table-to-xls"  border={1} className="table  table-bordered ">
                        <tbody className='bg-white'>
                        {loading ? <SqaureLoader /> : <>
                                            <tr className='bg-purple fs-10' style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                                <td align="center">
                                                <b>{t('dashboard.Date')} </b>
                                                </td>
                                                {listeUsers?.map((items, keys) => (
                                                    <td align="center" key={keys}>
                                                        <b value={items.value}> {items.name}</b>
                                                    </td>
                                                ))}
                                            </tr>
                                            {liste?.map((items, keys) => (
                                                <>
                                                    <tr key={keys}  className={
                                                    keys % 2 === 1 ?"fs-12 bg-blue-lightest" : "fs-12"
                                                }>
                                                        <td align="center" className='fs-10 text-azure' style={{ verticalAlign: "middle" }} > 
                                                            <b>{moment(items).format("DD dddd ")} </b>
                                                        </td>
                                                        {listeUsers?.map((i, k) => (
                                                    <td align="center" key={k}>
                                                      {findPdv(moment(items).format("YYYY-MM-DD"),i.value)}                                                    </td>
                                                ))}
                                                    </tr>
                                                </>
                                            ))}
                                            </>}
                        </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
            </div>
            </div>
    </div>
  )
}
