/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'

export default function TableauDetailsMerch({ enseignes, grouped, liste, listeGrouped, listeMarques, pdvs, t, setloading }) {

    const [marques, setmarques] = useState([])
    const [dates, setdates] = useState([])

    useEffect(() => {
        // GROUPEMENT DES PRODUITS PAR MARQUE
        var m = _.groupBy(listeGrouped, 'marque_code'); // m= marques
        setmarques(m);
        // GROUPEMENT DES DATES PAR PDVS
        var d = _.groupBy(liste, 'pdv_code'); //d= dates

        setdates(d)

        //delete the first index of all marques[items.value].
    }, [liste])

    const findListe = (date, pdv_code, produit_code, key) => {
        setloading(true)
        const findEl = liste?.filter((el) => el.produit_code === produit_code && el.pdv_code === pdv_code && moment(el.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"))
        setloading(false)


        if (findEl[0]?.etat_repture === undefined) {
            return <td className='fs-10  text-center' style={{ background: "#ABD9FF" }} key={key}><b> HA </b></td>
        } else return findEl[0]?.etat_repture === "1" ? <td className='fs-10 text-center' style={{ background: "#FFFDA2" }} key={key}> <b >R ({findEl[0].cause})</b></td> : <td className='fs-10 text-center' style={{ background: "#D9F8C4" }} key={key}><b >D</b></td>

    }

    const getCountDate = (enseigne_code) =>{
        var values_array = []
        pdvs[enseigne_code]?.map((items )=>{
            values_array.push(Object.keys(grouped[items.value])?.length)
        })
        const initialValue = 0;
        const sum = values_array.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
        );
        return sum
    }
    return (
        <div className='col-12'>
            <div className='float-left'>
                <table className='table table-responsive'>
                    <tr className='fs-10 '>
                        <td> Disponible : </td>
                        <td style={{ background: "#D9F8C4" }}> D </td>
                        <td> Rupture : </td>
                        <td style={{ background: "#FFFDA2" }}> R </td>
                        <td> Hors collection : </td>
                        <td style={{ background: "#ABD9FF" }}> HA </td>
                    </tr>
                </table>
            </div>
            <div className='float-right '>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-azure  mb-3 btn-pill fs-12 mt-0 "
                    table="table-to-xls"
                    filename="Rapport_Rupture_Merch"
                    sheet="tablexls"
                    buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                />
               
            </div>
           
            <div className="table table-responsive  product-datatable " style={{ overflow: 'auto', height: '600px' }}>
                <table id="table-to-xls" className="table  table-bordered  mt-2">
                    <thead className="text-center bg-azure-lighter" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                        <tr>
                            <th className='fs-10' colSpan={3} > </th>
                            {enseignes?.map((items, keys) => (
                                <>
                                    <th className='fs-10' key={keys} colSpan={getCountDate(items?.value)}>{items.name}</th>

                                </>
                            ))}
                        </tr>
                        <tr>
                            <th className='fs-10'>Marque</th>
                            <th className='fs-10'>Code à barre</th>
                            <th className='fs-10'>Désignation</th>
                            {/** ici map le groupement des magasins */}
                            {enseignes?.map((i, k) => (
                                pdvs[i.value]?.map((items) =>
                                    <>
                                        <th className='fs-10' key={k} colSpan={Object.keys(grouped[items.value])?.length}>{items.name}</th>
                                    </>
                                )
                            ))}
                        </tr>
                        <tr>
                            <td colSpan={3}></td>
                            {enseignes?.map((i)=>(
                                pdvs[i.value]?.map((items ,keys)=>(
                                    <>
                                     {Object.keys(grouped[items.value])?.map((x, k) => (
                                            <th className='fs-10' key={k}>{moment(x).format("DD/MM/YYYY")}</th>
                                        ))}
                                    </>
                                ))
                            ))}
                         
                        </tr>
                    </thead>
                    <tbody className='bg-white'>
                        {listeMarques?.map((items, keys) => (
                            <>
                                <tr key={keys} className={
                                    keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                }>
                                    <td style={{ verticalAlign: "middle" }} className="fs-10" rowSpan={marques[items.value]?.length + 1}> {marques[items.value] !== undefined ? marques[items.value][0]?.marques : null} </td>
                                </tr>
                                {marques[items.value]?.map((i, k) => (
                                    <tr className={
                                        keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                    }>
                                        <td className='fs-10'>{i?.produit_code}</td>
                                        <td className='fs-10'>{i?.nom_produit}</td>
                                        {enseignes?.map((x, k) => (
                                            pdvs[x.value]?.map((items) =>
                                                <>
                                                    {Object.keys(grouped[items.value])?.map((item, key) => (
                                                        <>
                                                            {findListe(item, items.value, i?.produit_code, key)}
                                                        </>
                                                    ))}
                                                </>
                                            )
                                        ))}

                                    </tr>
                                ))}

                            </>

                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
