export   const sumFamilleSDK = (l, it ,t,array_pdv) => {
    var te = []
    var te2 = []

    if (array_pdv?.length === 1) {
        te.push(0)
        te2.push(0)
        var lg = l.length
        var sum = 0
        for (var i = 0; i < lg; i++) {
            if (l[i]?.te[0] === "0") {
                sum = sum + 1
            }
        }
        te.splice(0, 0, sum)
        var t1 = te.reduce((a, b) => a + b, 0)
        te.splice(1, 1, t1)
        te.splice(2, 2, "-")
        te2.splice(1, 1, '-')
        te2.splice(2,2, '-')

    } else {
        for (var o = 0; o < array_pdv?.length; o++) {
            te.push(0)
            te2.push(0)
        }
        //console.log(te2);
        var lg = l.length // la longeur de l'objet qui contient la liste des produits de la famille 
        /**
         * Array Pdv =>Liste des points de ventes
         */

        array_pdv.map((items, k) => {
            var sum = 0
            for (var i = 0; i < lg; i++) {
                if (l[i]?.te[k] === "0") {
                    sum = sum + 1
                }
                te.splice(k, k, sum)
                te2.splice(k,k,parseFloat((sum / lg) * 100).toFixed(2) + " %")
                var total = 0
                for (var j = 0; j < array_pdv.length; j++) {
                    total = parseFloat(te[j]) + total
                }
                // console.log("T "+total)
                te.splice(array_pdv.length, array_pdv.length, total)
                te2.splice(array_pdv.length, array_pdv.length, '-')
                //console.log(k,k,parseFloat((sum / lg) * 100).toFixed(2))
                /*  te2.splice(k, k, parseFloat((sum / lg) * 100).toFixed(2) + " %") */
            }

        })
    }


    return (
        <>
            <tr className='bg-azure-lighter fs-12'>
                <td> {t('dn.totalFamille')} : <span style={{ fontWeight: 'bold' }} > {it.famille}</span></td>
                {te?.map((items, keys) => (
                    <td> <b> {items} </b></td>
                ))}
                {array_pdv.length === 1 ? null : <td>-</td>}

            </tr>
            <tr className='bg-azure-lighter fs-12'>
                <td> {t('dn.presenceFamille')} : <span style={{ fontWeight: 'bold' }} > {it.famille}</span></td>
                {te2?.map((items, keys) => (
                    <td> <b> {items} </b></td>
                ))}
                {array_pdv.length === 1 ? null : <td>-</td>}
                {/*  <td colSpan={2}></td> */}
            </tr>
        </>
    )
}