/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { addParmPointages, getListeParmPointage } from '../../../services/Rapports/RapportsApi'
import { getMerch } from '../../../services/Users/UsersApi'
import ModalDeleteParamPointage from './ModalDeleteParamPointage'
import ModalEditParamPointage from './ModalEditParamPointage'

export default function ParamétragePointage() {
    //------ History -----------------
    const history = useHistory()
    //--------- LISTE PARAM POINTAGES -------------
    const [liste, setliste] = useState([])
    const [listeUsers, setlisteUsers] = useState([])
    //---------- CONFIGURATION POINTAGE-----------
    const [actif, setactif] = useState("")
    const [codeUser, setcodeUser] = useState("")
    const [datedebut, setdatedebut] = useState("")
    const [datefin, setdatefin] = useState("")
    const [heuredebut, setheuredebut] = useState("")
    const [heurefin, setheurefin] = useState("")
    const [dureeRetard, setdureeRetard] = useState("")
    const [pauseDej, setpauseDej] = useState("")
    const [nbrHeure, setnbrHeure] = useState("")
    const [showEdit, setShowEdit] = useState(false)
    const [code, setCode] = useState("")
    //Modal DELete
    const [OK1, setOK1] = useState(false)
    const [deleteCode, setdeleteCode] = useState("")
    const [indexDelete, setindexDelete] = useState(-1)

    //--------- LOADER -----------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    const traitement = () => {
        getListeParmPointage()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }
    useEffect(() => {
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteUsers(result?.data?.data)
                   
                }
            }).catch((err) => {
                console.log(err)
            }).finally(()=>{
                listeUsers.push({
                    value:"all", name:"Tout les utilisateurs"
                })
            })
        traitement()
    }, [])

    const saveData = () => {
        if (actif === "") {
            toast(t("Vous devez selectionner le champs actif "), { type: "warning", position: "top-right" })
        } else
            if (datedebut > datefin) {
                toast(t('Routings.dateinf'), { type: "warning", position: "top-right" })
            } else {
                const data = {
                    user_code: codeUser,
                    actif: actif,
                    date_debut: datedebut,
                    date_fin: datefin,
                    heure_debut: heuredebut,
                    durai_retard: dureeRetard,
                    heure_fin: heurefin,
                    pause: pauseDej,
                    nbr_heure: nbrHeure
                }
                /**
                 * Add Parametrage Pointage
                 */
                addParmPointages(data)
                    .then((result) => {
                        if (result?.data?.success === 1) {
                            toast(t("messageErreur.ajoutavecsuccee"), { type: "success", position: "top-right" })
                            traitement()
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
            }
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('rapports.RapportSuiviPointage')}</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('rapports.parametragepointage')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <button className="btn bg-primary " onClick={() => history.goBack()}>
                            <i className="fe fe-arrow-left" />
                            {t('rapports.retour')}
                        </button>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    {/** Tableaux Parametrage  */}
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-3 float-left text-azure">  {t('rapports.listeparametrage')} </div>
                        </div>
                        <ToastContainer />
                        <div className="card-body">
                            {loading ? <SqaureLoader /> : (
                                <div className="table-responsive product-datatable">
                                    <table className="table table-striped table-bordered ">
                                        <thead className="text-center bg-azure-lighter ">
                                            <tr>
                                                <th className="w-15p fs-10"> {t('rapportrupture.merch')}</th>
                                                <th className="w-15p fs-10">{t('Routings.datedebut')}</th>
                                                <th className="w-15p fs-10">{t('Routings.datefin')}</th>
                                                <th className="w-15p fs-10">{t('rapports.H.DEBUT')}</th>
                                                <th className="w-15p fs-10">{t('rapports.H.FIN')}</th>
                                                <th className="w-15p fs-10">{t('rapports.dureepause')}</th>
                                                <th className="w-15p fs-10">{t('rapports.nbrheure')}</th>
                                                <th className="w-15p fs-10">{t('Produits.Actif')}</th>
                                                <th className="w-15p fs-10">{t('Magasins.ACTION')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-center'>
                                            {liste?.map((items, keys) => (
                                                <tr key={keys} className="fs-10">
                                                    <td> {items.nom} </td>
                                                    <td> {moment(items.date_debut).format("DD/MM/YYYY")} </td>
                                                    <td> {moment(items.date_fin).format("DD/MM/YYYY")} </td>
                                                    <td> {items.heure_debut} </td>
                                                    <td> {items.heure_fin} </td>
                                                    <td> {items.pause} </td>
                                                    <td> {items.nbr_heure} </td>
                                                    <td> {items.actif === 1 ? <i className='fa fa-stop text-success '/> : <i className='fa fa-stop text-danger'/>} </td>
                                                    <td>
                                                        <i className='icon icon-note text-warning  mr-1 text-yellow' data-toggle="modal" data-target="#exampleModal"
                                                            onClick={() => { setShowEdit(true); setCode(items.id) }} style={{ cursor: "pointer" }} />
                                                        <i className="ti-trash text-danger"  data-toggle="modal" data-target="#modal-delete-delegation" onClick={() => { setdeleteCode(items.id); setindexDelete(keys) }} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    {/** Card add produits  */}
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-3 text-center text-azure"><i className='ti-settings mr-1' /> {t('rapports.configpointage')} </div>
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label fs-12"> {t('Produits.UTILISATEUR')} <span className="text-danger">*</span></label>
                                        <select value={codeUser} onChange={(e) => setcodeUser(e.target.value)} className="form-control fs-12 custom-select br-md-0">
                                            <option value=""> {t("dashboard.Merchandiseur")}  </option>
                                            {listeUsers?.map((items, keys) => (
                                                <option value={items.value} key={keys}>{items.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label fs-12"> {t('Magasins.Actif')} <span className="text-danger">*</span></label>
                                        <select value={actif} onChange={(e) => setactif(e.target.value)} className="form-control fs-12  custom-select br-md-0">
                                            <option value="">   {t('Magasins.Actif')}  </option>
                                            <option value={"1"}> {t('Magasins.Oui')}</option>
                                            <option value={"0"}>{t('Magasins.Non')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label fs-12">{t('Routings.datedebut')} <span className="text-danger">*</span></label>
                                        <input className='form-control fs-12' type="date" value={datedebut} onChange={(e) => setdatedebut(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label fs-12">{t('Routings.datefin')} <span className="text-danger">*</span></label>
                                        <input className='form-control fs-12' type="date" value={datefin} onChange={(e) => setdatefin(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label fs-12">{t('rapports.H.DEBUT')} <span className="text-danger">*</span></label>
                                        <input className='form-control fs-12' type="time" value={heuredebut} onChange={(e) => setheuredebut(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label fs-12">{t('rapports.H.FIN')} <span className="text-danger">*</span></label>
                                        <input className='form-control fs-12' type="time" value={heurefin} onChange={(e) => setheurefin(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-control-label fs-12">{t('rapports.dureeretard')}<span className="text-danger">*</span></label>
                                        <input className='form-control fs-12' type="time" value={dureeRetard} onChange={(e) => setdureeRetard(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label fs-12">{t('rapports.pausedej')} <span className="text-danger">*</span></label>
                                        <input className='form-control fs-12' type="time" value={pauseDej} onChange={(e) => setpauseDej(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label fs-12">{t('rapports.nbrheure')} <span className="text-danger">*</span></label>
                                        <input className='form-control fs-12' type="time" value={nbrHeure} onChange={(e) => setnbrHeure(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <button className='btn btn-azure float-right col-12 fs-12' onClick={() => saveData()}><i className='ti-save mr-1' /> {t('rapports.enregistrer')} </button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDeleteParamPointage deleteCode={deleteCode} indexDelete={indexDelete} setOK1={setOK1} t={t} />
            {showEdit ? <ModalEditParamPointage code={code} setShowEdit={setShowEdit} listeUsers={listeUsers} t={t} /> : null}
        </div>
    )
}
