/* eslint-disable react-hooks/exhaustive-deps */
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast, ToastContainer } from 'react-toastify';
import SqaureLoader from '../../components/Loader/SqaureLoader';
import { partLineaireM } from '../../services/PartLineaire/partLineaireAPI';
import PLEnseigneWeek from './PLEnseigneWeek';
import PLGammeWeek from './PLGammeWeek';
import PLMagasinWeek from './PLMagasinWeek';
import PLMerchWeek from './PLMerchWeek';

export default function PartLineaireWeek() {
    //------ History -----------------
    const history = useHistory()
    //--------PARAMS ---------------
    const { debut, fin } = useParams('')
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //--------- Search --------------------
    const [date_d, setdate_d] = useState(debut);
    const [date_f, setdate_f] = useState(fin);
    //Enseigne Precedent
    const [allEnseignes, setallEnseignes] = useState([])
    const [listeEnseignes, setlisteEnseignes] = useState([])
    const [listeEnseignesPres, setlisteEnseignesPres] = useState([])
    //Gamme Precedent
    const [allGamme, setallGamme] = useState([])
    const [listeGamme, setlisteGamme] = useState([])
    const [listeGammePresc, setlisteGammePresc] = useState([])
    //Magasins Precedent
    const [allMagasins, setallMagasins] = useState([])
    const [listeMagasins, setlisteMagasins] = useState([])
    const [listeMagasinsPresc, setlisteMagasinsPresc] = useState([])
    //Merch Precedent
    const [allMerch, setallMerch] = useState([])
    const [listeMerch, setlisteMerch] = useState([])
    const [listeMerchPresc, setlisteMerchPresc] = useState([])
    const header = ["Semaine 1", "Semaine 2", "Semaine 3", "Semaine 4", "Semaine 5"]
    const liste = [1, 2, 3, 4, 5]
    //Loader 
    const [Loading, setLoading] = useState(true)

    useEffect(() => {
        partLineaireM(date_d, date_f).then((result) => {
            if (result?.data?.success === 1) {
                const ensg = groupBy(result?.data.plEnseigne, "nom_enseigne")
                const x = Object.keys(ensg)
                const liste = groupBy(result.data.plEnseigne, "WEEK_OF_MONTH")
                setlisteEnseignes(liste)
                setallEnseignes(x)
                const gamme = groupBy(result?.data?.plGamme, "gamme")
                const y = Object.keys(gamme)
                const liste1 = groupBy(result?.data?.plGamme, "WEEK_OF_MONTH")
                setallGamme(y)
                setlisteGamme(liste1)
                const magasin = groupBy(result?.data?.plMagasin, "nompdv")
                const z = Object.keys(magasin)
                const liste2 = groupBy(result?.data?.plMagasin, "WEEK_OF_MONTH")
                setallMagasins(z)
                setlisteMagasins(liste2)
                const merch = groupBy(result?.data?.plMerch, "nom_user")
                const a = Object.keys(merch)
                const liste3 = groupBy(result?.data?.plMerch, "WEEK_OF_MONTH")
                setallMerch(a)
                setlisteMerch(liste3)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])
    const onChangeDate = () => {
        setLoading(true)
        const date1 = new Date(date_d);
        const date2 = new Date(date_f);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
   
        if(date_d>date_f){
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })

        }else if (diffDays>31){
            toast("Vous devez choisir un mois ",{type:"warning",position:"top-right"})
        }
        else {
            history.push({
                pathname:
                    '/partlineaireWeek/datedebut=' + date_d + '&datefin=' + date_f ,
            })
            partLineaireM(date_d, date_f).then((result) => {
                if (result?.data?.success === 1) {
                    const ensg = groupBy(result?.data.plEnseigne, "nom_enseigne")
                    const x = Object.keys(ensg)
                    const liste = groupBy(result.data.plEnseigne, "WEEK_OF_MONTH")
                    setlisteEnseignes(liste)
                    setallEnseignes(x)
                    const gamme = groupBy(result?.data?.plGamme, "gamme")
                    const y = Object.keys(gamme)
                    const liste1 = groupBy(result?.data?.plGamme, "WEEK_OF_MONTH")
                    setallGamme(y)
                    setlisteGamme(liste1)
                    const magasin = groupBy(result?.data?.plMagasin, "nompdv")
                    const z = Object.keys(magasin)
                    const liste2 = groupBy(result?.data?.plMagasin, "WEEK_OF_MONTH")
                    setallMagasins(z)
                    setlisteMagasins(liste2)
                    const merch = groupBy(result?.data?.plMerch, "nom_user")
                    const a = Object.keys(merch)
                    const liste3 = groupBy(result?.data?.plMerch, "WEEK_OF_MONTH")
                    setallMerch(a)
                    setlisteMerch(liste3)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
        }
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">{ }
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.pl')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> Part linéaire mensuelle</li>
                </ol>
            </div>
            <ToastContainer />
            <div className='row mb-2'>
                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'></div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                    <div className="input-group">
                        <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control text-primary fs-12" />
                        <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control text-primary fs-12" />
                        <span className="input-group-append">
                            <button className="btn btn-primary" type="button" onClick={() => onChangeDate()}><i className='ti-search' /></button>
                        </span>
                    </div>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'></div>
            </div>
            {Loading ? <SqaureLoader /> :
            
            <div className='col-12'>
            <div className='row'>
                <PLEnseigneWeek header={header} t={t} liste={liste} allEnseignes={allEnseignes} listeEnseignes={listeEnseignes} listeEnseignesPres={listeEnseignesPres} />
            </div>
            <div className='row'>
                <PLGammeWeek header={header} t={t} liste={liste} allGamme={allGamme} listeGamme={listeGamme} listeGammePresc={listeGammePresc} />
            </div>
            <div className='row'>
                <PLMagasinWeek header={header} t={t} liste={liste} allMagasins={allMagasins} listeMagasins={listeMagasins} listeMagasinsPresc={listeMagasinsPresc} />
            </div>
            <div className='row'>
                <PLMerchWeek header={header} t={t} liste={liste} allMerch={allMerch} listeMerch={listeMerch} listeMerchPresc={listeMerchPresc} />
            </div>
        </div>}
           

        </div>
    )
}
