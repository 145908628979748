/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import _ from 'lodash';
import SqaureLoader from '../../../components/Loader/SqaureLoader';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export default function GraphEnseignes({ graphEnseigne, totalEnsGamme, ruptureEnseigne, totalEnseigne, partLineaireEnseigne, date1, date2, t }) {

    //--------------- GRAPH 1 ---------------
    const [labels, setlabels] = useState([])
    const [data, setdata] = useState([])
    //--------------- GRAPH 2 ---------------
    var [label, setlabel] = useState([])
    var [data2, setdata2] = useState([])
    var [color, setcolor] = useState([])
    //--------------- GRAPH 3 ---------------  
    const [labels3, setlabels3] = useState([])
    const [data3, setdata3] = useState([])
    //---------- LOADER --------------------
    const [loading, setloading] = useState(true)

    useEffect(() => {
        //------------ GRAPHE 1 ------------------------
        //const total_ensg = _.groupBy(totalEnsGamme, 'enseigne_code')
        const total_ensg = _.groupBy(totalEnsGamme, 'gamme_code')
        const x = []
        // GROUPED Labels par enseigne exemple : ["monoprix","UHD"]
        graphEnseigne?.map((items, k) => {
            x.push(items.libelle)
        })
        const l = _.uniq(x)
        setlabels(l)
        // Groupement par gamme 
        //const gr = _.groupBy(graphEnseigne, 'libelle')
        const gr = _.groupBy(graphEnseigne, 'enseigne')
        const arr = []
        for (let value of Object.values(gr)) {
            const te = []
            const lg = value.length
            value.map((i, k) => {
                if (lg === l.length) {

                    te.push(parseFloat((i.total / total_ensg[i.gamme_code][0]?.total) * 100).toFixed(2))
                } else {
                    l.map((y, z) => {
                        if (i.nom_enseigne === y) {
                            te.splice(z, z, parseFloat((i.total / total_ensg[i.gamme_code][0]?.total) * 100).toFixed(2));
                        } else {
                            te.splice(z, z, 0);
                        }
                    })
                }
            })
            arr.push({
                label: value[0].enseigne,
                backgroundColor: value[0].color,
                borderWidth: 1,
                data: te
            })
            setdata(arr)
        }
        //-------------GRAPHE 2 --------------------------
        var l1 = []
        var d1 = []
        var c1 = []
        ruptureEnseigne?.map((items) => {
            l1.push(items.enseigne + "(" + parseFloat((items.etat / totalEnseigne) * 100).toFixed(2) + ") %")
            d1.push(parseFloat((items.etat / totalEnseigne) * 100).toFixed(2))
            c1.push(items.color)
        })
        setlabel(l1)
        setdata2(d1)
        setcolor(c1)
        //-------------GRAPHE 3 --------------------------
        const pl = []
        // GROUPED Labels par enseigne exemple : ["monoprix","UHD"]
        partLineaireEnseigne?.map((items, k) => {
            pl.push(items.enseigne)
        })
        const pl1 = _.uniq(pl)
        setlabels3(pl1)
        const gr3 = _.groupBy(partLineaireEnseigne, 'month')
        const arr3 = []
        for (let value of Object.values(gr3)) {
            const te = []
            const lg = value.length
            value.map((i, k) => {
                if (lg === pl1.length) {
                    te.push(parseFloat(i.sumtot).toFixed(3))
                } else {
                    pl1.map((y, z) => {
                        if (i.enseigne === y) {
                            te.splice(z, z, parseFloat(i.sumtot).toFixed(3));
                        } else {
                            te.splice(z, z, 0);
                        }
                    })
                }
            })
            arr3.push({
                label: value[0].month,
                backgroundColor: value[0].color,
                borderWidth: 1,
                data: te
            })
            setdata3(arr3)

        }
        setloading(false)
    }, [])

    return (
        <>
                
            {/****--------- RUPTURE par ENSEIGNE ET GAMME ------------------ */}
            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12'>
                <div className='card'>
                    <div className='card-body'>
                        {loading ? <SqaureLoader /> :
                            <Bar
                                style={{ height: "400px" }}
                                data={{
                                    labels: labels,
                                    datasets: data
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    indexAxis: 'y',

                                    plugins: {
                                        legend: {
                                            possition: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: t('dashboard.rupturegammeE') + date1 + '-' + date2
                                        }
                                    },
                                }}
                            />
                        }

                    </div>
                </div>
            </div>
            {/****--------- RUPTURE par ENSEIGNE  ------------------ */}
            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12'>
                <div className='card'>
                    <div className='card-body'>
                        {loading ? <SqaureLoader /> :
                            <Doughnut
                                style={{ height: "400px" }}
                                data={{
                                    labels: label,
                                    datasets: [
                                        {
                                            label: label,
                                            data: data2,
                                            backgroundColor: color,
                                            borderColor: color,
                                        }]
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            possition: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: t('dashboard.ruptureEnseigneG') + date1 + '-' + date2,
                                        },
                                    },
                                }}
                            />}
                    </div>
                </div>
            </div>
            {/****--------- PART Linéaire  ------------------ */}
            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12'>
                <div className='card'>
                    <div className='card-body'>
                        {loading ? <SqaureLoader /> :
                            <Bar
                                style={{ height: "400px" }}
                                data={{
                                    labels: labels3,
                                    datasets: data3
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    indexAxis: 'x',

                                    plugins: {
                                        legend: {
                                            possition: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: t('dashboard.GraphPartLineaire')
                                        },
                                    },
                                }}
                            />
                        }
                    </div>
                </div>
            </div>
        </>

    )
}
