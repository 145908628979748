/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getRapportSuiviPointageImage } from '../../../services/Rapports/RapportsApi'

export default function ModalImagePointageDebut({ date, user_code,pdv_code, setok1,visiteCode, t }) {
    const [data, setdata] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        getRapportSuiviPointageImage(date, pdv_code,user_code,visiteCode)
            .then((result) => {
                if (result?.data?.success === 1) {
                    console.log(result?.data);
                    setdata(result?.data?.liste)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [date, pdv_code,user_code])

    return (
        <div className="modal fade" id="exampleModal" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog" aria-hidden="true" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3"> {t('rapports.pointagedebut')}:   </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }}  data-dismiss="modal" aria-label="Close" onClick={() => setok1(false)}  /> </span>

                    </div>
                    {loading ? <SqaureLoader /> : (
                        <>
                            <div className="modal-body">
                                <img src={data?.path === null || data?.path === undefined ? "../assets/images/users/17.jpg" : data?.path} alt="image_pointage" />
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-yellow" data-dismiss="modal" aria-label="Close" onClick={() => setok1(false)} >
                                    <i className=' ti-close mr-1' /> {t('dashboard.Fermer')}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
