import React from 'react'

export default function RapportVisiteTournee() {
    return (
        <div>
             <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> Rapports </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> Rapport Visite Tournées </li>
                </ol>
            </div>
        </div>
    )
}
