import React from 'react'

export default function FiltreSearch({ dateDebut, setDateDebut, dateFin, setDateFin, onSearchData, onClickBack, t, listeEnseigne, codeEnseigne, setcodeEnseigne }) {
    return (
        <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
                <div className="panel-heading " role="tab" id="headingOne">
                    <h4 className="panel-title">
                        <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <i className="icon icon-grid text-primary mr-2" />{t('relevePrix.relevePrix')}
                            <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                        </a>
                    </h4>
                </div>
                <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                        <div className="form row no-gutters">
                            <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-6 mb-0">
                                <input
                                    className="form-control fc-datepicker fs-10"
                                    type="date"
                                    value={dateDebut}
                                    onChange={(e) => setDateDebut(e.target.value)}
                                />
                            </div>
                            <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-6 mb-0">
                                <input
                                    className="form-control fc-datepicker fs-10"
                                    type="date"
                                    value={dateFin}
                                    onChange={(e) => setDateFin(e.target.value)}
                                />
                            </div>
                            <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-6 mb-0">
                                <select className="form-control  br-md-0  fs-10 text-primary" value={codeEnseigne} onChange={(e) => setcodeEnseigne(e.target.value)}>
                                    <option value="all" selected>{t('dashboard.Enseigne')}</option>
                                    {listeEnseigne?.map((items, keys) => (
                                        <option key={keys} value={items.value}>{items.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-12 col-6 mb-0">
                                <button
                                    className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16" onClick={() => onSearchData()}>
                                    <i className="fe fe-search" />
                                </button>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-12 col-6 mb-0">
                                <button
                                    className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16" onClick={() => onClickBack()}>
                                    <i className="ti-close" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
