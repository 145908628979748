import React from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import SelectSearch, { fuzzySearch } from "react-select-search";

export default function SearchAutoEvaluation({month,setmonth,liste,codeUser,listeUsers,searchInput1,handleChangeUsers,onClickUser,t,showInfo,yearValue,setyearValue
}) {
  return (
    <div className="row">
      <div className="col-3" />
      <div className="col-6">
        <div className="input-group text-center">
          <select
            className="form-control fs-10 text-center text-azure"
            value={month}
            onChange={(e) => setmonth(e.target.value)}
          >
            <option value={""}>Choisir ... </option>
            {liste?.map((items, keys) => (
              <option value={items.code} key={keys}>
                {items.libelle}
              </option>
            ))}
          </select>
          <select
            className="form-control fs-10 text-center text-azure"
            value={yearValue}
            onChange={(e) => setyearValue(e.target.value)}
          >
           <option value={""}>Choisir ... </option>
           <option value={"2020"}>2020</option>
           <option value={"2021"}>2021</option>
           <option value={"2022"}>2022</option>
           <option value={"2023"}>2023</option>

          </select>
          <SelectSearch
            value={codeUser}
            options={listeUsers}
            search
            ref={searchInput1}
            onChange={handleChangeUsers}
            filterOptions={fuzzySearch}
            emptyMessage={() => (
              <div style={{ textAlign: "center", fontSize: "0.8em" }}>
                {t("Zoning.Aucunresultat")}
              </div>
            )}
            placeholder={t("Liste Merchandiseurs")}
          />
          <button
            className="btn btn-azure fs-12 mt-0 mb-2"
            onClick={() => onClickUser()}
          >
            <i className="ti-search" />
          </button>
          {!showInfo && (
            <ReactHtmlTableToExcel
              id="test-table-xls-button"
              className="btn btn-azure btn-square fs-12 mt-0 mb-2"
              table="table-to-xls"
              filename="auto_evaluation"
              sheet="tablexls"
              buttonText={
                <span className=" text-center">
                  <i className="icon icon-share-alt mr-1" />
                  {t("rapportrupture.exporter")}
                </span>
              }
            />
          )}
        </div>
      </div>
      <div className="col-3" />
    </div>
  );
}
