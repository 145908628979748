import moment from 'moment'
import React, { useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import ModalAllPlanifiee from './ModalAllPlanifiee';
import ModalPDVplanifiee from './ModalPDVplanifiee';
import ModalPDVvisites from './ModalPDVvisites';
import ModalPL from './ModalPL';
import ModalPromotions from './ModalPromotions';
import ModalRelevePrix from './ModalRelevePrix';
import ModalRupture from './ModalRupture';

export default function Tableau({ countPDV_planifieee, loadingTable, datetime ,listeTournee, today, countPdv, countPdvMonth, countPromotions, countPL, countReleve, countRupture,inMagasins,outMagasins, t }) {

   // pdv_visite_tournee => 
const [ok1, setok1] = useState(false) //Modal Ruptures 
const [ok2, setok2] = useState(false) //Modal promotion 
const [ok3, setok3] = useState(false) //Modal releve 
const [ok4, setok4] = useState(false) //Modal pl 
const [ok5, setok5] = useState(false) //Modal pdv Plannifiee 
const [ok6, setok6] = useState(false) //Modal pdv visite Plannifiee 
const [ok7, setok7] = useState(false) //Modal pdv Plannifiee 

const [userCode, setuserCode] = useState('')
const days = new Date(today).getDay()
const code_jour = datetime[days]
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-0">
                <div className="card">
                    <div className='card-header'>
                        <div className="  text-primary fs-14 ml-2 mt-4 mb-0">
                            <i className='icon icon-briefcase'
                                width={80}
                                height={80} />
                            {t('dashboard.suiviTourneePointage')}
                            <div className='float-right '>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="btn btn-azure btn-pill fs-12 mt-0 "
                                    table="table-to-xls"
                                    filename="Rapport_Merch"
                                    sheet="tablexls"
                                    buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="card-body">
                        <span className="fs-12 text-azure mb-3"><i className='icon icon-exclamation mr-1'/> {t('dashboard.formatheure')}</span>
                        {loadingTable? <SqaureLoader /> :
                         <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '600px' }}>
                         <table id="table-to-xls" className="table  table-bordered  " >
                             <thead className="text-center font-weight-normal" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >
                                 <tr >
                                     <td colSpan="1" style={{ borderBottomColor: "#f7f9fc" }} ></td>
                                     <td colSpan="13" className='fs-12 ' style={{ borderBottomColor: "#f4faff" }}> {moment(today).format('DD-MM-YYYY')} </td>
                                     <td colSpan="5" className='fs-12  bg-azure-lighter' style={{ borderBottomColor: "#f4faff", backgroundColor: "#f7f9fc" }}> {moment().format('MM-YYYY')} </td>
                                 </tr>
                                 <tr >
                                     <td className="w-5p fs-10 text-center "> {t('dashboard.Merchandiseur')} </td>
                                     <td className="w-10p fs-10">{t('dashboard.heuredebut')}</td>
                                     <td className="w-10p fs-10">{t('dashboard.heureFin')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.hinpdv')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.houtpdv')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.pdvvisite')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.pdvvisiteplanifiee')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.pdvplanifiee')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.txrespectournee')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.txsuccees')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.partl')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.rupture')}</td>
                                     <td className="wd-10p fs-10">{t('relevePrix.relevePrix')}</td>
                                     <td className="wd-10p fs-10">{t('dashboard.Promotions')}</td>
                                     <td className="wd-10p fs-10 bg-azure-lighter" >{t('dashboard.pdvvisite')}</td>
                                     <td className="wd-10p fs-10 bg-azure-lighter " >{t('dashboard.pdvvisiteplanifiee')}</td>
                                     <td className="wd-10p fs-10 bg-azure-lighter" >{t('dashboard.pdvplanifiee')}</td>
                                     <td className="wd-10p fs-10 bg-azure-lighter" >{t('dashboard.txrespectournee')}</td>
                                     <td className="wd-10p fs-10  bg-azure-lighter" >{t('dashboard.txsuccees')}</td>
                                 </tr>
                             </thead>
                             <tbody>
                             {listeTournee?.map((items, keys) => (
                                     <tr className='text-center fs-10' key={keys}>
                                         <td className='text-left'> <a className='text-azure' href={"rapports/rapportJournalier&date="+today+"&usercode="+items.code}> <b> {items.nom} </b></a> 
                                         {items.validation === 1 ? <span className="ml-1 badge badge-success  mr-1 mb-1 mt-1">Congé</span> : (items.validation === 0 ? <span className=" badge badge-danger  mr-1 mb-1 mt-1"><a href='/users/conge' className='text-white'> Demande Congé</a> </span> : null)} 
                                          </td>
                                         <td className='fs-10'> {items.heure_debut === null ?
                                             <div className="bg-danger-transparent icon-bg icon-service text-danger" style={{ height: "20px", width: "20px" }}>
                                                 <i className="fa fa-clock-o fs-12" />
                                             </div> : <a href={'/rapports/rapportSuiviPointage&datedebut=' + today + '&datefin=' + today + "&user_code=" + items.code} ><b className='text-azure'>{items.heure_debut=== 'null' ? '-' : (items.heure_debut).slice(0, 5)}</b></a>}  </td>
                                         <td className='fs-10'>{items.heure_fin=== 'null' ? t('dashboard.encours') :items.heure_fin!==null ? (items.heure_fin).slice(0, 5) :'-' }</td>
                                         <td className="wd-10p fs-10">{items?.inMagasins==="00:00:00.000000" || items?.inMagasins===null ? '-' : (items?.inMagasins).slice(0, 5)}</td>
                                         <td className="wd-10p fs-10">{items?.OutPDV==="00:00:00" ||items?.OutPDV===null ? '-' : ((items?.OutPDV).slice(0, 5))}</td>
                                         <td  data-toggle="modal" data-target="#pdvVisité"><b style={{ cursor: 'pointer' }}  onClick={()=>{setok6(true);setuserCode(items.code)}} className={items.pdv_visite !== 0 ? "text-primary" : ""}> {items.pdv_visite !== undefined ? items.pdv_visite : 0} </b></td>
                                         <td data-toggle="modal" data-target="#pdvplanifiee"> <b style={{ cursor: 'pointer' }}  onClick={()=>{setok5(true);setuserCode(items.code)}} className={items.pdv_visite_tournee !== 0 ? "text-primary" : ""}> {items.pdv_visite_tournee !== undefined ? items.pdv_visite_tournee : 0} </b> </td>
                                         <td data-toggle="modal" data-target="#getALLpdv"> <b style={{ cursor: 'pointer' }}  onClick={()=>{setok7(true);setuserCode(items.code)}} className={items.pdv_planifiee !== 0 ? "text-primary" : ""}> {items.pdv_planifiee !== undefined ? items.pdv_planifiee : 0} </b> </td>
                                         
                                         
                                         <td data-toggle="tooltip" data-placement="top" title={t('dashboard.pdvvisiteplanifiee')+"/"+t('dashboard.pdvplanifiee')}> {items?.pdv_planifiee === null ? 0 : (parseFloat((items.pdv_visite_tournee / items?.pdv_planifiee) * 100) >= 100 ? "100 %" : Math.round(parseFloat((items.pdv_visite_tournee / items?.pdv_planifiee) * 100).toFixed(2)) + "%")} </td>
                                         <td> {items.total_cmd === 0 ? 0 : (parseFloat((items.total_cmd / items.pdv_visite) * 100) >= 100 ? "100 %" : Math.round(parseFloat((items.total_cmd / items.pdv_visite) * 100).toFixed(2)) + "%")}  </td>
                                        {/** ------------------ PL / Rupture /  Releve // Promotions ---- */}
                                         <td className="wd-10p fs-10"  data-toggle="modal" data-target="#exampleModal2"><b style={{ cursor: 'pointer' }} onClick={()=>{setok4(true);setuserCode(items.code)}} className={items?.total_pl > 0 ? 'text-primary' : ''}> {items?.total_pl} </b></td>
                                         <td className="wd-10p fs-10" data-toggle="modal" data-target="#exampleModal3"><b style={{ cursor: 'pointer' }} onClick={()=>{setok1(true);setuserCode(items.code)}} className={items?.total_rupture > 0 ? 'text-primary' : ''}> {items?.total_rupture}</b></td>
                                         <td className="wd-10p fs-10" data-toggle="modal" data-target="#exampleModal4"><b style={{ cursor: 'pointer' }} onClick={()=>{setok3(true);setuserCode(items.code)}} className={items?.total_relevePrix > 0 ? 'text-primary' : ''}>{items?.total_relevePrix}</b></td>
                                         <td className="wd-10p fs-10"  data-toggle="modal" data-target="#exampleModal5"><b style={{ cursor: 'pointer' }}onClick={()=>{setok2(true);setuserCode(items.code)}} className={items?.total_promotions > 0 ? 'text-primary' : ''}>{items?.total_promotions}</b></td>
                                 
                                         {/**------ MONTH PART ------------------- */}
                                         <td className=' fs-10 bg-azure-lighter' ><strong> {countPdvMonth[items.code] !== undefined ? countPdvMonth[items.code][0]?.pdv_visite_month : 0}</strong></td>
                                         <td className=' fs-10 bg-azure-lighter' > <strong>{countPdvMonth[items.code] !== undefined ? countPdvMonth[items.code][0]?.pdv_visite_planifiee_month : 0}</strong></td>
                                         <td className=' fs-10 bg-azure-lighter' ><strong>{countPDV_planifieee[items.code] === undefined ? 0 : countPDV_planifieee[items.code][0]?.nbr_pdv_month}</strong></td>
                                          <td className=' fs-10 bg-azure-lighter' ><strong>{(countPdvMonth[items.code]=== undefined || countPDV_planifieee[items.code]=== undefined)? 0 : (parseFloat((countPdvMonth[items.code][0]?.pdv_visite_planifiee_month / countPDV_planifieee[items.code][0]?.nbr_pdv_month ) * 100).toFixed(2) >= 100 ? "100" : Math.round(parseFloat((((countPdvMonth[items.code][0]?.pdv_visite_planifiee_month !== undefined  && (countPDV_planifieee[items.code][0]?.nbr_pdv_month !== undefined) )? countPdvMonth[items.code][0]?.pdv_visite_planifiee_month : 0) / countPDV_planifieee[items.code][0]?.nbr_pdv_month ) * 100).toFixed(2)))} %</strong> </td>
                                            <td className=' fs-10 bg-azure-lighter' ><strong>{(countPdvMonth[items.code]=== undefined || countPdvMonth[items.code][0]?.total_cmd_month === 0 || countPdvMonth[items.code][0]?.pdv_visite_month === null )? 0 : Math.round(parseFloat((countPdvMonth[items.code][0]?.total_cmd_month / countPdvMonth[items.code][0]?.pdv_visite_month) * 100).toFixed(2))}  %</strong> </td>
                                     </tr>
                                 ))}
                                {/*  {listeTournee?.map((items, keys) => (
                                     <tr className='text-center fs-10' key={keys}>
                                         <td className='text-left'> <a className='text-azure' href={"rapports/rapportJournalier&date="+today+"&usercode="+items.code}> <b> {items.nom} </b></a>  </td>
                                         <td className='fs-10'> {items.heure_debut === null ?
                                             <div className="bg-danger-transparent icon-bg icon-service text-danger" style={{ height: "20px", width: "20px" }}>
                                                 <i className="fa fa-clock-o fs-12" />
                                             </div> : <a href={'/rapports/rapportSuiviPointage&datedebut=' + today + '&datefin=' + today + "&user_code=" + items.code} ><b className='text-azure'>{items.heure_debut=== 'null' ? '-' : (items.heure_debut).slice(0, 5)}</b></a>}  </td>
                                         <td className='fs-10'>{items.heure_fin=== 'null' ? t('dashboard.encours') :items.heure_fin!==null ? (items.heure_fin).slice(0, 5) :'-' }</td>
                                         <td className="wd-10p fs-10">{inMagasins[items.code]=== undefined  || inMagasins[items.code][0]?.inMagasins==="00:00:00.000000" || inMagasins[items.code][0]?.inMagasins===null ? '-' : (inMagasins[items.code][0]?.inMagasins).slice(0, 5)}</td>
                                         <td className="wd-10p fs-10">{outMagasins[items.code]=== undefined || outMagasins[items.code][0]?.OutPDV==="00:00:00" || outMagasins[items.code][0]?.OutPDV===null ? '-' : ((outMagasins[items.code][0]?.OutPDV).slice(0, 5))}</td>
                                         <td  data-toggle="modal" data-target="#pdvVisité"><b style={{ cursor: 'pointer' }}  onClick={()=>{setok6(true);setuserCode(items.code)}} className={items.pdv_visite !== 0 ? "text-primary" : ""}> {items.pdv_visite !== undefined ? items.pdv_visite : 0} </b></td>
                                         <td data-toggle="modal" data-target="#pdvplanifiee"> <b style={{ cursor: 'pointer' }}  onClick={()=>{setok5(true);setuserCode(items.code)}} className={items.pdv_visite_tournee !== 0 ? "text-primary" : ""}> {items.pdv_visite_tournee !== undefined ? items.pdv_visite_tournee : 0} </b> </td>
                                         <td> {countPdv[items.code] === undefined ? 0 : countPdv[items.code][0]?.pdv_planifiee} </td>
                                         <td data-toggle="tooltip" data-placement="top" title={t('dashboard.pdvvisiteplanifiee')+"/"+t('dashboard.pdvplanifiee')}> {countPdv[items.code] === undefined ? 0 : (parseFloat((items.pdv_visite_tournee / countPdv[items.code][0]?.pdv_planifiee) * 100) >= 100 ? "100 %" : Math.round(parseFloat((items.pdv_visite_tournee / countPdv[items.code][0]?.pdv_planifiee) * 100).toFixed(2)) + "%")} </td>
                                         <td> {items.total_cmd === 0 ? 0 : (parseFloat((items.total_cmd / items.pdv_visite) * 100) >= 100 ? "100 %" : Math.round(parseFloat((items.total_cmd / items.pdv_visite) * 100).toFixed(2)) + "%")}  </td>
                                        {/** ------------------ PL / Rupture /  Releve // Promotions ---- */}
                                      {/*    <td className="wd-10p fs-10"  data-toggle="modal" data-target="#exampleModal2"><b style={{ cursor: 'pointer' }} onClick={()=>{setok4(true);setuserCode(items.code)}} className={countPL[items.code][0]?.total_pl > 0 ? 'text-primary' : ''}> {countPL[items.code][0]?.total_pl} </b></td>
                                         <td className="wd-10p fs-10" data-toggle="modal" data-target="#exampleModal3"><b style={{ cursor: 'pointer' }} onClick={()=>{setok1(true);setuserCode(items.code)}} className={countRupture[items.code][0]?.total_rupture > 0 ? 'text-primary' : ''}> {countRupture[items.code][0]?.total_rupture}</b></td>
                                         <td className="wd-10p fs-10"   data-toggle="modal"  data-target="#exampleModal4"><b style={{ cursor: 'pointer' }} onClick={()=>{setok3(true);setuserCode(items.code)}} className={countReleve[items.code][0]?.total_relevePrix > 0 ? 'text-primary' : ''}>{countReleve[items.code][0]?.total_relevePrix}</b></td>
                                         <td className="wd-10p fs-10"  data-toggle="modal" data-target="#exampleModal5"><b style={{ cursor: 'pointer' }}onClick={()=>{setok2(true);setuserCode(items.code)}} className={countPromotions[items.code][0]?.total_promotions > 0 ? 'text-primary' : ''}>{countPromotions[items.code][0]?.total_promotions}</b></td>
                                  */}
                                         {/**------ MONTH PART ------------------- */}
                                        {/*  <td className=' fs-10 bg-azure-lighter' ><strong> {items.pdv_visite_month !== undefined ? items.pdv_visite_month : 0}</strong></td>
                                         <td className=' fs-10 bg-azure-lighter' > <strong>{items.pdv_visite_planifiee_month !== undefined ? items.pdv_visite_planifiee_month : 0}</strong></td>
                                         <td className=' fs-10 bg-azure-lighter' ><strong>{countPdvMonth[items.code] === undefined ? 0 : countPdvMonth[items.code][0]?.nbr_pdv_month}</strong></td>
                                         <td className=' fs-10 bg-azure-lighter' ><strong>{countPdvMonth[items.code] === undefined ? 0 : (parseFloat((items.pdv_visite_planifiee_month / countPdvMonth[items.code][0]?.nbr_pdv_month) * 100).toFixed(2) >= 100 ? "100" : Math.round(parseFloat(((items.pdv_visite_planifiee_month !== undefined ? items.pdv_visite_planifiee_month : 0) / countPdvMonth[items.code][0]?.nbr_pdv_month) * 100).toFixed(2)))} %</strong> </td>
                                         <td className=' fs-10 bg-azure-lighter' ><strong>{items.total_cmd_month === 0 || items.pdv_visite_month === undefined ? 0 : Math.round(parseFloat((items.total_cmd_month / items.pdv_visite_month) * 100).toFixed(2))}  %</strong> </td>
                                     </tr>
                                 ))} */} 
                             </tbody>
                         </table>
                     </div>
                        
                        }
                       

                    </div>
                </div>
            </div>
            {/**
             * MODAL RUPTURES ====> Liste des ruptures total
             */}
            {ok1? <ModalRupture t={t} setok1={setok1} user_code={userCode}   today={today} />:null}
                {/**
             * MODAL Promotions ====> Liste des promotions total
             */}
            {ok2? <ModalPromotions t={t} setok2={setok2} user_code={userCode}   today={today} />:null}
               {/**
             * MODAL Releve Prix ====> Liste des releve Prix total
             */}
            {ok3? <ModalRelevePrix t={t} setok3={setok3} user_code={userCode}   today={today} />:null}
                      {/**
             * MODAL Releve Prix ====> Liste des releve Prix total
             */}
            {ok4? <ModalPL t={t} setok4={setok4} user_code={userCode}   today={today} />:null}
            {/**
             * MODAL PDV PLANIFIEE ====> pdv planifiee
             */}
             {ok5? <ModalPDVplanifiee t={t} setok5={setok5} user_code={userCode}   today={today} />:null}
              {/**
             * MODAL PDV PLANIFIEE ====> pdv planifiee
             */}
             {ok6? <ModalPDVvisites t={t} setok6={setok6} user_code={userCode}   today={today} />:null}
              {/**
             * MODAL PDV PLANIFIEE ====> pdv planifiee
             */}
             {ok7? <ModalAllPlanifiee t={t} setok7={setok7} user_code={userCode}   today={today} code_jour={code_jour} />:null}

        </div>
    )
}
