import moment from 'moment'
import React from 'react'

export default function ModalReporting({days , setok3 , t , respect_rupture , rupture}) {
  return (
    <div
      className="modal fade"
      id="rupture"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="rupture">
              Note Reporting(Ruptures) : du {moment(days).format("DD/MM/YYYY")}
            </h5>
            <span>
              <i
                className="icon icon-close text-azure"
                style={{ cursor: "pointer" }}
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setok3(false)}
              />{" "}
            </span>
          </div>

          <div className="modal-body">
            <table className="table  ">
              <thead>
                <tr>
                  <th>Critéres </th>
                  <th className="text-center">Disponible</th>
                  <th className="text-center">Rupture</th>
                  <th className="text-center">Note</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Rupture </td>
                  <td className="text-center">
                    {respect_rupture[days]!== undefined? respect_rupture[days][0]?.total_rupture : "-"}
                  </td>
                  <td className="text-center">
                    {respect_rupture[days]!== undefined? respect_rupture[days][0]?.total_disponible :"-"}
                  </td>
                  <td className="text-center">
                    {respect_rupture[days]!== undefined? respect_rupture[days][0]?.respect_etat_rupture :"-"}
                  </td>
                </tr>
            
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>
                    Note Total : <b>{rupture(days, respect_rupture)}/1</b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setok3(false)}
            >
              <i className=" ti-close mr-1" /> {t("dashboard.Fermer")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
