/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { getFiltreKPI } from "../../../services/kpi/serviceKPI";
import _ from "lodash";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

export default function FiltreKpiSales({ t, setlisteproduits, setdatedebut, setdatefin, datedebut, datefin, setlisteQualite, setlisteVisibilite, setlistepointage, settotalEquipement, setgroupedProduits, setgammes, setdates, setgroupedQualite, setheaderAssortiments, saveListeRuptures, setsaveListeRuptures, ListeRuptures, setListeRuptures, setheaderQualite, listeRegion, setlisteRegion, listeMerch, setlisteMerch, listeMG, setlisteMG, settotalVisibilite, setgroupedVisibilite, setHeaderVisibilité, setRelevePrix, saveReleveprix, setsaveReleveprix, setproduit_releve, saveListeMG, setsaveListeMG, setheaderGammePL, setpartLineaire, setlisteSocietes, listeSocietes, setlisteMG_GROUPED,listeMG_GROUPED,setsavelisteMG_GROUPED, savelisteMG_GROUPED,setloading}) {
  const history = useHistory();
  //------------------ VAR FILTRE --------------------------------
  const [codeMG, setcodeMG] = useState(""); // code magasin (pdv)
  const [codeMerch, setcodeMerch] = useState(""); //MERCH CODE => COMMERCIAL
  const [codeRegion, setcodeRegion] = useState(""); //REGION CODE
  const [codeSociete, setcodeSociete] = useState(""); //REGION CODE

  //---------- SEARCH REFERENCES ---------------------
  const { searchregion, searchPDV, searchMerch ,searchSociete} = useRef();

  const searchListebyDate = () => {
    history.push({
      pathname: "/rapportKPI/date_debut=" + datedebut + "&date_fin=" + datefin,
    });
    getFiltreKPI(datedebut, datefin)
      .then((result) => {
        if (result?.data?.success === 1) {
          setheaderGammePL(result?.data?.headerPL);
          setpartLineaire(result?.data?.partlineaire);
          var data = result?.data?.liste; //DATA FOR FILTRE (PDVS MERCH REGION)
          //--------------------------------- LISTE DES POINTAGES ------------------
          setlistepointage(data);
          //-------------------------- INIT LISTE RUPTURES ------------------------
          var rupture = result?.data?.ruptures;
          // FILTRE ------------------
          var pdvs = []; // ARRAY PDVS
          var regions = []; //ARRAY Regions
          var Merch = [];
          data?.map((items) => {
            pdvs.push({
              value: items.pdv_code,
              name: items.nompdv,
              user_code: items.user_code,
              region_code: items.region_code,
              merch : items.nom,
              date : moment(items.date).format("YYYY/MM/DD")
            });
            regions.push({
              value: items.region_code,
              name: items.libelle,
            });
            Merch.push({
              value: items.user_code,
              name: items.nom,
            });
          });
      
          //------------ HEADER ASSORTIMENTS FROM LISTE RUPTURES ------------------------------
          var produits = []; // INIT PRODUITS ASSORTIMENTS
          var listeGammes = []; // INIT GAMMES ASSORTIMENTS
          var dates_ruptures = []; // INIT LISTE OF DATES RUPTURES PRODUITS
          var listeSocietes = [];
          rupture?.map((items) => {
            //------------------- EXTRACT GAMMES PRODUITS -------------------------------------
            listeGammes.push({
              gamme_code: items.gamme_code,
              gammes: items.gammes,
            });
            //------------------- EXTRACT DATES RUPTURES -------------------------------------
            dates_ruptures.push({
              date: items.date,
            });
            //------------------- EXTRACT SOCIETES -------------------------------------
            listeSocietes.push({
              value: items.societe_code,
              name: items.societe,
            });
            //------------------- EXTRACT PRODUITS -------------------------------------
            produits.push({
              produit_code: items.produit_code,
              nom_produit: items.nom_produit,
              gamme_code: items.gamme_code,
            });
          });
          //---------------- init ListeRuptures with all ruptures liste
          setListeRuptures(rupture);
          setsaveListeRuptures(rupture)
          setgammes(_.uniqBy(listeGammes, "gamme_code")); // eliminer la répetitoon
          setdates(_.uniqBy(dates_ruptures, "date")); // dates
          setlisteproduits(_.uniqBy(produits, "produit_code")); // set produits
          setlisteSocietes(_.uniqBy(listeSocietes, "value")); // set and create societes liste
          //----------- initi lignes tableau
          var uniq_pdv = _.uniqBy(pdvs, "value");
          var uniq_pdv_merch = _.uniqWith(
            pdvs,
            (locationA, locationB ) =>
              locationA.value === locationB.value &&
              locationA.date === locationB.date && 
              locationA.user_code === locationB.user_code
          )
          setlisteMG_GROUPED(_.orderBy(uniq_pdv_merch,"date"))
          console.log(uniq_pdv_merch)
          
          setsavelisteMG_GROUPED(_.orderBy(pdvs,"date"))
          //console.log(_.orderBy(pdvs,"date"));
          // EQUIPEMENT / QUALITE
          var liste_equipements = result?.data?.equipements;
          var equipement = [];
          var visibilite = [];
          liste_equipements?.map((items) => {
            if (items.type === "Qualité") {
              equipement.push(items);
            } else {
              visibilite.push(items);
            }
          });

          //---------- EQUIPEMENT QUALITE ---------------
          setheaderQualite(_.uniqBy(equipement, "equipement"));

          var liste_equip = _.orderBy(
            _.uniqWith(
              equipement,
              (locationA, locationB) =>
                locationA.equipement === locationB.equipement &&
                locationA.libelle === locationB.libelle
            ),
            ["libelle"],
            "desc"
          );
          var finalListe = _.groupBy(liste_equip, "equipement");
          setgroupedQualite(finalListe);

          settotalEquipement(
            _.uniqWith(
              equipement,
              (locationA, locationB) =>
                locationA.equipement === locationB.equipement &&
                locationA.libelle === locationB.libelle
            ).length
          );
          setlisteQualite(equipement);
          //---------- EQUIPEMENT VISIBILITE -----------
          setHeaderVisibilité(_.uniqBy(visibilite, "equipement"));
          /*  setgroupedVisibilite(
            _.groupBy(
              _.uniqWith(
                visibilite,
                (locationA, locationB) =>
                  locationA.equipement === locationB.equipement &&
                  locationA.libelle === locationB.libelle
              ),
              "equipement"
            )
          ); */
          var liste_visibilite = _.orderBy(
            _.uniqWith(
              visibilite,
              (locationA, locationB) =>
                locationA.equipement === locationB.equipement &&
                locationA.libelle === locationB.libelle
            ),
            ["libelle"],
            "desc"
          );
          var finalListevisibulite = _.groupBy(liste_visibilite, "equipement");
          setgroupedVisibilite(finalListevisibulite);

          settotalVisibilite(
            _.uniqWith(
              visibilite,
              (locationA, locationB) =>
                locationA.equipement === locationB.equipement &&
                locationA.libelle === locationB.libelle
            ).length
          );
          setlisteVisibilite(visibilite);
          // INIT ARRAY PART LINEAIRE ---------------------------------
          var releveprix = result?.data?.releveprix;
          setRelevePrix(releveprix);
          setsaveReleveprix(releveprix)
          var produits_releve = [];
          releveprix?.map((items) => {
            produits_releve.push({
              produit_code: items.produit_code,
              nom_produit: items.libelle,
              prix_max: items.prix_max,
              prix_min: items.prix_min,
              prix: items.prix,
            });
          });
          setproduit_releve(_.uniqBy(produits_releve, "produit_code"));
          // INITI ARRAY  MG (PDVS) BY CODE
          setlisteMG(uniq_pdv);
          setsaveListeMG(uniq_pdv);
          // INITI ARRAY MERCH BY CODE
          setlisteMerch(_.uniqBy(Merch, "value"));
          // INITI ARRAY REGION BY CODE
          setlisteRegion(_.uniqBy(regions, "value"));
          //--------- INIT HEADER ASSORTIMENTS
          var uniqProduit = _.uniqBy(produits, "produit_code");
          setheaderAssortiments(uniqProduit);
          var groupedProduit = _.groupBy(uniqProduit, "gamme_code");
          setgroupedProduits(groupedProduit);
          setloading(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    searchListebyDate();
  }, []);

  const handleChangePDV = (...args) => {
    setcodeMG(args[1].value);
    //const L = saveListeMG?.filter((el) => el.value === args[1].value);
    const L1= listeMG_GROUPED?.filter((el)=>el.value === args[1].value)
    setlisteMG_GROUPED(L1)
 };
  const handleChangeMerch = (...args) => {
    const L = listeMG?.filter((el) => el.user_code === args[1].value);
    const L1= listeMG_GROUPED?.filter((el)=>el.user_code ===  args[1].value)
    setlisteMG(L);
    setlisteMG_GROUPED(L1)

    setcodeMerch(args[1].value);
  };
  const handleChangeRegion = (...args) => {
    const L = listeMG_GROUPED?.filter((el) => el.region_code === args[1].value);
    setlisteMG(L);
    setcodeRegion(args[1].value);
  };
  const handleChangeSociete = (...args) => {
    const L1 = saveListeRuptures?.filter((el) => el.societe_code === args[1].value);
    const L2 = saveReleveprix?.filter((el) => el.societe_code === args[1].value);
    setListeRuptures(L1);
    setRelevePrix(L2)
    setcodeSociete(args[1].value);
  };
  const back = () => {
    setlisteMG(saveListeMG);
    setcodeMG("");
    setcodeMerch("");
    setcodeRegion("");
    setcodeSociete("")
  };

  const onChangeDateFormat = () => {};
  return (
    <div>
      <div className="row mb-2">
        <div className="col-3" />
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="input-group">
            <input
              type="date"
              className="form-control text-primary"
              placeholder="Search for..."
              value={datedebut}
              onChange={(e) => setdatedebut(e.target.value)}
            />
            <input
              type="date"
              className="form-control text-primary"
              placeholder="Search for..."
              value={datefin}
              onChange={(e) => setdatefin(e.target.value)}
            />
            <span className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={searchListebyDate}
              >
                <i className="ti-search" />
              </button>
            </span>
          </div>
        </div>
        <div className="col-3" />
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div
            className="tags mb-2  text-center"
            style={{ alignContent: "center" }}
          />
        </div>
        <div className="col-1" />
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
          <div className="input-group">
            <SelectSearch
              options={saveListeMG}
              search
              value={codeMG}
              ref={searchPDV}
              onChange={handleChangePDV}
              filterOptions={fuzzySearch}
              emptyMessage={() => (
                <div style={{ textAlign: "center", fontSize: "0.8em" }}>
                  {t("Zoning.Aucunresultat")}
                </div>
              )}
              placeholder="Magasin ... "
            />
            <SelectSearch
              options={listeMerch}
              search
              value={codeMerch}
              ref={searchMerch}
              onChange={handleChangeMerch}
              filterOptions={fuzzySearch}
              emptyMessage={() => (
                <div style={{ textAlign: "center", fontSize: "0.8em" }}>
                  {t("Zoning.Aucunresultat")}
                </div>
              )}
              placeholder="Merchandiseur ... "
            />
            <SelectSearch
              options={listeRegion}
              search
              value={codeRegion}
              ref={searchregion}
              onChange={handleChangeRegion}
              filterOptions={fuzzySearch}
              emptyMessage={() => (
                <div style={{ textAlign: "center", fontSize: "0.8em" }}>
                  {t("Zoning.Aucunresultat")}
                </div>
              )}
              placeholder="Région ... "
            />
             <SelectSearch
              options={listeSocietes}
              search
              value={codeSociete}
              ref={searchSociete}
              onChange={handleChangeSociete}
              filterOptions={fuzzySearch}
              emptyMessage={() => (
                <div style={{ textAlign: "center", fontSize: "0.8em" }}>
                  {t("Zoning.Aucunresultat")}
                </div>
              )}
              placeholder="Societe ... "
            />
            <span className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => onChangeDateFormat()}
              >
                <i className="ti-search" />
              </button>
              <button
                className="btn bg-yellow"
                type="button"
                onClick={() => back()}
              >
                <i className="ti-close"></i>
              </button>
            </span>
          </div>
          <div className="col-2" />
        </div>
        <div className="col-1" />

      </div>
    </div>
  );
}
