/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { ToastContainer } from 'react-toastify'
import { getReclamations } from '../../services/Notes/notesApi';
import CardReclamation from './CardReclamation';
import 'moment/locale/fr'  // without this line it didn't work
import { SearchItems } from '../../utils/SearchUtils';
import { useTranslation } from 'react-i18next';

export default function Reclamations() {
    //USE REFERENCES 
    const inputEl = useRef("")
    const history = useHistory();
    //PARAMETRE
    const { debut, fin } = useParams()
    //Search 
    const [search, setSearch] = useState('')
    //------ DATE SEARCH (debut / fin) -----------
    const [dateDebut, setDateDebut] = useState(debut);
    const [dateFin, setDateFin] = useState(fin);
    //----------- LISTE -----------------------
    const [liste, setliste] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(36);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? liste?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)
  //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getReclamations(dateDebut, dateFin)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.liste)
                    setSaveList(result?.data?.liste)
                }
            }).catch((err) => {
                console.log(err)
            });
    }, [])

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(liste, search)
            setSearchlist(newList)
        } else {
            setliste(saveList)
        }
    }
    const onSerachData = () => {
        history.push({
            pathname: "/reclamation/datedebut=" + dateDebut + "&datefin=" + dateFin
        })
        getReclamations(dateDebut, dateFin)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.liste)
                    setSaveList(result?.data?.liste)
                }
            }).catch((err) => {
                console.log(err)
            });
    }

    return (
        <div>
            {/** ----------------------------- Header Page Stock --------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.reclammation')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('Reclamation.Liste')} </li>
                </ol>
            </div>
            <ToastContainer />
            {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" /> {t('Reclamation.Liste')}
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className="form row no-gutters">
                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-4 mb-0">
                                    <input
                                        className="form-control"
                                        type="texte"
                                        placeholder={t('Reclamation.Rechercher')}
                                        ref={inputEl} value={search}
                                        onChange={() => getSearchTerm()}
                                    />
                                </div>
                                <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-4 mb-0">
                                    <input
                                        className="form-control fc-datepicker fs-10"
                                        type="date"
                                        value={dateDebut}
                                        onChange={(e) => setDateDebut(e.target.value)}
                                    />
                                </div>
                                <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-4 mb-0">
                                    <input
                                        className="form-control fc-datepicker fs-10"
                                        type="date"
                                        value={dateFin}
                                        onChange={(e) => setDateFin(e.target.value)}
                                    />
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button
                                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16" onClick={() => onSerachData()}>
                                        <i className="fe fe-search" />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/** ------------------BODY OF PAGE ----------------------------- */}
            {liste?.length === 0 ?
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                        <div className="alert bg-purple mb-5 py-4" role="alert">
                            <div className="d-flex">
                                <i className="icon icon-info mr-3 fs-30"></i>
                                <div>
                                    <h4 className="alert-heading"> {t('Reclamation.Aucune')} </h4>
                                    <p>  {t('Reclamation.aucunereclammation')} {moment(dateDebut).format("DD/MM/YYYY") + " - " + moment(dateFin).format("DD/MM/YYYY")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : (<>
                    <div className="row">
                        {currentListe?.map((items, keys) =>
                            <CardReclamation items={items} key={keys} t={t}/>
                        )}
                    </div>
                </>)
            }
        </div>
    )
}
