import moment from 'moment'
import React, { useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { detailsRapportContact } from '../../../services/Rapports/RapportsApi'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function ModalToutExporter({ t, setok }) {
    //---------- FORMULAIRE --------------
    const [date_d, setdate_d] = useState("")
    const [date_f, setdate_f] = useState("")
    //--------- liste get details of contacts 
    const [liste, setliste] = useState([])
    //------------ Loader -------------
    const [loading, setloading] = useState(true)

    const saveData = () => {
        detailsRapportContact(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.liste)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }
    return (
        <div id="largeModal" className="modal fade">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content " style={{ width: "800px" }}>
                    <div className="modal-header pd-x-20">
                        <h6 className="modal-title">{t('exportRapportContact')} </h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body pd-20">
                        <div className='row'>
                            <div className="input-group">
                                <input type="date" className='form-control fs-12' value={date_d} onChange={(e) => setdate_d(e.target.value)} />
                                <input type="date" className='form-control fs-12' value={date_f} onChange={(e) => setdate_f(e.target.value)} />
                                <button className="btn btn-primary" type="button" onClick={() => saveData()}><i className='ti ti-search' /></button>

                            </div>
                            <div className='col-12 '>
                                {liste?.length === 0 ? <div className="row">
                                    <div className='col-12 mt-3 mb-3'>
                                        <h6 className='text-center text-azure'> {t('selctionnerExporter')} </h6>
                                    </div>

                                </div> :

                                    <div className="table-responsive  product-datatable  mt-3" style={{ overflow: 'auto', height: '500px' }}>

                                        <table className="table  table-bordered  " id="table-to-xls2">
                                            <thead className="text-center  fs-10" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>

                                                <tr>
                                                    <th className="w-15p fs-10">{t('rapports.code')}</th>
                                                    <th className="w-15p fs-10">{t('contacts.codeContacts')}</th>
                                                    <th className="w-15p fs-10">{t('contacts.produit')} </th>
                                                    <th className="w-15p fs-10">{t('Magasins.Type')}</th>
                                                    <th className="w-15p fs-10">{t('dashboard.Date')}</th>
                                                    <th className="w-15p fs-10">{t('Magasins.Magasins')}</th>
                                                    <th className="w-15p fs-10">{t('Routings.Zone')}</th>
                                                    <th className="w-15p fs-10">{t('Magasins.MERCH')}</th>
                                                    <th className="w-15p fs-10">{t('rapports.QUANTITE')} </th>

                                                </tr>
                                            </thead>
                                            {
                                                loading ? <SqaureLoader /> : <tbody className='text-center fs-10'>
                                                    {liste?.map((items, keys) => (
                                                        <tr key={keys}>
                                                            <td>{items.code} </td>
                                                            <td>{items.codeContact} </td>
                                                            <td>{items.libelle} </td>
                                                            <td>{items.type_visite} </td>
                                                            <td>{moment(items.date).format("DD/MM/YYYY")} </td>
                                                            <td>{items.nompdv} </td>
                                                            <td>{items.zone_code} </td>
                                                            <td>{items.nom} </td>
                                                            <td>{items.quantite } </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            }

                                        </table>
                                    </div>}


                            </div>
                        </div>


                    </div>{/* modal-body */}
                    <div className="modal-footer">
                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-success text-center"
                                        table="table-to-xls2"
                                        filename="Rapport_Gestion_contact"
                                        sheet="tablexls"
                                        buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                                    />
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal"  ><i className='icon icon-close' /> {t('Zoning.Fermer')}</button>
                    </div>
                </div>
            </div>{/* modal-dialog */}
        </div>
    )
}
