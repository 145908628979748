import React from 'react'

export default function TableauStock({headerGammes , headerEnseignes,groupedByGammes,findElementTotal , calculAverage  }) {
  return (
    <table id="table-to-xls" className="table  table-bordered  mt-2">
    <thead className="bg-azure-lighter text-center">
      <tr className="fs-10">
        <th colSpan={1}></th>
        {headerEnseignes?.map((items, keys) => (
          <th key={keys} className="fs-10" colSpan={3}>
            {items.name}
          </th>
        ))}
        <th className="fs-10" colSpan={3}>
          Average
        </th>
      </tr>
      <tr className="fs-10">
        <th className="fs-10"> Gammes</th>
        {/*   <th colSpan={2} className="fs-10">
      Produits
    </th> */}
        {headerEnseignes?.map((items, keys) => (
          <>
            <th className="fs-10">AV</th>
            <th className="fs-10">OOS</th>
            <th className="fs-10">HA</th>
          </>
        ))}
        <th className="fs-10">AV (%) </th>
        <th className="fs-10">OOS (%)</th>
        <th className="fs-10">HA (%)</th>
      </tr>
    </thead>
    <tbody className="bg-white text-center">
      {headerGammes?.map((items, keys) => (
        <>
          <tr
            key={keys}
            className={
              keys % 2 === 1 ? "fs-10 bg-azure-lightest" : "fs-10"
            }
          >
            <td
              style={{ verticalAlign: "middle" }}
              rowSpan={
                groupedByGammes[items.gamme_code]?.length + 1
              }
            >
              {items.gammes}
            </td>
            {headerEnseignes?.map((i, k) =>
              findElementTotal(i.value, items.gamme_code)
            )}
            {calculAverage(items.gamme_code)}
          </tr>
        </>
      ))}
    </tbody>
  </table>
  )
}
