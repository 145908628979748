/**
 * Date between tow date
 * @param {DATE} start
 * @param {DATE} end
 * @param {ARRAY} arrayObject
 * @returns
 */
 export function dateBetweenTwoDates(start, end, arrayObject) {
    var from = new Date(start);
    var to = new Date(end);
    const newList = arrayObject.filter(
      (el) => new Date(el.date) >= from && new Date(el.date) <= to
    );
    return newList;
  }
  export function myRnId () {
    return parseInt(Date.now() * Math.random())
  } 


  export function getDates (startDate, endDate) {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate <= endDate) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }

  export function dayDifferenceBetweenDates (startDate, endDate){
    const  date1 = new Date(startDate);  
    const date2 = new Date(endDate);  

    //calculate time difference  
    var time_difference = date2.getTime() - date1.getTime();  

    //calculate days difference by dividing total milliseconds in a day  
    var days_difference = time_difference / (1000 * 60 * 60 * 24);  
    return days_difference
  }

  /**
   * 
   * @param {*} year 
   * @param {*} month 
   * @returns 
   */
 export function getAllDaysInMonth(year, month) {
    const date = new Date(year, month, 1);
  
    const dates = [];
  
    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
  
    return dates;
  }