/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import 'leaflet/dist/leaflet.css';
import './map.css'
import img from './marker.png'
import { TileLayer, MapContainer, Popup } from "react-leaflet";
import Marker from 'react-leaflet-enhanced-marker'
import './map.css'
export default function MapMagasin({ latitude, longitude, Magasin,t }) {
    //------------ REFERENCES -----------
    const mapRef = useRef();
    //--------- DETAILS MAP -------------
    const [center, setCenter] = useState({
        lat: latitude === "-1" ? "36.8842439" : latitude,
        lng: longitude === "-1" ?"10.1625628"  : longitude,
        
    });
    const ZOOM_LEVEL = 8;

    return (
        <div className="panel-group" id="accordio5" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
                <div className="panel-heading " role="tab" id="headingFIVE">
                    <h4 className="panel-title text-primary">
                        <a role="button" data-toggle="collapse" data-parent="#accordio5" href="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                            <i className="icon icon-folder-alt mr-2" />Localisation
                            <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                        </a>
                    </h4>
                </div>
                <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFIVE">
                    <div className="panel-body">
                        <div className="panel panel-body" style={{ overflow: 'scroll', height: "600px" }}>
                            <div>
                                <MapContainer center={center} zoom={ZOOM_LEVEL} map={mapRef} style={{ height: "550px" }}>
                                    <TileLayer
                                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                        url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
                                    />
                                    <Marker
                                        position={[latitude === null ? -1 : latitude, longitude === null ? -1 : longitude]}
                                        icon={<img height="50px" src={img} alt="icon" />}
                                        className="marker"
                                    >
                                        <Popup>
                                            <b>
                                                {t('dashboard.Magasin')} :  {Magasin}
                                            </b>
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
