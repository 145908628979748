/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader/Loader";
import SqaureLoader from "../../../components/Loader/SqaureLoader";
import PaginationTable from "../../../components/Pagination/PaginationTable";
import { getDetailsPays, getListePays } from "../../../services/Zoning/ZoningApi";
import { SearchItems } from "../../../utils/SearchUtils";
import ModalAddPays from "./ModalAddPays";
import ModalDeletePays from "./ModalDeletePays";
import ModalEditerPays from "./ModalEditerPays";

export default function Listepays({ t }) {
    //  Liste des pays
    const [listePays, setListePays] = useState([]);
    const [searchList, setSearchlist] = useState([]);
    const [saveList, setSaveList] = useState([]);
    //USE REF
    const inputEl = useRef("");
    //Search
    const [search, setSearch] = useState("");
    //Loader
    const [Loading, setLoading] = useState(true);
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(50);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste
    const currentListe =
        search.length < 1
            ? listePays.slice(indexOfFirstPost, indexOfLastPost)
            : searchList.slice(indexOfFirstPost, indexOfLastPost);

    //Modal items
    const [show, setShow] = useState(true);
    const [index, setIndex] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    const [showEdit, setShowEdit] = useState(false)

    //Modal DELete
    const [OK1, setOK1] = useState(false)
    const [deleteCode, setdeleteCode] = useState("")
    const [indexDelete, setindexDelete] = useState(-1)

    useEffect(() => {
        setLoading(true);
        getListePays()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setListePays(result?.data.data);
                    setSaveList(result?.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [OK1]);

    //ONChange Pays
    useEffect(() => {
        if (show === false || showEdit === true) {
            getListePays()
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setListePays(result?.data.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setShow(true);
                });
        }
    }, [show, showEdit]);

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value);
        if (inputEl.current.value !== "") {
            const newList = SearchItems(listePays, search);
            setSearchlist(newList);
        } else {
            setListePays(saveList);
        }
    };
    const changedetails = (code) => {
        getDetailsPays(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setObject(result?.data?.data[0])

                }
            }).catch((err) => {
                console.log(err)
            })
    }
    return (
        <div className="row">
            <div className="col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title mt-3 float-left fs-14">
                            <i className="icon icon-map text-azure mr-2" />
                            {t("Zoning.ListedesPays")}
                        </div>
                        <button
                            className="btn btn-azure float-right fs-12"
                            data-toggle="modal"
                            data-target="#exampleModal3"
                        >
                            <i className="icon icon-plus mr-1" /> {t("Produits.Ajouter")}
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="search1 search-elements-sm mb-4 ">
                            <div className="form row no-gutters">
                                <div className="col-7"></div>
                                <div className="  col-xl-5 col-lg-5 col-md-5 mb-0">
                                    <span className="input-group-append">
                                        <input
                                            type="text"
                                            ref={inputEl}
                                            value={search}
                                            onChange={() => getSearchTerm()}
                                            className="form-control br-tr-md-0 br-br-md-0 text-azure fs-12"
                                            id="text1"
                                            placeholder={t("Zoning.Chercherunpays")}
                                        />
                                        <i className="text-azure ti-search ml-2 mt-4" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {Loading ? (
                            <SqaureLoader />
                        ) : (
                            <div
                                className="table-responsive product-datatable"
                                style={{ overflow: "scroll", height: "300px" }}
                            >
                                <table
                                    id="example"
                                    className="table table-striped table-bordered "
                                >
                                    <thead
                                        className="text-center"
                                        style={{ position: "sticky", top: 0, zIndex: 1 }}
                                    >
                                        <tr>
                                            <th className="w-15p fs-12">{t("rapports.code")}</th>
                                            <th className="wd-15p fs-12">{t("Produits.Libelle")}</th>
                                            <th className="wd-15p fs-12">{t("Routings.editer")}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {currentListe?.map((items, keys) => (
                                            <tr
                                                key={keys}
                                                className={
                                                    keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                                }
                                            >
                                                <td>{items.code} </td>
                                                <td>{items.libelle} </td>
                                                <td style={{ cursor: "pointer" }}>   <a data-toggle="modal" data-target="#updatePays" onClick={() => {
                                                    changedetails(items.code);
                                                    setCode(items.code); setIndex(keys); setShowEdit(true)
                                                }}><b> <i className="icon icon-note text-azure" /></b> </a> 
                                                 <a   data-toggle="modal" data-target="#modal-delete-pays" onClick={() => { setdeleteCode(items.code); setindexDelete(keys) }}><i className="icon icon-trash text-danger"/></a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div className="card-footer">
                        <div className="float-right">
                            <PaginationTable
                                postsPerPage={stocksPerPage}
                                totalPosts={listePays.length}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalDeletePays deleteCode={deleteCode} indexDelete={indexDelete} setOK1={setOK1} t={t} />

            <ModalAddPays setShow={setShow} t={t} />
            {showEdit ? <ModalEditerPays code={code} setShowEdit={setShowEdit} index={index} object={object} setShow={setShow} t={t} /> : null}

        </div>
    );
}
