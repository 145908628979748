/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ToastContainer } from 'react-toastify';
import PaginationTable from '../../components/Pagination/PaginationTable';
import { getNoteByCode, searchNotes } from '../../services/Notes/notesApi';
import { getMerch } from '../../services/Users/UsersApi';
import AlerteDeleteNote from './AlerteDeleteNote';
import CardNotes from './CardNotes';
import ModalDetailsNote from './ModalDetailsNote';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import SqaureLoader from '../../components/Loader/SqaureLoader';

export default function ListeNotes() {

    const { debut, fin } = useParams()
    const history = useHistory();
    //---------- REFERENCE -------------
    const searchInput = useRef();

    //------ DATE SEARCH (debut / fin) -----------
    const [dateDebut, setDateDebut] = useState(debut);
    const [dateFin, setDateFin] = useState(fin);
    //-----------  Filtre Value ------------------
    //-- Liste notes ------------
    const [listeNotes, setlisteNotes] = useState([])
    const [saveListe, setsaveListe] = useState([])
    const [listeMerch, setlisteMerch] = useState([])
    const [codeMerch, setcodeMerch] = useState("")
    //Details notes
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    //Loader 
    const [Loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(36);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = listeNotes?.slice(indexOfFirstPost, indexOfLastPost)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //ON CHNAGE current page
    useEffect(() => {
        /***
         * GET LISTE OF MERCHANDISER
         */
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteMerch(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        /**
         * GET TOTAL LISTE OF NOTE PAGINATED
         */
        const data ={}
         searchNotes(dateDebut, dateFin ,data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteNotes(result?.data?.getListeNotes)
                    setsaveListe(result?.data?.getListeNotes)
                }
                setLoading(false)
            }).catch((err) => {
                console.log(err)
            })
    }, [currentPage])

    /**
     * SEARCH DATA 
     */
    const onSearchData = () => {
        history.push({
            pathname: "/notes/datedebut=" + dateDebut + "&datefin=" + dateFin
        })
        if (codeMerch === "") {
            const data ={}
            searchNotes(dateDebut, dateFin ,data)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setlisteNotes(result?.data?.getListeNotes)
                        setsaveListe(result?.data?.getListeNotes)
                    }
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                })
        } else {
            const data = {
                user_code: codeMerch,
            }
            searchNotes(dateDebut, dateFin, data)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setlisteNotes(result?.data?.getListeNotes)
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }

    /**
     * Get DETAIL NOTE BY CODE
     * @param {string} code 
     */
    const changeDetailsRoute = (code) => {
        setLoading(true)
        getNoteByCode(code)
            .then((result) => {
                setObject(result.data.getListeNotes)
            }).catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }

    /**
     * BACK TO INITIAL RESULTS
     */
    const backListe = () => {
        setcodeMerch("")
        setlisteNotes(saveListe)
    }
    // Handle Change Marque
    const handleChangeMerch = (...args) => {
        setcodeMerch(args[1].value)
    };
    return (
        <div>
            {/**  PAGE HEADER  */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.notes')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('Note.ListeNotes')} </li>
                </ol>
            </div>
            <ToastContainer />
            {/** ----------------- SECTION  : RECHERCHE  --------------------- */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2" />{t('Note.ListeNotes')}
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className="input-group">
                                <input
                                    className="form-control fc-datepicker fs-10"
                                    type="date"
                                    value={dateDebut}
                                    onChange={(e) => setDateDebut(e.target.value)}
                                />
                                <input
                                    className="form-control fc-datepicker fs-10"
                                    type="date"
                                    value={dateFin}
                                    onChange={(e) => setDateFin(e.target.value)}
                                />
                                <div className="d-flex" >
                                    <SelectSearch
                                        options={listeMerch}
                                        search
                                        value={codeMerch}
                                        ref={searchInput}
                                        onChange={handleChangeMerch}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t("affectationtaches.choisirmerchandiseur")}
                                    />
                                </div>
                                <span className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => onSearchData()}><i className='ti-search' /></button>
                                    <button className="btn btn-secondary" type="button" onClick={() => backListe()}><i className='ti-close' /></button>
                                </span>
                            </div> 
                          </div>
                    </div>
                </div>
            </div>
            <ModalDetailsNote object={object} t={t} />
            <AlerteDeleteNote code={code} t={t} />
            {Loading? <SqaureLoader /> : <>
             {/**DETAILS PRODUITS  */}
             {listeNotes?.length === 0 ? (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                        <div className="alert bg-purple mb-5 py-4" role="alert">
                            <div className="d-flex">
                                <i className="icon icon-info mr-3 fs-30"></i>
                                <div>
                                    <h4 className="alert-heading">{t('Note.catalogueVide')}</h4>
                                    <p> {t('Note.aucunenote')}{moment(dateDebut).format("DD-MM-YYYY") + " au " + moment(dateFin).format("DD-MM-YYYY")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="row">
                        {currentListe?.map((items, keys) => (
                            <CardNotes items={items} key={keys} setCode={setCode} changeDetailsRoute={changeDetailsRoute} t={t} />
                        ))}
                    </div>
                    <div className="float-right">
                        <PaginationTable
                            postsPerPage={stocksPerPage}
                            totalPosts={listeNotes?.length}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </>
            )}
            </>}
           


        </div>
    )
}
