import React from 'react'

export default function CollapseFitler({date_d ,setdate_d , date_f,  setdate_f ,listeZones ,codezone , handleChangeZone ,handleChangeEnseigne,codeEnseigne,listeEnseigne,codePdv ,handlechangePdv ,listePdv ,saveData ,showDetailsUsers ,listeUsers ,t}) {
    return (
        <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
            <div className="panel-body">
                <div className='row mb-2'>
                    <div className='col-1'></div>
                    <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12'>
                        <div className="input-group">
                            <input type="date" className='form-control' value={date_d} onChange={(e) => setdate_d(e.target.value)} />
                            <input type="date" className='form-control' value={date_f} onChange={(e) => setdate_f(e.target.value)} />
                            <select className="form-control  br-md-0  fs-12 text-primary" value={codezone} onChange={handleChangeZone}>
                                <option value={""} >{t('rapports.Zones')}</option>
                                {listeZones?.map((items, keys) => (
                                    <option key={keys} value={items.value}>{items.name}</option>
                                ))}
                            </select>
                            <select className="form-control  br-md-0  fs-12 text-primary" value={codeEnseigne} onChange={handleChangeEnseigne}>
                                <option value={""} >{t('rapports.Enseignes')}</option>
                                {listeEnseigne?.map((items, keys) => (
                                    <option key={keys} value={items.value}>{items.name}</option>
                                ))}
                            </select>

                            <select className="form-control  br-md-0  fs-12 text-primary" value={codePdv} onChange={ handlechangePdv}>
                                <option value={""}>{t('rapports.Magasins')}</option>
                                {listePdv?.map((items, keys) => (
                                    <option key={keys} value={items.value}>{items.name}</option>
                                ))}
                            </select>

                            <span className="input-group-append">
                                <button className="btn btn-primary" type="button" onClick={() => saveData()}><i className='ti ti-search' /></button>
                                <button className="btn btn-yellow" type="button"><i className='ti ti-close' /></button>
                            </span>
                        </div>
                    </div>
                    <div className='col-1'></div>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>

                        <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                            <button className="btn tag bg-azure text-white" onClick={() => showDetailsUsers("all")}> {t('rapports.ALL')} </button>
                            {listeUsers?.map((items, keys) => (
                                <button className="btn tag bg-purple" key={keys} onClick={() => showDetailsUsers(items.value)}> {items.name}</button>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
