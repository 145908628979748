/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getTouByCode } from '../../../services/Dashboard/FirstPage';
import ListePdvLocalisation from '../../Magasins/ListePdvLocalisation';

export default function ListeDetailsTournees() {
    // Parameteres 
    const { code } = useParams('')
    //History
    const history = useHistory();
    //---- LISTE +----
    const [listeEnseinges, setlisteEnseinges] = useState([])
    // Loader
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getTouByCode(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnseinges(result?.data?.tourneebycode)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [])

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.Trournees')} </li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Magasins.Listedesmagasins')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <button className="btn bg-primary " onClick={() => history.goBack()}>
                            <i className="fe fe-arrow-left" />
                            {t('rapports.retour')}
                        </button>

                    </div>
                </div>

            </div>

            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <ListePdvLocalisation listeEnseinges={listeEnseinges} loading={loading} t={t} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
