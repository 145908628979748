/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getListeSocietes } from '../../../services/Produits/configurationProduits/ConfigurationAPI'
import {
  getListeRoles,
  getUserbycode,
  updateUserbycode,
} from '../../../services/Users/UsersApi'
import {
  getListePays,
  getListeRegions,
  getListZones,
} from '../../../services/Zoning/ZoningApi'

export default function ModifierUtilisateur() {
  const { id } = useParams('')
  //--------- FORMULAIRE -----------------
  //const [code, setCode] = useState("")
  const [nom, setnom] = useState('')
  const [telephone, settelephone] = useState('')
  const [email, setemail] = useState('')
  const [adresse, setadresse] = useState('')
  const [cin, setCin] = useState('')
  const [actif, setActif] = useState(1)
  const [codeSociete, setcodeSociete] = useState(-1)
  const [codePays, setcodePays] = useState(-1)
  const [codeRoles, setcodeRoles] = useState(-1)
  const [codeRegions, setcodeRegions] = useState(-1)
  const [codeZone, setcodeZone] = useState(-1)
  //-------------LISTE SOCIETE / PAYS / ZONES / REGIONS  --------
  const [listeSociete, setlisteSociete] = useState([])
  const [listeZones, setlisteZones] = useState([])
  const [listePays, setlistePays] = useState([])
  const [listeRegions, setlisteRegions] = useState([])
  const [role, setrole] = useState([])
  //add  image produits aux
  const [image, setImage] = useState({ preview: '', raw: '' })
  const [sync, setSync] = useState("") // CODE synchronisation
  const [loading, setloading] = useState(true) // Loader
  //------------- Traduction ---------------
  const [t, i18n] = useTranslation('global')

  useEffect(() => {
    getUserbycode(id)
      .then((result) => {
        if (result?.data?.success === 1) {
          const object = result?.data?.data[0]
          setemail(object?.email)
          setCin(object?.cin)
          setActif(object?.isactif)
          setnom(object?.nom)
          setcodePays(object?.paye_code)
          setcodeRegions(object?.region_code)
          setcodeSociete(object?.societe_code)
          settelephone(object?.telephone)
          setcodeZone(object?.zone_code)
          setadresse(object?.adresse)
          setcodeRoles(object?.role_code)
          setSync(object?.synchronisation)

          setImage({ preview: object?.image })
        }
      })
      .catch((err) => {
        console.log(err)
      }).finally(()=>{
        setloading(false)
      })
    getListeSocietes()
      .then((result) => {
        if (result?.data?.success === 1) {
          setlisteSociete(result?.data?.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getListePays()
      .then((result) => {
        if (result?.data?.success === 1) {
          setlistePays(result?.data?.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getListeRegions()
      .then((result) => {
        if (result?.data?.success === 1) {
          setlisteRegions(result?.data?.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getListZones()
      .then((result) => {
        if (result?.data?.success === 1) {
          setlisteZones(result?.data?.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getListeRoles()
      .then((result) => {
        if (result?.data?.success === 1) {
          setrole(result?.data?.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const saveData = () => {
    const data = {
      nom: nom,
      adresse: adresse,
      email: email,
      telephone: telephone,
      login: email,
      paye_code: codePays,
      region_code: codeRegions,
      zone_code: codeZone,
      role_code: codeRoles,
      societe_code: codeSociete,
      cin: cin,
      isactif: actif,
    }

    if (image.raw !== '') {
      Object.assign(data, {
        image: image.raw,
      })
    }
    if (sync !== "") {
      Object.assign(data, {
        synchronisation: sync,
      })
    }
    //web
    if (cin !== '' && nom !== '' && adresse !== '' && codePays !== -1) {
      updateUserbycode(id, data)
        .then((result) => {
          if (result?.data?.success === 1) {
            toast(t('users.usermodifee'), {
              type: 'success',
              position: 'top-right',
            })
          } else if (result === 500) {
            toast(t('users.userinscrit'), {
              type: 'warning',
              position: 'top-right',
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      toast(t('users.remplireformulaire'),
        { type: 'error', position: 'top-right' },
      )
    }
  }
  //UPLOAD IMAGE WITH PREVIEW image Commerce
  const selectFile_image = (event) => {
    if (event.target.files.length) {
      setImage({
        preview: URL.createObjectURL(event.target.files[0]),
        raw: event.target.files[0],
      })
    }
  }
  return (
    <div>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item fs-12">
            <a href="#">
              <i className="fe fe-life-buoy mr-1" /> {t('sidebar.utilisateurs')}{' '}
            </a>
          </li>
          <li className="breadcrumb-item active  fs-12" aria-current="page">
            {t('users.modifieruser')}
          </li>
        </ol>
        <div className="mt-3 mt-lg-0">
          <div className="d-flex align-items-center flex-wrap text-nowrap">
            <a
              href="/users/ajouterutilisateur"
              className="btn btn-azure btn-icon-text mr-2 d-none d-md-block  fs-12"
            >
              <i className="icon icon-settings mr-1" />
              {t('sidebar.ajoutUser')}
            </a>
            <a
              href="/users/parametrage"
              className="btn btn-azure btn-icon-text mr-2 d-none d-md-block  fs-12"
            >
              <i className="icon icon-settings mr-1" />
              {t('sidebar.parametrageUtilisateur')}
            </a>
            <a
              href="/users/liste"
              className="btn btn-azure btn-icon-text mr-2 d-none d-md-block  fs-12"
            >
              <i className="fe fe-download" />
              {t('sidebar.listeUtilisateurs')}
            </a>
          </div>
        </div>
      </div>
      <ToastContainer />
      <div className="row">
        {loading? <SqaureLoader /> : <>
        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
          {/** ----------- code / code barre produits ------------------ */}
          <div className="card">
            <div className="card-header">
              <div className="card-title mt-2">
                {' '}
                {t('users.coordonneeuser')}{' '}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('rapports.code')} :{' '}
                    </label>

                    <input
                      type="text"
                      value={id}
                      readOnly
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label className="form-control-label">
                      {' '}
                      {t('users.cin')}
                    </label>

                    <input
                      type="text"
                      value={cin}
                      onChange={(e) => setCin(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('users.nomprenom')} :{' '}
                      <span className="text-danger"> * </span>
                    </label>

                    <input
                      type="text"
                      value={nom}
                      onChange={(e) => setnom(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('users.phone')} :{' '}
                      <span className="text-danger"> * </span>
                    </label>

                    <input
                      type="number"
                      value={telephone}
                      onChange={(e) => settelephone(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('users.E-Mail')} :{' '}
                      <span className="text-danger"> * </span>
                    </label>

                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('Routings.adresse')} :{' '}
                      <span className="text-danger"> * </span>
                    </label>

                    <input
                      type="email"
                      value={adresse}
                      onChange={(e) => setadresse(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <label className="form-control-label">
                    {t('users.userimage')}{' '}
                  </label>
                  <input
                    type="file"
                    name="image"
                    onChange={(e) => selectFile_image(e)}
                    className="dropify"
                    data-height={180}
                  />
                  {image?.preview !== '' ? (
                    <img src={image.preview} alt="image_produit" height={400} />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
          {/** ----------- config  produits ------------------ */}{' '}
          <div className="card">
            <div className="card-header">
              <div className="card-title mt-2">
                {' '}
                {t('users.parametrelocalite')}{' '}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('Routings.ACTIF')}
                    </label>
                    <select
                      value={actif}
                      onChange={(e) => setActif(e.target.value)}
                      className="form-control  text-muted select2 custom-select br-md-0"
                    >  <option value={''}> Choisir</option>
                      <option value={0}> {t('rapports.non')} </option>
                      <option value={1}> {t('rapports.oui')} </option>
                    </select>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('Produits.Societe')} :{' '}
                      <span className="text-danger"> * </span>
                    </label>

                    <select
                      value={codeSociete}
                      onChange={(e) => setcodeSociete(e.target.value)}
                      className="form-control select2 custom-select br-md-0"
                    >
                      <option value={-1}> {t('Produits.Societe')} ... </option>

                      {listeSociete?.map((items, keys) => (
                        <option value={items.code} key={keys}>
                          {items.libelle}{' '}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('Zoning.Pays')} :{' '}
                      <span className="text-danger"> * </span>
                    </label>

                    <select
                      value={codePays}
                      onChange={(e) => setcodePays(e.target.value)}
                      className="form-control select2 custom-select br-md-0"
                    >
                      <option value={-1}> {t('Zoning.Pays')} ... </option>
                      {listePays?.map((items, keys) => (
                        <option value={items.code} key={keys}>
                          {' '}
                          {items.libelle}{' '}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('dashboard.region')} :{' '}
                      <span className="text-danger"> * </span>
                    </label>

                    <select
                      value={codeRegions}
                      onChange={(e) => setcodeRegions(e.target.value)}
                      className="form-control select2 custom-select br-md-0"
                    >
                      <option value={-1}> {t('dashboard.region')} ... </option>
                      {listeRegions?.map((items, keys) => (
                        <option value={items.code} key={keys}>
                          {items.libelle}{' '}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label">
                      {t('rapports.Zones')} :{' '}
                      <span className="text-danger"> * </span>
                    </label>

                    <select
                      value={codeZone}
                      onChange={(e) => setcodeZone(e.target.value)}
                      className="form-control select2 custom-select br-md-0"
                    >
                      <option value={-1}> {t('rapports.Zones')} ... </option>
                      {listeZones?.map((items, keys) => (
                        <option value={items.code} key={keys}>
                          {items.libelle}{' '}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-control-label">{t('roles')}</label>
                    <select
                      value={codeRoles}
                      onChange={(e) => setcodeRoles(e.target.value)}
                      className="form-control select2 custom-select br-md-0"
                    >
                      <option value={-1}> {t('roles')} ... </option>
                      {role?.map((items, keys) => (
                        <option value={items.code} key={keys}>
                          {items.libelle}{' '}
                        </option>
                      ))}
                    </select>{' '}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-control-label">{t('synchronisation')}</label>
                    <select
                      value={sync} onChange={(e) => setSync(e.target.value)} className="form-control    br-md-0 fs-12">
                      <option value={""}> -- {t('synchronisation')} --  </option>
                      <option value={"route"}> {t('Routings.Route')}  </option>
                      <option value={"total"}> {t('rapports.TOTAL')}   </option>
                    </select>

                  </div>
                </div>
              </div>
              <br />
              <button
                className="btn btn-primary float-right  "
                onClick={() => saveData()}
              >
                {' '}
                <i className="ti-save mr-2" /> {t('users.modifieruser')}
              </button>
            </div>
          </div>
        </div>
        </>}
       
      </div>
    </div>
  )
}
