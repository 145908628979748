/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory,useParams,} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import MainStock from "./MainStock";
import { traitement } from "./Traitement";

export default function RapportStockIssues() {
  const history = useHistory();
  const { date_debut, date_fin } = useParams("");
  const searchInput = useRef();

  //----------- FILTRE ( date debut et date fin ) --------------
  const [datedebut, setDatedebut] = useState(date_debut);
  const [datefin, setDateFin] = useState(date_fin);
  //HEADERS TABLE
  const [headerGammes, setHeaderGammes] = useState([]);
  const [headerEnseignes, setheaderEnseignes] = useState([]);

  //--------------- LISTE ENSEIGNES ----------
  const [codeEnseigne, setcodeEnseigne] = useState("");
  const [Saveenseignes, setSaveEnseignes] = useState([]);
  
  //GROUPED PRODUITS
  const [groupedByGammes, setgroupedByGammes] = useState([]); //GROUPED PRODUITS BY gammes
  const [totalRuptures, settotalRuptures] = useState([]); // total ruptures par gammes
  const [totalProduits, settotalProduits] = useState([]); //Total produits par gammes
  //------------------- HA (HORS ASSORTIMENTS ) ----------------------
  const [produit_conventions, setproduit_conventions] = useState([]) // TOTAL PRODUIT PAR CONVENTION GMS DANS GAMME ET ENSEINGE
  const [produit_ruptures, setproduit_ruptures] = useState([]) // TOTAL DES RUPTURES PAR PRODUITS (gamme_code , enseigne_code)

  var liste = [];

  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //--------- Loader ------------------------
  const [loading, setloading] = useState(true);
  const [loadingChart, setloadingChart] = useState(true);
  // Labels
  const [Labels, setLabels] = useState([]);
  const [Series, setSeries] = useState([]);

  var liste_1 = [];
  // DATA OF CHARTS
  var options = {
    options: {
      chart: {
        width: 380,
        type: "pie",
        stacked: true,
        id: "basic-bar",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
      },
      labels: Labels, //GAMMES
      title: {
        text: "Moyenne des AV/OOS",
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },

      tickPlacement: "on",
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: Series, // Values of Gammes
  };


  useEffect(() => {
    traitement(date_debut, date_fin , setproduit_conventions ,setproduit_ruptures , setHeaderGammes, setSaveEnseignes, setheaderEnseignes  ,settotalProduits , settotalRuptures , setLabels , produit_conventions , produit_ruptures  ,liste_1 , setSeries , setloading , setloadingChart);
  }, []);

  const findElementTotal = (enseigne_code, gamme_code) => {
    var L = totalRuptures?.filter(
      (el) => el.enseigne_code === enseigne_code && el.gamme_code === gamme_code
    );
    var L1 =produit_conventions?.filter((el)=>el.enseigne_code === enseigne_code && el.gamme_code === gamme_code) //TOTAL CONVENTION
    console.log(L1)
    var L2 =produit_ruptures?.filter((el)=>el.enseigne_code === enseigne_code && el.gamme_code === gamme_code) //TOTAL PRODUIT
    console.log(L2)
    /**
     * 
     * Formule convention enseigne code /gamme code
     */
    var Moyenne_L2_PDV = L2?.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      return accumulateur + (valeurCourante.total_produits/L1[0]?.total);
    },
    0)
    if (L?.length > 0) {
      var rupture =
        (parseFloat(L[0]?.total_ruptures /  (L[0]?.total_ruptures+L[0]?.total_disponible))) * 100;
      var disponible =
        (parseFloat(L[0]?.total_disponible / (L[0]?.total_ruptures+L[0]?.total_disponible))) * 100;
      var ha = Moyenne_L2_PDV
      liste.push({
        rupture: rupture,
        disponible: disponible,
        ha: ha,
        gamme_code: gamme_code,
        enseigne_code: enseigne_code,
      });
      return (
        <>
          <td style={{ background: "#D9F8C4" }}>
          {parseFloat(disponible).toFixed(2)}
            {"% "}
          </td>
          <td style={{ background: "#FFFDA2" }}>
          {parseFloat(rupture).toFixed(2)} %
        
          </td>
          <td style={{ background: "#ABD9FF" }}>
            {parseFloat(ha).toFixed(2)} %
          </td>
        </>
      );
    } else
      return (
        <>
          <td style={{ background: "#D9F8C4" }}>0</td>
          <td style={{ background: "#FFFDA2" }}> 0 </td>
          <td style={{ background: "#ABD9FF" }}> 0 </td>
        </>
      );
  };

  const calculAverage = (gamme_code) => {
    var l = _.groupBy(liste, "gamme_code");
    const total_disponible = l[gamme_code]?.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      return accumulateur + parseFloat(valeurCourante.disponible);
    },
    0);

    const total_rupture = l[gamme_code]?.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      return accumulateur + parseFloat(valeurCourante.rupture);
    },
    0);

    const ha = l[gamme_code]?.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      return accumulateur + valeurCourante.ha;
    },
    0);
   
    return (
      <>
        <td style={{ background: "#D9F8C4" }}>
        {parseFloat(total_disponible / l[gamme_code]?.length).toFixed(2)}
        </td>
        <td style={{ background: "#FFFDA2" }}>
        {parseFloat(total_rupture / l[gamme_code]?.length).toFixed(2)}{" "}
        </td>
        <td style={{ background: "#ABD9FF" }}>       
        {parseFloat(ha / l[gamme_code]?.length).toFixed(2)}
        </td>
      </>
    );
  };

  const saveData = () => {
    setloading(true);
    if (datedebut !== "" && datefin !== "") {
      if (datedebut <= datefin) {
        setloading(true);
        history.push({
          pathname:
            "/reportStockIssues/date_debut=" +
            datedebut +
            "&date_fin=" +
            datefin,
        });
        traitement(date_debut, date_fin , setproduit_conventions ,setproduit_ruptures , setHeaderGammes, setSaveEnseignes, setheaderEnseignes  ,settotalProduits , settotalRuptures , setLabels , produit_conventions , produit_ruptures  ,liste_1 , setSeries , setloading , setloadingChart);
      }
    } else if (datedebut < datefin) {
      toast(t("messages.dateinf"), { type: "warning", position: "top-right" });
    }
  };

  const handleChangeEnseigne = (...args) => {
    setcodeEnseigne(args[1].value);
    const L = Saveenseignes?.filter(
      (el) => el.value === args[1].value
    );
    setheaderEnseignes(L);
  };
  return (
    <div>
      <MainStock t={t} loading={loading} headerGammes={headerGammes} headerEnseignes={headerEnseignes} groupedByGammes={groupedByGammes} Labels={Labels} options={options}  loadingChart={loadingChart} Saveenseignes={Saveenseignes} datedebut={datedebut} setDatedebut={setDatedebut}  datefin={datefin}  setDateFin={setDateFin}  saveData={saveData} codeEnseigne={codeEnseigne} searchInput={searchInput}   handleChangeEnseigne={handleChangeEnseigne} calculAverage={calculAverage} findElementTotal={findElementTotal} />
    </div>
  );
}
