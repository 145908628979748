/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getEtatPDVS, updateMagasin } from '../../services/Magasins/PdvApi';

export default function ModalUpdateEtatPdvs({t, setOk , code}) {
    //etat pdvs
    const [etat, setetat] = useState()
   
    useEffect(() => {
        getEtatPDVS(code).then((result) => {
            if(result?.data?.success === 1){
                setetat(result?.data?.data[0]?.isactif)
            }
        }).catch((err) => {
            console.log(err);
        });
    }, [])
    const ChangeEtat = () =>{
        updateMagasin(code, {isactif:etat})
        .then((result) => {
            if(result?.data?.success===1){
                toast("Modification effectué avec succès!",{type:"success",position:"top-right"})
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  return (
    <div className="modal fade" id="desactiver" tabIndex={-1} role="dialog" aria-labelledby="modal" aria-hidden="true" >
    <div className="modal-dialog modal-danger" role="document">
        <div className="modal-content border-0">
            <div className="modal-header">
                <h2 className="modal-title" id="modal">{"Produit : " + code} </h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setOk(true)}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="py-3 text-center">
                    <h3> <i className='ti-na text-danger' /> </h3>
                    <h4 className="heading mt-4 fs-12"> <b>Voulez vous désactiver le produit ? </b></h4>
                    <select className='form-control fs-10 custom-select' value={etat} onChange={(e)=>setetat(e.target.value)}>
                        <option value={""}>Choisir</option>
                        <option value={"1"}>Actif</option>
                        <option value={"0"}>Inactif</option>
                    </select>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={()=>{ChangeEtat()}}> <i className="icon icon-check mr-1" /> Enregistrer </button>
                <button type="button" className="btn btn-secondary text-white ml-auto" data-dismiss="modal" onClick={() => setOk(true)}><i className="icon icon-close mr-1" /> {t('Routings.annueler')} </button>
            </div>
        </div>
    </div>
</div>
  )
}
