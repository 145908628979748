
import React from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'

export default function PLGammeWeek({liste,t,header,listeGamme,listeGammePresc, allGamme }) {

    const valueByMonth = (month, enseigne) => {
        if (listeGamme[month] !== undefined) {
            const liste = listeGamme[month].find((elemnents) => elemnents.gamme === enseigne)
            if (liste !== undefined) {
                var pl = (liste.plconstate/liste.pltotal)*100
                return parseFloat(pl).toFixed(2)+"%"
            } else return undefined
        }
    }


  return (
    <div className='card'>
            <div className="card-header">
                <div className="card-title mt-3 float-left">Part Linéaire  par Semaine/Gamme  </div>
                <div className="float-right">
                    <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-success text-center "
                        table="table-to-xlsx"
                        filename="Pl_Gamme"
                        sheet="tablexls"
                        buttonText={<span className=" text-center fs-10"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                    />
                </div>
            </div>
            <div className='card-body'>
                <div className="table-responsive table-striped product-datatable" style={{ overflow: 'auto', height: 'auto' }}>
                    <table
                        id="table-to-xlsx"
                        className="table  table-bordered "
                    >
                        <thead className="text-center " style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                       
                        <tr>
                        <th className="w-15p  fs-10"  >{t('dashboard.Enseigne')} </th>
                        {header?.map((items,keys)=>(
                            <th className='fs-10' key={keys}>{items}</th>
                        ))}
                        </tr>
                        </thead>
                        <tbody>
                        {allGamme?.length === 0 ?
                             <tr>
                                            <td colSpan={13} className='text-center text-danger'> {t('Zoning.Aucunresultat')}</td>
                                        </tr> :
                                allGamme?.map((items, keys) => (
                                    <tr key={keys}   className={
                                        keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"
                                    }>
                                                                            <td>{items}</td>
                                    {liste?.map((i,k)=>(
                                         <td> {valueByMonth(i, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(i, items)}</h6> : 0} </td>
                                    ))}
                                    </tr>))         
                                        
                                     }
                        </tbody>
                    </table>
                </div>
            </div>
    </div>
  )
}
