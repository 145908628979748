import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify'
import SqaureLoader from '../../components/Loader/SqaureLoader';
import { getEvaluationYear } from '../../services/Evaluation/EvaluationAPI';
import ModalEvaluationQuestions from './ModalEvaluationQuestions';

export default function ResultatAnnuelle() {
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    const today = new Date()
    const year = today.getFullYear()
    const [currentYear, setcurrentYear] = useState(year)
    const [loading, setloading] = useState(true)
    const [liste, setliste] = useState([])
    const [listermech, setlistermech] = useState([])
    const [month, setmonth] = useState("") //Month
    const [userCode, setuserCode] = useState("") // USER CODE
    const [ok, setok] = useState(false) //Modal Ruptures 
    const [grouped_merch, setgrouped_merch] = useState([]) // Liste des merch grouped => Pour avoir le user_code
    useEffect(() => {
       
        getEvaluationYear(currentYear).then((result) => {
            if (result?.data?.success === 1) {
                const merch = groupBy(result?.data.data, "merch")
                const x = Object.keys(merch)
                setgrouped_merch(merch)
                setliste(x)
                const l = groupBy(result.data.data, "month")
                console.log(l)

                setlistermech(l)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloading(false)
        })
    }, [])

    const valueByMonth = (month, merch) => {
        if (listermech[month] !== undefined) {
            const liste = listermech[month].find((elemnents) => elemnents.merch === merch)
            if (liste !== undefined) {
                return liste.moyenne
            } else return undefined
        }
    }
    const onChangeYear = (e)=>{
        setloading(true)
        setcurrentYear(e.target.value)
        getEvaluationYear(e.target.value).then((result) => {
            if (result?.data?.success === 1) {
                const merch = groupBy(result?.data.data, "merch")
                const x = Object.keys(merch)
                setliste(x)
                const l = groupBy(result.data.data, "month")
                setlistermech(l)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloading(false)
        })
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Evaluation')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('Moyennes des évaluations')}</li>
                </ol>

            </div>
            <ToastContainer />
            <div className="card">
            <div className="card-header">
                <div className="card-title mt-3 float-left"> {t('Evaluation Annuelle')}  </div>
                <div className="float-right">
                    <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-success text-center "
                        table="table-to-xls"
                        filename="evaluation_Merchandiseurs"
                        sheet="tablexls"
                        buttonText={<span className=" text-center fs-10"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                    />
                </div>
            </div>
            <div className="card-body">
                <div className='row mb-3'>
                    <div className='col-4' />
                    <div className='col-4'>
                        <select name="annee" className="form-control select2 custom-select text-center" value={currentYear} onChange={(e) => onChangeYear(e)}>
                        <option value={""}>{t('conventions.annee')}</option>
                            <option value={2022}>2022</option>
                            <option value={2021}>2021</option>
                            <option value={2020}>2020</option>
                            <option value={2019}>2019</option>
                            <option value={2018}>2018</option>
                        </select>
                    </div>
                    <div className='col-4' />
            </div>
            {loading ? <SqaureLoader /> : (
                    <div className="table-responsive table-striped product-datatable" style={{ overflow: 'auto', height: 'auto' }}>
                        <table
                            id="table-produit"
                            className="table  table-bordered "
                        >
                            <thead className="text-center " style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                                
                                
                                <tr>
                                    <th className='fs-10'>{t('Merchandiseurs')}</th>
                                    <th className="wd-15p fs-10" >{t('janvier')}</th>
                                    <th className="wd-15p fs-10" >{t("fevrier")}</th>
                                    <th className="wd-15p fs-10" >{t("Mars")}</th>
                                    <th className="wd-15p fs-10" >{t("avril")}</th>
                                    <th className="wd-15p fs-10" >{t("mai")}</th>
                                    <th className="wd-15p fs-10" >{t("juin")}</th>
                                    <th className="wd-15p fs-10"  >{t("juillet")}</th>
                                    <th className="wd-15p fs-10" >{t("aout")}</th>
                                    <th className="wd-15p fs-10" >{t("Septembre")}</th>
                                    <th className="wd-15p fs-10" >{t("Octobre")}</th>
                                    <th className="wd-15p fs-10" >{t("Novembre")}</th>
                                    <th className="wd-15p fs-10" >{t("decembre")}</th>
                                </tr>
                               
                            </thead>
                            <tbody>
                                {
                                    liste?.length === 0 ?
                                        <tr>
                                            <td colSpan={13} className='text-center text-danger'> {t('Zoning.Aucunresultat')}</td>
                                        </tr> :
                                        liste?.map((items, keys) => (
                                            <tr key={keys}   className={
                                                keys % 2 === 1 ? "fs-12 bg-azure-lightest text-center" : "fs-12 text-center"
                                            }>
                                                <td> {items}</td>
                                                <td> {valueByMonth(1 , items) !== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals" style={{cursor: 'pointer'}} onClick={()=>{setok(true);setmonth(1 );setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(1, items) }</h6> : 0} </td>
                                                <td> {valueByMonth(2 , items) !== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals" style={{cursor: 'pointer'}} onClick={()=>{setok(true);setmonth(2 );setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(2, items) }</h6> : 0} </td>
                                                <td> {valueByMonth(3 , items) !== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals" style={{cursor: 'pointer'}} onClick={()=>{setok(true);setmonth(3 );setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(3, items) }</h6> : 0} </td>
                                                <td> {valueByMonth(4 , items) !== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals" style={{cursor: 'pointer'}} onClick={()=>{setok(true);setmonth(4 );setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(4, items) }</h6> : 0} </td>
                                                <td> {valueByMonth(5 , items) !== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals" style={{cursor: 'pointer'}} onClick={()=>{setok(true);setmonth(5 );setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(5, items) }</h6> : 0} </td>
                                                <td> {valueByMonth(6 , items) !== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals" style={{cursor: 'pointer'}} onClick={()=>{setok(true);setmonth(6 );setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(6, items) }</h6> : 0} </td>
                                                <td> {valueByMonth(7 , items) !== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals" style={{cursor: 'pointer'}} onClick={()=>{setok(true);setmonth(7 );setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(7, items) }</h6> : 0} </td>
                                                <td> {valueByMonth(8 , items) !== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals" style={{cursor: 'pointer'}} onClick={()=>{setok(true);setmonth(8 );setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(8, items) }</h6> : 0} </td>
                                                <td> {valueByMonth(9 , items) !== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals" style={{cursor: 'pointer'}} onClick={()=>{setok(true);setmonth(9 );setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(9, items) }</h6> : 0} </td>
                                                <td> {valueByMonth(10, items)!== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals"  style={{cursor: 'pointer'}}onClick={()=>{setok(true);setmonth (10);setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(10, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(11, items)!== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals"  style={{cursor: 'pointer'}}onClick={()=>{setok(true);setmonth (11);setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(11, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(12, items)!== undefined ? <h6 className='text-primary fs-12' data-toggle="modal" data-target="#getEvals"  style={{cursor: 'pointer'}}onClick={()=>{setok(true);setmonth (12);setuserCode(grouped_merch[items][0]?.user_code)}}> {valueByMonth(12, items)}</h6> : 0} </td>
                                            </tr> 
                                        ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            </div>
              {/**
             * MODAL Evaluation ====> Liste des eval total
             */}
            {ok? <ModalEvaluationQuestions t={t} setok={setok} user_code={userCode}   month={month} year={year} />:null}
        </div>
    )
}
