/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SqaureLoader from '../../components/Loader/SqaureLoader';
import { addGratuite, getListeGratuite, getListeGratuiteSearch, deleteGratuites } from '../../services/Produits/ProduitsAPI';
import { SearchItems } from '../../utils/SearchUtils';
import _ from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import DeleteGratuite from './DeleteGratuite';
import { getMerch } from '../../services/Users/UsersApi';
import SelectSearch, { fuzzySearch } from 'react-select-search';

export default function ValidationGratuite() {

    //------------ LOADING -------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //--------- Search --------------------
    const [date_d, setdate_d] = useState("")
    const [date_f, setdate_f] = useState("")
    const [codeUser, setCodeUser] = useState("")
    const [listeUsers, setlisteUsers] = useState([])
    const searchInput = useRef();

    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    const [listeGratuite, setlisteGratuite] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    const [ok, setok] = useState([])
    const currentListe = search.length < 1 ? listeGratuite : searchList

    //Modal items
    const [index2, setindex2] = useState(-1)
    const [code, setCode] = useState("")
    const [show2, setShow2] = useState(false)

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeGratuite, search)
            setSearchlist(newList)
        } else {
            setlisteGratuite(saveList)
        }
    }
    const traitement = () => {
        
        getListeGratuite().then((result) => {
            if (result?.data?.success === 1) {
                console.log(result?.data?.data);
                setlisteGratuite(result?.data?.data)
                setSaveList(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }
    useEffect(() => {
        getMerch().then((result) => {
            if(result?.data?.success === 1){
                setlisteUsers(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        traitement()
    }, [])

    /**
     * CHANGE ETAT VALIDATION 
     * @param {*} e 
     * @param {*} index 
     */
    const handleChangeEtat = (e, index) => {
        const clonedData = [...listeGratuite]
        clonedData[index][e.target.name] = e.target.checked
        setlisteGratuite(clonedData)
    }

    /**
     * ADD VALIDATION
     */
    const addValidation = () => {
        var data = []
        listeGratuite?.map((items) => {
            var el = {
                id: items.id,
                code: items.code,
                date: items.date,
                pdv_code: items.pdv_code,
                produit_code: items.produit_code,
                codeVisite:items.codeVisite,
                user_code: items.user_code,
                qte: items.qte,
                type: items.type,
                typeSaisie: items.typeSaisie,
                etat: items.etat === true || items.etat === 1 ? 1 : 0,
                created_at: items.created_at,
                updated_at: items.updated_at
            }
            data.push(el)
        })
        var l = _.uniq(data)
        var test = l.filter((el) => el.etat === 1)
        test?.map((items) => {
            deleteGratuites(items.id)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        toast(t("Routings.modifieSuccess"), { type: "success", position: 'top-right' })
                        addGratuite({
                            code: items.code,
                            date: items.date,
                            pdv_code: items.pdv_code,
                            produit_code: items.produit_code,
                            user_code: items.user_code,
                            codeVisite:items.codeVisite,
                            qte: items.qte,
                            ajout : 1,
                            type: items.type,
                            typeSaisie: items.typeSaisie,
                            created_at: items.created_at,
                            updated_at: items.updated_at

                        }).then((result) => {
                            console.log('AJOUT GRATUITE')
                        }).catch((err) => {
                            console.log(err)
                        });
                    } else {
                        toast("Erreur de modification ! ", { type: "success", position: 'top-right' })
                    }
                }).catch((err) => {
                    console.log(err)
                })
        })
        setloading(true)
        traitement()


    }
    const handleChangeUsers = (...args) => {
        setCodeUser(args[1].value)
    }
    const onChangeValue = () =>{
        if(date_d>date_f){
            toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
        }else if(codeUser === ""){
            toast("Vous devez choisir un merchandiseur", { type: "warning", position: "top-right" })

        }else {
            //Search by date debut / date fin et user_code
            getListeGratuiteSearch(codeUser,date_d,date_f).then((result) => {
                if (result?.data?.success === 1) {
                    setlisteGratuite(result?.data?.data)
                    setSaveList(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
        }
    }
   
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Produits.Produits')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('sidebar.validationGratuite')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/produits/cadeaux" className="btn btn-yellow btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-present mr-1" />
                            {t('Produits.Cadeaux')}
                        </a>
                        <a href="/produits/Configurations" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-gifts mr-1" />
                            {t('Produits.ConfiguraionProduits')}
                        </a>
                        <a href="/produits/codeMETI" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-wrench mr-1" />
                            {t('Produits.ConfigurationCodeMETI')}
                        </a>

                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className='card'>
                <div className='card-body'>
                    <div className="form row no-gutters mb-4">
                        <div className="col-6">
                        <div className="input-group">
                                        <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control text-primary fs-10" />
                                        <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control text-primary fs-10" />
                                        <SelectSearch
                                        options={listeUsers}
                                        search
                                        ref={searchInput}
                                        onChange={handleChangeUsers}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder="Liste Merchandiseurs"
                                    />
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => onChangeValue()}><i className='ti-search' /></button>
                                        </span>
                                    </div>
                        </div>
                        <div className='col-1'/>
                        <div className=" col-xl-5 col-lg-5 col-md-5 mb-0">
                            <div className="input-group">
                                <input type="text" ref={inputEl} value={search}
                                    onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary"
                                    id="text1" placeholder="Rechercher Gratuité" />
                                <button className="btn bg-primary"  ><i className="ti-search"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive  product-datatable mt-3" style={{ overflow: 'auto', height: '500px' }}>
                        <table className="table  table-bordered ">
                            <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1/* , backgroundColor: "#acd0fc" */ }} >

                                <tr>
                                    <th className="w-15p fs-10" >Date</th>
                                    <th className="wd-15p fs-10" >Magasin</th>
                                    <th className="wd-15p fs-10" > Produit</th>
                                    <th className="wd-15p fs-10" >Merchandiseur</th>
                                    <th className="wd-15p fs-10" >Quantité</th>
                                    <th className="wd-15p fs-10" >Type</th>
                                    <th className="wd-15p fs-10" >Type Saisie</th>
                                    <th className="wd-15p fs-10" >Validation</th>
                                    <th className="wd-15p fs-10" >Action</th>


                                </tr>
                            </thead>
                            {loading ? <SqaureLoader /> : (
                                <tbody>
                                    {currentListe?.map((items, keys) => (
                                        <tr key={keys} className={
                                            keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10  text-center"
                                        }>
                                            <td>{moment(items.date).format("DD/MM/YYYY")}</td>
                                            <td>{items.nommagasin}</td>
                                            <td>{items.produit}</td>
                                            <td>{items.merch}</td>
                                            <td>{items.qte}</td>
                                            <td>{items.type}</td>
                                            <td>{items.typeSaisie}</td>
                                            <td> <input type="checkbox" name="etat" value={items.etat} onChange={(e) => handleChangeEtat(e, keys)} />
                                            </td>
                                            <td> <i className='ti-trash text-danger' data-toggle="modal" data-target="#modal-notification" onClick={() => { setCode(items.id); setindex2(keys); setShow2(true) }} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}

                        </table>
                        <div className='row'>
                            <div className='col-8' />
                            <div className='col-4'>
                                <button className='btn btn-primary float-right' onClick={() => addValidation()}> <i className='ti-save mr-2' /> Enregistrer </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show2 ? <DeleteGratuite setShow2={setShow2} index2={index2} code={code} t={t} /> : null}

        </div>
    )
}
