import React from 'react'

export default function EnteteStock({t}) {
  return (
    <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/rapports">
              <i className="fe fe-life-buoy mr-1" /> {t("rapports.Rapport")}
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Rapport Stock Issues
          </li>
        </ol>
      </div>
  )
}
