import moment from 'moment';
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { getDetailsRupturesByPdvandMonth } from '../../../services/Dashboard/FirstPage';

export default function ModalHistoriqueRuptures({t,setok1,month,year,pdv_code ,produit_code}) {
     //------------- LISTE RUPTURES ----------
     const [listeRuptures, setlisteRuptures] = useState([])
     const [loading, setloading] = useState(true)
     useEffect(() => {
        setloading(true)
        getDetailsRupturesByPdvandMonth(month,year, pdv_code,produit_code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteRuptures(result?.data?.ruptures)
                    console.log(result?.data?.ruptures)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [month])
  return (
    <div className="modal fade" id="exampleModal3" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="exampleModal3" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content" style={{ width: "max-content" }}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModal3"> <i className='fe fe-tag text-azure mr-1' /> <u>{t('sidebar.detailRuptures')}</u> </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setok1(false); setlisteRuptures([]) }}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                {loading ? <SqaureLoader /> : (
                    <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '500px' }}>
                        <table className="table  table-bordered ">
                            <thead className="text-center bg-purple fs-12" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                <td className='fs-10'>#</td>
                                <td className="wd-15p fs-10">Produit</td>
                                <td className="wd-15p fs-10">Merchandiseur</td>
                                <td className="wd-15p fs-10">Date</td>
                            </thead>
                            <tbody className='fs-12'>
                                {listeRuptures?.map((items, keys) => (
                                    <tr key={keys}>
                                        <td className='fs-10'>{keys+1}</td>
                                        <td className="wd-15p fs-10">{items.nom_produit}</td>
                                        <td className="wd-15p fs-10">{items.nom_user}</td>
                                        <td className="wd-15p fs-10">{moment(items.date).format("DD-MM-YYYY")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
           
        </div>
    </div>
</div>
  )
}
