import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { postObjectPays } from '../../../services/Zoning/ZoningApi'
import { myRnId } from '../../../utils/DateUtils'

export default function ModalAddPays({ setShow, t }) {
    //------ Formulaire -----------
    const [code, setCode] = useState("")
    const [libelle, setlibelle] = useState("")

    const traitement = (data)=>{
        postObjectPays(data)
        .then((result) => {
            if (result.data.success === 0) {
                toast(t('Zoning.Erreur'), {
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else if (result.data.success === 1) {
                toast(t('Zoning.messagepays'), {
                    type: "success",
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const saveData = () => {

        //Data of code & libelle FOR OBJECT PAYS
        if (code === "" && libelle !== "") {
            var generateCode = libelle.slice(0, 3)
            setCode(generateCode + "-" + myRnId())

            const data = {
                code: generateCode + "-" + myRnId(),
                libelle: libelle
            }
             traitement(data)
        }else if (code !== "" && libelle !== ""){
            const data = {
                code: code,
                libelle: libelle
            }
            traitement(data)

        }
    }

    return (
        <div className="modal fade" id="exampleModal3" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-azure" id="example-Modal3"> <i className='icon icon-map mr-1' /> {t('Zoning.ajouterPays')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} /> </span>

                    </div>
                    <div className="modal-body">
                        <form>
                            <div className='row'>

                                <div className="form-group col-6">
                                    <label className="form-control-label">{t('rapports.code')}:</label>
                                    <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="form-control-label">{t('dashboard.Libelle')}:</label>
                                    <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer ">
                        <button type="button" className="btn btn-azure fs-12 " onClick={() => saveData()} > <i className='icon icon-cloud-download mr-1' />{t('rapports.enregistrer')} </button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" onClick={() => setShow(false)}> <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
                    </div>

                </div>
            </div>
        </div>

    )
}
