import moment from 'moment';
import React from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import SqaureLoader from '../../../components/Loader/SqaureLoader';

export default function ModalExportExcel({ listeRuptures ,t }) {
    return (
        <div className="modal fade " id="exampleModal3" tabIndex={-1} role="dialog" aria-hidden="true" >
            <div className="modal-dialog modal-xln" role="document">
                <div className="modal-content" style={{ width:"150%", display:"flex",verticalAlign:"middle" ,justifyContent: 'center', }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModal3">  {t('rapportrupture.exporter')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" /> </span>

                    </div>
                    <div className="modal-body">
                        <div className="float-right">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-success text-center "
                            table="table-to-xls"
                            filename="RuptureMerbiss"
                            sheet="tablexls"
                            buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                        />
                        </div>
                       
                        <div className="table-responsive table-striped product-datatable">
                            {listeRuptures.lentgh === 0 ? (
                                <SqaureLoader/>
                            ) : (
                                <table
                                id="table-to-xls"
                                className="table  table-bordered "
                            >
                                <thead className="text-center">
                                    <tr >
                                        <th className="w-15p fs-12" >{t('dashboard.Utilisateur')}</th>
                                        <th className="wd-15p fs-12">{t('rapportrupture.date')}</th>
                                        <th className="wd-15p fs-12">{t('rapports.Enseignes')}</th>
                                        <th className="wd-15p fs-12">{t('rapportrupture.categorie')}</th>
                                        <th className="wd-15p fs-12">{t('rapports.MAGASIN')}</th>
                                        <th className="wd-15p fs-12">{t('rapports.code')}</th>
                                        <th className="wd-15p fs-12">{t('relevePrix.cab')}</th>
                                        <th className="wd-15p fs-12" >{t('Produits.CodeMETI')}</th>
                                        <th className="wd-15p fs-12">{t('rapportrupture.produit')}</th>
                                        <th className="wd-15p fs-12">{t('rapportrupture.etat')}</th>

                                        <th className="wd-15p">{t('rapportrupture.actioncause')}</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {listeRuptures?.map((items, keys) => (
                                        <tr key={keys} className="fs-12"> 
                                            <td >{items.nom_user} </td>
                                            <td >{moment(items.date).format("DD-MM-YYYY")} </td>
                                            <td >{items.nom_enseigne} </td>
                                            <td >{items.nom_categoriepdv} </td>
                                            <td >{items.nom_pdv} </td>
                                            <td >{items.code} </td>
                                            <td >{items.code_a_barre} </td>
                                            <td >{items.code_meti !== null ? items.societe : "-"} </td>
                                            <td >{items.nom_produit} </td>
                                            <td >
                                                {items.etat_repture === "0" ? t('rapports.Disponible') : t('rapports.Rupture')}
                                            </td>
                                            <td>{items.cause !== null ? items.cause : "-"} </td>
                                        </tr>
                                    ))}
            
                                       
                                </tbody>
                            </table>
                            ) }
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
