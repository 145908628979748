/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast, ToastContainer } from 'react-toastify'
import { getListeSocietes } from '../../../services/Produits/configurationProduits/ConfigurationAPI'
import { addUser, getListeRoles } from '../../../services/Users/UsersApi'
import { getListePays, getListeRegions, getListZones } from '../../../services/Zoning/ZoningApi'

export default function AjouterUtilisateur() {


    //--------- FORMULAIRE -----------------
    //const [code, setCode] = useState("")
    const [nom, setnom] = useState("")
    const [telephone, settelephone] = useState("")
    const [email, setemail] = useState("")
    const [adresse, setadresse] = useState("")
    const [cin, setCin] = useState("")
    const [actif, setActif] = useState(1)
    const [password, setpassword] = useState("")
    const [codeSociete, setcodeSociete] = useState(-1) // CODE SOCIETE
    const [codePays, setcodePays] = useState(-1) //CODE PAYS
    const [codeRoles, setcodeRoles] = useState(-1) // CODE ROLE 
    const [codeRegions, setcodeRegions] = useState(-1) // CODE REGION
    const [codeZone, setcodeZone] = useState(-1) // CODE ZONE
    const [sync, setSync] = useState("") // CODE synchronisation

    //-------------LISTE SOCIETE / PAYS / ZONES / REGIONS  --------
    const [listeSociete, setlisteSociete] = useState([])
    const [listeZones, setlisteZones] = useState([])
    const [saveZone, setsaveZone] = useState([])
    const [listePays, setlistePays] = useState([])
    const [listeRegions, setlisteRegions] = useState([])
    const [saveRegions, setsaveRegions] = useState([])
    const [role, setrole] = useState([])
    //add  image produits aux 
    const [image, setImage] = useState({ preview: "", raw: "" });

    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");


    useEffect(() => {
        getListeSocietes()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteSociete(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getListePays().then((result) => {
            if (result?.data?.data !== null) {
                setlistePays(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getListeRegions().then((result) => {
            if (result?.data?.data !== null) {
                setlisteRegions(result?.data?.data)
                setsaveRegions(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getListZones()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteZones(result?.data?.data)
                    setsaveZone(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getListeRoles().then((result) => {
            if (result?.data?.data !== null) {
                setrole(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }, [])

    const onChangePays = (e) => {
        setcodePays(e.target.value)
        const liste = saveRegions?.filter((element) => element.paye_code === e.target.value)
        setlisteRegions(liste)
    }
    const onChangeRegions = (e) => {
        if (codePays !== -1) {
            setcodeRegions(e.target.value)
            const liste = saveZone?.filter((element) => element.region_code === e.target.value)
            setlisteZones(liste)
        } else {
            toast(t('users.selectionnerpays'), { type: "info", position: 'top-right' })
        }

    }
    const onChangeZone = (e) => {
        if (codePays !== -1 && codeRegions !== -1) {
            setcodeZone(e.target.value)

        } else if (codePays !== -1 && codeRegions === -1) {
            toast(t('users.selectionnerregion'), { type: "info", position: 'top-right' })
        } else {
            toast(t('users.selectionnerPaysRegion'), { type: "info", position: 'top-right' })

        }
    }
    //UPLOAD IMAGE WITH PREVIEW image Commerce
    const selectFile_image = (event) => {
        if (event.target.files.length) {
            setImage({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0]
            })
        }
    }

    const saveData = () => {
        const data = {
            nom: nom,
            adresse: adresse,
            email: email,
            telephone: telephone,
            login: email,
            paye_code: codePays,
            region_code: codeRegions,
            zone_code: codeZone,
            role_code: codeRoles,
            societe_code: codeSociete,
            password: password,
            cin: cin,
            isactif: actif,
        }
        if (image.raw !== "") {
            Object.assign(data, {
                image: image.raw,
            })
        }
        if(sync !== ""){
            Object.assign(data, {
                synchronisation: sync,
            })
        }
        if (nom !== "" && adresse !== "" && password !== "" && codePays !== -1 && codeRegions !== -1 && codeZone !== -1 && codeRoles !== -1){
            addUser(data)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        toast(t('users.inscriptionuser'), { type: "success", position: "top-right" })
                    } else if (result === 500) {
                        toast(t('ERREUR Systéme !!'), { type: "warning", position: "top-right" })
                    } else if (result=== 400 || result === 422) {
                        toast("Utilisateur Déja inscrit avec cet email ou Num° télèphone",{type:"error" , position:"top-right"})
                    }
                }).catch((err) => {
                    console.log(err)
                });
        } else {
            toast(t('users.remplireformulaire'), { type: "error", position: "top-right" })
        }

    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('sidebar.utilisateurs')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">   {t('sidebar.ajoutUser')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/users/parametrage" className="btn btn-azure fs-12 btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-settings mr-1" />
                            {t('sidebar.parametrageUtilisateur')}
                        </a>
                        <a href="/users/liste" className="btn btn-azure  fs-12 btn-icon-text mr-2 d-none d-md-block">
                            <i className="fe fe-download" />
                            {t('sidebar.listeUtilisateurs')}
                        </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="row">
                <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                    {/** ----------- code / code barre produits ------------------ */}
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-2">  {t('users.coordonneeuser')} </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {/*    <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.code')} : <span className="text-danger"> * </span></label>

                                        <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                    </div>
                                </div> */}

                             
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('users.nomprenom')} : <span className="text-danger"> * </span></label>

                                        <input type="text" value={nom} onChange={(e) => setnom(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('users.phone')} : <span className="text-danger"> * </span></label>

                                        <input type="number" value={telephone} onChange={(e) => settelephone(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('users.E-Mail')} : <span className="text-danger"> * </span></label>

                                        <input type="email" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('users.cin')}</label>

                                        <input type="text" value={cin} onChange={(e) => setCin(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Routings.adresse')} : <span className="text-danger"> * </span></label>

                                        <input type="email" value={adresse} onChange={(e) => setadresse(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <label className="form-control-label">{t('users.userimage')} </label>
                                    <input type="file" name='image' onChange={(e) => selectFile_image(e)} className="dropify" data-height={180} />
                                    {image?.preview !== "" ? <img src={image.preview} alt="image_produit" height={400} /> : ""}

                                </div>
                            </div>
                            <br />

                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                    {/** ----------- config  produits ------------------ */}  <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-2"> {t('users.parametrelocalite')}  </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('users.motdepasse')} : <span className="text-danger"> * </span></label>
                                        <input type="password" value={password} onChange={(e) => setpassword(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Routings.ACTIF')}</label>
                                        <select value={actif} onChange={(e) => setActif(e.target.value)} className="form-control   br-md-0">
                                            <option value={''}> Choisir</option>
                                            <option value={0}> {t('rapports.non')}  </option>
                                            <option value={1}> {t('rapports.oui')}   </option>
                                        </select>

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Produits.Societe')} : <span className="text-danger"> * </span></label>
                                        <select value={codeSociete} onChange={(e) => setcodeSociete(e.target.value)} className="form-control select2 custom-select br-md-0">
                                            <option value={-1}> {t('Produits.Societe')} ... </option>

                                            {listeSociete?.map((items, keys) => (

                                                <option value={items.code} key={keys}>{items.libelle} </option>

                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Zoning.Pays')} : <span className="text-danger"> * </span></label>
                                        <select value={codePays} onChange={(e) => setcodePays(e.target.value)} className="form-control select2 custom-select br-md-0">
                                            <option value={-1} > {t('Zoning.Pays')} ... </option>
                                            {listePays?.map((items, keys) => (
                                                <option value={items.code} key={keys}> {items.libelle} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('dashboard.region')} : <span className="text-danger"> * </span></label>
                                        <select value={codeRegions} onChange={(e) => setcodeRegions(e.target.value)} className="form-control select2 custom-select br-md-0">
                                            <option value={-1} > {t('dashboard.region')}  ... </option>
                                            {listeRegions?.map((items, keys) => (
                                                <option value={items.code} key={keys}>{items.libelle} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.Zones')}  : <span className="text-danger"> * </span></label>
                                        <select value={codeZone} onChange={(e) => setcodeZone(e.target.value)} className="form-control select2 custom-select br-md-0">
                                            <option value={-1} > {t('rapports.Zones')} ... </option>
                                            {listeZones?.map((items, keys) => (
                                                <option value={items.code} key={keys}>{items.libelle} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('roles')}  : <span className="text-danger"> * </span></label>
                                        <select value={codeRoles} onChange={(e) => setcodeRoles(e.target.value)} className="form-control select2 custom-select br-md-0">
                                            <option value={-1} > {t('roles')} ... </option>
                                            {role?.map((items, keys) => (
                                                <option value={items.code} key={keys}>{items.libelle} </option>
                                            ))}
                                        </select>                                         
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('synchronisation')}</label>
                                        <select
                                            value={sync} onChange={(e) => setSync(e.target.value)} className="form-control    br-md-0 fs-12">
                                             <option value={""}> -- {t('synchronisation')} --  </option>
                                            <option value={"route"}> {t('Routings.Route')}  </option>
                                            <option value={"total"}> {t('rapports.TOTAL')}   </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <button className="btn btn-primary float-right  " onClick={() => saveData()}> <i className="ti-save mr-2" />     {t('sidebar.ajoutUser')}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
