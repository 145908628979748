/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { nbrContactParheure } from '../../../services/Rapports/RapportsApi'
import {Chart as ChartJS,PointElement,LineElement,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export default function ContactParHeure({ t, code, pdv_code, today }) {

    const [loading, setloadign] = useState(true)

    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] // init Hour 
    const quantite = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] // Initi quantité 0 
    const [data, setdata] = useState([])
    useEffect(() => {
        //nbrContactParheure
        nbrContactParheure(code, today, pdv_code).then((result) => {
            if (result?.data?.success === 1) {
                console.log(result.data?.nbrContactHeure)
                hours?.map((i, k) => {
                    result.data?.nbrContactHeure?.map((items) => {
                        if (i === items.timeslot) {
                            quantite[k] = items.totalVendu
                        }
                    })
                })
                var arr = []
                arr.push({
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 1,
                    data: quantite,
                    label: "Quantité Vendu par heure"
                })
                // hours?.map(())
                setdata(arr)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloadign(false)
        })
    }, [])

    return (
        <div>
            <div className='card'>
                <div className='card-header mt-3 text-azure'>
                    <h6> Contact par heure avec achat  </h6>
                </div>
                <div className='card-body'>
                    {loading ? <SqaureLoader /> :
                        <Bar
                            data={{
                                labels: hours,
                                datasets: data
                            }}

                            options={{
                                plugins: {
                                    legend: {
                                        possition: 'top',
                                    },
                                    title: {
                                        display: true,
                                        text: "Quantité Vendu par heure"
                                    }
                                },
                                scales: {
                                    x: {
                                        display: true,
                                        title: {
                                            display: true,
                                            text: "Heure",
                                            font: {
                                                family: 'Poppins',
                                                size: 16,
                                                style: 'normal',
                                                lineHeight: 1.2
                                            },
                                            padding: { top: 20, left: 0, right: 0, bottom: 0 }
                                        }
                                    },
                                    y: {
                                        display: true,
                                        title: {
                                            display: true,
                                            text: t('Produits.Quantite'),
                                            font: {
                                                family: 'Poppins',
                                                size: 16,
                                                style: 'normal',
                                                lineHeight: 1.2
                                            },
                                            padding: { top: 30, left: 0, right: 0, bottom: 0 }
                                        }
                                    }
                                }
                            }}
                        />

                    }
                </div>
            </div>


        </div>
    )
}
