import React from "react";

export default function InfoCard() {
  return (
    <div className="alert bg-purple mb-5 mt-4 py-4" role="alert">
      <div className="d-flex">
        <i className="icon icon-info mr-3 fs-30" />
        <div>
          <h4 className="alert-heading">
            Veuillez sélectionner un merchandiser
          </h4>
          <p>
            Pour afficher les notes d'évaluation des merchandiseurs vous devez choisir le Mois et le merchandiseur.
          </p>
        </div>
      </div>
    </div>
  );
}
