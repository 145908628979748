import React from 'react'

export default function TableGestionStock({listeOfData ,listeContacts ,index , show , t , setindex , setShow , setcodeContact}) {
  return (
    <table id="table-to-xls"  className="table  table-bordered ">
    <thead className="text-center bg-azure-lighter">
        <tr><th></th>
            <th className="w-15p fs-10">{t('rapports.Magasins')}</th>
            <th className="w-15p fs-10">{t('Magasins.MERCH')}</th>
            <th className="w-15p fs-10">   {t('contacts.contacts')} </th>
        </tr>
    </thead>
    <tbody className='text-center'>
        {listeContacts?.map((items,keys)=>(
            <>
            <tr key={keys}>
            <td className='fs-10'> {index === keys && show ? <i className='ti-angle-up' onClick={() => { setindex(-1); setShow(false) }} /> : <i className='ti-angle-down' onClick={() => { setindex(keys); setShow(true) ;setcodeContact(items.code)}} />} </td>
                <td> {items.nompdv} </td>
                <td> {items.nom} </td>
                <td> {items.qte === 0 ? 0 :items.total_contact } </td>
            </tr>
            {index === keys && show ?
                <tr>
                    <td colSpan="10">
                        <table className="table table-striped table-hover">
                            <thead className="text-center ">
                                <th className="fs-10" >{t('rapports.Magasins')}</th>
                                <th className="fs-10" >{t('contacts.codeProduit')}</th>
                                <th className="fs-10" >{t('contacts.produit')}</th>
                                <th className="fs-10" >{t('contacts.codeContacts')}</th>
                                <th className="w-15p fs-10">{t('contacts.qteConculante')}</th>

                            </thead>
                            <tbody className="text-center ">
                             {listeOfData()}
                            </tbody>
                        </table>
                    </td>
                </tr> : null }
            </>

        ))}
    </tbody>
</table>
  )
}
