import React from 'react'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';

export default function ModalPartLineaire({ listepartlineaire,t, loading,listeHeaders,currentListe,listeFamilles  }) {
  return (
    <div className="modal fade" id="exportPartLineaire" tabIndex={-1} role="dialog" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title text-azure fs-12" id="ListePDV"> {t('listepartlineaire')} </h5>
                <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close"  /> </span>
                
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="table-responsive  product-datatable"  style={{ overflow: 'auto', height: '500px' }}>
                   
                    <table id="table-pl" className="table  table-bordered ">
                                <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                   
                                    <tr>
                                    <th className="w-15p fs-10"> {t('rapportrupture.date')} </th>
                                    <th className="wd-15p fs-10">{t('dashboard.Merchandiseurs')}</th>
                                    
                                    <th className="wd-15p fs-10">{t('rapports.Enseignes')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.Magasin')}</th>
                                    
                                    {/* <th className="wd-15p fs-10">{t('rapports.facing')}</th> */}
                                    <th className="wd-15p fs-10">{t('rapports.facingtotal')}</th>
                                    <th className="wd-15p fs-10">{listeHeaders?.societe} (PL %)</th>
                                    {listeFamilles?.map((items, keys) => (
                                          <th className="wd-15p fs-10">{items.libelle}</th>
                                    ))}
                                    
                                  
                                    </tr>
                                </thead>
                                {loading ? <SqaureLoader /> : (
                                    <tbody>
                                        {currentListe?.map((items, itemsKey) => (
                                        <tr key={itemsKey} className={itemsKey% 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"}>
                                        <td>{moment(items.date).format("DD/MM/YYYY")}</td>
                                        <td>{items.merch}</td>
                                        <td>{items.enseignes}</td>
                                        <td>{items.magasin}</td>
                                        <td>{items.lineaire}</td>
                                        <td>{parseFloat(( (items.sumtotal/items.lineaire) / listeFamilles.length) * 100).toFixed(2)} %</td>
                                            {listeFamilles?.map((fam, famKey) => (
                                            <td key={famKey}>
                                             {listepartlineaire?.map((part, partKey) => (
                                                  part.famille_code === fam.code && part.pdv_code=== items.pdv_code ? (
                                                    part.facing
                                                  ) : (
                                                    ""
                                                  )
                                            ))} %
                                            </td>
                                        ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                )}

                            </table>
                    </div>
                </div>
            </div>
         
            <div className="modal-footer ">
            <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-azure fs-10"
                    table="table-pl"
                    filename="liste-partLineaire"
                    sheet="tablexls"
                    buttonText={<span className=" text-center"> <i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')}</span>}
                />
                <button type="button" className="btn btn-secondary fs-10" data-dismiss="modal" > <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
            </div>
        </div>
    </div>
</div>
  )
}
