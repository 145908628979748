
import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

export async function getListeConge() {
    try {
      const response = await axios.get(url + "conge/", { headers: authHeader() }, {
        IsEssential: true
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  }
  export async function addconge(data) {
    try {
        const response = await axios.post(url + "conge/",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function updateCongee(data,code) {
    try {
        const response = await axios.patch(url + "conge/"+code,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function deleteConge(code) {
    try {
        const response = await axios.delete(url + "conge/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}