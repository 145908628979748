/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getHistoriques, historiqueAssortiments, ruptureGamme } from '../../../services/Magasins/PdvApi'
import CardGeneralInformations from './CardGeneralInformations'
import HistoriqueMensuel from './HistoriqueMensuel'
import MapMagasin from './MapMagasin'
import RuptureGamme from './RuptureGamme'
import RuptureProduits from './RuptureProduits'
import RuptureProduitsMois from './RuptureProduitsMois'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { parseInt } from 'lodash'
import { generateRandomColor } from '../../../utils/Colors'

export default function HistoriqueMagasins() {

    const { code } = useParams("")
    const [historique, setHistorique] = useState({})
    const [loading, setloading] = useState(true)
    const [latitude, setlatitude] = useState("")
    const [longitude, setlongitude] = useState("")
    const [ruptureProduit, setruptureProduit] = useState([]) // par mois 
    const [saveRupture, setsaveRupture] = useState([])// rupture produits / mois
    const [nbrRuptureMois, setnbrRuptureMois] = useState([])
    const [produits, setProduits] = useState([])
    const [nbrBisite, setnbrBisite] = useState([])
    const [saveProduits, setsaveProduits] = useState([])
    const [partLineaire, setpartLineaire] = useState([])
    const [assortiments, setAssortiments] = useState([])
    const [totalImages, settotalImages] = useState(0)
    //-------------- Previous Year -------------------

    const [nbrRuptureMois2, setnbrRuptureMois2] = useState([])
    const [partLineaire2, setpartLineaire2] = useState([])
    const [nbrBisite2, setnbrBisite2] = useState([])
    const [assortiments2, setAssortiments2] = useState([])

    const liste = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const [year, setYear] = useState(moment(firstDay).format('YYYY'))
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        //---------- Previous Year ------------------
        getHistoriques(code, parseInt(year)-1)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setnbrBisite2(result?.data?.nbrVisiteMois)
                    setnbrRuptureMois2(result?.data?.nbrRuptureMois)
                    setpartLineaire2(result?.data?.partlineaire)
                    historiqueAssortiments(year,result?.data?.historique?.categorie_code,result?.data?.historique?.enseigne_code)
                    .then((result) => {
                        if(result?.data?.success===1){
                            setAssortiments2(result?.data?.assortiments)
                        }
                    }).catch((err) => {
                            console.log(err)
                    });
                }
            }).catch((err) => {
                console.log(err)
            })
           
        getHistoriques(code, year)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlatitude(result?.data?.localisation?.latitude)
                    setlongitude(result?.data?.localisation?.logitude)
                    setHistorique(result?.data?.historique)
                    setruptureProduit(result?.data?.listeProduits)
                    setsaveRupture(result?.data?.listeProduits)
                    setProduits(result?.data?.ruptureProduit)
                    setsaveProduits(result?.data?.ruptureProduit)
                    setnbrBisite(result?.data?.nbrVisiteMois)
                    setnbrRuptureMois(result?.data?.nbrRuptureMois)
                    setpartLineaire(result?.data?.partlineaire)
                    settotalImages(result?.data?.totalImages?.totalimages)
                    historiqueAssortiments(year,result?.data?.historique?.categorie_code,result?.data?.historique?.enseigne_code)
                    .then((result) => {
                        if(result?.data?.success===1){
                            setAssortiments(result?.data?.assortiments)
                        }
                    }).catch((err) => {
                            console.log(err)
                    });
                
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [])
    const onChangeYear = (e) => {
        setloading(true)
        setYear(e.target.value)

        getHistoriques(code, parseInt(e.target.value)-1)
        .then((result) => {
            if (result?.data?.success === 1) {
      
                setnbrBisite2(result?.data?.nbrVisiteMois)
                setnbrRuptureMois2(result?.data?.nbrRuptureMois)
                setpartLineaire2(result?.data?.partlineaire)
                historiqueAssortiments(year,result?.data?.historique?.categorie_code,result?.data?.historique?.enseigne_code)
                .then((result) => {
                    if(result?.data?.success===1){
                        setAssortiments2(result?.data?.assortiments)
                    }
                }).catch((err) => {
                        console.log(err)
                });
            }
        }).catch((err) => {
            console.log(err)
        })
      
        getHistoriques(code,  e.target.value)
            .then((result) => {
                if (result?.data?.success === 1) {
          
                    setlatitude(result?.data?.localisation?.latitude)
                    setlongitude(result?.data?.localisation?.logitude)
                    setHistorique(result?.data?.historique)
                    setruptureProduit(result?.data?.listeProduits)
                    setsaveRupture(result?.data?.listeProduits)
                    setProduits(result?.data?.ruptureProduit)
                    setsaveProduits(result?.data?.ruptureProduit)
                    setnbrBisite(result?.data?.nbrVisiteMois)
                    setnbrRuptureMois(result?.data?.nbrRuptureMois)
                    setpartLineaire(result?.data?.partlineaire)
                    historiqueAssortiments(year,result?.data?.historique?.categorie_code,result?.data?.historique?.enseigne_code)
                    .then((result) => {
                        if(result?.data?.success===1){
                            setAssortiments(result?.data?.assortiments)
                        }
                    }).catch((err) => {
                            console.log(err)
                    });
                   
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }
    return (

        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item fs-12"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Magasins')} </a></li>
                    <li className="breadcrumb-item active fs-12" aria-current="page">{t('historiqueMagasin.historiquemagasin')} : {code} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                <a className='btn btn-azure text-white fs-10 mt-1' href={"/pdv/historiqueImages&code="+code+"&annee="+year} >{totalImages} {t('contacts.images')} </a>

                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-4' />
                <div className='col-4'>
                    <select name="annee" className="form-control select2 custom-select text-center" value={year} onChange={(e) => onChangeYear(e)}>
                        <option value>{t('conventions.annee')}</option>
                        <option value={2023}>2023</option>
                        <option value={2022}>2022</option>
                        <option value={2021}>2021</option>
                        <option value={2020}>2020</option>
                        <option value={2019}>2019</option>
                        <option value={2018}>2018</option>
                    </select>
                    
                </div>
                <div className='col-4' />

            </div>
            <div className="row">
                {/** --------------- Tableau Historiques ----------------------- */}
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <CardGeneralInformations historique={historique} loading={loading} t={t} />
                </div>
                {/** --------------- Details Historiques ----------------------- */}
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <HistoriqueMensuel pdv_code={code} t={t} year={year} loading={loading} nbrvisite={nbrBisite} nbrvisite2={nbrBisite2} nbrRuptureMois2={nbrRuptureMois2} nbrRuptureMois={nbrRuptureMois} dateYear={year} liste={liste} assortiments={assortiments}  assortiments2={assortiments2} partLineaire={partLineaire} partLineaire2={partLineaire2} />
                </div>
                {/** CARD LOCALISATION / RUPTURE PRODUITS / CHART RUPTURE GAMME  */}
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                    <MapMagasin t={t} Magasin={historique?.nompdv} latitude={latitude} longitude={longitude} />
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                    <RuptureProduits t={t} produits={produits} loading={loading} setProduits={setProduits} saveProduits={saveProduits} />
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <RuptureGamme t={t} code={code} year={year} />
                </div>
                {/** --------------- Tableau Historiques ----------------------- */}
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <RuptureProduitsMois year={year} pdv_code={code} t={t} ruptureProduit={ruptureProduit} saveRupture={saveRupture} setruptureProduit={setruptureProduit} loading={loading} />
                </div>
            </div>
        </div>
    )
}
