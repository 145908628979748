import React, { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { addconge } from '../../services/Conge/CongeAPI';
import { getListeUsers } from '../../services/Users/UsersApi';
import { myRnId } from '../../utils/DateUtils';

export default function AddConge() {
    //-------- FORMULAIRE D AUTHENTIFICATION
    const { register, handleSubmit } = useForm();
    const [codeUser, setcodeUser] = useState("")
    const [listeUser, setlisteUser] = useState([])
    //----------Loading -----------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    var code = "Conge-" + myRnId()
    const searchInput = useRef();


    useEffect(() => {
        setloading(true)
        getListeUsers().then((result) => {
            if (result?.data?.success === 1) {
                var liste = result?.data?.data
               /*  liste.push({
                    value: "all",
                    name: "Tout les utilisateurs"
                }) */
                setlisteUser(liste)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }, [])
    const handleChangeUser = (...args) => {
        setcodeUser(args[1].value)
    };
    const saveData = (data) => {
        Object.assign(data, {
            code: code,
            user_code: codeUser,
        })
        const arr = []
        arr.push(data)
        addconge(arr).then((result) => {
            if (result?.data?.success === 1) {
                toast("Congé ajouté avec succès !", { position: "top-right", type: "success" })
            }
        }).catch((err) => {
            console.log(err)
        });
        console.log(data)

    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="#">
                            <i className="fe fe-life-buoy mr-1" />
                            Congé
                        </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Ajouter un congé
                    </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a
                            href="/users/conge"
                            className="btn btn-azure btn-icon-text fs-12  mr-2 d-none d-md-block"
                        >
                            <i className="ti-plus mr-1" />
                            Liste des congés
                        </a>
                    </div>
                </div>
            </div>
            <div className="card-body">
                {loading ? <Loader /> : (
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className='card'>

                                <div className='card-body'>    <form className="form-horizontal" onSubmit={handleSubmit(saveData)}>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-form-label">Code</label>
                                        <div className="col-md-9">
                                            <input type="text"

                                                className="form-control" value={code} disabled />
                                        </div>
                                    </div>
                                    <ToastContainer />
                                 
                                    <div className="form-group row">
                                        <label className="col-md-3 col-form-label">Date debut</label>
                                        <div className="col-md-9">
                                            <input type="date" className="form-control" {...register('date_debut')} required />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-form-label">Date fin</label>
                                        <div className="col-md-9">
                                            <input type="date" className="form-control"  {...register('date_fin')} required />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-form-label">Merchandiseur</label>
                                        <div className="col-md-9">

                                            <SelectSearch
                                                options={listeUser}
                                                search
                                                ref={searchInput}
                                                onChange={handleChangeUser}
                                                filterOptions={fuzzySearch}
                                                emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                                placeholder="Choisir un utilisateur"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-form-label">Raison</label>
                                        <div className="col-md-9">
                                            <textarea type="text" className="form-control" placeholder='raisons ...' {...register('raison')} required />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-3 col-form-label">Nbr Jours</label>
                                        <div className="col-md-9">
                                            <input type="number" className="form-control" placeholder='nombre de jours'  {...register('nbrjour')} required />
                                        </div>
                                    </div>
                                 
                                    <div className='card-footer'>
                                        <button className="btn btn-azure text-white mr-2 float-right" type='submit'><i className="ti-save mr-1" /> Enregister</button>
                                    </div>
                                </form></div>

                            </div>

                        </div>

                    </div>
                )}

            </div>
        </div>
    )
}
