/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getListedelegations, getListeSecteurs, getListZones, postObjectLocalite } from '../../../services/Zoning/ZoningApi'
import { myRnId } from '../../../utils/DateUtils'

export default function ModalAddLocalite({ setShow, t }) {
    //------ Formulaire -----------
    const [code, setCode] = useState("")
    const [libelle, setlibelle] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [codeDelegation, setcodeDelegation] = useState(0)
    const [codeSecteurs, setcodeSecteurs] = useState(0)
    const [codeZones, setcodeZones] = useState(0)
    //  Liste des delegations 
    const [listeDelegation, setlisteDelegation] = useState([])
    const [listeSecteurs, setlisteSecteurs] = useState([])
    const [listeZones, setlisteZones] = useState([])

    useEffect(() => {
        getListedelegations()
            .then((result) => {
                setlisteDelegation(result?.data?.data)
            }).catch((err) => {
                console.log(err)
            })
        getListeSecteurs()
            .then((result) => {
                setlisteSecteurs(result?.data?.data)
            }).catch((err) => {
                console.log(err)
            })
        getListZones()
            .then((result) => {
                setlisteZones(result?.data?.data)
            }).catch((err) => {
                console.log(err)
            })
    }, [])
    const traitement = (data) =>{
        postObjectLocalite(data)
        .then((result) => {
            if (result.data.success === 0) {
                toast(t('Zoning.Erreur'), {
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else if (result.data.success === 1) {
                toast(t('Zoning.messagelocalite'), {
                    type: "success",
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const saveData = () => {
        if (code === "" && libelle !== "" && codePostal !== "" && codeZones !== 0 && codeDelegation !== 0 && codeSecteurs !== 0) {
            var generateCode = libelle.slice(0, 3)
            setCode(generateCode + "-" + myRnId())
            const data = {
                code: generateCode + "-" + myRnId(),
                libelle: libelle,
                cp: codePostal,
                delegation_code: codeDelegation,
                zone_code: codeZones,
                secteur_code: codeSecteurs
            }
            traitement(data)
        }else if (code !== "" && libelle !== "" && codePostal !== "" && codeZones !== 0 && codeDelegation !== 0 && codeSecteurs !== 0) {
            const data = {
                code: code,
                libelle: libelle,
                cp: codePostal,
                delegation_code: codeDelegation,
                zone_code: codeZones,
                secteur_code: codeSecteurs
            }
            traitement(data)
        }
    }
    return (
        <div className="modal fade" id="exampleModa20" tabIndex={-1} role="dialog" aria-hidden="true" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-azure" id="example-Modal3"> <i className='icon icon-map mr-1' /> {t('Zoning.ajouterlocalite')} </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} /> </span>

                    </div>
                    <div className="modal-body">
                        <form>
                            <div className='row'>
                                <div className=" form-group col-6">
                                    <label className="form-control-label">{t('rapports.code')} <span className="text-danger">*</span></label>
                                    <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="form-control-label"> {t('Zoning.Codepostal')} <span className="text-danger">*</span></label>
                                    <input type="text" value={codePostal} onChange={(e) => setCodePostal(e.target.value)} className="form-control" />
                                </div>
                            </div>

                            <div className="form-group ">
                                <label className="form-control-label"> {t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control" />
                            </div>
                            <div className='row'>
                          
                            <div className="form-group col-4">
                            <label className="mr-2 mt-1 mb-sm-1"> {t('Zoning.secteur')} <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codeSecteurs}
                                    onChange={(e) => {
                                        setcodeSecteurs(e.target.value)
                                    }}>
                                    <option value={0}> {t('Zoning.choisirsecteur')}  </option>
                                    {listeSecteurs?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group col-4">
                            <label className="mr-2 mt-1 mb-sm-1"> Delegations <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codeDelegation}
                                    onChange={(e) => {
                                        setcodeDelegation(e.target.value)
                                    }}>
                                    <option value={0}>  {t('Zoning.choisirdelegation')}  </option>
                                    {listeDelegation?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                            <div className='form-group col-4'>
                            <label className="mr-2 mt-1 mb-sm-1"> Zones <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codeZones}
                                    onChange={(e) => {
                                        setcodeZones(e.target.value)
                                    }}>
                                    <option value={0}> {t('Zoning.choisirzone')}  </option>
                                    {listeZones?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer ">
                        <button type="button" className="btn btn-azure fs-12 " onClick={() => saveData()} > <i className='icon icon-cloud-download mr-1' />Enregister </button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" onClick={() => setShow(false)}> <i className="ti-close mr-1" />Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
