import React from 'react'

export default function LoaderTable() {
    return (
        <div className="card-body text-center">
            <div className="lds-grid"><div /><div /><div /><div /><div /><div /><div /><div /><div /></div>
        </div>

    )
}
