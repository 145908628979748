import React from 'react'

export default function Profile({detailsUser,t}) {
  return (
    <div className="tab-pane active show" id="tab-51">
    <div id="profile-log-switch">
        <div className="table-responsive mb-5">
            <table className="table row table-borderless w-100 m-0 border">
                <tbody className="col-lg-6 p-0">
                    <tr>
                        <td><strong>{t('nomprenom')}:</strong> {detailsUser?.nom}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("Pays et zone")} :</strong> {detailsUser?.paye+" , "+detailsUser?.zone}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("Rôle")} :</strong> {detailsUser?.role}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("Produits.SOCIETE")} :</strong> {detailsUser?.societe} </td>
                    </tr>
                </tbody>
                <tbody className="col-lg-6 p-0">
                    <tr>
                        <td><strong>{t("E-Mail")} :</strong>  {detailsUser?.email}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("Télèphone")} :</strong>  {detailsUser?.telephone} </td>
                    </tr>
                    <tr>
                        <td><strong>{t("Routings.adresse")} :</strong>  {detailsUser?.adresse}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("users.cin")} :</strong>  {detailsUser?.cin}</td>
                    </tr>
                </tbody>
            </table>
        </div>
 
    </div>
</div>
  )
}
