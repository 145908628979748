import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader';

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
const role = user?.result?.role_code
const user_code = user?.result?.code


/**
 * liste Entete
 * @returns 
 */
 export async function getAffectationsUsers() {
    try {
        const response = await axios.get(url + "affectations/users/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

export async function getAffectationsZones() {
    try {
        const response = await axios.get(url + "affectations/zones/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function getUsersZones() {
    try {
        const response = await axios.get(url + "affectations/zones/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

export async function getChefsUsers() {
    try {
        const response = await axios.get(url + "affectations/users/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

export async function addUsers(data) {
    try {
        const response = await axios.post(url + "affectations/users/add",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function addZones(data) {
    try {
        const response = await axios.post(url + "affectations/zones/add",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}