/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../../components/Loader/Loader'
import { getListeFamilles, getListeGammes, getListeMarque, getListeSocietes, getListeSousFamilles } from '../../../services/Produits/configurationProduits/ConfigurationAPI'
import { getProduitsbyCode, updateProduits } from '../../../services/Produits/ProduitsAPI'

export default function ModifierProduits() {
    const { id } = useParams("")
    //-------- FORMULAIRE ADD PRODUCT---------
    const { register, handleSubmit, reset } = useForm();
    //------ Formulaire -----------
    const [data, setData] = useState(null)

    const [listeMarque, setlisteMarque] = useState([])
    const [listeGammes, setlisteGammes] = useState([])
    const [listeSociete, setlisteSociete] = useState([])
    const [listeFamilles, setlisteFamilles] = useState([])
    const [listeSousFamilles, setlisteSousFamilles] = useState([])
    //add  image produits aux 
    const [image, setImage] = useState({ preview: "", raw: "" });
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    // Loader
    const [loading, setloading] = useState(true)

    const [codeMarque, setcodeMarque] = useState("")
    const [codeSociete, setcodeSociete] = useState("")
    const [codeFamille, setcodeFamille] = useState("")
    const [codeSFamille, setcodeSFamille] = useState("")
    const [codeGammes, setcodeGammes] = useState("")

    //UPLOAD IMAGE WITH PREVIEW image Commerce
    const selectFile_image = (event) => {
        if (event.target.files.length) {
            setImage({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0]
            })
        }
    }

    useEffect(() => {

        setloading(true)
        getProduitsbyCode(id)
            .then((result) => {
                const data = result?.data?.data[0]
                setcodeMarque(data?.marque_code)
                setcodeFamille(data?.famille_code)
                setcodeGammes(data?.gamme_code)
                setcodeSociete(data?.societe_code)
                setcodeSFamille(data?.sousfamille_code)
                setData(data)
                setImage({ preview: data.image })
            }).catch((err) => {
                console.log(err)

            });
        getListeMarque()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteMarque(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getListeFamilles()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteFamilles(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getListeSocietes()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteSociete(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getListeSousFamilles()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteSousFamilles(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getListeGammes()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteGammes(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            })

        setloading(false)

    }, [])


    useEffect(() => {
        reset(data)
    }, [data])
    
    /** Modifier un produit  */
    const ModifierProduits = (data) => {
        if (image.raw !== "") {
            Object.assign(data, {
                image: image.raw,
            })
        }
        if(codeMarque!=="" ||codeMarque !== null){
            Object.assign(data, {
                marque_code: codeMarque,
            })
        }
        if(codeFamille!=="" ||codeFamille !== null){
            Object.assign(data, {
                famille_code: codeFamille,
            })
        }
        if(codeSociete!=="" ||codeSociete !== null){
            Object.assign(data, {
                societe_code: codeSociete,
            })
        }
        if(codeSFamille!=="" ||codeSFamille !== null){
            Object.assign(data, {
                sousfamille_code: codeSFamille,
            })
        }
        if(codeGammes!=="" ||codeGammes !== null){
            Object.assign(data, {
                gamme_code: codeGammes,
            })
        }
        //web service add product
        updateProduits(id, data)
            .then((result) => {
                if (result?.data?.success === 0) {
                    toast(t('messageErreur.erreur'), { type: 'warning', position: 'top-right' })
                } else if (result?.data.success === 1) {
                    toast(t('messageErreur.produitmodifie'), { type: 'success', position: 'top-right' })
                }
            }).catch((err) => {
                console.log(err)
            });


    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/produits/liste"><i className="fe fe-life-buoy mr-1" /> {t('Produits.Produits')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Produits.modifierProduit')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/produits/liste" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="fe fe-download" />
                            {t('Produits.Listeproduits')}
                        </a>
                        <a href="/produits/Configurations" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="fe fe-download" />
                            {t('Produits.ConfigurationProduits')}
                        </a>
                        <a href="/produits/codeMETI" className="btn btn-azure btn-icon-text mr-2 mb-2 mb-md-0 fs-12" >
                            <i className="fe fe-printer" />
                            {t('sidebar.configCodeMETI')}
                        </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            {loading ? <Loader /> : (
                <form onSubmit={handleSubmit(ModifierProduits)}>

                    <div className="row">
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                            {/** ----------- code / code barre produits ------------------ */}
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title mt-2"> {t('Produits.DescriptionProduits')} </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('rapports.code')}  : <span className="text-danger"> * </span></label>

                                                <input type="text"  {...register('code')} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.CODEABARRE')}</label>

                                                <input type="text" {...register('code_a_barre')} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.Libelle')} : <span className="text-danger"> * </span></label>

                                                <input type="text" {...register('libelle')} className="form-control" required />
                                            </div>
                                        </div>
                                     {/*    <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">Type : <span className="text-danger"> * </span></label>
                                                <select name="type" {...register('type')} required
                                                    className="form-control  text-muted  br-md-0">
                                                    <option>Choisir </option>
                                                    <option value={"equipement"} selected> Equipement </option>
                                                    <option value={"produit"} selected> Produit </option>
                                                </select>                                          </div>
                                        </div> */}
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-control-label">Qte. Min</label>
                                                <input type="number" {...register('qte_min')} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-control-label">Qte. Max</label>
                                                <input type="number" {...register('qte_max')} className="form-control" required />

                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.Actif')}</label>
                                                <select name="actif" {...register('actif')} required className="form-control  text-muted select2 custom-select br-md-0">
                                                    <option>Choisir </option>
                                                    <option value={1} selected> {t('rapports.oui')} </option>
                                                    <option value={""} selected> Non </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.Unite')} : <span className="text-danger"> * </span></label>

                                                <input type="text" {...register('unite')} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.Colisage')} : <span className="text-danger"> * </span></label>
                                                <input type="text" {...register('colisage')} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.PrixAchat')} <span className="text-danger"> * </span></label>

                                                <input type="text" {...register('prix_achat')} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.PrixHT')} : <span className="text-danger"> * </span></label>

                                                <input type="text" {...register('prix_ht')} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('rapports.prix')} : <span className="text-danger"> * </span></label>

                                                <input type="text" {...register('prix')} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-control-label">Prix. Min</label>
                                                <input type="number" {...register('prix_min')} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-control-label">Prix. Max</label>
                                                <input type="number" {...register('prix_max')} className="form-control" required />

                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-sm-12">
                                    <label className="form-control-label">{t('Produits.imageproduit')}</label>

                                    <input type="file" name='image' onChange={(e) => selectFile_image(e)}  className="dropify" data-height={180} />
                                    {image?.preview !== "" ? <img src={image.preview} alt="image_produit" height={400} />: ""}
                                </div>

                                    </div>
                                    <br />

                                </div>

                            </div>


                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                            {/** ----------- config  produits ------------------ */}
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title mt-2">{t('sidebar.configProduits')}  </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('relevePrix.marque')} : <span className="text-danger"> * </span></label>
                                                <select name="marque_code" onChange={(e)=>setcodeMarque(e.target.value) } value={codeMarque} required className="form-control  text-muted  br-md-0">
                                                    <option value={""}> {t('relevePrix.marque')} ... </option>
                                                    {listeMarque?.map((items, keys) => (
                                                        <option value={items.code} key={keys}>{items.libelle} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.SOCIETE')} : <span className="text-danger"> * </span></label>
                                                <select name="societe_code" onChange={(e)=>codeSociete(e.target.value) } value={codeSociete} required className="form-control  text-muted select2 custom-select br-md-0">
                                                    <option value={""}>{t('Produits.SOCIETE')} ...</option>
                                                    {listeSociete?.map((items, keys) => (
                                                        <option value={items.code} key={keys}>{items.libelle} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.Gamme')} : <span className="text-danger"> * </span></label>
                                                <select name="gamme_code" onChange={(e)=>codeGammes(e.target.value) } value={codeGammes} required className="form-control  text-muted  br-md-0">
                                                    <option value={""}>{t('Produits.Gamme')} ...</option>
                                                    {listeGammes?.map((items, keys) => (
                                                        <option value={items.code} key={keys}>{items.libelle} </option>
                                                    ))}
                                                </select>                                    </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label"> {t('Produits.FAMILLE')} : <span className="text-danger"> * </span></label>
                                                <select name="famille_code" onChange={(e)=>setcodeFamille(e.target.value) } value={codeFamille} required className="form-control  text-muted  br-md-0">
                                                    <option value={""}>  {t('Produits.FAMILLE')} ... </option>
                                                    {listeFamilles?.map((items, keys) => (
                                                        <option value={items.code} key={keys}>{items.libelle} </option>
                                                    ))}
                                                </select>                                    </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">{t('Produits.S.FAMILLE')} : <span className="text-danger"> * </span></label>
                                                <select name="sousfamille_code" onChange={(e)=>setcodeSFamille(e.target.value) } value={codeSFamille} required className="form-control  text-muted  br-md-0">
                                                    <option value={""}> {t('Produits.S.FAMILLE')} ... </option>
                                                    {listeSousFamilles?.map((items, keys) => (
                                                        <option value={items.code} key={keys}>{items.libelle} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <br />
                                    <button className="btn btn-primary float-right  " type="submit "> <i className="ti-save mr-2" />  Modifier </button>
                                </div>
                            </div>

                        </div>

                    </div></form>
            )}


        </div>
    )
}
