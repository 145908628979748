/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import {toast, ToastContainer } from 'react-toastify'
import SelectSearch, { fuzzySearch } from 'react-select-search';    
import { getSelctEnseignes } from '../../../services/Produits/ProduitsAPI';
import { getSelectCategorie, getSelectGouvernorat, getSelectRegions, getSelectRoute, getSelectTypePdv,addMagasin } from '../../../services/Magasins/PdvApi';
import { getSelectLocalite, getSelectZone } from '../../../services/Zoning/ZoningApi';
import { useTranslation } from 'react-i18next';
import { myRnId } from '../../../utils/DateUtils';

export default function AjouterPdv() {

    //-------------------- FORMULAIRE -------------------
    const [code, setcode] = useState("PDV-"+myRnId())
    const [codeErp, setcodeErp] = useState("")
    const [nom, setnom] = useState("")
    const [adresse, setadresse] = useState("")
    const [adresseLivraison, setadresseLivraison] = useState("")
    const [description, setDescription] = useState("")
    const [codeTypeMagasin, setcodeTypeMagasin] = useState("")
    const [codeCategorie, setcodeCategorie] = useState("")
    const [codeEnseigne, setcodeEnseigne] = useState("")
    //const [codeAffectation, setcodeAffectation] = useState("")
    const [codeRoute, setcodeRoute] = useState("")
    const [codeCentralise, setcodeCentralise] = useState("")
    const [codeRegion, setcodeRegion] = useState("")
    const [codeZone, setcodeZone] = useState("")
    const [codeGouvernorat, setcodeGouvernorat] = useState("")
    //const [codeDelegation, setcodeDelegation] = useState("")
    const [codeLocalite, setcodeLocalite] = useState("")
    const [actif, setactif] = useState(1)
    const [logitude, setlogitude] = useState("-1")
    const [latitude, setlatitude] = useState("-1")
    //---------------- LISTE --------------------------
    const [listeEnsegines, setlisteEnsegines] = useState([])
    const [listeCategorie, setlisteCategorie] = useState([])
    const [listeTypePdv, setlisteTypePdv] = useState([])
    const [listeRoute, setlisteRoute] = useState([])
    const [listeRegion, setlisteRegion] = useState([])
    const [listeZones, setlisteZones] = useState([])
    const [listeGouvernorat, setlisteGouvernorat] = useState([])
    const [listeLocalite, setlisteLocalite] = useState([])
    //----------------- REFERENCES -----------------
    const searchInput = useRef();
   //------------- Traduction ---------------
   const [t, i18n] = useTranslation("global");


    useEffect(() => {
        getSelectGouvernorat()
        .then((result) => {
            if (result?.data?.success === 1) {
                setlisteGouvernorat(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectLocalite().then((result) => {
            if (result?.data?.success === 1) {
                setlisteLocalite(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectZone().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectRegions()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteRegion(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectRoute().then((result) => {
            if (result?.data?.success === 1) {
                setlisteRoute(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectCategorie()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteCategorie(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectTypePdv()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTypePdv(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnsegines(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
    }, [])

    // Handle Change enseigne
    const handleChangeEnseigne = (...args) => {
        setcodeEnseigne(args[1].value)
    };
    // Handle Change Categorie
    const handleChangeCategorie = (...args) => {
        setcodeCategorie(args[1].value)
    };
    // Handle Change type pdv
    const handleChangeTypePdv = (...args) => {
        setcodeTypeMagasin(args[1].value)
    };
    // Handle Change route
    const handleChangeRoute = (...args) => {
        setcodeRoute(args[1].value)
    };
    // Handle Change route
    const handleChangeRegions = (...args) => {
        setcodeRegion(args[1].value)
    };
    // Handle Change route
    const handleChangeZone = (...args) => {
        setcodeZone(args[1].value)
    };
     // Handle Change route
     const handleChangeGouvernorat = (...args) => {
        setcodeGouvernorat(args[1].value)
    };
     // Handle Change route
     const handleChangeLocalite = (...args) => {
        setcodeLocalite(args[1].value)
    };
    
    const saveData = () =>{

        if(code === ""){
            toast(t('messageErreur.messageerreurcode') ,{type:"warning",position:"top-right"} )}
       else if (nom === ""){
            toast(t('messageErreur.messageerreursaisienompdv'),{type:"warning",position:"top-right"} )
        }
       /*  else if (codeRoute === ""){
            toast(t('messageErreur.messagechoixroute'),{type:"warning",position:"top-right"} )
        } */
        else if (codeRegion==="" || codeZone === ""  ){
            toast(t('messageErreur.localisationmagasin'),{type:"warning",position:"top-right"} )
        }else {
            const data = {
                code : code,
                code_a_barre: code,
                isactif :actif,
                nompdv : nom,
                logitude : logitude,
                latitude: latitude,
                adresse : adresse,
                adresse_livraison : adresseLivraison,
                description : description,
               typepdv_code: codeTypeMagasin,
               region_code: codeRegion,
               categoriepdv_code:codeCategorie,
               enseigne_code: codeEnseigne,
               zone_code: codeZone,
            }
            if(codeGouvernorat !== ""){
                Object.assign(data,{
                    gouvernorat_code : codeGouvernorat,
                })
            }
            if(codeCentralise !== ""){
                Object.assign(data,{
                    centralise: codeCentralise,
                })
            }
            if (codeRoute !== ""){
                Object.assign(data,{
                    routing_code: codeRoute,
                })
            }
            if(codeErp !== ""){
                Object.assign(data,{
                    erp : codeErp,
                })
            }
            if(codeLocalite !== ""){
                Object.assign(data,{
                    localite_code : codeLocalite,
                })
            }
            addMagasin(data)
            .then((result) => {
                if(result?.data?.success === 1){
                    toast(t('messageErreur.ajoutavecsuccee'),{type:"success",position:"top-right"} )
                }else {
                   toast(t('messageErreur.erreursaisie'),{type:"error",position:"top-right"} )
                }
            }).catch((err) => {
                console.log(err)
            });
        }
         
    }
    
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.Magasin')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Magasins.AjouterPDV')}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Parametragemagasins')}
                        </a>
                        {/* 
                        <a href="/pdv/groupePDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-organization mr-1" />
                            Groupe PDV
                        </a> */}
                        <a href="/pdv/convention" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-docs mr-1" />
                            {t('Magasins.Convention')}
                        </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="row">
                {/* ------------------ COORDONEES MAGASINS --------------------- */}
                <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                    <div className="card">
                    <div className="card-header mt-3 mb-0 pb-0">
                           <h5 className="card-title ">  {t('Magasins.CoordonneesMagasin')} </h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.code')} : <span className="text-danger"> * </span></label>

                                        <input type="text" value={code} readOnly disabled  className="form-control"  />
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.CodeERP')}: </label>

                                        <input type="text" value={codeErp} onChange={(e) => setcodeErp(e.target.value)} className="form-control" placeholder="Code ERP ... " />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Nommagasins')} : <span className="text-danger"> * </span></label>

                                        <input type="text" value={nom} required onChange={(e) => setnom(e.target.value)} className="form-control" placeholder="Nom magasin ... " />
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Adresse')}   </label>

                                        <input type="text" value={adresse} onChange={(e) => setadresse(e.target.value)} className="form-control" placeholder="Adresse ... " />
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Adressedelivraison')} </label>

                                        <input type="text" value={adresseLivraison} onChange={(e) => setadresseLivraison(e.target.value)} className="form-control" placeholder="Adresse ... " />
                                    </div>

                                </div>
                              
                            </div>

                        </div>
                    </div>

                </div>
                {/* ------------------ TYPE MAGASINS --------------------- */}
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                    <div className="card">
                        <div className="card-header mt-3 mb-0 pb-0">
                            <h5 className="card-title"> {t('Magasins.DetailsMagasins')} </h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.TypeMagasin')}:  </label>
                                        <SelectSearch
                                            value={codeTypeMagasin}
                                            options={listeTypePdv}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeTypePdv}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Magasins.ChoisirTypeMagasin')}
                                        />

                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('rapportrupture.categorie')} : </label>
                                        <SelectSearch
                                            value={codeCategorie}
                                            options={listeCategorie}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeCategorie}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Magasins.ChoisirCategorie')}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('Produits.Enseigne')}  :</label>
                                        <SelectSearch
                                            value={codeEnseigne}
                                            options={listeEnsegines}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeEnseigne}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={ t('Magasins.Choisirenseigne')}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Route')}   : {/* <span className="text-danger"> * </span> */} </label>
                                        <SelectSearch
                                            value={codeRoute}
                                            options={listeRoute}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeRoute}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Magasins.Choisirroute')}
                                        />
                                    </div>

                                </div>
                                <div className="col-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Centralise')}  </label>

                                        <select className="form-control br-md-0" value={codeCentralise} onChange={(e) => setcodeCentralise(e.target.value)}>
                                            <option value={""}> ----  {t('Magasins.Centralise')} --- </option>
                                            <option value={0}>{t('Magasins.Non')} </option>
                                            <option value={1}>{t('Magasins.Oui')} </option>

                                        </select>
                                    </div>

                                </div>
                                <div className="col-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Actif')} </label>

                                        <select className="form-control br-md-0" value={actif} onChange={(e) => setactif(e.target.value)}>
                                            <option value={0}>{t('Magasins.Non')} </option>
                                            <option value={1}>{t('Magasins.Oui')} </option>

                                        </select>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/* ------------------ Localisation --------------------- */}
                <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
                    <div className="card">
                        <div className="card-header mt-3 mb-0 pb-0">
                            <h5 className="card-title"> {t('Magasins.Localisation')} </h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('Magasins.Region')} : <span className="text-danger"> * </span></label>
                                        <SelectSearch
                                            value={codeRegion}
                                            options={listeRegion}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeRegions}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Magasins.Choisirregion')}

                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Zone')} : <span className="text-danger"> * </span></label>
                                        <SelectSearch
                                            value={codeZone}
                                            options={listeZones}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeZone}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={ t('Magasins.Choisirzone')}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Gouvernorat')} : <span className="text-danger"> * </span></label>

                                        <SelectSearch
                                            value={codeGouvernorat}
                                            options={listeGouvernorat}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeGouvernorat}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Magasins.ChoisirGouvernorat')}
                                        />
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Localite')}</label>
                                        <SelectSearch
                                            value={codeLocalite}
                                            options={listeLocalite}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeLocalite}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t('Magasins.ChoisirLocalite')}

                                        />
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Longitude')}  </label>

                                        <input type="text" value={logitude} onChange={(e) => setlogitude(e.target.value)} className="form-control" placeholder="Adresse ... " />
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('Magasins.Latitude')} </label>

                                        <input type="text" value={latitude} onChange={(e) => setlatitude(e.target.value)} className="form-control" placeholder="Adresse ... " />
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Description')} </label>

                                        <textarea type="text" value={description} onChange={(e) => setDescription(e.target.value)} className="form-control" placeholder="Description ... " />
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <button className="btn btn-primary float-right" onClick={() => saveData()} ><i className="ti-save mr-1" /> {t('rapports.enregistrer')} </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
