import React from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'

export default function PLGamme({ t, dateYear ,allGamme, listeGamme , listeGammePresc}) {
  
  const valueByMonth = (month, enseigne) => {
    if (listeGamme[month] !== undefined) {
        const liste = listeGamme[month].find((elemnents) => elemnents.gamme	 === enseigne)
        if (liste !== undefined) {
            return parseFloat(liste.pl).toFixed(2)+" %"
        } else return undefined
    }
}
const valueByMonthPrecedent = (month, enseigne) => {
    if (listeGammePresc[month] !== undefined) {
        const liste = listeGammePresc[month].find((elemnents) => elemnents.gamme	 === enseigne)
        if (liste !== undefined) {
            return parseFloat(liste.pl).toFixed(2)+" %"
        } else return undefined
    }
}
  
  return (
    <div className='card'>
            <div className="card-header">
                <div className="card-title mt-3 float-left">Part Linéaire par Gamme  </div>
                <div className="float-right">
                    <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-success text-center "
                        table="table-to-xlssx"
                        filename="Pl_Gamme"
                        sheet="tablexls"
                        buttonText={<span className=" text-center fs-10"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                    />
                </div>
            </div>
            <div className='card-body'>
                <div className="table-responsive table-striped product-datatable" style={{ overflow: 'auto', height: 'auto' }}>
                    <table
                        id="table-to-xlssx"
                        className="table  table-bordered "
                    >
                        <thead className="text-center " style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>


                             <tr>
                                    <th></th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t('janvier')}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("fevrier")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("Mars")}</th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t("avril")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("mai")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("juin")}</th>
                                    <th className="wd-15p fs-10" colSpan={2} >{t("juillet")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("aout")}</th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t("Septembre")}</th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t("Octobre")}</th>
                                    <th className="wd-15p fs-10" colSpan={2}>{t("Novembre")}</th>
                                    <th className="wd-15p fs-10"colSpan={2} >{t("decembre")}</th>
                                </tr>
                                <tr >
                                    <th className="w-15p  fs-10"  >{t('dashboard.Magasin')} </th>
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>                                    
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>                                
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>                                  
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>                                   
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>    
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>     
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>  
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>  
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>  
                                    <th className="wd-15p fs-10" >{dateYear}</th>
                                    <th className="wd-15p fs-10" >{parseInt(dateYear) -1}</th>                         
                                </tr>
                        </thead>
                        <tbody>
                            {allGamme?.length === 0 ?
                             <tr>
                                            <td colSpan={13} className='text-center text-danger'> {t('Zoning.Aucunresultat')}</td>
                                        </tr> : 
                            allGamme?.map((items, keys) => (
                                <tr key={keys}   className={
                                    keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"
                                }>
                                    <td>{items}</td>
                                    <td> {valueByMonth(1, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(1, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(1, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(1, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(2, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(2, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(2, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(2, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(3, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(3, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(3, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(3, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(4, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(4, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(4, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(4, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(5, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(5, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(5, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(5, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(6, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(6, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(6, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(6, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(7, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(7, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(7, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(7, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(8, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(8, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(8, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(8, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(9, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(9, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(9, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(9, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(10, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(10, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(10, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(10, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(11, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(11, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(11, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(11, items)}</h6> : 0} </td>
                                                <td> {valueByMonth(12, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonth(12, items)}</h6> : 0} </td>
                                                <td> {valueByMonthPrecedent(12, items) !== undefined ? <h6 className='text-primary fs-10'> {valueByMonthPrecedent(12, items)}</h6> : 0} </td>

                                </tr> 
                            ))}
                        </tbody>
                    
                    </table>
                </div>
            </div>

        </div>
  )
}
