/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { SearchItems } from '../../../utils/SearchUtils';
import ModalHistoriqueRuptures from './ModalHistoriqueRuptures';

export default function RuptureProduitsMois({ year, pdv_code,ruptureProduit,t, loading, setruptureProduit, saveRupture }) {
    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    const [searchList, setSearchlist] = useState([])
    // pdv_visite_tournee => 
    const [ok1, setok1] = useState(false) //Modal Ruptures 
    const [month, setmonth] = useState(null)
    const [produit, setproduit] = useState(null)

    const currentListe = search.length < 1 ? ruptureProduit : searchList

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(ruptureProduit, search)
            setSearchlist(newList)
        } else {
            setruptureProduit(saveRupture)
        }
    }

    return (
        <div className="panel-group" id="accordion3" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
        <div className="panel panel-default active">
            <div className="panel-heading " role="tab" id="headingfOUR">
                <h4 className="panel-title text-primary">
                    <a role="button" data-toggle="collapse" data-parent="#accordion3" href="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                    <i className="icon icon-book-open mr-2" /> {t('historiqueMagasin.rapportproduitparmois')}
                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                    </a>
                </h4>
            </div>
            <div id="collapsefour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfOUR">
                <div className="panel-body">
                    <div className='row'>
                        <div className='col-11'/>
                        <div className='col-1'>
                      
                        </div>
                    </div>    
                    <div className="search1 search-elements-sm mb-4 ">
                            <div className="form row no-gutters">
                                <div className="col-8"></div>
                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 mb-0">
                                    <input type="text" ref={inputEl} value={search}
                                        onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary fs-10" placeholder="Chercher un produit" />
                                </div>

                                <div className="col-xl-1 col-lg-1 col-md-1 mb-0">
                                    <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16 "
                                table="table-to-xls"
                                filename="RuptureMerbiss"
                                sheet="tablexls"
                                buttonText={<span className=" text-center">{/*  {t('rapportrupture.exporter')} */} <i className="fa fa-file-excel-o" /> </span>}
                            />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <table
                                id="table-to-xls"
                                className="table  table-bordered "
                            >
                                <thead className="text-center bg-azure-lighter">
                                    <tr>
                                        <th className='fs-10'>{t('rapportrupture.produit')}</th>
                                        <th className="wd-15p fs-10" >{t('janvier')}</th>
                                        <th className="wd-15p fs-10" >{t("fevrier")}</th>
                                        <th className="wd-15p fs-10" >{t("Mars")}</th>
                                        <th className="wd-15p fs-10" >{t("avril")}</th>
                                        <th className="wd-15p fs-10" >{t("mai")}</th>
                                        <th className="wd-15p fs-10" >{t("juin")}</th>
                                        <th className="wd-15p fs-10"  >{t("juillet")}</th>
                                        <th className="wd-15p fs-10" >{t("aout")}</th>
                                        <th className="wd-15p fs-10" >{t("Septembre")}</th>
                                        <th className="wd-15p fs-10" >{t("Octobre")}</th>
                                        <th className="wd-15p fs-10" >{t("Novembre")}</th>
                                        <th className="wd-15p fs-10" >{t("decembre")}</th>
                                    </tr>
                                </thead>
                                {loading ? <SqaureLoader /> : (
                                    <tbody>
                                        {ruptureProduit?.length === 0 ?
                                            <tr>
                                                <td colSpan="13">
                                                    <h6 className="text-danger text-center">
                                                        {t('Zoning.Aucunresultat')}
                                                    </h6>
                                                </td>
                                            </tr> : (
                                                <>
                                                    {currentListe?.map((items, keys) => (
                                                        <tr className="text-center fs-10" key={keys}> 
                                                            <td className='text-left'><b className='text-primary'>{"("+items.code +") "}</b>{ items.produit}</td>
                                                            <td >{items.month === 1 ?  (items.sum !== 0 ? <span className="text-primary fw-500" data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer"}} >{items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 2 ?  (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer"}} >{items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 3 ?  (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer"}} >{items.sum}</span> : 0) : 0}</td>
                                                            <td >{items.month === 4 ?  (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer"}} >{items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 5 ?  (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer"}} >{items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 6 ?  (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer"}} > {items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 7 ?  (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer"}} >{items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 8 ?  (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer" }}>{items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 9 ?  (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer"}} >{items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 10 ? (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer" }}>{items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 11 ? (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer" }}>{items.sum}</span> : 0) : 0} </td>
                                                            <td >{items.month === 12 ? (items.sum !== 0 ? <span className="text-primary"  data-toggle="modal" data-target="#exampleModal3" onClick={()=>{setok1(true); setmonth(items.month); setproduit(items.code)}} style={{ fontWeight: "bold",cursor:"pointer" }}>{items.sum}</span> : 0) : 0} </td>

                                                        </tr>
                                                    ))
                                                    }
                                                </>
                                            )}


                                    </tbody>
                                )}

                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {ok1 && <ModalHistoriqueRuptures t={t} setok1={setok1} pdv_code={pdv_code}  month={month} year={year} produit_code={produit}/>}

        </div>
    )
}
