import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../../Authentification/authHeader'

//GET URL BACKEND 
dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL




/**
 * Get liste  : Familles
 * @returns 
 */
export async function getListeFamilles() {
    try {
        const response = await axios.get(url + "familles/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function getFamillebyCode(code) {
    try {
        const response = await axios.get(url + "familles/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function postObjectFamille(data) {
    try {
        const response = await axios.post(url + "familles/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function updateObjectFamille(code, data) {
    try {
        const response = await axios.patch(url + "familles/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function getSelectFamilles() {
    try {
        const response = await axios.get(url + "familles/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function getListeSousFamilles() {
    try {
        const response = await axios.get(url + "sousfamilles/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function getSousFamillebyCode(code) {
    try {
        const response = await axios.get(url + "sousfamilles/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function postObjectSousFamille(data) {
    try {
        const response = await axios.post(url +"sousfamilles/", data, 
        { headers: authHeader() },
         { IsEssential: true });
        return response;

    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Get liste  : Familles
 * @returns 
 */
export async function updateObjectSousFamille(code, data) {
    try {
        const response = await axios.patch(url + "sousfamilles/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function geSelectSousFamilles() {
    try {
        const response = await axios.get(url + "sousfamilles/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function getListeMarque() {
    try {
        const response = await axios.get(url + "marques/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
} 
/**
 * Add Excel Marque
 * @param {*} file 
 * @returns 
 */
export async function addExcelMarque(file) {


    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(url + "marques/excel", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Marque by code
 * @returns 
 */
export async function getByCodeMarques(code) {
    try {
        const response = await axios.get(url + "marques/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Update  : Marque by code
 * @returns 
 */
export async function addObjectMarque(data) {
    try {
        const response = await axios.post(url + "marques/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Update  : Marque by code
 * @returns 
 */
export async function updateByCodeMarques(code, data) {
    try {
        const response = await axios.patch(url + "marques/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function getSelecteMarque() {
    try {
        const response = await axios.get(url + "marques/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : GAMMES
 * @returns 
 */
export async function getListeGammes() {
    try {
        const response = await axios.get(url + "gammes/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : GAMMES
 * @returns 
 */
export async function getByCodeGammes(code) {
    try {
        const response = await axios.get(url + "gammes/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * ADD OBJECT GAMME : DATA
 * @param {*} data 
 * @returns 
 */
export async function postObjectGammes(data) {
    try {
        const response = await axios.post(url + "gammes/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Uodate OBJECT GAMME : DATA
 * @param {*} data 
 * @returns 
 */
export async function updateObjectGammes(code, data) {
    try {
        const response = await axios.patch(url + "gammes/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Familles
 * @returns 
 */
export async function getSelectGamme() {
    try {
        const response = await axios.get(url + "gammes/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
//------------------------------------------------------------------ SOCIETE ----------------------------------------------------------------------------------
/**
 * Get liste  : Societe
 * @returns 
 */
export async function getListeSocietes() {
    try {
        const response = await axios.get(url + "societe/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * POST ADD  : Societe
 * @returns 
 */
 export async function addSociete(data) {
    
    const formData = new FormData();
    formData.append("code", data.code);
    formData.append("libelle", data.libelle);
    formData.append("email", data.email);
    formData.append("photo_visite", data.photo_visite);
    formData.append("logo", data.logo);
    formData.append("type", data.type);
    formData.append("expire_commande", data.expire_commande);
    formData.append("accees", data.accees);
    formData.append("cause_rupture", data.cause_rupture);
    formData.append("list_pdv", data.list_pdv);
    formData.append("envoie_from_mobile", data.envoie_from_mobile);

    try {
        const response = await axios.post(url + "societe/", formData,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * GET LAST ID SOCIETE 
 * @returns 
 */
 export async function getLastIdSociete() {
    try {
        const response = await axios.get(url + "societe/getId",{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
//------------------------------------------------------------ DAILY MAIL ----------------------------------------------------------------------

/**
 * POST ADD  : Societe
 * @returns 
 */
export async function addMailUser(data) {
    
   

    try {
        const response = await axios.post(url + "mail/", data,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * LISTE DAILY MAIL
 * @returns 
 */
export async function getListeMailingADMIN() {
    
    try {
        const response = await axios.get(url + "mail/",{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * DELETE DAILY MAIL
 * @param {*} code 
 * @returns 
 */
export async function deleteMailUser(code) {
    
    try {
        const response = await axios.delete(url + "mail/"+code,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * PARCH  : Societe
 * @returns 
 */
 export async function updateSociete(code, data) {

    const formData = new FormData();
    formData.append("libelle", data.libelle);
    formData.append("releve_prix", data.releve_prix);
    formData.append("email", data.email);
    formData.append("photo_visite", data.photo_visite);
    formData.append("logo", data.logo);
    formData.append("type", data.type);
    formData.append("expire_commande", data.expire_commande);
    formData.append("accees", data.accees);
    formData.append("cause_rupture", data.cause_rupture);
    formData.append("list_pdv", data.list_pdv);
    formData.append("envoie_from_mobile", data.envoie_from_mobile);

    
    try {
        const response = await axios.patch(url + "societe/"+code,formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  {
            return error?.response?.status
        }
    }
}
/**
 * Get liste  : Societe
 * @returns 
 */
 export async function getSocieteByCode(code) {
    try {
        const response = await axios.get(url + "societe/code/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * GET RELEVE Prix Societe
 * @returns 
 */
 export async function getRelevePrixSociete() {
    try {
        const response = await axios.get(url + "societe/relevePrix", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}