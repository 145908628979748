/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast, ToastContainer } from 'react-toastify'
import { addTourneeTrade, deleteTourneeAgenda, getListeByZone, getListeTourneeTrade } from '../../../services/Routings/RoutingsApi';
import { getSelectZone } from '../../../services/Zoning/ZoningApi'
import { getDates } from '../../../utils/DateUtils';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';
import { SearchItems } from '../../../utils/SearchUtils';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import LoaderTable from '../../../components/Loader/LoaderTable';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

export default function CreationTourneeTrade() {

    const searchInput = useRef();
    //------ History -----------------
    const history = useHistory()
    // Formulaire 
    const inputEl = useRef("")
    const [search, setSearch] = useState([])
    //--------- INTERVALLE DU TEMPS ----------------   
    const [date_debut, setdate_debut] = useState("")
    const [date_fin, setdate_fin] = useState("")
    //------------- LISTE ---------------------
    const [liste, setliste] = useState([])
    const [listeZone, setlisteZone] = useState([])
    const [codeZone, setcodeZone] = useState("")
    var [listeTournees, setlisteTournees] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //Tab auxiliaire => Quand on sélectionne un pdv on aura une ligne qui affichera les dates des magasins planifiée
    const [tab_plannifiee, settab_plannifiee] = useState([]) // Format normal 
    const [toutpdvs, settoutpdvs] = useState([])
    const [routings, setRoutings] = useState([])
    const [listePdv, setlistePdv] = useState([])
    const [codePdv, setcodePdv] = useState("")
    const [listeEnseignes, setlisteEnseignes] = useState([])
    // const [codeEnseigne, setcodeEnseigne] = useState("")
    const [listeUsers, setlisteUsers] = useState([])
    const [codeUser, setcodeUser] = useState("")
    const [ok, setok] = useState([])
    //-------------- LOADER ---------------------
    const [loading, setloading] = useState(true)
    const [loadingTable, setloadingTable] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    const currentListe = search.length < 1 ? _.orderBy(listePdv, ['plannifie'], ['asc']) : searchList
    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")

    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
    useEffect(() => {
        getSelectZone()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteZone(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });

    }, [])
    // Handle Change Marque
    const handleChangeZones = (...args) => {
        setcodeZone(args[1].value)
    };

    const addLigneTable = (ligne, listegrouped, listepdvs, list) => {
        var obj = Object.assign({}, {
            code: ligne.code,
            user_code: ligne.user_code,
            routing_code: ligne.routing_code,
            pdv_code: ligne.pdv_code,
            dates: moment(ligne.dates).format("YYYY-MM-DD"),
            frequence: "Par_dates",
            date_debut: date_debut,
            date_fin: date_fin,
            code_jour: "Lundi",
            rang: ligne.rang,
            quota: ligne.quota,
            actif: ligne.actif,
            pdvs: listepdvs[moment(ligne.dates).format("YYYY-MM-DD")]?.[0]?.pdvs
        })
        /**
         * Pour le tableau des pdvs 
         */
        var x = list?.findIndex((el) => el.value === ligne.pdv_code)
        var planiftab = { ...list }
        var newTab = [...list]
        newTab[x].plannifie = 1
        planiftab[x] = newTab
        /**
         * Chargements des cases selon la sauvegarde
         */
        let table1 = { ...listegrouped }
        let clonedData = [...listegrouped[moment(ligne.dates).format("YYYY-MM-DD")]?.[0]?.pdvs[ligne.user_code]]
        clonedData.push(obj)
        table1[moment(ligne.dates).format("YYYY-MM-DD")][0].pdvs[ligne.user_code] = clonedData
        setlisteTournees(table1)
    }
    // Handle Change Marque
    const handleChangePdv = (e, merch, date, index) => {
        //e code pdv  / merch = code merch / date la date de l'objet / index position dans le tableau des pdvs 
        //TRAITEMENT EL KBIIIIR ELI BECH NAKES FIH LES PDVS MEL LIGNES LOKHRA
        var aux_pdv = '' // PDV déja planifiée

        let table1 = { ...listeTournees }
        //console.log(listeTournees[date][0].pdvs[merch][index])
        let clonedData = [...listeTournees[date][0]?.pdvs[merch]]
        // let clonedTable = [...listeTournees[date]]
        if (e.target.name === "pdv_code" && clonedData[index][e.target.name] !== '' && e.target.value !== "") {
            aux_pdv = clonedData[index][e.target.name] //  J'ai sauvegarder la valeur aux 
            var x = listePdv?.findIndex((el) => el.value === aux_pdv)
            var planiftab = { ...listePdv }
            var newTab = [...listePdv]
            newTab[x].plannifie = 0
            planiftab[x] = newTab[x]
            var aux_pdv2 = e.target.value // ajouter la nouvelle valur en tant que planifiee
            var y = listePdv?.findIndex((el) => el.value === aux_pdv2)
            newTab[y].plannifie = 1
            planiftab[y] = newTab[y]
            /*    listeUsers?.map((items, keys) => {
                   clonedTable[0].pdvs[items.value].map((x, y) => {
                       console.log(x)
                   })

               }) */
            //nrajaaa3 fi le reste du tableai
        } else {
            if (e.target.name === "pdv_code" && e.target.value !== "") {
                //SI JE SUIS ENTRAIN DE CHANGER LE PDV CODE
                var obj = Object.assign({}, {
                    date: date,
                    pdv_code: e.target.value
                })
                tab_plannifiee.push(obj)
                //avec l'aux ici je dois eliminer les valeurs du pdv_code déja existante dans le meme code jour sauf la position du merch selectionnée
                aux_pdv = e.target.value
                var x = listePdv?.findIndex((el) => el.value === aux_pdv)
                let planiftab = { ...listePdv }
                let newTab = [...listePdv]
                newTab[x].plannifie = 1
                planiftab[x] = newTab[x]
                //liste user nombre merch
            }
        }
        clonedData[index][e.target.name] = e.target.value
        table1[date][0].pdvs[merch] = clonedData
        setlisteTournees(table1)

        //setcodePdv(args[1].value)
    };


    const onChangeValue = () => {
        if (date_debut > date_fin) {
            toast(t("messages.dateinf"), { type: "warning", position: "top-right" })
        } else if (codeZone === "") {
            toast(t("Routings.selectionnerZone"), { type: "warning", position: "top-right" })
        } else {
            setloading(true)
            const dates = getDates(new Date(date_debut), new Date(date_fin))

            setliste(dates)
            var tournee = []
            var listeusers = []
            getListeByZone(codeZone)
                .then((result) => {
                    if (result?.data?.success === 1) {
                        //---- Liste de points de  ventes ------
                        var list = []
                        var tabulation = []
                        dates?.map((items, keys) => {
                            var ob1 = Object.assign({}, {
                                date: moment(items).format("YYYY-MM-DD"),
                                pdvs: result?.data?.listePdvs
                            })
                            tabulation.push(ob1)
                        })
                        result?.data?.listePdvs?.map((i, k) => {
                            var obj = Object.assign({}, {
                                value: i.value,
                                name: i.name,
                                plannifie: 0
                            })
                            list.push(obj)
                        })
                        settoutpdvs(_.groupBy(tabulation, "date")) // TOUT PDVS PAR JOURS 

                        setlistePdv(list)
                        setSaveList(list)
                        //--------- Liste PDV --------------
                        setlisteUsers(result?.data?.listeUsers)
                        setlisteEnseignes(result?.data?.listeEnseigne)
                        //------------------------------------
                        setRoutings(result?.data?.listeroutings)
                        var gr = _.groupBy(result?.data?.listeroutings, "user_code")
                        result?.data?.listeUsers?.map((items) => {
                            dates?.map((i) => {
                                var obj = Object.assign({}, {
                                    code: items.value + "-" + moment(i).format("MMMYYYY") + "-" + codeZone,
                                    user_code: items.value,
                                    routing_code: gr[items.value][0].code,
                                    pdv_code: "",
                                    dates: moment(i).format("YYYY-MM-DD"),
                                    frequence: "Par_dates",
                                    date_debut: date_debut,
                                    date_fin: date_fin,
                                    code_jour: "Lundi",
                                    rang: 1,
                                    quota: 0,
                                    actif: "1",
                                    pdvs: result?.data?.listePdvs
                                })
                                tournee.push(obj)
                                var list = Object.assign({}, {
                                    user_code: items.value,
                                    dates: moment(i).format("YYYY-MM-DD"),
                                    pdvs: result?.data?.listePdvs
                                })

                                listeusers.push(list)
                            })
                        })
                        var grouped = _.groupBy(tournee, "dates")
                        var test1 = []
                        for (let value of Object.keys(grouped)) {
                            var x = _.groupBy(grouped[value], "user_code")
                            //console.log(x) 
                            var y = {
                                dates: value,
                                pdvs: x
                            }
                            test1.push(y)

                        }
                        var listegrouped = _.groupBy(test1, "dates")
                        var listepdvs = _.groupBy(tabulation, "date")
                        setlisteTournees(_.groupBy(test1, "dates"))
                        getListeTourneeTrade(codeZone, date_debut, date_fin)
                            .then((result) => {
                                if (result?.data?.success === 1) {
                                    result?.data?.liste?.map((items, keys) => {
                                        addLigneTable(items, listegrouped, listepdvs, list)
                                    })
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            }).finally(() => {
                                setloadingTable(false)
                            })


                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setloading(false)
                })




        }

    }

    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (inputEl.current.value !== "") {
            const newList = SearchItems(saveList, inputEl.current.value)
            setSearchlist(newList)
        } else {
            setlistePdv(saveList)
        }
    }

    const onClickSaveData = () => {
        var t1 = []
        liste.map((items) => {
            listeUsers?.map((it) => {
                listeTournees[moment(items).format("YYYY-MM-DD")][0].pdvs[it.value].map((i) => {
                    if (i.pdv_code !== "") {
                        var obj = {}
                        Object.assign(obj, {
                            code: i.code,
                            user_code: i.user_code,
                            routing_code: i.routing_code,
                            pdv_code: i.pdv_code,
                            dates: moment(i.dates).format("YYYY-MM-DD"),
                            frequence: "Par_dates",
                            date_debut: i.date_debut,
                            date_fin: i.date_fin,
                            code_jour: i.code_jour,
                            rang: i.rang,
                            quota: i.quota,
                            actif: i.actif,
                        })
                        t1.push(obj)

                    }
                })
            })
        })

        var finalListe = _.groupBy(t1, 'user_code')
        var test = []
        for (let value of Object.values(finalListe)) {
            test.push(value[0].code)
        }
        /**
         * Suppression de l'ancienne sauvegarde
         */


        /**
         * Création nouvelle sauvegarde
         */
        try {
            const l = _.uniq(test)
            l?.map((items) => {
                deleteTourneeAgenda(items, date_debut, date_fin)
                    .then((result) => {
                        if (result?.data?.success === 1) {
                            //console.log("suppression sauvegarde")
                        } else {
                         //   console.log(result?.data)
                        }
                    }).catch((error) => {
                        console.log(error)

                    })
            })
        } catch (error) {
            console.log(error)
        } finally {
            for (let value of Object.values(finalListe)) {
                addTourneeTrade(value)
                    .then((result) => {
                        if (result === 500) {
                            ok.push("false")
                            // toast(t('messages.erreurajout'), { type: "error", position: 'top-right' })
                        } else if (result?.data?.success === 1) {
                            ok.push("true")
                            //toast(t('messages.ajoutsuccee'), { type: "success", position: "top-right" })
                        } else {
                            ok.push("error")
                            // toast(t('messages.verifierdonner'), { type: "warning", position: "top-right" })
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
            }
            const l1 = _.uniq(ok)
            if (l1.length === 1 || l1.length === 0) {
                toast(t('messages.ajoutsuccee'), { type: "success", position: "top-right" })
               /*  setTimeout(() => {
                    history.push({
                        pathname:
                            '/routings/tournee&datedebut=' + date_debut + '&datefin=' + date_fin
                    })
                }, 3000); */

            } else {
                toast(t('messages.erreurajout'), { type: "error", position: 'top-right' })
            }
        }


    }

    const changeTable = (merch, date) => {
        var gr = _.groupBy(routings, "user_code")
        var obj = Object.assign({}, {
            code: merch + "-" + moment(date).format("MMMYYYY") + "-" + codeZone,
            user_code: merch,
            routing_code: gr[merch][0].code,
            pdv_code: "",
            dates: moment(date).format("YYYY-MM-DD"),
            frequence: "Par_dates",
            date_debut: date_debut,
            date_fin: date_fin,
            code_jour: "Lundi",
            rang: 1,
            quota: 0,
            actif: "1",
            pdvs: toutpdvs[moment(date).format("YYYY-MM-DD")][0].pdvs
        })
        //listeTournees[moment(date).format("YYYY-MM-DD")][0].pdvs[merch][0].push(obj)
        let table1 = { ...listeTournees }
        let clonedData = [...listeTournees[moment(date).format("YYYY-MM-DD")][0].pdvs[merch]]
        clonedData.push(obj)
        table1[moment(date).format("YYYY-MM-DD")][0].pdvs[merch] = clonedData
        setlisteTournees(table1)
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Routings.Tournee')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">  {t('sidebar.creationTournee')} </li>
                </ol>
                {details?.role_code==="admin" ? 
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href={'/routings/tournee&datedebut=' + date1 + '&datefin=' + date2} className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-directions mr-1" />
                            {t('Routings.Tournee')}
                        </a>
                        <a href="/routings/routings&route=-1&region=-1" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-plus mr-1" />
                            {t('sidebar.creationTournee')}
                        </a>
                        <a href="/routings/routes" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-map mr-1" />
                            {t('Routings.Route')}
                        </a>
                        <a href="/routings/tournee" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                         <i className="icon icon-map mr-1" />
                         {t('Tournee agenda par zone')}
                     </a>
                    </div>
                </div> : null }
            </div>
            <ToastContainer />
            <div>
                <div className='row'>
                    <div className='col-8'>
                        <div className='card' style={{ height: '200px' }}>
                            <div className='card-header'>
                                <h6 className='card-title text-center text-primary mt-3 fs-12'>{t('sidebar.creationTournee')}</h6>
                            </div>
                            <div className='card-body'>
                                <div className='row mb-2'>
                                    <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12'></div>
                                    <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12'>
                                        <div className="input-group">
                                            <input type="date" value={date_debut} onChange={(e) => setdate_debut(e.target.value)} className="form-control text-primary fs-10" />
                                            <input type="date" value={date_fin} onChange={(e) => setdate_fin(e.target.value)} className="form-control text-primary fs-10" />
                                            <SelectSearch
                                                options={listeZone}
                                                search
                                                ref={searchInput}
                                                onChange={handleChangeZones}
                                                filterOptions={fuzzySearch}
                                                emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                                placeholder={t('Routings.choisirunezone')}
                                            />

                                            <span className="input-group-append">
                                                <button className="btn btn-primary fs-10" type="button" onClick={() => onChangeValue()} ><i className='ti-check' /></button>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12'></div>
                                </div>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>

                                </div>
                                <h6 className=" mt-2 text-muted fs-12">  <span className="text-info"> <i className="icon icon-exclamation mr-1" /> {t('dashboard.Information')} : </span> <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                                    <div>
                                        <strong> {t('Routings.messageMagasin')} </strong>
                                        <br />
                                    </div>
                                </div>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-4' >
                        <div className='card' style={{ overflow: 'auto', height: '200px' }}>
                            <div className='card-header mb-0 mt-0'>
                                <h6 className='card-title text-center text-primary mt-3 fs-12 float-left '>{t('sidebar.listeMagasins')}</h6>
                                <span className="input-group-append float-right">
                                    <div className="input-group">
                                        <input type="text" className="form-control fs-10" placeholder="Recherche ..." ref={inputEl} value={search}
                                            onChange={() => getSearchTerm()} />
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button"><i className='ti-search fs-10' /></button>
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div className='card-body'>
                         
                                <table className="table table-bordered " >
                                    <thead className='text-center bg-azure-lighter ' style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                        <tr>
                                            <th scope="col" className='fs-10'>{t('dashboard.Magasin')}</th>
                                            <th scope="col" className='fs-10'>{t('dashboard.pdvplanifiee')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center  fs-10'>
                                        {currentListe?.map((x, y) => (
                                            <tr className={x.plannifie === 0 ? "bg-pink-lightest" : "bg-green-lightest"}>
                                                <td>{x.name}</td>
                                                <td> {x.plannifie === 0 ? <span className='text-danger'> <b><i className='icon icon-close mr-1' /> Non Plannifié </b> </span> : <span className='text-success'> <b><i className='icon icon-check mr-1' /> Plannifié </b> </span>}</td>
                                            </tr>

                                        ))}

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>

                {liste?.length === 0 ?
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                            <div className="alert bg-purple mb-5 py-4" role="alert">
                                <div className="d-flex">
                                    <i className="icon icon-info mr-3 fs-30"></i>
                                    <div>
                                        <h4 className="alert-heading fs-14">{t('Routings.intervalleDate')}</h4>
                                        <p className='fs-14'>{t('Routings.messageDatedeb')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='row mb-2'>

                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div align="center">
                           {/*  <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-success text-center "
                        table="table-to-xls"
                        filename="Planning"
                        sheet="tablexls"
                        buttonText={<span className=" text-center fs-10"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                    /> */}
                                <button className='btn btn-primary mb-3 fs-10' onClick={() => onClickSaveData()}><i className='ti-save mr-1' />{t('Magasins.Enregistrer')} </button>
                                <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '800px' }}>
                                    <table border={1} className="table  table-bordered " id="table-to-xls">
                                        <tbody className='bg-white'>
                                            {loading ? <SqaureLoader /> : <>
                                                <tr className='bg-purple fs-10' style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                                    <td align="center">
                                                        <b>{t('dashboard.Date')} </b>
                                                    </td>
                                                    {listeUsers?.map((items, keys) => (
                                                        <td align="center" key={keys}>
                                                            <b value={items.value}> {items.name}</b>
                                                        </td>
                                                    ))}
                                                </tr>
                                                {liste?.map((items, keys) => (
                                                    <>
                                                        <tr key={keys} className={keys % 2 === 1 ? "fs-12 bg-blue-lightest" : "fs-12"}>
                                                            <td align="center" className='fs-10 text-azure' style={{ verticalAlign: "middle" }} >
                                                                <b>{moment(items).format("DD dddd ")} </b>
                                                            </td>
                                                            {listeTournees[moment(items).format("YYYY-MM-DD")]?.map((i, k) => (
                                                                listeUsers?.map((x, y) => (
                                                                    <td align="center" key={y}>
                                                                        <>
                                                                            {loadingTable ? <LoaderTable /> : i?.pdvs[x.value]?.map((z, b) => (
                                                                                <span className="input-group-append" >

                                                                                    {/** e = element.target.value la valeur changé , x.value == le code du merchandiseur , i.dates == date de l'objet , b == position dans la liste des merch  */}
                                                                                    <select data-placeholder="Select" value={z?.pdv_code} onChange={(e) => handleChangePdv(e, x.value, i.dates, b)} name="pdv_code" className={z?.pdv_code !== "" ? "form-control text-azure fs-10 br-md-0 bg-azure-lightest" : "form-control  fs-10 br-md-0"}>
                                                                                        <optgroup label="Magasins">
                                                                                            <option value={""}> {t('Routings.choisir')}</option>
                                                                                            {z?.pdvs?.map((n, m) => (
                                                                                                <option value={n.value} key={m}>{n.name}</option>
                                                                                            ))}
                                                                                        </optgroup>
                                                                                    </select>
                                                                                    <input type='number' className={z?.quota !== 0 ? "form-control text-azure fs-10 br-md-0 bg-azure-lightest" : "form-control  fs-10 br-md-0"} name='quota' value={z?.quota} onChange={(e) => handleChangePdv(e, x.value, i.dates, b)} placeholder='Quota' min={1} max={4} style={{ width: '50px' }} />
                                                                                    <i className=' ion-plus-circled text-azure mr-1 ml-1 mt-3 mb-2' style={{ cursor: "pointer" }} onClick={() => changeTable(x.value, items)} />
                                                                                </span>

                                                                            ))}
                                                                        </>
                                                                    </td>
                                                                ))
                                                            ))}
                                                        </tr>
                                                    </>
                                                ))}
                                                {/*   <tr>
                                                <td align="center" colSpan={listeUsers.length + 1}>
                                                    <button className='btn btn-primary fs-10' onClick={() => onClickSaveData()}><i className='ti-save mr-1' />{t('Magasins.Enregistrer')} </button>
                                                </td>
                                            </tr> */}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                                <button className='btn btn-primary mb-3 fs-10' onClick={() => onClickSaveData()}><i className='ti-save mr-1' /> {t('Magasins.Enregistrer')} </button>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>

    )
}
