import React, { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { toast } from 'react-toastify';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { updateObjectFamille } from '../../../services/Produits/configurationProduits/ConfigurationAPI';

/**
 * EDIT MODAL FAMILLE
 * @param {*} param0 
 * @returns 
 */
export default function EditModalFamille({ listeGammes, keys, setShowEdit, setShow, object, t }) {

    //----- USE REFERENCE ------------
    const gammes = useRef();
    ///---------- FORMULAIRE -------
    const [code, setCode] = useState("")
    const [libelle, setLibelle] = useState("")
    const [codegamme, setCodeGamme] = useState("")
    const [color, setColor] = useState("#fffff")

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        setLoading(true)
        setCode(object.code)
        setLibelle(object.libelle)
        setColor(object.color)
        setCodeGamme(object.gamme_code)

        setLoading(false)
    }, [object])

    // Handle Change Famille
    const handleGammes = (...args) => {
        setCodeGamme(args[1].value)
    };

    /**
     * Handle Change Color
     * @param {*} color 
     */
    const handleChangeComplete = (color) => {
        setColor(color.hex)
    }
    /**
     * SAVE DATA
     */
    const saveData = () => {
        //Data of code & libelle FOR OBJECT PAYS
        if (code !== "" && color !== "" && libelle !== "" && codegamme !== "") {
            const data = {
                code: code,
                color: color,
                gamme_code: codegamme,
                libelle: libelle
            }
            updateObjectFamille(code, data)
                .then((result) => {
                    if (result?.data?.success === 0) {
                        toast(t('Zoning.Erreur'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        toast(t('messageErreur.modifsuccee'), {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    return (
        <div className="modal fade" id="editFamille" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="editFamille"> <i className=' icon icon-equalizer mr-1 text-azure' /> {t('Produits.modifierfamille')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {loading ? <SqaureLoader /> : (
                            <form>
                                <div className='row'>
                                <div className="form-group col-6">
                                    <label className="form-control-label">{t('rapports.code')} :</label>
                                    <input type="text" disabled value={code} onChange={(e) => setCode(e.target.value)} className="form-control" />
                                </div>
                                <div className="form-group  col-6">
                                    <label className="mr-2 mt-1 mb-sm-1"> {t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                    <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} className="form-control" />

                                </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-control-label">{t('Produits.Gamme')}  <span className="text-danger">*</span></label>
                                    <SelectSearch
                                        options={listeGammes}
                                        search
                                        ref={gammes}
                                        onChange={handleGammes}
                                        value={codegamme}
                                        filterOptions={fuzzySearch}
                                        emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                        placeholder={t('Produits.choisirGamme')}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-control-label">{t('Produits.Couleur')} :</label>
                                    <SketchPicker
                                        color={color}
                                        onChangeComplete={handleChangeComplete}
                                    />
                                </div>
                            </form>
                        )}

                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-azure fs-12 "  onClick={() => saveData()} > <i className='icon icon-cloud-download mr-1' />{t('rapports.enregistrer')}  </button>
                    <button type="button" className="btn btn-secondary " data-dismiss="modal" onClick={() => setShow(false)}><i className='ti-close mr-1'/> {t('Routings.annueler')}</button>

                    </div>
                </div>
            </div>
        </div>
    )
}
