/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SqaureLoader from '../../components/Loader/SqaureLoader';
import { getRapports } from '../../services/Rapports/RapportsApi';

export default function ListeRapports() {

    const today = moment().format('YYYY-MM-DD')
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")
    //-------- LOading 
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    var rapports =[
        {code:"CODE-1" , nom:t('rapports.RapportJournalier'),icon:"ti-clipboard text-white",class:"info-box-icon bg-danger ",href:"/rapports/rapportJournalier&date=" + today + "&usercode=''"},
        {code:"CODE-2" , nom:t('rapports.RapportSuiviPointage'),icon:"ti-hand-point-up text-white",class:"info-box-icon bg-primary ",href:"/rapports/rapportSuiviPointage&datedebut=" + today + "&datefin=" + today + "&user_code=''"},
        {code:"CODE-3" , nom:t('rapports.GestiondeStock'),icon:"ti-package text-white",class:"info-box-icon bg-success ",href:"/rapports/rapportStock&datedebut=" + today + "&datefin=" + today },
        {code:"CODE-4" , nom:t('rapports.SuiviTournees'),icon:"ti-truck text-white",class:"info-box-icon bg-yellow ",href:"/rapports/suiviTournee&date=" + today},
        {code:"CODE-5" , nom:t('rapports.Vente'),icon:"ti-money text-white",class:"info-box-icon bg-purple ",href:"/rapports/rapportvente&datedebut=" + today + "&datefin=" + today},
        {code:"CODE-6" , nom:t('rapports.rupture1'),icon:"ti-na text-white",class:"info-box-icon bg-azure ",href:"/rapports/rupture1&datedebut=" + today + "&datefin=" + today },
        {code:"CODE-7" , nom:t('rapports.rupture2'),icon:"ti-na text-white",class:"info-box-icon bg-lime ",href:"/rapports/rupture2&datedebut=" + today + "&datefin=" + today},
        {code:"CODE-8" , nom:t('rapports.Dn'),icon:"ti-bar-chart text-white",class:"info-box-icon  bg-pink ",href:"/rapports/rapportDN&date=" + today},
        {code:"CODE-9" , nom:t('dashboard.pl'),icon:"icon icon-grid text-white",class:"info-box-icon bg-orange ",href:"/rapports/rapportTableauPL&datedebut=" + today + "&datefin=" + today},
        {code:"CODE-10", nom:t('rapports.VisiteMagasins'),icon:"ti-home text-white",class:"info-box-icon bg-blue ",href:"/rapports/visitesMagasins&datedebut=" + today + "&datefin=" + today},
        {code:"CODE-11", nom:t('rapports.graphPartLineaire'),icon:"ti-bar-chart text-white",class:"info-box-icon bg-green ",href:'/rapports/rapportPL&datedebut=' + today + "&datefin=" + today + '&enseigne=""'},
        {code:"CODE-12", nom:t('contacts.contacts'),icon:"ti-user text-white",class:"info-box-icon bg-azure ",href:'/rapports/rapportContacts&datedebut=' + today + "&datefin=" + today + "&user_code=''"},
        {code:"CODE-13", nom:t('rapports.rapportPointageVisites'),icon:"icon icon-speedometer text-white",class:"info-box-icon bg-lime ",href:'/rapports/rapport_visite_pointage'},
        {code:"CODE-14", nom:t('relevePrix.relevePrix'),icon:"icon icon-notebook text-white",class:"info-box-icon bg-pink ",href:"/produits/relevePrix&datedebut=" + date1 + "&datefin=" + date1},
        {code:"CODE-15", nom:t('rapportrupture.grapheRupture'),icon:"icon icon-graph text-white",class:"info-box-icon bg-primary ",href:"/rapportsGraphe/datedebut=" + date1 + "&datefin=" + date2},
        {code:"CODE-16", nom:t('Rapport de gestion globale'),icon:"icon icon-screen-desktop text-white",class:"info-box-icon bg-purple ",href:"/rapports/gestions&datedebut=" + date1 + "&datefin=" + date2},
        {code:"CODE-17", nom:t('Rapport KPI Sales'),icon:"icon icon-screen-desktop text-white",class:"info-box-icon bg-purple ",href:"/rapportKPI/date_debut="+today+"&date_fin="+today},
        {code:"CODE-18", nom:"Rapport Stock Issues",icon:"ti-clipboard text-white",class:"info-box-icon bg-yellow ",href:"/reportStockIssues/date_debut=" + date1+"&date_fin="+date2},
        {code:"CODE-19", nom:"Rapport Merchandiseurs",icon:"ti-clipboard text-white",class:"info-box-icon bg-pink ",href:"/rapport_merchandiseur/date_debut=" + date1+"&date_fin="+date2},
        {code:"CODE-20", nom:"Suivi commande livrés",icon:"ti-clipboard text-white",class:"info-box-icon bg-danger ",href:"/suivi_commandes/date_debut=" + date1+"&date_fin="+date2},
    ]
    const [allRapports, setallRapports] = useState([]);
    useEffect(() => {
        getRapports().then((result) => {
            if(result?.data?.success === 1){
                var liste = result?.data?.liste;
                var array = [];
                liste?.map((items) =>
                  rapports?.map((i) => {
                    if (items?.code === i?.code) {
                      array.push({
                        id : items.id,
                        code: i.code,
                        nom: i.nom,
                        icon: i.icon,
                        class: i.class,
                        href: i.href,
                        actif: items.actif,
                      });
                    }
                  })
                );
                setallRapports(array);
            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloading(false)
        })
    }, [])
    
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')}</li>
                    <li className="breadcrumb-item active" aria-current="page">{t('rapports.Touslesrapports')}</li>
                </ol>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <a className='btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12 float-right mb-2 fs-10' href='/ParamRapports'>
                        <i className="icon icon-equalizer mr-1"/>
                        Paramétrage affichage Rapport</a>
                </div>
            </div> 
            {loading ? <SqaureLoader /> : 
            (<div className="row">
                {allRapports?.map((items,keys)=>
                  items?.actif!==0 && <div className="col-xl-3 col-lg-3 col-md-12 " key={keys}>
                  <a href={items.href}>
                      <div className="info-box " >
                          <span className={items.class} > <i className={items.icon} /></span>
                          <div className="info-box-content">
                              <h6 className='mt-3 fs-12'>{keys+1+" - "+ items.nom}</h6>
                          </div>
                      </div>
                  </a>
              </div>)}
           </div>)}
           
        </div>

    )
}
