import { useEffect, useLayoutEffect, useState } from "react";
import L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import 'leaflet-geosearch/dist/geosearch.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import "leaflet-image";

export default function MapVisitesMerch({ VisiteMagasins }) {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map) {
       setInterval(function () {
          map.invalidateSize();
       }, 100);
    }
 }, [map]);
  
  useLayoutEffect (() => {
    let isMounted = true;
/*     
    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow
  });
  L.Marker.prototype.options.icon = DefaultIcon; */
  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });
  L.Marker.prototype.options.icon = DefaultIcon;
    const initMap = () => {
      const mapInstance = L.map("map", {
        center: [33.886917, 9.537499], // center on Dakar, Senegal
        zoom: 7,
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1,
      }).addTo(mapInstance);

      // add search control
      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        provider: provider,
        showMarker: true,
        style: "bar",
        autoClose: true,
        searchLabel: "Enter address, city, or country",
        keepResult: true,
      });
      mapInstance.addControl(searchControl);

      if (isMounted) {
        setMap(mapInstance);
      }
     
    };

    initMap();

    return () => {
      isMounted = false;
      if (map) {
        map.remove();
      }
    };
  }, []);
  const handleExportClick = () => {
    if (map) {
      L.DomEvent.on(map.getContainer(), "leaflet-image:done", function (e) {
        const img = e.target;
        const link = document.createElement("a");
        link.href = img.src;
        link.download = "map.png";
        console.log(img);
        link.click();
        L.DomEvent.off(map.getContainer(), "leaflet-image:done");
      });
      map.getContainer().classList.add("leaflet-image-loading");
      map.getContainer().classList.add("leaflet-image-loaded");
      map.getContainer().classList.add("leaflet-tile-loaded");
      L.imageOverlay(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        map.getBounds()
      ).addTo(map);
      map.fire("leaflet-image:export", {
        format: "png",
        resolution: 2,
        tileSize: 512,
        exportOnly: true,
        trim: true,
      });
    }
  };
  useLayoutEffect (() => {
    
    if (map && VisiteMagasins.length > 0) {
      VisiteMagasins.forEach((store) => {
        
        const marker = L.marker([store.latitude, store.longitude]).addTo(map);
        marker.bindPopup(`<b>${store.nompdv}</b>`);
      });
    }
  }, [map, VisiteMagasins]);

  // Function to handle clicking on a store card
  const handleStoreCardClick = (store) => {
    // Center the map on the store's location
    map.setView([store.latitude, store.longitude], 15);
  };
  return (
    <div className="tab-pane " id="tab-82">
      <div id="profile-log-switch">
        <div className="container">
          <div className="row">
            <div className="col-5">
              <p>Nombre de points de ventes : <b>{VisiteMagasins?.length}</b></p>
              <div className="row">
              {VisiteMagasins?.map((items,keys) => (
                <div className="col-4" key={keys}>
              <div className="card" onClick={()=>handleStoreCardClick(items)} style={{cursor:"pointer"}}>
                <div className="card-status card-status-left bg-red" />
                <div className="card-body">
                <h3 className="card-title mb-0">{items.nompdv}</h3>
                </div>
              </div>
              </div>
            ))}
              </div>
      
            </div>
           <div className="col-7">
{/*            <button className="btn btn-primary mb-2" onClick={()=>handleExportClick()}>Export Map Image</button>
 */}           <div id="map" style={{ height: "800px" }} />
           

           </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}
