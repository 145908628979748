/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useTranslation } from 'react-i18next';
import ListeCategorie from './ListeCategorie'
import ListeEnseigne from './ListeEnseigne'
import ListeTypeMagasins from './ListeTypeMagasins'

export default function ConfigurationMagasins() {
  //------------- Traduction ---------------
  const [t, i18n] = useTranslation("global");
   
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('Magasins.Magasins')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Magasins.Parametragemagasins')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/listePdv" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Listedesmagasins')}
                        </a>

                      {/*   <a href="/pdv/groupePDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block">
                            <i className="icon icon-organization mr-1" />
                            Groupe PDV
                        </a> */}
                        <a href="/pdv/convention" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-docs mr-1" />
                            {t('conventions.convention')}
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
            <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                 <ListeCategorie t={t} />
                </div>
               
                <div className="col-12 col-md-12 col-lg-6  col-sm-6 col-xl-6">
                <ListeTypeMagasins t={t} />
                </div>
                <div className="col-12 col-md-12 col-lg-12  col-sm-12 col-xl-12">
                <ListeEnseigne  t={t} />
                </div>
            </div>
          
          
            


        </div>
    )
}
