import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Get liste  : magasins
 * @returns 
 */
export async function getListeNotifications(datedebut, datefin) {
    try {
        const response = await axios.get(url + "notifications/" + datedebut + "/" + datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : Produits
 * @returns 
 */
 export async function getListeProduitsNotifications(date) {
    try {
        const response = await axios.get(url + "notifications/produits/"+date, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}

/**
 * GET LISE NOTES
 * @param {*} today 
 * @returns 
 */
export async function getListeNotes(today) {
    try {
        const response = await axios.get(url + "notifications/notes/" + today, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}