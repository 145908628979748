/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import SqaureLoader from '../../../../components/Loader/SqaureLoader';
import { getListeMailingADMIN } from '../../../../services/Produits/configurationProduits/ConfigurationAPI';
import { SearchItems } from '../../../../utils/SearchUtils';
import AddMailingUser from './AddMailingUser';

export default function TableMailingUser({t}) {
    //-------------- LOADER ------------------
    const [Loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')
    const inputEl = useRef("")
  //------- FORMULAIRE ---------
  const [liste, setListe] = useState([])
  const [searchList, setSearchlist] = useState([])
  const [saveList, setSaveList] = useState([])
  const currentListe = search.length < 1 ? liste : searchList
  const [show, setShow] = useState(true)

  //SEARCH ITEMS
  const getSearchTerm = () => {
    setSearch(inputEl.current.value)
    if (search !== "") {
        const newList = SearchItems(liste, search)
        setSearchlist(newList)
    } else {
        setListe(saveList)
    }
}
useEffect(()=>{
    getListeMailingADMIN().then((result) => {
        if(result?.data?.success === 1){
            setListe(result?.data?.data)
            setSaveList(result?.data?.data)
        }
    }).catch((err) => {
        console.log(err)
    }).finally(()=>{
        setLoading(false)
    })

},[show])
  return (
    <div className="row">
    <div className="col-md-12 col-lg-12">

        <div className="card">
            <div className="card-header">
                <div className="card-title mt-3 float-left">Paramétrage des E-Mail </div>
                <button className="btn btn-azure float-right" data-toggle="modal" data-target="#addMailingUser"> <i className="icon icon-plus" /></button>

            </div>
            <div className="card-body">
                <div className="search1 search-elements-sm mb-4 ">
                    <div className="form row no-gutters">
                        <div className="col-6"></div>
                        <div className="form-group  col-xl-5 col-lg-5 col-md-5 mb-0">
                            <input type="text" ref={inputEl} value={search}
                                onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary" id="text1" placeholder="Recherche E-Mail" />
                        </div>

                        <div className="col-xl-1 col-lg-1 col-md-1 mb-0">
                            <a href="#" className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-16"><i className="icon icon-magnifier" /></a>
                        </div>
                    </div>
                </div>
                {Loading ? (
                    <SqaureLoader />
                ) : (
                    <div className="table-responsive product-datatable">
                        <table
                            id="example"
                            className="table table-striped table-bordered "
                        >
                            <thead className="text-center">
                                <tr>
                                    <th className="w-15p fs-12">{t('rapports.code')}</th>
                                    <th className="wd-15p fs-12">{t('login.email')}</th>                                   
{/*                                     <th className="wd-15p fs-12">{t('rapports.ACTION')}</th>
 */}
                                  </tr>
                            </thead>
                            <tbody className="text-center">
                                {currentListe?.map((items, keys) => (
                                    <tr key={keys}>
                                        <td className='fs-12'>{items.code} </td>
                                        <td className='fs-12' >{items.email} </td>
                                    
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        
                    </div>
                )}

            </div>
        </div>
    
    </div>
    <AddMailingUser setShow={setShow} t={t} />
</div>
  )
}
