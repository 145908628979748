import moment from "moment";
import React from "react";
import { poctualite2 } from "./Traitement_auto_evaluation";

export default function ModalPonctualite({
  days,
  setok1,
  t,
  poctualite,
  respect_horaire_travail,
  respect_horaire_debut,
 /*  respect_horaire_fin */
}) {
  return (
    <div
      className="modal fade"
      id="exampleModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
   
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="example-Modal3">
                Note de ponctualité du {moment(days).format("DD/MM/YYYY")}            
            </h5>
            <span>
              <i
                className="icon icon-close text-azure"
                style={{ cursor: "pointer" }}
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setok1(false)}
              />{" "}
            </span>
          </div>

          <div className="modal-body">
          <table className="table table-responsive " >
                <thead>
                    <tr>
                    <th>Critéres </th>
                  <th className="text-center">Autorisé</th>
                  <th className="text-center">Heure</th>
                  <th className="text-center">Respect</th>

                    </tr>
                </thead>
              <tbody>
                <tr>
                  <td >Respect des heures de travail </td>
                  <td className="text-center">{"08:00:00"}</td>
                  <td className="text-center">{respect_horaire_travail[days]!== undefined ? respect_horaire_travail[days][0]?.heure_magasin :"-"}</td>
                  <td className="text-center">{respect_horaire_travail[days][0]?.valeur_respect_horaire}</td>
                </tr>
                <tr>
                <td >Respect des H.Debut </td>
                  <td className="text-center">{"06:00:00 - 09:00:00"}</td>
                  <td className="text-center">{respect_horaire_debut[days]!== undefined ? respect_horaire_debut[days][0]?.debut_visite :"-"}</td>
                  <td className="text-center">{respect_horaire_debut[days][0]?.valeur_respect_horaire}</td>

                </tr>
             {/*    <tr>
                <td >Respect des H.Fin </td>
                  <td className="text-center">{"17:00:00" }</td>
                  <td className="text-center">{respect_horaire_fin[days]!== undefined ? respect_horaire_fin[days][0]?.fin_visite :"-"}</td>
                  <td className="text-center">{respect_horaire_fin[days][0]?.valeur_respect_horaire}</td>

                </tr> */}
                
              </tbody>
              <tfoot>
              <tr >
                    <td colSpan={4} >
                        Note Total : <b>{  poctualite2(days , respect_horaire_travail ,respect_horaire_debut)}/2</b>
                    </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setok1(false)}
            >
              <i className=" ti-close mr-1" /> {t("dashboard.Fermer")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
