/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify'
import { getPromotionsConv } from '../../services/Magasins/PdvApi';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export default function ListePromotions() {
    //DATE TODAY
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")
    //-- Liste --------------------
    const [liste, setliste] = useState([])
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getPromotionsConv()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
    }, [])
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.Promotions')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('planningPromotions')} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a className='btn btn-azure text-white' href={"/promotion/datedebut=" + date1 + "&datefin=" + date2}><i className='icon icon-tag mr-1' />  {t('listepromotions')} </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="card">
                <div className='card-header'>
                    <div className="  text-primary fs-14 ml-2 mt-4 mb-0">
                        <i className='icon icon-briefcase mr-2'
                            width={80}
                            height={80} />
                        {t('planningPromotions')}
                        <div className='float-right '>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-azure btn-pill fs-12 mt-0 "
                                table="table-to-xls"
                                filename="planning_promotions_merbiss"
                                sheet="tablexls"
                                buttonText={<span className=" text-center">  <i className="icon icon-share-alt mr-1" />   {t('rapportrupture.exporter')}</span>}
                            />
                        </div>
                    </div>

                </div>
                <div className="card-body">
                    <div className="row">

                        <div className="table-responsive  product-datatable">
                            <table id="table-to-xls" className="table  table-bordered ">
                                <thead className="text-center bg-azure-lighter">

                                    <tr>
                                        <th className="w-15p fs-12" >{t('Routings.datedebut')}</th>
                                        <th className="w-15p fs-12" >{t('Routings.datefin')}</th>
                                        <th className="w-15p fs-12" >{t('dashboard.Libelle')}</th>
                                        <th className="w-15p fs-12" >{t('rapports.Enseignes')}</th>
                                        <th className="w-15p fs-12" >{t('rapportrupture.categorie')}</th>
                                        <th className="w-15p fs-12" >{t('dashboard.Gamme')}</th>
                                        <th className="w-15p fs-12" >{t('rapportrupture.produit')}</th>
                                        <th className="w-15p fs-12" >{t('rapportrupture.famille')}</th>
                                        <th className="w-15p fs-12" >{t('rapports.S.FAMILLE')}</th>
                                        <th className="w-15p fs-12" >{t('rapportrupture.produit')}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {liste?.map((items, keys) => (
                                        <tr key={keys} className={keys % 2 === 1 ? 'text-center bg-azure-lightest ' : "text-center"}>
                                            <td>{moment(items.date_debut).format("DD-MM-YYYY")}</td>
                                            <td>{moment(items.date_fin).format("DD-MM-YYYY")}</td>
                                            <td>{items.libelle}</td>
                                            <td>{items.enseignes}</td>
                                            <td>{items.categories}</td>
                                            <td>{items.gammes === null ? " - " : items.gammes}</td>
                                            <td>{items.marques === null ? " - " : items.marques}</td>
                                            <td>{items.familles === null ? " - " : items.familles}</td>
                                            <td>{items.sousfamilles === null ? " - " : items.sousfamilles}</td>
                                            <td>{items.produits === null ? " - " : items.produits}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
