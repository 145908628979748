/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

export default function TableRuptures({ sum_ass_rupture, ruptures, info, listeRuptures, rupturedisponibilite, t }) {

    const [liste1, setListe1] = useState([]) // Liste disponible
    const [liste2, setListe2] = useState([]) // Liste Rupture
    const [liste3, setliste3] = useState([]) // Liste Produits non saisies

    useEffect(() => {
        const lis1 = rupturedisponibilite?.filter((el) => el.etat_repture === "0")
        setListe1(lis1)
        const lis2 = rupturedisponibilite?.filter((el) => el.etat_repture === "1")
        setListe2(lis2)
        const li3 = listeRuptures?.filter((el) => el.etat_repture === null)
        setliste3(li3)
    }, [])
    
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='float-left mt-3'>
                    <span className='text-danger'>{ruptures?.somme_repture}</span> {t('Routings.rupturesoit')}
                </div>
                <div className='float-right mt-3'>
                    <span className='text-azure'> {isNaN(parseFloat((ruptures?.somme_repture / ruptures?.total) * 100)) ? 0 : parseFloat((ruptures?.somme_repture / ruptures?.total) * 100).toFixed(2)} %</span>   {t('Routings.txrupture')}
                </div>
            </div>

            <div className='card-body'>
                <div className="panel panel-body mb-2" style={{ overflow: 'scroll', height: "300px" }}>
                    <h6 className=" mt-2 text-muted fs-12">  <span className="text-info"> <i className="icon icon-exclamation mr-1" />  {t('dashboard.Information')} : </span> <div className="d-flex align-items-center  mb-3 mt-3 mr-2">
                        <div className="w-3 h-3 bg-danger  mr-2" />
                        <div>
                            <strong> {t('rapportrupture.rupture')} ({liste2?.length})  </strong>
                            <br />
                        </div>
                        <div className="w-3 h-3 bg-green ml-2 mr-2" />
                        <div>
                            <strong> {t('rapportrupture.disponible')} ({liste1?.length})  </strong>
                            <br />
                        </div>
                        <div className="w-3 h-3 bg-gray ml-2 mr-2" />
                        <div>
                            <strong> {t('rapports.NonSaisie')} ({liste3?.length})  </strong>
                            <br />
                        </div>

                    </div>

                    </h6>
                    <div className='float-right'>
                        <strong className='text-muted'> {t('Magasins.Assortiments')}  ({sum_ass_rupture})  </strong>
                    </div>
                    {/** Tableau rupture Produits  */}
                    <div className="table-responsive  product-datatable">
                        <table className="table  table-bordered ">
                            <thead className="text-center bg-azure-lighter">
                                <tr>
                                    <th className="w-15p fs-12"> {t('contacts.produit')} </th>
                                    <th className="wd-15p fs-12">{t('relevePrix.cause')} </th>
                                    <th className="wd-15p fs-12">{t('rapports.prix')}</th>
                                    <th className="wd-15p fs-12">{t('rapports.QUANTITE')}</th>
                                    <th className="wd-15p fs-12">{t('rapports.position')}</th>
                                    <th className="wd-15p fs-12">{t('dashboard.type')}</th>

                                </tr>
                            </thead>
                            <tbody className='text-center fs-12'>
                                {listeRuptures.length === 0 ?
                                    <tr>
                                        <td colSpan={5} className='text-center text-danger'>{t('rapports.aucuneinfo')}
                                            <hr className='hr1' />
                                            <img src="../assets/images/photos/no.png" alt='noData' style={{ height: "200px" }} />
                                        </td>
                                    </tr> :
                                    <>
                                        {liste1?.map((items, keys) => (
                                            <tr key={keys} className={
                                                keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                            }>
                                                <td  > {items.nom_produit}</td>
                                                <td  > {items.cause === null ? "-" : items.cause}</td>
                                                <td  > {items.prix === null ? "-" : items.prix}</td>
                                                <td  > {items.qte === null ? "-" : items.qte}</td>
                                                <td  > {items.mise_avant === null ? "-" : items.mise_avant}</td>
                                                <td> <i className='ion-stop text-green' /></td>
                                            </tr>
                                        ))}
                                        {liste2?.map((items, keys) => (
                                            <tr key={keys} className={
                                                keys % 2 === 0 ? "fs-12 " : "bg-azure-lightest fs-12"
                                            }>
                                                <td  > {items.nom_produit}</td>
                                                <td  > {items.cause}</td>
                                                <td  > {items.prix}</td>
                                                <td  > {items.qte}</td>
                                                <td  > {items.mise_avant}</td>
                                                <td> <i className='ion-stop text-danger' /></td>

                                            </tr>
                                        ))
                                        }
                                        {liste3?.map((items, keys) => (
                                            <tr key={keys} className="fs-12 bg-gray-lightest">
                                                <td colSpan={5} > {items.nom_produit}</td>
                                                <td> <i className='ion-stop text-muted' /></td>
                                            </tr>
                                        ))
                                        }

                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
