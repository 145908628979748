/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import PaginationTable from '../../../components/Pagination/PaginationTable';
import { SearchItems } from '../../../utils/SearchUtils';
import { getCategorieByCode, getListeCategories } from '../../../services/Magasins/PdvApi'
import ModalAddCategorie from './ModalcCategorie.js/modalAddCategorie';
import ModalEditCategorie from './ModalcCategorie.js/modalEditCategorie';
import { useTranslation } from 'react-i18next';

export default function ListeCategorie({t}) {

    const [listeCategorie, setlisteCategorie] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    //Loader 
    const [Loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(50);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeCategorie?.slice(indexOfFirstPost, indexOfLastPost) : searchList?.slice(indexOfFirstPost, indexOfLastPost)
    //Edit Items 
    const [show, setShow] = useState(true)
    const [showEdit, setShowEdit] = useState(false)
    const [index, setIndex] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    //MODAL  IMPROT EXCEL
    const [ok, setOk] = useState(false)
  
    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeCategorie, search)
            setSearchlist(newList)
        } else {
            setlisteCategorie(saveList)
        }
    }

    useEffect(() => {
        getListeCategories()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteCategorie(result?.data?.data)
                    setSaveList(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [])

    //ONChange 
    useEffect(() => {
        if (show === false || showEdit === true) {
            setLoading(true)
            getListeCategories()
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setlisteCategorie(result?.data?.data)
                        setSaveList(result?.data.data)
                    }
                }).catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [show, showEdit])

    /**
      * Change Cateogires 
      */
    const changeDetailsCategorie = (code) => {
        setLoading(true)
        getCategorieByCode(code)
            .then((result) => {
                setObject(result.data.data[0])
            }).catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }
    return (
        <div className="row">
            <div className="col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title mt-3 float-left"><i className="icon icon-settings text-azure mr-2" />{t('Magasins.ListeCategorie')} </div>
                        <button className="btn btn-azure float-right fs-12" data-toggle="modal" data-target="#addCategorie">   <i className="icon icon-plus mr-1" /> {t("Produits.Ajouter")}</button>

                    </div>
                    <ToastContainer />
                    <div className="card-body">
                        <div className="search1 search-elements-sm mb-3 ">
                            <div className="form row no-gutters">
                                <div className="col-7" />
                                <div className="  col-xl-5 col-lg-5 col-md-5 mb-0">
                                    <span className="input-group-append">
                                        <input type="text" ref={inputEl} value={search}
                                            onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-azure fs-12" placeholder={t('Magasins.Chercherunecategorie')} />
                                        <i className=' ti-search text-azure ml-2 mt-4' />
                                    </span>
                                </div>
                            </div>
                        </div>

                        {Loading ? (
                            <SqaureLoader />
                        ) : (
                            <div className="table-responsive product-datatable" style={{ overflow: "scroll", height: "300px" }}>
                                <table
                                    id="example"
                                    className="table table-striped table-bordered " 
                                >
                                    <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                        <tr>
                                            <th className="w-15p fs-12">{t("rapports.code")}</th>
                                            <th className="wd-15p fs-12">{t("Produits.Libelle")}</th>
                                            <th className="wd-15p fs-12">{t("Routings.editer")}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {currentListe?.map((items, keys) => (
                                            <tr key={keys}
                                                className={
                                                    keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                                }>
                                                <td style={{ fontSize: "12px" }}>{items.code} </td>
                                                <td style={{ fontSize: "12px" }}>{items.libelle} </td>

                                                <td style={{ cursor: "pointer" }}>  <a data-toggle="modal" data-target="#updateCategorie" onClick={() => {
                                                    changeDetailsCategorie(items.code); setCode(items.code); setIndex(keys); setShowEdit(true)
                                                }}> <i className="icon icon-note text-azure" /></a> </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>)}
                    </div>
                    <div className="card-footer">
                        <div className="float-right">
                            <PaginationTable
                                postsPerPage={stocksPerPage}
                                totalPosts={search.length < 1 ? listeCategorie?.length : searchList?.length}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalAddCategorie setShow={setShow} t={t} />
            {showEdit ? <ModalEditCategorie setShowEdit={setShowEdit} setShow={setShow} keys={index} object={object}  t={t}  /> : null}

        </div>
    )
}
