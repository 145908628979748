/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { addSociete, getLastIdSociete } from '../../../../services/Produits/configurationProduits/ConfigurationAPI'
import { myRnId } from '../../../../utils/DateUtils'

export default function ModalAddSociete({ setShow, t }) {
    //------- FORMULAIRE --------------
    const [libelle, setLibelle] = useState("")
    const [email, setemail] = useState("")
    const [expireCMD, setexpireCMD] = useState(0)
    const [photo_visite, setphoto_viste] = useState(0)
    const [codeType, setcodeType] = useState("")
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [list_pdv, setlist_pdv] = useState("")
    const [accees, setaccees] = useState("")
    const [obligation_saisie_assortiment, setobligation_saisie_assortiment] = useState("")
    const [relevePrix, setrelevePrix] = useState("")
    const [cause_rupture, setcause_rupture] = useState("")
    const [envoie_from_mobile, setenvoie_from_mobile] = useState("")

    const saveData = () => {

        if (libelle !== "" && email !== "" && codeType !== "" && relevePrix !== "") {
            getLastIdSociete()
                .then((result) => {
                    if (result?.data?.success === 1) {
                        var y = libelle.slice(0, 2);
                        const data = {
                            code: y + "-" + myRnId(),
                            libelle: libelle,
                            email: email,
                            photo_visite: photo_visite,
                            logo: image.raw,
                            type: codeType,
                            expire_commande: expireCMD,
                            releve_prix: relevePrix,
                            accees: accees,
                            list_pdv: list_pdv,
                            obligation_saisie_assortiment: obligation_saisie_assortiment,
                            cause_rupture: cause_rupture,
                            envoie_from_mobile: envoie_from_mobile
                        }
                        addSociete(data)
                            .then((result) => {
                                if (result?.data?.success === 1) {

                                    toast(t('messageErreur.ajoutavecsuccee'), { type: 'success', position: 'top-right' })
                                } else {
                                    toast(t('Zoning.Erreur'), { type: 'error', position: 'top-right' })
                                }
                            }).catch((err) => {
                                console.log(err)
                            });
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }

    }
    //UPLOAD IMAGE WITH PREVIEW image Commerce
    const selectFile_image = (event) => {
        if (event.target.files.length) {
            setImage({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0]
            })
        }
    }
    return (
        <div className="modal fade" id="addSociete" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addSociete">{t('users.ajouterSociete')}  </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>

                            <div className='row'>

                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="mr-2 mt-1 mb-sm-1"> {t('dashboard.Libelle')}<span className="text-danger">*</span></label>
                                        <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} className="form-control" />

                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('login.email')} :</label>
                                        <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label" > Accès:</label>
                                        <input type="text" value={accees} onChange={(e) => setaccees(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label" > Obligation saisie assortiment:</label>
                                        <select className="form-control  br-md-0  fs-12 text-primary" value={obligation_saisie_assortiment} onChange={(e) => setobligation_saisie_assortiment(e.target.value)}>
                                            <option value={""}> Choisir  </option>

                                            <option value={0}> {t('rapports.non')}  </option>
                                            <option value={1}> {t('rapports.oui')}   </option>

                                        </select>                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-control-label">Enregistrement rupture :</label>
                                        <select className="form-control  br-md-0  fs-12 text-primary" value={envoie_from_mobile} onChange={(e) => setenvoie_from_mobile(e.target.value)}>
                                            <option value={""}> Choisir  </option>
                                            <option value={"piece"}> Piéce  </option>
                                            <option value={"total"}> Total   </option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-control-label">Cause rupture :</label>
                                        <select className="form-control  br-md-0  fs-12 text-primary" value={cause_rupture} onChange={(e) => setcause_rupture(e.target.value)}>
                                            <option value={""}> Choisir  </option>

                                            <option value={0}> {t('rapports.non')}  </option>
                                            <option value={1}> {t('rapports.oui')}   </option>

                                        </select>                                          </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('conventions.bloquagemagasin')} :</label>
                                        {/*                                         <input type="text" value={codeBloquage} onChange={(e) => setcodeBloquage(e.target.value)} className="form-control" />
*/}
                                        <select className="form-control  br-md-0  fs-12 text-primary" value={list_pdv} onChange={(e) => setlist_pdv(e.target.value)}>
                                            <option value={""}> Choisir  </option>
                                            <option value={1}> {t('rapports.non')}  </option>
                                            <option value={0}> {t('rapports.oui')}   </option>

                                        </select>
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="mr-2 mt-1 mb-sm-1"> {t('users.expireCMD')}:  <span className="text-danger">*</span></label>
                                        <input type="text" value={expireCMD} onChange={(e) => setexpireCMD(e.target.value)} className="form-control" />

                                    </div>
                                </div>
                                <div className='col-12'>
                                    <label className='text-danger' > <i className='icon icon-info mr-1' /> 0 :  {t('users.ignorerDate')} </label>
                                </div>
                            </div>
                            <div className='row'>

                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="mr-2 mt-1 mb-sm-1"> {t('Magasins.Type')} <span className="text-danger">*</span></label>
                                        <select className="form-control  br-md-0  fs-12 text-primary" value={codeType} onChange={(e) => setcodeType(e.target.value)}>
                                            <option value={""}> --- {t('Magasins.Type')} ----  </option>
                                            <option value={"merchandising"}> {t('users.Merchandising')}  </option>
                                            <option value={"promotrice"}> {t('Promotrice')}  </option>

                                        </select>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('conventions.photovisite')} :</label>
                                        <select className="form-control  br-md-0  fs-12 text-primary" value={photo_visite} onChange={(e) => setphoto_viste(e.target.value)}>
                                            <option value={0}> {t('rapports.non')}  </option>
                                            <option value={1}> {t('rapports.oui')}   </option>

                                        </select>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('relevePrix.relevePrix')} : <span className="text-danger">*</span></label>
                                        <select className="form-control  br-md-0  fs-12 text-primary" value={relevePrix} onChange={(e) => setrelevePrix(e.target.value)}>
                                            <option value={"produit"}> {t('contacts.produit')}  </option>
                                            <option value={"sousfamille"}> {t('Produits.SousFamille')}   </option>

                                        </select>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('users.logo')} :</label>
                                        <img src={image?.preview} alt="preview" />
                                        <input type="file" name='logo' onChange={(e) => selectFile_image(e)} className="dropify" data-height={180} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => saveData()} ><i className='ti-save mr-1' /> {t('rapports.enregistrer')} </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShow(false)}> <i className='ti-close mr-1' /> {t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
