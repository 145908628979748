import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getListePays, patchObjectRegions } from '../../../services/Zoning/ZoningApi'

export default function ModalUpdateRegion({ code, index,object, setShow , setShowEdit,t }) {
  const [libelle, setlibelle] = useState("")
  const [codepays, setCodePays] = useState(0)
  //  Liste des pays 
  const [listePays, setListePays] = useState([])
   //-------- Loading -----------------------
   const [loading, setloading] = useState(true)

  useEffect(() => {
    setloading(true)
    setlibelle(object?.libelle)
    setCodePays(object?.paye_code)
    getListePays()
    .then((result) => {
      if(result?.data?.success === 1){
        setListePays(result?.data?.data)
      }
    }).catch((err) => {
        console.log(err)
    })
    setloading(false)

},[object])

const saveData = () =>{
  if (code !== "" && libelle!=="") {
    const data = {
        code: code,
        libelle: libelle
    }
    if(codepays !== 0){
      Object.assign(data,{
        paye_code : codepays
      })
    }
    patchObjectRegions(code, data)
        .then((result) => {
            if (result?.data?.success === 0) {
                toast(t('Zoning.Erreur'), {
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else if (result?.data?.success === 1) {
                toast("La région est modifié avec succée !", {
                    type: "success",
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
        }).catch((err) => {
            console.log(err)
        });
}
}
  return (
    <div className="modal fade" id="updateRegions" tabIndex={-1} role="dialog" aria-hidden="true" key={index} >
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-azure" id="updateRegions"> <i className='icon icon-map mr-1' /> {t('Zoning.modifierRegion')} </h5>
                    <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() =>{setShow(false)}} /> </span>
                </div>
                <div className="modal-body">
                    {loading ? <SqaureLoader /> : (
                        <form>
                            <div className='row'>
                                <div className=" form-group col-6">
                                    <label className="form-control-label">{t('rapports.code')} <span className="text-danger">*</span></label>
                                    <input type="text" value={code} disabled className="form-control" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="form-control-label">{t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                    <input type="text" value={libelle} onChange={(e) => setlibelle(e.target.value)} className="form-control" />
                                </div>
                                <div className="form-group col-12">
                                <label className="mr-2 mt-1 mb-sm-1"> Pays <span className="text-danger">*</span></label>
                                <select name="item" className="form-control  nice-select mb-0 nice-select-md col-12 "
                                    value={codepays}
                                    onChange={(e) => {
                                        setCodePays(e.target.value)
                                    }}>
                                    <option value={0}> choisir </option>
                                    {listePays?.map((items, keys) => (
                                        <option value={items.code} key={keys}> {items.libelle} </option>
                                    ))}
                                </select>
                            </div>
                            </div>

                         
                        </form>
                    )}

                </div>
                <div className="modal-footer ">
                    <button type="button" className="btn btn-azure fs-12 " onClick={() => { saveData(); setShowEdit(true); setShow(false) }}  > <i className='icon icon-cloud-download mr-1' />Enregister </button>
                    <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal" > <i className="ti-close mr-1" />Fermer</button>

                </div>
            </div>
        </div>
    </div>)
}
