/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { toast, ToastContainer } from 'react-toastify'
import { addEvaluationn, getUsers, listeEntete } from '../../services/Evaluation/EvaluationAPI';
import { myRnId } from '../../utils/DateUtils';

export default function EvaluationInterface() {
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //----------- LISTE -----------------
    const [listeUsers, setlisteUsers] = useState([])
    const [code, setcode] = useState("Eval-" + myRnId())
    const [codeUser, setcodeUser] = useState("")
    const [somme, setsomme] = useState(0)
    var [Questions, setQuestions] = useState([])
    const [loading, setloading] = useState(true)
    const date = new Date()
    const year = date.getFullYear()
    const valueMonth = date.getMonth() + 1
    const [month, setmonth] = useState(valueMonth)

    const liste = [{ code: 1, libelle: "Janvier" }, { code: 2, libelle: "Février" }, { code: 3, libelle: "Mars" }, { code: 4, libelle: "Avril" }, { code: 5, libelle: "Mai" }, { code: 6, libelle: "Juin" }, { code: 7, libelle: "Juillet" }, { code: 8, libelle: "Aôut" }, { code: 9, libelle: "Septembre" }, { code: 10, libelle: "Octobre" }, { code: 11, libelle: "Novembre" }, { code: 12, libelle: "Décembre" }]
    const searchInput1 = useRef()

    useEffect(() => {
        getUsers().then((result) => {
            if (result?.data?.success === 1) {
                setlisteUsers(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }, [])

    const handleChangeUsers = (...args) => {
        setcodeUser(args[1].value)
    }
    const onClickUser = () => {
        listeEntete().then((result) => {
            if (result?.data?.success === 1) {
                const arr = []
                result?.data?.data?.map((items) => {
                    arr.push({
                        code: items.code,
                        libelle: items.libelle,
                        note: 0
                    })
                })
                let x = _.uniq(arr);
                setQuestions(x)
            }

        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }
    const saveData = () => {
        var arr = []
        Questions?.map((items) => {
            var data = {
                code: code,
                question_code: items.code,
                merch_code: codeUser,
                note: items.note,
                month: month,
                year: year
            }
            arr.push(data)
        })
        let x = _.uniq(arr);
        addEvaluationn(x).then((result) => {
            if (result?.data?.success === 1) {
                toast(t("messageErreur.ajoutavecsuccee"), { type: "success", position: "top-right" })
                setQuestions([])
                setcode("Eval-" + myRnId())
            } else {
                toast(t("messageErreur.erreursaisie"), { type: "error", position: "top-right" })
            }
        }).catch((err) => {
            console.log(err)
        });

    }
    const handleChangeMontant = (e, index) => {
        if (e.target.value > 5 || e.target.value < 0) {
            toast(t("Veuillez saisir une note sur une échelle de 1 à 5"), { type: "warning", position: "top-right" })
        } else {
            const table = [...Questions]
            let clonedData = { ...Questions[index] }
            clonedData[e.target.name] = parseFloat(e.target.value)
            table[index] = clonedData
            setQuestions(table)
            var y = table.reduce(
                (totalsum, { note }) => totalsum + parseFloat(note),
                0
            );
            setsomme(parseFloat(y / Questions.length).toFixed(2));
        }


    }
    return (
        <div>

            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item mt-2"><i className="icon icon-equalizer text-azure mr-1" /> {t("Evaluation Merchandiseurs")}</li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href='/QEvaluation' className='btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12 text-white'><i className="ti-plus" /> {t("Questions")}</a>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-4' />
                <div className='col-4'>
                    <div className='input-group text-center'>
                        <select className='form-control fs-10 text-center text-azure' value={month} onChange={(e) => setmonth(e.target.value)}>
                            {liste?.map((items, keys) => (
                                <option value={items.code} key={keys}>{items.libelle}</option>
                            ))}

                        </select>
                        <SelectSearch
                            value={codeUser}
                            options={listeUsers}
                            search
                            ref={searchInput1}
                            onChange={handleChangeUsers}
                            filterOptions={fuzzySearch}
                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                            placeholder={t("Liste Merchandiseurs")}
                        />
                        <button className='btn btn-azure' onClick={() => onClickUser()}><i className='ti-search' /></button>
                    </div>
                </div>
                <div className='col-4' />
                <div className='col-12 mt-3'>
                    {Questions?.length === 0 ? <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                        <div className="alert bg-purple mb-5 py-4" role="alert">
                            <div className="d-flex">
                                <i className="icon icon-info mr-3 fs-30"></i>
                                <div>
                                    <h4 className="alert-heading">{t("Evaluation Merchandiseur")}</h4>
                                    <p> {t('Vous devez selectionner un merchandiseur')}</p>
                                </div>
                            </div>
                        </div>
                    </div> :
                        <div className='card'>
                            <div className='card-header mt-2'>
                                <label>{t("Fiche D'évaluation du")}: {valueMonth + "/" + year} </label>
                                <label className='float-right' >{t('Moyenne')} : {somme <= 2 ? <span className='text-danger'>{somme}</span> : <span className='text-success'>{somme}</span>}</label>

                            </div>
                            <div className='card-body'>
                                <table className="table table-bordered mt-2">
                                    <thead>
                                        <tr className='text-center text-primary bg-azure-lighter fs-12'>
                                            <td>#</td>
                                            <td>{t("Questions")}</td>
                                            <td>{t('Note (/5)')} </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Questions?.map((items, keys) => (
                                            <tr className={
                                                keys % 2 === 1 ? "fs-12 bg-azure-lightest text-center" : "fs-12 text-center"
                                            }>
                                                <td>{keys + 1}</td>
                                                <td>{items.libelle}</td>
                                                <td >
                                                    <input className='form-control fs-10 text-primary text-center' placeholder='note' name="note" type="number" min={0} max={5} value={items.note} onChange={(e) => handleChangeMontant(e, keys)} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                            <div className='card-footer'>
                                <label >{t('Moyenne')} : {somme <= 2 ? <span className='text-danger'>{somme}</span> : <span className='text-success'>{somme}</span>}</label>
                                <button className='btn btn-azure float-right' onClick={() => saveData()}><i className='ti-plus mr-1' /> {t("Enregistrer")}</button>
                            </div>
                        </div>

                    }
                </div>
            </div>
        </div>
    )
}
