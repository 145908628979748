/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React from 'react'

export default function CardCatalogue({ currentListe, setCode, changeDetailsRoute, t }) {
    return (
        <div className=" col-md-12 col-sm-12 col-12 col-xl-12">
            <div className="card">
                <div className="product-details table-responsive border-top">
                    <table className="table table-bordered table-hover mb-0 text-nowrap">
                        <thead>
                            <tr className='fs-10 text-center'>
                                <td>{t('Image')}</td>
                                <td>{t("dashboard.Date")}</td>
                                <td>{t("Produits.DATEDEBUT")}</td>
                                <td>{t("Produits.DATEFIN")}</td>
                                <td>{t("dashboard.Magasin")} </td>
                                <td>{t("dashboard.Merchandiseurs")}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentListe?.map((items, keys) => (
                                <tr key={keys} className="fs-10 text-center">
                                    <td> <a onClick={() => { changeDetailsRoute(items.code); setCode(items.code) }} className="text-primary" data-toggle="modal" data-target="#exampleModal3">                                            <img src={items?.image !== null ? items?.image : "../assets/images/users/23.png"} alt="notes" style={{ width: "50px", height: "50px" }} />
                                    </a>
                                    </td>
                                    <td>{moment(items.date).format("DD/MM/YYYY")}</td>
                                    <td>{moment(items.date_debut).format("DD/MM/YYYY")}</td>
                                    <td>{moment(items.date_fin).format("DD/MM/YYYY")}</td>
                                    <td>{items.nompdv}</td>
                                    <td>{items.nom === null ? "-" : items.nom}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
