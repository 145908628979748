import React from 'react'

export default function TablePartLineaire({ listePL, infoPl ,sum_ass_pl,t}) {
    return (
        <div className='card'>
            <div className='card-header'>
                <h6 className='card-title mt-3 text-center '> {  Math.round(infoPl?.facingTotal).toFixed(2)} % {t('dashboard.pl')} </h6>
            </div>
            <div className='card-body'>
           
                <div className="panel panel-body mb-2" style={{ overflow: 'scroll', height: "300px" }}>
                <div className='float-right'>
                        <strong className='text-muted'> {t('Magasins.Assortiments')}  ({sum_ass_pl})  </strong>
                    </div>
                    {/** Tableau rupture Produits  */}
                    <div className="table-responsive  product-datatable">
                        <table className="table  table-bordered ">
                            <thead className="text-center bg-azure-lighter">
                                <tr>
                                    <th className="w-15p fs-12">{t('dashboard.Libelle')}</th>
                                    <th className="wd-15p fs-12">{t('rapports.facing')}</th>
                                    <th className="wd-15p fs-12">{t('rapports.facingtotal')}</th>
                                    <th className="wd-15p fs-12">{t('dashboard.pl')}</th>

                                </tr>
                            </thead>
                            <tbody className='text-center fs-12'>
                                {listePL.length === 0 ?
                                    <tr>
                                        <td colSpan={4} className='text-center text-danger'>{t('rapports.aucuneinfo')}
                                        <hr className='hr1'/>
                                        <img src="../assets/images/photos/no.png" alt='noData' style={{height:"200px"}}/>
                                        </td>
                                    </tr> :
                                    listePL?.map((items, keys) => (
                                        <tr  key={keys} className={
                                            keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                        }>
                                            <td>{items.cible}</td>
                                            <td>{items.plconstate}</td>
                                            <td>{items.pltotal}</td>
                                            <td>{ Math.round(parseFloat((items.plconstate / items.pltotal) * 100))} %</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
