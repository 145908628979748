import React, { useEffect, useRef, useState } from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast } from 'react-toastify'
import SqaureLoader from '../../../../components/Loader/SqaureLoader'
import { addZones, getUsersZones } from '../../../../services/Affectations/AffectationAPI'
import { myRnId } from '../../../../utils/DateUtils'

export default function AddAffectationZone({ setShow, t }) {
    const [code, setCode] = useState("AffZone-" + myRnId())
    const [codeZone, setcodeZone] = useState("")
    const [codeChef, setcodeChef] = useState("")
    const [listeZones, setlisteZones] = useState([])
    const [listeChefs, setlisteChefs] = useState([])
    const { searchInput1, searchInput2 } = useRef();
    //------------Loading ----------------
    const [loading, setloading] = useState(true)

    const saveData = () => {
        if(codeChef !== "" && codeChef !== ""){
            const data = {
                code : code,
                chef_code : codeChef,
                zone_code : codeZone
            }
            addZones(data).then((result) => {
                if(result?.data?.success === 1){
                    toast("added",{type:"success",position:"top-right"})
                    setShow(false)

                }else {
                    toast("error",{type:"error",position:"top-right"})

                }
            }).catch((err) => {
                console.log(err)
            });
        }else {
            toast("Veuillez vérifier vos données ",{type:"warning", position:"top-right"})
        }
    }
    useEffect(() => {
        getUsersZones().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.zones)
                setlisteChefs(result?.data?.users)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }, [])

    // Handle Change MERCHANDISEUR
    const handlechangeZone = (...args) => {
        setcodeZone(args[1].value)
    };
    // Handle Change chef
    const handleChangeChef = (...args) => {
        setcodeChef(args[1].value)
    };
    return (
        <div className="modal fade" id="addZone" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addZone"> Affectation Zone  </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {loading ? <SqaureLoader /> : (
                            <form>

                                <div className='row'>

                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label className="mr-2 mt-1 mb-sm-1"> {t('rapports.code')}<span className="text-danger">*</span></label>
                                            <input type="text" value={code} readOnly disabled className="form-control" />

                                        </div>
                                    </div>

                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label className="form-control-label">Chef :</label>
                                            <SelectSearch
                                                value={codeChef}
                                                options={listeChefs}
                                                search
                                                ref={searchInput1}
                                                onChange={handleChangeChef}
                                                filterOptions={fuzzySearch}
                                                emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                                placeholder="Liste Chefs"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label className="form-control-label">Zone :</label>
                                            <SelectSearch
                                                value={codeZone}
                                                options={listeZones}
                                                search
                                                ref={searchInput2}
                                                onChange={handlechangeZone}
                                                filterOptions={fuzzySearch}
                                                emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                                placeholder="Liste Zones"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </form>
                        )}

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => saveData()} ><i className='ti-save mr-1' /> {t('rapports.enregistrer')} </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShow(false)}> <i className='ti-close mr-1' /> {t('Routings.annueler')}</button>

                    </div>
                </div>
            </div>
        </div>
    )
}
