import React from 'react'
import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"

export default function ExportExcel({excelData, fileName ,commande_code ,keys, handleChange,noHandle}) {

    const filteType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileExtension =".xlsx"

    const exportToExcel = async  ()=> {
        const ws = XLSX.utils.json_to_sheet(excelData)
        const wb ={Sheets : {'data':ws},SheetNames :['data']};
        const excelBuffer = XLSX.write(wb,{bookType :"xlsx",type:"array"});
        const data = new Blob([excelBuffer],{type:filteType})
        FileSaver.saveAs(data,fileName+fileExtension)
        if(!noHandle){
          handleChange(true,keys,commande_code )
        }
    }
  return (
        <button className='btn btn-warning text-white fz-10 ml-2' 
        onClick={(e)=>exportToExcel(fileName)}>
            <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-download  fz-10"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>  
        </button>
  )
}
