/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { getParamPointageByCode, updateParmPointages } from '../../../services/Rapports/RapportsApi';

export default function ModalEditParamPointage({ code  ,setShowEdit ,listeUsers ,t}) {

    //---------- CONFIGURATION POINTAGE-----------
    const [actif, setactif] = useState("")
    const [codeUser, setcodeUser] = useState("")
    const [datedebut, setdatedebut] = useState("")
    const [datefin, setdatefin] = useState("")
    const [heuredebut, setheuredebut] = useState("")
    const [heurefin, setheurefin] = useState("")
    const [dureeRetard, setdureeRetard] = useState("")
    const [pauseDej, setpauseDej] = useState("")
    const [nbrHeure, setnbrHeure] = useState("")
   //--------- LOADER -----------------
   const [loading, setloading] = useState(true)

    useEffect(()=>{
        getParamPointageByCode(code)
        .then((result) => {
            if(result?.data?.success===1){
                const data = result.data.data[0]
                setactif(data?.actif)
                setcodeUser(data?.user_code)
                setdatedebut(moment(data?.date_debut).format("YYYY-MM-DD"))
                setdatefin(moment(data?.date_fin).format("YYYY-MM-DD"))
                setheuredebut(data?.heure_debut)
                setheurefin(data?.heure_fin)
                setdureeRetard(data?.durai_retard)
                setpauseDej(data?.pause)
                setnbrHeure(data?.nbr_heure)


            }
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            setloading(false)
        })
    },[])
    
    const onSaveData = () => {
        if (actif === "") {
            toast("Vous devez selectionner le champs actif ", { type: "warning", position: "top-right" })
        } else
            if (datedebut > datefin) {
                toast("La date début doit être inférieur à la date fin ", { type: "warning", position: "top-right" })
            } else { 
                const data = {
                    user_code: codeUser,
                    actif: actif,
                    date_debut: datedebut,
                    date_fin: datefin,
                    heure_debut: heuredebut,
                    durai_retard: dureeRetard,
                    heure_fin: heurefin,
                    pause: pauseDej,
                    nbr_heure: nbrHeure
                }
                /**
                 * Update Parametrage Pointage
                 */
                 updateParmPointages(code,data)
                    .then((result) => {
                        if (result?.data?.success === 1) {
                            toast("Modifié avec succée !! ", { type: "success", position: "top-right" })
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
            }
    }


    return (
        <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-hidden="true" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3">{t('rapports.modifierpointage')}  </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close"  /> </span>

                    </div>
                    <div className="modal-body">
                        {loading ?
                            <SqaureLoader /> : (
                                <div >
                                      <div className='row'>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('Produits.UTILISATEUR')} <span className="text-danger">*</span></label>
                                        <select value={codeUser} onChange={(e) => setcodeUser(e.target.value)} className="form-control  custom-select br-md-0">
                                            <option value=""> Merchandiseur  </option>
                                            {listeUsers?.map((items, keys) => (
                                                <option value={items.value} >{items.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('Magasins.Actif')} <span className="text-danger">*</span></label>
                                        <select value={actif} onChange={(e) => setactif(e.target.value)} className="form-control  custom-select br-md-0">
                                            <option value="">   {t('Magasins.Actif')}  </option>
                                            <option value={"1"}> {t('Magasins.Oui')}</option>
                                            <option value={"0"}>{t('Magasins.Non')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Routings.datedebut')} <span className="text-danger">*</span></label>
                                        <input className='form-control' type="date" value={datedebut} onChange={(e) => setdatedebut(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Routings.datefin')} <span className="text-danger">*</span></label>
                                        <input className='form-control' type="date" value={datefin} onChange={(e) => setdatefin(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.H.DEBUT')} <span className="text-danger">*</span></label>
                                        <input className='form-control' type="time" value={heuredebut} onChange={(e) => setheuredebut(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.H.FIN')} <span className="text-danger">*</span></label>
                                        <input className='form-control' type="time" value={heurefin} onChange={(e) => setheurefin(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.dureeretard')}<span className="text-danger">*</span></label>
                                        <input className='form-control' type="time" value={dureeRetard} onChange={(e) => setdureeRetard(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.pausedej')} <span className="text-danger">*</span></label>
                                        <input className='form-control' type="time" value={pauseDej} onChange={(e) => setpauseDej(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.nbrheure')} <span className="text-danger">*</span></label>
                                        <input className='form-control' type="time" value={nbrHeure} onChange={(e) => setnbrHeure(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                                </div>
                            )}

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={()=>onSaveData()}><i className='ti-save mr-1' /> {t('Zoning.modifier')} </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"> <i className='ti-close mr-1'/> {t('dashboard.Fermer')}</button>

                    </div>
                </div>
            </div>
        </div>
    )
}
