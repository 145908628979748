import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { toast } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { updateObjectGammes } from '../../../services/Produits/configurationProduits/ConfigurationAPI'

export default function EditModalGammes({ keys, setShowEdit,setShow, object ,t}) {

    const [code, setCode] = useState("")
    const [libelle, setLibelle] = useState("")
    const [color, setColor] = useState("#fffff")

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        setLoading(true)
        setCode(object.code)
        setLibelle(object.libelle)
        setColor(object.color)
        setLoading(false)
    }, [object])

    
    /**
     * SAVE DATA
     */
     const saveData = () => {
        //Data of code & libelle FOR OBJECT PAYS
        if (code !== "" && color !== "") {
            const data = {
                code: code,
                color: color,
                libelle: libelle
            }
            updateObjectGammes(code,data)
                .then((result) => {
                    console.log(result)
                    if (result?.data?.success === 0) {
                        toast(t('Zoning.Erreur'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        toast(t('messageErreur.modifsuccee'), {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    /**
     * Change Color
     * @param {*} color 
     */
    const handleChangeComplete = (color) => {
        setColor(color.hex)
    }
    return (
        <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-hidden="true" key={keys}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="example-Modal3">  <i className=' icon icon-equalizer mr-1 text-azure' /> {t('Produits.modifiergamme')}  </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} /> </span>

                    </div>
                    <div className="modal-body">
                        {loading ?
                         <SqaureLoader /> : (
                            <form>
                                <div className="form-group">
                                    <label className="form-control-label">{t('rapports.code')} :</label>
                                    <input type="text" value={code} disabled onChange={(e) => setCode(e.target.value)} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label className="mr-2 mt-1 mb-sm-1"> {t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                    <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} className="form-control" />

                                </div>
                                <div className="form-group">
                                    <label className="form-control-label">{t('Produits.Couleur')} :</label>
                                    <SketchPicker
                                        color={color}
                                        onChangeComplete={handleChangeComplete}
                                    />
                                </div>
                            </form>
                        )}

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-azure fs-12" onClick={() => {saveData();setShowEdit(true);setShow(false)}} > <i className='icon icon-cloud-download mr-1' />{t('rapports.enregistrer')}  </button>
                        <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal"><i className='ti-close mr-1'/> {t('Routings.annueler')}</button>

                    </div>
                </div>
            </div>
        </div>
    )
}
