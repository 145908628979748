/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { getRapportVisiteMagasin } from '../../../services/Rapports/RapportsApi'
import { getSelectMagasins } from '../../../services/Ruptures/ruptureAPI'
import { getMerch } from '../../../services/Users/UsersApi'
import { getSelectZone } from '../../../services/Zoning/ZoningApi'
import { diff_minutes } from '../../../utils/Distance'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useTranslation } from 'react-i18next'

export default function RapportVisiteMagasins() {
    //--------- PARAMETERE -------------
    const { datedebut, datefin } = useParams('')
    //------ History -----------------
    const history = useHistory()
    //---------- FORMULAIRE --------------
    const [date_d, setdate_d] = useState(datedebut)
    const [date_f, setdate_f] = useState(datefin)
    const [codeUser, setcodeUser] = useState("")
    const [codezone, setcodezone] = useState("")
    const [codePdv, setcodePdv] = useState("")
    //--------- LISTES ---------------------
    const [listeUsers, setlisteUsers] = useState([])
    const [listeZones, setlisteZones] = useState([])
    const [listePdv, setlistePdv] = useState([])
    const [liste, setliste] = useState([])
    const [saveListe, setsaveListe] = useState([])
    //--------- Loader ------------------------
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteUsers(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            })
        getSelectMagasins().then((result) => {
            if (result?.data?.success === 1) {
                setlistePdv(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });

        getSelectZone().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
        getRapportVisiteMagasin(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.liste)
                    setsaveListe(result?.data?.liste)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })

    }, [])

    const onChangeValues = () => {
        history.push({
            pathname:
                '/rapports/visitesMagasins&datedebut=' + date_d + '&datefin=' + date_f,
        })
        getRapportVisiteMagasin(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setliste(result?.data?.liste)
                    setsaveListe(result?.data?.liste)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })

    }
    const handleChangeMerch = (e) =>{
        setcodeUser(e.target.value)
        console.log(saveListe);
        const L= saveListe?.filter((el)=>el.user_code === e.target.value)
        setliste(L)
    }
    const handleChangeZone = (e) =>{
        setcodezone(e.target.value)
        const L= saveListe?.filter((el)=>el.zone_code === e.target.value)
        setliste(L)
    }
    const back= ()=>{
        setcodezone("")
        setcodeUser("")
        setliste(saveListe)
    }
    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/rapports"><i className="fe fe-life-buoy mr-1" /> {t('rapports.Rapport')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">  {t('rapports.RapportVisitesMagasins')} </li>
                </ol>
            </div>
            {/** ------------- rapport : Rapport Visite Magasins  ------------------------------ */}
            <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                <div className="panel panel-default active">
                    <div className="panel-heading " role="tab" id="headingOne">
                        <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <i className="icon icon-grid text-primary mr-2 fs-10" />{t('rapports.RapportVisitesMagasins')}
                                <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                            </a>
                        </h4>
                    </div>
                    <ToastContainer />
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                            <div className="form row no-gutters">
                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 mb-0">
                                    <input type="date" className='form-control fs-10' value={date_d} onChange={(e) => setdate_d(e.target.value)} />
                                </div>

                                <div className="form-group  col-xl-3 col-lg-3 col-md-3 col-6 mb-0 ">
                                    <input type="date" className='form-control  fs-10' value={date_f} onChange={(e) => setdate_f(e.target.value)} />
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 col-6 mb-0">
                                    <select className="form-control  br-md-0  fs-10 text-primary" value={codeUser} onChange={(e) => handleChangeMerch(e)}>
                                        <option value={""} >{t('dashboard.Merchandiseurs')}</option>
                                        {listeUsers?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group  col-xl-2 col-lg-2 col-md-2 col-6 mb-0">
                                    <select className="form-control  br-md-0  fs-10 text-primary" value={codezone} onChange={(e)=>handleChangeZone((e))} >
                                        <option value={""} >{t('rapports.Zones')}</option>
                                        {listeZones?.map((items, keys) => (
                                            <option key={keys} value={items.value}>{items.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-14" onClick={() => onChangeValues()}><i className='ti ti-search' /></button>

                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                                    <button className="br-tl-md-0 br-bl-md-0 btn btn-block btn-primary fs-14" onClick={()=>back()}><i className='ti ti-close' /></button>

                                </div>
                              
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                {loading ? <SqaureLoader /> :
                    <div className='card-body'>
                          <div className="col-12 mb-0">
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-green float-right fs-12 mr-1 mt-2 mb-2"
                                        table="table-pdv"
                                        filename="liste_visite_pdvs"
                                        sheet="tablexls"
                                        buttonText={<span className=" text-center"> {t('rapportrupture.exporter')} <i className="fa fa-file-excel-o" /> </span>}
                                    />
                                </div>
                        <table id="table-pdv" className="table  table-bordered ">
                            <thead className="text-center bg-azure-lighter">
                                <tr>
                                    <th className="w-15p fs-10">{t('rapports.REGION')} </th>
                                    <th className="w-15p fs-10">{t('rapportVentes.datedevisite')}</th>
                                    <th className="w-15p fs-10">{t('dashboard.Merchandiseurs')}</th>
                                    <th className="w-15p fs-10">{t('dashboard.Enseigne')}</th>
                                    <th className="w-15p fs-10">{t('dashboard.Magasin')}</th>
                                    <th className="w-15p fs-10">{t('rapports.tournee')} </th>
                                    <th className="w-15p fs-10">{t('Routings.Jour')}</th>
                                    <th className="w-15p fs-10">{t('Routings.InOutRoutings')}</th>
                                    <th className="w-15p fs-10">{t('rapports.H.DEBUT')} </th>
                                    <th className="w-15p fs-10">{t('rapports.H.FIN')} </th>
                                    <th className="w-15p fs-10">{t('dashboard.delais')} </th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {liste?.map((items, keys) => (
                                    <tr key={keys} className='fs-10'>
                                        <td> {items.region} </td>
                                        <td> {moment(items.date).format("DD-MM-YYYY")} </td>
                                        <td> {items.nom} </td>
                                        <td> {items.enseignes} </td>
                                        <td> <a className='text-success' href={'/pdv/historique&code=' + items.pdv_code}>{items.nompdv}</a> </td>
                                        <td> <a className='text-primary' href={"/tournee/code=" + items.codetournee}> {items.codetournee}</a> </td>
                                        <td> {items.code_jour} </td>
                                        <td> {items.inRouting} </td>
                                        <td> {items.heure_debut} </td>
                                        <td> {items.heure_fin} </td>
                                        <td> {items.difference}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
            </div>
        </div>
    )
}
